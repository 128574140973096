import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import PinDropIcon from '@material-ui/icons/PinDrop';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { getServiceUIName } from '../../../constants';
import { useAppState } from '../../../contexts/AppState';
import { ListingMarketResponseIncludes } from '../../../types/marketplace-service/market';
import { ServiceRelationship, ServiceTypeKeys } from '../../../types/services';
import ListingExpiry from '../../ListingExpiry';
import { MarketState } from '../../MarketInfoDisplay';
import { ServiceState } from '../../ServiceDetails';
import Tags, { Tag } from '../../Tags';
import { ListingResponse } from '../../../types/marketplace-service/listing';

interface ListingItemProps extends ListingResponse {
  focused?: boolean;
  onClick: () => void;
  markets?: ListingMarketResponseIncludes[];
  services?: ServiceRelationship[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      border: (props: { focused?: boolean }) =>
        props.focused
          ? '1px solid transparent'
          : `1px solid ${theme.palette.grey[300]}`,
    },
    borderRadius: '10px',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      boxShadow: theme.shadows[4],
      border: '1px solid transparent',
    },
    '&:hover': {
      [theme.breakpoints.up('sm')]: {
        border: '1px solid transparent',
        boxShadow: theme.shadows[4],
      },
    },
  },
  container: {
    padding: theme.spacing(2, 2),
    alignItems: 'center',
    marginBottom: 'auto',
  },
  viewCount: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
  },
  companyName: {
    color: theme.palette.grey[500],
  },
  companyNameSmall: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightBold,
  },
  multi: { marginRight: theme.spacing(1) },
  icon: {
    display: 'block',
    color: theme.palette.primary.main,
  },
  date: {
    color: theme.palette.success.main,
  },
  serviceIcon: {
    transform: 'rotate(90deg)',
  },
  rating: {
    color: theme.palette.primary.main,
  },
  px: {
    padding: theme.spacing(0, 1),
  },
  tags: {
    marginTop: theme.spacing(3),
  },
  MuiChipOutlinedPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  logo: {
    height: '24px',
    objectFit: 'contain',
  },
}));

const ListingItem: FunctionComponent<ListingItemProps> = React.memo(
  ({ focused, id, attributes, markets = [], services = [], onClick }) => {
    const classes = useStyles({ focused });
    const appState = useAppState();
    const { company, title, company_logo } = attributes;
    const listingId = id;
    return (
      <Paper
        elevation={focused ? 7 : 0}
        className={classes.root}
        onClick={onClick}
      >
        <div className={classes.container}>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item xs={6} zeroMinWidth>
              {company_logo && (
                <img
                  className={classes.logo}
                  src={company_logo}
                  alt={`${company} logo`}
                />
              )}
              {!company_logo && (
                <Typography noWrap variant="h6" className={classes.companyName}>
                  {company}
                </Typography>
              )}
            </Grid>
            <Grid item container justifyContent="flex-end" xs={6}>
              <ListingExpiry
                end={parseISO(attributes.end)}
                state={attributes.state}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item xs>
              {company_logo && (
                <Typography
                  noWrap
                  component="div"
                  className={classes.companyNameSmall}
                >
                  {company}
                </Typography>
              )}
              <Typography component="div">
                {title.length <= 70 ? title : `${title.substr(0, 70)}...`}
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.tags}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs="auto">
                <PinDropIcon className={classes.icon} />
              </Grid>
              <Grid item xs="auto">
                <Tags slice={2}>
                  {markets.map((market) => (
                    <MarketState key={market.id} id={market.id}>
                      {(market) =>
                        appState.markets[market.attributes.id] ? (
                          <Tag>
                            {appState.markets[market.attributes.id].name}
                          </Tag>
                        ) : (
                          <></>
                        )
                      }
                    </MarketState>
                  ))}
                </Tags>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs="auto">
                <CallMergeIcon
                  className={clsx(classes.icon, classes.serviceIcon)}
                />
              </Grid>
              <Grid item xs="auto">
                <Tags slice={2}>
                  {services.map((service) => (
                    <ServiceState
                      key={service.id}
                      id={service.id!}
                      listingId={listingId}
                    >
                      {(service) => {
                        if (
                          service.attributes.type !==
                          'multi_service_requirements'
                        ) {
                          return (
                            <Tag>
                              {getServiceUIName(service.attributes.type)}
                            </Tag>
                          );
                        } else if (
                          service.attributes.attributes?.requirements
                        ) {
                          return (
                            <>
                              {Object.entries(
                                service.attributes.attributes.requirements
                              )
                                .filter(([, value]) => value)
                                .map(([type]) => (
                                  <span className={classes.multi} key={type}>
                                    <Tag key={type}>
                                      {getServiceUIName(
                                        type as ServiceTypeKeys
                                      )}
                                    </Tag>
                                  </span>
                                ))}
                            </>
                          );
                        } else {
                          return <Tag> Other Services </Tag>;
                        }
                      }}
                    </ServiceState>
                  ))}
                </Tags>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    );
  }
);

export default ListingItem;
