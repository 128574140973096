import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Link,
  makeStyles,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import RateLimitImage from '../../assets/marketplace.limit.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      maxWidth: '100%',
      width: 450,
      margin: theme.spacing(4),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface RateLimitModalComponentProps {
  open: boolean;
  onClose: () => void;
}

const RateLimitModalComponent: React.FC<RateLimitModalComponentProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <img
              className={classes.image}
              alt="No listings"
              src={RateLimitImage}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogTitle id="alert-dialog-slide-title">
              <Typography component="div" variant="h2">
                Woah, you&apos;ve been busy…
              </Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                We are happy to consider extending your search capability, but
                you&apos;ll need to get in touch with us first. This process
                helps to protect Cloudscene against any breaches to our 
                <Link
                  href="https://access.cloudscene.com/terms/"
                  rel="noopener noreferrer"
                >
                  Website Terms
                </Link>
                .
              </Typography>
              <Box py={2}>
                <Typography variant="body1">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://cloudscene.com/contact-us/"
                  >
                    Contact us
                  </Link>{' '}
                  to tell us a little more about your primary business purpose
                  for using Cloudscene Marketplace, and our team will review
                  your daily search limit. Please allow 24 hours for our team to
                  respond.
                </Typography>
              </Box>

              {/*Interim solution for Rate Limit form before Rate Limiting API email point can be built MP-352 */}
              {/*
              <Form
                onSubmit={(values: any) => {
                  const email = sendRateLimitedResponseEmail({
                    full_name: user?.name!,
                    email: user?.email!,
                    company_name: companyQuery.data?.attributes.name!,
                    user_id: user?.id!,
                    message: message!,
                  });
                  console.log(email); // TODO: add email API call when Back end is ready
                }}
                keepDirtyOnReinitialize
                subscription={{ submitting: true, pristine: true }}
              >
                {({ handleSubmit }: FormRenderProps<any>) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          name="text"
                          render={({ input, meta }) => (
                            <>
                              <CSTextField
                                label=""
                                {...input}
                                error={meta.touched && !!meta.error}
                                helperText={meta.touched && meta.error}
                                multiline
                                onBlur={() => setMessage(input.value)}
                                placeholder="Tell us more about your business purpose
                                for using Cloudscene Marketplace."
                              />
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Box my={2} mx="auto" width="200px">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={!message}
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                          
                )}
                          </Form> */}
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default RateLimitModalComponent;
