import { useSelector } from 'react-redux';
import { RootState } from '../../store/types.d';
import { selectDocumentById } from '../../store/documents';
import { Document } from '../../types/document';

const DocumentState = ({
  id,
  children,
}: {
  id: string;
  children: (document: Document) => JSX.Element;
}) => {
  const document = useSelector((state: RootState) =>
    selectDocumentById(state, id)
  );
  return document ? children(document) : null;
};

export default DocumentState;
