import { useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyAccountResponse } from '../types/account-service/company-account';
import { UserResponse } from '../types/account-service/users';
import { startActing, stopActing } from '../store/acting';
import { ActingUserType } from '../store/acting/types';
import { RootState, AppDispatch } from '../store/types.d';

type StartActingOptions = (args: {
  user: UserResponse;
  account: CompanyAccountResponse;
  profiles?: string[];
  permissions?: string[];
  roles?: string[];
  options?: { redirect?: boolean; origin?: string };
  companyPackage: string;
}) => void;

type StopActingOptions = (redirect?: boolean) => void;

export type ActingContextType = {
  actingUser: ActingUserType;
  startActing: StartActingOptions;
  stopActing: StopActingOptions;
  isActing: boolean;
};

export const useActingUser = (): ActingContextType => {
  const dispatch: AppDispatch = useDispatch();
  const acting = useSelector((state: RootState) => state.acting);
  const onStartActing = useCallback<StartActingOptions>(
    ({
      user,
      account,
      profiles,
      permissions,
      roles,
      options = {},
      companyPackage,
    }) => {
      const { origin, redirect = true } = options;
      dispatch(
        startActing({
          user,
          account,
          profiles,
          permissions,
          roles,
          origin,
          companyPackage,
        })
      );
      if (redirect) navigate('/');
    },
    [dispatch]
  );
  const onStopActing = useCallback<StopActingOptions>(
    (redirect = true) => {
      dispatch(stopActing());
      if (acting.origin && redirect) navigate(acting.origin);
    },
    [dispatch, acting]
  );

  return {
    actingUser: acting.user,
    isActing: !!acting.user.id && !!acting.user.account,
    startActing: onStartActing,
    stopActing: onStopActing,
  };
};
