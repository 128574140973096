import React, { useState, useRef, useLayoutEffect } from 'react';
import { Box, InputLabel, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, lighten } from '@material-ui/core/styles';
import {
  Field,
  FormRenderProps,
  FormSpy,
  FormSpyRenderProps,
  FieldRenderProps,
} from 'react-final-form';
import ServicePricing from '../ServicePricing';
import TextField from '../Form/CSTextField';
import BiddingFooter from '../BiddingFooter';
import FileUpload from '../FileUpload';
import AttachmentList from '../AttachmentList';
import TextReadMore from '../TextReadMore';
import { deleteDocument } from '../../api';
import { CreateBidFormState, CreateBidQuote } from '../../types/bid';
import { Document } from '../../types/document';
import ServiceDetails from '../ServiceDetails';
import { FieldArray } from 'react-final-form-arrays';
import { getServiceUIName } from '../../constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    service: {
      backgroundColor: lighten(theme.palette.grey[100], 0.5),
      padding: theme.spacing(2, 3),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(2),
      },
    },
    info: {},
  })
);

const RegisterBid: React.FC<FormRenderProps<CreateBidFormState>> = ({
  handleSubmit,
  form,
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'), {
    noSsr: true,
  });
  const [formSize, setFormSize] = useState<number | undefined>();
  const ref = useRef<HTMLFormElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      setFormSize(ref.current?.offsetWidth);
    }
  }, [setFormSize]);

  return (
    <form onSubmit={handleSubmit} ref={ref}>
      <FieldArray name="quotes">
        {({ fields }) =>
          fields.map((field, index) => (
            <Box key={index} className={classes.service}>
              <ServiceQuoteItem
                name={field}
                index={index}
                formSize={formSize}
              />
            </Box>
          ))
        }
      </FieldArray>
      <Box py={3} px={mobile ? 2 : 1}>
        <Field name="installLeadTime">
          {({ input, meta }) => (
            <TextField
              label="Please provide information around installation and lead time *"
              multiline
              minRows={4}
              maxRows={4}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              placeholder="e.g. lead time requirements are 45 days after signed order"
              {...input}
            />
          )}
        </Field>
      </Box>
      <Box py={3} px={mobile ? 2 : 1}>
        <Field name="termsConditions">
          {({ input, meta }) => (
            <TextField
              label="Mention any exclusions or terms and conditions *"
              multiline
              minRows={4}
              maxRows={4}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              placeholder="e.g. cross connects not included"
              {...input}
            />
          )}
        </Field>
      </Box>
      <Box py={3} px={mobile ? 2 : 1}>
        <Field name="pointOfDifference">
          {({ input, meta }) => (
            <TextField
              label="Describe your company's capabilities/point of difference to assist the buyer with the shortlisting process *"
              multiline
              minRows={4}
              maxRows={4}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              placeholder="e.g. we are a reseller of some of the biggest carriers in the United States, with large domestic and international capability"
              {...input}
            />
          )}
        </Field>
      </Box>
      <Box py={3} px={mobile ? 2 : 1}>
        <Box mb={1}>
          <InputLabel>
            Do you have any attachments to assist your bid?
          </InputLabel>
        </Box>
        <FileUpload
          onAdd={(file) => {
            form.mutators.push('documents', file);
          }}
        >
          <FormSpy subscription={{ values: true, pristine: true }}>
            {({ form, values }: FormSpyRenderProps<CreateBidFormState>) => {
              const documents = values.documents;
              return documents.map((document: Document, index: number) => (
                <AttachmentList
                  key={index}
                  document={document}
                  onDelete={(id: Document['id']) => {
                    deleteDocument(id);
                    form.mutators.remove('documents', index);
                  }}
                />
              ));
            }}
          </FormSpy>
        </FileUpload>
      </Box>
      <Box py={3} px={mobile ? 2 : 1}>
        <BiddingFooter />
      </Box>
    </form>
  );
};

const ServiceQuoteItem = ({
  name,
  index,
  formSize,
}: {
  name: string;
  index: number;
  formSize?: number;
}) => {
  return (
    <>
      <Field name={`${name}.service`}>
        {({ input }: FieldRenderProps<CreateBidQuote['service']>) => (
          <>
            <ServiceDetails.ExpansionPanel
              id={input.value.id}
              title={getServiceUIName(input.value.attributes.type)}
            >
              <ServiceDetails.Summary service={input.value.attributes} />
            </ServiceDetails.ExpansionPanel>
            <Box my={2}>
              {input.value.attributes.type === 'multi_service_requirements' ? (
                <TextReadMore text={input.value.attributes.additional_info} />
              ) : (
                <ServicePricing name={name} index={index} formSize={formSize} />
              )}
            </Box>
          </>
        )}
      </Field>
      <FormSpy subscription={{ values: true }}>
        {({ values }: FormSpyRenderProps<CreateBidFormState>) => {
          return (
            <Box pt={2}>
              {values.quotes[index].service.attributes.type ===
                'multi_service_requirements' && (
                <Field name={`${name}.additionalInfo`}>
                  {({ input, meta }: FieldRenderProps<CreateBidQuote>) => (
                    <TextField
                      label="Please provide pricing options/estimates *"
                      placeholder="Non-recurring costs, monthly recurring costs, terms, etc"
                      multiline
                      disabled={values.quotes[index].optOut}
                      minRows={4}
                      maxRows={4}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...input}
                    />
                  )}
                </Field>
              )}

              {values.quotes[index].service.attributes.type !==
                'multi_service_requirements' && (
                <Field name={`${name}.additionalInfo`}>
                  {({ input }: FieldRenderProps<CreateBidQuote>) => (
                    <TextField
                      label="Additional pricing information for this service. (optional)"
                      multiline
                      disabled={values.quotes[index].optOut}
                      minRows={4}
                      maxRows={4}
                      {...input}
                    />
                  )}
                </Field>
              )}
            </Box>
          );
        }}
      </FormSpy>
    </>
  );
};

export default RegisterBid;
