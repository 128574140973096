import React, { FunctionComponent } from 'react';
import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  InputAdornment,
  Grid,
  MenuItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import withFormLabel from '../WithFormLabel/WithFormLabel';
import CSSelectField from '../CSSelectField';
import { format, addHours, isToday } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
  },
  timezone: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px',
    },
  },
}));

interface CSDateTimeFieldProps extends DatePickerProps {
  value: Date;
  minDate: Date;
  disabled?: boolean;
}

const getHoursInTwelveHourFormat = (time: number, meridian: string) =>
  time === 12 ? time : meridian === 'AM' ? time : time + 12;
const getDayFromDate = (date: Date) => format(date, 'dd MMMM yyyy');
const formatDateTime = (day: string, hours: number) =>
  addHours(new Date(day), hours);

const CSDateTimeField: FunctionComponent<CSDateTimeFieldProps> = ({
  value,
  minDate,
  disabled = false,
  ...props
}) => {
  const classes = useStyles();
  const time = Number(format(value, 'h'));
  const meridian = format(value, 'a');
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={1} alignContent="flex-end" alignItems="center">
      <Grid item xs={12} sm="auto">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            className={classes.root}
            autoOk
            required
            animateYearScrolling
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            autoComplete="off"
            format="dd MMMM yyyy"
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isToday(value) && '(Today)'}
                  <DateRangeIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={(value: MaterialUiPickersDate) => {
              const date = formatDateTime(
                getDayFromDate(value as Date),
                getHoursInTwelveHourFormat(time, meridian)
              );
              props.onChange(date);
            }}
            fullWidth
            minDate={minDate}
            value={value}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs="auto">
        <CSSelectField
          value={time === 0 ? 12 : time}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            if (event.target.value !== time) {
              const date = formatDateTime(
                getDayFromDate(value),
                getHoursInTwelveHourFormat(
                  Number(event.target.value) === 12
                    ? 0
                    : Number(event.target.value),
                  meridian
                )
              );
              props.onChange(date);
            }
          }}
          displayEmpty
        >
          <MenuItem value={1}>1:00</MenuItem>
          <MenuItem value={2}>2:00</MenuItem>
          <MenuItem value={3}>3:00</MenuItem>
          <MenuItem value={4}>4:00</MenuItem>
          <MenuItem value={5}>5:00</MenuItem>
          <MenuItem value={6}>6:00</MenuItem>
          <MenuItem value={7}>7:00</MenuItem>
          <MenuItem value={8}>8:00</MenuItem>
          <MenuItem value={9}>9:00</MenuItem>
          <MenuItem value={10}>10:00</MenuItem>
          <MenuItem value={11}>11:00</MenuItem>
          <MenuItem value={12}>12:00</MenuItem>
        </CSSelectField>
      </Grid>
      <Grid item xs="auto">
        <CSSelectField
          value={meridian}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            if (event.target.value !== meridian) {
              const date = formatDateTime(
                getDayFromDate(value),
                getHoursInTwelveHourFormat(
                  time === 12 && event.target.value === 'AM' ? 0 : time,
                  event.target.value as string
                )
              );
              props.onChange(date);
            }
          }}
          displayEmpty
        >
          <MenuItem value="AM">AM</MenuItem>
          <MenuItem value="PM">PM</MenuItem>
        </CSSelectField>
      </Grid>
      <Grid item xs="auto">
        <Typography
          variant="body1"
          display="block"
          className={classes.timezone}
        >
          {Intl.DateTimeFormat().resolvedOptions().timeZone}{' '}
          {!mobile ? format(value, 'zzzz') : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default withFormLabel(CSDateTimeField);
