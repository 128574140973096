import React, { useEffect } from 'react';
import { useActingUser } from '../../hooks/useActingUser';
import { useAuth } from '../../hooks/useAuth';
import { useReadActingUserByIDQuery } from '../../services/api/acting-on-behalf/acting-user';

const AdminActingAsUser: React.FC<{
  id?: string;
  redirect?: boolean;
  onStartActing?: () => void;
}> = ({ id, redirect = false, onStartActing = () => {}, children }) => {
  const { user } = useAuth();
  const { startActing, isActing } = useActingUser();
  const { data } = useReadActingUserByIDQuery(
    { id },
    { skip: !id || !user?.csAdmin || user?.id === id || isActing }
  );

  useEffect(() => {
    if (data && !isActing) {
      startActing({
        ...data,
        options: {
          redirect,
        },
      });
      onStartActing();
    }
  }, [data, startActing, onStartActing, redirect, isActing]);

  return <>{children}</>;
};

export default AdminActingAsUser;
