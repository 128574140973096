import React, { FunctionComponent } from 'react';
import OutlinedInput, {
  OutlinedInputProps,
} from '@material-ui/core/OutlinedInput';
import WithFormLabel from '../WithFormLabel/WithFormLabel';

export type CSTextFieldProps = OutlinedInputProps;

export const TextField: FunctionComponent<Omit<OutlinedInputProps, 'css'>> = (
  props
) => {
  return <OutlinedInput fullWidth {...props} />;
};

const TextFieldWithLabel = WithFormLabel(TextField);

export default TextFieldWithLabel;
