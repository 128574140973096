import { FC, useContext } from 'react';
import tw from 'twin.macro';
import { useAuth, AuthUserType } from '../../hooks/useAuth';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../components/Cloudscene/Button';
import { FloatingAction } from '../../components/FloatingAction';
import { Caption, Typography } from '../../components/Cloudscene/Typography';
import {
  TEAM_URL,
  TEAMS_ADMIN_URL,
  ADMIN_URL,
  PRODUCT_PORTAL_URL,
} from '../../api';
import {
  MenuCard,
  MenuItem,
  MenuLink,
  MenuList,
  MenuSeparator,
} from '../Header/Menu';
import ProfileImage from '../../components/Cloudscene/ProfileImage';
import { ROUTES } from '../../routes';
import ActOnBehalf from '../../components/ActOnBehalf';
import { DropdownMenuContext } from '../../components/DropdownMenu';

const AOB = () => {
  const { closeMenu } = useContext(DropdownMenuContext);
  return (
    <ActOnBehalf>
      {({ onToggle }) => (
        <MenuItem>
          <MenuLink
            onClick={() => {
              closeMenu();
              onToggle();
            }}
          >
            Act on Behalf
          </MenuLink>
        </MenuItem>
      )}
    </ActOnBehalf>
  );
};

export const Mobile = () => {
  const { user, signinWithRedirect, isAuthenticated } = useAuth();

  return isAuthenticated && user ? (
    <UserMenu profile={user} />
  ) : (
    <SecondaryButton
      variant="ghost"
      onClick={() => {
        signinWithRedirect();
      }}
    >
      Login
    </SecondaryButton>
  );
};

export const Desktop: FC = ({ children }) => {
  const { user, signin, isAuthenticated } = useAuth();

  return isAuthenticated && user ? (
    <UserMenu profile={user}>{children}</UserMenu>
  ) : (
    <AnonymousMenu onSignin={signin}>{children}</AnonymousMenu>
  );
};

const AnonymousMenu: FC<{ onSignin: () => void }> = ({
  children,
  onSignin,
}) => {
  return (
    <div
      css={[tw`grid grid-cols-3 grid-template-columns[auto auto auto] gap-2`]}
    >
      {children}
      <PrimaryButton as="a" href={`${TEAM_URL}/register`}>
        Join
      </PrimaryButton>
      <TextButton onClick={onSignin}>Login</TextButton>
    </div>
  );
};

const UserMenu: FC<{ profile: AuthUserType }> = ({ children, profile }) => {
  const { isAdmin, hasRole } = useAuth();
  const productEnabled = hasRole([
    'CS_Admin',
    'Company_Contributor',
    'Company_Admin',
    'MP_Sales_Manager',
  ]);

  return (
    <div
      css={tw`grid grid-cols-2 grid-template-columns[1fr auto] gap-4 xl:flex`}
    >
      {children}
      <FloatingAction
        origin="bottom-end"
        action={
          <button
            type="button"
            css={[tw`flex rounded-full focus:(outline-none ring-brand ring)`]}
          >
            <ProfileImage id={profile.id} />
          </button>
        }
        trigger={['click']}
      >
        <MenuCard>
          <div css={tw`grid grid-flow-row gap-3 p-3 max-width[240px]`}>
            <img
              alt={profile.employer}
              css={[tw`object-contain h-8 max-w-full`]}
              src={profile.account_logo}
              height={32}
              width="auto"
            />
            <div>
              <Typography css={tw`font-bold`}>
                {profile.first_name} {profile.last_name}
              </Typography>
              <Caption>{profile.employer}</Caption>
            </div>
          </div>
          <MenuSeparator />
          <MenuList>
            <MenuItem>
              <MenuLink href={`${TEAM_URL}/user`}>My Profile</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink
                href={`${TEAM_URL}/company/${profile.company_account_id}/overview`}
              >
                My Company
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href={ROUTES.buyingListings}>My Marketplace</MenuLink>
            </MenuItem>
            {productEnabled && (
              <MenuItem>
                <MenuLink href={PRODUCT_PORTAL_URL}>My Products</MenuLink>
              </MenuItem>
            )}
            <MenuItem>
              <MenuLink href={`${TEAM_URL}/user/alerts`}>My Alerts</MenuLink>
            </MenuItem>
            <MenuSeparator />

            {isAdmin() && (
              <>
                <MenuLink href={ADMIN_URL}>Tools [New World]</MenuLink>
                <MenuLink href={TEAMS_ADMIN_URL}>Tools [Old World]</MenuLink>
                <AOB />
                <MenuSeparator />
              </>
            )}
            <MenuItem>
              <MenuLink href="/signout" variant="danger">
                Logout
              </MenuLink>
            </MenuItem>
          </MenuList>
        </MenuCard>
      </FloatingAction>
    </div>
  );
};
