import createDecorator from 'final-form-calculate';
import {
  addDays,
  parseISO,
  formatISO,
  differenceInCalendarDays,
} from 'date-fns';
import { CreateListingFormState } from './types';

export default createDecorator(
  {
    field: 'attributes.external',
    updates: {
      'attributes.visibility': () => 'public',
      'status.private': (fieldValue, allValues) => {
        const values = allValues as CreateListingFormState;
        return fieldValue ? false : values.status.private;
      },
    },
  },
  {
    field: 'status.private',
    updates: {
      'attributes.visibility': (fieldValue) =>
        fieldValue ? 'private' : 'public',
      'attributes.external': (fieldValue, allValues) => {
        const values = allValues as CreateListingFormState;
        return fieldValue ? false : values.attributes.external;
      },
    },
  },
  {
    field: 'attributes.start',
    updates: {
      'attributes.end': (fieldValue, allValues) => {
        const values = allValues as CreateListingFormState;
        const start = parseISO(fieldValue);
        const end = parseISO(values.attributes.end);

        return differenceInCalendarDays(end, start) < 14
          ? formatISO(addDays(start, 14))
          : values.attributes.end;
      },
    },
  },
  {
    field: 'attributes.end',
    updates: {
      'attributes.end': (fieldValue, allValues) => {
        const values = allValues as CreateListingFormState;
        const start = parseISO(values.attributes.start);
        const end = parseISO(fieldValue);

        return differenceInCalendarDays(end, start) < 3
          ? formatISO(addDays(start, 3))
          : values.attributes.end;
      },
    },
  }
);
