import { createSelector } from '@reduxjs/toolkit';
import { selectProviderEntities, selectAllProviders } from './reducer';
import { ListingResponse } from '../../types/marketplace-service/listing';

export const selectListingProvidersFromRelationship = (
  listing?: ListingResponse
) =>
  createSelector(selectAllProviders, (providers) => {
    return providers
      .filter((provider) =>
        listing?.relationships?.providers?.data.find(
          (p) => p.id === provider.id
        )
      )

      .filter((provider) => !!provider.relationships.provider.data);
  });

export const selectAllListingProvidersFromRelationship = (
  listing?: ListingResponse
) =>
  createSelector(selectAllProviders, (providers) => {
    return providers.filter((provider) =>
      listing?.relationships?.providers?.data.find((p) => p.id === provider.id)
    );
  });

export const selectExternalProvidersByProviderIds = (providerIds: string[]) =>
  createSelector(selectProviderEntities, (entities) => {
    const ids: string[] = [];
    providerIds.forEach((id) => {
      const externalId = entities[id]?.attributes.external_provider_id;
      if (externalId) {
        ids.push(externalId);
      }
    });
    return ids;
  });
