import React, { useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import CreateListing from '../../../containers/CreateListing';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import AdminActingAsUser from '../../../components/AdminActingAsUser';
import { getPageTitleTemplate } from '../../../constants';
import { ROUTES } from '../../../routes';
import { useListingInfo } from '../../../components/Listings/ListingInfo/useListingInfo';
import { useLazyReadListingByIDQuery } from '../../../services/api/marketplace-service/listings';

const EditListing: React.FunctionComponent<
  RouteComponentProps<{
    id: string;
    title: string;
  }>
> = ({ title = 'Edit Listing', navigate, id }) => {
  const { listing } = useListingInfo(id);
  const [readListingById] = useLazyReadListingByIDQuery();
  const onStartActing = useCallback(async () => {
    if (!listing?.id) return undefined;

    readListingById({
      id: listing.id,
      params: {
        include:
          'documents,markets,providers,provider-contacts,providers.listing,providers.bids,providers.bid.listing,providers.bid.documents',
      },
    });
  }, [readListingById, listing?.id]);

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        {listing && (
          <AdminActingAsUser
            id={listing.attributes.user_id}
            redirect={false}
            onStartActing={onStartActing}
          >
            <CreateListing
              pageTitle="Edit Marketplace Listing"
              onEdit={() => navigate?.(ROUTES.buyingListings)}
              listing={listing}
              action="edit"
            />
          </AdminActingAsUser>
        )}
      </AppLayout>
    </>
  );
};

export default EditListing;
