import { createContext } from 'react';
import { noop } from '../../../utilities/noop';
import type { ModalContextType } from './types.d';

export const ModalContext = createContext<ModalContextType>({
  ref: undefined,
  isOpen: false,
  openModal: noop,
  closeModal: noop,
  toggleModal: noop,
});
