import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, useMediaQuery } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { sanitizeString } from '../../utilities/sanitize-filename';
import { useLazyExportAllBidsByListingIDQuery } from '../../services/api/marketplace-service/bids';

export interface CSVQueryProps {
  listingId: string;
  title: string;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5),
    },
  })
);

const CSVQuery: React.FC<CSVQueryProps> = ({ listingId, title, disabled }) => {
  const classes = useStyles();
  const [download, setDownload] = useState<boolean>(false);
  const [downloadAllBids, { isFetching }] =
    useLazyExportAllBidsByListingIDQuery({
      selectFromResult: (response) => {
        if (!response.isFetching && response.data && download) {
          const result = response.data;
          const blob = new Blob([`${result}`], {
            type: 'text/csv;charset=utf-8',
          });
          const filename = sanitizeString(title);
          FileSaver.saveAs(blob, `${filename}.csv`);
          setDownload(false);
        }
        const isFetching = response.isFetching;
        return { isFetching };
      },
    });
  const smallScreens = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down('lg'),
    {
      noSsr: true,
    }
  );
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disabled={disabled || isFetching}
      startIcon={<GetApp />}
      className={classes.button}
      fullWidth={smallScreens ?? true}
      onClick={() => {
        setDownload(true);
        downloadAllBids({
          listing: listingId,
          allowDownload: true,
        });
      }}
    >
      Export Bids
    </Button>
  );
};

export default CSVQuery;
