import React, { useState } from 'react';
import {
  Typography,
  Button,
  ButtonProps,
  Grid,
  Divider,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface BidActionViewProps extends ButtonProps {
  onClose: (rating: number) => void;
}

const ListingRating: React.FC<BidActionViewProps> = ({ onClose, ...props }) => {
  const [rating, setRating] = useState<number | null>(5);

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid container spacing={2} item xs="auto" justifyContent="center">
        <Grid item xs="auto">
          <CheckCircleIcon color="primary" />
        </Grid>
        <Grid item xs="auto">
          <Typography component="div" variant="h6" color="textPrimary">
            Listing has been marked as complete
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Typography component="div" variant="h3" gutterBottom>
          Overall, how was your experience?
        </Typography>
        <Typography component="div" variant="body2" color="textSecondary">
          Please let us know, so we can continue to improve our service
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Rating
          name="Rating"
          value={rating}
          onChange={(event, value) => {
            setRating(value);
          }}
        />
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={!rating}
          onClick={() => {
            if (rating) {
              onClose(rating);
            }
          }}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ListingRating;
