import { createContext } from 'react';
import { noop } from '../../utilities/noop';
import type { AppMenuContextType } from './types';

export const AppMenuContext = createContext<AppMenuContextType>({
  isOpen: false,
  openAppMenu: noop,
  closeAppMenu: noop,
  toggleAppMenu: noop,
});
