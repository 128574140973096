import { CreateListingFormState, ServicesMeta } from './types';
import { CreateListingDraft } from '../../types/listing';
import { ListingService, Services } from '../../types/services';
import { NewContact, Contact } from '../../types/contact';
import { Document } from '../../types/document';
import { ListingProvider } from '../../types/provider';
import { ListingProviderContact } from '../../types/provider-contact';
import { ListingMarketRequest } from '../../types/marketplace-service/market';
import { AuthUserType } from '../../hooks/useAuth';
import { formatISO, parseISO } from 'date-fns';

export const makeContact = ({
  first_name,
  last_name,
  email,
  phone,
  position,
}: NewContact): Contact => ({
  type: 'contact',
  attributes: {
    full_name: `${first_name} ${last_name}`,
    email,
    position,
    phone,
  },
});

export const makeDocuments = (
  documents: CreateListingFormState['documents']
): Document[] => {
  return documents.map((doc): Document => {
    return {
      type: 'document',
      id: doc.value.id,
      attributes: doc.value.attributes,
    };
  });
};

export const makeListingServices: (
  services: ServicesMeta<Services>[]
) => ListingService[] = (services) => {
  return services.map(
    (serviceMeta): ListingService => ({
      type: 'listing-service',
      id: serviceMeta.value.service.id,
      attributes: serviceMeta.value.service.attributes,
    })
  );
};

export const makeListingMarkets = (
  markets: CreateListingFormState['markets']
): ListingMarketRequest[] => {
  return markets.map((market) => ({
    type: 'listing-market',
    id: market.value.id,
    relationships: {
      market: {
        data: {
          type: market.value.attributes.type,
          id: market.value.attributes.id,
        },
      },
    },
  }));
};

export const makeListingProviders = (
  providers: CreateListingFormState['providers']
): ListingProvider[] => {
  return providers.map((provider) => ({
    type: 'listing-provider',
    id: provider.value.id,
    attributes: {
      state: 'invited',
    },
    relationships: {
      provider: {
        data: {
          type: 'provider',
          id: provider.value.relationships.provider.data.id,
        },
      },
    },
  }));
};

export const makeListingProviderContacts = (
  provider_contacts: CreateListingFormState['provider_contacts']
): ListingProviderContact[] => {
  return provider_contacts.map((contact) => ({
    type: 'listing-provider-contact',
    id: contact.value.id,
    relationships: {
      'provider-contact': {
        data: {
          type: 'provider-contact',
          id: contact.value.relationships['provider-contact'].data.id,
        },
      },
    },
  }));
};

export const makeListingStateUpdate = (
  id: string,
  attributes: {
    state?: string;
    notify_unsuccessful?: boolean;
    rating?: number | null;
    user_id?: string;
    company_id?: string;
  },
  user: AuthUserType | undefined
): {
  type: 'listing';
  id: string;
  attributes: {
    state?: string;
    notify_unsuccessful?: boolean;
    rating?: number | null;
    user_id?: string;
    company_id?: string;
  };
} => {
  return {
    type: 'listing',
    id,
    attributes: {
      ...attributes,
      user_id: attributes.user_id ?? user?.id,
      company_id: attributes.company_id ?? user?.company_account_id,
    },
  };
};

export const makeListingEndDateUpdate = (
  id: string,
  attributes: {
    state: string;
    end: string;
  },
  user: AuthUserType | undefined
): {
  type: 'listing';
  id: string;
  attributes: {
    state: string;
    end: string;
    user_id?: string;
    company_id?: string;
  };
} => {
  return {
    type: 'listing',
    id,
    attributes: {
      ...attributes,
      user_id: user?.id,
      company_id: user?.company_account_id,
    },
  };
};

export const makeListingRatingUpdate = (
  id: string,
  attributes: {
    rating?: number | null;
    user_id?: string;
    company_id?: string;
  },
  user: AuthUserType | undefined
): {
  type: 'listing';
  id: string;
  attributes: {
    rating?: number | null;
    user_id?: string;
    company_id?: string;
  };
} => {
  return {
    type: 'listing',
    id,
    attributes: {
      ...attributes,
      user_id: attributes.user_id ?? user?.id,
      company_id: attributes.company_id ?? user?.company_account_id,
    },
  };
};

export const createListingDraft = ({
  id,
  attributes,
  services,
  multi_requirements,
  markets,
  providers,
  provider_contacts,
  documents,
  owner,
  status,
}: CreateListingFormState): CreateListingDraft => {
  const { start, scheduled } = attributes;
  return {
    type: 'listing',
    id,
    attributes: {
      ...attributes,
      state: attributes.state === 'draft' ? 'pending' : attributes.state,
      start:
        scheduled === 'scheduled'
          ? formatISO(parseISO(start))
          : formatISO(new Date()),
      visibility: status.private === true ? 'private' : 'public',
      contacts: [],
      contact: {
        full_name: owner.full_name,
        email: owner.email,
        phone: owner.phone,
      },
    },
    services: [
      ...makeListingServices(services),
      ...makeListingServices(multi_requirements),
    ],
    documents: makeDocuments(documents),
    markets: makeListingMarkets(markets),
    providers: makeListingProviders(providers),
    contacts: makeListingProviderContacts(provider_contacts),
  };
};

export const requestQuoteDraft = ({
  id,
  attributes,
  service,
  markets,
  providers,
  owner,
}: CreateListingFormState): CreateListingDraft => {
  return {
    type: 'listing',
    id,
    attributes: {
      ...attributes,
      start: formatISO(new Date()),
      visibility: 'private',
      contacts: [],
      contact: {
        full_name: owner.full_name,
        email: owner.email,
        phone: owner.phone,
      },
    },
    services: makeListingServices([
      {
        state: 'add',
        value: {
          service: {
            type: 'listing-service',
            attributes: service,
          } as ListingService,
          meta: {
            markets: [],
          },
        },
      },
    ]),
    documents: [],
    markets: makeListingMarkets(markets),
    providers: makeListingProviders(providers),
    contacts: [],
  };
};

export const getListingTitle = (
  action: 'create' | 'edit' | 'copy',
  title?: string
) => {
  switch (action) {
    case 'create':
      return '';

    case 'edit':
      return title ?? '';

    case 'copy':
      return `**Copy** ${title}`;
  }
};
