import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Field, FormSpy } from 'react-final-form';
import TextField from '../../../Form/CSTextField';
import { getFeatureUIName, TECH_SPECS_API_KEYS } from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    radio: { width: '100%' },
  })
);

const WavelengthAdvanced = React.memo(() => {
  const classes = useStyles();
  return (
    <Box my={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography className={classes.heading} gutterBottom>
            {getFeatureUIName(TECH_SPECS_API_KEYS.physical)}
          </Typography>
          <Field
            name="attributes.advanced.phy"
            type="radio"
            value={TECH_SPECS_API_KEYS.access_grade_enterprise}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.access_grade_enterprise}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('access_grade_enterprise')}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography className={classes.heading} gutterBottom>
            Redundancy
          </Typography>
          <Field
            name="attributes.advanced.redundancy"
            type="radio"
            value={TECH_SPECS_API_KEYS.protected}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.protected}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('protected')}
              />
            )}
          </Field>
          <Field
            name="attributes.advanced.redundancy"
            type="radio"
            value={TECH_SPECS_API_KEYS.unprotected}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.unprotected}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('unprotected')}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography className={classes.heading} gutterBottom>
            {getFeatureUIName(TECH_SPECS_API_KEYS.minimum_sla)}
          </Typography>
          <Field
            name="attributes.advanced.minimum_sla"
            type="radio"
            value={TECH_SPECS_API_KEYS.enterprise_grade_99_9}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.enterprise_grade_99_9}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('enterprise_grade_99_9')}
              />
            )}
          </Field>
          <Field
            name="attributes.advanced.minimum_sla"
            type="radio"
            value={TECH_SPECS_API_KEYS.business_grade_99_5}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.business_grade_99_5}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('business_grade_99_5')}
              />
            )}
          </Field>
          <Field
            name="attributes.advanced.minimum_sla"
            type="radio"
            value={TECH_SPECS_API_KEYS.no_sla}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.no_sla}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('no_sla')}
              />
            )}
          </Field>
        </Grid>
        <FormSpy subscription={{ pristine: true }}>
          {({ form }) => (
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => form.mutators.resetAdvanced()}
              >
                Clear Advanced Options
              </Button>
            </Grid>
          )}
        </FormSpy>
        <Grid item xs={12}>
          <Field name="additional_info">
            {({ input }) => (
              <TextField
                label="Additional Notes (Optional)"
                placeholder="e.g. We would like pricing options for 24-month and 36-month contract terms"
                multiline
                minRows={6}
                {...input}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
});

export default WavelengthAdvanced;
