import { accountServiceAPI } from './base';
import {
  EntityResponse,
  EntityCollectionResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import {
  UserResponse,
  UserResponseIncludes,
} from '../../../types/account-service/users';
import {
  transformEntityResponse,
  transformEntityCollectionResponse,
} from '../transform-response';

export const usersAPI = accountServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    readUserByID: builder.query<
      EntityResponse<UserResponse, UserResponseIncludes>,
      { id: string; params?: URLQueryParams }
    >({
      providesTags: (_result, _error, args) => [{ type: 'User', id: args.id }],
      query: (args) => ({
        url: `/users/${encodeURIComponent(args.id)}`,
        method: 'GET',
        params: args.params,
      }),
      transformResponse: transformEntityResponse,
    }),
    readAllUsers: builder.query<
      EntityCollectionResponse<UserResponse, UserResponseIncludes>,
      { params?: URLQueryParams }
    >({
      providesTags: (result, _error, _args) =>
        result?.entities.map((user) => ({ type: 'User', id: user.id })) ?? [],
      query: (args) => ({
        url: `/users`,
        method: 'GET',
        params: args.params,
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
  }),
});

export const {
  useLazyReadAllUsersQuery,
  useLazyReadUserByIDQuery,
  usePrefetch,
  useReadAllUsersQuery,
  useReadUserByIDQuery,
} = usersAPI;
