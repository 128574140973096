import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';

interface BuyerDetailsProps {
  name: string;
  phone?: string | null;
  email: string;
}

const BuyerDetails: FunctionComponent<BuyerDetailsProps> = ({
  name,
  phone,
  email,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body2">Buyer's details</Typography>
                <Typography variant="body1">{name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {phone && (
              <Typography variant="body2" align="right">
                {phone}
              </Typography>
            )}
            <Typography variant="body2" align="right">
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BuyerDetails;
