import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  Grid,
  Button,
  IconButton,
  Checkbox,
  Theme,
  useMediaQuery,
  Hidden,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PricingOptions from './PricingOptions';

interface ServicePricingProps {
  name: string;
  index: number;
  formSize?: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    removeIcon: {
      color: theme.palette.grey[500],
      [theme.breakpoints.only('xs')]: {
        margin: theme.spacing(1, 0),
      },
    },
    checkBoxInput: {
      color: theme.palette.grey[400],
    },
    checkboxLabel: {
      fontSize: 14,
      lineHeight: 1,
      color: theme.palette.grey[500],
    },
  })
);

const ServicePricing: React.FC<ServicePricingProps> = ({
  name,
  index,
  formSize,
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'), {
    noSsr: true,
  });

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            const disableFields = values.quotes[index].optOut;

            return (
              <FieldArray name={`${name}.pricing`}>
                {({ fields }) =>
                  fields.map((field, index) => (
                    <PricingOptions
                      key={index}
                      field={field}
                      showLabels
                      disableFields={disableFields}
                      index={index}
                      formSize={formSize}
                    >
                      <Hidden smUp>
                        <Divider />
                      </Hidden>
                      {index !== 0 && (
                        <IconButton
                          size="small"
                          onClick={() => fields.remove(index)}
                        >
                          <CloseIcon
                            fontSize="small"
                            className={classes.removeIcon}
                          />
                        </IconButton>
                      )}
                    </PricingOptions>
                  ))
                }
              </FieldArray>
            );
          }}
        </FormSpy>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <FormSpy subscription={{ pristine: true, values: true }}>
            {({ form, values }) => (
              <Button
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                disabled={values.quotes[index].optOut}
                onClick={() =>
                  form.mutators.addPricingOption(`${name}.pricing`)
                }
              >
                Pricing Option
              </Button>
            )}
          </FormSpy>
        </Grid>
        <Grid item xs={mobile ? 6 : 'auto'}>
          <Field name={`${name}.optOut`} type="checkbox">
            {({ input }) => (
              <FormControlLabel
                classes={{ label: classes.checkboxLabel }}
                control={
                  <Checkbox
                    name={input.name}
                    classes={{ root: classes.checkBoxInput }}
                    checked={input.checked}
                    onChange={input.onChange}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  mobile
                    ? 'We cannot provide a service'
                    : 'Unable to bid for this requirement'
                }
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicePricing;
