import { styled } from 'twin.macro';
import Button from './Button';
import * as styles from './styles';

export interface SecondaryButtonProps {
  variant?: 'default' | 'primary' | 'ghost' | 'danger';
}

export const SecondaryButton = styled(Button)<SecondaryButtonProps>(
  ({ variant = 'default' }) => [
    styles.secondary.DEFAULT,
    variant === 'primary' && styles.secondary.primary,
    variant === 'ghost' && styles.secondary.ghost,
    variant === 'danger' && styles.secondary.danger,
  ]
);
