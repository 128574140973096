import { marketplaceServiceAPI } from './base';
import {
  EntityCollectionResponse,
  EntityResponse,
  URLQueryParams,
  Pagination,
} from '../../../types/entity-collection';
import {
  ListingRegionMarketResponse,
  ListingRequest,
  ListingResponse,
  ListingResponseIncludes,
  ListingResponseMeta,
} from '../../../types/marketplace-service/listing';
import {
  transformEntityCollectionResponse,
  transformEntityResponse,
} from '../transform-response';

type ListingCacheTag = { type: 'Listing'; id: string };
export const listingsAPI = marketplaceServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    createListing: builder.mutation<
      EntityResponse<ListingResponse, ListingResponseIncludes>,
      {
        id?: string;
        payload: ListingRequest;
        params?: URLQueryParams;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Listing', id: result.entity.id },
              { type: 'Listing', id: 'LIST' },
            ]
          : [{ type: 'Listing', id: 'LIST' }],
      query: (args) => {
        return {
          url: `/listings`,
          params: args?.params,
          method: 'POST',
          body: JSON.stringify({ data: args.payload }),
        };
      },
      transformResponse: transformEntityResponse,
    }),
    readAllListings: builder.query<
      EntityCollectionResponse<
        ListingResponse,
        ListingResponseIncludes,
        { pagination: Pagination }
      >,
      { params?: URLQueryParams } | void
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<ListingCacheTag>(({ id }) => ({
                type: 'Listing',
                id,
              }))
              .concat({ type: 'Listing', id: 'LIST' })
          : [{ type: 'Listing', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/search`,
        params: args ? args?.params : undefined,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readListingByID: builder.query<
      EntityResponse<ListingResponse, ListingResponseIncludes>,
      { id: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'Listing',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.id)}`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),
    updateListingByID: builder.mutation<
      EntityResponse<ListingResponse, ListingResponseIncludes>,
      {
        id: string;
        payload: ListingRequest;
        params?: URLQueryParams;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Listing', id: result.entity.id },
              { type: 'Listing', id: 'LIST' },
            ]
          : [{ type: 'Listing', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.id)}`,
        params: args.params,
        method: 'PATCH',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    deleteListingByID: builder.mutation<void, { id: string }>({
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.id)}`,
        method: 'DELETE',
      }),
    }),
    readAvailableMarkets: builder.query<
      EntityCollectionResponse<ListingRegionMarketResponse, any>,
      { params?: URLQueryParams }
    >({
      query: (args) => ({
        url: `/listings/search-filter-region-markets`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readListingsByCompanyID: builder.query<
      EntityCollectionResponse<
        ListingResponse,
        ListingResponseIncludes,
        ListingResponseMeta
      >,
      {
        id: string;
        params?: URLQueryParams;
      }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<ListingCacheTag>(({ id }) => ({
                type: 'Listing',
                id,
              }))
              .concat({ type: 'Listing', id: 'LIST' })
          : [{ type: 'Listing', id: 'LIST' }],
      query: (args) => ({
        url: `company-accounts/${args.id}/listings`,
        params: args ? args?.params : undefined,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    cancelListingByID: builder.mutation<
      EntityResponse<ListingResponse, ListingResponseIncludes>,
      { id: string }
    >({
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.id)}/close`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCreateListingMutation,
  useDeleteListingByIDMutation,
  useLazyReadAllListingsQuery,
  useLazyReadListingByIDQuery,
  useLazyReadAvailableMarketsQuery,
  useLazyReadListingsByCompanyIDQuery,
  usePrefetch,
  useReadAllListingsQuery,
  useReadListingByIDQuery,
  useUpdateListingByIDMutation,
  useReadAvailableMarketsQuery,
  useReadListingsByCompanyIDQuery,
  useCancelListingByIDMutation,
} = listingsAPI;
