import { ReactNode } from 'react';
import tw from 'twin.macro';

export const Pill = ({
  variant,
  children,
}: {
  variant?: 'pro' | 'manager';
  children: ReactNode;
}) => {
  return (
    <div
      css={[
        tw`text-center rounded-full px-4 whitespace-pre w-min text-sm`,
        variant === 'pro' && tw`text-white bg-brand`,
        variant === 'manager' && tw`text-white bg-yellow-500`,
      ]}
    >
      {children}
    </div>
  );
};
