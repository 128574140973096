import { RequestContext, EntityType } from '../types/entity-collection';

export const isEntityType = <T extends EntityType>(
  type: T['type'],
  entity: EntityType
): entity is T => {
  return entity.type === type;
};

const reducer = (obj: any, entity: EntityType) => {
  type Key = typeof entity['type'];

  if (!entity.id) return obj;

  const ids = obj[entity.type]?.ids ?? [];
  const entities = obj[entity.type]?.entities ?? {};

  ids.push(entity.id);
  entities[entity.id] = entity;

  obj[entity.type as Key] = {
    ids,
    entities,
  };

  return obj;
};

export const getIncludedContext = <
  T extends EntityType | never,
  U extends { [key: string]: EntityType }
>(
  arr?: T[]
): T extends never ? never : RequestContext<U> => {
  return arr?.reduce(reducer, {}) ?? {};
};
