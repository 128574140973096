import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  switchWrap: {
    padding: 10,
  },
  resetBtn: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  resetBtnAssistOff: {
    color: theme.palette.grey[500],
  },
}));

type HeaderBlockProps = {
  title: string;
  onReset?: () => void;
};

const HeaderBlock = (props: HeaderBlockProps): ReactElement => {
  const classes = useStyles(props);
  const [openDialog, setOpenDialog] = useState(false);
  const { title, onReset } = props;

  return (
    <Grid container alignItems="center" className={classes.root}>
      <ConfirmDialog
        cancelAction={() => setOpenDialog(false)}
        confirmedAction={() => {
          if (onReset) {
            onReset();
          }
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Form Reset Confirmation"
        content={
          <Typography paragraph align="center">
            This action will clear all the entered data.
            <br />
            Are you sure you want to proceed?
          </Typography>
        }
      />
      <Grid item container xs={12} sm={6}>
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        justifyContent="flex-end"
        alignItems="center"
        className={classes.switchWrap}
      >
        {onReset && (
          <Button
            variant="text"
            className={clsx(classes.resetBtn, classes.resetBtnAssistOff)}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            Clear form
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderBlock;
