import React from 'react';
import { navigate, useMatch } from '@reach/router';
import ButtonGroupToggle, { ButtonGroupOption } from '../ButtonGroupToggle';
import { ROUTES } from '../../routes';
import { Theme, useMediaQuery } from '@material-ui/core';

const MarketplaceSwitch: React.FunctionComponent = () => {
  const match = useMatch(`${ROUTES.sellingDashboard}/*`);
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      <ButtonGroupToggle
        name="marketplace"
        value={match ? 'marketplace-switch-sell' : 'marketplace-switch-source'}
      >
        <ButtonGroupOption
          value="marketplace-switch-source"
          onClick={() => {
            console.log('source');
            navigate(mobile ? ROUTES.buyingDashboard : ROUTES.buyingListings);
          }}
        >
          Source
        </ButtonGroupOption>
        <ButtonGroupOption
          value="marketplace-switch-sell"
          onClick={() => {
            console.log('sell');
            navigate(
              mobile ? ROUTES.sellingDashboard : ROUTES.sellingOpportunities
            );
          }}
        >
          Sell
        </ButtonGroupOption>
      </ButtonGroupToggle>
    </>
  );
};

export default MarketplaceSwitch;
