import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ProductsStatusLabel, {
  TextProductStatusLabel,
} from '../../ProductsStatusLabel';
import {
  ProviderGatewayProducts,
  PresenceType,
} from '../../../types/provider-gateway';
import { getFeatureUIName } from '../../../constants';
import { ServicesPlaceAttributes } from '../../../types/services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      background: theme.palette.grey[200],
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1,
      padding: theme.spacing(0.5, 1),
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      position: 'relative',
      margin: theme.spacing('auto', 1, 'auto', 3),
      [theme.breakpoints.only('xs')]: {
        margin: theme.spacing('auto', 1, 'auto', 0),
      },
    },
    label: {
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    place: { fontWeight: 'bold' },
    container: {
      padding: theme.spacing(2),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(1),
      },
    },
  })
);

export interface ProductsInfoDetailsProps {
  products?: ProviderGatewayProducts;
  servicesPlaceAttributes: ServicesPlaceAttributes[];
}

interface DetailProps {
  product;
  servicesPlaceAttributes: ServicesPlaceAttributes;
}

export const Detail: React.FC<DetailProps> = ({
  product,
  servicesPlaceAttributes,
}) => {
  const classes = useStyles();
  const serviceType = servicesPlaceAttributes?.serviceType;
  if (serviceType === 'multi_service_requirements') return null;
  const servicePlaces = servicesPlaceAttributes;
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      alignContent="center"
      justifyContent="space-between"
      className={classes.container}
    >
      <>
        <Grid item xs={12} sm={4}>
          <span className={classes.chips}>{getFeatureUIName(serviceType)}</span>
          <ProductsStatusLabel
            aEndStatus={product?.aEnd as PresenceType}
            zEndStatus={product?.zEnd as PresenceType}
            azEndStatus={product?.azEnd as PresenceType}
            isColoc={serviceType === 'colocation'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="div" gutterBottom className={classes.label}>
            {serviceType === 'colocation' ? 'Location' : 'A-End'}
          </Typography>
          <Typography className={classes.place}>
            {servicePlaces?.point_a?.description}{' '}
            {serviceType !== 'colocation' &&
              product?.aEnd !== '' &&
              product?.aEnd && (
                <TextProductStatusLabel aEndStatus={product?.aEnd} />
              )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {servicePlaces?.point_z?.description && (
            <>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                B-End
              </Typography>
              <Typography className={classes.place}>
                {servicePlaces?.point_z?.description}{' '}
                {serviceType !== 'colocation' &&
                  product?.zEnd !== '' &&
                  product?.zEnd && (
                    <TextProductStatusLabel aEndStatus={product?.zEnd} />
                  )}
              </Typography>
            </>
          )}
        </Grid>
      </>
    </Grid>
  );
};

const ServiceProviderProductDetails: React.FC<ProductsInfoDetailsProps> = ({
  products,
  servicesPlaceAttributes,
}) => {
  if (!products) return null;
  return (
    <>
      {Object.entries(servicesPlaceAttributes).map(([key, value]) => {
        return (
          <Detail
            key={key}
            product={products[key]}
            servicesPlaceAttributes={value}
          />
        );
      })}
    </>
  );
};

export default ServiceProviderProductDetails;
