import { object, string, number, boolean } from 'yup';
import { TECH_SPECS_API_KEYS } from '../../../../constants';

export const schema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  // external_service_name: string(),
  // external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({
      value: number().required().min(1),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    advanced: object({
      access_type: string().nullable().label('Access Type'),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.q_in_q]: boolean(),
        [TECH_SPECS_API_KEYS.jumbo_frames]: boolean(),
        [TECH_SPECS_API_KEYS.encryption]: boolean(),
      }),
      minimum_sla: string().nullable().label('Minimum SLA'),
      hand_off: string().nullable().label('Hand Off'),
    }),
  }).defined(),
}).defined();

export default schema;
