import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { uniqBy } from 'lodash';
import { RegionMarket } from '../../types/region-market';
import { ListingRegionMarketResponse } from '../../types/marketplace-service/listing';

const useStyles = makeStyles((theme) => ({
  regionMarketsContainer: {
    marginLeft: theme.spacing(4),
  },
}));

const getMarketIdsFromRegion = (regionMarket: RegionMarket): string[] => {
  return regionMarket.attributes.markets.map((market) => market.id);
};

const unselectAllRegionMarkets = (
  selectedMarkets: string[],
  regionMarket: RegionMarket
): string[] => {
  return selectedMarkets.filter(
    (selectedMarket) =>
      regionMarket.id !== selectedMarket &&
      !regionMarket.attributes.markets.find(
        (market) => selectedMarket === market.id
      )
  );
};

const MarketFilters: React.FC<{
  regionMarkets?: ListingRegionMarketResponse[];
  selectedMarkets: string[];
  setSelectedMarkets: (selectedMarkets: string[]) => void;
}> = ({ selectedMarkets, setSelectedMarkets, regionMarkets }) => {
  const classes = useStyles();
  const [openMarkets, setOpenMarkets] = useState<string[]>([]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="AllMarkets"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedMarkets([]);
                }
              }}
              checked={!selectedMarkets.length}
              color="primary"
            />
          }
          label="All Markets"
        />
      </Grid>
      {!!regionMarkets?.length && (
        <Grid item xs={12}>
          <Box my={1}>
            <Divider />
          </Box>
        </Grid>
      )}
      {regionMarkets?.map((regionMarket) => {
        return (
          <Grid container alignItems="center" key={regionMarket.id}>
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedMarkets([
                          ...uniqBy(
                            [
                              ...selectedMarkets,
                              regionMarket.id,
                              ...getMarketIdsFromRegion(regionMarket),
                            ],
                            (id) => id
                          ),
                        ]);
                      } else {
                        setSelectedMarkets([
                          ...unselectAllRegionMarkets(
                            selectedMarkets,
                            regionMarket
                          ),
                        ]);
                      }
                    }}
                    checked={selectedMarkets.includes(regionMarket.id)}
                    color="primary"
                    name={regionMarket.id}
                    data-testid={regionMarket.id}
                  />
                }
                label={regionMarket.attributes.name}
              />
            </Grid>
            <Grid item>
              {regionMarket.attributes.markets.length > 0 && (
                <IconButton
                  onClick={() => {
                    if (openMarkets.includes(regionMarket.id)) {
                      setOpenMarkets([
                        ...openMarkets.filter(
                          (openMarket) => openMarket !== regionMarket.id
                        ),
                      ]);
                    } else {
                      setOpenMarkets([...openMarkets, regionMarket.id]);
                    }
                  }}
                  size="small"
                >
                  {openMarkets.includes(regionMarket.id) ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              )}
            </Grid>
            {regionMarket.attributes.markets && (
              <Grid item container className={classes.regionMarketsContainer}>
                <Collapse in={openMarkets.includes(regionMarket.id)}>
                  {regionMarket.attributes.markets.map((market) => {
                    return (
                      <Grid key={market.id} item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={selectedMarkets.includes(market.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // if all other child markets are selected already
                                  if (
                                    getMarketIdsFromRegion(regionMarket)
                                      .filter(
                                        (marketId) => marketId !== market.id
                                      )
                                      .every((marketId) =>
                                        selectedMarkets.includes(marketId)
                                      )
                                  ) {
                                    setSelectedMarkets([
                                      ...selectedMarkets,
                                      market.id,
                                      regionMarket.id,
                                    ]);
                                  } else {
                                    setSelectedMarkets([
                                      ...selectedMarkets,
                                      market.id,
                                    ]);
                                  }
                                } else {
                                  if (
                                    selectedMarkets.includes(regionMarket.id)
                                  ) {
                                    setSelectedMarkets([
                                      // remove region and all markets
                                      ...unselectAllRegionMarkets(
                                        selectedMarkets,
                                        regionMarket
                                      ),
                                      // reselect the other markets
                                      ...getMarketIdsFromRegion(
                                        regionMarket
                                      ).filter(
                                        (marketId) => marketId !== market.id
                                      ),
                                    ]);
                                  } else {
                                    setSelectedMarkets(
                                      selectedMarkets.filter(
                                        (selectedMarket) =>
                                          selectedMarket !== market.id
                                      )
                                    );
                                  }
                                }
                              }}
                              color="primary"
                              name={market.id}
                              data-testid={market.id}
                            />
                          }
                          label={market.name}
                        />
                      </Grid>
                    );
                  })}
                </Collapse>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MarketFilters;
