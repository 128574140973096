import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { isValidElement } from 'react';

interface ButtonGroupToggleProps {
  name: string;
  value: string;
  className?: string;
  onChange?: (
    event: React.SyntheticEvent<HTMLInputElement>,
    value: string
  ) => void;
  children: React.ReactElement[];
}

const useButtonGroupStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f6f9',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'stretch',
    minHeight: '100%',
    borderRadius: 8,
  },
  first: {
    borderRight: 0,
    borderRadius: 8,
    fontSize: 16,

    '&:after': {
      left: '100%',
    },
  },
  second: {
    borderLeft: 0,
    borderRadius: 8,
    fontSize: '12px',

    '&:after': {
      left: '-100%',
    },
  },
}));

const ButtonGroupToggle: React.FC<ButtonGroupToggleProps> = ({
  name,
  value,
  className,
  onChange,
  children = [],
}) => {
  const classes = useButtonGroupStyles();

  if (children.length !== 2) {
    console.error('`ButtonGroupToggle` accepts exactly two children');
    return <></>;
  }

  return (
    <Box className={clsx(classes.root, className)}>
      {React.Children.map(children, (child, index) =>
        isValidElement(child)
          ? React.cloneElement(child, {
              name,
              checked: child.props.value === value,
              className: clsx({
                [classes.first]: index === 0,
                [classes.second]: index === 1,
              }),
              ...(onChange ? { onClick: onChange } : undefined),
            })
          : null
      )}
    </Box>
  );
};

export default ButtonGroupToggle;
