import { FC } from 'react';
import ListingStatusLabel from '../ListingStatusLabel';

interface LeadStatusProps {
  state: 'APPROVED' | 'ASSIGNED' | 'ACTIONED';
}

const LeadStatus: FC<LeadStatusProps> = ({ state }) => {
  switch (state) {
    case 'APPROVED':
      return null;
    case 'ASSIGNED':
      return <ListingStatusLabel variant="assigned" />;
    case 'ACTIONED':
      return <ListingStatusLabel variant="actioned" />;
  }
};

export default LeadStatus;
