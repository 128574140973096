import {
  useState,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import tw from 'twin.macro';
import { useDebounce } from '../../hooks/useDebounce';
import { isFunction } from '../../utilities/is-function';
import { DropdownMenuContext } from './context';
import type { DropdownMenuProps } from './types';

export const DropdownMenu = ({
  action,
  children,
  tooltip,
  trigger = ['click'],
}: DropdownMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const toggleMenu = useCallback(
    (value?: boolean) => {
      value === undefined ? setOpen((open) => !open) : setOpen(value);
    },
    [setOpen]
  );

  const hideOnEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        closeMenu();
      }
    },
    [open, closeMenu]
  );

  const isOpen = useDebounce(open, open ? 0 : 200);

  useEffect(() => {
    document.addEventListener('keydown', hideOnEsc, false);

    return () => {
      document.removeEventListener('keydown', hideOnEsc, false);
    };
  }, [hideOnEsc]);

  return (
    <DropdownMenuContext.Provider
      value={{ isOpen, openMenu, closeMenu, toggleMenu }}
    >
      <ClickAwayListener onClickAway={closeMenu}>
        <div
          css={[tw`relative`]}
          onMouseEnter={() => trigger.includes('hover') && openMenu()}
          onMouseLeave={() => trigger.includes('hover') && closeMenu()}
        >
          {isValidElement(action) &&
            cloneElement(
              isValidElement(tooltip) ? tooltip : <></>,
              undefined,
              cloneElement(action, {
                'aria-expanded': isOpen,
                'aria-haspopup': true,
                type: 'button',
                onClick: () => trigger.includes('click') && toggleMenu(),
              })
            )}

          {isFunction(children)
            ? children({ isOpen, openMenu, closeMenu, toggleMenu })
            : children}
        </div>
      </ClickAwayListener>
    </DropdownMenuContext.Provider>
  );
};
