import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMounted } from './useMounted';

const useDebouncedFunction = (cb: any, delay: number = 300) => {
  const inputsRef = useRef(cb); // mutable ref like with useThrottle
  const isMounted = useMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  }); //also track cur. delay

  const debouncedHandler = useMemo(
    () =>
      debounce((...args: any) => {
        // Debounce is an async callback. Cancel it, if in the meanwhile
        // (1) component has been unmounted (see isMounted in snippet)
        // (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted)
          inputsRef.current.cb(...args);
      }, delay),
    [delay, isMounted]
  );

  return useCallback(
    (...args) => debouncedHandler(...args),
    [debouncedHandler]
  );
};

export default useDebouncedFunction;
