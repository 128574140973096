import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { marketplaceMatchByAddress } from './actions';
import { RootState } from '../types.d';
import { MarketplaceMatchResults } from '../../types/marketplace-match';

const MarketplaceMatchAdapter = createEntityAdapter<MarketplaceMatchResults>({
  selectId: (marketplaceMatchResults) => marketplaceMatchResults.id!,
});

const marketplaceMatch = createSlice({
  name: 'marketplace-match',
  initialState: MarketplaceMatchAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(marketplaceMatchByAddress.fulfilled, (state, action) => {
      MarketplaceMatchAdapter.upsertMany(state, action.payload.data);
    });
  },
});

export const {
  selectById: selectMarketplaceMatchById,
  selectAll: selectAllMarketplaceMatch,
  selectTotal: selectMarketplaceMatch,
} = MarketplaceMatchAdapter.getSelectors(
  (state: RootState) => state.marketplaceMatch
);

export { marketplaceMatchByAddress };

export default marketplaceMatch.reducer;
