import { object, string, number, boolean } from 'yup';
import { TECH_SPECS_API_KEYS } from '../../../../constants';

export const schema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    cores: number().nullable().required().label('Cores'),
    advanced: object({
      interface_type: string().nullable().label('Interface Type'),
      diversity: object({
        [TECH_SPECS_API_KEYS.building_lead_in]: boolean(),
        [TECH_SPECS_API_KEYS.street_geo]: boolean(),
      }).label('Diversity'),
      mttr: string().nullable().label('MTTR'),
    }),
  }).defined(),
}).defined();

export default schema;
