import { useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';
import TextField from '../Form/CSTextField';
import { Market } from '../../types/market';
import { useAppState } from '../../contexts/AppState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    endAdornment: {
      top: 'calc(50% - 16px)',
      right: theme.spacing(1),
    },
  })
);

interface MarketsAutocompleteProps {
  label?: string;
  limit?: number;
  onChange: (value: Market[]) => void;
  selectedMarkets: Market[];
  InputProps?: {};
  onDelete?: (index: number) => void;
}
const MarketsAutocomplete = ({
  InputProps = {},
  onChange,
  selectedMarkets,
  label,
  limit = 2,
  onDelete = () => {},
  ...props
}: MarketsAutocompleteProps) => {
  const classes = useStyles();
  const appState = useAppState();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const markets: Market[] = useMemo(
    () =>
      Object.values(appState.markets).sort((a, b) => {
        if (a.type === b.type) {
          return a.name.localeCompare(b.name);
        }
        if (a.type === 'zone') {
          return -1;
        }
        return 1;
      }),
    [appState.markets]
  );

  return (
    <Autocomplete
      classes={classes}
      filterOptions={createFilterOptions({
        limit: 50,
        trim: true,
      })}
      multiple
      inputValue={searchTerm}
      onChange={(_, value) => {
        if (value?.every((item): item is Market => typeof item !== 'string')) {
          onChange(value);
          setSearchTerm('');
        }
      }}
      openOnFocus={false}
      getOptionLabel={(market: Market) => {
        if (market.name === market.zone) {
          return market.name;
        }
        return market.name + (', ' + market.zone);
      }}
      renderTags={(value, getTagProps) => {
        if (!label) return false;
        return value.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            onDelete={() => {
              value.splice(index, 1);
              onDelete(index);
            }}
          />
        ));
      }}
      options={selectedMarkets.length < limit || !label ? markets : []}
      renderInput={({
        id,
        InputProps: { ref, className, ...InputProps },
        inputProps,
      }) => (
        <TextField
          label={label}
          id={id}
          onChange={(event) => {
            if (selectedMarkets.length < limit || !label)
              setSearchTerm(event.target.value);
          }}
          inputProps={inputProps}
          innerRef={ref}
          {...InputProps}
          onKeyDown={(event: any) => {
            if (!label) return false;
            if (event.key === 'Backspace' || selectedMarkets.length < limit) {
              event.stopPropagation();
            }
          }}
        />
      )}
      {...props}
    />
  );
};

export default MarketsAutocomplete;
