import { Typography, Paper, Grid, Avatar } from '@material-ui/core/';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Market } from '../../types/market';
import { useAppState } from '../../contexts/AppState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.25, 3),
      borderWidth: 2,
      borderRadius: 6,
      minWidth: 200,
    },
    avatar: {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    label: {
      lineHeight: 1.3,
    },
  })
);

export interface MarketInfoDisplayProps extends Market {}

export const MarketInfoDisplay = ({
  name,
  zone,
  countryCode,
  flag,
  type,
}: MarketInfoDisplayProps) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs="auto">
          <Avatar src={flag} alt={countryCode} className={classes.avatar}>
            {countryCode}
          </Avatar>
        </Grid>
        <Grid item xs>
          {type !== 'zone' && (
            <Typography variant="body2" className={classes.label}>
              {name}
            </Typography>
          )}
          <Typography variant="body1" className={classes.label}>
            {zone}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const MarketInfo = ({ id }: { id: string }) => {
  const { markets } = useAppState();

  return markets[id] ? <MarketInfoDisplay {...markets[id]} /> : null;
};

export default MarketInfo;
