import {
  createEntityAdapter,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  fetchAllBidPricingOptions,
  fetchBidPricingOptionById,
} from './actions';
import { BidPricingOption } from '../../../types/bid';
import { RootState } from '../../types.d';

const bidPricingOptionsAdapter = createEntityAdapter<BidPricingOption>({
  selectId: (pricingOption) => pricingOption.id!,
});

const bidPricingOptions = createSlice({
  name: 'bid-pricing-options',
  initialState: bidPricingOptionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    // FETCH ALL
    builder
      .addCase(fetchAllBidPricingOptions.pending, (state, _action) => {
        bidPricingOptionsAdapter.removeAll(state);
      })
      .addCase(fetchAllBidPricingOptions.fulfilled, (state, action) => {
        bidPricingOptionsAdapter.addMany(state, action.payload.data);
      })
      .addCase(fetchAllBidPricingOptions.rejected, (state, action) => {
        bidPricingOptionsAdapter.removeAll(state);
      });

    // FETCH BY ID
    builder
      .addCase(fetchBidPricingOptionById.pending, (state, action) => {
        bidPricingOptionsAdapter.removeAll(state);
      })
      .addCase(fetchBidPricingOptionById.fulfilled, (state, action) => {
        bidPricingOptionsAdapter.addOne(state, action.payload.data);
      })
      .addCase(fetchBidPricingOptionById.rejected, (state, action) => {
        bidPricingOptionsAdapter.removeAll(state);
      });
  },
});

export const {
  selectById: selectBidPricingOptionById,
  selectIds: selectBidPricingOptionIds,
  selectEntities: selectBidPricingOptionEntities,
  selectAll: selectAllBidPricingOptions,
  selectTotal: selectTotalBidPricingOptions,
} = bidPricingOptionsAdapter.getSelectors(
  (state: RootState) => state.bidPricingOptions
);

export const selectBidPricingOptionsByBidServiceId = (id: string) => {
  return createSelector(selectAllBidPricingOptions, (options) => {
    if (!id) {
      return [];
    }

    return options.filter(
      (option) => option.relationships['bid-service']?.data.id === id
    );
  });
};

export default bidPricingOptions.reducer;
