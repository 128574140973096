import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';

interface CancelListingConfirmationProps {
  submitting: boolean;
  onConfirm: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: theme.palette.error.main,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    primaryButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    button: {
      margin: '0 auto',
    },
  })
);

const CancelListingConfirmation: React.FC<CancelListingConfirmationProps> = ({
  submitting,
  onConfirm,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cancelListing = () => {
    setLoading(true);
    onConfirm();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        className={classes.cancelButton}
        fullWidth
        disabled={submitting}
      >
        Cancel Listing
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Cancel Listing
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to cancel this listing? This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${classes.button} ${classes.primaryButton}`}
            onClick={cancelListing}
            disabled={submitting || loading}
            endIcon={
              (submitting || loading) && (
                <CircularProgress color="secondary" size={20} />
              )
            }
            variant="contained"
            autoFocus
          >
            Cancel Listing
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelListingConfirmation;
