import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types.d';
import { API_BASE_URL } from '../../api';
import { Document } from '../../types/document';

export const createDocument = createAsyncThunk<
  {
    data: Document[];
  },
  { listing: string; document: Document },
  { extra: ThunkExtra }
>(`document/create`, async ({ listing, document }, { extra: { axios } }) => {
  const response = await axios.patch(
    `${API_BASE_URL}/listings/${listing}/relationships/documents`,
    {
      data: [document],
    }
  );

  return response.data;
});
export const fetchDocumentById = createAsyncThunk<
  { data: Document },
  { id: string },
  { extra: ThunkExtra }
>(`document/fetchById`, async ({ id }, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/documents/${id}`);
  return response.data;
});

export const updateDocumentById = createAsyncThunk<
  { data: Document },
  { listing: string; document: Document },
  { extra: ThunkExtra }
>(`document/update`, async ({ listing, document }, { extra: { axios } }) => {
  const response = await axios.patch(
    `${API_BASE_URL}/listings/${listing}/documents/${document.id!}`,
    { data: document }
  );

  return response.data;
});

export const deleteDocumentById = createAsyncThunk<
  { id: string },
  { document: string },
  { extra: ThunkExtra }
>(`document/delete`, async ({ document }, { extra: { axios } }) => {
  await axios.delete(`${API_BASE_URL}/documents/${document}`);

  return { id: document };
});
