import { createContext, useCallback } from 'react';
import { RedirectLoginOptions, useAuth0, User } from '@auth0/auth0-react';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import memoize from 'memoize-one';
import { dequal } from 'dequal/lite';
import { ActingUserType } from '../store/acting/types';
import { useActingUser } from './useActingUser';
import { hasIntersection } from '../utilities/arrays';

export type CompanyPackageType = 'FREE' | 'STARTER' | 'PRO';
export type CompanyPackage = 'fre' | 'sta' | 'pro';

export const COMPANY_PACKAGES: Record<CompanyPackageType, CompanyPackage> = {
  FREE: 'fre',
  STARTER: 'sta',
  PRO: 'pro',
};

export const USER_ROLES = {
  CS_ADMIN: 'CS_Admin',
  COMPANY_ADMIN: 'Company_Admin',
  COMPANY_CONTRIBUTOR: 'Company_Contributor',
  SALES_MANAGER: 'MP_Sales_Manager',
  SALES_PRO: 'MP_Sales_Pro',
  PROCUREMENT_MANAGER: 'MP_Procurement',
} as const;

export type AuthUserType = {
  permissions: string[];
  roles: string[];
  first_name: string;
  last_name: string;
  id: string;
  phone: string;
  position: string;
  employer: string;
  company_account_id: string;
  account_logo: string;
  companies: string[];
  companyPackage: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  sub: string;
  csAdmin: boolean;
};

export type Auth0PropType = {
  'https://cloudscene/roles': string[];
  'https://cloudscene/phone': string;
  'https://cloudscene/position': string;
  'https://cloudscene/employer': string;
  'https://cloudscene/acc': string;
  'https://cloudscene/acc_logo': string;
  'https://cloudscene/companies': string[];
  'https://cloudscene/email': string;
  'https://cloudscene/first_name': string;
  'https://cloudscene/last_name': string;
  'https://cloudscene/user_id': string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  sub: string;
};

export type AuthContextType = {
  isAuthenticated: boolean;
  user: AuthUserType | undefined;
  signin: (options?: RedirectLoginOptions) => void;
  signout: () => void;
  hasRole: (role: string | string[]) => boolean;
  hasCompany: (company: string | string[]) => boolean;
  hasPermission: (permission: string | string[]) => boolean;
  hasCompanyPackage: (pkg?: string | string[]) => boolean;
  isSynced: () => boolean;
  isAdmin: () => boolean;
  silentlyRefreshToken: (args?: GetTokenSilentlyOptions) => Promise<string>;
  signinWithRedirect: (options?: RedirectLoginOptions) => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

const makeUserProfile = (user?: User): AuthUserType | undefined => {
  if (!user) return undefined;

  return {
    roles: user['https://cloudscene/roles'],
    first_name: user['https://cloudscene/first_name'],
    last_name: user['https://cloudscene/last_name'],
    id: user['https://cloudscene/user_id'],
    phone: user['https://cloudscene/phone'],
    position: user['https://cloudscene/position'],
    employer: user['https://cloudscene/employer'],
    company_account_id: user['https://cloudscene/acc'],
    companies: user['https://cloudscene/companies'],
    account_logo: user['https://cloudscene/acc_logo'],
    companyPackage: user['https://cloudscene/pac'] ?? COMPANY_PACKAGES.FREE,
    name: user.name ?? '',
    email: user.email ?? '',
    nickname: user.nickname ?? '',
    picture: user.picture ?? '',
    updated_at: user.updated_at ?? '',
    sub: user.sub ?? '',
    permissions: user.permissions ?? [],
    csAdmin: user['https://cloudscene/roles']?.includes('CS_Admin'),
  };
};

const makeActingProfile = (user?: ActingUserType): AuthUserType | undefined => {
  if (!user) return undefined;

  return {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    position: user.position,
    company_account_id: user.account,
    companies: user.companies,
    companyPackage: user.companyPackage,
    roles: user.roles,
    employer: user.employer,
    account_logo: user.logo,
    nickname: '',
    name: `${user.first_name} ${user.last_name}`,
    picture: '',
    updated_at: '',
    sub: '',
    permissions: user.permissions,
    csAdmin: user.roles.includes('CS_Admin'),
  };
};

const getUserProfile = memoize(makeUserProfile, dequal);
const getActingProfile = memoize(makeActingProfile, dequal);

export const useAuth = (): AuthContextType => {
  const {
    isAuthenticated,
    user,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const { actingUser, isActing } = useActingUser();
  const profile = isActing
    ? getActingProfile(actingUser)
    : getUserProfile(user);

  const signin = useCallback(
    (options?: RedirectLoginOptions) =>
      options ? loginWithRedirect(options) : loginWithRedirect(),
    [loginWithRedirect]
  );
  const signout = useCallback(
    () =>
      logout({
        logoutParams: {
          returnTo: process.env.REACT_APP_SIGNOUT_URL,
        },
      }),
    [logout]
  );

  const isSynced = useCallback(() => !!profile?.company_account_id, [profile]);
  const isAdmin = useCallback(() => !!profile?.csAdmin, [profile]);
  const hasRole = useCallback(
    (role: string | string[]) => hasIntersection(role, profile?.roles),
    [profile?.roles]
  );
  const hasPermission = useCallback(
    (permission: string | string[]) =>
      hasIntersection(permission, profile?.permissions),
    [profile?.permissions]
  );
  const hasCompany = useCallback(
    (company: string | string[]) =>
      hasIntersection(company, profile?.companies),
    [profile]
  );
  const hasCompanyPackage = useCallback(
    (pkg?: string | string[]) =>
      [pkg].flat().some((item) => item === profile?.companyPackage),
    [profile?.companyPackage]
  );

  return {
    isAuthenticated,
    user: profile,
    signout,
    signin,
    hasRole,
    hasCompany,
    hasPermission,
    hasCompanyPackage,
    isAdmin,
    isSynced,
    silentlyRefreshToken: getAccessTokenSilently,
    signinWithRedirect: loginWithRedirect,
  };
};
