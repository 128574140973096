import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MarketPlaceMatchInfoLabel from './MarketPlaceMatchInfoLabel';
import { MPMatchForMarketsLabel } from '../../constants';
import { PhysicalAddress, ServiceTypeKeys } from '../../types/services';
import { isMarketOption } from '../../utilities/services';

interface MarketPlaceMatchInfoProps {
  aEnd?: PhysicalAddress | null;
  serviceType: ServiceTypeKeys;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#9E9E9E',
      fontSize: 14,
      fontWeight: 'bold',
      padding: theme.spacing(1, 0),
    },
  })
);

const MarketPlaceMatchInfo: React.FC<MarketPlaceMatchInfoProps> = ({
  aEnd,
  serviceType,
}) => {
  const classes = useStyles();

  if (!aEnd) {
    return null;
  }

  if (isMarketOption(aEnd) && aEnd.marketType === 'zone') {
    return (
      <Typography className={classes.label} component="div">
        {MPMatchForMarketsLabel}
      </Typography>
    );
  } else {
    return <MarketPlaceMatchInfoLabel aEnd={aEnd} serviceType={serviceType} />;
  }
};

export default MarketPlaceMatchInfo;
