import React, { useCallback } from 'react';
import { useAppMessenger } from '../../store/messenger';
import { Dialog, DialogContent } from '@material-ui/core';
import { useAuth } from '../../hooks/useAuth';
import { useUpdateListingByIDMutation } from '../../services/api/marketplace-service/listings';
import ListingRating from '../ListingRating';
import { useNavigate } from '@reach/router';

interface EndListingProps {
  open?: boolean;
  listingId: string;
  userId: string;
  companyId: string;
  onClose: () => void;
}

const CompleteListing: React.FC<EndListingProps> = ({
  open = false,
  onClose,
  listingId,
  userId,
  companyId,
}) => {
  const messenger = useAppMessenger();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [updateListingByID] = useUpdateListingByIDMutation();
  const handleListingUpdate = useCallback(
    async (rating) => {
      return await updateListingByID({
        id: listingId,
        payload: {
          id: listingId,
          type: 'listing',
          attributes: {
            state: 'completed',
            rating: rating,
            user_id: userId ?? user?.id,
            company_id: companyId ?? user?.company_account_id,
          },
        },
      })
        .unwrap()
        .then(() => {
          messenger.addMessage(
            'info',
            'This listing has been marked as complete.'
          );
          onClose();
        });
    },
    [updateListingByID, companyId, listingId, messenger, onClose, userId, user]
  );

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <ListingRating
            onClose={(rating) => {
              if (rating) {
                handleListingUpdate(rating).then(() => {
                  navigate?.(`/my-marketplace/buying/completed`);
                });
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompleteListing;
