import React from 'react';
import {
  Chip,
  LinearProgress,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/';
import LoopIcon from '@material-ui/icons/Loop';

export interface GatewayProgressBarProps {
  isLoading: boolean;
  gatewayProvidersCount: number;
  gatewayProvidersState: boolean;
  onRefresh: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(2),
    },
  })
);
export const GatewayProgressBar: React.FC<GatewayProgressBarProps> = ({
  isLoading,
  gatewayProvidersCount,
  gatewayProvidersState,
  onRefresh,
}) => {
  const classes = useStyles();
  if (!isLoading && gatewayProvidersCount > 0 && !gatewayProvidersState) {
    return (
      <>
        <Typography variant="caption" color="textPrimary">
          Refresh to see available live data
        </Typography>
        <Chip
          className={classes.chip}
          icon={<LoopIcon />}
          label="Refresh"
          clickable
          color="primary"
          onClick={() => onRefresh()}
        />
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        <Typography variant="caption" color="textPrimary">
          We're ensuring you have the most up-to-date live service provider data
        </Typography>
        <LinearProgress />
      </>
    );
  }
  return null;
};
export default GatewayProgressBar;
