import { marketplaceServiceAPI } from './base';
import {
  EntityCollectionResponse,
  EntityResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import {
  transformEntityCollectionResponse,
  transformEntityResponse,
} from '../transform-response';
import {
  ListingMarketRequest,
  ListingMarketResponse,
  ListingMarketResponseIncludes,
} from '../../../types/marketplace-service/market';

type ListingMarketCacheTag = { type: 'ListingMarket'; id: string };

export const marketsAPI = marketplaceServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    createListingMarket: builder.mutation<
      EntityResponse<ListingMarketResponse, ListingMarketResponseIncludes>,
      {
        listing: string;
        payload: ListingMarketRequest;
        params?: URLQueryParams;
      }
    >({
      query: (args) => {
        return {
          url: `/listings/${encodeURIComponent(args.listing)}/markets`,
          params: args?.params,
          method: 'POST',
          body: JSON.stringify({ data: args.payload }),
        };
      },
      transformResponse: transformEntityResponse,
    }),
    readAllListingMarketsByListingID: builder.query<
      EntityCollectionResponse<
        ListingMarketResponse,
        ListingMarketResponseIncludes
      >,
      { listing: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<ListingMarketCacheTag>(({ id }) => ({
                type: 'ListingMarket',
                id,
              }))
              .concat({ type: 'ListingMarket', id: 'LIST' })
          : [{ type: 'ListingMarket', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.listing)}/markets`,
        params: args ? args?.params : undefined,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readListingMarketByID: builder.query<
      EntityResponse<ListingMarketResponse, ListingMarketResponseIncludes>,
      { listing: string; market: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'ListingMarket',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(
          args.listing
        )}/markets/${encodeURIComponent(args.market)}`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),
    deleteListingMarketByID: builder.mutation<
      void,
      { listing: string; market: string }
    >({
      query: (args) => ({
        url: `/listings/${encodeURIComponent(
          args.listing
        )}/markets/${encodeURIComponent(args.market)}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateListingMarketMutation,
  useLazyReadAllListingMarketsByListingIDQuery,
  useLazyReadListingMarketByIDQuery,
  useReadAllListingMarketsByListingIDQuery,
  useReadListingMarketByIDQuery,
  useDeleteListingMarketByIDMutation,
} = marketsAPI;
