import React, { FunctionComponent, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import Invitation from '../containers/Signup/Invitation';
import { getPageTitleTemplate, SIGNUP_URL } from '../constants';
import axios from '../services/axios';
import { API_BASE_URL } from '../api';
import { Listing } from '../types/listing';
import { useAuth0 } from '@auth0/auth0-react';
import FullScreenLoader from '../components/FullScreenLoader';
import { ROUTES } from '../routes';
import { Typography } from '@material-ui/core';
import { addMessage } from '../store/messenger';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/types.d';
import { filterRelationshipEntityType } from '../utilities/relationships';

const DirectInvitePage: FunctionComponent<
  RouteComponentProps<{
    code: string;
  }>
> = ({ code }) => {
  const title = 'Invited Opportunity';
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch: AppDispatch = useDispatch();

  const onLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };
  const onJoin = () => {
    // TEMPORARY: should be removed later, see SignupPage/index.tsx
    window.localStorage.setItem('returnUrl', window.location.href);
    if (SIGNUP_URL)
      window.location.href = `${SIGNUP_URL}?return_url=${window.location.href}`;
  };

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(`${API_BASE_URL}/listing-invitation/${code}`)
        .then((resp) => {
          const [listing] = filterRelationshipEntityType<Listing>(
            'listing',
            resp.data.included
          );
          navigate(
            `${ROUTES.sellingOpportunities}/${listing.attributes.short_id}`,
            { replace: true }
          );
        })
        .catch(() => {
          dispatch(addMessage('error', `Error accepting invitation`));
          navigate(ROUTES.root, {
            replace: true,
          });
        });
    }
  }, [isAuthenticated, code, dispatch]);

  if (isAuthenticated) {
    return <FullScreenLoader />;
  }
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <Invitation
        body={
          <Typography>
            As an approved vendor for a customer of yours who's using Cloudscene
            Marketplace for network procurement, you've been invited to provide
            a quote for their service requirements. You can log in or create a
            free account to view and respond to your customer's Marketplace
            listing.
          </Typography>
        }
        header={
          <Typography variant="h1">
            You've been invited to quote one of your customers.
          </Typography>
        }
        onLogin={onLogin}
        onJoin={onJoin}
      />
    </>
  );
};

export default DirectInvitePage;
