import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Paper,
  Hidden,
  Link,
  Typography,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ServiceTypes } from '../../types/services';

interface TileProps {
  type: {
    type: string;
    key: ServiceTypes;
    description: string;
  };
  setView: (x: 'buttons' | 'form') => void;
  setService: (ServiceTypeKeys: ServiceTypes) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    paper: {
      alignItems: 'center',
      border: 'none',
      boxShadow: 'none',
      padding: theme.spacing(3),
      transition: 'box-shadow 100ms ease',
      position: 'relative',
      paddingBottom: '64px',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        border: `1px solid ${theme.palette.grey[100]}`,
        boxShadow: theme.shadows[4],
        paddingBottom: '0px',
      },
      '&:hover': {
        borderRadius: 8,
        borderColor: theme.palette.grey[100],
        boxShadow: theme.shadows[5],
        zIndex: 1,
      },
    },
    spacingBottom: {
      paddingBottom: theme.spacing(3),
    },
    tile: {
      display: 'flex',
    },
    buttonLabel: {
      justifyContent: 'left',
      bottom: '16px',
      left: '24px',
      position: 'absolute',
      width: 'calc(100% - 48px)',
    },
    icon: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
      [theme.breakpoints.only('xs')]: {
        top: theme.spacing(2.5),
      },
    },
  })
);

const Tile: React.FC<TileProps> = ({ type, setView, setService }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={4} className={classes.tile}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.spacingBottom}>
          {type.type}
          <Hidden mdUp>
            <Link
              onClick={() => {
                setView('form');
                setService(type.key);
              }}
            >
              <ChevronRightIcon color="primary" className={classes.icon} />
            </Link>
          </Hidden>
        </Typography>
        <Typography
          component="h6"
          variant="subtitle2"
          color="textPrimary"
          className={classes.spacingBottom}
        >
          {type.description}
        </Typography>
        <Hidden smDown>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ChevronRightIcon className={classes.icon} />}
            className={classes.buttonLabel}
            onClick={() => {
              setView('form');
              setService(type.key);
            }}
          >
            Select
          </Button>
        </Hidden>
      </Paper>
    </Grid>
  );
};

export default Tile;
