import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import {
  Grid,
  useMediaQuery,
  Theme,
  makeStyles,
  createStyles,
  Typography,
  Button,
} from '@material-ui/core';
import { useAuth } from '../../../hooks/useAuth';
import StaticSplitViewLayout from '../../../containers/StaticSplitViewLayout';
import ListingDetail from '../../../components/ListingDetail';
import ListingTabs from '../../../components/ListingTabs';
import { getPageTitleTemplate } from '../../../constants';
import ListItem from '../../../components/ListItem';
import ListingStatusLabel from '../../../components/ListingStatusLabel';
import noListingsImage from '../../../assets/marketplace-no-listings-buyer-completed.svg';
import { ROUTES } from '../../../routes';
import { useAppState } from '../../../contexts/AppState';
import UnstyledLink from '../../../components/UnstyledLink';
import MarketPlaceHeaderNav from '../header-nav';
import BackButton from '../../../components/BackButton';
import ListingCard from '../../../components/ListingCard';
import * as ListingInfo from '../../../components/Listings/ListingInfo';
import ListingData from '../../../components/ListingData';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NoCompany from '../../../components/Listings/NoCompany';
import {
  ListingCardGroup,
  ListingPanel,
} from '../../../components/ListingGroup';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EmptyContent from '../../../components/EmptyContent';
import { ListingProviderGroup } from '../../../types/listing-provider';
import { useLazyReadListingsByCompanyIDQuery } from '../../../services/api/marketplace-service/listings';

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      width: 120,
      display: 'block',
      margin: theme.spacing(4),
    },
  })
);

export const BuyingPendingContainer: React.FunctionComponent<RouteComponentProps> =
  ({ children }) => {
    const { user } = useAuth();

    return user?.company_account_id ? (
      <>{children}</>
    ) : (
      <NoCompany>
        It&apos;s looking a little empty in here. That's because you
        haven&apos;t been approved to join your team just yet. When the team
        manager approves your request, you&apos;ll be able to view your assigned
        listings. In the meantime, feel free to keep browsing listings on
        Cloudscene Marketplace.
      </NoCompany>
    );
  };

const BuyingPending: React.FunctionComponent<
  RouteComponentProps & {
    title?: string;
    listingShortId?: string;
  }
> = ({ title = 'Pending', listingShortId }) => {
  const { user } = useAuth();

  const [readListingsByCompanyID, { data, isFetching, isSuccess }] =
    useLazyReadListingsByCompanyIDQuery();

  const reviewListings =
    data?.entities.map<ListingProviderGroup>((listing) => ({
      listing,
    })) ?? [];
  const appState = useAppState();
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const noListings = reviewListings.length === 0;
  const classes = useStyles();
  useEffect(() => {
    if (user) {
      readListingsByCompanyID(
        {
          id: user?.company_account_id!,
          params: {
            include: 'documents,markets,providers,provider-contacts,bids',
            'filter[state]': 'ended',
          },
        },
        true
      );
    }
  }, [user, readListingsByCompanyID]);
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <MarketPlaceHeaderNav title="Ready to Review" isDetail={!!listingShortId}>
        <UnstyledLink to={ROUTES.buyingDashboard}>
          <BackButton />
        </UnstyledLink>
      </MarketPlaceHeaderNav>
      <StaticSplitViewLayout
        open={!!listingShortId && !noListings && isSuccess}
        content={
          <>
            <Grid container spacing={mobile ? 0 : 4}>
              {isFetching && <LoadingSpinner />}
              {!isFetching && isSuccess && noListings && (
                <Grid
                  item
                  sm={5}
                  xs={12}
                  component={EmptyContent}
                  img={
                    <img
                      className={classes.image}
                      alt="No Listings"
                      src={noListingsImage}
                    />
                  }
                >
                  You have no pending listings yet.
                </Grid>
              )}
              {!isFetching && isSuccess && reviewListings.length > 0 && (
                <ListingData
                  title="Listings"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={reviewListings}
                  baseURL={ROUTES.buyingPending}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    userId: item.listing.attributes.user_id ?? null,
                    userName:
                      item.listing.attributes.contact?.full_name ?? null,
                  })}
                  status={() => <ListingStatusLabel variant="review" />}
                />
              )}
            </Grid>
          </>
        }
        detail={
          <ListingInfo.Container id={listingShortId}>
            <ListingDetail
              leftNav={
                <UnstyledLink to={ROUTES.buyingPending}>
                  {mobile || !splitScreenMode ? (
                    <BackButton />
                  ) : (
                    <Button
                      color="primary"
                      endIcon={<ChevronRightIcon>close</ChevronRightIcon>}
                      variant="text"
                      size="small"
                    >
                      Close
                    </Button>
                  )}
                </UnstyledLink>
              }
              middleNav={mobile && <Typography variant="h6">Source</Typography>}
            >
              <ListingTabs />
            </ListingDetail>
          </ListingInfo.Container>
        }
      />
    </>
  );
};

export default BuyingPending;
