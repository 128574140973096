import { object, string, array, boolean } from 'yup';

const schema = object({
  attributes: object({
    title: string(),
    external: boolean(),
    external_link: string().when('external', {
      is: true,
      then: string().trim().url().required().label('Link'),
    }),
    external_contact_email: string().when('external', {
      is: true,
      then: string().trim().email().label('Email'),
    }),
    company: string().required().label('Company'),
    start: string().required().label('Start Date'),
    end: string().required().label('End Date'),
  }).defined(),
  owner: object({
    full_name: string().trim().required().label('Full Name'),
    email: string().trim().required().label('Email'),
    position: string().trim().required(),
    phone: string().trim().label('Phone'),
    user_details_agreement: boolean(),
  })
    .defined()
    .label('Contact Details'),
  contacts: array(),
  documents: array(),
  services: array(),
  multi_requirements: array(),
  providers: array(),
  provider_contacts: array(),
  markets: array().test(
    'atLeastOne',
    'At least one market is required',
    (markets) => {
      if (!markets) return false;
      return (
        markets.filter(
          (market: any) => market.state === 'add' || market.state === 'initial'
        ).length > 0
      );
    }
  ),
  status: object().defined(),
}).defined();

export default schema;
