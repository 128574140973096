import React from 'react';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Link,
  Grid,
} from '@material-ui/core';

interface PublishListingProps {
  disabled: boolean;
  submitting: boolean;
  onSubmit: () => void;
}

const PublishListing: React.FC<PublishListingProps> = ({
  disabled,
  submitting,
  onSubmit,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={disabled}
        endIcon={submitting && <CircularProgress color="secondary" size={20} />}
        onClick={() => setOpen(true)}
      >
        Publish Listing
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography component="div" variant="h2" align="center">
            Just a quick reminder...
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Listings published on Marketplace must be for genuine sourcing
            opportunities only as per our{' '}
            <Link target="_blank" href="https://access.cloudscene.com/terms/">
              website terms
            </Link>
            .
          </Typography>
          <Typography variant="body1">
            We also require you to use our platform to notify service providers
            on the outcome of their bids and supply feedback to those that are
            unsuccessful so they can improve future responses.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            xs="auto"
            container
            spacing={1}
            alignItems="center"
            direction="column"
          >
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                endIcon={
                  submitting && <CircularProgress color="secondary" size={20} />
                }
                onClick={onSubmit}
              >
                Agree &amp; Publish
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="text"
                color="primary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PublishListing;
