import { createApi } from '@reduxjs/toolkit/query/react';
import { ACCOUNT_SERVICE_API } from '../../../api';
import { staggeredBaseQueryWithBailOut } from '../fetch-base-query';

export const accountServiceAPI = createApi({
  reducerPath: 'accountServiceAPI',
  baseQuery: staggeredBaseQueryWithBailOut(ACCOUNT_SERVICE_API),
  tagTypes: ['User', 'CompanyAccount'],
  endpoints: () => ({}),
});
