import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import debounce from 'lodash/debounce';
import { DIRECTORY_SERVICE_API } from '../../../api';
import { Facility, FacilityHit } from '../../../types/services';
import axios from '../../../services/axios';

export default function useFacilityPredictions(
  value: string
): [Facility[], () => void] {
  const { data, refetch, remove } = useQuery(
    ['data-centers', value],
    () => {
      return axios
        .get(`${DIRECTORY_SERVICE_API}/facilities/search`, {
          params: {
            query: value,
            attributesToRetrieve:
              'name,operator,objectID,logo,market,zone,address',
          },
        })
        .then(({ data }) => {
          return data.hits.map((facility: FacilityHit): Facility => {
            const line1 = facility.address.line1
              ? `${facility.address.line1}, `
              : '';
            const city = facility.address.city
              ? `${facility.address.city}, `
              : '';
            const zipcode = facility.address.zipCode
              ? `${facility.address.zipCode}, `
              : '';
            const country = facility.address.country ?? '';

            const address = facility.address
              ? `${line1}${city}${zipcode}${country}`
              : '';
            return {
              type: 'data-center',
              description: `${facility.name} - ${facility.operator.name}`,
              id: facility.objectID,
              logo: facility.logo,
              market: facility.market,
              dc_id: facility.objectID,
              zone: facility.zone,
              address: address,
            };
          });
        });
    },
    {
      initialData: [],
      enabled: false,
    }
  );

  const debounceRefetch = useMemo(() => debounce(refetch, 300), [refetch]);
  const debounceRemove = useMemo(() => debounce(remove, 100), [remove]);

  const getFacilityPredictions = useCallback(debounceRefetch, [
    debounceRefetch,
  ]);
  const clearFacilityPredictions = useCallback(debounceRemove, [
    debounceRemove,
  ]);

  useEffect(() => {
    if (value) {
      getFacilityPredictions();
    } else {
      clearFacilityPredictions();
    }
  }, [value, getFacilityPredictions, clearFacilityPredictions]);

  return [data ?? [], clearFacilityPredictions];
}
