import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getProductLabelVariant } from '../../../utilities/marketplace-match';
import { ProviderGatewayProducts } from '../../../types/provider-gateway';
import { ServicesPlaceAttributes } from '../../../types/services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      position: 'relative',
      fontWeight: 'bold',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    empty: {
      [theme.breakpoints.only('xs')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    icon: { position: 'absolute', top: '-2px' },
  })
);

export interface ProductsInfoProps {
  products?: ProviderGatewayProducts;
  servicesPlaceAttributes: ServicesPlaceAttributes[];
  expand: boolean;
  setExpand: (expand: boolean) => void;
}

const ServiceProviderProductsInfo: React.FC<ProductsInfoProps> = ({
  products,
  servicesPlaceAttributes,
  expand,
  setExpand,
}) => {
  const classes = useStyles();

  if (!products) return null;
  const presenceArray = ['on', 'onNet', 'near', 'nearNet'];
  const sum = Object.entries(products).reduce(
    (acc, [_productKey, productValue]) => {
      if (
        !Object.keys(productValue).includes('zEnd') &&
        presenceArray.includes(getProductLabelVariant(productValue?.aEnd))
      ) {
        acc = acc + 1;
        return acc;
      } else if (
        Object.keys(productValue).includes('zEnd') &&
        presenceArray.includes(getProductLabelVariant(productValue?.azEnd))
      ) {
        acc = acc + 1;
        return acc;
      } else {
        return acc;
      }
    },
    0
  );

  return (
    <>
      <Grid item xs="auto">
        <Link
          href="#"
          onClick={(event: React.SyntheticEvent) => {
            event.preventDefault();
            setExpand(!expand);
          }}
          underline="none"
        >
          <Typography color="primary" variant="body1" className={classes.text}>
            {sum === 0
              ? 'Invite provider for more information'
              : `${sum} of ${servicesPlaceAttributes.length} services available`}
            {expand && <ExpandLess className={classes.icon} />}
            {!expand && <ExpandMore className={classes.icon} />}
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

export default ServiceProviderProductsInfo;
