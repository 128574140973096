import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import {
  selectAllProviderContacts,
  selectProviderContactEntities,
} from './reducer';
import { selectListingProviderContactById } from '../listing-provider-contacts';

export const selectProviderContactsByProviderId = (id?: string) =>
  createSelector(selectAllProviderContacts, (contacts) => {
    if (!id) {
      return [];
    }

    return contacts.filter((contact) => contact.attributes.provider_id === id);
  });

export const selectProviderContactByRelationshipId = (id: string) =>
  createSelector(
    (state: RootState) => selectListingProviderContactById(state, id),
    selectProviderContactEntities,
    (listingProviderContactRelationship, providerContactEntities) => {
      if (!listingProviderContactRelationship) return;

      return providerContactEntities[
        listingProviderContactRelationship.relationships['provider-contact']
          .data.id
      ];
    }
  );
