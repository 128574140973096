import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchBidsByListingId, updateBidById, fetchBidById } from './actions';
import { fetchAllOpportunities } from '../opportunities';
import { RootState } from '../types.d';
import { Bid } from '../../types/bid';
import { filterRelationshipEntityType } from '../../utilities/relationships';
import { fetchListingById } from '../listings';

const bidsAdapter = createEntityAdapter<Bid>({
  selectId: (bid) => bid.id!,
});

export enum BidStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  ERROR = 'error',
}

const bids = createSlice({
  name: 'bids',
  initialState: bidsAdapter.getInitialState({
    status: BidStatus.IDLE,
    error: undefined as undefined | string,
  }),
  reducers: {},
  extraReducers: (builder) => {
    // FETCH BY ID
    builder.addCase(fetchBidById.fulfilled, (state, action) => {
      bidsAdapter.upsertOne(state, action.payload.data);
    });

    // FETCH BY LISTING ID
    builder
      .addCase(fetchBidsByListingId.pending, (state, action) => {
        state.status = BidStatus.PENDING;
        state.error = undefined;
        bidsAdapter.removeAll(state);
      })
      .addCase(fetchBidsByListingId.fulfilled, (state, action) => {
        state.status = BidStatus.IDLE;
        bidsAdapter.addMany(state, action.payload.data);
      })
      .addCase(fetchBidsByListingId.rejected, (state, action) => {
        state.status = BidStatus.ERROR;
        state.error = action.error.message;
        bidsAdapter.removeAll(state);
      });

    // UPDATE STATUS
    builder.addCase(updateBidById.fulfilled, (state, action) => {
      bidsAdapter.upsertOne(state, action.payload.data);
    });

    builder.addCase(fetchListingById.fulfilled, (state, action) => {
      bidsAdapter.upsertMany(
        state,
        filterRelationshipEntityType<Bid>('bid', action.payload.included)
      );
    });

    // FETCH OPPORTUNITIES
    builder.addCase(fetchAllOpportunities.fulfilled, (state, action) => {
      bidsAdapter.upsertMany(
        state,
        filterRelationshipEntityType<Bid>('bid', action.payload.included).map(
          ({ id, type, attributes }) => {
            const listing = action.payload.data.find(
              (provider) => provider.relationships.bid?.data?.id === id
            )?.relationships.listing;

            return {
              id,
              type,
              attributes,
              relationships: {
                listing: listing!,
              },
            };
          }
        )
      );
    });
  },
});

export const {
  selectById: selectBidById,
  selectIds: selectBidIds,
  selectEntities: selectBidEntities,
  selectAll: selectAllBids,
} = bidsAdapter.getSelectors((state: RootState) => state.bids);

export default bids.reducer;
