import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useNavigate } from '@reach/router';
import { Link, Typography } from '@material-ui/core';
import { TEAM_URL } from '../../api';

interface CompanyCreateModalProps {
  open?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      margin: '0 auto',
    },
  })
);

export const CompanyCreateModal: React.FC<CompanyCreateModalProps> = ({
  open = false,
}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>
          <Typography component="div" variant="h2" align="center">
            Unable to create a listing
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You do not have a company account, please{' '}
            <Link target="_blank" href="https://www.cloudscene.com/contact-us">
              Contact Cloudscene Support - USA, Canada, Australia, Asia, Europe
            </Link>{' '}
            for help or click on the button below to create a company!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={() => navigate?.(`${TEAM_URL}/register/company`)}
            variant="contained"
            color="primary"
            autoFocus
          >
            Company Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
