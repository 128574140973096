import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import { ProviderGatewayServiceProvider } from '../../types/provider-gateway';
import { createListing } from '../listings';

const gatewayProductProviderAdapter =
  createEntityAdapter<ProviderGatewayServiceProvider>({
    selectId: (provider) => provider.id,
  });
const gatewayProductProviders = createSlice({
  name: 'gateway-product-providers',
  initialState: gatewayProductProviderAdapter.getInitialState(),
  reducers: {
    resetGatewayProductProviders: (state) => {
      gatewayProductProviderAdapter.removeAll(state);
    },
    addServiceProviderFromProducts: (state, action) => {
      const provider = state.entities[action.payload.id];

      if (!provider) {
        return gatewayProductProviderAdapter.addOne(state, action);
      }

      provider.products = { ...provider.products, ...action.payload.products };
      provider.score = provider.score + action.payload.score;

      return gatewayProductProviderAdapter.upsertOne(state, provider);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createListing.fulfilled, (state) => {
      gatewayProductProviderAdapter.removeAll(state);
    });
  },
});

export const {
  selectById: selectProductServiceProviderById,
  selectAll: selectAllProductServiceProviders,
  selectTotal: selectProductServiceProviders,
  selectIds: selectAllProductServiceProviderIds,
} = gatewayProductProviderAdapter.getSelectors(
  (state: RootState) => state.gatewayProductProviders
);

export const { addServiceProviderFromProducts, resetGatewayProductProviders } =
  gatewayProductProviders.actions;

export default gatewayProductProviders.reducer;
