import tw, { css, styled } from 'twin.macro';
import Logo from '../../assets/cloudscene.full.logo.svg';
const sizes = {
  small: css`
    height: 20px;
    width: 148px;
  `,
  normal: css`
    height: 32px;
    width: 240px;
  `,
};

export const CloudsceneLogo = styled.img.attrs({
  src: Logo,
  alt: 'Cloudscene logo',
  // Native aspect ratio: 500*68
  height: 32,
  width: 240,
})<{ size?: 'small' | 'normal' }>(({ size = 'normal' }) => [
  tw`block max-w-full`,
  sizes[size],
]);
