import React, { Component, ComponentState, PropsWithChildren } from 'react';

type ErrorBoundaryState = {
  hasError: boolean;
} & ComponentState;
type ErrorBoundaryProps = PropsWithChildren<{}>;

export class AppErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // to be send to bugsnag
    // @TODO add app error messaging
    console.error(error, errorInfo);
  }
  render() {
    // @TODO just silencing short term; do proper messaging
    return (
      <>
        {this.state.hasError && <div />}
        {this.props.children}
      </>
    );
  }
}
