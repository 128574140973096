import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import noListingsImage from '../../../assets/marketplace-no-listings-buyer.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      maxWidth: '100%',
      width: 450,
    },
  })
);

const NoCompany: React.FC = ({
  children = `It's looking a little empty in here. That's because you haven't been approved to join a company team just yet. In the meantime, feel free to keep browsing listings on Cloudscene Marketplace.`,
}) => {
  const classes = useStyles();

  return (
    <Box py={8}>
      <Grid container item xs="auto" alignItems="center" direction="column">
        <Grid item xs="auto">
          <img
            className={classes.image}
            alt="No listings"
            src={noListingsImage}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body2" align="center">
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoCompany;
