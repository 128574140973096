import { gql } from 'graphql-request';
import { URLQueryParams } from '../../../../types/entity-collection';
import { marketplaceServiceGQLAPI } from './base';
import {
  ProviderContactResponse,
  ProviderContactsResponse,
  ProviderContactRequest,
} from '../../../../types/marketplace-service/gql/service-provider-contact';

export const serviceProviderContactsAPI =
  marketplaceServiceGQLAPI.injectEndpoints({
    endpoints: (builder) => ({
      createServiceProviderContact: builder.mutation<
        ProviderContactResponse,
        {
          payload: ProviderContactRequest;
          params?: URLQueryParams;
        }
      >({
        query: (args) => ({
          document: gql`
            mutation CreateProviderContact(
              $providerContactRequest: CreateProviderContact!
            ) {
              providerContact: createProviderContact(
                input: $providerContactRequest
              ) {
                id
                user_id
                provider_id
                first_name
                last_name
                email
              }
            }
          `,
          variables: {
            providerContactRequest: args.payload,
          },
        }),
      }),
      readServiceProviderContactByID: builder.query<
        ProviderContactResponse,
        { id: string }
      >({
        providesTags: (result) =>
          result?.providerContact
            ? [
                {
                  type: 'ServiceProviderContact',
                  id: result.providerContact.id,
                },
              ]
            : [],
        query: (args) => ({
          document: gql`
            query GetProviderContact($id: ID!) {
              providerContact(id: $id) {
                id
                user_id
                provider_id
                first_name
                last_name
                email
              }
            }
          `,
          variables: {
            id: args.id,
          },
        }),
      }),
      readAllProviderContacts: builder.query<ProviderContactsResponse, void>({
        query: () => ({
          document: gql`
            query GetProviderContacts {
              providerContacts {
                id
                user_id
                provider_id
                first_name
                last_name
                email
              }
            }
          `,
        }),
      }),
      updateServiceProviderContactByID: builder.mutation<
        ProviderContactResponse,
        {
          payload: ProviderContactRequest;
          params?: URLQueryParams;
        }
      >({
        invalidatesTags: (result) =>
          result
            ? [
                {
                  type: 'ServiceProviderContact',
                  id: result.providerContact.id,
                },
                { type: 'ServiceProviderContact', id: 'LIST' },
              ]
            : [],
        query: (args) => ({
          document: gql`
            mutation UpdateProviderContact(
              $providerContactRequest: UpdateProviderContact!
            ) {
              providerContact: updateProviderContact(
                input: $providerContactRequest
              ) {
                id
                user_id
                provider_id
                first_name
                last_name
                email
              }
            }
          `,
          variables: {
            providerContactRequest: args.payload,
          },
        }),
      }),
      deleteServiceProviderContactByID: builder.mutation<void, { id: string }>({
        query: (args) => ({
          document: gql`
            mutation DeleteProviderContact($id: ID!) {
              deleteProviderContact(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: args.id,
          },
        }),
      }),
    }),
  });

export const {
  useCreateServiceProviderContactMutation,
  useDeleteServiceProviderContactByIDMutation,
  useLazyReadAllProviderContactsQuery,
  useLazyReadServiceProviderContactByIDQuery,
  useReadAllProviderContactsQuery,
  useReadServiceProviderContactByIDQuery,
  useUpdateServiceProviderContactByIDMutation,
} = serviceProviderContactsAPI;
