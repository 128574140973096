import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ActionMenu from '../ActionMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(2),
    },
  })
);
export interface ServiceDetailsReview {
  heading: React.ReactNode;
  onEdit?: () => void;
  onDuplicate?: () => void;
  onDelete: () => void;
}

const ServiceDetailReview: React.FC<ServiceDetailsReview> = ({
  heading,
  onEdit,
  onDuplicate,
  onDelete,
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs>
          <Typography component="div" variant="h5" className={classes.title}>
            {heading}
          </Typography>
        </Grid>
        <Grid item xs="auto" className={classes.title}>
          <ActionMenu>
            {onEdit && (
              <ActionMenu.Item onClick={onEdit} divider={!onDuplicate}>
                Edit
              </ActionMenu.Item>
            )}
            {onDuplicate && (
              <ActionMenu.Item onClick={onDuplicate} divider>
                Duplicate
              </ActionMenu.Item>
            )}
            <ActionMenu.Item onClick={onDelete} variant="danger">
              Remove
            </ActionMenu.Item>
          </ActionMenu>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceDetailReview;
