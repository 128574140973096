import React from 'react';
import { Box, Grid, Typography, MenuItem } from '@material-ui/core';
import { formatISO, parseISO } from 'date-fns';
import { Field } from 'react-final-form';
import { getFeatureUIName } from '../../../constants';
import PlacesAutocomplete from '../../Form/PlacesAutocomplete';
import { SelectFieldPlaceholder as SelectField } from '../../Form/CSSelectField';
import CSDateField from '../../Form/CSDateField';
import {
  ServiceBandwidth,
  ServiceBandwidthUnits,
  ServiceContractTerm,
  ServiceContractUnits,
  ServiceTypes,
} from '../../../types/services';

interface ServiceRequirementsEthernetProps {
  minRfsDate: Date;
  name: string;
}

const ServiceRequirementsEthernet = ({
  minRfsDate,
  name,
}: ServiceRequirementsEthernetProps) => {
  return (
    <Box my={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {getFeatureUIName(ServiceTypes.ethernet)}
          </Typography>
        </Grid>

        <Grid item xs>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field name={`${name}.attributes.point_a`}>
                {({ input }) => (
                  <PlacesAutocomplete
                    label="Where is the A-End Location? *"
                    selected={input.value}
                    onSelect={(place) => input.onChange(place)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name={`${name}.attributes.point_z`}>
                {({ input }) => (
                  <PlacesAutocomplete
                    label="Where is the B-End Location? *"
                    selected={input.value}
                    onSelect={(place) => input.onChange(place)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${name}.attributes.bandwidth`}
                parse={(bandwidth: string): ServiceBandwidth => {
                  const [, value, unit] =
                    bandwidth.match(/(\d{1,})(\w{4})/) || [];

                  return {
                    value: Number(value),
                    unit: unit as ServiceBandwidthUnits,
                  };
                }}
                format={(bandwidth: ServiceBandwidth) => {
                  return bandwidth ? `${bandwidth.value}${bandwidth.unit}` : '';
                }}
              >
                {({ input }) => (
                  <SelectField
                    label="What Bandwidth do you require?"
                    {...input}
                  >
                    <MenuItem value="10Mbps">10 Mbps</MenuItem>
                    <MenuItem value="20Mbps">20 Mbps</MenuItem>
                    <MenuItem value="50Mbps">50 Mbps</MenuItem>
                    <MenuItem value="100Mbps">100 Mbps</MenuItem>
                    <MenuItem value="200Mbps">200 Mbps</MenuItem>
                    <MenuItem value="500Mbps">500 Mbps</MenuItem>
                    <MenuItem value="1Gbps">1 Gbps</MenuItem>
                    <MenuItem value="10Gbps">10 Gbps</MenuItem>
                    <MenuItem value="100Gbps">100 Gbps</MenuItem>
                  </SelectField>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${name}.contract_term`}
                parse={(term: string): ServiceContractTerm => {
                  const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];
                  return {
                    value: Number(value),
                    unit: unit as ServiceContractUnits,
                  };
                }}
                format={(term: ServiceContractTerm) => {
                  return term ? `${term.value}${term.unit}` : '';
                }}
              >
                {({ input }) => (
                  <SelectField
                    label="What is your preferred Contract Term? *"
                    {...input}
                  >
                    <MenuItem value="12months">12 months</MenuItem>
                    <MenuItem value="24months">24 months</MenuItem>
                    <MenuItem value="36months">36 months</MenuItem>
                    <MenuItem value="48months">48 months</MenuItem>
                    <MenuItem value="60months">60 months</MenuItem>
                  </SelectField>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${name}.ready_for_service`}
                parse={(value) => (value ? formatISO(value) : '')}
                format={(value) => (value ? parseISO(value) : '')}
              >
                {({ input }) => (
                  <CSDateField
                    minDate={minRfsDate}
                    label="When do you need the connection by? (RFS Date) *"
                    {...input}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="attributes.end"
                parse={(value) => (value ? formatISO(value) : '')}
                format={(value) => (value ? parseISO(value) : '')}
              >
                {({ input }) => (
                  <CSDateField
                    minDate={minRfsDate}
                    label="How soon do you need a quote? *"
                    {...input}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceRequirementsEthernet;
