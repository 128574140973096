import React, { useState, useEffect, useCallback, createContext } from 'react';
import { navigate } from '@reach/router';
import { useAppMessenger } from '../../store/messenger';
import { ROUTES } from '../../routes';
import AssignOpportunityModal from '../../components/AssignOpportunity';
import DeclineOpportunityModal from './DeclineOpportunityModal';
import RegisterToBidModal from './RegisterToBidModal';
import {
  useRegisterListingProviderMutation,
  useAssignListingProviderMutation,
  useDeclineListingProviderMutation,
} from '../../services/api/marketplace-service/listing-providers';
import { selectProviderContactByRelationshipId } from '../../store/provider-contacts';
import {
  ListingProviderResponse,
  ListingProviderContact,
} from '../../types/marketplace-service/listing-provider';
import { Bid } from '../../types/bid';
import { selectUserById } from '../../store/users';
import { useCompanyQuery } from '../../hooks/useCompanyQuery';
import ViewBid, { ViewBidDialog } from '../../components/ViewBid';
import { useLazyReadAllBidsByListingIDQuery } from '../../services/api/marketplace-service/bids';
import EditListingConfirmation from '../../components/EditListingConfirmation';
import { UserResponse } from '../../types/account-service/users';
import {
  AllowedActions,
  useListingOpportunityStatus,
} from './useListingOpportunityStatus';
import InfoModal from './InfoModal';
import ListingOpportunityManagerView from './View';
import { useActions } from './useActions';
import { useQuery } from 'react-query';
import axios from '../../services/axios';
import { API_BASE_URL } from '../../api';
import { parseISO } from 'date-fns';
import { useAppSelector } from '../../store/hooks';
import { ListingResponse } from '../../types/marketplace-service/listing';

interface ListingOpportunityManagerProps {
  listing: ListingResponse;
  provider?: ListingProviderResponse;
  bid?: Bid;
  navigateToOpportunity?: boolean;
  onFocusBidForm?: () => void;
}

export interface ListingOpportunityActions {
  onIntendToBid?: () => void;
  onDecline?: () => void;
  onAssign?: () => void;
  onRequestToBid?: () => void;
  onMoreInfo?: () => void;
  onContact?: () => void;
  onEditListing?: () => void;
  onViewListing?: () => void;
  onCopyListing?: () => void;
  onViewOpportunity?: () => void;
}

export const ListingOpportunityContext = createContext<
  ListingOpportunityActions & {
    allowedActions: AllowedActions[];
    isAssigned: boolean;
    isInvited: boolean;
  }
>({ allowedActions: [], isAssigned: false, isInvited: false });

const ListingOpportunityManager: React.FC<ListingOpportunityManagerProps> = ({
  provider,
  listing,
  navigateToOpportunity = false,
  onFocusBidForm,
  children,
}) => {
  const [viewBid, setViewBid] = useState(false);
  const [intentToBid, setIntentToBid] = useState(false);
  const [assignToBid, setAssignToBid] = useState(false);
  const [declineToBid, setDeclineToBid] = useState(false);
  const [editListing, setEditListing] = useState(false);
  const [readAllBidsByListingIDQuery, { bid }] =
    useLazyReadAllBidsByListingIDQuery({
      selectFromResult: (result) => {
        const bid = result.data?.entities.find(
          (bid) =>
            bid.relationships.listing.data.id === listing.id &&
            bid.relationships.companyAccount?.data.id !== 'not-set'
        );

        return { bid };
      },
    });
  const [registerListingProvider] = useRegisterListingProviderMutation();
  const [assignListingProvider] = useAssignListingProviderMutation();
  const [declineListingProvider] = useDeclineListingProviderMutation();
  const messenger = useAppMessenger();
  const companyQuery = useCompanyQuery(
    provider?.attributes.external_company_account_id
  );
  // I think this only works on the sellers side as we would only have access to one provider-contact?
  // so it's lucky we only use contact on the selling side..
  const contact = useAppSelector(
    selectProviderContactByRelationshipId(
      listing.relationships?.['provider-contacts']?.data[0]?.id ?? ''
    )
  );
  const assignedUser = useAppSelector(
    selectUserById(provider?.attributes.external_assigned_to_user_id)
  );
  const intendByUser = useAppSelector(
    selectUserById(provider?.attributes.external_set_by_user_id)
  );
  const assignedNewUser = provider?.attributes.assigned_to_contact;

  const [showOwnerDetails, setShowOwnerDetails] = useState(
    bid?.attributes.contact_info_viewed_at ? true : false
  );

  const { listingOpportunityStatus, allowedActions } =
    useListingOpportunityStatus(listing, provider, bid, contact);
  const externalActions = useActions(
    allowedActions,
    listing,
    navigateToOpportunity,
    onFocusBidForm
  );
  const actions: ListingOpportunityActions = {
    ...externalActions,
    onAssign: allowedActions.includes('assign')
      ? () => setAssignToBid(true)
      : undefined,
    onDecline: allowedActions.includes('decline')
      ? () => setDeclineToBid(true)
      : undefined,
    onEditListing: allowedActions.includes('edit_listing')
      ? () => setEditListing(true)
      : undefined,
    onIntendToBid: allowedActions.includes('intend')
      ? () => setIntentToBid(true)
      : undefined,
  };

  useEffect(() => {
    if (!bid && provider?.attributes.bid_at) {
      readAllBidsByListingIDQuery({
        listing: listing.id,
        params: {
          include: ['companyAccount', 'user', 'documents'].join(','),
        },
      });
    }
  }, [readAllBidsByListingIDQuery, listing, provider, bid]);

  const { data, isError, refetch } = useQuery(
    ['listing-contact', listing?.id],
    () => {
      return axios.get<{
        data: ListingResponse;
      }>(`${API_BASE_URL}/listings/${listing?.id}/contact`);
    },
    {
      enabled: !!bid?.attributes.contact_info_viewed_at,
    }
  );

  const onDeclineToBid = useCallback(
    async (reason: string) => {
      if (!provider) return;

      return await declineListingProvider({
        listing: listing.id,
        provider: provider.id,
        reason,
      })
        .unwrap()
        .then(() => {
          setDeclineToBid(false);
          messenger.addMessage('info', `You have declined this opportunity`);
        })
        .catch(() => {
          messenger.addMessage('error', 'Error declining opportunity');
        });
    },
    [declineListingProvider, listing, provider, messenger]
  );

  const onRegisterIntent = useCallback(async () => {
    return await registerListingProvider({
      listing: listing.id,
      provider: provider?.id,
    })
      .unwrap()
      .then(() => {
        setIntentToBid(false);
        messenger.addMessage('info', 'You have a new opportunity');
        if (navigateToOpportunity) {
          navigate(
            `${ROUTES.sellingOpportunities}/${listing.attributes.short_id}`
          );
        }
      })
      .catch(() => {
        messenger.addMessage('error', 'Error while intending to bid');
      });
  }, [
    registerListingProvider,
    listing,
    provider,
    messenger,
    navigateToOpportunity,
  ]);

  const onUserAssigned = useCallback(
    async (user: UserResponse) => {
      return await assignListingProvider({
        listing: listing.id,
        provider: provider?.id,
        user: user.id,
      })
        .unwrap()
        .then(() => {
          setAssignToBid(false);
          messenger.addMessage('info', 'You have assigned an opportunity');
        })
        .catch(() => {
          messenger.addMessage('error', 'Error while assigning opportunity');
        });
    },
    [assignListingProvider, listing, provider, messenger]
  );

  const onNewUserAssigned = useCallback(
    async (contact: ListingProviderContact) => {
      if (!provider) return;

      return await assignListingProvider({
        listing: listing.id,
        provider: provider.id,
        user: null,
        contact,
      })
        .unwrap()
        .then(() => {
          setAssignToBid(false);
          messenger.addMessage('info', 'You have assigned an opportunity');
        })
        .catch(() => {
          messenger.addMessage('error', 'Error while assigning opportunity');
        });
    },
    [assignListingProvider, listing, provider, messenger]
  );

  return (
    <ListingOpportunityContext.Provider
      value={{
        ...actions,
        allowedActions,
        isAssigned: !!contact || !!assignedNewUser,
        isInvited:
          !provider?.attributes.declined_at &&
          !!provider?.attributes.invited_at,
      }}
    >
      {children}
      {bid && (
        <ViewBidDialog
          open={viewBid}
          onClose={() => setViewBid(false)}
          company={companyQuery.data?.entity}
          bidder={bid.attributes.contact}
          viewBidderInfo
        >
          <ViewBid bid={bid} />
        </ViewBidDialog>
      )}
      {actions.onIntendToBid && (
        <RegisterToBidModal
          onIntendToBid={onRegisterIntent}
          open={intentToBid}
          onClose={() => setIntentToBid(false)}
        />
      )}
      {actions.onAssign && (
        <AssignOpportunityModal
          open={assignToBid}
          onClose={() => setAssignToBid(false)}
          onAssign={onUserAssigned}
          onNewUserAssign={onNewUserAssigned}
        />
      )}
      {actions.onDecline && (
        <DeclineOpportunityModal
          open={declineToBid}
          onDecline={onDeclineToBid}
          onClose={() => setDeclineToBid(false)}
        />
      )}
      {actions.onEditListing && (
        <EditListingConfirmation
          open={editListing}
          onClose={() => setEditListing(false)}
          listingShortId={listing.attributes.short_id!}
        />
      )}
      <ListingOpportunityManagerView
        text={
          listingOpportunityStatus !== 'ENDED' && (
            <InfoModal
              listingStartDate={parseISO(listing.attributes.start)}
              ownerDetails={data?.data?.data}
              isError={isError}
              showOwnerDetails={showOwnerDetails}
              onShowOwnerDetails={() => {
                setShowOwnerDetails(true);
                refetch();
              }}
              setViewBid={setViewBid}
              intendByUser={intendByUser}
              listingOpportunityStatus={listingOpportunityStatus}
              provider={provider}
              bid={bid}
              contact={contact}
              assignedUser={assignedUser}
            />
          )
        }
        {...actions}
      />
    </ListingOpportunityContext.Provider>
  );
};

export default ListingOpportunityManager;
