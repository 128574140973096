import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ServiceDetails from '../../ServiceDetails';
import MarketInfo, { MarketState } from '../../MarketInfoDisplay';
import { getServiceUIName } from '../../../constants';
import AttachmentList, { DocumentState } from '../../AttachmentList';
import { ServiceState } from '../../ServiceDetails';
import HtmlParser from '../../HtmlParser';
import { ServiceRelationship } from '../../../types/services';
import { ListingMarketResponseIncludes } from '../../../types/marketplace-service/market';
import { ProviderRelationship } from '../../../types/provider';
import { Document } from '../../../types/document';
import { ListingInfoContext } from './Context';
import AdminClientDetails from '../../AdminClientDetails';
import { useAuth } from '../../../hooks/useAuth';

interface ListingInfoContentProps {
  services?: ServiceRelationship[];
  markets?: ListingMarketResponseIncludes[];
  providers?: ProviderRelationship[];
  documents?: Document[];
}

const useContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    additionalInfo: {
      whiteSpace: 'pre-line',
    },
    section: {
      margin: theme.spacing(2, 0, 4, 0),
    },
  })
);

const ListingInfoContent: React.FC<ListingInfoContentProps> = ({
  children,
}) => {
  const classes = useContentStyles();
  const { listing, isFetching } = useContext(ListingInfoContext);
  const { user } = useAuth();
  if (!listing || isFetching) {
    return null;
  }

  const services = listing?.relationships?.services?.data ?? [];
  const markets = listing?.relationships?.markets?.data ?? [];
  const documents = listing?.relationships?.documents?.data ?? [];

  return (
    <>
      {services.length > 0 && (
        <Grid item xs={12} className={classes.section}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h6">
                Services
              </Typography>
            </Grid>
            {services.map((service) => (
              <ServiceState
                key={service.id}
                id={service.id!}
                listingId={listing.id}
              >
                {(service) => {
                  if (!service.attributes.attributes) {
                    return <></>;
                  }

                  const title = service.attributes.attributes
                    ?.external_service_name
                    ? `${getServiceUIName(service.attributes.type)} (${
                        service.attributes.attributes.external_service_name
                      })`
                    : getServiceUIName(service.attributes.type);
                  return (
                    <Grid item xs={12}>
                      <ServiceDetails.ExpansionPanel
                        id={service.id}
                        title={title}
                      >
                        <ServiceDetails.Summary service={service.attributes} />
                      </ServiceDetails.ExpansionPanel>
                    </Grid>
                  );
                }}
              </ServiceState>
            ))}
          </Grid>
        </Grid>
      )}
      {markets.length > 0 && (
        <Grid item xs={12} className={classes.section}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h6">
                Markets
              </Typography>
            </Grid>
            {markets.map((market) => (
              <MarketState key={market.id} id={market.id!}>
                {(market) => (
                  <Grid item xs="auto">
                    <MarketInfo id={market.attributes.id} />
                  </Grid>
                )}
              </MarketState>
            ))}
          </Grid>
        </Grid>
      )}
      {documents.length > 0 && (
        <Grid item xs={12} className={classes.section}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h6">
                Attachments
              </Typography>
            </Grid>

            {documents.map((document, index: number) => (
              <DocumentState key={document.id!} id={document.id!}>
                {(document) => {
                  return <AttachmentList key={index} document={document} />;
                }}
              </DocumentState>
            ))}
          </Grid>
        </Grid>
      )}
      {listing.attributes.description && (
        <Grid item xs={12} className={classes.section}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h6">
                Additional Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.additionalInfo}
                color="textPrimary"
                variant="body2"
                component="div"
              >
                <HtmlParser>{listing.attributes.description}</HtmlParser>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {user?.csAdmin && <AdminClientDetails />}
      <Grid item xs={12} className={classes.section}>
        {children}
      </Grid>
    </>
  );
};

export default ListingInfoContent;
