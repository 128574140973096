import {
  useImperativeHandle,
  forwardRef,
  ForwardedRef,
  PropsWithChildren,
} from 'react';
import { isFunction } from '../../../utilities/is-function';
import { ModalContext } from './Context';
import type { ModalContextType, ModalProps } from './types.d';
import { useModal } from './useModal';

const ModalComponent = (
  {
    Overlay,
    Dialog,
    action,
    children,
    onClose,
    isOpen,
    closeOnEsc,
    closeOnOutsideClick,
  }: PropsWithChildren<ModalProps>,
  imperativeRef: ForwardedRef<
    | Pick<
        ModalContextType,
        'isOpen' | 'openModal' | 'closeModal' | 'toggleModal'
      >
    | undefined
  >
) => {
  const {
    ref,
    isOpen: isModalOpen,
    openModal,
    closeModal,
    toggleModal,
    Modal,
  } = useModal({
    overlay: Overlay,
    dialog: Dialog,
    onClose,
    isOpen,
    closeOnEsc,
    closeOnOutsideClick,
  });

  useImperativeHandle(imperativeRef, () => {
    return {
      isOpen: isModalOpen,
      openModal,
      closeModal,
      toggleModal,
    };
  });

  return (
    <ModalContext.Provider
      value={{ ref, isOpen: isModalOpen, openModal, closeModal, toggleModal }}
    >
      {isFunction(action)
        ? action({
            ref,
            isOpen: isModalOpen,
            openModal,
            closeModal,
            toggleModal,
          })
        : action}
      {isModalOpen && (
        <Modal>
          {isFunction(children)
            ? children({
                ref,
                isOpen: isModalOpen,
                openModal,
                closeModal,
                toggleModal,
              })
            : children}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export const Modal = forwardRef(ModalComponent);
