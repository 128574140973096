import React from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Link,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { navigate } from '@reach/router';
import { ListingOpportunityActionMenu } from '../../../containers/ListingOpportunityManager';
import { useAuth } from '../../../hooks/useAuth';
import { isListingOwner } from '../../../utilities/listings';
import ActionMenu from '../../ActionMenu';
import ListingServiceProviders from '../../ListingServiceProviders';
import * as ListingInfo from '../ListingInfo';
import AdminListingProviders from '../../AdminListingProviders';
import { APP_ENV } from './../../../api';

const useStyles = makeStyles((theme) => ({
  close: {
    color: theme.palette.grey[500],
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '90px',
  },
}));

const ActiveListing: React.FC<{
  listingShortId: string | undefined;
  onClose: Function;
}> = ({ listingShortId, onClose }) => {
  const { user } = useAuth();
  const classes = useStyles();

  return (
    <ListingInfo.Container id={listingShortId} setTrackView>
      <Box position="absolute" top={8} left={8} right={8}>
        <Grid container spacing={1} justifyContent="flex-end">
          {user?.csAdmin && (
            <Grid item xs="auto">
              <ActionMenu>
                <ActionMenu.Item
                  onClick={() => navigate(`/listing/${listingShortId}/edit`)}
                >
                  Edit Listing
                </ActionMenu.Item>
              </ActionMenu>
            </Grid>
          )}
          <Grid item xs="auto">
            <IconButton
              className={classes.close}
              size="small"
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <DialogTitle>
        <ListingInfo.Header navigateToOpportunity>
          <Hidden mdUp>
            <Box py={1}>
              <Grid container justifyContent="flex-end">
                <ListingOpportunityActionMenu />
              </Grid>
            </Box>
          </Hidden>
        </ListingInfo.Header>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <ListingInfo.Content>
          <ListingInfo.ListingInfoContext.Consumer>
            {({ listing }) =>
              isListingOwner(listing?.attributes.user_id, user?.id) ? (
                <ListingServiceProviders />
              ) : (
                user?.csAdmin && <AdminListingProviders />
              )
            }
          </ListingInfo.ListingInfoContext.Consumer>
        </ListingInfo.Content>

        {APP_ENV === 'production' && (
          <Grid container justifyContent="center">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={
                'https://servedbyadbutler.com/go2/;ID=180994;size=728x90;setID=479123'
              }
            >
              <img
                className={classes.image}
                alt="Marketplace Exclusive Banner"
                src={
                  'https://servedbyadbutler.com/adserve/;ID=180994;size=728x90;setID=479123;type=img;click=CLICK_MACRO_PLACEHOLDER'
                }
                width="728"
                height="90"
              />
            </Link>
          </Grid>
        )}
      </DialogContent>
    </ListingInfo.Container>
  );
};

export default ActiveListing;
