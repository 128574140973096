import { Grid, makeStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import { getServiceUIName } from '../../constants';
import { ServiceTypeKeys, ServiceTypes } from '../../types/services';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  selected: {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.text.primary}!important`,
  },
}));

const disallowedServiceTypes: Array<ServiceTypeKeys> = [
  'mpls',
  'other',
  'cloud_connectivity',
  'cloud_services',
  'sd_wan',
];

const ServiceTypeFilters: React.FC<{
  selectedServiceTypes: ServiceTypeKeys[];
  setSelectedServiceTypes: (serviceTypes: ServiceTypeKeys[]) => void;
}> = ({ selectedServiceTypes, setSelectedServiceTypes }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container item spacing={2} direction="row">
        <Grid item xs={6}>
          <ToggleButton
            size="small"
            classes={{ selected: classes.selected, root: classes.root }}
            onClick={() => setSelectedServiceTypes([])}
            selected={!selectedServiceTypes.length}
            value="All-Services"
          >
            All services
          </ToggleButton>
        </Grid>
        {Object.keys(ServiceTypes)
          .map((key) => key as ServiceTypeKeys)
          .filter(
            (serviceType) => !disallowedServiceTypes.includes(serviceType)
          )
          .map((serviceType) => {
            return (
              <Grid item xs={6} key={serviceType}>
                <ToggleButton
                  classes={{ selected: classes.selected, root: classes.root }}
                  size="small"
                  onClick={() => {
                    if (selectedServiceTypes.includes(serviceType)) {
                      setSelectedServiceTypes(
                        selectedServiceTypes.filter(
                          (selectedServiceType) =>
                            selectedServiceType !== serviceType
                        )
                      );
                    } else {
                      setSelectedServiceTypes([
                        ...selectedServiceTypes,
                        serviceType,
                      ]);
                    }
                  }}
                  value={serviceType}
                  selected={selectedServiceTypes.includes(serviceType)}
                  style={{ width: '100%' }}
                >
                  {serviceType === 'multi_service_requirements'
                    ? 'Other Services'
                    : getServiceUIName(serviceType)}
                </ToggleButton>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default ServiceTypeFilters;
