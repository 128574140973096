import { cloneElement, isValidElement, memo, PropsWithChildren } from 'react';
import FocusLock from 'react-focus-lock';
import usePortal from 'react-useportal';
import { useBodyScrollLock } from '../../../hooks/useBodyScrollLock';
import { useInitialised } from '../../../hooks/useInitialised';
import { useUnmount } from '../../../hooks/useUnmount';
import { Base } from './Dialog';
import { Overlay } from './Overlay';
import type { UseModalOptions } from './types.d';

export const useModal = ({
  overlay = <Overlay variant="light" />,
  dialog = <Base size="sm" />,
  onClose,
  isOpen = false,
  closeOnEsc = false,
  closeOnOutsideClick = false,
}: UseModalOptions = {}) => {
  const { lock, unlock, clear } = useBodyScrollLock();
  const {
    ref,
    isOpen: isPortalOpen,
    openPortal,
    togglePortal,
    closePortal,
    Portal,
  } = usePortal({
    onOpen: (event) => lock(event.targetEl.current),
    onClose: (event) => {
      onClose?.();
      unlock(event.targetEl.current);
    },
    isOpen,
    closeOnEsc,
    closeOnOutsideClick,
  });

  useUnmount(() => clear());

  const PortalModal = memo<PropsWithChildren<unknown>>(({ children }) => {
    const initialised = useInitialised();
    const child = isValidElement(dialog)
      ? cloneElement(dialog, undefined, children)
      : children;
    const component = isValidElement(overlay)
      ? cloneElement(overlay, undefined, child)
      : child;

    return initialised ? (
      <Portal>
        <FocusLock>{component}</FocusLock>
      </Portal>
    ) : null;
  });

  PortalModal.displayName = 'PortalModal';

  return {
    ref,
    Modal: PortalModal,
    openModal: openPortal,
    toggleModal: togglePortal,
    closeModal: closePortal,
    isOpen: isPortalOpen,
  };
};
