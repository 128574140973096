import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useReadAllBidPricingOptionsQuery } from '../../services/api/marketplace-service/bids';
import BidPricingTable from '../BidPricingTable';
import LoadingSpinner from '../LoadingSpinner';

interface BidPricingOptionProps {
  bid: string;
  service: string;
}

const BidPricingOption: React.FC<BidPricingOptionProps> = ({
  bid,
  service,
}) => {
  const { data, isFetching, isSuccess } = useReadAllBidPricingOptionsQuery({
    bid,
    service,
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return isSuccess && !!data?.entities.length ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BidPricingTable
          pricing={data.entities.map((option) => ({
            contractTerm: {
              value: option.attributes.contract_term_value,
              unit: option.attributes.contract_term_unit,
            },
            recurringCost: {
              value: option.attributes.recurring_cost_value,
              unit: option.attributes.recurring_cost_currency,
            },
            nonRecurringCost: {
              value: option.attributes.cost_value,
              unit: option.attributes.cost_currency,
            },
          }))}
        />
      </Grid>
    </Grid>
  ) : (
    <Typography color="error">No Bid</Typography>
  );
};

export default BidPricingOption;
