import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  fetchAllListingProviders,
  fetchListingProviderById,
  deleteListingProviderById,
} from './actions';
import { resetListings } from '../listings/reducer';
import { RootState } from '../types.d';
import { listingsAPI } from '../../services/api/marketplace-service/listings';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';

const listingProvidersAdapter = createEntityAdapter<ListingProviderResponse>({
  selectId: (provider) => provider.id,
});

const providers = createSlice({
  name: 'listing-providers',
  initialState: listingProvidersAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetListings, (state) => {
      listingProvidersAdapter.removeAll(state);
    });

    builder.addCase(fetchAllListingProviders.fulfilled, (state, action) => {
      listingProvidersAdapter.upsertMany(state, action.payload.data);
    });

    builder.addCase(fetchListingProviderById.fulfilled, (state, action) => {
      listingProvidersAdapter.upsertOne(state, action.payload.data);
    });

    builder.addCase(deleteListingProviderById.fulfilled, (state, action) => {
      listingProvidersAdapter.removeOne(state, action.payload.id);
    });

    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingsByCompanyID.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context['listing-provider']) return state;

        const providers = Object.values(
          action.payload.context['listing-provider'].entities
        );

        listingProvidersAdapter.upsertMany(state, providers);
      }
    );
  },
});

export const {
  selectById: selectProviderById,
  selectIds: selectProviderIds,
  selectEntities: selectProviderEntities,
  selectAll: selectAllProviders,
  selectTotal: selectTotalProviders,
} = listingProvidersAdapter.getSelectors(
  (state: RootState) => state.listingProviders
);

export default providers.reducer;
