import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk, ThunkExtra } from '../types.d';
import { API_BASE_URL } from '../../api';
import {
  ListingProviderContact,
  ProviderContact,
} from '../../types/provider-contact';

export const createListingProviderContact = createAsyncThunk<
  {
    data: ProviderContact;
  },
  { listing: string; contact: ListingProviderContact },
  { extra: ThunkExtra }
>(
  `listing-provider-contact/create`,
  async ({ listing, contact }, { extra: { axios } }) => {
    const response = await axios.post(
      `${API_BASE_URL}/listings/${listing}/provider-contacts`,
      {
        data: contact,
      }
    );

    return response.data;
  }
);

export const updateListingProviderContactById = createAsyncThunk<
  {
    data: ProviderContact;
  },
  { listing: string; contact: ListingProviderContact },
  { extra: ThunkExtra }
>(
  `listing-provider-contact/updateById`,
  async ({ listing, contact }, { extra: { axios } }) => {
    const response = await axios.patch(
      `${API_BASE_URL}/listings/${listing}/provider-contacts/${contact.id}`,
      { data: contact }
    );

    return response.data;
  }
);

export const deleteListingProviderContactById = createAsyncThunk<
  { id: string },
  { listing: string; contact: string },
  { extra: ThunkExtra }
>(
  `listing-provider-contact/delete`,
  async ({ listing, contact }, { extra: { axios } }) => {
    await axios.delete(
      `${API_BASE_URL}/listings/${listing}/provider-contacts/${contact}`
    );

    return { id: contact };
  }
);

export const dispatchListingProviderContact =
  (
    action: string,
    payload: {
      listing: string;
      contact: ListingProviderContact;
    }
  ): AppThunk =>
  async (dispatch) => {
    if (action === 'add')
      return dispatch(
        createListingProviderContact({
          listing: payload.listing,
          contact: payload.contact,
        })
      );
    if (action === 'edit')
      return dispatch(
        updateListingProviderContactById({
          listing: payload.listing,
          contact: payload.contact,
        })
      );
    if (action === 'delete')
      return dispatch(
        deleteListingProviderContactById({
          listing: payload.listing,
          contact: payload.contact.id!,
        })
      );
  };
