import Axios, { AxiosError } from 'axios';
import { Store } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { RootState } from '../store/types.d';
import { getAccessTokenHandler } from './auth';
import { addMessage } from '../store/messenger';
import { getFingerprintHandler } from './fingerprint-handler';

const axios = Axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const axiosInterceptors = (store: Store<RootState>) => {
  axios.interceptors.request.use(async (config) => {
    const { acting } = store.getState();
    const getAccessToken = getAccessTokenHandler();
    const getFingerprint = getFingerprintHandler();

    if (!getAccessToken || !getFingerprint) {
      throw new Error('Authentication not initialized');
    }

    const token = await getAccessToken();
    const fp = await getFingerprint();
    if (acting.user.id) {
      return merge({}, config, {
        headers: {
          Authorization: `Bearer ${token}`,
          'On-Behalf-Of-UserId': acting.user.id,
          'On-Behalf-Of-CompanyAccountId': acting.user.account,
          'On-Behalf-Of-ClaimedCompanyIds': acting.user.companies,
          'On-Behalf-Of-Permissions': acting.user.permissions,
        },
      });
    }

    return merge({}, config, {
      headers: {
        Authorization: `Bearer ${token}`,
        'CS-Visitor': fp?.visitorId,
      },
    });
  });

  axios.interceptors.response.use(
    (successResp) => successResp,
    (errorResp: AxiosError) => {
      if (errorResp.response?.status === 429) {
        store.dispatch(
          addMessage(
            'error',
            'Sorry, you have been blocked from using our services due to excessive usage. Please contact us.',
            'dialog'
          )
        );
      }
      return Promise.reject(errorResp);
    }
  );
};

export default axios;
