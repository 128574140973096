import * as React from 'react';
import { Services } from '../../../types/services';
import { MARKETPLACE_MATCH_SEARCH_URL } from '../../../api';
import {
  formatAddressForSearch,
  formatCategoryForSearch,
  sumScoreReducer,
} from '../../../utilities/marketplace-match';
import { AppDispatch } from '../../../store/types';
import { useDispatch } from 'react-redux';
import {
  addServiceProviderFromProducts,
  resetGatewayProductProviders,
} from '../../../store/gateway-product-providers';
import { useQuery } from 'react-query';
import axios from '../../../services/axios';
import {
  ProviderGatewayResponse,
  ProviderGatewayServiceProvider,
} from '../../../types/provider-gateway';
import { hasPoint } from '../../../utilities/services';

interface ProductGatewayProps {
  products?: Services[];
}

const ProductGateway: React.FC<ProductGatewayProps> = React.memo(
  ({ products }) => {
    const dispatch: AppDispatch = useDispatch();

    const onCompleteQueryCallback = React.useCallback(
      (providers) => {
        dispatch(resetGatewayProductProviders());
        providers.forEach((provider) => {
          dispatch(addServiceProviderFromProducts(provider));
        });
      },
      [dispatch]
    );
    if (products?.length === 0) {
      dispatch(resetGatewayProductProviders());
    }
    useQuery<ProviderGatewayServiceProvider[]>(
      ['marketplace-match', products],
      async () => {
        return await Promise.all(
          products?.map(async (service) => {
            const aEnd = hasPoint(service)
              ? formatAddressForSearch(service.attributes.point_a)
              : null;
            const zEnd = hasPoint(service)
              ? formatAddressForSearch(service.attributes.point_z)
              : null;

            const category = formatCategoryForSearch(service.type);
            return await axios.post<{ data: ProviderGatewayResponse[] }>(
              MARKETPLACE_MATCH_SEARCH_URL,
              {
                product: category,
                providers: [],
                aEnd: aEnd,
                zEnd: zEnd,
              }
            );
          }) ?? []
        ).then((allResponses) => {
          const providersList: ProviderGatewayServiceProvider[] = [];
          allResponses?.forEach((response, index) => {
            const request = JSON.parse(response?.config?.data);
            const serviceType = request.product.subCategory[0];
            const data: ProviderGatewayServiceProvider[] =
              response?.data?.data?.map((item) => {
                const aEndScore =
                  item.products?.aEnd?.reduce(sumScoreReducer, 0) ?? 0;
                const azEndScore =
                  item.products?.aEnd_to_zEnd?.reduce(sumScoreReducer, 0) ?? 0;
                const azEndPresence = item.products?.zEnd
                  ? Object.keys(item.products?.aEnd_to_zEnd).length > 0
                    ? 'onNet'
                    : 'offNet'
                  : item.presence.aEnd;
                const totalScore = item.products?.zEnd
                  ? Object.keys(item.products?.aEnd_to_zEnd).length > 0
                    ? azEndScore
                    : 0
                  : aEndScore;
                return {
                  ...item.serviceProvider,
                  score: totalScore ?? 0,
                  products: {
                    [index]: {
                      serviceType,
                      aEnd: item.presence.aEnd,
                      zEnd: item.presence.zEnd,
                      azEnd: azEndPresence,
                    },
                  },
                };
              });
            providersList.push(...data);
          });
          return providersList;
        });
      },
      {
        enabled: !!products?.length,
        onSuccess: (providers) => {
          onCompleteQueryCallback(providers);
        },
      }
    );
    return null;
  }
);

export default ProductGateway;
