import { styled } from 'twin.macro';
import { TextButton } from '../../components/Cloudscene/Button';
import * as styles from './mobile.styles';

export const Container = styled.div(() => [styles.menu.container]);
export const List = styled.ul(() => [styles.menu.list]);
export const Item = styled.li(() => [styles.menu.item]);
export const Anchor = styled(TextButton)(() => [styles.menu.action]);
export const Back = styled(TextButton)(() => [styles.menu.back]);
export const Separator = styled.div(() => [styles.menu.separator]);
