import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types.d';
import { MARKETPLACE_MATCH_SEARCH_URL } from '../../api';
import {
  MarketplaceMatchResults,
  MarketplaceMatchQuery,
} from '../../types/marketplace-match';

export const marketplaceMatchByAddress = createAsyncThunk<
  { data: MarketplaceMatchResults[] },
  { payload: MarketplaceMatchQuery },
  { extra: ThunkExtra }
>(`marketplace/match`, async ({ payload }, { extra: { axios } }) => {
  const response = await axios.post(MARKETPLACE_MATCH_SEARCH_URL, payload);
  return response.data;
});
