import { useCallback } from 'react';
import { Services } from '../types/services';
import { useAppState } from '../contexts/AppState';
import {
  hasPoint,
  isAddress,
  isFacility,
  isMarketOption,
} from '../utilities/services';
import { MarketEntity } from '../types/market';

export const useMarketsFromService = () => {
  const appState = useAppState();

  return useCallback(
    (service: Services): MarketEntity[] => {
      const markets: MarketEntity[] = [];
      if (hasPoint(service)) {
        const foundMarkets: { market: string | null; zone: string | null }[] =
          [];
        // POINT A
        if (isFacility(service.attributes.point_a)) {
          foundMarkets.push({
            market: service.attributes.point_a.market ?? null,
            zone: service.attributes.point_a.zone ?? null,
          });
        } else if (isAddress(service.attributes.point_a)) {
          foundMarkets.push({
            market: service.attributes.point_a.market ?? null,
            zone: service.attributes.point_a.zone ?? null,
          });
        } else if (isMarketOption(service.attributes.point_a)) {
          foundMarkets.push({
            market: service.attributes.point_a.name ?? null,
            zone: service.attributes.point_a.zone ?? null,
          });
        }

        // POINT Z
        if (isFacility(service.attributes.point_z)) {
          foundMarkets.push({
            market: service.attributes.point_z.market ?? null,
            zone: service.attributes.point_z.zone ?? null,
          });
        } else if (isAddress(service.attributes.point_z)) {
          foundMarkets.push({
            market: service.attributes.point_z.market ?? null,
            zone: service.attributes.point_z.zone ?? null,
          });
        } else if (isMarketOption(service.attributes.point_z)) {
          foundMarkets.push({
            market: service.attributes.point_z.name ?? null,
            zone: service.attributes.point_z.zone ?? null,
          });
        }

        foundMarkets.forEach((foundMarket) => {
          const market = Object.values(appState.markets).find(
            (market) =>
              market.zone === foundMarket.zone &&
              market.name === foundMarket.market
          );
          if (
            market &&
            !markets.find(
              (currentMarket) => currentMarket.attributes.id === market.id
            )
          ) {
            markets.push({
              type: 'listing-market',
              attributes: {
                id: market.id,
                type: market.type,
              },
            });
          }
        });
      }
      return markets;
    },
    [appState]
  );
};
