import {
  Facility,
  Address,
  Services,
  ServiceEthernet,
  ServiceMPLS,
  ServiceDarkFiber,
  ServiceIPTransit,
  ServiceCloudConnectivity,
  ServiceInternetAccess,
  ServiceWavelength,
  ServiceColocation,
} from '../types/services';
import { PhysicalAddress, MarketOption } from '../types/services';

export const isMarketOption = (
  obj?: PhysicalAddress | null
): obj is MarketOption => {
  return obj?.type === 'market';
};

export const isFacility = (obj?: PhysicalAddress | null): obj is Facility => {
  return obj?.type === 'data-center' || obj?.type === 'data_center';
};

export const isAddress = (obj?: PhysicalAddress | null): obj is Address => {
  return obj?.type === 'address';
};

export const hasPoint = (
  service?: Services
): service is
  | ServiceEthernet
  | ServiceMPLS
  | ServiceDarkFiber
  | ServiceIPTransit
  | ServiceCloudConnectivity
  | ServiceInternetAccess
  | ServiceWavelength
  | ServiceColocation => {
  if (service?.type === 'cloud_services' || service?.type === 'sd_wan')
    return false;
  return true;
};
