import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useWebSocket from 'react-use-websocket';

const useProviderGateway = (url: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const getSocketUrl = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return token ? `${url}/query?access_token=${token}` : url;
  }, [getAccessTokenSilently, url]);

  return useWebSocket(getSocketUrl);
};

export default useProviderGateway;
