import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface WrapperProps extends BaseProps {
  helperText?: React.ReactNode;
  tooltip?: React.ReactNode;
  label?: React.ReactNode;
}

interface BaseProps {
  error?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    margin: 'auto 5px',
    color: theme.palette.grey[400],
    fontSize: 16,
  },
}));

function withFormLabel<P extends BaseProps>(
  InputComponent: React.ComponentType<P>
) {
  return ({ tooltip, error, helperText, label, ...rest }: P & WrapperProps) => {
    const classes = useStyles();
    return (
      <Grid container item xs={12} direction="column">
        {(label || tooltip || helperText) && (
          <div className={classes.root}>
            <Box display="flex" alignItems="center">
              {typeof label === 'string' ? (
                <InputLabel disableAnimation>{label}</InputLabel>
              ) : (
                label
              )}

              {tooltip && (
                <Tooltip placement="right" arrow title={tooltip}>
                  <HelpOutlineIcon className={classes.icon} />
                </Tooltip>
              )}
            </Box>
            {helperText && (
              <FormHelperText error={error}>{helperText}</FormHelperText>
            )}
          </div>
        )}
        <InputComponent error={error} {...(rest as P)} />
      </Grid>
    );
  };
}

export default withFormLabel;
