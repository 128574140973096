import React, { ReactElement, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { nanoid } from 'nanoid/non-secure';
import { useDropzone } from 'react-dropzone';
import { makeStyles, lighten } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid, { GridProps } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { FilePill } from '../Pill';
import { formatBytes } from '../../utils';
import { useAppMessenger } from '../../store/messenger';
import { Document } from '../../types/document';
import { uploadDocument } from '../../api';

const userStyles = makeStyles((theme) => {
  const greyHoverColor = lighten(theme.palette.grey[100], 0.9);
  const greyColorActive = lighten(theme.palette.grey[100], 0.7);

  return {
    root: {
      padding: theme.spacing(1),
    },
    dropZone: {
      display: 'flex',
      width: '100%',
      minHeight: 200,
      background: theme.palette.common.white,
      flexDirection: 'column',
      color: theme.palette.grey[400],
      border: `2px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
      transition: theme.transitions.create('all', {
        easing: 'ease-in-out',
      }),
    },
    default: {
      outline: 'none',
      '&:hover, &:hover button': {
        cursor: 'pointer',
      },
      '&:focus, &:active': {
        borderColor: theme.palette.primary.main,
      },
    },
    defaultActive: {
      backgroundColor: lighten(theme.palette.primary.light, 0.8),
    },
    browseBtn: {
      color: theme.palette.primary.main,
    },
    text: {
      fontWeight: 800,
    },
    textActive: {
      color: theme.palette.grey[500],
    },
    fileUploadIcon: {
      color: theme.palette.grey[300],
    },
    fileUploadIconActive: {
      color: theme.palette.grey[500],
    },
    light: {
      '&:hover, &:hover button': {
        cursor: 'pointer',
        backgroundColor: greyHoverColor,
      },
    },
    lightActive: {
      backgroundColor: greyColorActive,
    },
    loadedFileChip: {
      maxWidth: '100%',
      margin: theme.spacing(1, 1, 0, 0),

      [theme.breakpoints.up(400)]: {
        maxWidth: 300,
      },
    },
  };
});

type FileType = {
  id: string;
  name: string;
  size: number;
  loaded: number;
  cancel?: () => void;
};

type FileUploadProps = GridProps & {
  color?: 'default' | 'lightGrey';
  onAdd?: (file: Document) => void;
};

const MAX_SIZE = 5242880;

const FileUpload: React.FC<Omit<FileUploadProps, 'css'>> = ({
  onAdd,
  color = 'default',
  children,
  ...props
}): ReactElement => {
  const classes = userStyles();
  const [files, setFiles] = useState<FileType[]>([]);
  const messenger = useAppMessenger();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    maxSize: MAX_SIZE,
    onDrop: (acceptedFiles, rejectedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');

        reader.onload = () => {
          const id = nanoid();

          // Add a file stub locally
          setFiles((files) => [
            ...files,
            {
              id,
              name: file.name,
              size: file.size,
              loaded: 0,
            },
          ]);

          // https://github.com/axios/axios/blob/master/examples/upload/index.html
          const data = new FormData();
          // Create a token allowing the request to be cancelled
          const source = axios.CancelToken.source();
          data.append('data[document]', file);

          const onUploadProgress = (event: {
            loaded: number;
            total: number;
          }) => {
            setFiles((files) => {
              // Update `loaded` and `size` properties of the current file
              return files.map((file) => {
                if (file.id !== id) {
                  return file;
                }

                file.loaded = event.loaded;
                file.size = event.total;
                // Files can only be cancelled while in local state
                file.cancel = source.cancel;

                return file;
              });
            });
          };

          uploadDocument(data, { onUploadProgress, cancelToken: source.token })
            .then((res) => {
              // The remote document object is "done"
              if (onAdd) {
                onAdd(res.data.data);
              }

              // File completed and is no longer handled locally
              setFiles((state: FileType[]) =>
                state.filter((file) => file.id !== id)
              );
            })
            .catch((err) => {
              // Request error or upload cancelled
              setFiles((state: FileType[]) =>
                state.filter((file) => file.id !== id)
              );

              messenger.addMessage(
                'error',
                'There was a problem uploading your document'
              );
            });
        };

        reader.readAsArrayBuffer(file);
      });

      rejectedFiles.forEach(({ file }) => {
        if (file.size > MAX_SIZE) {
          messenger.addMessage(
            'error',
            `File size is too large to upload. Max size is ${formatBytes(
              MAX_SIZE
            )} and your file size is: ${formatBytes(file.size)}`
          );
        } else {
          messenger.addMessage(
            'error',
            `File type: ${file.type} is not supported`
          );
        }
      });
    },

    accept: [
      // word
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      // excel
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // csv
      'text/csv',
      'text/plain',
      'text/x-csv',
      // pdf
      'application/pdf',
      // images
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/png',
      // powerpoint
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      // Keyhole Markup Language
      'application/vnd.google-earth.kml+xml',
      '.kml', // because mime type 👆 support in missing
      'application/vnd.google-earth.kmz',
      '.kmz', // because mime type 👆 support in missing
      // other
      'application/vnd.oasis.opendocument.text',
      'text/plain',
      'application/rtf',
    ],
  });

  const isDefault = color === 'default';
  const isLight = color === 'lightGrey';

  return (
    <Grid container item spacing={2} className={classes.root} {...props}>
      <div
        className={clsx(classes.dropZone, {
          [classes.default]: isDefault,
          [classes.defaultActive]: isDefault && isDragActive,
          [classes.light]: isLight,
          [classes.lightActive]: isLight && isDragActive,
        })}
        {...getRootProps()}
      >
        <input className="dropzone-input" {...getInputProps()} />
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CloudUploadOutlinedIcon
              fontSize="large"
              className={clsx(classes.fileUploadIcon, {
                [classes.fileUploadIconActive]: isDragActive,
              })}
            />
          </Grid>
          <Grid item>
            {isDragActive ? (
              <Typography
                className={clsx(classes.text, classes.textActive)}
                variant="body1"
              >
                Release to drop the files here
              </Typography>
            ) : (
              <Typography className={classes.text} variant="body1">
                Drop files here or{' '}
                <span className={classes.browseBtn}>browse</span> to upload
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
      {files.length > 0 && (
        <Box my={1}>
          <Grid container spacing={2}>
            {files.map((file) => (
              <Grid key={file.id} item xs="auto">
                <FilePill
                  label={file.name}
                  fileSize={file.size}
                  loaded={file.loaded}
                  onDelete={() => {
                    if (file.cancel) {
                      file.cancel();
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {children}
    </Grid>
  );
};

export default FileUpload;
