import React, { useCallback, useEffect } from 'react';
import { dequal } from 'dequal/lite';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Form, FormRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Mutator } from 'final-form';
import InfoBlock from '../../components/InfoBlock';
import CreateBidForm from '../../components/RegisterBid';
import BidderInfoBlock from '../../components/BidderInfoBlock';
import { Currencies } from '../../constants';
import { CreateBidFormState, QuotePricing } from '../../types/bid';
import { useAuth } from '../../hooks/useAuth';
import { usePrevious } from '../../hooks/usePrevious';
import {
  makeBidRequest,
  makeBidServiceRequest,
  makeBidPricingOptionRequest,
} from './make-bid';
import { selectAllServices } from '../../store/services';
import validationSchema from './validation';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import {
  useCreateBidMutation,
  useCreateBidServiceMutation,
  useCreateBidPricingOptionMutation,
  useLockBidMutation,
} from '../../services/api/marketplace-service/bids';
import { ListingServiceResponse } from '../../types/marketplace-service/listing-service';
import { ListingResponse } from '../../types/marketplace-service/listing';
import { useAppSelector } from '../../store/hooks';

interface CreateBidProps {
  listing: ListingResponse;
  onSuccess?: () => void;
  onError?: () => void;
  focusForm?: boolean;
  onFocusBidForm?: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.only('xs')]: {
        margin: theme.spacing(2, -2, 4, -2),
        overflowX: 'hidden',
      },
    },
    bidder: {
      padding: theme.spacing(2),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(1, 2),
      },
    },
    formWrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.only('xs')]: {
        maxWidth: 'none',
        margin: theme.spacing(0, -2, 4, -2),
      },
    },
  })
);

const addPricingOption: Mutator<CreateBidFormState> = (
  [name],
  state,
  { changeValue }
) => {
  changeValue(state, name, (options: QuotePricing[]): QuotePricing[] => [
    ...options,
    {
      contractTerm: null,
      recurringCost: { value: 0, unit: Currencies.USD },
      nonRecurringCost: {
        value: 0,
        unit: Currencies.USD,
      },
    },
  ]);
};

const resetForm: Mutator<CreateBidFormState> = (
  [name],
  state,
  { changeValue }
) => {
  const initialValues = state.formState.initialValues as CreateBidFormState;
  changeValue(state, 'installLeadTime', () => initialValues!.installLeadTime);
  changeValue(state, 'termsConditions', () => initialValues!.termsConditions);
  changeValue(
    state,
    'pointOfDifference',
    () => initialValues!.pointOfDifference
  );
  changeValue(state, 'contacts', () => initialValues!.contacts);
  changeValue(state, 'documents', () => initialValues!.documents);
  changeValue(state, 'quotes', () => initialValues!.quotes);
};

const createInitialValues = (
  services: ListingServiceResponse[]
): CreateBidFormState => {
  return {
    installLeadTime: '',
    termsConditions: '',
    pointOfDifference: '',
    quotes: services.map((service) => ({
      service,
      optOut: false,
      additionalInfo: '',
      pricing: [
        {
          contractTerm: null,
          recurringCost: { value: 0, unit: Currencies.USD },
          nonRecurringCost: { value: 0, unit: Currencies.USD },
        },
      ],
    })),
    contacts: {},
    documents: [],
  };
};

const CreateBid = React.forwardRef<HTMLElement, CreateBidProps>(
  (
    {
      listing,
      onSuccess = () => {},
      onError = () => {},
      focusForm,
      onFocusBidForm = () => false,
    },
    ref
  ) => {
    const id = usePrevious(listing.id);
    const classes = useStyles();
    const [createBidMutation] = useCreateBidMutation();
    const [createBidServiceMutation] = useCreateBidServiceMutation();
    const [createBidPricingOptionMutation] =
      useCreateBidPricingOptionMutation();
    const [lockBidMutation] = useLockBidMutation();
    const { user } = useAuth();
    const listingServices = listing.relationships?.services?.data ?? [];
    const allServices = useAppSelector(selectAllServices);
    const services = listingServices
      .map((service) => allServices?.find((srv) => srv.id === service.id))
      .filter((service) => !!service) as ListingServiceResponse[];
    const validator = useValidationSchema(validationSchema);
    const initialValues = createInitialValues(services);

    const handleSubmit = useCallback(
      async (values: CreateBidFormState) => {
        try {
          // create the new bid entity
          const bid = await createBidMutation({
            payload: makeBidRequest(values, listing.id, user),
          }).unwrap();

          // create a new bid service for each quoted item
          for (const quote of values.quotes) {
            const service = await createBidServiceMutation({
              bid: bid.entity.id,
              payload: makeBidServiceRequest(
                quote.additionalInfo,
                quote.service
              ),
            }).unwrap();

            // create a new bid pricing option for all non-opt-out quotes
            if (
              quote.service.attributes.type !== 'multi_service_requirements' &&
              !quote.optOut
            ) {
              for (const price of quote.pricing) {
                await createBidPricingOptionMutation({
                  bid: bid.entity.id,
                  service: service.entity.id,
                  payload: makeBidPricingOptionRequest(service.entity, price),
                }).unwrap();
              }
            }
          }

          // lock the bid when all services and pricing options are created
          await lockBidMutation({ id: bid.entity.id }).unwrap();

          onSuccess();
        } catch (error) {
          onError();
        }
      },
      [
        createBidMutation,
        createBidServiceMutation,
        createBidPricingOptionMutation,
        lockBidMutation,
        onError,
        onSuccess,
        listing.id,
        user,
      ]
    );

    useEffect(() => {
      if (focusForm) {
        onFocusBidForm();
      }
    }, [focusForm, onFocusBidForm]);

    return (
      <Paper elevation={4} className={classes.root} ref={ref}>
        <Grid container>
          {user && (
            <Grid item xs={12} className={classes.bidder}>
              <BidderInfoBlock
                id={user.id}
                name={user.name}
                company={user.employer}
                email={user.email}
                phone={user.phone}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.bidder}>
            <InfoBlock>
              Winning new business opportunities starts with good communication.
              Please be clear and descriptive about how you can service this
              customer, and always provide pricing where possible.
            </InfoBlock>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <Form
            ref={ref}
            initialValues={initialValues}
            validate={validator}
            keepDirtyOnReinitialize={listing.id === id}
            initialValuesEqual={(a, b) => dequal(a, b)}
            onSubmit={handleSubmit}
            subscription={{ pristine: true }}
            mutators={{
              ...arrayMutators,
              addPricingOption,
              resetForm,
            }}
          >
            {(props: FormRenderProps<CreateBidFormState>) => (
              <CreateBidForm {...props} />
            )}
          </Form>
        </Grid>
      </Paper>
    );
  }
);

export default CreateBid;
