import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export enum StatusLabels {
  invited = 'Invited',
  opportunity = 'Opportunity',
  intend = 'Intend to Bid',
  submitted = 'Submitted',
  bid = 'Submitted',
  declined = 'No Bid',
  unsubmitted = 'No Response',
  shortlisted = 'Shortlisted',
  successful = 'Successful',
  unsuccessful = 'Unsuccessful',
  pending = 'Pending',
  scheduled = 'Scheduled',
  active = 'Active',
  review = 'Review',
  complete = 'Complete',
  unassigned = 'Unassigned',
  archived = 'Archived',
  qualified = 'Qualified',
  nobid = 'No Bid Submitted',
  actioned = 'Actioned',
  closed = 'Closed',
  assigned = 'Assigned',
}

interface ListingStatusLabelProps {
  variant?: keyof typeof StatusLabels;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1,
      padding: '4px 8px',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
    },

    // LEGACY
    archived: {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[200],
    },

    // CHECK
    intend: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.dark,
    },
    review: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.dark,
    },
    shortlisted: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.light,
    },

    // DONE
    invited: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
    },
    opportunity: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
    },
    complete: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
    },
    unassigned: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
    },
    assigned: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
    },

    // YAY!
    active: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    bid: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    submitted: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    successful: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    qualified: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    actioned: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },

    // WARN
    pending: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
    },

    scheduled: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
    },

    // ERR
    unsubmitted: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    unsuccessful: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    declined: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    nobid: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
  })
);

const ListingStatusLabel: React.FC<ListingStatusLabelProps> = ({
  variant,
  children,
  ...props
}) => {
  const classes = useStyles();

  if (!variant) {
    return null;
  }

  return (
    <span
      {...props}
      className={clsx(classes.root, {
        [classes[variant]]: true,
      })}
    >
      {StatusLabels[variant]}
    </span>
  );
};

export default ListingStatusLabel;
