import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  Divider,
  Grid,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { navigate } from '@reach/router';

import UnstyledLink from '../../components/UnstyledLink';
import { useAppState } from '../../contexts/AppState';
import { useAuth } from '../../hooks/useAuth';
import { useUserQuery } from '../../hooks/useUserQuery';
import { ROUTES } from '../../routes';
import { useUpdateMarketplaceLeadByIDMutation } from '../../services/api/marketplace-service/gql/marketplace-leads';
import { useAppMessenger } from '../../store/messenger';
import { MarketplaceLead } from '../../types/marketplace-service/gql/marketplace-leads';
import { isSalesManager } from '../../utilities/users';
import AssignOpportunity from '../AssignOpportunity';
import LeadActionModal from './LeadActionModal';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: '100%',
    width: 450,
  },
  emptyText: {
    fontWeight: 'normal',
  },
  label: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      fontSize: '13px',
      padding: theme.spacing(1, 1),
    },
  },
  message: {
    padding: theme.spacing(2),
    background: '#f1f3fa',
    borderRadius: '8px',
  },
  icon: {
    display: 'block',
  },
  gap: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    margin: theme.spacing(2, 0),
  },
}));

const MarketLocationName = ({ id }: { id: string }) => {
  const state = useAppState();

  const market = state.markets[id];

  return (
    <Typography>
      {market?.name}, {market?.zone}
    </Typography>
  );
};

const LeadDetails = ({ lead }: { lead: MarketplaceLead }) => {
  const classes = useStyles();
  const messenger = useAppMessenger();
  const [assignModal, setAssignModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const { user } = useUserQuery(lead.external_assigned_to_user_id);
  const services = useMemo(() => {
    if (lead.services.length === 0) {
      return 'Other Services';
    }
    return lead.services
      .map((service) => {
        return service.external_service_name;
      })
      .join(', ');
  }, [lead.services]);

  const businessType =
    lead.business_type === 'BUSINESS' ? 'Business' : 'Wholesale';

  const auth = useAuth();
  const userIsSalesManager = isSalesManager(auth.user);

  const [updatepdateMarketplaceLeadByID] =
    useUpdateMarketplaceLeadByIDMutation();

  const handleAssignListing = useCallback(
    (user) => {
      return updatepdateMarketplaceLeadByID({
        id: lead.id,
        payload: {
          id: lead.id,
          external_assigned_to_user_id: user.id,
          state: 'ASSIGNED',
        },
      })
        .unwrap()
        .then(() => {
          setAssignModal(false);
          messenger.addMessage('info', 'This lead has been updated');
          navigate(`${ROUTES.leads}/${lead.short_id}`, { replace: true });
        })
        .catch(() => {
          messenger.addMessage('error', 'Error while assigning user');
        });
    },
    [lead, messenger, updatepdateMarketplaceLeadByID]
  );

  const handleActionListing = useCallback(() => {
    return updatepdateMarketplaceLeadByID({
      id: lead.id,
      payload: {
        id: lead.id,
        state: 'ACTIONED',
      },
    })
      .unwrap()
      .then(() => {
        setActionModal(false);
        messenger.addMessage('info', 'This lead has been updated');
        navigate(`${ROUTES.leads}/${lead.short_id}`, { replace: true });
      })
      .catch(() => {
        messenger.addMessage('error', 'Error while updating state');
      });
  }, [lead, messenger, updatepdateMarketplaceLeadByID]);

  return (
    <>
      <Grid container alignItems="center" className={classes.gap}>
        <Grid item xs={4}>
          <UnstyledLink to={ROUTES.leads}>
            <Button
              color="primary"
              endIcon={<ForwardIcon>forward</ForwardIcon>}
              variant="text"
              size="small"
            >
              Close
            </Button>
          </UnstyledLink>
        </Grid>
      </Grid>
      {lead.state === 'APPROVED' && (
        <Grid item xs={12} className={classes.message}>
          <div className={classes.message}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <InfoIcon className={classes.icon} fontSize="small" />
              </Grid>
              <Grid item xs>
                <Typography component="div" variant="body2" color="textPrimary">
                  Your team has a new direct lead. Assign it to a team member
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      {user && lead.state === 'ASSIGNED' && (
        <Grid item xs={12} className={classes.message}>
          <div className={classes.message}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <InfoIcon className={classes.icon} fontSize="small" />
              </Grid>
              <Grid item xs>
                <Typography component="div" variant="body2" color="textPrimary">
                  {user.attributes.firstName} {user.attributes.lastName} is the
                  owner of this contact request
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      <Grid item xs={12} className={classes.actions}>
        <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
          {lead.state === 'APPROVED' && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setAssignModal(true)}
              >
                Assign Request
              </Button>
            </Grid>
          )}
          {lead.state === 'ASSIGNED' && userIsSalesManager && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setAssignModal(true)}
              >
                Reassign
              </Button>
            </Grid>
          )}
          {lead.state === 'ASSIGNED' && (
            <Grid item>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={() => setActionModal(true)}
              >
                Actioned
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <AssignOpportunity
        open={assignModal}
        onClose={() => setAssignModal(false)}
        assignListingOwner={true}
        onAssign={handleAssignListing}
      />
      <LeadActionModal
        open={actionModal}
        onClose={() => setActionModal(false)}
        onConfirm={handleActionListing}
      />
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h6">
              {services} in {lead.locations.length}{' '}
              {lead.locations.length === 1 ? 'location' : 'locations'}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>Services</Typography>
            <Typography>{services}</Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>Locations</Typography>
            {lead.locations.map((location) => {
              return <MarketLocationName id={location.external_location_id} />;
            })}
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>
              Business Type / Pricing
            </Typography>
            <Typography>{businessType}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.label}>
              Additional Information
            </Typography>
            <Typography>
              {lead.additional_info === '' ? '-' : lead.additional_info}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h6">
              Customer Details
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>Name</Typography>
            <Typography>
              {lead.first_name} {lead.last_name}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>Email</Typography>
            <Typography>{lead.email === '' ? '-' : lead.email}</Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>Phone</Typography>
            <Typography>{lead.phone === '' ? '-' : lead.phone}</Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography className={classes.label}>Country</Typography>
            <Typography>{lead.country === '' ? '-' : lead.country}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LeadDetails;
