import React from 'react';
import { FilePill } from '../Pill';
import { Document } from '../../types/document';

interface AttachmentListProps {
  document?: Document;
  onDelete?: (id: string) => void;
}

const AttachmentList: React.FC<AttachmentListProps> = ({
  document,
  onDelete,
}) => {
  if (!document) {
    return null;
  }
  const [, name] = document.attributes?.file_name.split('/');
  return (
    <FilePill
      label={name}
      fileSize={document.attributes!.file_size ?? 0}
      onClick={
        document.attributes?.link
          ? () => {
              window.open(document.attributes!.link, '_blank');
            }
          : undefined
      }
      onDelete={onDelete ? () => onDelete(document.id) : undefined}
    />
  );
};

export default AttachmentList;
