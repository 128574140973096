export const concatAndDeDuplicateObjectsDeep = (p, ...arrs) =>
  []
    .concat(...arrs)
    .reduce(
      (a, b) => (!a.filter((c) => b[p] === c[p]).length ? [...a, b] : a),
      []
    );

/*
 * Split an array (T[]) into an array of arrays (T[][]) of size (N)
 */
export const chunk = <T>(arr: T[] = [], size = 1) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
    arr.slice(index * size, index * size + size)
  );

export const hasIntersection = (
  value: string | string[] | null = [],
  arr: string[] | null = []
) => {
  if (value === null || arr === null) return false;

  if (!value.length || !arr.length) {
    return false;
  }

  if (typeof value === 'string') {
    return arr.some((item) => [value].includes(item));
  }

  if (Array.isArray(value)) {
    return arr.some((item) => value.includes(item));
  }

  return false;
};
