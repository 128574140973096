import React, { useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import UnsuccessfulProvider from '../BidActions/UnsuccessfulProvider';
import { BidResponse, BidState } from '../../types/marketplace-service/bid';
import { useUpdateBidByIDMutation } from '../../services/api/marketplace-service/bids';

interface UnsuccessfulProvidersProps {
  bids: BidResponse[];
  bid: BidResponse;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    reason: {
      margin: theme.spacing(-2, 2, 0, 2),
    },
    view: {
      textAlign: 'right',
      margin: theme.spacing(0, 2),
    },
    text: {
      margin: theme.spacing(4, 0),
    },
  })
);

const UnsuccessfulProviders: React.FC<UnsuccessfulProvidersProps> = ({
  bids,
  bid,
}) => {
  const classes = useStyles();
  const [updateBidByIDMutation] = useUpdateBidByIDMutation();
  const handleBidUpdate = useCallback(
    (state: BidState, bid: BidResponse, reason?: string) =>
      updateBidByIDMutation({
        id: bid.id,
        payload: {
          id: bid.id,
          type: 'bid',
          attributes: {
            state,
            state_uninterested_reason: reason,
          },
        },
      }).unwrap(),
    [updateBidByIDMutation]
  );

  if (bids.length === 0) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography
          component="div"
          variant="body2"
          color="textSecondary"
          align="center"
        >
          If you don&apos;t have any other providers to mark as successful, you
          can complete your listing by letting the other service providers know
          that they were unsuccessful.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" align="center" className={classes.text}>
          Unsuccessful Providers
        </Typography>
      </Grid>
      {bids.map((bid) => (
        <Grid item xs={12}>
          <UnsuccessfulProvider
            key={bid.id}
            bid={bid}
            onUnsuccessfulReasonChange={(bid, reason) =>
              handleBidUpdate('unsuccessful', bid, reason)
            }
          />
        </Grid>
      ))}
    </>
  );
};

export default UnsuccessfulProviders;
