import React, { useState } from 'react';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '../CSTextField';
import useAddressPredictions from './useAddressPredictions';
import useFacilityPredictions from './useFacilityPredictions';
import useMarketsPredictions from './useMarketsPredictions';
import { MarketOption } from '../../../types/services';
import { useGeoCoder } from '../../../hooks/useGeoCoder';
import { Address, Facility } from '../../../types/services';
import {
  isAddress,
  isFacility,
  isMarketOption,
} from '../../../utilities/services';
import { getMarketByCoords } from '../../../api';

interface PlacesAutocompleteProps {
  label?: string;
  selected: Address | Facility | MarketOption | null;
  onSelect: (place: Address | Facility | MarketOption | null) => void;
  filterOptionType?: ('address' | 'data-center' | 'data_center' | 'market')[];
}

const useAutocompleteStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0.5, 0),
      border: `2px solid ${theme.palette.grey[100]}`,
      borderRadius: 6,
    },
    endAdornment: {
      top: 'calc(50% - 16px)',
      right: theme.spacing(1),
    },
  })
);
const useStyles = makeStyles((theme) =>
  createStyles({
    highlight: {
      fontWeight: theme.typography.fontWeightBold,
    },
    logo: {
      width: 40,
      height: 40,
      margin: theme.spacing(2),
      borderRadius: 100,
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
    },
    image: {
      width: 30,
    },
  })
);

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({
  label,
  selected = null,
  onSelect,
  filterOptionType,
}) => {
  const { getCoords } = useGeoCoder();
  const autocompleteClasses = useAutocompleteStyles();
  const classes = useStyles();
  const [value, setValue] = useState<string>('');
  const [addressPredictions, clearAddressPredictions] =
    useAddressPredictions(value);
  const [facilityPredictions, clearFacilityPredictions] =
    useFacilityPredictions(value);
  const [marketPredictions, clearMarketPredictions] =
    useMarketsPredictions(value);
  const options = [
    ...facilityPredictions,
    ...addressPredictions,
    ...marketPredictions,
  ];

  return (
    <Autocomplete
      open={!selected && options.length > 0}
      classes={autocompleteClasses}
      getOptionLabel={(option) => (option ? option.description : '')}
      getOptionSelected={(option, value) => option.id === value.id}
      options={options}
      filterOptions={
        filterOptionType
          ? (options) =>
              options.filter((option) => filterOptionType.includes(option.type))
          : undefined
      }
      autoComplete
      autoHighlight
      autoSelect
      includeInputInList
      inputValue={value}
      value={selected}
      onChange={async (_event, value) => {
        if (typeof value === 'string') return;
        if (!value) {
          onSelect(value);
        } else if (isAddress(value)) {
          /*
            Google rate limits if we try to attach this to every result in useAddressPredictions
          */
          const location = await getCoords(value.id);
          if (location) {
            const { lat, lng } = location;
            const market = await getMarketByCoords(
              lat.toString(),
              lng.toString()
            );

            if (market) {
              value.market = market.name;
              value.zone = market.countryName;
            }
          }
          onSelect(value);
        } else if (isFacility(value) || isMarketOption(value)) {
          onSelect(value);
        }

        clearAddressPredictions();
        clearFacilityPredictions();
        clearMarketPredictions();
      }}
      onInputChange={(_event, input) => {
        if (input !== selected?.description) {
          onSelect(null);
        }
        setValue(input);
      }}
      renderInput={({
        id,
        disabled,
        InputProps: { ref, className, ...InputProps },
        inputProps,
      }) => (
        <TextField
          label={label}
          id={id}
          disabled={disabled}
          inputProps={inputProps}
          innerRef={ref}
          {...InputProps}
        />
      )}
      renderOption={(option) => {
        const matches = match(option.description, value);
        const parts = parse(option.description, matches);

        return (
          <Grid container spacing={2} alignItems="center">
            {option.type === 'data-center' && (
              <Grid
                container
                alignContent="center"
                justifyContent="center"
                item
                className={classes.logo}
              >
                <img
                  className={classes.image}
                  src={option.logo}
                  alt={option.description}
                />
              </Grid>
            )}
            <Grid item xs>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  display="inline"
                  className={clsx({ [classes.highlight]: part.highlight })}
                >
                  {part.text}
                </Typography>
              ))}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default PlacesAutocomplete;
