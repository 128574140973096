import { createApi } from '@reduxjs/toolkit/query/react';
import { MARKETPLACE_SERVICE_API } from '../../../api';
import { staggeredBaseQueryWithBailOut } from '../fetch-base-query';

export const marketplaceServiceAPI = createApi({
  reducerPath: 'marketplaceServiceAPI',
  baseQuery: staggeredBaseQueryWithBailOut(MARKETPLACE_SERVICE_API),
  tagTypes: [
    'Listing',
    'ListingProvider',
    'Bid',
    'BidService',
    'BidPricingOption',
    'ListingProviderContact',
    'ServiceProviderContact',
    'ListingMarket',
  ],
  endpoints: () => ({}),
});
