import { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import usePortal from 'react-useportal';
import tw, { css } from 'twin.macro';
import {
  Adornment,
  SecondaryButton,
} from '../../../components/Cloudscene/Button';
import { Typography } from '../../../components/Cloudscene/Typography';
import { STRIPE_TIMEOUT } from '../../../hooks/useStripeCheckout';

export const Overlay = ({ onCancel }: { onCancel: () => void }) => {
  const [showClose, setShowClose] = useState(false);
  const { Portal, ref } = usePortal({
    closeOnEsc: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowClose(true);
    }, STRIPE_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Portal>
      <div ref={ref}>
        <div
          css={[
            tw`fixed h-screen w-screen max-h-screen max-w-full bg-black bg-opacity-75 top-0 z-modal px-6`,
          ]}
        >
          <div
            css={[
              tw`absolute grid grid-flow-row gap-4 justify-items-center`,
              css`
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              `,
            ]}
          >
            <Typography css={tw`text-white`}>
              Please don&#39;t close this window during checkout
            </Typography>
            {showClose && (
              <SecondaryButton onClick={onCancel} variant="danger">
                <Adornment start={<FiX size={20} />}>
                  Cancel checkout and close
                </Adornment>
              </SecondaryButton>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};
