import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

interface TagProps {
  slice: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 200,
      borderRadius: 4,
      height: 24,
    },
  })
);

export const Tag = ({ children }: { children: string }) => {
  const classes = useStyles();
  return <Chip classes={classes} label={children} />;
};

const Tags: React.FC<TagProps> = ({ slice, children }) => {
  const tags = React.Children.toArray(children);

  if (tags.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      {tags.slice(0, slice).map((tag, index: number) => {
        return (
          <Grid key={index} item xs="auto">
            {tag}
          </Grid>
        );
      })}
      {tags.length > slice && (
        <Grid item xs="auto">
          <Tag>{`+ ${tags.length - slice}`}</Tag>
        </Grid>
      )}
    </Grid>
  );
};

export default Tags;
