import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../api';
import { Listing } from '../../types/listing';
import { ListingService } from '../../types/services';
import {
  ListingProviderResponse,
  ListingProviderContact,
} from '../../types/marketplace-service/listing-provider';
import { Bid } from '../../types/bid';
import { ThunkExtra } from '../types.d';

export const fetchAllOpportunities = createAsyncThunk<
  {
    data: ListingProviderResponse[];
    included?: (Listing | ListingService | Bid)[];
  },
  void,
  { extra: ThunkExtra }
>('opportunity/fetchAll', async (_, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/listing-providers`, {
    params: {
      include: 'listing,bid',
    },
  });
  return response.data;
});

export const registerOpportunity = createAsyncThunk<
  {
    data: ListingProviderResponse;
    included?: (Listing | ListingService | Bid)[];
  },
  {
    listing: string;
    provider?: string;
  },
  { extra: ThunkExtra; rejectValue: string }
>(
  'opportunity/register',
  async ({ listing, provider }, { extra: { axios }, rejectWithValue }) => {
    const data = {
      type: 'listing-provider',
      attributes: {
        state: 'intend',
      },
    };

    if (provider) {
      try {
        const response = await axios.patch(
          `${API_BASE_URL}/listings/${listing}/providers/${provider}`,
          { data },
          { params: { include: 'listing,bid' } }
        );

        return response.data;
      } catch (err: any) {
        return rejectWithValue(
          err.response.status === 403
            ? 'Please contact your sales manager to be assigned this opportunity'
            : 'Error while intending to bid'
        );
      }
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/listings/${listing}/providers`,
        { data },
        { params: { include: 'listing,bid' } }
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.response.status === 403
          ? 'Please contact your sales manager to be assigned this opportunity'
          : 'Error while intending to bid'
      );
    }
  }
);

export const assignOpportunity = createAsyncThunk<
  {
    data: ListingProviderResponse;
    included?: (Listing | ListingService | Bid)[];
  },
  {
    listing: string;
    user: string | null;
    assigned_to_contact?: ListingProviderContact;
    provider?: string;
  },
  { extra: ThunkExtra; rejectValue: string }
>(
  'opportunity/assignUser',
  async (
    { listing, provider, user, assigned_to_contact },
    { extra: { axios }, rejectWithValue }
  ) => {
    const data = {
      type: 'listing-provider',
      attributes: {
        state: 'assign',
        external_assigned_to_user_id: user,
        assigned_to_contact: assigned_to_contact,
      },
    };

    if (provider) {
      try {
        const response = await axios.patch(
          `${API_BASE_URL}/listings/${listing}/providers/${provider}`,
          { data },
          { params: { include: 'listing,bid' } }
        );

        return response.data;
      } catch (err: any) {
        return rejectWithValue(
          err.response.status === 403
            ? 'Please contact your sales manager in order to reassign this opportunity'
            : 'Error while assigning opportunity'
        );
      }
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/listings/${listing}/providers`,
        { data },
        { params: { include: 'listing,bid' } }
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.response.status === 403
          ? 'Please contact your sales manager in order to reassign this opportunity'
          : 'Error while assigning opportunity'
      );
    }
  }
);

export const declineOpportunity = createAsyncThunk<
  {
    data: ListingProviderResponse;
    included?: (Listing | ListingService | Bid)[];
  },
  { listing: string; provider: string; reason: string },
  { extra: ThunkExtra; rejectValue: string }
>(
  'opportunity/decline',
  async (
    { listing, provider, reason },
    { extra: { axios }, rejectWithValue }
  ) => {
    const data = {
      type: 'listing-provider',
      attributes: {
        state: 'decline',
        declined_reason: reason,
      },
    };

    try {
      const response = await axios.patch(
        `${API_BASE_URL}/listings/${listing}/providers/${provider}`,
        { data },
        { params: { include: 'listing,bid' } }
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.response.status === 403
          ? 'Please contact your sales manager to decline this opportunity'
          : 'Error declining opportunity'
      );
    }
  }
);
