import React from 'react';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import LogoLoader from '../Logo/LogoCloudLoader';

const FullScreenLoader = () => (
  <Zoom in mountOnEnter appear>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        left: 0,
        top: 0,
        background: 'white',
      }}
    >
      <LogoLoader />
    </Grid>
  </Zoom>
);

export default FullScreenLoader;
