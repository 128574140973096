import { ReactNode } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import tw, { theme } from 'twin.macro';
import { Adornment, TextButton } from '../../../components/Cloudscene/Button';
import { FloatingAction } from '../../../components/FloatingAction';

type BaseMenuProps = { id: string; label: ReactNode; action?: ReactNode };
type WithHrefProps = { href: string; children?: never };
type WithChildrenProps = {
  href?: never;
  children: ReactNode;
};

export type MenuProps =
  | (BaseMenuProps & WithHrefProps)
  | (BaseMenuProps & WithChildrenProps);

export const Menu = ({
  id,
  label,
  href,
  children,
  action = children && (
    <TextButton id={id} css={tw`truncate p-1 text-sm`}>
      <Adornment
        end={<HiChevronDown color={theme`colors.current`} size={20} />}
      >
        {label}
      </Adornment>
    </TextButton>
  ),
}: MenuProps) => {
  if (!children && href) {
    return (
      <a href={href}>
        <TextButton as="a" id={id} css={tw`truncate p-1 text-sm`}>
          {label}
        </TextButton>
      </a>
    );
  }

  return (
    <FloatingAction
      trigger={['click', 'hover']}
      origin="bottom-start"
      action={action}
    >
      {children}
    </FloatingAction>
  );
};
