import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  formatISO,
  parseISO,
  differenceInCalendarDays,
  addDays,
} from 'date-fns';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import InputWithDynamicHelperText from '../../components/Form/InputWithDynamicHelperText';
import CSDateTimeField from '../../components/Form/CSDateTimeField';
import ListingStartDate from '../../components/ListingStartDate';

interface FormSectionProps {
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    durationWrapper: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
    },
    duration: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 0,
        top: theme.spacing(4),
        align: 'right',
      },
    },
    heading: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      width: '100%',
      padding: theme.spacing(0, 1),
    },
  })
);

const ListingDetails: React.FC<FormSectionProps> = ({ name }) => {
  const classes = useStyles();
  const today = new Date();

  return (
    <Box className={classes.root}>
      <Field name={`${name}.title`}>
        {({ input, meta }) => (
          <InputWithDynamicHelperText
            title="Title*"
            tooltip="Describe your requirements at a high level with some key details."
            {...input}
            error={meta.touched && !!meta.error}
          />
        )}
      </Field>
      <Grid container spacing={2} alignContent="flex-end" alignItems="center">
        <Typography className={classes.heading} component="div">
          Start Date
        </Typography>
        <Grid item xs="auto">
          <FormSpy subscription={{ pristine: true, values: true }}>
            {({ values, form }) => {
              return (
                <ListingStartDate
                  start={values.attributes.start}
                  scheduled={values.attributes.scheduled}
                  state={values.attributes.state}
                  name={name}
                  onChange={() =>
                    form.change('attributes.start', formatISO(new Date(today)))
                  }
                />
              );
            }}
          </FormSpy>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignContent="flex-end" alignItems="baseline">
        <Grid item xs="auto">
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <Field
                name={`${name}.end`}
                parse={(value) => (value ? formatISO(value) : '')}
                format={(value) => (value ? parseISO(value) : '')}
              >
                {({ input }) => (
                  <CSDateTimeField
                    label="End Date"
                    minDate={addDays(parseISO(values.attributes.start), 3)}
                    tooltip="Intended expiry date for your listing"
                    {...input}
                  />
                )}
              </Field>
            )}
          </FormSpy>
        </Grid>
        <Grid item xs>
          <div className={classes.durationWrapper}>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const diff = differenceInCalendarDays(
                  parseISO(values.attributes.end),
                  parseISO(values.attributes.start)
                );

                return (
                  <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    className={classes.duration}
                  >
                    Listing Duration: {diff} {diff === 1 ? 'day' : 'days'}
                  </Typography>
                );
              }}
            </FormSpy>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListingDetails;
