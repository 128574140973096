import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOpen from '@material-ui/icons/LockOpen';
import Lock from '@material-ui/icons/Lock';
import { ListingVisibility } from '../../types/listing';

interface ListingTypeProps {
  type: ListingVisibility;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  icon: {
    fontSize: 18,
    lineHeight: 1,
    marginRight: 5,
  },
  tag: {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 1,
    padding: 2,
    marginRight: 5,
    borderRadius: 2,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

const ListingType: FunctionComponent<ListingTypeProps> = ({ type }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {type === 'public' && <LockOpen className={classes.icon} />}
      {type === 'gated' && <Lock className={classes.icon} />}
      {type === 'private' && <Lock className={classes.icon} />}
      <Typography component="span" className={classes.text}>
        {type === 'public' && 'Public Listing'}
        {type === 'gated' && 'Gated Listing'}
        {type === 'private' && 'Private Listing'}
      </Typography>
    </div>
  );
};

export default ListingType;
