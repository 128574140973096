import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Radio,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Field, FormSpy } from 'react-final-form';
import TextField from '../../../Form/CSTextField';
import { getFeatureUIName, TECH_SPECS_API_KEYS } from '../../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    radio: { width: '100%' },
  })
);

const IPTransitAdvanced = React.memo(() => {
  const classes = useStyles();
  return (
    <Box my={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography className={classes.heading} gutterBottom>
            Transit Type
          </Typography>
          <Field
            name="attributes.advanced.transit_type"
            type="radio"
            value={TECH_SPECS_API_KEYS.domestic}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.domestic}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('domestic')}
              />
            )}
          </Field>
          <Field
            name="attributes.advanced.transit_type"
            type="radio"
            value={TECH_SPECS_API_KEYS.international}
          >
            {({ input }) => (
              <FormControlLabel
                value={TECH_SPECS_API_KEYS.international}
                className={classes.radio}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    onChange={input.onChange}
                    checked={input.checked}
                  />
                }
                label={getFeatureUIName('international')}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography className={classes.heading} gutterBottom>
            Advanced Features
          </Typography>
          <FormGroup>
            <Field
              name={`attributes.advanced.advanced_features.${TECH_SPECS_API_KEYS.bgp_community_support}`}
              type="checkbox"
            >
              {({ input }) => (
                <FormControlLabel
                  label={getFeatureUIName(
                    TECH_SPECS_API_KEYS.bgp_community_support
                  )}
                  control={<Checkbox color="primary" size="small" />}
                  {...input}
                />
              )}
            </Field>
            <Field
              name={`attributes.advanced.advanced_features.${TECH_SPECS_API_KEYS.ddos_filtering_scrubbing}`}
              type="checkbox"
            >
              {({ input }) => (
                <FormControlLabel
                  label={getFeatureUIName(
                    TECH_SPECS_API_KEYS.ddos_filtering_scrubbing
                  )}
                  control={<Checkbox color="primary" size="small" />}
                  {...input}
                />
              )}
            </Field>
            <Field
              name={`attributes.advanced.advanced_features.${TECH_SPECS_API_KEYS.remote_trigger_black_hole}`}
              type="checkbox"
            >
              {({ input }) => (
                <FormControlLabel
                  label={getFeatureUIName(
                    TECH_SPECS_API_KEYS.remote_trigger_black_hole
                  )}
                  control={<Checkbox color="primary" size="small" />}
                  {...input}
                />
              )}
            </Field>
          </FormGroup>
        </Grid>
        <FormSpy subscription={{ pristine: true }}>
          {({ form }) => (
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => form.mutators.resetAdvanced()}
              >
                Clear Advanced Options
              </Button>
            </Grid>
          )}
        </FormSpy>
        <Grid item xs={12}>
          <Field name="additional_info">
            {({ input }) => (
              <TextField
                label="Additional Notes (Optional)"
                placeholder="e.g. We would like pricing options for 24-month and 36-month contract terms"
                multiline
                minRows={6}
                {...input}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
});

export default IPTransitAdvanced;
