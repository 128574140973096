import React from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import AttachFile from '../../components/CreateListing/AttachFile';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Document } from '../../types/document';
import { CreateListingFormState } from '../CreateListing';
import AttachmentList from '../../components/AttachmentList';
import { deleteDocument } from '../../api';

interface FormSectionProps {
  name: string;
}

const AttachmentsSection: React.FC<FormSectionProps> = ({ name }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <FormSpy subscription={{ values: true, pristine: true }}>
        {({ form, values }: FormSpyRenderProps<CreateListingFormState>) => {
          const documents = values.documents;

          const chips = documents.map(({ state, value }, index) => {
            return (
              state !== 'delete' && (
                <AttachmentList
                  document={value}
                  key={index}
                  onDelete={(id: string) => {
                    deleteDocument(id);
                    form.mutators.deleteResource(name, index);
                  }}
                />
              )
            );
          });

          return (
            <>
              {chips}
              <AttachFile
                open={open}
                onDone={(values) => {
                  setOpen(false);
                  if (values) {
                    values.map((document) =>
                      form.mutators.addResource(name, {
                        type: 'document',
                        id: document.id,
                        attributes: document.attributes,
                      } as Document)
                    );
                  }
                }}
              >
                {chips}
              </AttachFile>
            </>
          );
        }}
      </FormSpy>
      <Box mt={3}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add Documents
        </Button>
      </Box>
    </Box>
  );
};

export default AttachmentsSection;
