import { useCallback, useEffect } from 'react';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';

export const useBodyScrollLock = () => {
  const lock = useCallback(
    (target: HTMLElement | Element) =>
      disableBodyScroll(target, { reserveScrollBarGap: true }),
    []
  );

  const unlock = useCallback(
    (target: HTMLElement | Element) => enableBodyScroll(target),
    []
  );

  const clear = useCallback(() => clearAllBodyScrollLocks(), []);

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  return {
    lock,
    unlock,
    clear,
  };
};
