import { object, string, number, boolean } from 'yup';
import { TECH_SPECS_API_KEYS } from '../../../../constants';

export const schema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({
      value: number().required().min(1),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    sd_wan_compatible: boolean(),
    advanced: object({
      access_grade: string().nullable().label('Access Grade'),
      access_method: string().nullable().label('Access Method'),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.bgp_community_support]: boolean(),
        [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: boolean(),
      }),
      minimum_sla: string().nullable().label('Minimum SLA'),
    }),
  }).defined(),
}).defined();

export default schema;
