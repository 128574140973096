import { SchemaOf } from 'yup';
import { set, get } from 'lodash';

export const makeValidator = <T>(validator: SchemaOf<T>) => {
  return async (values: T) => {
    try {
      await validator.validate(values, { abortEarly: false });
    } catch (err: any) {
      return err.inner.reduce((errors: any, { path, message }: any) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, get(errors, path) + ' ' + message);
        } else {
          set(errors, path, message);
        }
        return errors;
      }, {});
    }
  };
};
