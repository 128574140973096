import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useCompanyQuery } from '../../hooks/useCompanyQuery';
import { useUserQuery } from '../../hooks/useUserQuery';
import { UserResponse } from '../../types/account-service/users';
import { CompanyAccountResponse } from '../../types/account-service/company-account';

export interface CompanyInfoDisplayProps {
  company: CompanyAccountResponse;
  user?: UserResponse;
  viewBidderInfo?: boolean;
  size?: string;
  justifyCenter?: boolean;
}
export interface CompanyInfoProps {
  company: string;
  user?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      position: 'relative',
      borderRadius: 8,
    },
    logo: {
      display: 'block',
      maxWidth: 100,
      margin: theme.spacing(1, 2),
      backgroundColor: theme.palette.common.white,
    },
    smallLogo: {
      maxWidth: 50,
      margin: theme.spacing(1, 0, 1, 2),
    },
    name: {
      margin: theme.spacing(0, 2),
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

export const CompanyInfoDisplay: React.FC<CompanyInfoDisplayProps> = ({
  company,
  user,
  viewBidderInfo,
  size,
  justifyCenter,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={user ? 6 : 12}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={justifyCenter ? 'center' : 'flex-start'}
        >
          {company.attributes.logoUrl && (
            <Grid item xs="auto">
              <img
                src={company.attributes.logoUrl}
                alt={company.attributes.name}
                className={clsx(classes.logo, {
                  [classes.smallLogo]: size === 'small',
                })}
              />
            </Grid>
          )}
          <Grid item xs="auto">
            <Typography
              variant={size === 'small' ? 'body1' : 'h4'}
              className={classes.name}
            >
              {company.attributes.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {viewBidderInfo && user && (
        <Grid item xs={12} sm={6}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs="auto">
              <Typography variant="body1" align="right">
                <strong>
                  {user.attributes.firstName} {user.attributes.lastName}
                </strong>
              </Typography>
              <Typography variant="body2" align="right">
                {user.attributes.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!viewBidderInfo && user && (
        <Grid container item xs={12} sm={6}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs="auto">
              <Typography variant="body1">
                <strong> Provider contact hidden </strong>
              </Typography>
              <Typography variant="body2">
                To see the contact details, let them know you are interested.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company, user }) => {
  const companyQuery = useCompanyQuery(company);
  const userQuery = useUserQuery(user);

  if (companyQuery.isLoading || companyQuery.isError || !companyQuery.data) {
    return null;
  }

  return (
    <CompanyInfoDisplay
      company={companyQuery.data.entity}
      user={userQuery.user}
    />
  );
};

export default CompanyInfo;
