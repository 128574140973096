import React, { FunctionComponent } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles, Theme } from '@material-ui/core';
import WithFormLabel from '../WithFormLabel/WithFormLabel';
import { TextField, CSTextFieldProps } from '../CSTextField/CSTextField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 8,
  },
  input: {
    padding: '12px 14px',
    borderWidth: '2px',
    borderColor: theme.palette.grey[300],
  },
}));

type Option = {
  label: string;
  value: any;
};

interface DropdownProps extends SelectProps {
  options: Option[];
  placeholder?: string;
}

const Dropdown: FunctionComponent<Omit<DropdownProps, 'css'>> = ({
  options = [],
  id,
  placeholder,
  ...rest
}) => {
  const classes = useStyles({});
  return (
    <Select
      className={classes.root}
      variant="outlined"
      labelId={id}
      classes={{
        selectMenu: classes.input,
      }}
      IconComponent={KeyboardArrowDownIcon}
      {...rest}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

interface DropdownWithOtherProps extends DropdownProps {
  otherInputProps?: Omit<CSTextFieldProps, 'css'>;
  value?: string;
  showInputKey?: string;
}

const _DropdownWithOther: FunctionComponent<
  Omit<DropdownWithOtherProps, 'css'>
> = ({ value, otherInputProps, showInputKey = 'other', ...rest }) => {
  return (
    <Grid container item xs={12} direction="column">
      <Dropdown value={value} {...rest} />
      {value === showInputKey && <TextField {...otherInputProps} />}
    </Grid>
  );
};

export default WithFormLabel(Dropdown);

export const DropdownWithOther = WithFormLabel(_DropdownWithOther);
