import {
  createEntityAdapter,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { fetchAllBidServices, fetchBidServiceById } from './actions';
import { BidService } from '../../../types/bid';
import { RootState } from '../../types.d';

const bidServicesAdapter = createEntityAdapter<BidService>({
  selectId: (pricingOption) => pricingOption.id!,
});

const bidServices = createSlice({
  name: 'bid-services',
  initialState: bidServicesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    // FETCH ALL
    builder
      .addCase(fetchAllBidServices.pending, (state, _action) => {
        bidServicesAdapter.removeAll(state);
      })
      .addCase(fetchAllBidServices.fulfilled, (state, action) => {
        bidServicesAdapter.addMany(state, action.payload.data);
      })
      .addCase(fetchAllBidServices.rejected, (state, action) => {
        bidServicesAdapter.removeAll(state);
      });

    // FETCH BY ID
    builder
      .addCase(fetchBidServiceById.pending, (state, action) => {
        bidServicesAdapter.removeAll(state);
      })
      .addCase(fetchBidServiceById.fulfilled, (state, action) => {
        bidServicesAdapter.addOne(state, action.payload.data);
      })
      .addCase(fetchBidServiceById.rejected, (state, action) => {
        bidServicesAdapter.removeAll(state);
      });
  },
});

export const {
  selectById: selectBidServiceById,
  selectIds: selectBidServiceIds,
  selectEntities: selectBidServiceEntities,
  selectAll: selectAllBidServices,
  selectTotal: selectTotalBidServices,
} = bidServicesAdapter.getSelectors((state: RootState) => state.bidServices);

export const selectBidServicesByBidId = (id: string) => {
  return createSelector(selectAllBidServices, (services) => {
    if (!id) {
      return [];
    }

    return services.filter(
      (service) => service.relationships.bid?.data.id === id
    );
  });
};

export const selectBidServicesByListingServiceId = (id: string) => {
  return createSelector(selectAllBidServices, (services) => {
    if (!id) {
      return [];
    }

    return services.filter(
      (service) => service.relationships['listing-service']?.data.id === id
    );
  });
};

export default bidServices.reducer;
