import React, { FunctionComponent } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import { makeStyles, Theme, MenuItem } from '@material-ui/core';
import WithFormLabel from '../WithFormLabel/WithFormLabel';
import ArrowDown from '@material-ui/icons/KeyboardArrowDownRounded';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    padding: theme.spacing(1.5, 14 / theme.spacing(1)),
    borderWidth: 2,
  },
  icon: {
    top: 'calc(50% - 14px)',
  },
}));

export type CSSelectFieldProps = SelectProps;

const CSSelectField: FunctionComponent<Omit<SelectProps, 'css'>> = (props) => {
  const classes = useStyles();

  return (
    <Select
      classes={classes}
      variant="outlined"
      IconComponent={ArrowDown}
      fullWidth
      {...props}
    />
  );
};

export const SelectField = CSSelectField;
export const SelectFieldPlaceholder = WithFormLabel(
  ({ children, ...rest }: Omit<CSSelectFieldProps, 'css'>) => (
    <CSSelectField displayEmpty {...rest}>
      <MenuItem value="" disabled>
        Select
      </MenuItem>
      {children}
    </CSSelectField>
  )
);
export default WithFormLabel(CSSelectField);
