import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '../../Form/CSTextField';
import ButtonGroupToggle, { ButtonGroupOption } from '../../ButtonGroupToggle';

interface FiltersProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  toggleMarketsValue: 'all' | 'selected';
  setToggleMarketsValue: (value: 'all' | 'selected') => void;
  matchedProvidersCount: number;
  allProvidersCount: number;
  allMatchedProvidersCount: number;
  totalProvidersCount: number;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    buttonToggles: {
      width: '100%',
      minHeight: '44px',
    },
  })
);
export const Filters: React.FC<FiltersProps> = ({
  inputValue,
  setInputValue,
  toggleMarketsValue,
  setToggleMarketsValue,
  matchedProvidersCount,
  allProvidersCount,
  allMatchedProvidersCount,
  totalProvidersCount,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container item xs={7} alignItems="flex-end">
        <TextField
          fullWidth
          label="Search service providers"
          placeholder="Start typing to find service providers"
          type="text"
          value={inputValue}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <ButtonGroupToggle
          name="providers"
          className={classes.buttonToggles}
          value={toggleMarketsValue}
          onChange={(e, val: 'selected' | 'all') => setToggleMarketsValue(val)}
        >
          <ButtonGroupOption value="selected">
            Matched Providers
          </ButtonGroupOption>
          <ButtonGroupOption value="all">All Providers</ButtonGroupOption>
        </ButtonGroupToggle>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" component="div">
          <>
            <b>Displaying</b>{' '}
            {toggleMarketsValue === 'selected'
              ? matchedProvidersCount
              : allProvidersCount}{' '}
            of{' '}
            {toggleMarketsValue === 'selected'
              ? allMatchedProvidersCount
              : totalProvidersCount}{' '}
            service providers in your selected markets.
          </>
        </Typography>
      </Grid>
    </>
  );
};
