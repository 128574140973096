import { createSelector } from '@reduxjs/toolkit';
import { selectAllListings } from './reducer';
import { RequestContext } from '../../types/entity-collection';
import {
  ListingResponse,
  ListingResponseIncludes,
} from '../../types/marketplace-service/listing';
import { parseISO } from 'date-fns';

export const selectListingByShortId = (shortId?: string) => {
  return createSelector(selectAllListings, (listings) =>
    listings.find((listing) => listing.attributes.short_id === shortId)
  );
};

export const selectListingsByState = (state: string) =>
  createSelector(selectAllListings, (listings) =>
    listings.filter((listing) => listing.attributes.state === state)
  );

export const selectActiveListings = createSelector(
  selectAllListings,
  (listings) =>
    listings.filter(
      (listing) =>
        listing.attributes.state === 'pending' ||
        listing.attributes.state === 'approved' ||
        listing.attributes.state === 'published'
    )
);
const getUniqueListings = (listings: ListingResponse[]): ListingResponse[] => {
  const result = listings.reduce<{ [key: string]: ListingResponse }>(
    (result, listing) => {
      const id = listing.id;

      if (!id) {
        return result;
      }

      if (result[id] !== undefined) {
        const createdAt = parseISO(listing.attributes.created_at);

        if (createdAt > parseISO(result[id].attributes.created_at)) {
          result[id] = listing;
        }
      } else {
        result[id] = listing;
      }

      return result;
    },
    {}
  );

  return Object.values(result);
};

export const selectListingsByListingState = (
  entities: ListingResponse[],
  context?: RequestContext<ListingResponseIncludes>
) => {
  return getUniqueListings(entities).reduce<{
    active: ListingResponse[];
    review: ListingResponse[];
    completed: ListingResponse[];
  }>(
    (result, listing) => {
      if (
        listing?.attributes.state === 'published' ||
        listing?.attributes.state === 'pending' ||
        listing?.attributes.state === 'approved'
      ) {
        result.active.push(listing);
      }

      if (listing?.attributes.state === 'ended') {
        result.review.push(listing);
      }

      if (listing?.attributes.state === 'completed') {
        result.completed.push(listing);
      }

      return result;
    },
    {
      active: [],
      review: [],
      completed: [],
    }
  );
};
