import React, { useState } from 'react';
import {
  Button,
  ButtonProps,
  MenuItem,
  Select,
  Typography,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListingRating from '../../components/ListingRating';

interface BidActionUnInterestedProps extends ButtonProps {
  options: { value: string; label: string }[];
  showRating: boolean;
  onUnInterested: (reason: string | undefined, rating?: number | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropDown: {
      boxShadow: 'none',
      '& .MuiSvgIcon-root': {
        color: '#f60e5a',
      },
      '& .MuiSelect-select': {
        minWidth: 0,
        maxWidth: 0,
        color: 'white',
      },
    },
    label: {
      color: '#f60e5a',
      '&.Mui-focused': {
        color: '#f60e5a',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    company: { textAlign: 'center' },
    section: {
      margin: theme.spacing(2, 0),
    },
  })
);

const BidActionUnInterested: React.FC<Omit<BidActionUnInterestedProps, 'css'>> =
  ({ onUnInterested, showRating, options, ...props }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [rating, setRating] = useState<number | null>(5);
    const [reason, setReason] = useState<string>();

    return (
      <>
        <Button onClick={() => setOpen(!open)} variant="text" {...props}>
          <Typography
            variant="subtitle2"
            color="error"
            onClick={() => setOpen(!open)}
          >
            Pass
          </Typography>
          <Select
            className={classes.dropDown}
            variant="standard"
            disableUnderline
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onChange={(event) => {
              if (showRating) {
                setReason(event.target.value as string);
                setOpenDialog(true);
              } else {
                onUnInterested(event.target.value as string);
              }
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Button>
        <Dialog
          open={openDialog}
          maxWidth="sm"
          fullWidth
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpen(false);
            }
          }}
          TransitionProps={{
            onExited: () => {
              if (showRating) {
                onUnInterested(reason, rating);
              } else {
                onUnInterested(reason);
              }
            },
          }}
          disableEscapeKeyDown
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              data-testid="close-button"
              className={classes.closeButton}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12} className={classes.section}>
              <ListingRating
                onClose={(rating) => {
                  setRating(rating);
                  setOpenDialog(false);
                }}
              />
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

export default BidActionUnInterested;
