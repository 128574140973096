import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import ListingContainer from '../../../components/Listings/Container';
import { getPageTitleTemplate } from '../../../constants';

const BuyingPage: React.FunctionComponent<
  RouteComponentProps & {
    title?: string;
  }
> = ({ title = 'Browse Listings' }) => {
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout containerProps={{ maxWidth: 'xl' }} title={title}>
        <ListingContainer />
      </AppLayout>
    </>
  );
};

export default BuyingPage;
