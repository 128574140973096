import ServiceRequirements from './ServiceRequirements';
import ServiceRequirementsEthernet from './Ethernet';
import ServiceRequirementsIPTransit from './IPTransit';
import ServiceRequirementsDarkFiber from './DarkFiber';
import ServiceRequirementsCloudConnectivity from './CloudConnectivity';
import ServiceRequirementsInternetAccess from './InternetAccess';
import ServiceRequirementsWavelength from './Wavelength';
import ServiceRequirementsColocation from './Colocation';
import ServiceRequirementsMulti from './Multi';

export const Ethernet = ServiceRequirementsEthernet;
export const IPTransit = ServiceRequirementsIPTransit;
export const DarkFiber = ServiceRequirementsDarkFiber;
export const CloudConnectivity = ServiceRequirementsCloudConnectivity;
export const InternetAccess = ServiceRequirementsInternetAccess;
export const Wavelength = ServiceRequirementsWavelength;
export const Colocation = ServiceRequirementsColocation;
export const MultiRequirements = ServiceRequirementsMulti;

export default ServiceRequirements;
