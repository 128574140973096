import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../api';
import { ListingService, Services } from '../../types/services';
import { Document } from '../../types/document';
import { ThunkExtra } from '../types.d';

export const createService = createAsyncThunk<
  {
    data: ListingService;
    included: Document[];
  },
  { listing: string; service: ListingService },
  { extra: ThunkExtra }
>(`service/create`, async ({ listing, service }, { extra: { axios } }) => {
  const response = await axios.post(
    `${API_BASE_URL}/listings/${listing}/services`,
    {
      data: service.attributes,
    }
  );

  return response.data;
});

export const fetchAllServices = createAsyncThunk<
  {
    data: Services[];
    included: Document[];
  },
  { listing: string },
  { extra: ThunkExtra }
>(`service/fetchAll`, async (listing, { extra: { axios } }) => {
  const response = await axios.get(
    `${API_BASE_URL}/listings/${listing}/services`
  );

  return response.data;
});

export const fetchServiceById = createAsyncThunk<
  { data: Services; included: Document[] },
  { listing: string; service: string },
  { extra: ThunkExtra }
>(`service/fetchById`, async ({ listing, service }, { extra: { axios } }) => {
  const response = await axios.get(
    `${API_BASE_URL}/listings/${listing}/services/${service}`
  );

  return response.data;
});

export const updateServiceById = createAsyncThunk<
  { data: Services; included: Document[] },
  { listing: string; service: ListingService },
  { extra: ThunkExtra }
>(`service/update`, async ({ listing, service }, { extra: { axios } }) => {
  const response = await axios.patch(
    `${API_BASE_URL}/listings/${listing}/services/${service.id!}`,
    { data: service.attributes }
  );

  return response.data;
});

export const deleteServiceById = createAsyncThunk<
  { id: string },
  { listing: string; service: string },
  { extra: ThunkExtra }
>(`service/delete`, async ({ listing, service }, { extra: { axios } }) => {
  await axios.delete(`${API_BASE_URL}/listings/${listing}/services/${service}`);

  return { id: service };
});
