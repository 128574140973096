import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { GlobalStyles as BaseStyles } from 'twin.macro';

/*
  Fix some conflicting styles between twin.macro/tailwind and material UI.
*/
const CustomStyles = createGlobalStyle`
  img, video {
    max-width: initial;
  }
  button, [type='button'], [type='reset'], [type='submit'] {
    appearance: none;
  }
  button:focus {
    outline: initial;
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
