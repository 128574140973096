import { createContext, ReactNode, useCallback, useState } from 'react';
import { noop } from '../../utilities/noop';

type MenuItemType = 'source' | 'cpf' | 'resources';

interface MenuContextType {
  state: null | MenuItemType;
  onSelect: (value: MenuItemType) => void;
  onBack: () => void;
}

export const MenuContext = createContext<MenuContextType>({
  state: null,
  onSelect: noop,
  onBack: noop,
});

export const MenuContextProvider = ({
  children,
}: {
  children: ReactNode | ((args: MenuContextType) => ReactNode);
}) => {
  const [state, setState] = useState<MenuContextType['state']>(null);

  const onSelect = useCallback((value: MenuItemType) => {
    setState(value);
  }, []);

  const onBack = useCallback(() => {
    setState(null);
  }, []);

  return (
    <MenuContext.Provider value={{ state, onSelect, onBack }}>
      {children}
    </MenuContext.Provider>
  );
};
