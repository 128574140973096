import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../api';
import { Listing, ListingMeta, CreateListingDraft } from '../../types/listing';
import { ListingMarketResponse } from '../../types/marketplace-service/market';
import { Document } from '../../types/document';
import { ListingProvider, ProviderEntity } from '../../types/provider';
import { ProviderContact } from '../../types/provider-contact';
import { ListingService } from '../../types/services';
import { ThunkExtra } from '../types.d';
import { Bid } from '../../types/bid';
import { RegionMarket } from '../../types/region-market';

export const createListing = createAsyncThunk<
  { data: Listing },
  Pick<CreateListingDraft, 'type' | 'id' | 'attributes'>,
  { extra: ThunkExtra }
>(`listings/create`, async ({ id, type, attributes }, { extra: { axios } }) => {
  const response = id
    ? await axios.patch(`${API_BASE_URL}/listings/${id}`, {
        data: {
          type,
          id,
          attributes,
        },
      })
    : await axios.post(`${API_BASE_URL}/listings`, {
        data: {
          type,
          attributes,
        },
      });
  return response.data;
});

interface FetchListingsOptions {
  page: number;
  filterBy: {
    term?: string;
    serviceTypes?: string[];
    external?: number;
    markets?: string[];
  };
  sortBy: string;
}

export const fetchListingsByPage = createAsyncThunk<
  {
    data: Listing[];
    included?: (
      | ListingService
      | Document
      | ListingMarketResponse
      | ListingProvider
      | ProviderContact
      | ProviderEntity
    )[];
    meta: ListingMeta;
  },
  FetchListingsOptions,
  { extra: ThunkExtra }
>(
  `listing/fetchByPage`,
  async ({ page, filterBy, sortBy }, { extra: { axios } }) => {
    const response = await axios.get(`${API_BASE_URL}/listings/search`, {
      params: {
        page,
        include: 'markets',
        'filter[search]': filterBy.term || undefined,
        'filter[service_type]': filterBy.serviceTypes?.join(',') || undefined,
        'filter[external]': filterBy.external,
        'filter[market]': filterBy.markets?.join(',') || undefined,
        sort: sortBy,
      },
    });

    return response.data;
  }
);

export const fetchAvailableMarkets = createAsyncThunk<
  {
    data: RegionMarket[];
  },
  Omit<FetchListingsOptions, 'page'>,
  { extra: ThunkExtra }
>(
  `listing/fetchAvailableMarkets`,
  async ({ filterBy, sortBy }, { extra: { axios } }) => {
    const response = await axios.get(
      `${API_BASE_URL}/listings/search-filter-region-markets`,
      {
        params: {
          'filter[search]': filterBy.term || undefined,
          'filter[service_type]': filterBy.serviceTypes?.join(',') || undefined,
          'filter[external]': filterBy.external,
          sort: sortBy,
        },
      }
    );

    return response.data;
  }
);

interface FetchListingOptions {
  id: string;
  track_view?: number;
}

export const fetchListingById = createAsyncThunk<
  {
    data: Listing;
    included?: (
      | ListingService
      | Document
      | ListingMarketResponse
      | ListingProvider
      | ProviderContact
      | ProviderEntity
      | Bid
    )[];
    meta: ListingMeta;
  },
  FetchListingOptions,
  { extra: ThunkExtra }
>(`listings/fetchById`, async ({ id, track_view }, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/listings/${id}`, {
    params: {
      track_view: track_view,
      include: [
        'documents',
        'markets',
        'providers',
        'provider-contacts',
        'providers.listing',
        'providers.bids',
        'providers.bid.listing',
        'providers.bid.documents',
      ].join(','),
    },
  });
  return response.data;
});

export const updateListingStateById = createAsyncThunk<
  { data: Listing },
  {
    id: string;
    data: {
      type: 'listing';
      id: string;
      attributes: {
        state?: string;
        notify_unsuccessful?: boolean;
        rating?: number | null;
      };
    };
  },
  { extra: ThunkExtra }
>(`listings/updateStateById`, async ({ id, data }, { extra: { axios } }) => {
  const response = await axios.patch(`${API_BASE_URL}/listings/${id}`, {
    data,
  });
  return response.data;
});

export const assignListing = createAsyncThunk<
  { data: Listing },
  {
    listingId: string;
    user_id: string;
    company_id?: string;
    contact?: {
      full_name?: string;
      email: string;
      phone?: string | null;
    };
  },
  { extra: ThunkExtra }
>(
  `listings/assign`,
  async ({ listingId, user_id, company_id, contact }, { extra: { axios } }) => {
    const response = await axios.patch(
      `${API_BASE_URL}/listings/${listingId}`,
      {
        data: {
          type: 'listing',
          id: listingId,
          attributes: { user_id, company_id, contact },
        },
      }
    );
    return response.data;
  }
);

export const removeListingById = createAsyncThunk<
  { id: string; data: Listing },
  string,
  { extra: ThunkExtra }
>(`listings/removeById`, async (id, { extra: { axios } }) => {
  const response = await axios.delete(`${API_BASE_URL}/listings/${id}`);
  return { id, ...response.data };
});

export const fetchListingsByCompany = createAsyncThunk<
  {
    data: Listing[];
    included?: (
      | ListingService
      | Document
      | ListingMarketResponse
      | ListingProvider
      | ProviderContact
    )[];
    meta: ListingMeta;
  },
  string | undefined,
  { extra: ThunkExtra }
>(`listings/fetchByCompany`, async (id, { extra: { axios } }) => {
  if (!id) {
    return { data: [] };
  }

  const response = await axios.get(
    `${API_BASE_URL}/company-accounts/${id}/listings`,
    {
      params: {
        include: [
          'documents',
          'markets',
          'providers',
          'provider-contacts',
          'bids',
        ].join(','),
      },
    }
  );

  return response.data;
});

export const updateListingEndDateById = createAsyncThunk<
  { data: Listing },
  {
    id: string;
    data: {
      type: 'listing';
      id: string;
      attributes: {
        state?: string;
        end: string;
      };
    };
  },
  { extra: ThunkExtra }
>(`listings/updateStateById`, async ({ id, data }, { extra: { axios } }) => {
  const response = await axios.patch(`${API_BASE_URL}/listings/${id}`, {
    data,
  });
  return response.data;
});

export const updateListingRatingById = createAsyncThunk<
  { data: Listing },
  {
    id: string;
    data: {
      type: 'listing';
      id: string;
      attributes: {
        rating?: number | null;
      };
    };
  },
  { extra: ThunkExtra }
>(`listings/updateRatingById`, async ({ id, data }, { extra: { axios } }) => {
  const response = await axios.patch(`${API_BASE_URL}/listings/${id}`, {
    data,
  });
  return response.data;
});
