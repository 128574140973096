import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../../../hooks/useAuth';
import NoCompany from '../../../../components/Listings/NoCompany';
import { getPageTitleTemplate } from '../../../../constants';

const Container: React.FunctionComponent<
  RouteComponentProps<{
    title: string;
  }>
> = ({ title, children }) => {
  const { user } = useAuth();
  const company = user?.company_account_id;

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      {company ? (
        children
      ) : (
        <NoCompany>
          It&apos;s looking a little empty in here. That's because you
          haven&apos;t been approved to join your team just yet. When the team
          manager approves your request, you&apos;ll be able to view your
          assigned listings. In the meantime, feel free to keep browsing
          listings on Cloudscene Marketplace.
        </NoCompany>
      )}
    </>
  );
};

export default Container;
