import {
  Entity,
  EntityCollection,
  EntityType,
  EntityCollectionMeta,
} from '../../types/entity-collection';
import { getIncludedContext } from '../../utilities/entities';

export const transformEntityCollectionResponse = <
  T extends EntityType = never,
  P extends EntityType = never,
  M extends EntityCollectionMeta<any> = never
>(
  response: EntityCollection<T, P, M>
) => {
  return {
    entities: response.data,
    context: getIncludedContext(response.included) ?? {},
    meta: response.meta,
  };
};

export const transformEntityResponse = <
  T extends EntityType = never,
  P extends EntityType = never
>(
  response: Entity<T, P>
) => {
  return {
    entity: response.data,
    context: getIncludedContext(response.included) ?? {},
  };
};
