import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useActingUser } from '../../hooks/useActingUser';

const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      background: theme.palette.error.light,
      padding: theme.spacing(1, 2),
      zIndex: 5,
      width: '100%',
    },
    message: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
    button: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const ActOnBehalfStatus: React.FC = ({ children }) => {
  const classes = useStyles();
  const { isActing, actingUser, stopActing } = useActingUser();

  if (!isActing) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={classes.status}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={1}></Grid>
          <Grid item xs>
            <Typography
              component="div"
              align="center"
              className={classes.message}
            >
              You are acting on behalf of {actingUser?.first_name}{' '}
              {actingUser?.last_name}
            </Typography>
          </Grid>
          <Grid container item xs={1} justifyContent="flex-end">
            <Button
              variant="text"
              onClick={() => stopActing()}
              className={classes.button}
            >
              STOP
            </Button>
          </Grid>
        </Grid>
      </div>
      {children}
    </>
  );
};

export default ActOnBehalfStatus;
