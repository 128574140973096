import React, { useCallback, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  Typography,
  Theme,
  makeStyles,
  Grid,
  Divider,
  Link,
} from '@material-ui/core';
import { useUpdateMarketplaceLeadByIDMutation } from '../services/api/marketplace-service/gql/marketplace-leads';
import LogoCloudsceneStatic from '../components/Logo/LogoCloudsceneStatic';
import { addMessage } from '../store/messenger';
import { ROUTES } from '../routes';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/types';

const useStyles = makeStyles((theme: Theme) => ({
  copy: {
    paddingBottom: theme.spacing(2),
  },
  logo: {
    margin: theme.spacing(4, 0, 0, 4),
  },
}));

const Contacted: React.FunctionComponent<
  RouteComponentProps<{
    leadId?: string;
  }>
> = ({ leadId }) => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const [updateMarketplaceLeadByID] = useUpdateMarketplaceLeadByIDMutation();
  const handleLeadMutation = useCallback(
    (id: string) => {
      return updateMarketplaceLeadByID({
        id,
        payload: {
          id,
          contacted: true,
        },
      }).catch(() => {
        dispatch(addMessage('error', `There was an error`));
        navigate(ROUTES.root, {
          replace: true,
        });
      });
    },
    [dispatch, updateMarketplaceLeadByID]
  );

  useEffect(() => {
    if (leadId) handleLeadMutation(leadId);
  }, [leadId, handleLeadMutation]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.logo}>
        <Link href={'http://home.cloudscene.com'}>
          <LogoCloudsceneStatic />
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.copy}>
          <Typography variant="h2" component="div" align="center">
            Thank you for letting us know!
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Contacted;
