import { ProviderContact } from '../types/provider-contact';
import { ProviderContact as GQLProviderContact } from '../types/marketplace-service/gql/service-provider-contact';

export const isProviderContact = (
  contact: ProviderContact | GQLProviderContact
): contact is ProviderContact => {
  return Object.prototype.hasOwnProperty.call(contact, 'attributes');
};
export const getProviderContactID = (
  contact?: ProviderContact | GQLProviderContact
): string | undefined => {
  if (!contact) return undefined;

  return isProviderContact(contact)
    ? contact.attributes.provider_id
    : contact.provider_id;
};
