import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Paper, Box, Chip, InputAdornment } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '../Form/CSTextField';
import { CompanyAccountResponse } from '../../types/account-service/company-account';
import { UserResponse } from '../../types/account-service/users';
import { useDebounce } from '../../hooks/useDebounce';
import { useLazyReadAllUsersQuery } from '../../services/api/account-service/users';
import { useLazyReadActingUserByIDQuery } from '../../services/api/acting-on-behalf/acting-user';
import { getRelationshipContext } from '../../utilities/relationships';
import { usePrevious } from '../../hooks/usePrevious';

interface AdminTeamMemberSearchProps {
  label?: string;
  defaultUser?: UserResponse;
  onChange: (args: {
    user: UserResponse;
    account: CompanyAccountResponse;
    profiles?: string[];
    permissions?: string[];
    roles?: string[];
    companyPackage: string;
  }) => void;
  optionType?: 'string' | 'table';
}

const useAutocompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0.5, 0),
      border: `2px solid ${theme.palette.grey[100]}`,
      borderRadius: 6,
    },
    endAdornment: {
      top: 'calc(50% - 16px)',
      right: theme.spacing(1),
    },
  })
);
const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  userStatus: {
    borderRadius: '4px',
    color: 'white',
  },
}));

const AdminTeamMemberSearch: React.FC<AdminTeamMemberSearchProps> = ({
  label,
  onChange,
  optionType,
  defaultUser = null,
}) => {
  const autocompleteClasses = useAutocompleteStyles();
  const classes = useStyles();
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 300);
  const [value, setValue] = useState<{
    user: UserResponse | null;
    account: CompanyAccountResponse | null;
  }>({ user: defaultUser, account: null });
  const [readAllUsers, readAllUsersState] = useLazyReadAllUsersQuery();
  const [readActingUserByID, readActingUserByIDState] =
    useLazyReadActingUserByIDQuery();
  const userId = usePrevious(readActingUserByIDState?.data?.user.id);
  useEffect(() => {
    if (debouncedInput) {
      readAllUsers({
        params: {
          'filter[search]': debouncedInput,
          'page[size]': 25,
          include: 'companyAccount',
        },
      });
    }
  }, [readAllUsers, debouncedInput]);

  useEffect(() => {
    if (
      readActingUserByIDState.data &&
      readActingUserByIDState.data.user.id !== userId
    ) {
      onChange(readActingUserByIDState.data);
    }
  }, [readActingUserByIDState.data, onChange, userId]);

  return (
    <Autocomplete
      PaperComponent={(props) => {
        if (optionType === 'table') {
          return (
            <Paper>
              <Box px={2} pt={2}>
                <Grid container spacing={2} className={classes.tableHeaderText}>
                  <Grid item xs={4}>
                    Name
                  </Grid>
                  <Grid item xs={6}>
                    Email
                  </Grid>
                  <Grid item xs={2}>
                    Status
                  </Grid>
                </Grid>
              </Box>

              {props.children}
            </Paper>
          );
        } else {
          return <Paper>{props.children}</Paper>;
        }
      }}
      value={value?.user}
      inputValue={input}
      onInputChange={(_, input) => {
        setInput(input);
      }}
      onChange={(_, user) => {
        if (typeof user === 'string') return;

        const account =
          getRelationshipContext(
            readAllUsersState.data?.context.companyAccounts,
            user?.relationships?.companyAccount.data?.id
          ) ?? null;

        if (user && account) {
          readActingUserByID({ id: user.id });
        }

        setValue({ user, account });
      }}
      classes={autocompleteClasses}
      getOptionLabel={(user) => {
        if (user) {
          return optionType === 'table'
            ? `${user.attributes.firstName} ${user.attributes.lastName} - ${user.attributes.position}`
            : `${user.attributes.firstName} ${user.attributes.lastName}`;
        }
        return '';
      }}
      getOptionDisabled={(user) => !user.relationships?.companyAccount.data?.id}
      options={readAllUsersState.data?.entities ?? []}
      filterOptions={(options) => options}
      renderOption={(user) => {
        if (optionType === 'table') {
          return (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                {user.attributes.firstName} {user.attributes.lastName}
              </Grid>
              <Grid item xs={6}>
                {user.attributes.email}
              </Grid>
              <Grid item xs={2}>
                <Chip
                  size="small"
                  className={classes.userStatus}
                  color="primary"
                  label={user.attributes.status}
                />
              </Grid>
            </Grid>
          );
        } else {
          const account =
            getRelationshipContext(
              readAllUsersState.data?.context.companyAccounts,
              user?.relationships?.companyAccount.data?.id
            ) ?? null;

          return (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                {user.attributes.firstName} {user.attributes.lastName} (
                {account?.attributes.name})
              </Grid>
            </Grid>
          );
        }
      }}
      renderInput={({
        id,
        disabled,
        InputProps: { ref, className, ...InputProps },
        inputProps,
      }) => (
        <TextField
          label={label}
          id={id}
          disabled={disabled}
          inputProps={inputProps}
          innerRef={ref}
          {...InputProps}
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded fontSize="small" />
            </InputAdornment>
          }
          autoFocus={optionType === 'table'}
        />
      )}
    />
  );
};

export default AdminTeamMemberSearch;
