import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface ServiceDetailPanelProps {
  id: React.ReactNode;
  title: React.ReactNode;
  initialOpen?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 6,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    details: {
      padding: theme.spacing(0, 0, 2, 2),
    },
    panel: {
      background: 'none',
    },
  })
);

const ServiceDetailPanel: React.FC<ServiceDetailPanelProps> = ({
  id,
  title,
  children,
  initialOpen = true,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(initialOpen);
  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded}
        onChange={(_, isExpanded) => setExpanded(isExpanded)}
        className={classes.panel}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Typography className={classes.title}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ServiceDetailPanel;
