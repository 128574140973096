import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  Form,
  Field,
  FormRenderProps,
  FormSpy,
  FormSpyRenderProps,
} from 'react-final-form';
import CSTextField from '../Form/CSTextField';
import newUserValidation from './newUserValidation';
import { AssignedNewUser } from '../../types/provider';

export type AssignNewUserFormState = {
  contact: AssignedNewUser;
  authorize: boolean;
};

interface NewUserFormProps {
  handleNewUserAssign: (contact: AssignedNewUser) => void;
}

const AssignNewUserForm: React.FC<NewUserFormProps> = ({
  handleNewUserAssign,
}) => {
  return (
    <Form
      onSubmit={(values: AssignNewUserFormState) => {
        handleNewUserAssign(values.contact!);
      }}
      keepDirtyOnReinitialize
      subscription={{
        pristine: true,
      }}
      validate={newUserValidation}
    >
      {({ handleSubmit }: FormRenderProps<AssignNewUserFormState>) => (
        <form onSubmit={handleSubmit}>
          <Box py={2}>
            <Typography variant="body1" gutterBottom>
              It looks like your colleague isn&apos;t a member of your company
              team yet. Enter their details below and invite them to join. This
              opportunity will then be automatically assigned to them when they
              first log in to Cloudscene.
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                name="contact.first_name"
                render={({ input, meta }) => (
                  <>
                    <CSTextField
                      label="First Name"
                      {...input}
                      required
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      data-testid="first-name"
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contact.last_name"
                render={({ input, meta }) => (
                  <CSTextField
                    label="Last Name"
                    {...input}
                    required
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    data-testid="last-name"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contact.email"
                type="email"
                render={({ input, meta }) => (
                  <CSTextField
                    label="Email Address"
                    {...input}
                    required
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    data-testid="email"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box my={2} mx="auto" width="200px">
            <FormSpy
              subscription={{
                submitting: true,
                valid: true,
              }}
            >
              {({ submitting, valid }: FormSpyRenderProps) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={submitting || !valid}
                  role="button"
                  data-testid="submit"
                >
                  Done
                </Button>
              )}
            </FormSpy>
          </Box>
        </form>
      )}
    </Form>
  );
};

export default AssignNewUserForm;
