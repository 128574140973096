import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Chip, Grid } from '@material-ui/core';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { CreateListingFormState } from '../CreateListing';
import AddMarketDialog from '../../components/AddMarketDialog';
import { useAppState } from '../../contexts/AppState';
import { MarketEntity, Market } from '../../types/market';

interface FormSectionProps {
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      margin: theme.spacing(0.5),
    },
  })
);

const MarketsSection: React.FC<FormSectionProps> = ({ name }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const appState = useAppState();

  return (
    <Grid container spacing={2}>
      <FormSpy subscription={{ values: true, pristine: true }}>
        {({ form, values }: FormSpyRenderProps<CreateListingFormState>) => {
          const markets = values.markets;

          const chips = markets.map(({ state, value }, index) => {
            const market = appState.markets[value?.attributes.id];
            if (market === undefined) return false;
            const label =
              market.name === market.zone
                ? market.name
                : `${market.name}, ${market.zone}`;

            return (
              state !== 'delete' && (
                <Grid item xs="auto" key={index}>
                  <Chip
                    label={label}
                    className={classes.chips}
                    onDelete={() => {
                      form.mutators.deleteResource(name, index);
                    }}
                  />
                </Grid>
              )
            );
          });

          return (
            <>
              <Grid container spacing={1} item xs>
                {chips}
              </Grid>
              <AddMarketDialog
                open={open}
                onClose={() => setOpen(false)}
                onChange={(marketValues: Market[]) => {
                  const marketEntities = marketValues
                    .filter(
                      (market) =>
                        !markets.find(
                          (m) => m.value.attributes.id === market.id
                        )
                    )
                    .map<MarketEntity>((market) => ({
                      type: 'listing-market',
                      attributes: {
                        id: market.id,
                        type: market.type,
                      },
                    }));
                  marketEntities.forEach((market) =>
                    form.mutators.addResource('markets', market)
                  );
                }}
                selectedMarkets={markets.map(
                  (market) => appState.markets[market.value?.attributes.id]
                )}
              >
                <Grid container spacing={1} item xs>
                  {chips}
                </Grid>
              </AddMarketDialog>
            </>
          );
        }}
      </FormSpy>

      <Grid item xs={12}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          Add Markets
        </Button>
      </Grid>
    </Grid>
  );
};

export default MarketsSection;
