import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Field, FormSpy, FormSpyRenderProps } from 'react-final-form';
import Toggle from '../../components/Toggle';
import TextField from '../../components/Form/CSTextField';
import { CreateListingFormState } from '../CreateListing';

interface FormSectionProps {
  name: string;
}

const ExternalListingSection: React.FC<FormSectionProps> = ({ name }) => {
  return (
    <Grid container spacing={4}>
      <Grid container spacing={4} item alignItems="center">
        <Grid item xs>
          <Typography variant="caption" component="div" gutterBottom>
            Cloudscene Admin
          </Typography>
          <Typography variant="h5" component="div" gutterBottom>
            External Listing
          </Typography>
          <Typography variant="body2" component="div">
            A 3rd Party listing is a listing that originates outside Cloudscene,
            and will be interacted with outside Cloudscene. A 3rd Party listing
            requires a link that connects the service providers on Cloudscene to
            the listing.
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Field name={`${name}.external`} type="checkbox">
            {({ input }) => <Toggle {...input} />}
          </Field>
        </Grid>
      </Grid>
      <FormSpy subscription={{ values: true }}>
        {({ values }: FormSpyRenderProps<CreateListingFormState>) => {
          return values.attributes.external ? (
            <Grid container spacing={4} item alignItems="center">
              <Grid item xs={12} sm={6}>
                <Field name={`${name}.external_link`}>
                  {({ input }) => (
                    <TextField label="External Link" {...input} />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name={`${name}.external_contact_email`}>
                  {({ input }) => (
                    <TextField
                      label="External Contact Email (optional)"
                      {...input}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          ) : null;
        }}
      </FormSpy>
    </Grid>
  );
};

export default ExternalListingSection;
