import { Placement } from '@floating-ui/react-dom';
import { TransitionStatus } from 'react-transition-group';
import tw, { TwStyle } from 'twin.macro';

export const placements: Record<Placement, TwStyle> = {
  right: tw`transform-origin[left center]`,
  'right-start': tw`transform-origin[left top]`,
  'right-end': tw`transform-origin[left bottom]`,
  left: tw`transform-origin[right center]`,
  'left-start': tw`transform-origin[right top]`,
  'left-end': tw`transform-origin[right bottom]`,
  top: tw`transform-origin[bottom]`,
  'top-start': tw`transform-origin[bottom left]`,
  'top-end': tw`transform-origin[bottom right]`,
  bottom: tw`transform-origin[top]`,
  'bottom-start': tw`transform-origin[top left]`,
  'bottom-end': tw`transform-origin[top right]`,
};

export const transitions: Record<TransitionStatus, TwStyle> = {
  unmounted: tw`hidden`,
  exited: tw`opacity-0 invisible scale-75`,
  exiting: tw`opacity-0 pointer-events-none scale-75`,
  entering: tw`opacity-100 pointer-events-none scale-100`,
  entered: tw`opacity-100 scale-100`,
};
