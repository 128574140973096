import React, { useEffect } from 'react';
import ServiceProviderInfoChip from './ServiceProviderInfoChip';
import { useReadCompanyProfileByIDQuery } from '../../services/api/directory-service/company-profiles';
import { CompanyProfileResponse } from '../../types/directory-service/company-profile';
import { useLazyReadServiceProviderContactByIDQuery } from '../../services/api/marketplace-service/service-provider-contacts';
import { ProviderContact } from '../../types/marketplace-service/gql/service-provider-contact';
import { ServiceProviderContactAttributes } from '../../types/marketplace-service/service-provider-contact';

export interface GatewayServiceProviderInfoDisplayProps {
  provider: CompanyProfileResponse;
  contact?: ProviderContact | ServiceProviderContactAttributes;
}

export const GatewayServiceProviderInfo: React.FC<{
  provider: string;
  contact?: string;
  onDelete?: () => void;
}> = ({ provider, contact, onDelete }) => {
  const [readServiceProviderContactByID, serviceProviderContactState] =
    useLazyReadServiceProviderContactByIDQuery();

  useEffect(() => {
    if (contact) {
      readServiceProviderContactByID({ id: contact });
    }
  }, [contact, readServiceProviderContactByID]);

  const companyProfileQuery = useReadCompanyProfileByIDQuery({
    id: provider,
  });

  if (!companyProfileQuery?.isSuccess) {
    return null;
  }
  return (
    <ServiceProviderInfoChip
      provider={companyProfileQuery.data.entity}
      onDelete={onDelete}
      contact={serviceProviderContactState?.data?.entity?.attributes}
    />
  );
};
