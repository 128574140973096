import React, { useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  Divider,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  FormSpy,
  FormSpyRenderProps,
  useFormState,
  useForm,
} from 'react-final-form';
import {
  CreateListingFormState,
  ListingServiceMarketMeta,
} from '../CreateListing';
import AddServices from '../../components/CreateListing/AddServices';
import ServiceDetails from '../../components/ServiceDetails';
import ServiceRequirements from '../../components/CreateListing/ServiceRequirements';
import { getServiceUIName } from '../../constants';
import { ListingService, Services, ServiceTypes } from '../../types/services';
import ServiceDetailsMulti from '../../components/ServiceDetails/Multi';
import { deleteDocument } from '../../api';
import { useMarketsFromService } from '../../hooks/useMarketsFromService';
import { MarketEntity } from '../../types/market';

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      margin: `${theme.spacing(4)}px auto`,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    close: {
      color: theme.palette.grey[500],
    },
  })
);

type editType = 'services' | 'multi_requirements' | null;

const ServiceRequirementsSection: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editing, setEditing] = React.useState<[number, editType]>([-1, null]);
  const getEditService = (values: CreateListingFormState) => {
    if (!editing[1])
      throw new Error(
        "This function should not be called if edit type isn't set"
      );
    const key = editing[1];
    return values[key][editing[0]];
  };
  const form = useForm();
  const formState = useFormState<CreateListingFormState>();
  const getMarketsFromService = useMarketsFromService();

  const getOtherServiceMarketIds = useCallback(
    (index: number) => {
      return formState.values.services
        .filter(
          (otherService, otherServiceIndex) =>
            otherService.state !== 'delete' && index !== otherServiceIndex
        )
        .map((service) =>
          service.value.meta.markets.map((m) => m.attributes.id)
        )
        .flat();
    },
    [formState]
  );
  const addMarkets = useCallback(
    (markets: MarketEntity[]) => {
      markets.forEach((market) => {
        if (
          !formState.values.markets.find(
            (formMarket) =>
              formMarket.state !== 'delete' &&
              formMarket.value.attributes.id === market.attributes.id
          )
        ) {
          form.mutators.addResource('markets', market);
        }
      });
    },
    [form, formState]
  );

  const pruneMarkets = useCallback(
    (newMarkets: MarketEntity[], serviceIndex: number) => {
      const otherServiceMarketIds = getOtherServiceMarketIds(serviceIndex);
      const usedMarkets = [
        ...otherServiceMarketIds,
        ...newMarkets.map((market) => market.attributes.id),
      ];
      const indexesToDelete: number[] = [];
      formState.values.markets.forEach((formMarket, marketIndex) => {
        if (
          formMarket.state !== 'delete' &&
          !usedMarkets.includes(formMarket.value.attributes.id)
        ) {
          indexesToDelete.push(marketIndex);
        }
      });
      if (indexesToDelete.length) {
        form.mutators.deleteResources('markets', indexesToDelete);
      }
      addMarkets(newMarkets);
    },
    [formState, form, getOtherServiceMarketIds, addMarkets]
  );

  const saveService = useCallback(
    (
      action: 'ADD' | 'UPDATE',
      type: 'multi_requirements' | 'services',
      service: {
        service: ListingService<Services>;
        meta: ListingServiceMarketMeta;
      },
      index?: number
    ) => {
      action === 'UPDATE'
        ? form.mutators.editResource(type, index, service)
        : form.mutators.addResource(type, service);
    },
    [form]
  );

  return (
    <>
      <FormSpy subscription={{ values: true, pristine: true }}>
        {({ form, values }: FormSpyRenderProps<CreateListingFormState>) => (
          <>
            <AddServices
              open={open}
              onClose={() => setOpen(false)}
              onDone={async (service) => {
                const markets = getMarketsFromService(service);
                saveService(
                  'ADD',
                  service.type === ServiceTypes.multi_service_requirements
                    ? 'multi_requirements'
                    : 'services',
                  {
                    service: {
                      type: 'listing-service',
                      attributes: service,
                    },
                    meta: {
                      markets,
                    },
                  }
                );
                addMarkets(markets);

                setOpen(false);
              }}
              allowMultiRequirements={
                values.multi_requirements.filter(
                  (service) => service.state !== 'delete'
                ).length === 0
              }
            />
            {values.services.map(({ state, value }, index) => {
              const service = value.service;
              if (state !== 'delete' && service.attributes) {
                return (
                  <Box key={index} my={2}>
                    <ServiceDetails.Review
                      heading={
                        <>
                          {service.attributes.attributes.external_service_name
                            ? `${getServiceUIName(service.attributes.type)} (${
                                service.attributes.attributes
                                  .external_service_name
                              })`
                            : getServiceUIName(service.attributes.type)}
                          {service.attributes.attributes.external_service_id &&
                            ` (${service.attributes.attributes.external_service_id})`}
                        </>
                      }
                      onEdit={() => {
                        setEditing([index, 'services']);
                      }}
                      onDuplicate={() => {
                        form.mutators.duplicateResource('services', index);
                      }}
                      onDelete={() => {
                        pruneMarkets([], index);
                        form.mutators.deleteResource('services', index);
                      }}
                    >
                      <ServiceDetails.Summary
                        service={value.service.attributes}
                        marketplaceMatchLabels
                        listingEndDate={values.attributes.end}
                      />
                    </ServiceDetails.Review>
                    <Divider className={classes.divider} />
                  </Box>
                );
              }
              return null;
            })}
            {values.multi_requirements.map(({ state, value }, index) => {
              if (state !== 'delete' && value?.service.attributes) {
                return (
                  <Box my={2} key={index}>
                    <ServiceDetails.Review
                      heading={getServiceUIName(
                        ServiceTypes.multi_service_requirements
                      )}
                      onEdit={() => {
                        setEditing([index, 'multi_requirements']);
                      }}
                      onDelete={() => {
                        form.mutators.deleteResource(
                          'multi_requirements',
                          index
                        );
                      }}
                    >
                      <ServiceDetailsMulti
                        onDocumentDelete={(id) => {
                          deleteDocument(id);
                          const attributes = value.service.attributes;
                          saveService(
                            'UPDATE',
                            'multi_requirements',
                            {
                              ...value,
                              service: {
                                ...value.service,
                                attributes: {
                                  ...attributes,
                                  documents: attributes.documents.filter(
                                    (d) => d.id !== id
                                  ),
                                },
                              },
                            },
                            index
                          );
                        }}
                        {...value.service.attributes}
                      />
                    </ServiceDetails.Review>
                  </Box>
                );
              }
              return null;
            })}
            <Dialog
              open={!!editing[1]}
              onClose={() => setEditing([-1, null])}
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle disableTypography className={classes.title}>
                <Typography variant="h4">Edit Service</Typography>
                <IconButton
                  aria-label="close"
                  className={classes.close}
                  onClick={() => setEditing([-1, null])}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {!!editing[1] && (
                  <ServiceRequirements
                    onDone={async (service) => {
                      const currentService = getEditService(values).value;
                      const markets = getMarketsFromService(service);

                      saveService(
                        'UPDATE',
                        editing[1] as 'services' | 'multi_requirements',
                        {
                          service: {
                            ...currentService.service,
                            attributes: {
                              ...currentService.service.attributes,
                              ...service,
                            },
                          },
                          meta: {
                            markets,
                          },
                        },
                        editing[0]
                      );
                      pruneMarkets(markets, editing[0]);

                      setEditing([-1, null]);
                    }}
                    type={getEditService(values).value.service.attributes.type}
                    service={getEditService(values).value.service.attributes}
                  />
                )}
              </DialogContent>
            </Dialog>
          </>
        )}
      </FormSpy>
      <Box mt={3}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add Services
        </Button>
      </Box>
    </>
  );
};

export default ServiceRequirementsSection;
