import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock';
import { isFunction } from '../../utilities/is-function';
import { AppMenuContext } from './context';
import { AppMenuProps } from './types';

export const AppMenu = ({ children, maxWidth }: AppMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { clear } = useBodyScrollLock();

  const openAppMenu = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeAppMenu = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const toggleAppMenu = useCallback(
    (value?: boolean) => {
      value === undefined ? setOpen((open) => !open) : setOpen(value);
    },
    [setOpen]
  );

  const hideOnEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        clear();
        closeAppMenu();
      }
    },
    [open, closeAppMenu, clear]
  );

  const appMenuVisible = useMediaQuery({ maxWidth });

  useEffect(() => {
    document.addEventListener('keydown', hideOnEsc, false);

    return () => {
      document.removeEventListener('keydown', hideOnEsc, false);
    };
  }, [hideOnEsc]);

  useEffect(() => {
    if (!appMenuVisible) {
      clear();
      closeAppMenu();
    }
  }, [appMenuVisible, closeAppMenu, clear]);

  return (
    <AppMenuContext.Provider
      value={{ isOpen: open, openAppMenu, closeAppMenu, toggleAppMenu }}
    >
      {isFunction(children)
        ? children({ isOpen: open, toggleAppMenu, openAppMenu, closeAppMenu })
        : children}
    </AppMenuContext.Provider>
  );
};
