import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import UnstyledLink from '../../../components/UnstyledLink';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BackButton from '../../../components/BackButton';
import { ListingOpportunityActionMenu } from '../../../containers/ListingOpportunityManager';

const SellingListingDetailHeader: React.FC<{
  back: string;
  splitScreenMode: boolean;
}> = ({ back, splitScreenMode }) => {
  return (
    <>
      <Hidden mdUp>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <UnstyledLink to={back}>
              <BackButton />
            </UnstyledLink>
          </Grid>
          <Grid item xs={4} container justifyContent="center">
            <Typography variant="h6"> Sell </Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <ListingOpportunityActionMenu />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid item xs="auto">
          <UnstyledLink to={back}>
            {!splitScreenMode ? (
              <BackButton />
            ) : (
              <Button
                color="primary"
                endIcon={<ChevronRightIcon>close</ChevronRightIcon>}
                variant="text"
                size="small"
              >
                Close
              </Button>
            )}
          </UnstyledLink>
        </Grid>
      </Hidden>
    </>
  );
};

export default SellingListingDetailHeader;
