import React from 'react';
import { Typography, Grid, Fade, Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CSTextField, { CSTextFieldProps } from './../CSTextField/CSTextField';
import DynamicHelperText, { Level } from './DynamicHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[700],
    },
    icon: {
      margin: 'auto 5px',
      color: theme.palette.grey[400],
      fontSize: 16,
    },
  })
);
interface InputWithDynamicHelperTextProps extends CSTextFieldProps {
  title?: string;
  subTitle?: string;
  label?: string;
  value?: string;
  tooltip?: React.ReactNode;
}

const getCurrentLevel = (wordCount: number): Level => {
  if (wordCount <= 3) {
    return 'low';
  }

  if (wordCount <= 5) {
    return 'med';
  }

  return 'high';
};

const InputWithDynamicHelperText: React.FC<
  Omit<InputWithDynamicHelperTextProps, 'css'>
> = ({ title, subTitle, tooltip, value = '', ...props }) => {
  const classes = useStyles();
  const numWords = value.trim().split(' ').length;
  const level = getCurrentLevel(numWords);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CSTextField
            name={title}
            label={
              <>
                <Typography className={classes.label}>{title}</Typography>
                <Typography variant="subtitle2">{subTitle}</Typography>
                {tooltip && (
                  <Tooltip placement="right" arrow title={tooltip}>
                    <HelpOutlineIcon className={classes.icon} />
                  </Tooltip>
                )}
              </>
            }
            value={value}
            {...props}
          />

          <Box my={1}>
            <Fade in={!!value}>
              <DynamicHelperText level={level} />
            </Fade>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InputWithDynamicHelperText;
