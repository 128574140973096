import React from 'react';
import { dequal } from 'dequal/lite';
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  InputLabel,
} from '@material-ui/core';
import {
  Form,
  FormSpy,
  Field,
  FormRenderProps,
  FormSpyRenderProps,
} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import validationSchema from './validation';
import TextField from '../../../Form/CSTextField';
import FileUpload from '../../../FileUpload';
import AttachmentList from '../../../AttachmentList';
import { deleteDocument } from '../../../../api';
import { getFeatureUIName, TECH_SPECS_API_KEYS } from '../../../../constants';
import { ServiceMultiRequirements } from '../../../../types/services';
import { Document } from '../../../../types/document';
import { useValidationSchema } from '../../../../hooks/useValidationSchema';

interface ServiceRequirementsMultiProps {
  onDone: (values: any) => void;
  values?: ServiceMultiRequirements;
}

const getInitialValues = (): ServiceMultiRequirements => ({
  type: 'multi_service_requirements',
  additional_info: '',
  documents: [],
  attributes: {
    requirements: {
      [TECH_SPECS_API_KEYS.ethernet]: false,
      [TECH_SPECS_API_KEYS.ip_transit]: false,
      [TECH_SPECS_API_KEYS.dark_fiber]: false,
      [TECH_SPECS_API_KEYS.cloud_connectivity]: false,
      [TECH_SPECS_API_KEYS.wavelength]: false,
      [TECH_SPECS_API_KEYS.colocation]: false,
      [TECH_SPECS_API_KEYS.sd_wan]: false,
      [TECH_SPECS_API_KEYS.other]: false,
    },
  },
});

const ServiceRequirementsMulti = React.memo(
  ({ onDone, values = getInitialValues() }: ServiceRequirementsMultiProps) => {
    const validator = useValidationSchema(validationSchema);

    return (
      <Box my={2}>
        <Form
          initialValues={values}
          keepDirtyOnReinitialize
          initialValuesEqual={(a, b) => dequal(a, b)}
          validate={validator}
          onSubmit={(values) => onDone(values)}
          mutators={{ ...arrayMutators }}
          subscription={{ pristine: true }}
        >
          {({
            form,
            handleSubmit,
          }: FormRenderProps<ServiceMultiRequirements>) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Connectivity
                  </Typography>
                  <FormGroup row>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.ethernet}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(TECH_SPECS_API_KEYS.ethernet)}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.dark_fiber}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(
                            TECH_SPECS_API_KEYS.dark_fiber
                          )}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.internet_access}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(
                            TECH_SPECS_API_KEYS.internet_access
                          )}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.ip_transit}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(
                            TECH_SPECS_API_KEYS.ip_transit
                          )}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.wavelength}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(
                            TECH_SPECS_API_KEYS.wavelength
                          )}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.cloud_services}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(
                            TECH_SPECS_API_KEYS.cloud_services
                          )}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.sd_wan}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(TECH_SPECS_API_KEYS.sd_wan)}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.other}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(TECH_SPECS_API_KEYS.other)}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Colocation
                  </Typography>
                  <FormGroup row>
                    <Field
                      name={`attributes.requirements.${TECH_SPECS_API_KEYS.colocation}`}
                      type="checkbox"
                    >
                      {({ input }) => (
                        <FormControlLabel
                          label={getFeatureUIName(
                            TECH_SPECS_API_KEYS.colocation
                          )}
                          control={<Checkbox color="primary" size="small" />}
                          {...input}
                        />
                      )}
                    </Field>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Field name="additional_info">
                    {({ input }) => (
                      <TextField
                        label="Description of Requirements"
                        placeholder="Enter a detailed description of your requirements or give a high-level overview of the services you need"
                        multiline
                        minRows={6}
                        {...input}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Box py={2}>
                    <Box mb={1}>
                      <InputLabel>
                        Documentation to Support Requirements
                      </InputLabel>
                    </Box>
                    <FileUpload
                      onAdd={(file) => {
                        form.mutators.push('documents', file);
                      }}
                    >
                      <FormSpy subscription={{ values: true, pristine: true }}>
                        {({ form, values }: FormSpyRenderProps) => {
                          const documents = values.documents;
                          return documents.map(
                            (document: Document, index: number) => (
                              <AttachmentList
                                document={document}
                                key={index}
                                onDelete={(id: Document['id']) => {
                                  deleteDocument(id);
                                  form.mutators.remove('documents', index);
                                }}
                              />
                            )
                          );
                        }}
                      </FormSpy>
                    </FileUpload>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box mx="auto" mt={2} width={200}>
                  <FormSpy
                    subscription={{
                      submitting: true,
                      valid: true,
                    }}
                  >
                    {({ form, submitting, valid }: FormSpyRenderProps) => (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={form.submit}
                        fullWidth
                        disabled={submitting || !valid}
                      >
                        Done
                      </Button>
                    )}
                  </FormSpy>
                </Box>
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    );
  }
);

export default ServiceRequirementsMulti;
