import { isAfter } from 'date-fns';
import { ListingState } from '../types/listing';
import { ProviderEntity } from '../types/provider';
import { Market } from '../types/market';
import { ServiceMultiRequirements, Services } from '../types/services';
import { getFeatureUIName } from '../constants';

export const listingHasEnded = (state: ListingState, end: Date) => {
  return state === 'ended' || state === 'completed' || isAfter(new Date(), end);
};

/**
 * Checks whether or not the listing/opportunity is assigned to the logged in user
 * @param listingUserId
 * @param userId
 * @returns boolean
 */
export const isListingOwner = (
  listingUserId?: string | null,
  userId?: string | null
) => {
  return listingUserId && userId ? listingUserId === userId : false;
};

export const getOpportunityStatusLabels = (listingProvider: ProviderEntity) => {
  if (listingProvider.attributes.declined_at) {
    return 'declined';
  }
  if (
    listingProvider.attributes.state === 'intend' ||
    listingProvider.attributes.state === 'bid'
  ) {
    return listingProvider.attributes.state;
  }
  if (
    listingProvider.attributes.state === 'assign' &&
    listingProvider.attributes.intended_to_bid_at !== null
  ) {
    return 'intend';
  }
  return null;
};

export const createListingTitle = (
  marketDetails?: Market[],
  service?: Exclude<Services, ServiceMultiRequirements>
) => {
  if (!marketDetails || !service) return '';
  const serviceName = getFeatureUIName(service.type);
  if (marketDetails.length === 1) {
    return `${serviceName} in ${marketDetails[0]?.name}`;
  }
  return `${serviceName} from ${marketDetails[0]?.name} to ${marketDetails[1]?.name}`;
};
