import {
  createEntityAdapter,
  createSlice,
  bindActionCreators,
  nanoid,
} from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider, VariantType } from 'notistack';
import { withStyles } from '@material-ui/core/styles';

export type Message = {
  variant: VariantType;
  description: string;
  id: string;
  type?: string;
};

const messagesAdapter = createEntityAdapter<Message>();

const messages = createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState(),
  reducers: {
    addMessage: {
      reducer: messagesAdapter.addOne,
      prepare(variant: VariantType, description: string, type?: string) {
        return { payload: { id: nanoid(), variant, description, type } };
      },
    },
    removeMessage: {
      reducer: messagesAdapter.removeOne,
      prepare(id: string) {
        return { payload: id };
      },
    },
  },
});

export const { addMessage, removeMessage } = messages.actions;

export const {
  selectById: selectMessageById,
  selectIds: selectMessageIds,
  selectEntities: selectMessageEntities,
  selectAll: selectAllMessages,
  selectTotal: selectTotalMessages,
} = messagesAdapter.getSelectors((state: RootState) => state.messages);

export const AppMessengerProvider = withStyles((theme) => ({
  variantSuccess: { backgroundColor: theme.palette.success.main },
  variantError: { backgroundColor: theme.palette.error.main },
  variantWarning: { backgroundColor: theme.palette.warning.main },
  variantInfo: { backgroundColor: theme.palette.primary.main },
}))(SnackbarProvider);

export const useAppMessenger = () => {
  const dispatch = useDispatch();
  const allMessages = useSelector(selectAllMessages);

  return {
    messages: allMessages,
    ...bindActionCreators({ addMessage, removeMessage }, dispatch),
  };
};

export default messages.reducer;
