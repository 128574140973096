import tw, { styled, css } from 'twin.macro';
import { API_ACCOUNT_URL } from '../../../api';
import { ImageProps, ProfileImageProps } from './types';

const Image = styled.div<ImageProps>(({ url, size = 'normal' }) => [
  size === 'small' && tw`h-6 w-6`,
  size === 'normal' && tw`h-10 w-10`,
  size === 'large' && tw`h-20 w-20`,
  tw`bg-cover bg-center rounded-full bg-gray-100`,
  css`
    background-image: url(${url});
  `,
]);

const ProfileImage = ({ id, size = 'normal' }: ProfileImageProps) => {
  return (
    <Image url={`${API_ACCOUNT_URL}/v2/users/${id}/profileImage`} size={size} />
  );
};

export default ProfileImage;
