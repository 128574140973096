import { useContext } from 'react';
import { FiX } from 'react-icons/fi';
import tw, { css } from 'twin.macro';
import { Subtitle } from '../../../../components/Cloudscene/Typography';
import { ModalContext } from '../../../../components/Cloudscene/Modal';

export const Header = () => {
  const ctx = useContext(ModalContext);

  return (
    <div
      css={[
        tw`bg-cover bg-center h-48 bg-gray-900 w-full flex justify-between items-start`,
        css`
          background-image: url('/sales-pro.variant-alpha.webp');
          text-shadow: 1px 1px 8px black;
        `,
      ]}
    >
      <div css={tw`grid place-items-center w-full h-full px-8`}>
        <Subtitle css={tw`text-white text-5xl`}>
          Upgrade to bid on this opportunity
        </Subtitle>
      </div>
      <button
        aria-label="Close this dialog"
        css={tw`p-2 grid place-items-center text-gray-100 cursor-pointer hover:text-gray-300`}
        onClick={() => ctx?.closeModal()}
      >
        <FiX size={24} />
      </button>
    </div>
  );
};
