import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Pill from '../Pill';
import AttachmentList from '../AttachmentList';
import {
  ServiceTypeKeys,
  ServiceMultiRequirements as ServiceMultiRequirementsProps,
} from '../../types/services';
import { Document } from '../../types/document';
import { getServiceUIName } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pill: {
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
  })
);

const ServiceDetailsMultiRequirements: React.FC<
  ServiceMultiRequirementsProps & {
    onDocumentDelete?: (id: Document['id'], index: number) => void;
  }
> = ({ additional_info, attributes, documents, onDocumentDelete }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {Object.entries(attributes.requirements)
          .filter(([, value]) => value)
          .map(([type]) => (
            <Pill
              className={classes.pill}
              key={type}
              variant="outlined"
              label={getServiceUIName(type as ServiceTypeKeys)}
            />
          ))}
      </Grid>
      <Grid item xs={12}>
        <Typography component="div">{additional_info}</Typography>
      </Grid>
      {Array.isArray(documents) && documents.length > 0 && (
        <Grid item xs={12}>
          {documents.map((document, index: number) => (
            <AttachmentList key={index} document={document} />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ServiceDetailsMultiRequirements;
