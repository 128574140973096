import React, { FunctionComponent, useState } from 'react';
import Dropdown from '../../components/Form/Dropdown';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useThrottle } from '../../hooks/useThrottle';

interface RegisterToBidModalProps {
  onDecline: (reason: string) => void;
  onClose: () => void;
  open?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dropDown: {
      marginTop: theme.spacing(2),
    },
  })
);

const options = [
  'We do not provide the required services',
  'We do not operate in the selected markets',
  'We cannot fulfill the contract term',
  'We cannot achieve the RFS date',
  'We are working directly with this buyer',
  'We would prefer not to say',
].map((option) => ({ value: option, label: option }));

const DeclineOpportunityModal: FunctionComponent<RegisterToBidModalProps> = ({
  onDecline,
  onClose,
  open = false,
}) => {
  const classes = useStyles();
  const [reason, setReason] = useState<string>('');
  const handleDecline = useThrottle(onDecline, 3000);

  return (
    <Dialog
      onClose={(event, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') onClose();
      }}
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography align="center" component="div" variant="h2">
          Decline this Opportunity
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <Typography align="center">
              Declining an opportunity will notify the buyer that you are unable
              to fulfil their service requirements.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography align="center">
              Let the buyer know why you have declined.
            </Typography>
            <Dropdown
              fullWidth
              value={reason}
              onChange={(event) => {
                setReason(event.target.value as string);
              }}
              className={classes.dropDown}
              placeholder="Select an option"
              options={options}
            />
          </Grid>

          <Grid
            item
            xs
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
            direction="column"
          >
            <Grid item xs>
              <Button
                disabled={reason === null}
                color="primary"
                variant="contained"
                onClick={() => handleDecline(reason!)}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeclineOpportunityModal;
