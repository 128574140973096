import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import axios from '../../services/axios';
import { ListingInfoContext } from '../Listings/ListingInfo';
import { API_BASE_URL } from '../../api';
import ServiceProviderInfo from '../ServiceProviderInfoBlock';
import InviteMoreProvidersInfo from '../InviteMoreProvidersInfo';
import {
  ListingProviderContact,
  ProviderContact,
} from '../../types/provider-contact';
import { filterRelationshipEntityType } from '../../utilities/relationships';
import LoadingSpinner from '../LoadingSpinner';
import { useLazyReadAllListingProvidersByListingIDQuery } from '../../services/api/marketplace-service/listing-providers';

const AdminListingProviders: React.FC = () => {
  const { listing } = useContext(ListingInfoContext);

  const [readListingProvidersByListingID, { providers, isFetching }] =
    useLazyReadAllListingProvidersByListingIDQuery({
      selectFromResult: (result) => {
        const isFetching = result?.isFetching;
        const providers =
          result?.data?.entities
            .filter((provider) => provider.relationships.provider.data !== null)
            .map((provider) => provider.relationships.provider.data.id) ?? [];
        return { providers, isFetching };
      },
    });
  const listingProviderContacts = useQuery(
    ['admin-listing-provider-contacts'],
    () => {
      return axios
        .get<{
          data: ListingProviderContact[];
          included: ProviderContact[];
        }>(`${API_BASE_URL}/listings/${listing?.id}/provider-contacts`)
        .then((data) =>
          filterRelationshipEntityType<ProviderContact>(
            'provider-contact',
            data.data.included
          )
        );
    },
    { enabled: !!listing?.id }
  );

  useEffect(() => {
    if (listing) {
      readListingProvidersByListingID({
        listing: listing.id,
      });
    }
  }, [listing, readListingProvidersByListingID]);

  if (isFetching || listingProviderContacts.isLoading) {
    return <LoadingSpinner />;
  }

  if (providers.length) {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="caption" component="div" color="textSecondary">
            Cloudscene Admin
          </Typography>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              Invited Service Providers
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="div" color="textSecondary">
              Buyer Email - {listing?.attributes.contact?.email}
            </Typography>
          </Grid>
          {providers.map((id) => (
            <Grid key={id} item xs={12}>
              <ServiceProviderInfo
                contact={
                  listingProviderContacts.data?.find(
                    (providerContact) =>
                      providerContact.attributes.provider_id === id
                  )?.id
                }
                provider={id}
              />
            </Grid>
          ))}
          {listing?.attributes.visibility === 'private' && (
            <Grid item xs={12}>
              <InviteMoreProvidersInfo
                inviteMore={listing?.attributes.invite_providers}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default AdminListingProviders;
