import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  lazy,
  Suspense,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FlagsmithProvider } from '@cloudscene-dev/flagsmith-react';
import { marketsIndex } from '../api';
import { AppDispatch } from '../store/types.d';
import { useAuth } from '../hooks/useAuth';
import { Market } from '../types/market';
import { fetchAllProviderContactsByUser } from '../store/provider-contacts';
import MarketplaceHeader from '../components/Header';
import { Header, SecondaryMenu } from '../features/Header';
import tw from 'twin.macro';
import { useMatch } from '@reach/router';
import { ROUTES } from '../routes';
import { useReadCompanyAccountUsersQuery } from '../services/api/account-service/company-accounts';
import { ActOnBehalfStatus } from '../components/ActOnBehalf';

export type MarketsAppState = {
  [key: string]: Market;
};

const HeaderOmniSearch = lazy(() =>
  import('../features/OmniSearch').then((OmniSearch) => ({
    default: OmniSearch.Header,
  }))
);

const CompanyAccountUsers = ({ company }: { company: string }) => {
  useReadCompanyAccountUsersQuery(
    {
      id: company,
      params: {
        'page[size]': 100,
        include: 'companyAccount,roles',
      },
    },
    {
      pollingInterval: 300000,
      skip: !company,
    }
  );

  return <></>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      background: theme.palette.primary.main,
      padding: theme.spacing(1, 2),
      zIndex: 5,
      width: '100%',
    },
    message: {
      color: theme.palette.common.white,
      fontSize: 14,
    },
  })
);

export type AppState = {
  markets: MarketsAppState;
  layoutPreference: LayoutPreference;
  setLayoutPreference: (layoutPreference: LayoutPreference) => void;
  menuIsOpen: boolean;
  closeAppMenu: () => void;
  openAppMenu: () => void;
};

export type LayoutPreference = 'SPLITSCREEN' | 'FULLSCREEN';

const AppStateContext = React.createContext<AppState>({
  markets: {},
  layoutPreference: 'SPLITSCREEN',
  setLayoutPreference: () => null,
  menuIsOpen: false,
  closeAppMenu: () => {},
  openAppMenu: () => {},
});

export const AppStateProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const [markets, setMarkets] = useState<MarketsAppState>({});
  const isOnboarding = useMatch(`${ROUTES.signup.root}`);
  const [layoutPreference, setLayoutPreference] =
    useState<LayoutPreference>('SPLITSCREEN');
  const allowSplitScreen = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up('lg'),
    {
      noSsr: true,
    }
  );
  const [menuIsOpen, setAppMenuOpen] = useState<boolean>(false);
  const closeAppMenu = useCallback(() => {
    setAppMenuOpen(false);
  }, [setAppMenuOpen]);
  const openAppMenu = useCallback(() => {
    setAppMenuOpen(true);
  }, [setAppMenuOpen]);
  useEffect(() => {
    setLayoutPreference(allowSplitScreen ? 'SPLITSCREEN' : 'FULLSCREEN');
  }, [allowSplitScreen]);

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchAllProviderContactsByUser(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    let hits: MarketsAppState = {};
    marketsIndex
      .browseObjects({
        filters: 'type:markets',
        batch: (
          batch: readonly {
            objectID: string;
            marketName: string;
            zoneName: string;
            zoneIso: string;
          }[]
        ) => {
          hits = batch.reduce((acc, hit) => {
            const id = hit.objectID;
            if (id) {
              acc[id] = {
                id,
                name: hit.marketName || hit.zoneName,
                zone: hit.zoneName,
                countryCode: hit.zoneIso,
                flag: `https://cloudscene.global.ssl.fastly.net/CountryFlags/${hit.zoneIso}.png`,
                type: 'marketName' in hit ? 'market' : 'zone',
              };
            }
            return acc;
          }, hits);
        },
      })
      .then(() => {
        setMarkets(hits);
      });
  }, []);

  return (
    <FlagsmithProvider
      environmentID={process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID}
      identity={user?.id}
    >
      <AppStateContext.Provider
        value={{
          markets,
          layoutPreference,
          setLayoutPreference,
          closeAppMenu,
          openAppMenu,
          menuIsOpen,
        }}
      >
        {!isOnboarding && (
          <Header
            secondary={
              <>
                <SecondaryMenu variant="contained">
                  <MarketplaceHeader />
                </SecondaryMenu>
                <div className={classes.status}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <Typography
                        component="div"
                        align="center"
                        className={classes.message}
                      >
                        <Link
                          target="_blank"
                          underline="hover"
                          rel="noreferrer noopener"
                          href="https://help.cloudscene.com/support/weve-simplified-the-way-you-connect-on-cloudscenes-marketplace/"
                          className={classes.message}
                        >
                          We have simplified the way you connect on
                          Cloudscene&apos;s Marketplace. Learn more about how we
                          have improved your experience.
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </>
            }
          >
            <Suspense fallback={<></>}>
              <HeaderOmniSearch />
            </Suspense>
          </Header>
        )}
        <ActOnBehalfStatus />
        <div css={[menuIsOpen && tw`hidden`]}>{children}</div>
        {user && <CompanyAccountUsers company={user.company_account_id} />}
      </AppStateContext.Provider>
    </FlagsmithProvider>
  );
};

export const useAppState = (): AppState => {
  const context = useContext(AppStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
};
