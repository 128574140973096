import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  formatAddressForSearch,
  formatCategoryForSearch,
} from '../../utilities/marketplace-match';
import { PhysicalAddress, ServiceTypeKeys } from '../../types/services';
import ProductInfoLabel from './ProductInfoLabel';
import { useQuery } from 'react-query';
import axios from '../../services/axios';
import { MARKETPLACE_MATCH_SEARCH_URL } from '../../api';

interface MarketPlaceMatchInfoLabelProps {
  aEnd?: PhysicalAddress;
  serviceType: ServiceTypeKeys;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkCircle: {
      width: '20px',
      margin: theme.spacing(1.5, 0, 1, 1),
    },
    nearNet: {
      color: theme.palette.warning.main,
    },
    onNet: {
      color: theme.palette.success.main,
    },
  })
);

const MarketPlaceMatchInfoLabel: React.FC<MarketPlaceMatchInfoLabelProps> = ({
  aEnd,
  serviceType,
}) => {
  const classes = useStyles();
  const pointA = formatAddressForSearch(aEnd);
  const category = formatCategoryForSearch(serviceType);

  const { data } = useQuery(
    ['marketplace-match', category, pointA],
    () => {
      return axios.post(MARKETPLACE_MATCH_SEARCH_URL, {
        product: category,
        providers: [],
        aEnd: pointA,
      });
    },
    {
      enabled: category !== null && pointA !== null,
    }
  );
  const products = data?.data;
  if (!products || products.meta.onNet.aEnd + products.meta.nearNet.aEnd === 0)
    return null;

  return (
    <Grid container spacing={2} item xs="auto">
      <CheckCircleIcon
        color="primary"
        className={clsx(classes.checkCircle, {
          [classes.nearNet]:
            products.meta.onNet.aEnd === 0 && products.meta.nearNet.aEnd > 0,
          [classes.onNet]: products.meta.onNet.aEnd > 0,
        })}
      />
      <Grid item xs="auto">
        <ProductInfoLabel meta={products.meta} />
      </Grid>
    </Grid>
  );
};

export default MarketPlaceMatchInfoLabel;
