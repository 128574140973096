import React, { useEffect, useMemo, useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Tab, Grid, Typography, Box, Hidden } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import ServiceProviderInfo, {
  ProviderState,
} from '../ServiceProviderInfoBlock';
import { useLazyReadAllBidsByListingIDQuery } from '../../services/api/marketplace-service/bids';
import BidActions from '../BidActions';
import { selectListingProvidersFromRelationship } from '../../store/listing-providers';
import { ListingInfoContext } from '../Listings/ListingInfo';
import UnsuccessfulProviders from '../BidActions/UnsuccessfulProviders';
import CSVQuery from '../CSVQuery';
import { getOpportunityStatusLabels } from '../../utilities/listings';
import { BidResponse } from '../../types/marketplace-service/bid';
import { useAppSelector } from '../../store/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      flexDirection: 'row',
    },
    panel: {
      width: '100%',
    },
    chip: {
      display: 'inline-flex',
      marginLeft: theme.spacing(1.2),
      padding: theme.spacing(0.4, 0.8),
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: 1,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
    },
    text: {
      margin: theme.spacing(4, 0),
    },
    providers: {
      margin: theme.spacing(1, 0),
    },
    bidCardTitle: {
      margin: theme.spacing(4, 0),
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const ListingTabs: React.FC = () => {
  const classes = useStyles();
  const { listing } = useContext(ListingInfoContext);
  const [readAllBidsByListingIDQuery, { submitted, interested, uninterested }] =
    useLazyReadAllBidsByListingIDQuery({
      selectFromResult: (response) => {
        const entities = response.data?.entities ?? [];

        const result = entities.reduce<{
          submitted: BidResponse[];
          interested: BidResponse[];
          uninterested: BidResponse[];
        }>(
          (result, entity) => {
            result[entity.attributes.state].push(entity);
            return result;
          },
          {
            submitted: [],
            interested: [],
            uninterested: [],
          }
        );

        return result;
      },
    });
  const providers = useAppSelector(
    selectListingProvidersFromRelationship(listing)
  );
  const [tab, setTab] = React.useState('invited');
  const handleChange = (event: React.ChangeEvent<{}>, tab: string) => {
    setTab(tab);
  };

  const nonSuccessful = useMemo(() => {
    return [...submitted, ...interested, ...uninterested];
  }, [submitted, interested, uninterested]);

  useEffect(() => {
    if (listing) {
      readAllBidsByListingIDQuery({
        listing: listing.id,
        params: { include: ['user', 'companyAccount', 'documents'].join(',') },
      });
    }
  }, [readAllBidsByListingIDQuery, listing]);

  if (!listing) {
    return null;
  }

  return (
    <TabContext value={tab}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs md={8}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            indicatorColor="primary"
          >
            <Tab
              classes={{ wrapper: classes.tab }}
              label={
                <>
                  Invited
                  {providers.length > 0 && (
                    <span className={classes.chip}>{providers.length}</span>
                  )}
                </>
              }
              value="invited"
            />
            <Tab
              classes={{ wrapper: classes.tab }}
              value="bids"
              label={
                <>
                  Bids
                  {submitted.length > 0 && (
                    <span className={classes.chip}>{submitted.length}</span>
                  )}
                </>
              }
            />
          </TabList>
        </Grid>
        <Hidden mdDown>
          <CSVQuery
            listingId={listing.id}
            title={listing.attributes.title}
            disabled={
              submitted.length === 0 &&
              interested.length === 0 &&
              uninterested.length === 0
            }
          />
        </Hidden>
      </Grid>
      <TabPanel classes={{ root: classes.panel }} value="invited">
        {providers.length > 0 && (
          <Grid container spacing={2}>
            {providers.map((provider) => (
              <Grid item xs={12} key={provider.id}>
                <ProviderState id={provider.id}>
                  {(listingProvider) => (
                    <ServiceProviderInfo
                      provider={listingProvider.attributes.external_provider_id}
                      status={getOpportunityStatusLabels(listingProvider)}
                    >
                      {listingProvider.attributes.declined_at && (
                        <Typography variant="body1" component="div">
                          <Box fontWeight="fontWeightMedium">
                            {listingProvider.attributes.declined_reason}
                          </Box>
                        </Typography>
                      )}
                    </ServiceProviderInfo>
                  )}
                </ProviderState>
              </Grid>
            ))}
          </Grid>
        )}
      </TabPanel>
      <TabPanel classes={{ root: classes.panel }} value="bids">
        {submitted.length > 0 && (
          <Typography align="left" className={classes.bidCardTitle}>
            Let the Service Provider know if you're interested in their bid.
          </Typography>
        )}

        <Grid container spacing={2}>
          {submitted.map((bid) => (
            <Grid key={bid.id!} item xs={12}>
              <BidActions
                company={bid.relationships.companyAccount?.data?.id}
                userId={bid.relationships.user?.data?.id}
                bid={bid}
                listing={listing}
                actions={{
                  view: true,
                  interested: true,
                  uninterested: true,
                  showRating:
                    submitted.length === 1 &&
                    listing.attributes.state === 'ended',
                }}
              >
                <UnsuccessfulProviders bids={nonSuccessful} bid={bid} />
              </BidActions>
            </Grid>
          ))}
        </Grid>
        {interested.length > 0 && (
          <>
            <Typography align="left" className={classes.bidCardTitle}>
              Providers you are interested in
            </Typography>
            <Grid container spacing={2}>
              {interested.map((bid) => (
                <Grid key={bid.id!} item xs={12}>
                  <BidActions
                    company={bid.relationships.companyAccount?.data?.id}
                    userId={bid.relationships.user?.data?.id}
                    bid={bid}
                    listing={listing}
                    actions={{
                      view: true,
                      interested: false,
                      uninterested: false,
                      showRating: false,
                    }}
                  >
                    <UnsuccessfulProviders bids={nonSuccessful} bid={bid} />
                  </BidActions>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {uninterested.length > 0 && (
          <>
            <Typography align="left" className={classes.bidCardTitle}>
              Bids you passed on
            </Typography>
            <Grid container spacing={2}>
              {uninterested.map((bid) => (
                <Grid key={bid.id!} item xs={12}>
                  <BidActions
                    company={bid.relationships.companyAccount?.data?.id}
                    userId={bid.relationships.user?.data?.id}
                    bid={bid}
                    listing={listing}
                    actions={{
                      view: true,
                      interested: true,
                      uninterested: false,
                      showRating:
                        submitted.length === 1 &&
                        listing.attributes.state === 'ended',
                    }}
                  >
                    <UnsuccessfulProviders bids={nonSuccessful} bid={bid} />
                  </BidActions>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </TabPanel>
    </TabContext>
  );
};

export default ListingTabs;
