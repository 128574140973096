import { RootState } from '../../store/types';
import { getAccessTokenHandler } from '../auth';
import { getFingerprintHandler } from '../fingerprint-handler';

export const prepareHeaders = async (
  headers: Headers,
  api: { getState: () => unknown }
) => {
  await attachAuthorizationHeader(headers);
  await attachActingOnBehalfHeader(headers, api.getState() as RootState);
  await attachJSONHeader(headers);
  await attachFingerprintHeader(headers);

  return headers;
};

export const attachAuthorizationHeader = async (headers: Headers) => {
  const getAccessToken = getAccessTokenHandler();

  if (!getAccessToken) {
    return headers;
  }

  try {
    const token = await getAccessToken();

    if (token && !headers.has('Authorization')) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  } catch (err) {
    return headers;
  }
};

export const attachActingOnBehalfHeader = async (
  headers: Headers,
  state: RootState
) => {
  const user = state.acting.user;

  if (user.id) {
    headers.set('On-Behalf-Of-UserId', user.id);
    headers.set('On-Behalf-Of-CompanyAccountId', user.account);
    headers.set('On-Behalf-Of-ClaimedCompanyIds', user.companies.join(','));
    headers.set('On-Behalf-Of-Permissions', user.permissions.join(','));
  }

  return headers;
};

export const attachFingerprintHeader = async (headers: Headers) => {
  const getFingerprint = getFingerprintHandler();

  if (!getFingerprint) {
    return headers;
  }

  try {
    const fp = await getFingerprint();
    if (fp && !headers.has('CS-Visitor')) {
      headers.set('CS-Visitor', fp.visitorId);
    }
    return headers;
  } catch (err) {
    return headers;
  }
};

export const attachJSONHeader = async (headers: Headers) => {
  if (!headers.has('Accept')) headers.set('Accept', 'application/vnd.api+json');
  if (!headers.has('Content-Type'))
    headers.set('Content-Type', 'application/vnd.api+json');

  return headers;
};
