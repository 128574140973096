import { object, string, array, boolean } from 'yup';
import { getServiceRequirementValidationSchema } from '../../utilities/service-requirements';
import { ServicesMeta } from './types';
import { ServiceMultiRequirements } from '../../types/services';

export const schema = object({
  attributes: object({
    title: string().trim().required().label('Title').min(10),
    external: boolean(),
    external_link: string().when('external', {
      is: true,
      then: string().trim().url().required().label('Link'),
    }),
    external_contact_email: string().when('external', {
      is: true,
      then: string().trim().email().label('Email'),
    }),
    company: string().required().label('Company'),
    start: string().required().label('Start Date'),
    end: string().required().label('End Date'),
  }),
  owner: object({
    full_name: string().trim().required().label('Full Name'),
    email: string().trim().required().label('Email'),
    position: string().trim(),
    phone: string().trim().label('Phone'),
    user_details_agreement: boolean()
      .oneOf([true])
      .label('User Details Agreement'),
  })
    .defined()
    .label('Contact Details'),
  contacts: array(),
  documents: array(),
  services: array().when('multi_requirements', {
    is: (multi_requirements) =>
      multi_requirements.filter(
        (service: ServicesMeta<ServiceMultiRequirements>) =>
          service.state === 'add' ||
          service.state === 'initial' ||
          service.state === 'edit'
      ).length === 0,
    then: array().test(
      'atLeastOne',
      'At least one valid service is required',
      async (value?: ServicesMeta<ServiceMultiRequirements>[]) => {
        if (!value) return false;
        const services = value.filter(
          (service) =>
            service.state === 'add' ||
            service.state === 'initial' ||
            service.state === 'edit'
        );
        if (!services.length) return false;
        return await Promise.all(
          services.map((serviceMeta) => {
            const schema = getServiceRequirementValidationSchema(
              serviceMeta.value.service.attributes.type
            );
            return schema
              .validate(serviceMeta.value.service.attributes, {
                abortEarly: false,
              })
              .then((res) => {
                return res;
              });
          })
        )
          .then((validated) => {
            return !!validated;
          })
          .catch((e) => {
            return !!e.length;
          });
      }
    ),
    otherwise: array(),
  }),
  multi_requirements: array(),
  providers: array(),
  provider_contacts: array(),
  markets: array().test(
    'atLeastOne',
    'At least one market is required',
    (markets) => {
      if (!markets) return false;
      return (
        markets.filter(
          (market: any) => market.state === 'add' || market.state === 'initial'
        ).length > 0
      );
    }
  ),
  status: object().defined(),
}).defined();

export default schema;
