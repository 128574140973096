import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../../types.d';
import { API_BASE_URL } from '../../../api';
import { BidPricingOption } from '../../../types/bid';

export const fetchAllBidPricingOptions = createAsyncThunk<
  {
    data: BidPricingOption[];
  },
  { bid: string; service: string },
  { extra: ThunkExtra }
>(
  `bid/pricingOption/fetchAll`,
  async ({ bid, service }, { extra: { axios } }) => {
    const response = await axios.get(
      `${API_BASE_URL}/bids/${bid}/services/${service}/options`
    );

    return response.data;
  }
);

export const fetchBidPricingOptionById = createAsyncThunk<
  { data: BidPricingOption },
  { bid: string; service: string; option: string },
  { extra: ThunkExtra }
>(
  `bid/pricingOption/fetchById`,
  async ({ bid, service, option }, { extra: { axios } }) => {
    const response = await axios.get(
      `${API_BASE_URL}/bids/${bid}/services/${service}/options/${option}`
    );

    return response.data;
  }
);
