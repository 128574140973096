import React from 'react';
import clsx from 'clsx';
import { formatISO, addDays } from 'date-fns';
import { dequal } from 'dequal/lite';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  Button,
  Link,
  Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  Form,
  FormRenderProps,
  FormSpy,
  FormSpyRenderProps,
} from 'react-final-form';
import { Mutator } from 'final-form';
import DarkFiberBasic from './Basic';
import DarkFiberAdvanced from './Advanced';
import validationSchema from './validation';
import { TECH_SPECS_API_KEYS } from '../../../../constants';
import { ServiceDarkFiber } from '../../../../types/services';
import { useValidationSchema } from '../../../../hooks/useValidationSchema';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 20,
    height: 20,
    float: 'left',
    transition: '.3s',
  },
  iconActive: {
    transform: 'rotate(180deg)',
  },
}));

const resetAdvanced: Mutator<
  ServiceDarkFiber & {
    external_service_name: string;
    external_service_id: string;
  }
> = (
  [
    name = 'attributes.advanced',
    value = getInitialValues().attributes.advanced,
  ],
  state,
  { changeValue }
) => {
  changeValue(state, name, () => value);
};

interface ServiceRequirementsDarkFiberProps {
  onDone: (values: any) => void;
  values?: ServiceDarkFiber;
  minRfsDate: Date;
}

const getInitialValues = (): ServiceDarkFiber => ({
  type: 'dark_fiber',
  ready_for_service: formatISO(addDays(new Date(), 30)),
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: null,
    cores: null,
    advanced: {
      interface_type: null,
      diversity: {
        [TECH_SPECS_API_KEYS.building_lead_in]: false,
        [TECH_SPECS_API_KEYS.street_geo]: false,
      },
      mttr: null,
    },
    documents: [],
  },
});

const ServiceRequirementsDarkFiber = ({
  onDone,
  values = getInitialValues(),
  minRfsDate,
}: ServiceRequirementsDarkFiberProps) => {
  const classes = useStyles();
  const [collapse, setCollapse] = React.useState(false);
  const validator = useValidationSchema(validationSchema);

  if (
    values.attributes.advanced &&
    'interface_type_single_mode' in values.attributes.advanced
  ) {
    const update = {
      attributes: {
        ...values.attributes,
        advanced: {
          ...values.attributes.advanced,
          interface_type:
            values.attributes.advanced?.interface_type_single_mode === true
              ? 'interface_type_single_mode'
              : null,
        },
      },
    };
    values = Object.assign({ ...values }, { ...update });
  }

  return (
    <Box my={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Dark Fiber
          </Typography>
        </Grid>

        <Grid item xs>
          <Form
            initialValues={values}
            keepDirtyOnReinitialize
            initialValuesEqual={(a, b) => dequal(a, b)}
            validate={validator}
            onSubmit={(values) => onDone(values)}
            subscription={{ pristine: true }}
            mutators={{ resetAdvanced }}
          >
            {({
              handleSubmit,
            }: FormRenderProps<
              ServiceDarkFiber & {
                external_service_name: string;
                external_service_id: string;
              }
            >) => (
              <form onSubmit={handleSubmit}>
                <DarkFiberBasic minRfsDate={minRfsDate} />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Link
                        onClick={() => {
                          setCollapse((prevState) => !prevState);
                        }}
                      >
                        <KeyboardArrowDownIcon
                          className={clsx(classes.icon, {
                            [classes.iconActive]: collapse,
                          })}
                        />
                        Advanced Options
                      </Link>
                    </Box>
                    <Collapse in={collapse}>
                      <DarkFiberAdvanced />
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mx="auto" mt={2} width={200}>
                      <FormSpy
                        subscription={{
                          submitting: true,
                          valid: true,
                        }}
                      >
                        {({ form, submitting, valid }: FormSpyRenderProps) => (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={form.submit}
                            fullWidth
                            disabled={submitting || !valid}
                          >
                            Done
                          </Button>
                        )}
                      </FormSpy>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceRequirementsDarkFiber;
