import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import { ProviderGatewayServiceProvider } from '../../types/provider-gateway';

const gatewayProviderAdapter =
  createEntityAdapter<ProviderGatewayServiceProvider>({
    selectId: (provider) => provider.id,
    sortComparer: (a, b) =>
      b.score - a.score ||
      b.pointsofpresence - a.pointsofpresence ||
      a.name.localeCompare(b.name),
  });
const gatewayProviders = createSlice({
  name: 'gateway-providers',
  initialState: gatewayProviderAdapter.getInitialState({
    meta: {
      queries: 0,
    },
  }),
  reducers: {
    startGatewayProviderQuery: (state) => {
      state.meta.queries = state.meta.queries + 1;
    },
    stopGatewayProviderQuery: (state) => {
      state.meta.queries = state.meta.queries - 1;
    },
    resetGatewayProviders: (state) => {
      gatewayProviderAdapter.removeAll(state);
    },
    addGatewayProvider: (state, action) => {
      const provider = state.entities[action.payload.id];

      if (!provider) {
        return gatewayProviderAdapter.addOne(state, action);
      }

      provider.products = { ...provider.products, ...action.payload.products };
      provider.score = provider.score + action.payload.score;

      return gatewayProviderAdapter.upsertOne(state, provider);
    },
    updateGatewayProvider: (state, action) => {
      const products = {
        id: action.payload.id,
        changes: { products: action.payload.products },
      };
      gatewayProviderAdapter.updateOne(state, products);
    },
  },
});

export const {
  selectById: selectGatewayServiceProviderById,
  selectAll: selectAllGatewayServiceProviders,
  selectTotal: selectGatewayServiceProvidersTotal,
  selectIds: selectAllServiceProviderIds,
} = gatewayProviderAdapter.getSelectors(
  (state: RootState) => state.gatewayProviders
);
export const selectProviderGatewayMeta = (state: RootState) =>
  state.gatewayProviders.meta;

export const {
  addGatewayProvider,
  updateGatewayProvider,
  startGatewayProviderQuery,
  stopGatewayProviderQuery,
  resetGatewayProviders,
} = gatewayProviders.actions;

export default gatewayProviders.reducer;
