import { styled } from 'twin.macro';
import Button from './Button';
import * as styles from './styles';

export interface TextButtonProps {
  variant?: 'default' | 'primary' | 'danger';
}

export const TextButton = styled(Button)<TextButtonProps>(
  ({ variant = 'default' }) => [
    styles.text.DEFAULT,
    variant === 'primary' && styles.text.primary,
    variant === 'danger' && styles.text.danger,
  ]
);
