import { createContext } from 'react';
import { noop } from '../../utilities/noop';
import { DropdownMenuContextType } from './types';

export const DropdownMenuContext = createContext<DropdownMenuContextType>({
  isOpen: false,
  openMenu: noop,
  closeMenu: noop,
  toggleMenu: noop,
});
