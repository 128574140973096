import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../api';
import { AxiosResponse } from 'axios';
import {
  Bid,
  BidService,
  BidPricingOption,
  BidStateUpdate,
} from '../../types/bid';
import { Listing } from '../../types/listing';
import { CompanyRelationship } from '../../types/provider';
import { AppThunk, ThunkExtra } from '../types.d';

export const createBid = createAsyncThunk<
  Pick<AxiosResponse<Bid>, 'data'>,
  { bid: Bid },
  { extra: ThunkExtra }
>(`bid/create`, async ({ bid }, { extra: { axios } }) => {
  const response = await axios.post(`${API_BASE_URL}/bids`, {
    data: bid,
  });
  return response.data;
});

export const createBidService = createAsyncThunk<
  Pick<AxiosResponse<BidService>, 'data'>,
  { bid: Bid; service: BidService },
  { extra: ThunkExtra }
>(`bid/service/create`, async ({ bid, service }, { extra: { axios } }) => {
  const response = await axios.post(`${API_BASE_URL}/bids/${bid.id}/services`, {
    data: service,
  });
  return response.data;
});

export const createBidPricingOption = createAsyncThunk<
  Pick<AxiosResponse<BidPricingOption>, 'data'>,
  { bid: Bid; service: BidService; pricing: BidPricingOption },
  { extra: ThunkExtra }
>(
  `bid/option/create`,
  async ({ bid, service, pricing }, { extra: { axios } }) => {
    const response = await axios.post(
      `${API_BASE_URL}/bids/${bid.id}/services/${service.id}/options`,
      { data: pricing }
    );
    return response.data;
  }
);

export const updateBidById = createAsyncThunk<
  {
    data: Bid;
  },
  BidStateUpdate,
  { extra: ThunkExtra }
>('bid/updateById', async (bid, { extra: { axios } }) => {
  const response = await axios.patch(`${API_BASE_URL}/bids/${bid.id!}`, {
    data: bid,
  });

  return response.data;
});

export const fetchBidsByListingId = createAsyncThunk<
  {
    data: Bid[];
    included: (Listing | CompanyRelationship)[];
  },
  string,
  { extra: ThunkExtra }
>('bid/fetchByListingId', async (id, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/listings/${id}/bids`, {
    params: {
      include: ['companyAccount', 'user', 'documents'].join(','),
    },
  });

  return response.data;
});

export const fetchBidById = createAsyncThunk<
  {
    data: Bid;
    included: (Listing | CompanyRelationship)[];
  },
  string,
  { extra: ThunkExtra }
>('bid/fetchById', async (id, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/bids/${id}`);

  return response.data;
});

export const lockBidById = createAsyncThunk<
  {
    data: Bid;
  },
  string,
  { extra: ThunkExtra }
>('bid/lockById', async (id, { extra: { axios } }) => {
  const response = await axios.patch(`${API_BASE_URL}/bids/${id}`, {
    data: {
      type: 'bid',
      id,
      attributes: {
        locked: true,
      },
    },
  });

  return response.data;
});

export const dispatchLockBid =
  (bid: Bid): AppThunk =>
  async (dispatch) => {
    return dispatch(lockBidById(bid.id!));
  };

export const dispatchPricingOption =
  (payload: {
    bid: Bid;
    service: BidService;
    pricing: BidPricingOption;
  }): AppThunk =>
  async (dispatch) => {
    return dispatch(createBidPricingOption(payload));
  };
