import * as React from 'react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  PhysicalAddress,
  Services,
  ServiceTypeKeys,
} from '../../../types/services';
import useProviderGateway from '../../../hooks/useProviderGateway';
import { PROVIDER_GATEWAY_API_URL } from '../../../api';
import {
  ProviderGatewayQuery,
  ProviderGatewayServiceProvider,
} from '../../../types/provider-gateway';
import {
  formatAddressForSearch,
  formatCategoryForSearch,
  sumScoreReducer,
} from '../../../utilities/marketplace-match';
import { AppDispatch } from '../../../store/types';
import { useDispatch } from 'react-redux';
import {
  addGatewayProvider,
  startGatewayProviderQuery,
  stopGatewayProviderQuery,
  resetGatewayProviders,
} from '../../../store/gateway-providers';
import { hasPoint } from '../../../utilities/services';

const ServiceProviderGatewayQuery = ({
  productKey,
  serviceType,
  pointA,
  pointZ,
  onStart,
  onComplete,
}: {
  productKey;
  serviceType: ServiceTypeKeys;
  pointA?: PhysicalAddress | null;
  pointZ?: PhysicalAddress | null;
  onStart;
  onComplete: (data: ProviderGatewayServiceProvider[]) => ReactNode | void;
}) => {
  const { sendJsonMessage, lastJsonMessage, readyState } = useProviderGateway(
    PROVIDER_GATEWAY_API_URL
  );
  const category = formatCategoryForSearch(serviceType);
  const aEnd = formatAddressForSearch(pointA);
  const zEnd = formatAddressForSearch(pointZ);
  const [providers, setProviders] = useState<ProviderGatewayServiceProvider[]>(
    []
  );
  const productQuery: ProviderGatewayQuery = useMemo(
    () => ({
      product: category,
      aEnd: aEnd,
      zEnd: zEnd,
      providers: [],
    }),
    [category, aEnd, zEnd]
  );
  useEffect(() => {
    if (readyState === 1 && lastJsonMessage === null) {
      onStart();
      sendJsonMessage(productQuery);
    }
  }, [readyState, sendJsonMessage, onStart, productQuery, lastJsonMessage]);

  useEffect(() => {
    if (
      lastJsonMessage?.subCode === 'SUCCESS' &&
      lastJsonMessage?.data?.serviceProvider
    ) {
      const aEndScore =
        lastJsonMessage?.data?.products?.aEnd?.reduce(sumScoreReducer, 0) ?? 0;
      const azEndScore =
        lastJsonMessage?.data?.products?.aEnd_to_zEnd?.reduce(
          sumScoreReducer,
          0
        ) ?? 0;
      const azEndPresence = lastJsonMessage?.data?.products?.zEnd
        ? Object.keys(lastJsonMessage?.data?.products?.aEnd_to_zEnd).length > 0
          ? 'onNet'
          : 'offNet'
        : lastJsonMessage?.data?.presence?.aEnd;
      const presence = {
        products: {
          [productKey]: {
            serviceType: serviceType,
            aEnd: lastJsonMessage?.data?.presence?.aEnd,
            zEnd: lastJsonMessage?.data?.presence?.zEnd,
            azEnd: azEndPresence,
          },
        },
      };
      const totalScore = lastJsonMessage?.data?.products?.zEnd
        ? Object.keys(lastJsonMessage?.data?.products?.aEnd_to_zEnd).length > 0
          ? azEndScore
          : 0
        : aEndScore;
      const serviceProvider: ProviderGatewayServiceProvider = {
        ...lastJsonMessage?.data?.serviceProvider,
        score: totalScore ?? 0,
        ...presence,
      };
      setProviders((providers) => [...providers, serviceProvider]);
    }
  }, [lastJsonMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lastJsonMessage?.subCode === 'COMPLETE') {
      onComplete(providers);
    }
  }, [lastJsonMessage?.subCode, providers, onComplete]);

  return null;
};

interface ServiceProviderGatewayProps {
  products?: Services[];
}

const ServiceProviderGateway: React.FC<ServiceProviderGatewayProps> =
  React.memo(({ products }) => {
    const dispatch: AppDispatch = useDispatch();
    const startQueryCallback = React.useCallback(() => {
      dispatch(startGatewayProviderQuery());
    }, [dispatch]);

    const onCompleteQueryCallback = React.useCallback(
      (providers) => {
        dispatch(stopGatewayProviderQuery());
        providers.forEach((provider) => {
          dispatch(addGatewayProvider(provider));
        });
      },
      [dispatch]
    );
    if (products?.length === 0) {
      dispatch(resetGatewayProviders());
    }
    return (
      <div>
        {products?.map((service, index) => {
          return (
            <ServiceProviderGatewayQuery
              key={`${service}-${index}`}
              productKey={index}
              serviceType={service.type}
              pointA={hasPoint(service) ? service.attributes.point_a : null}
              pointZ={hasPoint(service) ? service.attributes.point_z : null}
              onStart={startQueryCallback}
              onComplete={onCompleteQueryCallback}
            />
          );
        })}
      </div>
    );
  });

export default ServiceProviderGateway;
