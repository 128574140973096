import React from 'react';
import {
  Chip,
  Table,
  Theme,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Currency } from '../../types/currency';
import { QuotePricing } from '../../types/bid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      border: 'none',
      padding: '0 0 8px 0',
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    body: {
      fontWeight: theme.typography.fontWeightMedium,
      padding: '0 0 8px 0',
      borderBottom: 'none',
    },
    label: {
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    chip: {
      borderRadius: '8px',
      height: '24px',
    },
  })
);

const formatCost = ({ unit, value }: Currency) => {
  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: unit,
  }).format(value)} ${unit}`;
};

const BidPricingTable = ({ pricing = [] }: { pricing?: QuotePricing[] }) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'), {
    noSsr: true,
  });
  if (mobile) {
    return (
      <Grid container spacing={3}>
        {pricing.map((pricing, index) => (
          <>
            <Grid item xs={12}>
              <Chip
                label={`Pricing Option ${index + 1}`}
                color="primary"
                className={classes.chip}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Contract Term
              </Typography>
              <Typography component="div" className={classes.title}>
                {pricing.contractTerm?.value === 1
                  ? 'Monthly'
                  : `${pricing.contractTerm?.value} ${pricing.contractTerm?.unit}s`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Non-Recurring Cost
              </Typography>
              <Typography
                component="div"
                // title={attributes.point_z?.description}
                className={classes.title}
              >
                {formatCost(pricing.nonRecurringCost)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Monthly-Recurring Cost
              </Typography>
              <Typography component="div" className={classes.title}>
                {formatCost(pricing.recurringCost)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Total Contract Cost
              </Typography>
              <Typography component="div" className={classes.title}>
                {!isNaN(Number(pricing.contractTerm?.value)) &&
                  formatCost({
                    value:
                      Number(pricing.recurringCost?.value) *
                        Number(pricing?.contractTerm?.value) +
                      Number(pricing.nonRecurringCost?.value),
                    unit: pricing.recurringCost.unit,
                  })}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
    );
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.head}>Contract Term</TableCell>
          <TableCell className={classes.head}>Non-Recurring Cost</TableCell>
          <TableCell className={classes.head}>Monthly-Recurring Cost</TableCell>
          <TableCell className={classes.head}>Total Contract Cost</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pricing.map((pricing, index) => (
          <TableRow key={index}>
            <TableCell className={classes.body}>
              {pricing.contractTerm?.value === 1
                ? 'Monthly'
                : `${pricing.contractTerm?.value} ${pricing.contractTerm?.unit}s`}
            </TableCell>
            <TableCell className={classes.body}>
              {formatCost(pricing.nonRecurringCost)}
            </TableCell>
            <TableCell className={classes.body}>
              {formatCost(pricing.recurringCost)}
            </TableCell>
            <TableCell className={classes.body}>
              {!isNaN(Number(pricing.contractTerm?.value)) &&
                formatCost({
                  value:
                    Number(pricing.recurringCost?.value) *
                      Number(pricing?.contractTerm?.value) +
                    Number(pricing.nonRecurringCost?.value),
                  unit: pricing.recurringCost.unit,
                })}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BidPricingTable;
