import { makeValidator } from '../../makeValidator';
import { object, string } from 'yup';

export const newUserSchema = object({
  contact: object({
    first_name: string().trim().required().label('First Name'),
    last_name: string().trim().required().label('Last Name'),
    email: string().trim().email().required().label('Email'),
  }),
});

export default makeValidator(newUserSchema);
