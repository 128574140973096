import React, { ReactNode } from 'react';
import {
  Box,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import MarketplaceSwitch from '../../components/MarketplaceSwitch';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
    },
  })
);

const MarketplaceLayout: React.FC<{
  title: string;
  isRoot: boolean;
  navigationComponent: ReactNode;
}> = ({ children, title, isRoot, navigationComponent }) => {
  const isExtraSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <Container maxWidth={false} component="main" className={classes.container}>
      {isRoot && isSmall && (
        <Box mb={2}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <Grid container>
        {!isSmall || (isSmall && isRoot) ? (
          <Grid item xs={isRoot ? 12 : 'auto'}>
            <Box
              color={isRoot ? 'text.primary' : null}
              fontWeight={!isRoot ? 'fontWeightBold' : null}
              width={isExtraSmall ? '100%' : 320}
            >
              <MarketplaceSwitch />
              {navigationComponent}
            </Box>
          </Grid>
        ) : null}
        <Grid item xs zeroMinWidth={!isSmall}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default MarketplaceLayout;
