import React, { FunctionComponent, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { makeStyles, Theme, lighten } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { formatBytes } from '../../utils';

interface PillProps extends ChipProps {
  subLabel?: string;
  loaded?: number;
  element?: ReactNode;
}

const useStylesLoading = makeStyles((theme: Theme) => ({
  loadingContainer: {
    padding: theme.spacing(0, 0, 0, 1),
    display: 'flex',
  },
  loadingBar: {
    width: '100%',
    minWidth: '50px',
    height: '8px',
    borderRadius: '8px',
    margin: 'auto',
    background: lighten(theme.palette.grey[300], 0.1),
  },
  loadingBarAmount: {
    borderRadius: '8px',
    height: '8px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '10px 10px 10px 0',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: ({ subLabel }: PillProps) =>
      subLabel ? theme.spacing(0.5) : 0,
  },
  subLabel: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
  },
}));

const Pill: FunctionComponent<Omit<PillProps, 'css'>> = ({
  variant,
  subLabel,
  label,
  element,
  loaded,
  ...rest
}) => {
  const classes = useStyles({ variant, subLabel, ...rest });
  return (
    <Chip
      className={classes.root}
      variant={variant}
      deleteIcon={<CloseIcon />}
      {...rest}
      label={
        <div className={classes.labelContainer}>
          <Typography
            className={classes.label}
            variant="body2"
            color={loaded === 0 ? 'primary' : 'textPrimary'}
          >
            {label}
          </Typography>
          <Typography className={classes.subLabel} variant="body2">
            {subLabel}
          </Typography>
          {element}
        </div>
      }
    />
  );
};

interface FilePillProps extends PillProps {
  fileSize: number; //bytes
  loaded?: number;
}

export const FilePill: FunctionComponent<Omit<FilePillProps, 'css'>> = ({
  fileSize,
  loaded = 0,
  ...rest
}) => {
  const classes = useStylesLoading();

  return (
    <Pill
      {...rest}
      loaded={loaded}
      subLabel={`(${formatBytes(fileSize)})`}
      element={
        <Box className={classes.loadingContainer}>
          {loaded !== 0 && (
            <Box className={classes.loadingBar}>
              <Box
                className={classes.loadingBarAmount}
                width={loaded / fileSize}
              />
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default Pill;
