import React, {
  ReactNode,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import tw from 'twin.macro';
import { Tooltip } from './styles';

const sizes = {
  small: tw`max-w-xs`,
  normal: tw`max-w-sm`,
  large: tw`max-w-lg`,
};

export const ToolTip = ({
  children,
  label,
  variant,
  size = 'small',
  popper = {},
  ...props
}: PropsWithChildren<{
  label: ReactNode;
  variant?: 'dark' | 'light';
  size?: keyof typeof sizes;
  popper?: Config;
}>) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip(popper);
  return (
    <>
      {isValidElement(children) &&
        cloneElement(children, { ref: setTriggerRef })}
      {visible && (
        <Tooltip
          ref={setTooltipRef}
          {...getTooltipProps()}
          variant={variant}
          css={[sizes[size]]}
          {...props}
        >
          {label}
        </Tooltip>
      )}
    </>
  );
};
