import { useState, useCallback } from 'react';
import { noop } from 'lodash';
import ManageContactsMenu from '../ManageContactsMenu';
import ProviderContactForm from '../ProviderContactForm';
import { ProviderContact } from '../../../types/provider-contact';
import { CompanyProfileResponse } from '../../../types/directory-service/company-profile';
import {
  serviceProviderContactsAPI,
  useCreateServiceProviderContactMutation,
  useUpdateServiceProviderContactByIDMutation,
} from '../../../services/api/marketplace-service/service-provider-contacts';
import {
  useCreateServiceProviderContactMutation as useGQLCreateServiceProviderContactMutation,
  useUpdateServiceProviderContactByIDMutation as useGQLUpdateServiceProviderContactMutation,
} from '../../../services/api/marketplace-service/gql/service-provider-contacts';
import { useFlagsmithFeature } from '@cloudscene-dev/flagsmith-react';
import { FLAGSMITH_FEATURES } from '../../../utilities/flagsmith-config';
import { ProviderContact as GQLProviderContact } from '../../../types/marketplace-service/gql/service-provider-contact';
import { useAppDispatch } from '../../../store/hooks';

const AddServiceProviderContact = ({
  provider,
  contacts = [],
  value,
  onSelect = noop,
  onEdit = noop,
  onRemove = noop,
}: {
  provider: CompanyProfileResponse;
  contacts?: ProviderContact[];
  value?: ProviderContact;
  onSelect?: (value: ProviderContact | GQLProviderContact) => void;
  onEdit?: (value: ProviderContact | GQLProviderContact) => void;
  onRemove?: (value?: ProviderContact) => void;
}) => {
  const dispatch = useAppDispatch();
  const [contact, setContact] = useState<number | undefined>(undefined);
  const [createServiceProviderContactMutation] =
    useCreateServiceProviderContactMutation();
  const [updateServiceProviderContactMutation] =
    useUpdateServiceProviderContactByIDMutation();
  const [createGQLServiceProviderContactMutation] =
    useGQLCreateServiceProviderContactMutation();
  const [updateGQLServiceProviderContactMutation] =
    useGQLUpdateServiceProviderContactMutation();
  const useGraphQLPOC = useFlagsmithFeature(FLAGSMITH_FEATURES.GRAPHQL_POC);
  const handleDefaultContact = useCallback(
    (defaultContact: ProviderContact) => {
      contacts.forEach((contact) => {
        if (
          !!contact.attributes.default_contact &&
          contact.id !== defaultContact.id
        ) {
          updateServiceProviderContactMutation({
            id: contact.id,
            payload: {
              id: contact.id,
              type: 'provider-contact',
              attributes: {
                ...contact.attributes,
                default_contact: false,
              },
            },
          });
        }
      });
    },
    [contacts, updateServiceProviderContactMutation]
  );
  const handleGQLDefaultContact = useCallback(
    (defaultContact: GQLProviderContact) => {
      contacts.forEach((contact) => {
        if (
          !!contact.attributes.default_contact &&
          contact.id !== defaultContact.id
        ) {
          updateGQLServiceProviderContactMutation({
            payload: {
              id: contact.id,
              user_id: contact.attributes.user_id,
              provider_id: contact.attributes.provider_id,
              first_name: contact.attributes.first_name,
              last_name: contact.attributes.last_name,
              email: contact.attributes.email,
              default_contact: false,
            },
          });
        }
      });
    },
    [contacts, updateGQLServiceProviderContactMutation]
  );

  const onDoneGQL = useCallback(
    ({ contact }) => {
      if (contact.id) {
        updateGQLServiceProviderContactMutation({
          payload: {
            id: contact.id,
            user_id: contact.attributes.user_id,
            provider_id: contact.attributes.provider_id,
            first_name: contact.attributes.first_name,
            last_name: contact.attributes.last_name,
            email: contact.attributes.email,
            default_contact: Boolean(contact.attributes.default_contact),
          },
        })
          .unwrap()
          .then((response) => {
            onEdit(response.providerContact);
            setContact(undefined);
            return response.providerContact;
          })
          .then((contact) => {
            if (!!contact.default_contact) {
              handleGQLDefaultContact(contact);
            }
          })
          .then(() => {
            dispatch(
              serviceProviderContactsAPI.util.invalidateTags([
                { type: 'ServiceProviderContact', id: contact.id },
              ])
            );
          });
      }
      if (!contact.id) {
        createGQLServiceProviderContactMutation({
          payload: {
            user_id: contact.attributes.user_id,
            provider_id: contact.attributes.provider_id,
            first_name: contact.attributes.first_name,
            last_name: contact.attributes.last_name,
            email: contact.attributes.email,
            default_contact: contact.attributes.default_contact,
          },
        })
          .unwrap()
          .then((response) => {
            onSelect(response.providerContact);
            setContact(undefined);
            return response.providerContact;
          })
          .then((contact) => {
            if (!!contact.default_contact) {
              handleGQLDefaultContact(contact);
            }
          });
      }
    },
    [
      createGQLServiceProviderContactMutation,
      handleGQLDefaultContact,
      onEdit,
      onSelect,
      updateGQLServiceProviderContactMutation,
      dispatch,
    ]
  );
  const onDone = useCallback(
    ({ contact }) => {
      if (contact.id) {
        updateServiceProviderContactMutation({
          id: contact.id,
          payload: {
            id: contact.id,
            type: 'provider-contact',
            attributes: contact.attributes,
          },
        })
          .unwrap()
          .then((response) => {
            onEdit(response.entity);
            setContact(undefined);
            return response.entity;
          })
          .then((contact) => {
            if (!!contact.attributes.default_contact) {
              handleDefaultContact(contact);
            }
          });
      }
      if (!contact.id) {
        createServiceProviderContactMutation({
          payload: {
            id: contact?.id,
            type: 'provider-contact',
            attributes: contact.attributes,
          },
        })
          .unwrap()
          .then((response) => {
            onSelect(response.entity);
            setContact(undefined);
            return response.entity;
          })
          .then((contact) => {
            if (!!contact.attributes.default_contact) {
              handleDefaultContact(contact);
            }
          });
      }
    },
    [
      createServiceProviderContactMutation,
      handleDefaultContact,
      onEdit,
      onSelect,
      updateServiceProviderContactMutation,
    ]
  );

  return (
    <>
      <ManageContactsMenu
        contacts={contacts}
        onSelect={onSelect}
        onAdd={() => {
          setContact(-1);
        }}
        onEdit={
          value
            ? () => {
                setContact(
                  contacts.findIndex((contact) => contact.id === value?.id)
                );
              }
            : undefined
        }
        onRemove={
          value
            ? () => {
                onRemove(value);
              }
            : undefined
        }
      />
      <ProviderContactForm
        open={contact !== undefined}
        onClose={() => setContact(undefined)}
        provider={provider}
        contact={contact !== undefined ? contacts[contact] : undefined}
        onDone={useGraphQLPOC ? onDoneGQL : onDone}
      />
    </>
  );
};

export default AddServiceProviderContact;
