import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { intervalToDuration } from 'date-fns';
import clsx from 'clsx';
import { listingHasEnded } from '../../utilities/listings';
import { ListingState } from '../../types/listing';
import { Tooltip } from '@material-ui/core';
import { getFullReadable, getReadableDuration } from '../../utilities/time';

interface ListingExpiryProps {
  end: Date;
  state: ListingState;
  hidePrefix?: boolean;
}

const useTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
    base: {
      color: theme.palette.grey[500],
    },
    alert: {
      color: theme.palette.error.main,
    },
    safe: {
      color: theme.palette.success.main,
    },
  })
);

export const ListingExpiry: FunctionComponent<ListingExpiryProps> = ({
  end,
  hidePrefix = false,
  state,
}) => {
  const textClasses = useTextStyles();
  const isEnded = listingHasEnded(state, end);
  const { days } = intervalToDuration({
    start: new Date(),
    end,
  });
  const formatted = getReadableDuration(new Date(), end);
  const toolTipTitle = `${!isEnded ? 'Expires ' : ''} ${getFullReadable(end)}`;

  return (
    <Tooltip title={toolTipTitle} placement="bottom-start">
      <Typography
        component="div"
        className={clsx(textClasses.root, {
          [textClasses.safe]: days && !isEnded,
          [textClasses.alert]: !days && !isEnded,
          [textClasses.base]: isEnded,
        })}
      >
        {isEnded ? 'Ended' : `${!hidePrefix ? `Expires in ` : ''}${formatted}`}
      </Typography>
    </Tooltip>
  );
};
