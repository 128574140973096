import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk, ThunkExtra } from '../types.d';
import { API_BASE_URL } from '../../api';
import {
  ProviderRelationship,
  ProviderEntity,
  ListingProvider,
} from '../../types/provider';

export const createListingProvider = createAsyncThunk<
  {
    data: ProviderEntity;
    included: ProviderRelationship[];
  },
  { listing: string; provider: ListingProvider },
  { extra: ThunkExtra }
>(
  `listing-providers/create`,
  async ({ listing, provider }, { extra: { axios } }) => {
    const response = await axios.post(
      `${API_BASE_URL}/listings/${listing}/providers`,
      {
        data: provider,
      }
    );

    return response.data;
  }
);

export const fetchAllListingProviders = createAsyncThunk<
  {
    data: ProviderEntity[];
    included: ProviderRelationship[];
  },
  string,
  { extra: ThunkExtra }
>(`listing-providers/fetchAll`, async (listing, { extra: { axios } }) => {
  const response = await axios.get(
    `${API_BASE_URL}/listings/${listing}/providers`
  );

  return response.data;
});

export const fetchListingProviderById = createAsyncThunk<
  { data: ProviderEntity; included: ProviderRelationship[] },
  { listing: string; provider: string },
  { extra: ThunkExtra }
>(
  `listing-providers/fetchById`,
  async ({ listing, provider }, { extra: { axios } }) => {
    const response = await axios.get(
      `${API_BASE_URL}/listings/${listing}/providers/${provider}`
    );

    return response.data;
  }
);

export const deleteListingProviderById = createAsyncThunk<
  { id: string },
  { listing: string; provider: string },
  { extra: ThunkExtra }
>(
  `listing-providers/deleteById`,
  async ({ listing, provider }, { extra: { axios } }) => {
    await axios.delete(
      `${API_BASE_URL}/listings/${listing}/providers/${provider}`
    );

    return { id: provider };
  }
);

export const dispatchListingProvider =
  (
    action: string,
    payload: {
      listing: string;
      provider: ListingProvider;
    }
  ): AppThunk =>
  async (dispatch) => {
    if (action === 'add') return dispatch(createListingProvider(payload));
    if (action === 'delete')
      return dispatch(
        deleteListingProviderById({
          listing: payload.listing,
          provider: payload.provider.id!,
        })
      );
  };
