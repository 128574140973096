import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';
import { listingsAPI } from '../../services/api/marketplace-service/listings';

const opportunitiesAdapter = createEntityAdapter<ListingProviderResponse>({
  selectId: (entity) => entity.id,
});

const opportunities = createSlice({
  name: 'opportunities',
  initialState: opportunitiesAdapter.getInitialState(),
  reducers: {
    reset: (state) => {
      opportunitiesAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingsByCompanyID.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context['listing-provider']) return state;

        const providers = Object.values(
          action.payload.context['listing-provider'].entities
        );

        opportunitiesAdapter.upsertMany(state, providers);
      }
    );
  },
});

export const { reset: resetOpportunities } = opportunities.actions;

export const {
  selectById: selectOpportunityById,
  selectIds: selectOpportunityIds,
  selectEntities: selectOpportunityEntities,
  selectAll: selectAllOpportunities,
} = opportunitiesAdapter.getSelectors(
  (state: RootState) => state.opportunities
);

export default opportunities.reducer;
