import { createTheme, Theme, lighten } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { ToggleButtonClassKey } from '@material-ui/lab/ToggleButton';
import { ToggleButtonGroupClassKey } from '@material-ui/lab/ToggleButtonGroup';
import createSpacing from '@material-ui/core/styles/createSpacing';
import grey from '@material-ui/core/colors/grey';
import { shadows } from './utils';

interface Overrides extends CoreOverrides {
  MuiToggleButton?: Partial<
    Record<ToggleButtonClassKey, CSSProperties | (() => CSSProperties)>
  >;
  MuiToggleButtonGroup?: Partial<
    Record<ToggleButtonGroupClassKey, CSSProperties | (() => CSSProperties)>
  >;
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    custom?: {};
  }

  interface ThemeOptions {
    custom?: {};
  }
}

const utilTheme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
  },
});

const spacing = createSpacing(8);

const colorPrimaryMain = '#2EA0F9';
const secondaryMainColor = '#fff';

const overrides: Overrides = {
  MuiChip: {
    root: {
      height: 32,
      backgroundColor: grey[200],
      borderRadius: 16,
    },
    label: {
      fontSize: 14,
    },
    outlined: {
      height: 24,
      border: `2px solid ${grey[300]}`,
      borderRadius: 4,
    },
    deletable: {
      height: 32,
      backgroundColor: grey[200],
      borderRadius: 16,
    },
  },
  MuiLink: {
    root: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: utilTheme.palette.primary.main,
      transition: utilTheme.transitions.create('all'),
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: utilTheme.palette.common.white,
      borderRadius: 6,
    },
    input: {
      padding: spacing(1.5, 14 / spacing(1)),
      borderWidth: 2,
      borderColor: grey[300],
    },
    inputMultiline: {
      padding: spacing(0, 0.5),
      fontSize: 14,
      '&::placeholder': {
        fontSize: 14,
        color: grey[700],
      },
    },
    notchedOutline: {
      borderWidth: 2,
      borderColor: grey[300],
    },
  },
  MuiContainer: {
    maxWidthXl: {
      [utilTheme.breakpoints.up('lg')]: {
        maxWidth: 1600,
      },
      [utilTheme.breakpoints.up('xl')]: {
        maxWidth: 1600,
      },
    },
  },
  MuiButton: {
    root: {
      fontSize: 14,
      borderRadius: 6,
      textTransform: 'none',
      '&$disabled': {
        color: '#A9A9A9',
      },
    },
    contained: {
      backgroundColor: secondaryMainColor,
      '&$disabled': {
        backgroundColor: lighten(colorPrimaryMain, 0.5),
        color: lighten(secondaryMainColor, 0.5),
      },
      boxShadow:
        '0 3px 1px -4px rgba(150, 150, 150, 0.15), 0px 1px 8px 1px rgba(150,150,150,0.15), 0px 1px 5px 0px rgba(150,150,150,0.12)',
      '&:hover': {
        backgroundColor: secondaryMainColor,
        boxShadow:
          '1px -1px 3px -1px rgba(150,150,150,0.2), 0px 4px 5px 0px rgba(150,150,150,0.14), 0px 1px 10px 6px rgba(150,150,150,0.12)',
      },
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: secondaryMainColor,
      },
    },
    textSecondary: {
      color: grey[600],
    },
    outlined: {
      '&$disabled': {
        backgroundColor: '#F4F4F4',
        border: '2px solid #DCDCDC',
      },
      borderWidth: '2px',
      '&:hover': {
        borderWidth: '2px',
      },
    },
    outlinedPrimary: {
      borderWidth: '2px',
      '&:hover': {
        borderWidth: '2px',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      '&$error': {
        borderColor: utilTheme.palette.error.main,

        '&$focused': {
          borderColor: utilTheme.palette.error.main,
        },
      },
    },
  },
  MuiToolbar: {
    regular: {
      margin: 0,
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#1F1C4A',
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(255, 255, 255, 0.42)',
    },
  },
  MuiDialogTitle: {
    root: {
      [utilTheme.breakpoints.down('sm')]: {
        padding: spacing(3, 2.5),
      },
      padding: spacing(2.5, 5),
    },
  },
  MuiDialogContent: {
    root: {
      [utilTheme.breakpoints.down('sm')]: {
        padding: spacing(2),
      },
      padding: spacing(2.5, 5),
    },
  },
  MuiDialog: {
    paper: {
      borderRadius: 20,
      [utilTheme.breakpoints.down('sm')]: {
        padding: spacing(2, 0),
        minWidth: '100%',
        marginLeft: 0,
        marginRight: 0,
      },
      padding: spacing(3, 0),
      border: `1px solid ${grey[200]}`,
      boxShadow: `0 0 32px -12px ${grey[500]}`,
    },
  },
  MuiExpansionPanel: {
    root: {
      margin: 0,
      padding: 0,
      boxShadow: 'none',
      backgroundColor: 'white',
      '&:before': {
        backgroundColor: 'none',
      },
      '&$expanded': {
        margin: 0,
      },
    },
  },
  MuiExpansionPanelSummary: {
    root: {
      '&$expanded': {
        height: 'auto',
      },
      padding: 0,
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      transition: 'none',
      padding: 20,
    },
  },
  MuiExpansionPanelActions: {
    root: {
      justifyContent: 'flex-start',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  MuiInputBase: {
    input: {
      '&::placeholder': {
        color: grey[500],
        fontSize: '1rem',
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: utilTheme.typography.fontWeightBold,
      color: grey[700],
      '&$focused': {
        color: colorPrimaryMain,
      },

      '&$shrink': {
        color: grey[900],
        fontWeight: 500,
        fontSize: '1rem',

        '&$focused': {
          color: colorPrimaryMain,
        },
      },

      '&$error': {
        color: utilTheme.palette.error.main,

        '&$focused': {
          '&$shrink': {
            color: utilTheme.palette.error.dark,
          },
        },
      },
    },
  },
  MuiSelect: {
    select: {
      fontSize: 14,
    },
  },
  MuiFormGroup: {
    row: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',

      '& .MuiFormControlLabel-root': {
        minWidth: `calc(100% - ${utilTheme.spacing(2)}px)`,

        [utilTheme.breakpoints.up('sm')]: {
          minWidth: `calc(50% - ${utilTheme.spacing(2)}px)`,
        },

        [utilTheme.breakpoints.up('md')]: {
          minWidth: `calc(25% - ${utilTheme.spacing(2)}px)`,
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: grey.A400,
      '&$focused': {
        color: grey.A400,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      color: grey[500],
    },
    label: {
      fontSize: 14,
    },
  },
  MuiRadio: {
    colorSecondary: {
      color: secondaryMainColor,
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: 14,
    },
  },
  MuiTabs: {
    indicator: {
      height: 3,
    },
  },
  MuiTab: {
    root: {
      fontSize: 14,
      padding: spacing(0, 1),
      margin: spacing(0, 1),
      textTransform: 'unset',
      [utilTheme.breakpoints.up('xs')]: {
        minWidth: 80,
        maxWidth: 140,
      },
      [utilTheme.breakpoints.up('sm')]: {
        minWidth: 80,
        maxWidth: 140,
      },
      [utilTheme.breakpoints.up('md')]: {
        minWidth: 80,
        maxWidth: 140,
      },
    },
  },
  MuiToggleButton: {
    root: {
      borderRadius: '5px',
      textTransform: 'none',
      borderWidth: '2px',
      lineHeight: 1.4,
      '&:not(:last-child)': {
        borderRadius: '5px',
      },
      '&:not(:first-child)': {
        borderRadius: '5px',
        borderLeft: `2px solid rgba(0, 0, 0, 0.12)`,
        marginLeft: 0,
        '&$selected': {
          borderColor: colorPrimaryMain,
        },
      },
      '&$selected': {
        backgroundColor: 'white',
        borderColor: colorPrimaryMain,
      },
      '&$selected + &$selected': {
        // unfortunately material ui isn't respecting this override so I have to use important
        borderLeft: `2px solid ${colorPrimaryMain}!important`,
      },
    },
  },
  MuiToggleButtonGroup: {
    grouped: {
      '&:not(:last-child)': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      '&:not(:first-child)': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderLeft: `2px solid rgba(0, 0, 0, 0.12)`,
        marginLeft: 0,
      },
    },
  },
};

// create type sensitive theme
const theme: Theme = createTheme({
  breakpoints: {
    values: {
      // These are all the standard material breakpoints except for XL
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: colorPrimaryMain,
      contrastText: '#ffffff',
    },
    secondary: {
      main: secondaryMainColor,
      contrastText: colorPrimaryMain,
    },
    info: {
      light: '#6ad7fa',
      main: '#009eff',
      dark: '#3e5291',
    },
    error: {
      main: '#f60e5a',
    },
    warning: {
      main: '#ffab5e',
    },
    success: {
      main: '#44da73',
    },
    text: {
      primary: grey.A400,
    },
    background: {
      default: '#fff',
    },
    action: {
      disabledBackground: '#F4F4F4',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: [
      '"DM Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontSize: '1rem',
      color: grey[800],
    },
    body2: {
      fontSize: '0.875rem',
      color: grey[500],
    },
    subtitle1: {
      // used as title on admin pages
      fontSize: '1.1rem',
      color: grey[800],
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.9rem',
      color: grey[500],
      fontWeight: 400,
    },
    button: {
      fontSize: '1rem',
      color: grey[800],
    },
    h1: {
      fontSize: '1.75rem',
      color: grey[800],
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 600,
      color: grey[800],
    },
    h3: {
      fontSize: '1.45rem',
      fontWeight: 600,
      color: grey[800],
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 500,
      color: grey[800],

      [utilTheme.breakpoints.up('sm')]: {},
    },
    h5: {
      fontSize: '1.15rem',
      fontWeight: 600,
      color: grey[800],
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 600,
      color: grey[600],
    },
  },
  spacing,
  shadows,
  props: {
    MuiDialog: {},
    MuiTab: {
      disableRipple: true,
    },
  },
  overrides,
});

export default theme;
