import { createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { AppThunk, ThunkExtra } from '../types.d';
import { API_BASE_URL } from '../../api';
import {
  ProviderContact,
  ProviderContactEntity,
} from '../../types/provider-contact';

export const createProviderContact = createAsyncThunk<
  {
    data: ProviderContact;
  },
  ProviderContact,
  { extra: ThunkExtra }
>(`provider-contact/create`, async (contact, { extra: { axios } }) => {
  const response = await axios.post(`${API_BASE_URL}/provider-contacts`, {
    data: contact,
  });

  return response.data;
});

export const updateProviderContactById = createAsyncThunk<
  {
    data: ProviderContact;
  },
  ProviderContact,
  { extra: ThunkExtra }
>(`provider-contact/updateById`, async (contact, { extra: { axios } }) => {
  const response = await axios.patch(
    `${API_BASE_URL}/provider-contacts/${contact.id}`,
    {
      data: contact,
    }
  );

  return response.data;
});

export const fetchAllProviderContactsByUser = createAsyncThunk<
  {
    data: ProviderContact[];
  },
  string,
  { extra: ThunkExtra }
>(`provider-contact/fetchAllByUser`, async (id, { extra: { axios } }) => {
  const response = await axios.get<{ data: ProviderContact[] }>(
    `${API_BASE_URL}/provider-contacts`,
    {
      params: {
        'filter[user]': id,
      },
    }
  );

  return response.data;
});

export const fetchProviderContactById = createAsyncThunk<
  { data: ProviderContactEntity; included: ProviderContact[] },
  string,
  { extra: ThunkExtra }
>(`provider-contact/fetchById`, async (id, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/provider-contacts/${id}`);

  return response.data;
});

export const deleteProviderContactById = createAsyncThunk<
  { id: string },
  string,
  { extra: ThunkExtra }
>(`provider-contact/delete`, async (id, { extra: { axios } }) => {
  await axios.delete(`${API_BASE_URL}/provider-contacts/${id}`);

  return { id };
});

export const dispatchDefaultContact =
  (contact: ProviderContact, isDefault: boolean): AppThunk =>
  async (dispatch) => {
    const data = merge({}, contact, {
      attributes: { default_contact: isDefault },
    });
    return dispatch(updateProviderContactById(data));
  };
