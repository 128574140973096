import { format, formatDuration, intervalToDuration, Duration } from 'date-fns';

export function getFullReadable(date: Date): string {
  return `${format(date, 'do MMM yyyy')} (${format(date, 'h:mm a')}
        ${Intl.DateTimeFormat().resolvedOptions().timeZone}
        ${format(date, 'zzzz')})`;
}

const unitFormats: { key: string; replace: string }[] = [
  { key: 'year', replace: 'y' },
  { key: 'month', replace: 'mth' },
  { key: 'day', replace: 'd' },
  { key: 'hour', replace: 'h' },
  { key: 'minute', replace: 'm' },
  { key: 'second', replace: 'sec' },
];

export const formatOfDuration = (duration: Duration) => {
  const format: string[] = [];
  Object.entries(duration).some(([unit, amount]) => {
    if (!format.length) {
      if (amount) {
        format.push(unit);
      }
      return false;
    } else {
      if (amount) {
        format.push(unit);
      }
      return true;
    }
  });
  return format;
};

/*
  Custom formatter because the date-fns functions aren't quite right:
  formatDuration returns '1 year 5 days'
  formatDistanceStrict returns '1 year'

  We want '1y' but also '1y 1mth' if enough days in the new year have passed
*/

export const getReadableDuration = (start: Date, end: Date): string => {
  const duration = intervalToDuration({
    start,
    end,
  });
  const format: string[] = formatOfDuration(duration);

  return unitFormats.reduce((acc, curr) => {
    return acc
      .replace(` ${curr.key}s`, curr.replace)
      .replace(` ${curr.key}`, curr.replace);
  }, formatDuration(duration, { format }));
};
