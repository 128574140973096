import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ProviderContact } from '../../types/provider-contact';
import {
  createProviderContact,
  updateProviderContactById,
  fetchAllProviderContactsByUser,
} from './actions';
import { RootState } from '../types.d';
import { listingsAPI } from '../../services/api/marketplace-service/listings';
import { serviceProviderContactsAPI } from '../../services/api/marketplace-service/service-provider-contacts';
const providerContactsAdapter = createEntityAdapter<ProviderContact>({
  selectId: (provider) => provider.id,
});

const providers = createSlice({
  name: 'providers',
  initialState: providerContactsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProviderContact.fulfilled, (state, action) => {
      providerContactsAdapter.upsertOne(state, action.payload.data);
    });

    builder.addCase(updateProviderContactById.fulfilled, (state, action) => {
      providerContactsAdapter.upsertOne(state, action.payload.data);
    });

    builder.addCase(fetchAllProviderContactsByUser.pending, (state) => {
      providerContactsAdapter.removeAll(state);
    });

    builder.addCase(
      fetchAllProviderContactsByUser.fulfilled,
      (state, action) => {
        providerContactsAdapter.upsertMany(state, action.payload.data);
      }
    );

    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context['provider-contact']) return state;

        const providerContacts = Object.values(
          action.payload.context['provider-contact'].entities
        );
        providerContactsAdapter.upsertMany(state, providerContacts);
      }
    );

    builder.addMatcher(
      isAnyOf(
        serviceProviderContactsAPI.endpoints
          .readAllServiceProviderContactsByUserID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.entities) return state;

        const providerContacts = Object.values(action.payload.entities);
        providerContactsAdapter.upsertMany(state, providerContacts);
      }
    );
  },
});

export const {
  selectById: selectProviderContactById,
  selectIds: selectProviderContactIds,
  selectEntities: selectProviderContactEntities,
  selectAll: selectAllProviderContacts,
  selectTotal: selectTotalProviderContacts,
} = providerContactsAdapter.getSelectors(
  (state: RootState) => state.providerContacts
);

export default providers.reducer;
