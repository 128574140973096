import { Grid, makeStyles, createStyles } from '@material-ui/core';
import GatewayProgressBar from '../GatewayProgressBar';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';

interface LoadingProps {
  gatewayProvidersTotalCount: number;
  gatewayProvidersState: boolean;
  isLoading: boolean;
  onRefresh: () => void;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    gateway: {
      borderRadius: theme.spacing(4),
      padding: `${theme.spacing(2, 4)}!important`,
      [theme.breakpoints.only('xs')]: {
        width: '90%',
        margin: '0 auto',
      },
    },
    gatewayFixed: {
      boxShadow: theme.shadows[4],
      position: 'absolute',
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
    },
  })
);
export const Loading: React.FC<LoadingProps> = ({
  gatewayProvidersTotalCount,
  gatewayProvidersState,
  isLoading,
  onRefresh,
}) => {
  const classes = useStyles();
  const { ref, inView } = useInView();
  return (
    <div ref={ref}>
      <Grid container spacing={2} justifyContent="center">
        <div
          className={clsx(classes.gateway, {
            [classes.gatewayFixed]: !inView,
          })}
        >
          <GatewayProgressBar
            isLoading={isLoading}
            gatewayProvidersCount={gatewayProvidersTotalCount}
            gatewayProvidersState={gatewayProvidersState}
            onRefresh={onRefresh}
          />
        </div>
      </Grid>
    </div>
  );
};
