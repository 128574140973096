import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
  DialogContent,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { FormSpy } from 'react-final-form';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import { useReadCompanyProfileByIDQuery } from '../../services/api/directory-service/company-profiles';

interface SubmitSectionProps {
  providerId: string;
  onSubmit: (invite_providers: boolean) => void;
  disabled: boolean;
  submitting: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      boxShadow: theme.shadows[4],
      border: '1px solid transparent',
      borderRadius: '8px',
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
    },
    buttonLabel: {
      padding: theme.spacing(1, 4, 1, 2),
      float: 'right',
    },
    icon: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    logo: {
      display: 'block',
      maxWidth: 100,
      margin: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
    },
    close: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    name: { fontWeight: 'bold' },
    text: {
      margin: theme.spacing(4, 0),
    },
  })
);

const SubmitSection: React.FC<SubmitSectionProps> = ({
  providerId,
  onSubmit,
  disabled,
  submitting,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [addMoreProviders, setAddMoreProviders] = useState(false);
  const [noMoreProviders, setNoMoreProviders] = useState(false);
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'), {
    noSsr: true,
  });
  const { data } = useReadCompanyProfileByIDQuery({
    id: providerId,
  });
  if (!data) return null;
  const provider = data?.entity;

  return (
    <>
      <Grid
        container
        className={classes.submit}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={12} sm={6} direction="row" alignItems="center">
          {provider?.attributes.logo && (
            <Grid item xs="auto">
              <img
                src={provider.attributes.logo}
                alt={provider?.attributes.name}
                className={classes.logo}
              />
            </Grid>
          )}
          <Grid item xs="auto">
            <Typography className={classes.name}>
              {provider?.attributes.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth={mobile}
            className={classes.buttonLabel}
            onClick={() => setOpen(true)}
            disabled={disabled}
            endIcon={<ChevronRightIcon className={classes.icon} />}
          >
            Request a quote
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={1}>
              <HelpIcon color="primary" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" component="div" gutterBottom>
                Would you like Cloudscene to invite other providers to quote
                your requirements?
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.text}
          >
            If so, let us know and we'll do the work! Once your listing is
            published, our team of experts will invite other vendors that may be
            able to fulfill your service requirements.
          </Typography>
          <Grid item xs={12} container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormSpy>
                {({ form }) => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                    fullWidth
                    endIcon={
                      addMoreProviders &&
                      submitting && (
                        <CircularProgress color="secondary" size={20} />
                      )
                    }
                    onClick={() => {
                      setAddMoreProviders(true);
                      onSubmit(true);
                    }}
                  >
                    Yes, I would like multiple quotes
                  </Button>
                )}
              </FormSpy>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                disabled={disabled}
                fullWidth
                endIcon={
                  noMoreProviders &&
                  submitting && <CircularProgress color="secondary" size={20} />
                }
                onClick={() => {
                  setNoMoreProviders(true);
                  onSubmit(false);
                }}
              >
                I am only interested in {provider?.attributes.name}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubmitSection;
