import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { ServiceTypeKeys } from '../../../types/services';
import ListingFilters from '../../ListingFilters';
import MarketFilters from '../../ListingFilters/Markets';
import ServiceTypeFilters from '../../ListingFilters/ServiceTypes';
import LoadingSpinner from '../../LoadingSpinner';
import { useReadAvailableMarketsQuery } from '../../../services/api/marketplace-service/listings';

export interface BrowseListingFiltersProps {
  publicTenders?: boolean;
  setPublicTenders: (publicTenders: boolean) => void;
  selectedServiceTypes: ServiceTypeKeys[];
  setSelectedServiceTypes: (serviceTypes: ServiceTypeKeys[]) => void;
  selectedMarkets: string[];
  setSelectedMarkets: (selectedMarkets: string[]) => void;
}

const useStyles = makeStyles((theme) => ({
  filterTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
}));

const BrowseListingFilters: React.FC<BrowseListingFiltersProps> = ({
  publicTenders,
  setPublicTenders,
  selectedServiceTypes,
  setSelectedServiceTypes,
  selectedMarkets,
  setSelectedMarkets,
}) => {
  const classes = useStyles();
  const { data, isFetching } = useReadAvailableMarketsQuery({
    params: { sortBy: '-start' },
  });
  return (
    <>
      <ListingFilters
        publicTenders={publicTenders}
        setPublicTenders={setPublicTenders}
      />
      <Box mb={3}>
        <Typography gutterBottom className={classes.filterTitle}>
          Services
        </Typography>
        <ServiceTypeFilters
          selectedServiceTypes={selectedServiceTypes}
          setSelectedServiceTypes={setSelectedServiceTypes}
        />
      </Box>
      <Typography gutterBottom className={classes.filterTitle}>
        Markets
      </Typography>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <MarketFilters
          regionMarkets={data?.entities}
          selectedMarkets={selectedMarkets}
          setSelectedMarkets={setSelectedMarkets}
        />
      )}
    </>
  );
};

export default BrowseListingFilters;
