import tw from 'twin.macro';
import React, { useState, useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, InputAdornment } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '../Form/CSTextField';
import { UserResponse } from '../../types/account-service/users';
import { useSelector } from 'react-redux';
import { selectAllUsers, selectUserContext } from '../../store/users';
import { getRelationshipContext } from '../../utilities/relationships';
import { Pill } from './Pill';

interface TeamMemberSearchProps {
  label?: string;
  users?: UserResponse[];
  assignListingOwner?: boolean;
  onChange: (user: UserResponse | null) => void;
}

const sortUsers = (a: UserResponse, b: UserResponse) => {
  const firstNameA = a.attributes.firstName ?? '';
  const firstNameB = b.attributes.firstName ?? '';
  const lastNameA = a.attributes.lastName ?? '';
  const lastNameB = b.attributes.lastName ?? '';

  return (
    firstNameA.localeCompare(firstNameB) ||
    firstNameA.localeCompare(firstNameB) ||
    lastNameA.localeCompare(lastNameB)
  );
};

const useAutocompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0.5, 0),
      border: `2px solid ${theme.palette.grey[100]}`,
      borderRadius: 6,
    },
    endAdornment: {
      top: 'calc(50% - 16px)',
      right: theme.spacing(1),
    },
  })
);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    jobTitle: {
      color: theme.palette.grey[500],
    },
    option: {
      display: 'flex',
    },
  })
);

const TeamMemberSearch: React.FC<TeamMemberSearchProps> = ({
  label,
  assignListingOwner = true,
  onChange,
}) => {
  const users = useSelector(selectAllUsers);
  const userContext = useSelector(selectUserContext);
  const autocompleteClasses = useAutocompleteStyles();
  const classes = useStyles();
  const options = useMemo(() => users.sort(sortUsers), [users]);
  const [inputValue, setValue] = useState<UserResponse | null>(null);

  return (
    <Autocomplete
      value={inputValue}
      onChange={(_, value) => {
        if (typeof value === 'string') return;

        setValue(value);
        onChange(value);
      }}
      classes={autocompleteClasses}
      getOptionLabel={({ attributes }) =>
        `${attributes.firstName} ${attributes.lastName} - ${attributes.position}`
      }
      options={options}
      getOptionDisabled={
        assignListingOwner
          ? undefined
          : (user) => {
              const roles = getRelationshipContext(
                userContext.roles,
                user.relationships.roles.data
              );

              const pro = roles
                ?.map((role) => role.attributes.name)
                .includes('MP_Sales_Pro');

              const manager = roles
                ?.map((role) => role.attributes.name)
                .includes('MP_Sales_Manager');

              return !pro && !manager;
            }
      }
      renderOption={(user) => {
        const roles = getRelationshipContext(
          userContext.roles,
          user.relationships.roles.data
        );

        const pro = roles
          ?.map((role) => role.attributes.name)
          .includes('MP_Sales_Pro');

        const manager = roles
          ?.map((role) => role.attributes.name)
          .includes('MP_Sales_Manager');

        return (
          <div className={classes.option}>
            <Typography component="span">
              {user.attributes.firstName} {user.attributes.lastName} -{' '}
              <Typography className={classes.jobTitle} component="span">
                {user.attributes.position}{' '}
                <div css={tw`flex gap-2`}>
                  {manager && <Pill variant="manager">Sales Manager</Pill>}
                  {pro && <Pill variant="pro">Sales Pro</Pill>}
                </div>
              </Typography>
            </Typography>
          </div>
        );
      }}
      renderInput={({
        id,
        disabled,
        InputProps: { ref, className, ...InputProps },
        inputProps,
      }) => (
        <TextField
          label={label}
          id={id}
          disabled={disabled}
          inputProps={inputProps}
          innerRef={ref}
          fullWidth
          {...InputProps}
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded fontSize="small" />
            </InputAdornment>
          }
        />
      )}
    />
  );
};

export default TeamMemberSearch;
