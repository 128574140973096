import tw from 'twin.macro';

export const base = [tw`cursor-pointer`];
export const primary = [
  tw`font-brand text-primary-500 hover:text-primary-700 focus:text-primary-700`,
];

export const secondary = [tw`text-gray-700 hover:text-black focus:text-black`];

export const ghost = [
  tw`text-gray-400 hover:text-gray-600 focus:text-gray-600`,
];
