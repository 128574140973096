import React from 'react';
import { addDays, formatISO } from 'date-fns';
import ServiceRequirementsEthernet from './Ethernet';
import ServiceRequirementsColocation from './Colocation';
import ServiceRequirementsDarkFiber from './DarkFiber';
import ServiceRequirementsWavelength from './Wavelength';
import ServiceRequirementsIPTransit from './IPTransit';
import ServiceRequirementsInternetAccess from './InternetAccess';
import { Services, ServiceTypes, ServiceTypeKeys } from '../../types/services';

const ServiceRequirements: React.FC<{
  type: ServiceTypeKeys;
  service?: Services;
  name: string;
}> = ({ type, name }) => {
  const minRfsDate = new Date(formatISO(addDays(new Date(), 1)));

  return {
    [ServiceTypes.ethernet]: (
      <ServiceRequirementsEthernet minRfsDate={minRfsDate} name={name} />
    ),
    [ServiceTypes.mpls]: <></>,
    [ServiceTypes.ip_transit]: (
      <ServiceRequirementsIPTransit minRfsDate={minRfsDate} name={name} />
    ),
    [ServiceTypes.dark_fiber]: (
      <ServiceRequirementsDarkFiber minRfsDate={minRfsDate} name={name} />
    ),
    [ServiceTypes.cloud_connectivity]: <></>,
    [ServiceTypes.internet_access]: (
      <ServiceRequirementsInternetAccess minRfsDate={minRfsDate} name={name} />
    ),
    [ServiceTypes.wavelength]: (
      <ServiceRequirementsWavelength minRfsDate={minRfsDate} name={name} />
    ),
    [ServiceTypes.colocation]: (
      <ServiceRequirementsColocation minRfsDate={minRfsDate} name={name} />
    ),
    [ServiceTypes.cloud_services]: <></>,
    [ServiceTypes.sd_wan]: <></>,
    [ServiceTypes.multi_service_requirements]: <></>,
    [ServiceTypes.other]: <></>,
  }[type ?? 'other'];
};

export default ServiceRequirements;
