import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  typographyStyles: {
    flex: 1,
    fontSize: '14px',
    position: 'relative',
    top: '-5px',
  },
  warningIconWrapper: {
    borderRadius: 12,
    backgroundColor: theme.palette.grey[200],
    display: 'inline-block',
    float: 'left',
    height: 10,
    marginRight: 10,
    width: 50,
  },
  warningIcon: {
    borderRadius: 'inherit',
    height: 'inherit',
    transition: 'width .2s',
  },
  warningIconLow: {
    backgroundColor: theme.palette.error.dark,
    width: '33%',
  },
  warningIconMed: {
    backgroundColor: theme.palette.warning.dark,
    width: '66%',
  },
  warningIconHigh: {
    backgroundColor: theme.palette.success.dark,
    width: '100%',
  },
}));

export type Level = 'low' | 'med' | 'high';

interface DynamicHelperTextProps {
  level: Level;
}

const DynamicHelperText = ({ level, ...props }: DynamicHelperTextProps) => {
  const classes = useStyles();

  const warningIcon = (
    <div className={classes.warningIconWrapper}>
      <div
        className={clsx(classes.warningIcon, {
          [classes.warningIconHigh]: level === 'high',
          [classes.warningIconMed]: level === 'med',
          [classes.warningIconLow]: level === 'low',
        })}
      />
    </div>
  );

  const lowLevelDefault = (
    <Typography className={classes.typographyStyles} variant="caption">
      <b>Add more details.</b> We suggest you include relevant information like
      service type, size, speed, and location.
    </Typography>
  );
  const medLevelDefault = (
    <Typography className={classes.typographyStyles} variant="caption">
      <b>Not bad.</b> Don't forget to add specific details like quantity, power,
      or bandwidth requirements.
    </Typography>
  );
  const highLevelDefault = (
    <Typography className={classes.typographyStyles} variant="caption">
      <b>Looking good.</b> Before you move on, double-check your title is clear
      and you’ve included all relevant details.
    </Typography>
  );
  let messageText: React.ReactNode = '';

  switch (level) {
    case 'low':
      messageText = lowLevelDefault;
      break;
    case 'med':
      messageText = medLevelDefault;
      break;
    case 'high':
      messageText = highLevelDefault;
      break;
  }

  return (
    <div {...props}>
      {warningIcon}
      {messageText}
    </div>
  );
};

export default DynamicHelperText;
