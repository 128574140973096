import React from 'react';
import clsx from 'clsx';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 60,
      width: '100%',
      maxWidth: '100%',
      borderRadius: 4,
      flexGrow: 1,
      marginTop: theme.spacing(2),
      backgroundColor: '#fde4ea',
    },
    invite: {
      backgroundColor: '#e4f0ff',
    },
    icon: { padding: theme.spacing(2) },
    addIcon: {
      color: '#94afe6',
    },
    closeIcon: {
      color: '#f89e9e',
    },
  })
);

export interface InviteMoreProvidersInfoProps {
  inviteMore?: boolean;
}

const InviteMoreProvidersInfo: React.FC<InviteMoreProvidersInfoProps> = ({
  inviteMore,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      className={clsx(classes.root, {
        [classes.invite]: inviteMore,
      })}
    >
      <Grid item xs="auto" className={classes.icon}>
        {inviteMore ? (
          <AddCircleIcon className={classes.addIcon} />
        ) : (
          <CancelIcon className={classes.closeIcon} />
        )}
      </Grid>
      <Grid item xs="auto">
        <Typography variant="body1">
          {inviteMore
            ? 'The buyer has requested more service providers be invited to this private listing'
            : 'The buyer has requested that no other service providers be invited to this private listing'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InviteMoreProvidersInfo;
