import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useInitialised = () => {
  const { isLoading } = useAuth0();
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setInitialised(true);
    }
  }, [setInitialised, isLoading]);

  return initialised;
};
