import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { CompanyInfoDisplay } from '../CompanyInfo';
import BidderInfo from '../BidderInfoBlock';
import { format } from 'date-fns';
import { CompanyAccountResponse } from '../../types/account-service/company-account';

interface ViewBidDialogProps {
  open?: boolean;
  onClose?: () => void;
  company?: CompanyAccountResponse;
  bidder?: {
    full_name: string;
    email: string;
    phone: string;
  };
  viewBidderInfo?: boolean;
  submitted_at?: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    content: {
      padding: theme.spacing(0, 4),
    },
    section: {
      margin: theme.spacing(4, 0),
    },
  })
);

const ViewBidDialog: React.FC<ViewBidDialogProps> = ({
  open = false,
  onClose = () => {},
  company,
  bidder,
  viewBidderInfo,
  children,
  submitted_at,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={(event, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown')
          return false;
        onClose();
      }}
    >
      <DialogTitle>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            {company && <CompanyInfoDisplay company={company} />}
          </Grid>
          <Grid item xs="auto">
            {submitted_at && (
              <Typography component="div" align="right">
                {format(new Date(submitted_at), 'do MMMM yyyy')}
              </Typography>
            )}
          </Grid>
        </Grid>

        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={() => onClose()}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Grid container item xs={12}>
          {children}
        </Grid>
        {viewBidderInfo && bidder && (
          <Grid container item xs={12} className={classes.section}>
            <BidderInfo
              name={bidder.full_name}
              phone={bidder.phone}
              email={bidder.email}
              company={company?.attributes.name}
            />
          </Grid>
        )}
        {!viewBidderInfo && (
          <Grid container item xs={12} className={classes.section}>
            <Typography component="div">
              To see the contact details of{''}
              <strong> {company?.attributes.name} </strong>
              please let them know you are interested on the bid.
            </Typography>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewBidDialog;
