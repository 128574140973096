import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 38,
    height: 23,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'none',
  },
  switchBase: {
    padding: '2px 1px',
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 19,
    height: 19,
  },
  track: {
    borderRadius: 26 / 2,
    border: 'none',
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
}));

type ToggleProps = {
  checked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Toggle: React.FC<ToggleProps> = ({ checked, onChange, ...props }) => {
  const classes = useStyles();

  return (
    <Switch
      disableRipple
      color="primary"
      checked={checked}
      onChange={onChange}
      classes={classes}
      {...props}
    />
  );
};

export default Toggle;
