import React from 'react';
import { Box, Grid, Typography, MenuItem } from '@material-ui/core';
import { formatISO, parseISO } from 'date-fns';
import { Field } from 'react-final-form';
import { SelectFieldPlaceholder as SelectField } from '../../Form/CSSelectField';
import CSDateField from '../../Form/CSDateField';
import PlacesAutocomplete from '../../Form/PlacesAutocomplete';
import { getFeatureUIName } from '../../../constants';
import { ServiceTypes } from '../../../types/services';
import {
  ServiceContractTerm,
  ServiceContractUnits,
  ServicePower,
  ServicePowerUnits,
} from '../../../types/services';

interface ServiceRequirementsColocationProps {
  name: string;
  minRfsDate: Date;
}

const ServiceRequirementsColocation = ({
  name,
  minRfsDate,
}: ServiceRequirementsColocationProps) => {
  return (
    <Box my={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {getFeatureUIName(ServiceTypes.colocation)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field name={`${name}.attributes.point_a`}>
                {({ input }) => (
                  <PlacesAutocomplete
                    label="Where do you need colocation? (Market or Data Center)*"
                    selected={input.value}
                    onSelect={(place) => input.onChange(place)}
                    filterOptionType={['data-center', 'market']}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field name={`${name}.attributes.requirements.unit`}>
                {({ input }) => (
                  <SelectField label="Space *" {...input}>
                    <MenuItem value="RU">RU</MenuItem>
                    <MenuItem value="Quarter Rack">Quarter Rack</MenuItem>
                    <MenuItem value="Half Rack">Half Rack</MenuItem>
                    <MenuItem value="Full Rack">Full Rack</MenuItem>
                  </SelectField>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field name={`${name}.attributes.requirements.value`}>
                {({ input }) => (
                  <SelectField label="Quantity *" {...input}>
                    {Array(20)
                      .fill(null)
                      .map((_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                  </SelectField>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${name}.attributes.power`}
                parse={(term: string): ServicePower => {
                  const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];
                  return {
                    value: Number(value),
                    unit: unit as ServicePowerUnits,
                  };
                }}
                format={(term: ServicePower) => {
                  return term ? `${term.value}${term.unit}` : '';
                }}
              >
                {({ input }) => (
                  <SelectField
                    label="What are your power requirements? (kW Per Rack) *"
                    {...input}
                  >
                    {Array(20)
                      .fill(null)
                      .map((_, index) => (
                        <MenuItem key={index} value={`${index + 1}kW`}>
                          {index + 1}kW
                        </MenuItem>
                      ))}
                  </SelectField>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${name}.contract_term`}
                parse={(term: string): ServiceContractTerm => {
                  const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];

                  return {
                    value: Number(value),
                    unit: unit as ServiceContractUnits,
                  };
                }}
                format={(term: ServiceContractTerm) => {
                  return term ? `${term.value}${term.unit}` : '';
                }}
              >
                {({ input }) => (
                  <SelectField
                    label="What is your preferred contract term *"
                    {...input}
                  >
                    <MenuItem value="12months">12 months</MenuItem>
                    <MenuItem value="24months">24 months</MenuItem>
                    <MenuItem value="36months">36 months</MenuItem>
                    <MenuItem value="48months">48 months</MenuItem>
                    <MenuItem value="60months">60 months</MenuItem>
                  </SelectField>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${name}.ready_for_service`}
                parse={(value) => (value ? formatISO(value) : '')}
                format={(value) => (value ? parseISO(value) : '')}
              >
                {({ input }) => (
                  <CSDateField
                    minDate={minRfsDate}
                    label="When do you need the connection by? *"
                    {...input}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="attributes.end"
                parse={(value) => (value ? formatISO(value) : '')}
                format={(value) => (value ? parseISO(value) : '')}
              >
                {({ input }) => (
                  <CSDateField
                    minDate={minRfsDate}
                    label="How soon do you need a quote? *"
                    {...input}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceRequirementsColocation;
