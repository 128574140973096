import { Grid, Typography } from '@material-ui/core';
import { CompanyProfileResponse } from '../../../types/directory-service/company-profile';
import { ProviderGatewayServiceProvider } from '../../../types/provider-gateway';
import { ServiceProvider } from '../../../types/service-provider';
import ServiceProviderGatewayList from '../ServiceProviderGateway/ServiceProviderGatewayList';
import ServiceProvidersList from '../ServiceProvidersList';

interface ResultsProps {
  toggleMarketsValue: 'selected' | 'all';
  selectedProductsCount: number;
  matchedProvidersCount: number;
  matchedProviders: ProviderGatewayServiceProvider[];
  allProviders: ServiceProvider[] | CompanyProfileResponse[];
}

export const Results: React.FC<ResultsProps> = ({
  toggleMarketsValue,
  selectedProductsCount,
  matchedProvidersCount,
  matchedProviders,
  allProviders,
}) => {
  return (
    <Grid item>
      {toggleMarketsValue === 'selected' && (
        <>
          {selectedProductsCount === 0 && (
            <Typography variant="body2" component="div">
              Please add services to your listing, to be matched with the most
              optimal providers.
            </Typography>
          )}
          {matchedProvidersCount > 0 && (
            <ServiceProviderGatewayList providers={matchedProviders} />
          )}
          {matchedProvidersCount === 0 && selectedProductsCount !== 0 && (
            <Typography variant="body2" component="div">
              No service providers match your search criteria please try
              different combinations.
            </Typography>
          )}
        </>
      )}
      {toggleMarketsValue === 'all' && (
        <ServiceProvidersList serviceProviders={allProviders} />
      )}
    </Grid>
  );
};
