import { createSelector } from '@reduxjs/toolkit';
import { Listing } from '../../types/listing';
import { selectListingEntities } from '../listings/reducer';
import { selectAllOpportunities } from './reducer';
import { selectBidEntities } from '../bids';
import { Bid } from '../../types/bid';
import {
  ListingProviderResponse,
  ListingProviderResponseIncludes,
} from '../../types/marketplace-service/listing-provider';
import { ListingProviderGroup } from '../../types/listing-provider';
import { RequestContext } from '../../types/entity-collection';
import { getRelationshipContext } from '../../utilities/relationships';
import { parseISO } from 'date-fns';

export const isInvited = ({
  attributes: { invited_at, intended_to_bid_at, assigned_at, declined_at },
}: ListingProviderResponse) =>
  !!invited_at && !intended_to_bid_at && !assigned_at && !declined_at;

export const isPending = ({
  attributes: { intended_to_bid_at, assigned_at, bid_at, declined_at },
}: ListingProviderResponse) =>
  (!!intended_to_bid_at || !!assigned_at) && !bid_at && !declined_at;

export const isSubmitted = ({
  attributes: { intended_to_bid_at, bid_at },
}: ListingProviderResponse) => !!intended_to_bid_at && !!bid_at;

export const isDeclined = ({
  attributes: { declined_at },
}: ListingProviderResponse) => !!declined_at;

export const selectInvitedOpportunities = createSelector(
  selectAllOpportunities,
  (opportunities) => opportunities.filter(isInvited)
);

export const selectPendingOpportunities = createSelector(
  selectAllOpportunities,
  (opportunities) => opportunities.filter(isPending)
);

export const selectSubmittedOpportunities = createSelector(
  selectAllOpportunities,
  (opportunities) => opportunities.filter(isSubmitted)
);

export const selectDeclinedOpportunities = createSelector(
  selectAllOpportunities,
  (opportunities) => opportunities.filter(isDeclined)
);

export const selectOpportunitiesByListingId = (id?: string) =>
  createSelector(selectAllOpportunities, (opportunities) =>
    opportunities.filter(
      (opportunity) => opportunity.relationships.listing.data.id === id
    )
  );

export const selectOpportunitiesByListingsState = (state: string) =>
  createSelector(
    selectListingEntities,
    selectBidEntities,
    selectAllOpportunities,
    (listings, bids, opportunities) =>
      opportunities
        .filter((opportunity) => {
          const listing = listings[opportunity.relationships.listing.data.id];

          return listing?.attributes.state === state;
        })
        .map((opportunity) => {
          const listing = listings[opportunity.relationships.listing.data.id];
          const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

          return {
            ...opportunity,
            listing,
            bid,
          } as ListingProviderResponse & { listing: Listing; bid?: Bid };
        })
  );

export const selectInvitedListings = createSelector(
  selectListingEntities,
  selectInvitedOpportunities,
  (listings, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return listing?.attributes.state === 'published';
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return {
          ...opportunity,
          listing,
        } as ListingProviderResponse & { listing: Listing };
      })
);

export const selectUnassignedListings = createSelector(
  selectListingEntities,
  selectPendingOpportunities,
  (listings, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return (
          !opportunity.attributes.external_assigned_to_user_id &&
          listing?.attributes.state === 'published'
        );
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return {
          ...opportunity,
          listing,
        } as ListingProviderResponse & { listing: Listing };
      })
);

export const selectAssignedToOthersListings = createSelector(
  selectListingEntities,
  selectPendingOpportunities,
  (listings, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return (
          !!opportunity.attributes.external_assigned_to_user_id &&
          listing?.attributes.state === 'published'
        );
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return {
          ...opportunity,
          listing,
        } as ListingProviderResponse & { listing: Listing };
      })
);

export const selectAssignedToMeListings = (userid?: string) =>
  createSelector(
    selectListingEntities,
    selectPendingOpportunities,
    (listings, opportunities) =>
      opportunities
        .filter((opportunity) => {
          const listing = listings[opportunity.relationships.listing.data.id];

          return (
            !!opportunity.attributes.external_assigned_to_user_id &&
            listing?.attributes.state === 'published' &&
            userid === opportunity.attributes.external_assigned_to_user_id
          );
        })
        .map((opportunity) => {
          const listing = listings[opportunity.relationships.listing.data.id];

          return {
            ...opportunity,
            listing,
          } as ListingProviderResponse & { listing: Listing };
        })
  );

export const selectPendingListings = createSelector(
  selectListingEntities,
  selectPendingOpportunities,
  (listings, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return (
          !!opportunity.attributes.external_assigned_to_user_id &&
          listing?.attributes.state === 'published'
        );
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        return {
          ...opportunity,
          listing,
        } as ListingProviderResponse & { listing: Listing };
      })
);

export const selectAssignedToNewUserPendingListings = createSelector(
  selectListingEntities,
  selectPendingOpportunities,
  (listings, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return (
          !!opportunity.attributes.assigned_to_contact &&
          opportunity.attributes.external_assigned_to_user_id === null &&
          listing?.attributes.state === 'published'
        );
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        return {
          ...opportunity,
          listing,
        } as ListingProviderResponse & { listing: Listing };
      })
);

export const selectSubmittedListings = createSelector(
  selectListingEntities,
  selectBidEntities,
  selectSubmittedOpportunities,
  (listings, bids, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return (
          !!opportunity.attributes.external_assigned_to_user_id &&
          listing?.attributes.state === 'published'
        );
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return {
          ...opportunity,
          listing,
          bid,
        } as ListingProviderResponse & { listing: Listing; bid: Bid };
      })
);

export const selectDeclinedListings = createSelector(
  selectListingEntities,
  selectDeclinedOpportunities,
  (listings, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return (
          !!opportunity.attributes.external_assigned_to_user_id &&
          listing?.attributes.state === 'published'
        );
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];

        return {
          ...opportunity,
          listing,
        } as ListingProviderResponse & { listing: Listing };
      })
);

export const selectSuccessfulListings = createSelector(
  selectListingEntities,
  selectBidEntities,
  selectSubmittedOpportunities,
  (listings, bids, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return bid?.attributes.state === 'successful';
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return {
          ...opportunity,
          listing,
          bid,
        } as ListingProviderResponse & { listing: Listing; bid: Bid };
      })
);

export const selectUnsuccessfulListings = createSelector(
  selectListingEntities,
  selectBidEntities,
  selectSubmittedOpportunities,
  (listings, bids, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return bid?.attributes.state === 'unsuccessful';
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return {
          ...opportunity,
          listing,
          bid,
        } as ListingProviderResponse & { listing: Listing; bid: Bid };
      })
);

export const selectQualifiedListings = createSelector(
  selectListingEntities,
  selectBidEntities,
  selectSubmittedOpportunities,
  (listings, bids, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return bid?.attributes.state === 'interested';
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return {
          ...opportunity,
          listing,
          bid,
        } as ListingProviderResponse & { listing: Listing; bid: Bid };
      })
);

export const selectPassedListings = createSelector(
  selectListingEntities,
  selectBidEntities,
  selectSubmittedOpportunities,
  (listings, bids, opportunities) =>
    opportunities
      .filter((opportunity) => {
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return bid?.attributes.state === 'uninterested';
      })
      .map((opportunity) => {
        const listing = listings[opportunity.relationships.listing.data.id];
        const bid = bids[opportunity.relationships.bid?.data?.id ?? ''];

        return {
          ...opportunity,
          listing,
          bid,
        } as ListingProviderResponse & { listing: Listing; bid: Bid };
      })
);

const getUniqueListingProviders = (
  providers: ListingProviderResponse[]
): ListingProviderResponse[] => {
  const result = providers.reduce<{ [key: string]: ListingProviderResponse }>(
    (result, provider) => {
      const id = provider.relationships.listing.data?.id;

      if (!id) {
        return result;
      }

      if (result[id] !== undefined) {
        const createdAt = parseISO(provider.attributes.created_at);

        if (createdAt > parseISO(result[id].attributes.created_at)) {
          result[id] = provider;
        }
      } else {
        result[id] = provider;
      }

      return result;
    },
    {}
  );

  return Object.values(result);
};

export const selectActiveListingProviders = (
  entities: ListingProviderResponse[],
  context?: RequestContext<ListingProviderResponseIncludes>
) => {
  return getUniqueListingProviders(entities).reduce<{
    invited: ListingProviderGroup[];
    pending: ListingProviderGroup[];
    submitted: ListingProviderGroup[];
    declined: ListingProviderGroup[];
  }>(
    (result, provider) => {
      const listing = getRelationshipContext(
        context?.listing,
        provider.relationships.listing.data.id
      );

      const bid = getRelationshipContext(
        context?.bid,
        provider.relationships.bid?.data?.id
      );

      if (listing?.attributes.state !== 'published') return result;

      if (isInvited(provider)) {
        result.invited.push({ provider, listing, bid });
      }

      if (isPending(provider)) {
        result.pending.push({ provider, listing, bid });
      }

      if (isSubmitted(provider)) {
        result.submitted.push({ provider, listing, bid });
      }

      if (isDeclined(provider)) {
        result.declined.push({ provider, listing, bid });
      }

      return result;
    },
    {
      invited: [],
      pending: [],
      submitted: [],
      declined: [],
    }
  );
};
export const selectQualifiedListingProviders = (
  entities: ListingProviderResponse[],
  context?: RequestContext<ListingProviderResponseIncludes>
) => {
  return getUniqueListingProviders(entities).reduce<{
    uninterested: ListingProviderGroup[];
    interested: ListingProviderGroup[];
  }>(
    (result, provider) => {
      const listing = getRelationshipContext(
        context?.listing,
        provider.relationships.listing.data.id
      );

      const bid = getRelationshipContext(
        context?.bid,
        provider.relationships.bid?.data?.id
      );

      if (!listing) return result;

      if (bid?.attributes.state === 'uninterested') {
        result.uninterested.push({
          provider,
          listing,
          bid,
        });
      }

      if (bid?.attributes.state === 'interested') {
        result.interested.push({
          provider,
          listing,
          bid,
        });
      }

      return result;
    },
    {
      uninterested: [],
      interested: [],
    }
  );
};

export const selectFinishedListingProviders = (
  entities: ListingProviderResponse[],
  context?: RequestContext<ListingProviderResponseIncludes>
) => {
  return getUniqueListingProviders(entities).reduce<{
    ended: ListingProviderGroup[];
    completed: ListingProviderGroup[];
  }>(
    (result, provider) => {
      const listing = getRelationshipContext(
        context?.listing,
        provider.relationships.listing.data.id
      );

      const bid = getRelationshipContext(
        context?.bid,
        provider.relationships.bid?.data?.id
      );

      if (listing?.attributes.state === 'completed') {
        result.completed.push({
          provider,
          listing,
          bid,
        });
      }

      if (listing?.attributes.state === 'ended') {
        result.ended.push({
          provider,
          listing,
          bid,
        });
      }

      return result;
    },
    {
      ended: [],
      completed: [],
    }
  );
};
