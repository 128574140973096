import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';

const useItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      padding: theme.spacing(1.5, 3),
      margin: theme.spacing(0.5, 0),
      borderRadius: theme.shape.borderRadius,
      '&$selected': {
        backgroundColor: '#f3f6f9',
        '&:hover': {
          backgroundColor: '#f3f6f9',
        },
      },
      '&:hover': {
        backgroundColor: '#f3f6f9',
      },
    },
    selected: {
      color: theme.palette.text.primary,
    },
  })
);

const NavigationListItem: React.FC<ListItemProps> = ({
  children,
  selected,
  onClick,
}) => {
  const itemClasses = useItemStyles();
  return (
    <ListItem
      button
      classes={itemClasses}
      selected={selected}
      //@ts-ignore
      onClick={onClick}
    >
      {children}
    </ListItem>
  );
};

export default NavigationListItem;
