import {
  EntityCollectionResponse,
  EntityResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import {
  BidPricingOptionRequest,
  BidPricingOptionResponse,
  BidPricingOptionResponseIncludes,
  BidRequest,
  BidResponse,
  BidResponseIncludes,
  BidServiceRequest,
  BidServiceResponse,
  BidServiceResponseIncludes,
} from '../../../types/marketplace-service/bid';
import {
  transformEntityCollectionResponse,
  transformEntityResponse,
} from '../transform-response';
import { marketplaceServiceAPI } from './base';

type BidCacheTag = { type: 'Bid'; id: string };
type BidServiceCacheTag = { type: 'BidService'; id: string };
type BidPricingOptionCacheTag = { type: 'BidPricingOption'; id: string };

export const bidsAPI = marketplaceServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    createBid: builder.mutation<
      EntityResponse<BidResponse, BidResponseIncludes>,
      {
        payload: BidRequest;
        params?: URLQueryParams;
      }
    >({
      query: (args) => ({
        url: `/bids`,
        params: args.params,
        method: 'POST',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    readAllBids: builder.query<
      EntityCollectionResponse<BidResponse, BidResponseIncludes>,
      { params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<BidCacheTag>(({ id }) => ({
                type: 'Bid',
                id,
              }))
              .concat({ type: 'Bid', id: 'LIST' })
          : [{ type: 'Bid', id: 'LIST' }],
      query: (args = {}) => ({
        url: `/bids`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readBidByID: builder.query<
      EntityResponse<BidResponse, BidResponseIncludes>,
      { id: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'Bid',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(args.id)}`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),
    updateBidByID: builder.mutation<
      EntityResponse<BidResponse, BidResponseIncludes>,
      {
        id: string;
        payload: BidRequest;
        params?: URLQueryParams;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Bid', id: result.entity.id },
              { type: 'Bid', id: 'LIST' },
              {
                type: 'Listing',
                id: result.entity.relationships.listing.data.id,
              },
            ]
          : [{ type: 'Bid', id: 'LIST' }],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(args.id)}`,
        params: args.params,
        method: 'PATCH',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    deleteBidByID: builder.mutation<
      void,
      {
        id: string;
        params?: URLQueryParams;
      }
    >({
      invalidatesTags: (result, error, args) =>
        result
          ? [
              { type: 'Bid', id: args.id },
              { type: 'Bid', id: 'LIST' },
            ]
          : [{ type: 'Bid', id: 'LIST' }],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(args.id)}`,
        params: args.params,
        method: 'DELETE',
      }),
    }),

    readAllBidsByListingID: builder.query<
      EntityCollectionResponse<BidResponse, BidResponseIncludes>,
      { listing: string; params?: URLQueryParams; headers?: URLQueryParams }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<BidCacheTag>(({ id }) => ({
                type: 'Bid',
                id,
              }))
              .concat({ type: 'Bid', id: 'LIST' })
          : [{ type: 'Bid', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.listing)}/bids`,
        params: args?.params,
        method: 'GET',
        headers: args?.headers,
      }),
      transformResponse: transformEntityCollectionResponse,
    }),

    exportAllBidsByListingID: builder.query<
      { data: string },
      { listing: string; params?: URLQueryParams; allowDownload?: boolean }
    >({
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.listing)}/bids`,
        params: args?.params,
        method: 'GET',
        headers: {
          Accept: 'text/csv',
          'Content-Type': 'text/csv',
        },
        responseHandler: (response) => response.text(),
      }),
      keepUnusedDataFor: 10,
    }),

    lockBid: builder.mutation<
      EntityResponse<BidResponse, BidResponseIncludes>,
      {
        id: string;
        params?: URLQueryParams;
      }
    >({
      query: (args) => {
        const payload: BidRequest = {
          id: args.id,
          type: 'bid',
          attributes: {
            locked: true,
          },
        };

        return {
          url: `/bids/${encodeURIComponent(args.id)}`,
          params: args.params,
          method: 'PATCH',
          body: JSON.stringify({ data: payload }),
        };
      },
      transformResponse: transformEntityResponse,
    }),

    createBidService: builder.mutation<
      EntityResponse<BidServiceResponse, BidServiceResponseIncludes>,
      {
        bid: string;
        payload: BidServiceRequest;
        params?: URLQueryParams;
      }
    >({
      query: (args) => ({
        url: `/bids/${encodeURIComponent(args.bid)}/services`,
        params: args.params,
        method: 'POST',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    readAllBidServices: builder.query<
      EntityCollectionResponse<BidServiceResponse, BidServiceResponseIncludes>,
      { bid: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<BidServiceCacheTag>(({ id }) => ({
                type: 'BidService',
                id,
              }))
              .concat({ type: 'BidService', id: 'LIST' })
          : [{ type: 'BidService', id: 'LIST' }],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(args.bid)}/services`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readBidServiceByID: builder.query<
      EntityResponse<BidServiceResponse, BidServiceResponseIncludes>,
      { bid: string; service: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'BidService',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(
          args.bid
        )}/services/${encodeURIComponent(args.service)}`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),

    createBidPricingOption: builder.mutation<
      EntityResponse<
        BidPricingOptionResponse,
        BidPricingOptionResponseIncludes
      >,
      {
        bid: string;
        service: string;
        payload: BidPricingOptionRequest;
        params?: URLQueryParams;
      }
    >({
      query: (args) => ({
        url: `/bids/${encodeURIComponent(
          args.bid
        )}/services/${encodeURIComponent(args.service)}/options`,
        params: args.params,
        method: 'POST',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    readAllBidPricingOptions: builder.query<
      EntityCollectionResponse<
        BidPricingOptionResponse,
        BidPricingOptionResponseIncludes
      >,
      { bid: string; service: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<BidPricingOptionCacheTag>(({ id }) => ({
                type: 'BidPricingOption',
                id,
              }))
              .concat({ type: 'BidPricingOption', id: 'LIST' })
          : [{ type: 'BidPricingOption', id: 'LIST' }],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(
          args.bid
        )}/services/${encodeURIComponent(args.service)}/options`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readBidPricingOptionByID: builder.query<
      EntityResponse<
        BidPricingOptionResponse,
        BidPricingOptionResponseIncludes
      >,
      { bid: string; service: string; option: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'BidPricingOption',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/bids/${encodeURIComponent(
          args.bid
        )}/services/${encodeURIComponent(
          args.service
        )}/options/${encodeURIComponent(args.option)}`,
        params: args?.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),
  }),
});

export const {
  useCreateBidMutation,
  useCreateBidPricingOptionMutation,
  useCreateBidServiceMutation,
  useDeleteBidByIDMutation,
  useLazyReadAllBidPricingOptionsQuery,
  useLazyReadAllBidServicesQuery,
  useLazyReadAllBidsByListingIDQuery,
  useLazyExportAllBidsByListingIDQuery,
  useLazyReadAllBidsQuery,
  useLazyReadBidByIDQuery,
  useLazyReadBidPricingOptionByIDQuery,
  useLazyReadBidServiceByIDQuery,
  useLockBidMutation,
  usePrefetch,
  useReadAllBidPricingOptionsQuery,
  useReadAllBidServicesQuery,
  useReadAllBidsByListingIDQuery,
  useExportAllBidsByListingIDQuery,
  useReadAllBidsQuery,
  useReadBidByIDQuery,
  useReadBidPricingOptionByIDQuery,
  useReadBidServiceByIDQuery,
  useUpdateBidByIDMutation,
} = bidsAPI;
