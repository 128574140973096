import React, { useCallback } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useRegisterListingProviderMutation } from '../../services/api/marketplace-service/listing-providers';
import { useAppMessenger } from '../../store/messenger';
import { navigate } from '@reach/router';
import { ROUTES } from '../../routes';
import { useAuth } from '../../hooks/useAuth';

interface EnableIntendToBidProps {
  listingId: string;
  listingShortId?: string;
  providerId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extend: {
      boxShadow: theme.shadows[4],
      border: '1px solid transparent',
      borderRadius: '8px',
      padding: theme.spacing(1, 2),
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);

const EnableIntendToBid: React.FC<EnableIntendToBidProps> = ({
  listingId,
  listingShortId,
  providerId,
}) => {
  const classes = useStyles();
  const [registerListingProvider] = useRegisterListingProviderMutation();
  const messenger = useAppMessenger();
  const { user } = useAuth();

  const onRegisterIntent = useCallback(async () => {
    if (!providerId) return;

    return await registerListingProvider({
      listing: listingId,
      provider: providerId,
      user: user?.id,
    })
      .unwrap()
      .then(() => {
        messenger.addMessage('info', 'You have a new opportunity');
        navigate(`${ROUTES.sellingOpportunities}/${listingShortId}`);
      })
      .catch(() => {
        messenger.addMessage('error', 'Error while intending to bid');
      });
  }, [
    registerListingProvider,
    listingId,
    providerId,
    listingShortId,
    user,
    messenger,
  ]);

  if (!providerId) return null;

  return (
    <Grid container spacing={1} alignItems="center" className={classes.extend}>
      <Grid item xs={12} sm={9}>
        <Typography variant="h5" gutterBottom>
          Changed your mind?
        </Typography>
        <Typography variant="body2">
          No problem, there is still time to bid! Let the buyer know now.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={onRegisterIntent}
          fullWidth
        >
          Intend to bid
        </Button>
      </Grid>
    </Grid>
  );
};

export default EnableIntendToBid;
