import React, { FunctionComponent } from 'react';
import { isToday } from 'date-fns';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import withFormLabel from '../WithFormLabel/WithFormLabel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
  },
}));

interface CSDateFieldProps extends DatePickerProps {
  value: number | Date;
}
const CSDateField: FunctionComponent<Omit<CSDateFieldProps, 'css'>> = ({
  value,
  ...props
}) => {
  const classes = useStyles();

  return (
    <DatePicker
      className={classes.root}
      autoOk
      required
      animateYearScrolling
      disableToolbar
      variant="inline"
      inputVariant="outlined"
      margin="normal"
      autoComplete="off"
      format="dd/MM/yyyy"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isToday(value) && '(Today)'}
            <DateRangeIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      fullWidth
      value={value}
      {...props}
    />
  );
};

export default withFormLabel(CSDateField);
