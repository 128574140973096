import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialog, {
  DialogProps as MuiDialogProps,
} from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogContentText: {
    margin: 0,
  },
  dialogActions: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(1),

    [theme.breakpoints.up(330)]: {
      padding: theme.spacing(2, 3),
    },
  },
  confirmBtn: {
    color: theme.palette.error.main,
    fontWeight: 700,
    borderColor: theme.palette.error.main,
  },
}));

export type ConfirmDialogProps = MuiDialogProps & {
  cancelActionText?: string;
  confirmedActionText?: string;
  cancelAction: () => void;
  confirmedAction: () => void;
  title: string;
  content: string | ReactElement;
};

const ConfirmDialog = ({
  cancelActionText,
  confirmedActionText,
  cancelAction,
  confirmedAction,
  open,
  title,
  content,
}: ConfirmDialogProps): ReactElement => {
  const classes = useStyles();

  return (
    <MuiDialog color="primary" open={open} className={classes.root}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
        <Typography color="primary" variant="h5">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={cancelAction}>
          {cancelActionText || 'Cancel'}
        </Button>
        <Button
          className={classes.confirmBtn}
          variant="text"
          onClick={confirmedAction}
        >
          {confirmedActionText || 'Confirm'}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmDialog;
