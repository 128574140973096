import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Field, FormSpy } from 'react-final-form';
import TextField from '../../components/Form/CSTextField';
import AdminTeamMemberSearch from '../../components/AdminTeamMemberSearch';

interface FormSectionProps {
  name: string;
}

const ClientDetails: React.FC<FormSectionProps> = ({ name }) => {
  return (
    <Grid container spacing={4}>
      <Grid container spacing={4} item alignItems="center">
        <Grid item xs>
          <Typography variant="h5" component="div" gutterBottom>
            Client Details
          </Typography>
          <Typography variant="body2" component="div">
            Client details can be added to a listing for admin purposes. The
            information will only ever be visible by Cloudscene admins on
            listings.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} item alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormSpy subscription={{ pristine: true }}>
            {({ form }) => (
              <Field name="client">
                {({ input }) => {
                  return (
                    <AdminTeamMemberSearch
                      label="Name"
                      defaultUser={input.value}
                      onChange={({ user, account }) => {
                        input.onChange(user);
                        form.change(
                          'attributes.admin_client_account_service_users_id',
                          user.id
                        );
                        form.change('client_email', user.attributes.email);
                        form.change('client_company', account.attributes.name);
                      }}
                      optionType="string"
                    />
                  );
                }}
              </Field>
            )}
          </FormSpy>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Field name="client_email">
            {({ input }) => (
              <TextField label="Email" placeholder="-" disabled {...input} />
            )}
          </Field>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Field name="client_company">
            {({ input }) => (
              <TextField label="Company" placeholder="-" disabled {...input} />
            )}
          </Field>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientDetails;
