import React from 'react';
import { Field, useField } from 'react-final-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Grid,
  Theme,
  useMediaQuery,
  Typography,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import SelectField from '../Form/CSSelectField';
import TextSelectGroup from '../Form/CSTextSelectGroup';
import { Currencies } from '../../constants';
interface PricingOptionsProps {
  field: string;
  showLabels?: boolean;
  disableFields?: boolean;
  index: number;
  formSize?: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    symbol: {
      fontWeight: theme.typography.fontWeightBold,
    },
    removeIcon: {
      color: theme.palette.grey[500],
    },
    icon: {
      textAlign: 'right',
      width: '70px',
      marginTop: theme.spacing(3),
    },
  })
);

const PricingOptions: React.FC<PricingOptionsProps> = ({
  field = '',
  showLabels = false,
  disableFields = false,
  children,
  index,
  formSize,
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'), {
    noSsr: true,
  });
  const nonRecurringCostField = useField(`${field}.nonRecurringCost`);
  const recurringCostField = useField(`${field}.recurringCost`);

  return (
    <Grid container spacing={2} item xs="auto" alignItems="center">
      {children && mobile && (
        <Grid item xs={mobile ? 12 : 1} className={classes.icon}>
          {children}
        </Grid>
      )}
      <Grid item xs={12} sm={formSize && formSize < 600 ? 12 : 6} xl={4}>
        <Field
          name={`${field}.contractTerm`}
          disabled={disableFields}
          parse={(term: string) => {
            const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];

            return {
              value: Number(value),
              unit,
            };
          }}
          format={(term) => {
            return term ? `${term.value}${term.unit}` : '';
          }}
        >
          {({ input }) => (
            <SelectField
              label={showLabels ? 'Contract Term' : undefined}
              inputProps={{ disabled: disableFields }}
              {...input}
            >
              <MenuItem value="12month">12 months</MenuItem>
              <MenuItem value="24month">24 months</MenuItem>
              <MenuItem value="36month">36 months</MenuItem>
              <MenuItem value="48month">48 months</MenuItem>
              <MenuItem value="60month">60 months</MenuItem>
            </SelectField>
          )}
        </Field>
      </Grid>
      <Grid item xs={12} sm={formSize && formSize < 600 ? 12 : 6} xl={4}>
        <Field name={`${field}.nonRecurringCost`}>
          {({ input, meta }) => (
            <TextSelectGroup
              label={showLabels ? 'Non-Recurring Cost' : undefined}
              name={input.name}
              value={input.value.value}
              selected={input.value.unit}
              onBlur={input.onBlur}
              onChange={(event) => {
                const value = event.target.value;
                input.onChange({
                  value:
                    value.indexOf('.') === -1 ? value : value.replace('.', ''),
                  unit: input.value.unit,
                });
              }}
              onSelect={(event) => {
                input.onChange({
                  value: input.value.value,
                  unit: event.target.value,
                });
                recurringCostField.input.onChange({
                  value: recurringCostField.input.value.value,
                  unit: event.target.value,
                });
              }}
              defaultSelected={Currencies.USD}
              textFieldProps={{
                type: 'number',
                disabled: disableFields,
                inputProps: {
                  min: 0,
                  step: '1',
                  onKeyDown: (event) => {
                    if (event.key === '.') {
                      event.preventDefault();
                    }
                  },
                },
                error: meta.touched && !!meta.error,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography component="span" className={classes.symbol}>
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
              selectFieldProps={{ disabled: disableFields }}
            >
              {Object.keys(Currencies).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextSelectGroup>
          )}
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
        sm={formSize && formSize < 600 ? 12 : 6}
        xl={index === 0 ? 4 : 3}
      >
        <Field name={`${field}.recurringCost`}>
          {({ input, meta }) => (
            <TextSelectGroup
              label={showLabels ? 'Monthly-Recurring Cost' : undefined}
              name={input.name}
              value={input.value.value}
              selected={input.value.unit}
              onBlur={input.onBlur}
              onChange={(event) => {
                const value = event.target.value;
                input.onChange({
                  value:
                    value.indexOf('.') === -1 ? value : value.replace('.', ''),
                  unit: input.value.unit,
                });
              }}
              onSelect={(event) => {
                input.onChange({
                  value: input.value.value,
                  unit: event.target.value,
                });
                nonRecurringCostField.input.onChange({
                  value: nonRecurringCostField.input.value.value,
                  unit: event.target.value,
                });
              }}
              defaultSelected={Currencies.USD}
              textFieldProps={{
                type: 'number',
                disabled: disableFields,
                inputProps: {
                  min: 0,
                  step: '1',
                  onKeyDown: (event) => {
                    if (event.key === '.') {
                      event.preventDefault();
                    }
                  },
                },
                error: meta.touched && !!meta.error,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography component="span" className={classes.symbol}>
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
              selectFieldProps={{ disabled: disableFields }}
            >
              {Object.keys(Currencies).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextSelectGroup>
          )}
        </Field>
      </Grid>
      {children && !mobile && (
        <Grid item xs={1} className={classes.icon}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default PricingOptions;
