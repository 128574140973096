import {
  createEntityAdapter,
  createSlice,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import { ListingService } from '../../types/services';
import {
  createService,
  fetchAllServices,
  fetchServiceById,
  updateServiceById,
  deleteServiceById,
} from './actions';
import { RootState, AppThunk } from '../types.d';
import { resetListings, selectListingById } from '../listings';
import { listingsAPI } from '../../services/api/marketplace-service/listings';

const servicesAdapter = createEntityAdapter<ListingService>({
  selectId: (service) => service.id!,
});

const services = createSlice({
  name: 'services',
  initialState: servicesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetListings, (state) => {
      servicesAdapter.removeAll(state);
    });
    builder.addCase(fetchAllServices.fulfilled, (state, action) => {
      servicesAdapter.upsertMany(
        state,
        action.payload.data.map((service) => ({
          type: 'listing-service',
          id: service.id!,
          attributes: service,
        }))
      );
    });

    builder.addCase(fetchServiceById.fulfilled, (state, action) => {
      const service = action.payload.data;
      servicesAdapter.upsertOne(state, {
        type: 'listing-service',
        id: service.id!,
        attributes: service,
      });
    });

    builder.addCase(updateServiceById.fulfilled, (state, action) => {
      const service = action.payload.data;
      servicesAdapter.upsertOne(state, {
        type: 'listing-service',
        id: service.id!,
        attributes: service,
      });
    });

    builder.addCase(deleteServiceById.fulfilled, (state, action) => {
      servicesAdapter.removeOne(state, action.payload.id);
    });

    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingsByCompanyID.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context['listing-service']) return state;

        const services = Object.values(
          action.payload.context['listing-service'].entities
        );
        servicesAdapter.upsertMany(state, services);
      }
    );
  },
});

export const {
  selectIds: selectServiceIds,
  selectEntities: selectServiceEntities,
  selectAll: selectAllServices,
  selectTotal: selectTotalServices,
} = servicesAdapter.getSelectors((state: RootState) => state.services);

export {
  createService,
  fetchAllServices,
  fetchServiceById,
  updateServiceById,
  deleteServiceById,
};
export const selectServiceById = (id?: string | null) =>
  createSelector(selectServiceEntities, (services) =>
    id ? services[id] : undefined
  );
export const selectServicesByListingId = (id: string) => {
  return createSelector(
    (state: RootState) => selectListingById(state, id),
    selectAllServices,
    (listing, services) => {
      if (!listing?.relationships?.services?.data) {
        return [];
      }

      return listing.relationships.services.data.map((service) =>
        services.find((srv) => srv.id === service.id)
      );
    }
  );
};

export const dispatchService =
  (
    action: string,
    payload: {
      listing: string;
      service: ListingService;
    }
  ): AppThunk =>
  async (dispatch) => {
    if (action === 'add') return dispatch(createService(payload));
    if (action === 'edit') return dispatch(updateServiceById(payload));
    if (action === 'delete')
      return dispatch(
        deleteServiceById({ ...payload, service: payload.service.id! })
      );
  };

export default services.reducer;
