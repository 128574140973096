import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { getProductLabelTextVariant } from '../../utilities/marketplace-match';
import { ProductStatusLabels } from '../../types/marketplace-match';
import { Tooltip } from '@material-ui/core';
import { ProductStatusType } from '../../types/service-provider';
import { PresenceType } from '../../types/provider-gateway';

interface ProductsStatusLabelProps {
  aEndStatus: ProductStatusType | PresenceType;
  zEndStatus?: ProductStatusType | PresenceType;
  azEndStatus?: ProductStatusType | PresenceType;
  isColoc?: boolean;
}

interface TextProductStatusLabelProps {
  aEndStatus: ProductStatusType | PresenceType;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1,
      padding: '4px 8px',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      position: 'relative',
    },
    icon: {
      margin: 'auto 0px auto 5px',
      color: theme.palette.common.white,
      fontSize: 16,
      position: 'absolute',
      top: '1px',
    },
    // On Net
    on: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    available: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    // Near Net
    near: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
    },
    // Off Net
    off: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    unavailable: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
  })
);
const useTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 'bold',
    },
    // On Net
    on: {
      color: theme.palette.success.main,
    },
    onNet: {
      color: theme.palette.success.main,
    },
    available: {
      color: theme.palette.success.main,
    },
    // Near Net
    near: {
      color: theme.palette.warning.main,
    },
    nearNet: {
      color: theme.palette.warning.main,
    },
    // Off Net
    off: {
      color: theme.palette.grey[500],
    },
    offNet: {
      color: theme.palette.grey[500],
    },
    unavailable: {
      color: theme.palette.error.main,
    },
  })
);

export const TextProductStatusLabel: React.FC<TextProductStatusLabelProps> = ({
  aEndStatus,
}) => {
  const classes = useTextStyles();
  const variant = getProductLabelTextVariant(aEndStatus);
  return (
    <span className={clsx(classes.text, classes[variant])}>
      ({ProductStatusLabels[variant]})
    </span>
  );
};

const ProductStatusLabel: React.FC<ProductsStatusLabelProps> = ({
  aEndStatus,
  zEndStatus,
  azEndStatus,
  isColoc,
}) => {
  const classes = useStyles();
  const variant = isColoc
    ? aEndStatus === 'on' || aEndStatus === 'onNet'
      ? 'available'
      : 'unavailable'
    : azEndStatus !== undefined && azEndStatus === 'onNet'
    ? 'on'
    : 'off';
  if (aEndStatus === 'off' && zEndStatus === 'off') return null;
  if (variant === 'unavailable' || variant === 'off') return null;

  const tooltipLabel = isColoc
    ? aEndStatus === 'on'
      ? 'This provider is able to provide services at your selected location.'
      : ''
    : variant === 'on'
    ? 'This provider is connected and is able to provide services.'
    : 'This provider is connected within 200m and may be able to provide services';
  return (
    <Tooltip placement="bottom" arrow title={tooltipLabel}>
      <span className={clsx(classes.root, [classes[variant]])}>
        {ProductStatusLabels[variant]}
      </span>
    </Tooltip>
  );
};

export default ProductStatusLabel;
