import { fetchDocument } from '../../api';
import { Document } from '../../types/document';
import { useEffect, useState } from 'react';

const BidDocument = ({
  id,
  children,
}: {
  id: string;
  children: (document: Document) => JSX.Element;
}) => {
  const [doc, setDoc] = useState<Document>();
  useEffect(() => {
    fetchDocument(id)
      .then((res) => setDoc(res.data.data as Document))
      .catch((error) => console.error(error));
  }, [id]);

  return doc ? children(doc) : null;
};

export default BidDocument;
