import React, { ReactElement } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { ROUTES } from '../../routes';
import Helmet from 'react-helmet';
import AppLayout from '../../containers/AppLayout';
import { getPageTitleTemplate } from '../../constants';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: 'auto',
    marginTop: theme.spacing(7),
    maxWidth: '500px',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 65,
  },
  text: {
    marginBottom: theme.spacing(3),
  },
}));

const ListingSuccess = ({
  title = 'Create New Listing',
}: RouteComponentProps<{ title?: string }>): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <CheckCircleOutlineIcon className={classes.icon} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Success!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.text}>
              You have submitted your Marketplace listing and it’s now pending.
              You’ll receive an email once it’s live.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={ROUTES.buyingListings}
            >
              Go to My Marketplace
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" component={Link} to={ROUTES.createListing}>
              Create another listing
            </Button>
          </Grid>
        </Grid>
      </AppLayout>
    </>
  );
};

export default ListingSuccess;
