import { useCallback, useRef } from 'react';

export type Place = {
  place_id: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
};

export type GeoCoder = {
  geocode: ({ placeId: string }) => Promise<{ results: Place[] }>;
};

export const useGeoCoder = () => {
  const geoCoder = useRef<GeoCoder>(new window.google.maps.Geocoder());

  const getCoords = useCallback(
    async (placeId: string): Promise<{ lat: number; lng: number } | null> => {
      const placeRes = await geoCoder.current.geocode({
        placeId,
      });
      const location = placeRes.results[0].geometry.location;

      if (!location) {
        return null;
      }

      return {
        lat: location.lat(),
        lng: location.lng(),
      };
    },
    [geoCoder]
  );

  return {
    getCoords,
  };
};
