import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useAppMessenger } from '../../store/messenger';
import {
  Form,
  FormRenderProps,
  FormSpy,
  FormSpyRenderProps,
  Field,
} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { add, formatISO, parseISO, format } from 'date-fns';
import { dequal } from 'dequal/lite';
import {
  Box,
  Grid,
  Typography,
  Backdrop,
  Zoom,
  Hidden,
} from '@material-ui/core';
import ListingSection from '../sections/ListingSection';
import ServiceRequirementsSection from '../sections/ServiceRequirementsSection';
import MarketsSection from '../sections/MarketsSection';
import ServiceProvidersSection from '../sections/ServiceProvidersSection';
import ListingDetailsSection from '../sections/ListingDetailsSection';
import AdditionalInfoSection from '../sections/AdditionalInfoSection';
import AttachmentsSection from '../sections/AttachmentsSection';
import MyDetailsSection from '../sections/MyDetailsSection';
import ConfirmPublishSection from '../sections/ConfirmPublishSection';
import HeaderBlock from '../../components/CreateListing/HeaderBlock';
import PublishListing from '../../components/CreateListing/PublishListing';
import LogoLoader from '../../components/Logo/LogoCloudLoader';
import PreviewListing from '../../components/CreateListing/PreviewListing';
import UpdateListingConfirmation from '../../components/UpdateListingConfirmation';
import Toggle from '../../components/Toggle';
import validationSchema from './validation';
import { createListingDraft, getListingTitle } from './make-listing';
import { AppDispatch } from '../../store/types';
import { useAuth, AuthUserType } from '../../hooks/useAuth';
import { CreateListingProps, CreateListingFormState } from './types';
import {
  addResource,
  editResource,
  duplicateResource,
  deleteResource,
  deleteResources,
} from './mutators';
import calculator from './decorators';
import {
  ListingService,
  ServiceMultiRequirements,
  Services,
} from '../../types/services';
import { dispatchService } from '../../store/services';
import { dispatchDocuments } from '../../store/documents';
import { MarketEntity } from '../../types/market';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';
import { ListingProviderContact } from '../../types/provider-contact';
import { Document } from '../../types/document';
import { useInitialListingValues } from './useInitialListingValues';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import { ListingResponse } from '../../types/marketplace-service/listing';
import {
  listingsAPI,
  useCreateListingMutation,
  useUpdateListingByIDMutation,
  useCancelListingByIDMutation,
} from '../../services/api/marketplace-service/listings';
import {
  useCreateListingProviderMutation,
  useDeleteListingProviderByListingIDMutation,
} from '../../services/api/marketplace-service/listing-providers';
import {
  useCreateListingProviderContactMutation,
  useDeleteListingProviderContactByIDMutation,
  useUpdateListingProviderContactByIDMutation,
} from '../../services/api/marketplace-service/listing-provider-contacts';
import { ListingProviderContactResponse } from '../../types/marketplace-service/listing-provider-contact';
import {
  useCreateListingMarketMutation,
  useDeleteListingMarketByIDMutation,
} from '../../services/api/marketplace-service/markets';
import { useUserQuery } from '../../hooks/useUserQuery';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useMarketsFromService } from '../../hooks/useMarketsFromService';
import { ListingMarketRequest } from '../../types/marketplace-service/market';
import { ListingProvider } from '../../types/provider';
import { CompanyCreateModal } from '../../components/CreateListing/CompanyCreateModal';
import CancelListingConfirmation from '../../components/CancelListingConfirmation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const getInitialValues = (
  {
    listing,
    services = [],
    markets = [],
    providers = [],
    contacts = [],
    documents = [],
    user,
    action = 'create',
    getMarketsFromService,
  }: {
    listing?: ListingResponse;
    services?: ListingService[];
    markets?: MarketEntity[];
    providers?: ListingProviderResponse[];
    contacts?: ListingProviderContactResponse[];
    documents?: Document[];
    user?: AuthUserType;
    action?: 'create' | 'edit' | 'copy';
    getMarketsFromService: (service: Services) => MarketEntity[];
  } = {
    getMarketsFromService: () => [],
  }
): Omit<CreateListingFormState, 'owner'> => {
  const today = new Date();
  const defaultEndDate = formatISO(
    add(new Date(format(today, 'dd MMMM yyyy')), {
      days: 14,
      hours: 17,
    })
  );
  const defaultStartDate = formatISO(
    add(new Date(format(today, 'dd MMMM yyyy')), {
      hours: 9,
    })
  );

  return {
    id: action === 'edit' && listing?.id ? listing?.id : undefined,
    attributes: {
      description: listing?.attributes.description ?? '',
      end:
        listing?.attributes.end && action === 'edit'
          ? formatISO(parseISO(listing?.attributes.end))
          : defaultEndDate,
      external_contact_email: listing?.attributes.external_contact_email ?? '',
      external_link: listing?.attributes.external_link ?? '',
      external_service_id: '',
      external_service_name: '',
      external: listing?.attributes.external ?? false,
      start:
        listing?.attributes.start && action === 'edit'
          ? formatISO(parseISO(listing?.attributes.start))
          : defaultStartDate,
      state:
        listing?.attributes.state && action === 'edit'
          ? listing?.attributes.state
          : 'pending',
      title: getListingTitle(action, listing?.attributes.title),
      visibility: listing?.attributes.visibility ?? 'public',
      user_id: listing?.attributes.user_id ?? user?.id,
      company: listing?.attributes.company ?? user?.employer ?? '',
      company_id:
        listing?.attributes.company_id ?? user?.company_account_id ?? '',
      company_logo:
        listing?.attributes.company_logo ?? user?.account_logo ?? '',
      scheduled: listing?.attributes.start ? 'scheduled' : 'not',
      origin: `${process.env.REACT_APP_APP_URL}/${window.location.pathname}`,
      invite_providers: false,
      admin_client_account_service_users_id:
        listing?.attributes.admin_client_account_service_users_id ?? null,
    },
    contacts: [],
    documents:
      action === 'edit'
        ? documents.map((document) => ({
            state: 'initial',
            value: document,
          }))
        : [],
    services: services
      .filter(
        (
          service
        ): service is ListingService<
          Exclude<Services, ServiceMultiRequirements>
        > => service?.attributes?.type !== 'multi_service_requirements'
      )
      .map((service) => {
        const markets = getMarketsFromService(service.attributes);
        return {
          state: action === 'copy' ? 'add' : 'initial',
          value: {
            service,
            meta: {
              markets,
            },
          },
        };
      }),
    multi_requirements: services
      .filter(
        (service): service is ListingService<ServiceMultiRequirements> =>
          service?.attributes?.type === 'multi_service_requirements'
      )
      .map((service) => {
        return {
          state: action === 'copy' ? 'add' : 'initial',
          value: {
            service,
            meta: {
              markets: [],
            },
          },
        };
      }),
    markets: markets.map((market) => ({
      state: action === 'copy' ? 'add' : 'initial',
      value: market,
    })),
    providers:
      action === 'edit'
        ? providers
            .filter((provider) => !!provider?.attributes?.invited_at)
            .map((provider) => ({
              state: 'initial',
              value: provider,
            }))
        : [],
    provider_contacts:
      action === 'edit'
        ? contacts.map((contact) => ({
            state: 'initial',
            value: contact,
          }))
        : [],
    status: {
      private: listing?.attributes.visibility === 'private',
      gated: false,
    },
  };
};

const CreateListingForm: React.FC<CreateListingProps> = ({
  pageTitle,
  listing,
  onCreate,
  onEdit,
  action = 'create',
}) => {
  const getMarketsFromService = useMarketsFromService();
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { user } = useAuth();
  const { services, providers, contacts, markets, documents } =
    useInitialListingValues(listing);
  const validator = useValidationSchema(validationSchema);
  const {
    user: client,
    company: client_company,
    isFetching,
  } = useUserQuery(listing?.attributes?.admin_client_account_service_users_id);
  const [createListingMutation] = useCreateListingMutation();
  const [updateListingMutation] = useUpdateListingByIDMutation();
  const [cancelListingByIDMutation] = useCancelListingByIDMutation();
  const [createListingProviderMutation] = useCreateListingProviderMutation();
  const [deleteListingProviderMutation] =
    useDeleteListingProviderByListingIDMutation();
  const [createListingProviderContactMutation] =
    useCreateListingProviderContactMutation();
  const [updateListingProviderContactMutation] =
    useUpdateListingProviderContactByIDMutation();
  const [deleteListingProviderContactMutation] =
    useDeleteListingProviderContactByIDMutation();
  const [createListingMarketMutation] = useCreateListingMarketMutation();
  const [deleteListingMarketMutation] = useDeleteListingMarketByIDMutation();
  const messenger = useAppMessenger();

  const handleProviders = useCallback(
    async (state, listing, provider) => {
      if (state === 'add') {
        return await createListingProviderMutation({
          listing: listing,
          payload: provider,
        })
          .unwrap()
          .then(() => {
            messenger.addMessage('info', 'This listing has been updated');
          })
          .catch((err) => {
            if (typeof err === 'object' && err.data) {
              const hasIntendedToBid =
                err.data.errors.findIndex((error) =>
                  error.detail.includes(
                    'claimed that profile has already intended to bid'
                  )
                ) > -1;
              const message = hasIntendedToBid
                ? 'Can not invite provider to listing because the company has already intended to bid'
                : 'An error occurred. Please try again later.';

              messenger.addMessage('error', message);
            }
          });
      }
      if (state === 'delete') {
        return await deleteListingProviderMutation({
          listing: listing,
          provider: provider.id,
        });
      }
    },
    [createListingProviderMutation, deleteListingProviderMutation, messenger]
  );
  const handleProviderContacts = useCallback(
    async (state, listing, provider_contact) => {
      if (state === 'add') {
        return await createListingProviderContactMutation({
          listing: listing,
          payload: provider_contact,
        });
      }
      if (state === 'edit') {
        return await updateListingProviderContactMutation({
          listing: listing,
          contact: provider_contact.id,
          payload: provider_contact,
        });
      }
      if (state === 'delete') {
        return await deleteListingProviderContactMutation({
          listing: listing,
          contact: provider_contact.id,
        });
      }
    },
    [
      createListingProviderContactMutation,
      updateListingProviderContactMutation,
      deleteListingProviderContactMutation,
    ]
  );
  const handleMarkets = useCallback(
    async (state, listing, market) => {
      if (state === 'add') {
        return await createListingMarketMutation({
          listing: listing,
          payload: market,
        });
      }
      if (state === 'delete') {
        return await deleteListingMarketMutation({
          listing: listing,
          market: market.id,
        });
      }
    },
    [createListingMarketMutation, deleteListingMarketMutation]
  );
  const handleRelationships = useCallback(
    async ({
      id,
      values,
      markets,
      services,
      providers,
      contacts,
      documents,
    }: {
      values: CreateListingFormState;
      markets: ListingMarketRequest[];
      services: ListingService<Services>[];
      documents: Document[];
      providers: ListingProvider[];
      id: string;
      contacts: ListingProviderContact[];
    }) => {
      const multi_requirements_services = services.filter(
        (service) => service.attributes.type === 'multi_service_requirements'
      );
      const dispatchedServices =
        values.services.map(({ state }, index) =>
          dispatch(
            dispatchService(state, {
              listing: id,
              service: services[index],
            })
          )
        ) ?? [];

      const dispatchedMultiRequirementServices =
        values.multi_requirements.map(({ state }, index) =>
          dispatch(
            dispatchService(state, {
              listing: id,
              service: multi_requirements_services[index],
            })
          )
        ) ?? [];

      const dispatchedMarkets = values.markets.map(({ state }, index) => {
        if (state === 'add' || state === 'delete') {
          return handleMarkets(state, id, markets[index]);
        }
        return [];
      });

      const dispatchedProviders = values.providers.map(({ state }, index) => {
        if (state === 'add' || state === 'delete') {
          return handleProviders(state, id, providers[index]);
        }
        return [];
      });

      const dispatchedProviderContacts = values.provider_contacts.map(
        ({ state }, index) => {
          if (state !== 'initial') {
            return handleProviderContacts(state, id, contacts[index]);
          }
          return [];
        }
      );

      const dispatchedDocuments: any =
        values.documents.map(({ state }, index) =>
          dispatch(
            dispatchDocuments(state, {
              listing: id,
              document: documents[index],
            })
          )
        ) ?? [];

      return await Promise.all([
        ...dispatchedServices,
        ...dispatchedMultiRequirementServices,
        ...dispatchedMarkets,
        ...dispatchedProviders,
        ...dispatchedProviderContacts,
        ...dispatchedDocuments,
      ]).then(() => {
        dispatch(
          listingsAPI.util.invalidateTags([{ type: 'Listing', id: id }])
        );
      });
    },
    [dispatch, handleProviders, handleProviderContacts, handleMarkets]
  );
  const handleSubmit = useCallback(
    async (values: CreateListingFormState) => {
      const {
        id,
        type,
        attributes,
        services,
        markets,
        providers,
        contacts,
        documents,
      } = createListingDraft(values);

      if (!id) {
        return await createListingMutation({
          id: id,
          payload: {
            id: id,
            type: type,
            attributes,
          },
        })
          .unwrap()
          .then((response) => response.entity)
          .then(async ({ id }) => {
            return await handleRelationships({
              id,
              values,
              markets,
              services,
              providers,
              contacts,
              documents,
            });
          })
          .then(() => {
            onCreate?.();
          });
      } else {
        return await updateListingMutation({
          id: id!,
          payload: {
            id: id,
            type: type,
            attributes,
          },
        })
          .unwrap()
          .then((response) => response.entity)
          .then(async ({ id }) => {
            return await handleRelationships({
              id,
              values,
              markets,
              services,
              providers,
              contacts,
              documents,
            });
          })
          .then(() => {
            onEdit?.();
          });
      }
    },
    [
      createListingMutation,
      onCreate,
      onEdit,
      handleRelationships,
      updateListingMutation,
    ]
  );

  const handleCancel = async (listingId: string) => {
    await cancelListingByIDMutation({ id: listingId })
      .unwrap()
      .then((response) => {
        onEdit?.();
      });
  };

  const getUserName = (listingName?: string, userName?: string) => {
    switch (listingName) {
      case '':
      case undefined:
        return userName;
      default:
        return listingName;
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...getInitialValues({
        listing,
        services,
        providers,
        contacts,
        markets,
        documents,
        user,
        action,
        getMarketsFromService,
      }),
      owner: {
        full_name: getUserName(
          listing?.attributes.contact?.full_name,
          user?.name
        ),
        email: listing?.attributes.contact?.email ?? user?.email ?? '',
        position:
          listing?.attributes.user_id === user?.id ||
          typeof listing === 'undefined'
            ? user?.position ?? ''
            : '',
        phone: listing?.attributes.contact?.phone ?? user?.phone ?? '',
        user_details_agreement: !!listing?.id,
        company_id:
          listing?.attributes.company_id ?? user?.company_account_id ?? null,
      },
      client: client ?? null,
      client_email: client?.attributes.email ?? null,
      client_company: client_company?.attributes.name ?? null,
    };
  }, [
    listing,
    services,
    providers,
    contacts,
    markets,
    documents,
    user,
    action,
    getMarketsFromService,
    client,
    client_company,
  ]);
  const [openModal, setOpenModal] = useState(false);
  const hasCompanyAccountID = !!user?.company_account_id;

  useEffect(() => {
    if (!hasCompanyAccountID) {
      setOpenModal(true);
    }
  }, [hasCompanyAccountID]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (!hasCompanyAccountID) {
    return <CompanyCreateModal open={openModal} />;
  }
  return (
    <Box mb={3}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validator}
        keepDirtyOnReinitialize
        initialValuesEqual={(a, b) => dequal(a, b)}
        subscription={{ pristine: true }}
        decorators={[calculator]}
        mutators={{
          ...arrayMutators,
          addResource,
          editResource,
          duplicateResource,
          deleteResource,
          deleteResources,
        }}
        render={({ form }: FormRenderProps<CreateListingFormState>) => (
          <>
            <Hidden smDown>
              <Box px={1} mb={2}>
                <HeaderBlock title={pageTitle} />
              </Box>
            </Hidden>

            <Grid container direction="column" spacing={6}>
              <Grid item xs>
                <ListingSection
                  heading={
                    <Typography variant="h5" component="div">
                      Listing Details
                    </Typography>
                  }
                >
                  <ListingDetailsSection name="attributes" />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ListingSection
                  heading={
                    <>
                      <Typography variant="h5" component="div">
                        Service Requirements
                      </Typography>
                      <Typography variant="body2" component="div">
                        Add individual services to build out your Marketplace
                        listing.
                      </Typography>
                    </>
                  }
                >
                  <ServiceRequirementsSection />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ListingSection
                  heading={
                    <>
                      <Typography variant="h5" component="div">
                        Markets
                      </Typography>
                      <Typography variant="body2" component="div">
                        Add markets in which you require services.
                      </Typography>
                    </>
                  }
                >
                  <MarketsSection name="markets" />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ListingSection
                  heading={
                    <>
                      <Typography variant="h5" component="div">
                        Service Providers
                      </Typography>
                      <Typography variant="body2" component="div">
                        Select up to 20 service providers you wish to invite to
                        view your Marketplace listing and quote your
                        requirements.
                      </Typography>
                    </>
                  }
                >
                  <ServiceProvidersSection name="providers" />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ListingSection
                  heading={
                    <>
                      <Typography variant="h5" component="div">
                        Attachments
                      </Typography>
                      <Typography variant="body2" component="div">
                        Provide documentation such as spreadsheets to support
                        your listing requirements.
                      </Typography>
                    </>
                  }
                >
                  <AttachmentsSection name="documents" />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ListingSection
                  heading={
                    <>
                      <Typography variant="h5" component="div">
                        Additional Information
                      </Typography>
                      <Typography variant="body2" component="div">
                        Add extra information that will assist service providers
                        to respond with the most accurate quote estimate.
                      </Typography>
                    </>
                  }
                >
                  <AdditionalInfoSection name="attributes.description" />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ListingSection
                  heading={
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h5" component="div">
                          My Details
                        </Typography>
                      </Grid>
                      {user?.csAdmin && (
                        <Grid item xs="auto">
                          <Box display="flex">
                            <div>
                              <Typography
                                className={classes.bold}
                                align="right"
                              >
                                Assign User
                              </Typography>
                              <Typography
                                variant="caption"
                                component="div"
                                gutterBottom
                              >
                                Cloudscene Admin Control
                              </Typography>
                            </div>
                            <Field name="owner.search" type="checkbox">
                              {({ input }) => (
                                <Toggle
                                  {...input}
                                  onChange={(event) => {
                                    if (!event.target.checked) {
                                      form.change('owner', {
                                        full_name: user.name,
                                        email: user.email,
                                        position: user.position,
                                        phone: user.phone,
                                        user_details_agreement: false,
                                        company_id: user.company_account_id,
                                      });
                                    }
                                    input.onChange(event);
                                  }}
                                />
                              )}
                            </Field>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  }
                >
                  <MyDetailsSection name="owner" />
                </ListingSection>
              </Grid>
              <Grid item xs>
                <ConfirmPublishSection name="status" />
              </Grid>

              <Grid item xs>
                <Box my={2}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {listing?.id && action === 'edit' && user?.csAdmin && (
                      <Grid item xs={3}>
                        <FormSpy subscription={{ submitting: true }}>
                          {({
                            submitting,
                          }: FormSpyRenderProps<CreateListingFormState>) => (
                            <CancelListingConfirmation
                              submitting={submitting}
                              onConfirm={() => handleCancel(listing.id)}
                            />
                          )}
                        </FormSpy>
                      </Grid>
                    )}

                    <Grid item xs={3}>
                      <FormSpy subscription={{ values: true, valid: true }}>
                        {({
                          values,
                          valid,
                        }: FormSpyRenderProps<CreateListingFormState>) => (
                          <PreviewListing listing={values} disabled={!valid} />
                        )}
                      </FormSpy>
                    </Grid>
                    <Grid item xs={3}>
                      <FormSpy
                        subscription={{
                          valid: true,
                          submitting: true,
                          pristine: true,
                        }}
                      >
                        {({ form, valid, submitting, pristine }) => {
                          if (!listing || action !== 'edit') {
                            return (
                              <PublishListing
                                onSubmit={() => {
                                  form.submit();
                                }}
                                disabled={submitting || pristine || !valid}
                                submitting={submitting}
                              />
                            );
                          }

                          // A `draft` listing hasn't been saved yet from the user's point-of-view
                          // so it follows the `PublishListing` flow.
                          if (listing.attributes.state === 'draft') {
                            return (
                              <PublishListing
                                onSubmit={() => {
                                  form.submit();
                                }}
                                disabled={submitting || !valid} // the pristine draft _may_ be valid for publishing immediately
                                submitting={submitting}
                              />
                            );
                          }

                          // Have listing and not in draft state means we display the confirmation prompt.
                          return (
                            <UpdateListingConfirmation
                              onConfirm={() => form.submit()}
                              valid={valid}
                              submitting={submitting}
                            />
                          );
                        }}
                      </FormSpy>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <FormSpy subscription={{ submitting: true }}>
              {({ submitting }: FormSpyRenderProps) => (
                <Zoom in={submitting}>
                  <Backdrop open className={classes.backdrop}>
                    <LogoLoader />
                  </Backdrop>
                </Zoom>
              )}
            </FormSpy>
          </>
        )}
      />
    </Box>
  );
};

export default CreateListingForm;
