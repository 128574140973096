import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  Typography,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form, Field, FormRenderProps } from 'react-final-form';
import CSTextField from '../../Form/CSTextField';
import validationSchema from './validation';
import { ProviderContact } from '../../../types/provider-contact';
import { useAuth } from '../../../hooks/useAuth';
import { useValidationSchema } from '../../../hooks/useValidationSchema';
import { ProviderGatewayServiceProvider } from '../../../types/provider-gateway';

export type ProviderContactFormState = {
  contact: ProviderContact;
  hasContactAuthorization: boolean;
};

interface ProviderContactFormProps {
  open?: boolean;
  onClose?: () => void;
  onDone?: (values: ProviderContactFormState) => void;
  provider: ProviderGatewayServiceProvider;
  contact?: ProviderContact;
}

const useStyles = makeStyles((theme: Theme) => ({
  logoWrap: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
    flexWrap: 'nowrap',
  },
  logoWrapInner: {
    width: 50,
    height: 50,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '50%',
    background: 'transparent no-repeat center center',
    backgroundSize: '40px',

    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
      backgroundSize: '50px',
    },
  },
  companyName: {
    maxWidth: 250,
    paddingLeft: theme.spacing(2),
  },
  close: {
    color: theme.palette.grey[500],
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const getInitialContact = (
  provider: string,
  user: string = ''
): ProviderContact => ({
  type: 'provider-contact',
  id: '',
  attributes: {
    user_id: user,
    provider_id: provider,
    first_name: '',
    last_name: '',
    email: '',
    default_contact: false,
  },
});

export const GatewayProviderContactForm: React.FC<ProviderContactFormProps> = ({
  open = false,
  onClose = () => {},
  onDone = () => {},
  provider,
  contact,
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const validator = useValidationSchema(validationSchema);

  return (
    <Dialog
      open={open}
      onClose={(event, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown')
          return false;
        onClose();
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h4">
          {!contact ? 'Add New Vendor Contact' : 'Edit Vendor Contact'}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box my={2}>
        <DialogContent>
          <Form
            initialValues={{
              contact: contact ?? getInitialContact(provider.id, user?.id),
              hasContactAuthorization: false,
            }}
            validate={validator}
            onSubmit={(values: ProviderContactFormState) => {
              onDone(values);
            }}
            keepDirtyOnReinitialize
            subscription={{ submitting: true, pristine: true }}
          >
            {({ handleSubmit }: FormRenderProps<ProviderContactFormState>) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.logoWrap}>
                  <div
                    className={classes.logoWrapInner}
                    style={{
                      backgroundImage: `url(${provider.logo})`,
                    }}
                  />
                  <Typography
                    variant="h5"
                    component="h3"
                    className={classes.companyName}
                  >
                    {provider.name}
                  </Typography>
                </div>
                <Box py={2}>
                  <Typography variant="body1" gutterBottom>
                    To ensure your Marketplace quote request is sent only to
                    your vendor contact, please complete the details below.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    This is optional. If you don&apos;t allocate a contact, the
                    vendor will receive your quote through the business
                    development contact they&apos;ve allocated on Cloudscene.
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      name="contact.attributes.first_name"
                      render={({ input, meta }) => (
                        <>
                          <CSTextField
                            label="First Name"
                            {...input}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="contact.attributes.last_name"
                      render={({ input, meta }) => (
                        <CSTextField
                          label="Last Name"
                          {...input}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="contact.attributes.email"
                      type="email"
                      render={({ input, meta }) => (
                        <CSTextField
                          label="Email Address"
                          {...input}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="hasContactAuthorization"
                      type="checkbox"
                      render={({ input, meta }) => (
                        <>
                          {meta.touched && meta.error && (
                            <FormHelperText error>
                              Authorization is required to proceed
                            </FormHelperText>
                          )}
                          <FormControlLabel
                            control={<Checkbox color="primary" {...input} />}
                            label="This person has authorized me to provide their contact details for this purpose."
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="contact.attributes.default_contact"
                      type="checkbox"
                      render={({ input }) => (
                        <FormControlLabel
                          control={<Checkbox color="primary" {...input} />}
                          label={`Make this person the default direct contact for ${provider.name}.`}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box my={2} mx="auto" width="200px">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Done
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
