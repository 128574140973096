import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import CreateListing from '../../../containers/CreateListing';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import { getPageTitleTemplate } from '../../../constants';
import { ROUTES } from '../../../routes';

const CreateNewListing: React.FunctionComponent<
  RouteComponentProps & {
    title?: string;
  }
> = ({ title = 'New Marketplace Listing', navigate }) => {
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        <CreateListing
          pageTitle="New Marketplace Listing"
          onCreate={() => navigate?.(ROUTES.listingSuccess)}
        />
      </AppLayout>
    </>
  );
};

export default CreateNewListing;
