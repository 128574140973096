import React, { createContext } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { ListingProviderResponse } from '../../../types/marketplace-service/listing-provider';
import { useListingInfo } from './useListingInfo';
import { ListingResponse } from '../../../types/marketplace-service/listing';
import { BidResponse } from '../../../types/marketplace-service/bid';

export const ListingInfoContext = createContext<{
  listing?: ListingResponse;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  bids?: BidResponse[];
  opportunity?: ListingProviderResponse;
}>({
  listing: undefined,
  opportunity: undefined,
  bids: undefined,
  isFetching: false,
  isSuccess: false,
  isError: false,
});

const ListingInfoContainer: React.FC<{
  id?: string;
  setTrackView?: boolean;
}> = ({ id, setTrackView, children }) => {
  const { listing, opportunity, bids, isFetching, isSuccess, isError } =
    useListingInfo(id, setTrackView);

  if (isFetching) {
    return (
      <Box py={5}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <CircularProgress />
        </Grid>
      </Box>
    );
  }

  if (!listing) {
    return null;
  }

  return (
    <ListingInfoContext.Provider
      value={{ listing, opportunity, bids, isFetching, isSuccess, isError }}
    >
      {children}
    </ListingInfoContext.Provider>
  );
};

export default ListingInfoContainer;
