import React from 'react';
import { RouteComponentProps, useMatch } from '@reach/router';
import Helmet from 'react-helmet';
import BuyingNavigation from './navigation';
import { getPageTitleTemplate } from '../../../constants';
import { ROUTES } from '../../../routes';
import MarketplaceLayout from '../../../containers/MarketplaceLayout';
export {
  default as BuyingCompleted,
  BuyingCompletedContainer,
} from './completed';
export {
  default as BuyingPending,
  BuyingPendingContainer,
} from './buyerpending';
export { default as CreateNewListing } from './create-new-listing';
export { default as RequestQuote } from './request-quote';
export { default as EditListing } from './edit-listing';
export { default as ViewListing } from './view-listing';
export { default as CopyListing } from './copy-listing';

const BuyingPage: React.FunctionComponent<
  RouteComponentProps & {
    title?: string;
  }
> = ({ title = 'My Marketplace', children }) => {
  const root = useMatch(`${ROUTES.buyingDashboard}`);
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <MarketplaceLayout
        title={title}
        isRoot={root ? true : false}
        navigationComponent={<BuyingNavigation />}
      >
        {children}
      </MarketplaceLayout>
    </>
  );
};

export default BuyingPage;
