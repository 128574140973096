import { object, string, boolean, mixed } from 'yup';

export const schema = object({
  contact: object({
    type: string(),
    id: string(),
    attributes: object({
      provider_id: string(),
      user_id: string(),
      first_name: string().trim().required().label('First Name'),
      last_name: string().trim().required().label('Last Name'),
      email: string().trim().email().required().label('Email'),
      default_contact: mixed(),
    }).defined(),
  }).defined(),
  hasContactAuthorization: boolean().required().oneOf([true]),
}).defined();

export default schema;
