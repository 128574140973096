import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useActingUser, ActingContextType } from '../../hooks/useActingUser';
import AdminTeamMemberSearch from '../AdminTeamMemberSearch';

type ActOnBehalfProps = {
  children: (args: {
    open: boolean;
    onToggle: (state?: boolean) => void;
    state: ActingContextType;
  }) => React.ReactNode;
};

const ActOnBehalf = ({ children }: ActOnBehalfProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const actingUserState = useActingUser();
  const onToggle = React.useCallback((state?: boolean) => {
    setOpen((val) => state ?? !val);
  }, []);

  return (
    <>
      {children({ open, onToggle, state: actingUserState })}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={(event, reason: string) => {
          if (reason === 'backdropClick') return false;
          setOpen(false);
        }}
      >
        <DialogTitle disableTypography>
          <Typography variant="h2" component="div" align="center">
            Act on Behalf
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AdminTeamMemberSearch
            onChange={({
              user,
              account,
              profiles,
              permissions,
              roles,
              companyPackage,
            }) => {
              actingUserState.startActing({
                user,
                account,
                profiles,
                permissions,
                roles,
                companyPackage,
              });
              setOpen(false);
            }}
            optionType="table"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActOnBehalf;
