import { createApi } from '@reduxjs/toolkit/query/react';
import { ACCOUNT_SERVICE_SUBSCRIPTION_API } from '../../../api';
import { staggeredBaseQueryWithBailOut } from '../fetch-base-query';

export const accountServiceSubscriptionAPI = createApi({
  reducerPath: 'accountServiceSubscriptionAPI',
  baseQuery: staggeredBaseQueryWithBailOut(ACCOUNT_SERVICE_SUBSCRIPTION_API),
  tagTypes: [],
  endpoints: (builder) => ({
    createUserSession: builder.mutation<
      { url: string },
      {
        interval: 'monthly' | 'yearly';
        successUrl?: string;
        cancelUrl?: string;
      }
    >({
      query: (args) => ({
        method: 'POST',
        url: '/userCheckoutSessionUrl',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
        body: {
          interval: args.interval,
          successUrl: args.successUrl,
          cancelUrl: args.cancelUrl,
        },
      }),
    }),
    createUserPortal: builder.mutation<{ url: string }, void>({
      query: () => ({
        method: 'POST',
        url: `/userPortalSessionUrl`,
      }),
    }),
  }),
});

export const {
  useCreateUserPortalMutation,
  useCreateUserSessionMutation,
  usePrefetch,
} = accountServiceSubscriptionAPI;
