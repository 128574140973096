import { useReadCompanyAccountByIDQuery } from '../services/api/account-service/company-accounts';

export const useCompanyQuery = (company?: string) => {
  const companyAccountQuery = useReadCompanyAccountByIDQuery(
    { id: company! },
    { skip: !company }
  );

  return companyAccountQuery;
};
