import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ServiceProviderInfoChip from './ServiceProviderInfoChip';
import ListingStatusLabel, {
  StatusLabels,
} from '../ListingStatusLabel/ListingStatusLabel';
import { useReadCompanyProfileByIDQuery } from '../../services/api/directory-service/company-profiles';
import { CompanyProfileResponse } from '../../types/directory-service/company-profile';
import { useReadServiceProviderContactByIDQuery } from '../../services/api/marketplace-service/service-provider-contacts';
import { useReadServiceProviderContactByIDQuery as useGQLReadServiceProviderContactByIDQuery } from '../../services/api/marketplace-service/gql/service-provider-contacts';
import { ProviderContact } from '../../types/marketplace-service/gql/service-provider-contact';
import { useFlagsmithFeature } from '@cloudscene-dev/flagsmith-react';
import { FLAGSMITH_FEATURES } from '../../utilities/flagsmith-config';
import { ServiceProviderContactAttributes } from '../../types/marketplace-service/service-provider-contact';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    position: 'relative',
    borderRadius: 8,
  },
  logo: {
    width: 50,
    height: 50,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
  },
  logoImg: {
    width: 30,

    [theme.breakpoints.up(600)]: {
      width: 40,
    },
  },
  rawLogoCell: {
    width: 50,
    height: 50,
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '25%',
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.up(600)]: {
      width: 50,
      height: 50,
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
}));

export interface ServiceProviderInfoDisplayProps {
  provider: CompanyProfileResponse;
  contact?: ProviderContact | ServiceProviderContactAttributes;
  status?: keyof typeof StatusLabels | null;
}

export const ServiceProviderInfoDisplay: React.FC<ServiceProviderInfoDisplayProps> =
  ({ provider, contact, status, children }) => {
    const classes = useStyles();

    return (
      <Paper elevation={4} className={classes.root}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center">
              {provider?.attributes.logo && (
                <Grid
                  container
                  alignContent="center"
                  justifyContent="center"
                  item
                  className={classes.rawLogoCell}
                >
                  <img
                    src={provider?.attributes.logo}
                    alt={provider?.attributes.name}
                    className={classes.logoImg}
                  />
                </Grid>
              )}
              <Grid item xs>
                <Typography variant="body1" className={classes.name}>
                  {provider?.attributes.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {contact && (
                <Grid item xs="auto" data-testid="provider-contact">
                  <Typography variant="body1" align="right">
                    <strong>
                      {contact.first_name}
                      {contact.last_name}
                    </strong>
                  </Typography>
                  <Typography variant="body2" align="right">
                    {contact.email}
                  </Typography>
                </Grid>
              )}
              {status && (
                <Grid item xs="auto">
                  <ListingStatusLabel
                    variant={status}
                    data-test-id="status-label"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {children && (
          <Grid container wrap="nowrap">
            <Grid item>
              <div style={{ width: '50px' }} />
            </Grid>
            <Grid item xs="auto">
              {children}
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  };

const ServiceProviderInfo: React.FC<{
  provider: string;
  contact?: string;
  variant?: 'block' | 'chip';
  onDelete?: () => void;
  status?: keyof typeof StatusLabels | null;
}> = ({ provider, contact, variant = 'block', onDelete, status, children }) => {
  const useGraphQLPOC = useFlagsmithFeature(FLAGSMITH_FEATURES.GRAPHQL_POC);

  const { data } = useReadServiceProviderContactByIDQuery(
    {
      id: contact!,
    },
    {
      skip: !contact || useGraphQLPOC,
    }
  );
  const providerContact = useGQLReadServiceProviderContactByIDQuery(
    {
      id: contact!,
    },
    {
      skip: !contact || !useGraphQLPOC,
    }
  );

  const contactDetails = useGraphQLPOC
    ? providerContact?.data?.providerContact
    : data?.entity?.attributes;

  const companyProfileQuery = useReadCompanyProfileByIDQuery({
    id: provider,
  });

  if (!companyProfileQuery?.data) {
    return null;
  }
  return variant === 'chip' ? (
    <ServiceProviderInfoChip
      provider={companyProfileQuery.data?.entity}
      onDelete={onDelete}
      contact={contactDetails}
    />
  ) : (
    <ServiceProviderInfoDisplay
      provider={companyProfileQuery.data?.entity}
      contact={contactDetails}
      status={status}
    >
      {children}
    </ServiceProviderInfoDisplay>
  );
};

export default ServiceProviderInfo;
