import React, { useState } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Link,
  Collapse,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeft from '@material-ui/icons/ChevronLeftRounded';
import AddServiceButtons from './Buttons';
import ServiceRequirements from '../ServiceRequirements';
import {
  Services,
  ServiceTypes,
  ServiceTypeKeys,
} from '../../../types/services';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    content: {
      maxHeight: 'auto',
    },
    close: {
      color: theme.palette.grey[500],
    },
  })
);

interface AddServiceProps {
  open?: boolean;
  allowMultiRequirements?: boolean;
  onClose?: () => void;
  onDone?: (values: Services) => void;
}

const AddServices: React.FC<AddServiceProps> = ({
  open = false,
  allowMultiRequirements = true,
  onClose = () => {},
  onDone = () => {},
}) => {
  const classes = useStyles();
  const [service, setService] = useState<ServiceTypeKeys>(
    ServiceTypes.ethernet
  );
  const [view, setView] = useState<'buttons' | 'service'>('buttons');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      TransitionProps={{ onExited: () => setView('buttons') }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h4">Add Services</Typography>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Collapse in={view === 'buttons'}>
          <AddServiceButtons
            onServiceSelect={(id: ServiceTypeKeys) => {
              setService(id);
              setView('service');
            }}
          />
          {allowMultiRequirements && (
            <Typography
              variant="subtitle2"
              component={Box}
              fontWeight="fontWeightBold"
              textAlign="center"
              py={2}
            >
              Looking for more services?{' '}
              <Link
                onClick={() => {
                  setService(ServiceTypes.multi_service_requirements);
                  setView('service');
                }}
              >
                Add other requirements
              </Link>
            </Typography>
          )}
        </Collapse>

        <Collapse in={view === 'service'}>
          <Link onClick={() => setView('buttons')}>
            <Box display="inline-flex" alignItems="center">
              <ChevronLeft fontSize="small" />
              Back
            </Box>
          </Link>
          <ServiceRequirements onDone={onDone} type={service} />
        </Collapse>
      </DialogContent>
    </Dialog>
  );
};

export default AddServices;
