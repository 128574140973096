import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import tw, { theme } from 'twin.macro';
import {
  Headline,
  Typography,
} from '../../../../components/Cloudscene/Typography';

export const Features = () => {
  return (
    <div css={tw`flex flex-col gap-4`}>
      <Headline>Become a Sales Pro, and take the lead!</Headline>

      <ul css={tw`flex flex-col gap-2 p-2`}>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>Bid on any public listing</Typography>
        </li>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>See full listing requirements</Typography>
        </li>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>
            Get notified of any relevant new listings instantly
          </Typography>
        </li>
      </ul>
    </div>
  );
};
