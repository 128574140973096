import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Pill from '../Pill';
import Chip from '@material-ui/core/Chip';

interface ListingStatePillProps {
  hasDeclined: boolean;
  isInvited: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  declined: {
    background: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const ListingStatePill: React.FC<ListingStatePillProps> = ({
  hasDeclined,
  isInvited,
}) => {
  const classes = useStyles();

  return (
    <>
      {isInvited && <Pill label="Invited" variant="outlined" />}
      {hasDeclined && (
        <Chip
          className={classes.declined}
          label="Declined / No Bid"
          variant="outlined"
        />
      )}
    </>
  );
};

export default ListingStatePill;
