import React from 'react';
import { RouteComponentProps, useMatch } from '@reach/router';
import Helmet from 'react-helmet';
import { getPageTitleTemplate } from '../../../constants';
import SellingNavigation from './navigation';
import { ROUTES } from '../../../routes';
import MarketplaceLayout from '../../../containers/MarketplaceLayout';
export { default as SellingCompleted } from './completed';
export { default as SellingOpportunities } from './opportunities';
export { default as SellingPending } from './pending';
export { default as ViewBid } from './view-bid';
export { default as SellingContainer } from './selling-container';
export { default as SellingQualified } from './qualified-opportunities';
export { default as SellingClosedLost } from './closed-lost';
export { default as SellingNoBidNoResponse } from './no-bid-no-response';
export { default as SellingLeads } from './leads';

const SellingPage: React.FunctionComponent<
  RouteComponentProps & {
    title?: string;
  }
> = ({ title = 'My Marketplace', children }) => {
  const root = useMatch(`${ROUTES.sellingDashboard}`);
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <MarketplaceLayout
        title={title}
        isRoot={root ? true : false}
        navigationComponent={<SellingNavigation />}
      >
        {children}
      </MarketplaceLayout>
    </>
  );
};

export default SellingPage;
