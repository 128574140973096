import { ReactNode } from 'react';
import tw from 'twin.macro';

export const Adornment = ({
  start,
  end,
  children,
}: {
  start?: ReactNode;
  children: ReactNode;
  end?: ReactNode;
}) => (
  <div
    css={tw`flex space-x-2 items-center text-current pointer-events-none -mx-1`}
  >
    {start && <span>{start}</span>}
    <span css={[start && tw`pr-1`, end && tw`pl-1`]}>{children}</span>
    {end && <span>{end}</span>}
  </div>
);
