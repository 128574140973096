import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { getServiceUIName } from '../../../constants';
import { ServiceTypes, ServiceTypeKeys } from '../../../types/services';

export interface AddServiceButtonProps {
  onServiceSelect: (service: ServiceTypeKeys) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: 160,
    },
  })
);

const AddServiceButtons: React.FC<AddServiceButtonProps> = ({
  onServiceSelect,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box my={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Connectivity</Typography>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onServiceSelect(ServiceTypes.ethernet)}
              className={classes.button}
            >
              {getServiceUIName(ServiceTypes.ethernet)}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onServiceSelect(ServiceTypes.ip_transit)}
              className={classes.button}
            >
              {getServiceUIName(ServiceTypes.ip_transit)}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onServiceSelect(ServiceTypes.dark_fiber)}
              className={classes.button}
            >
              {getServiceUIName(ServiceTypes.dark_fiber)}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onServiceSelect(ServiceTypes.internet_access)}
              className={classes.button}
            >
              {getServiceUIName(ServiceTypes.internet_access)}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onServiceSelect(ServiceTypes.wavelength)}
              className={classes.button}
            >
              {getServiceUIName(ServiceTypes.wavelength)}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Colocation</Typography>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onServiceSelect(ServiceTypes.colocation)}
              className={classes.button}
            >
              {getServiceUIName(ServiceTypes.colocation)}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddServiceButtons;
