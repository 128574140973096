import React from 'react';
import ActionMenu from '../../components/ActionMenu';
import { ListingOpportunityContext } from './ListingOpportunityManager';

const ListingOpportunityActionMenu: React.FC<{}> = () => {
  return (
    <ListingOpportunityContext.Consumer>
      {({
        onAssign,
        onRequestToBid,
        onMoreInfo,
        onContact,
        onEditListing,
        onViewListing,
        onCopyListing,
        allowedActions,
      }) => {
        return (
          <ActionMenu
            iconButtonProps={{ edge: 'end', disabled: !allowedActions.length }}
          >
            {onMoreInfo && (
              <ActionMenu.Item divider onClick={onMoreInfo}>
                More information
              </ActionMenu.Item>
            )}
            {onContact && (
              <ActionMenu.Item divider onClick={onContact}>
                Contact
              </ActionMenu.Item>
            )}
            {onRequestToBid && (
              <ActionMenu.Item divider onClick={onRequestToBid}>
                Request to Bid
              </ActionMenu.Item>
            )}
            {onAssign && (
              <ActionMenu.Item divider onClick={onAssign}>
                Assign
              </ActionMenu.Item>
            )}
            {onEditListing && (
              <ActionMenu.Item divider onClick={onEditListing}>
                Edit Listing
              </ActionMenu.Item>
            )}
            {onViewListing && (
              <ActionMenu.Item divider onClick={onViewListing}>
                View Listing
              </ActionMenu.Item>
            )}
            {onCopyListing && (
              <ActionMenu.Item divider onClick={onCopyListing}>
                Share Listing
              </ActionMenu.Item>
            )}
          </ActionMenu>
        );
      }}
    </ListingOpportunityContext.Consumer>
  );
};

export default ListingOpportunityActionMenu;
