import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useAppMessenger } from '../../store/messenger';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme } from '@material-ui/core/styles';
import RateLimitModalComponent from '../../components/RateLimitModalComponent';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.contrastText,
  },
  button: {
    padding: '0 10px',
    minWidth: 'auto',
  },
}));

const AppMessenger: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const messenger = useAppMessenger();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    messenger.messages.forEach((message) => {
      if (message.type === 'dialog') {
        setOpen(true);
      } else {
        enqueueSnackbar(message.description, {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
          variant: message.variant,
          action: (key) => (
            <Button
              className={classes.button}
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon
                fontSize="small"
                className={classes.icon}
                color="primary"
              />
            </Button>
          ),
        });
      }
      messenger.removeMessage(message.id);
    });
  }, [
    messenger.messages.length,
    classes,
    closeSnackbar,
    enqueueSnackbar,
    messenger,
  ]);

  return <RateLimitModalComponent open={open} onClose={() => setOpen(false)} />;
};

export default AppMessenger;
