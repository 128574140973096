import { getFeatureUIName } from '../constants';
import {
  ServiceTypeKeys,
  Services,
  PhysicalAddress,
  PointA,
  PointZ,
} from '../types/services';
import {
  Category,
  SubCategory,
  FacilityQuery,
  AddressQuery,
  MarketQuery,
  ProductStatusLabels,
} from '../types/marketplace-match';
import { ProductStatusType } from '../types/service-provider';
import {
  PresenceType,
  ProviderGatewayProductDetails,
} from '../types/provider-gateway';
import { hasPoint, isAddress, isFacility, isMarketOption } from './services';

export const formatAddressForSearch = (
  place?: PhysicalAddress | null
): FacilityQuery | AddressQuery | MarketQuery | null => {
  if (!place) {
    return null;
  } else if (isAddress(place)) {
    return {
      transactionId: 'transactionId',
      placeId: place.id,
    };
  } else if (isFacility(place)) {
    if (!place.dc_id) {
      return null;
    } else {
      return {
        type: 'datacenter',
        id: place.dc_id,
      };
    }
  } else if (isMarketOption(place)) {
    return {
      type: 'market',
      id: place.id,
    };
  }

  return null;
};

export const formatCategoryForSearch = (
  type: ServiceTypeKeys
): { category: Category; subCategory: SubCategory[] } | null => {
  if (!type) return null;
  if (type === 'ethernet' || type === 'wavelength' || type === 'dark_fiber') {
    return {
      category: 'Point to Point' as Category,
      subCategory: [getFeatureUIName(type)] as SubCategory[],
    };
  }

  if (type === 'colocation') {
    return {
      category: getFeatureUIName(type) as Category,
      subCategory: [],
    };
  }

  if (type === 'internet_access') {
    return {
      category: 'Internet',
      subCategory: [getFeatureUIName(type) as SubCategory],
    };
  }
  if (type === 'ip_transit') {
    return {
      category: 'Internet',
      subCategory: ['Transit' as SubCategory],
    };
  }

  return null;
};

export type TypeKey = 'd' | 'p' | 'm' | 'r';
export const getTypeKeyForProductQuery = (point: PointA | PointZ): TypeKey => {
  if (isFacility(point)) {
    return 'd';
  } else if (isAddress(point)) {
    return 'p';
  } else if (isMarketOption(point)) {
    if (!point.marketType) {
      return 'r';
    }
    return point.marketType === 'market' ? 'm' : 'r';
  }
  return 'r';
};

export const productQueryParam = (service: Services, index: number): string => {
  const serviceType = service.type;

  let query = index === 0 ? '' : '&';
  if (hasPoint(service)) {
    const point_a = service.attributes.point_a;
    const point_z = service.attributes.point_z;

    if (point_a) {
      const a_end_type_key = getTypeKeyForProductQuery(point_a);
      const a_end_entity_id = isFacility(point_a) ? point_a.dc_id : point_a.id;
      query += `products[${serviceType}][${index}][a][${a_end_type_key}]=${a_end_entity_id}`;
    }

    if (point_z) {
      const z_end_type_key = getTypeKeyForProductQuery(point_z);
      const z_end_entity_id = isFacility(point_z) ? point_z.dc_id : point_z.id;
      if (z_end_entity_id) {
        query += `&products[${serviceType}][${index}][b][${z_end_type_key}]=${z_end_entity_id}`;
      }
    }
  }

  return query;
};
export const getProductLabelVariant = (
  aEndStatus: ProductStatusType | PresenceType,
  zEndStatus?: ProductStatusType | PresenceType
): keyof typeof ProductStatusLabels => {
  if (zEndStatus === undefined) {
    if (aEndStatus === '' || aEndStatus === 'pops') return 'off';
    return aEndStatus;
  } else {
    if (aEndStatus === 'on' && zEndStatus.length === 0) return 'off';
    if (aEndStatus === 'onNet' && zEndStatus.length === 0) return 'off';
    if (aEndStatus === 'on' && zEndStatus === 'off') return 'off';
    if (aEndStatus === 'onNet' && zEndStatus === 'off') return 'off';
    if ((aEndStatus === 'off' || !aEndStatus) && zEndStatus === 'on')
      return 'off';
    if ((aEndStatus === 'offNet' || !aEndStatus) && zEndStatus === 'onNet')
      return 'off';
    if (aEndStatus === 'near' && (zEndStatus === 'near' || zEndStatus === 'on'))
      return 'near';
    if (
      aEndStatus === 'nearNet' &&
      (zEndStatus === 'nearNet' || zEndStatus === 'onNet')
    )
      return 'near';
    if ((aEndStatus === 'near' || aEndStatus === 'on') && zEndStatus === 'near')
      return 'near';
    if (
      (aEndStatus === 'nearNet' || aEndStatus === 'onNet') &&
      zEndStatus === 'nearNet'
    )
      return 'near';
    if (aEndStatus === 'on' && zEndStatus === 'on') return 'on';
    if (aEndStatus === 'onNet' && zEndStatus === 'onNet') return 'on';
    if (aEndStatus === 'pops' && zEndStatus === 'on') return 'off';
    if (aEndStatus === 'pops' && zEndStatus === 'onNet') return 'off';
    if (aEndStatus === 'pops' || zEndStatus === 'pops') return 'off';
  }
  return 'off';
};

export const getProductLabelTextVariant = (
  aEndStatus: ProductStatusType | PresenceType,
  zEndStatus?: ProductStatusType | PresenceType
): keyof typeof ProductStatusLabels => {
  if (zEndStatus === undefined) {
    if (aEndStatus === '') return 'off';
    if (aEndStatus === 'pops') return 'on';
    return aEndStatus;
  } else {
    if (aEndStatus === 'on' && zEndStatus.length === 0) return 'off';
    if (aEndStatus === 'on' && zEndStatus === 'off') return 'off';
    if ((aEndStatus === 'off' || !aEndStatus) && zEndStatus === 'on')
      return 'off';
    if (aEndStatus === 'near' && (zEndStatus === 'near' || zEndStatus === 'on'))
      return 'near';
    if ((aEndStatus === 'near' || aEndStatus === 'on') && zEndStatus === 'near')
      return 'near';
    if (
      (aEndStatus === 'on' || aEndStatus === 'pops') &&
      (zEndStatus === 'on' || zEndStatus === 'pops')
    )
      return 'on';
  }
  return 'off';
};

export const sumScoreReducer = (
  acc: number,
  item: ProviderGatewayProductDetails
) => {
  const score = item.score ?? 0;
  return acc + score;
};
