import { styled } from 'twin.macro';
import { primary, secondary, base, ghost } from './styles';

export default styled.a(
  ({
    variant = 'primary',
  }: {
    variant?: 'primary' | 'secondary' | 'ghost';
  }) => [
    ...base,
    variant === 'primary' && [...primary],
    variant === 'secondary' && [...secondary],
    variant === 'ghost' && [...ghost],
  ]
);
