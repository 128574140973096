import React from 'react';
import { formatISO, parseISO } from 'date-fns';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, FormSpy, FormSpyRenderProps } from 'react-final-form';
import PlacesAutocomplete from '../../../Form/PlacesAutocomplete';
import { SelectFieldPlaceholder as SelectField } from '../../../Form/CSSelectField';
import TextField from '../../../Form/CSTextField';
import CSDateField from '../../../Form/CSDateField';
import {
  ServiceBandwidth,
  ServiceBandwidthUnits,
  ServiceContractTerm,
  ServiceContractUnits,
  ServiceWavelength,
} from '../../../../types/services';
import MarketPlaceMatchInfo from '../../../MarketPlaceMatchInfo';
import {
  standardBandWidthOptionsForWavelength,
  standardContractTermOptions,
} from '../../../../constants';

const WavelengthBasic: React.FC<{ minRfsDate: Date }> = React.memo(
  ({ minRfsDate }) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.external_service_name">
            {({ input }) => (
              <TextField label="Circuit Name (Optional)" {...input} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.external_service_id">
            {({ input }) => (
              <TextField label="Service ID (Optional)" {...input} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.point_a">
            {({ input }) => (
              <PlacesAutocomplete
                label="A-End Location *"
                selected={input.value}
                onSelect={(place) => input.onChange(place)}
              />
            )}
          </Field>
          <FormSpy
            subscription={{
              values: true,
            }}
          >
            {({ values }: FormSpyRenderProps<ServiceWavelength>) => (
              <MarketPlaceMatchInfo
                aEnd={values.attributes.point_a}
                serviceType={values.type}
              />
            )}
          </FormSpy>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.point_z">
            {({ input }) => (
              <PlacesAutocomplete
                label="B-End Location *"
                selected={input.value}
                onSelect={(place) => input.onChange(place)}
              />
            )}
          </Field>
          <FormSpy
            subscription={{
              values: true,
            }}
          >
            {({ values }: FormSpyRenderProps<ServiceWavelength>) => (
              <MarketPlaceMatchInfo
                aEnd={values.attributes.point_z}
                serviceType={values.type}
              />
            )}
          </FormSpy>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="contract_term"
            parse={(term: string): ServiceContractTerm => {
              const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];

              return {
                value: Number(value),
                unit: unit as ServiceContractUnits,
              };
            }}
            format={(term: ServiceContractTerm) => {
              return term ? `${term.value}${term.unit}` : '';
            }}
          >
            {({ input }) => (
              <SelectField label="Contract Term *" {...input}>
                {standardContractTermOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="attributes.bandwidth"
            parse={(bandwidth: string): ServiceBandwidth => {
              const [, value, unit] = bandwidth.match(/(\d{1,})(\w{4})/) || [];

              return {
                value: Number(value),
                unit: unit as ServiceBandwidthUnits,
              };
            }}
            format={(bandwidth: ServiceBandwidth) => {
              return bandwidth ? `${bandwidth.value}${bandwidth.unit}` : '';
            }}
          >
            {({ input }) => (
              <SelectField label="Bandwidth *" {...input}>
                {standardBandWidthOptionsForWavelength.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="ready_for_service"
            parse={(value) => (value ? formatISO(value) : '')}
            format={(value) => (value ? parseISO(value) : '')}
          >
            {({ input }) => (
              <CSDateField minDate={minRfsDate} label="RFS Date *" {...input} />
            )}
          </Field>
        </Grid>
      </Grid>
    );
  }
);

export default WavelengthBasic;
