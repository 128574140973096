import {
  createEntityAdapter,
  createSlice,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import {
  UserResponse,
  UserResponseIncludes,
} from '../../types/account-service/users';
import { companyAccountsAPI } from '../../services/api/account-service/company-accounts';
import { RequestContext } from '../../types/entity-collection';

const userAdapter = createEntityAdapter<UserResponse>();

const initialState: {
  entities: EntityState<UserResponse>;
  context: RequestContext<UserResponseIncludes>;
} = {
  entities: userAdapter.getInitialState(),
  context: {},
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      companyAccountsAPI.endpoints.readCompanyAccountUsers.matchFulfilled,
      (state, action) => {
        userAdapter.setAll(state.entities, action.payload.entities);
        state.context = action.payload.context;
      }
    );
  },
});

export const selectUserContext = (state: RootState) => state.users.context;

export const { selectAll: selectAllUsers, selectEntities } =
  userAdapter.getSelectors((state: RootState) => state.users.entities);

export const selectUserById = (id?: string | null) =>
  createSelector(selectEntities, (users) => (id ? users[id] : undefined));

export default users.reducer;
