import React, { ReactNode } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useAppState } from '../../contexts/AppState';
import { ListingCardProps } from '../ListingCard/ListingCard';
import ListingGroupProps from '../ListingGroup/ListingGroupProps';
import { ListItemProps } from '../ListItem/ListItem';
import UnstyledLink from '../UnstyledLink';
import { ListingProviderGroup } from '../../types/listing-provider';

interface ListingDataProps {
  items: ListingProviderGroup[];
  baseURL: string;
  status: (item: ListingProviderGroup) => ReactNode;
  activeListingShortId: string | null | undefined;
  component: React.ElementType;
  container: React.ElementType;
  gridProps?: Partial<Record<Breakpoint, boolean | GridSize>>;
  getProps?: (
    item: ListingProviderGroup
  ) => Partial<ListItemProps> & Partial<ListingCardProps>;
}

const ListingData: React.FC<ListingDataProps & ListingGroupProps> = ({
  items,
  baseURL,
  status,
  activeListingShortId,
  component,
  container,
  getProps = () => {},
  title,
  opportunity = false,
  empty = null,
  gridProps = {},
}) => {
  const appState = useAppState();
  const mergedGridProps: Partial<Record<Breakpoint, boolean | GridSize>> = {
    xs: 12,
    ...gridProps,
  };
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';

  return (
    <Grid item xs={12}>
      {React.createElement(
        container,
        { title, empty, opportunity },
        <>
          {items.map((item) => {
            const itemProps: ListItemProps & ListingCardProps = {
              opportunity,
              ...item.listing,
              active: activeListingShortId === item.listing.attributes.short_id,
              status: status(item),
              ...getProps(item),
            };
            return (
              <Grid
                item
                {...mergedGridProps}
                key={item.listing.attributes.short_id}
              >
                <UnstyledLink
                  to={`${baseURL}/${item.listing.attributes.short_id}`}
                  replace={splitScreenMode}
                >
                  {React.createElement(component, itemProps)}
                </UnstyledLink>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default ListingData;
