import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import { getPageTitleTemplate } from '../../../constants';
import { useLazyReadBidByIDQuery } from '../../../services/api/marketplace-service/bids';
import ViewBid from '../../../components/ViewBid';
import { useCompanyQuery } from '../../../hooks/useCompanyQuery';
import { Grid, Typography } from '@material-ui/core';
import { CompanyInfoDisplay } from '../../../components/CompanyInfo';
import { format } from 'date-fns';

interface ViewBidPageProps extends RouteComponentProps<{ id: string }> {
  title?: string;
}

const ViewBidPage: React.FunctionComponent<ViewBidPageProps> = ({
  title = 'View Bid',
  id,
}) => {
  const [readBidByIDQuery, { data }] = useLazyReadBidByIDQuery();
  const companyQuery = useCompanyQuery(
    data?.entity.relationships.companyAccount?.data.id
  );
  const submitted_at = data?.entity.attributes?.submitted_at;

  useEffect(() => {
    if (id) {
      readBidByIDQuery({ id });
    }
  }, [readBidByIDQuery, id]);

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        {data?.entity && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {companyQuery.data && (
                <CompanyInfoDisplay company={companyQuery.data.entity} />
              )}
            </Grid>
            <Grid item xs={6}>
              {submitted_at && (
                <Typography align="right">
                  {format(new Date(submitted_at), 'do MMMM yyyy')}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <ViewBid bid={data.entity} />
            </Grid>
          </Grid>
        )}
      </AppLayout>
    </>
  );
};

export default ViewBidPage;
