import React, { FunctionComponent } from 'react';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { useThrottle } from '../../hooks/useThrottle';
import { Form } from 'react-final-form';

interface RegisterToBidModalProps {
  onIntendToBid: () => void;
  onClose: () => void;
  open?: boolean;
}

const RegisterToBidModal: FunctionComponent<RegisterToBidModalProps> = ({
  onIntendToBid,
  onClose,
  open = false,
}) => {
  // the assign opportunity API is taking a while in staging now due to an extra lookup
  const handleIntendToBid = useThrottle(onIntendToBid, 5000);

  return (
    <Dialog
      onClose={(event, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') onClose();
      }}
      open={open}
    >
      <Form
        onSubmit={async (values) => {
          await handleIntendToBid();
        }}
        render={({ handleSubmit, values, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle disableTypography>
              <Typography align="center" component="div" variant="h2">
                Register intention to bid
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography align="center">
                    By registering your interest in the listing, you confirm as
                    a service provider you have the capability and offering
                    required to fulfil and provide an accurate quote to the
                    buyer.
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item xs="auto">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                      endIcon={
                        submitting && (
                          <CircularProgress color="secondary" size={20} />
                        )
                      }
                      onClick={handleIntendToBid}
                    >
                      Yes, we intend to bid
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Button color="primary" onClick={onClose}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};

export default RegisterToBidModal;
