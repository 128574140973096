import ServiceDetailsPanel from './Panel';
import ServiceDetailsReview from './Review';
import ServiceDetailsContent from './ServiceDetails';
import ServiceDetailsEthernet from './Ethernet';
import ServiceDetailsIPTransit from './IPTransit';
import ServiceDetailsDarkFiber from './DarkFiber';
import ServiceDetailsCloudConnectivity from './CloudConnectivity';
import ServiceDetailsInternetAccess from './InternetAccess';
import ServiceDetailsWavelength from './Wavelength';
import ServiceDetailsColocation from './Colocation';
import ServiceDetailsCloudServices from './CloudServices';
import ServiceDetailsSDWan from './SDWan';
import ServiceDetailsMulti from './Multi';
import State from './State';

export const Panel = ServiceDetailsPanel;
export const Ethernet = ServiceDetailsEthernet;
export const IPTransit = ServiceDetailsIPTransit;
export const DarkFiber = ServiceDetailsDarkFiber;
export const CloudConnectivity = ServiceDetailsCloudConnectivity;
export const InternetAccess = ServiceDetailsInternetAccess;
export const Wavelength = ServiceDetailsWavelength;
export const Colocation = ServiceDetailsColocation;
export const CloudServices = ServiceDetailsCloudServices;
export const SDWan = ServiceDetailsSDWan;
export const MultiRequirements = ServiceDetailsMulti;
export const ServiceState = State;

export default Object.assign(
  {},
  {
    ExpansionPanel: ServiceDetailsPanel,
    Review: ServiceDetailsReview,
    Summary: ServiceDetailsContent,
  }
);
