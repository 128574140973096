import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';

const useStyles = makeStyles(() => ({
  startIcon: {
    marginRight: 0,
  },
}));

const BackButton: React.FC<Omit<ButtonProps, 'css'>> = (props) => {
  const classes = useStyles();
  return (
    <Button
      classes={classes}
      color="primary"
      startIcon={<BackIcon>back</BackIcon>}
      variant="text"
      size="small"
      {...props}
    >
      Back
    </Button>
  );
};

export default BackButton;
