import { useState, useCallback } from 'react';
import { noop } from 'lodash';
import ManageContactsMenu from '../ManageContactsMenu';
import { ProviderContact } from '../../../types/provider-contact';
import { ProviderGatewayServiceProvider } from '../../../types/provider-gateway';
import { GatewayProviderContactForm } from '../ProviderContactForm/GatewayProviderContactForm';
import {
  useCreateServiceProviderContactMutation,
  useUpdateServiceProviderContactByIDMutation,
} from '../../../services/api/marketplace-service/service-provider-contacts';

const AddGatewayServiceProviderContact = ({
  provider,
  contacts = [],
  value,
  onSelect = noop,
  onEdit = noop,
  onRemove = noop,
}: {
  provider: ProviderGatewayServiceProvider;
  contacts?: ProviderContact[];
  value?: ProviderContact;
  onSelect?: (value: ProviderContact) => void;
  onEdit?: (value: ProviderContact) => void;
  onRemove?: (value?: ProviderContact) => void;
}) => {
  const [contact, setContact] = useState<number | undefined>(undefined);
  const [createServiceProviderContactMutation] =
    useCreateServiceProviderContactMutation();
  const [updateServiceProviderContactMutation] =
    useUpdateServiceProviderContactByIDMutation();
  const handleDefaultContact = useCallback(
    (defaultContact: ProviderContact) => {
      contacts.forEach((contact) => {
        if (
          !!contact.attributes.default_contact &&
          contact.id !== defaultContact.id
        ) {
          updateServiceProviderContactMutation({
            id: contact.id,
            payload: {
              id: contact.id,
              type: 'provider-contact',
              attributes: {
                ...contact.attributes,
                default_contact: false,
              },
            },
          });
        }
      });
    },
    [contacts, updateServiceProviderContactMutation]
  );
  return (
    <>
      <ManageContactsMenu
        contacts={contacts}
        onSelect={onSelect}
        onAdd={() => {
          setContact(-1);
        }}
        onEdit={
          value
            ? () => {
                setContact(
                  contacts.findIndex((contact) => contact.id === value?.id)
                );
              }
            : undefined
        }
        onRemove={
          value
            ? () => {
                onRemove(value);
              }
            : undefined
        }
      />
      <GatewayProviderContactForm
        open={contact !== undefined}
        onClose={() => setContact(undefined)}
        provider={provider}
        contact={contact !== undefined ? contacts[contact] : undefined}
        onDone={({ contact }) => {
          if (contact.id) {
            updateServiceProviderContactMutation({
              id: contact.id,
              payload: {
                id: contact.id,
                type: 'provider-contact',
                attributes: contact.attributes,
              },
            })
              .unwrap()
              .then((response) => {
                onEdit(response.entity);
                setContact(undefined);
                return response.entity;
              })
              .then((contact) => {
                if (!!contact.attributes.default_contact) {
                  handleDefaultContact(contact);
                }
              });
          }

          if (!contact.id) {
            createServiceProviderContactMutation({
              payload: {
                type: 'provider-contact',
                attributes: contact.attributes,
              },
            })
              .unwrap()
              .then((response) => {
                onSelect(response.entity);
                setContact(undefined);

                return response.entity;
              })
              .then((contact) => {
                if (!!contact.attributes.default_contact) {
                  handleDefaultContact(contact);
                }
              });
          }
        }}
      />
    </>
  );
};

export default AddGatewayServiceProviderContact;
