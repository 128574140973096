import tw from 'twin.macro';

export const base = [
  tw`block text-left text-sm text-gray-700 font-medium cursor-pointer relative py-1.5 px-3 hover:(text-gray-900 bg-gray-100) focus:(text-gray-900 bg-gray-100) rounded-md focus:(outline-none ring ring-brand)`,
];

export const primary = [
  tw`text-primary-500 hover:(text-primary-600 bg-primary-100) focus:(text-primary-600 bg-primary-100)`,
];

export const danger = [
  tw`text-danger-500 hover:(text-danger-600 bg-danger-100) focus:(text-danger-600 bg-danger-100)`,
];
