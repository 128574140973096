import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../../types.d';
import { API_BASE_URL } from '../../../api';
import { BidService } from '../../../types/bid';

export const fetchAllBidServices = createAsyncThunk<
  {
    data: BidService[];
  },
  { bid: string },
  { extra: ThunkExtra }
>(`bid/service/fetchAll`, async ({ bid }, { extra: { axios } }) => {
  const response = await axios.get(`${API_BASE_URL}/bids/${bid}/services`);

  return response.data;
});

export const fetchBidServiceById = createAsyncThunk<
  { data: BidService },
  { bid: string; service: string },
  { extra: ThunkExtra }
>(`bid/service/fetchById`, async ({ bid, service }, { extra: { axios } }) => {
  const response = await axios.get(
    `${API_BASE_URL}/bids/${bid}/services/${service}`
  );

  return response.data;
});
