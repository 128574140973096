import { AxiosRequestConfig } from 'axios';
import algoliasearch from 'algoliasearch';
import { ObjectWithObjectID } from '@algolia/client-search';
import {
  AccountDiscovery,
  CompanyAccount,
  CompanyProfile,
  UserRolesPermissions,
} from './types/accounts';
import axios from './services/axios';
import { LocationMarket } from './types/market';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_ACCOUNT_URL = process.env.REACT_APP_API_ACCOUNT_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const APP_ENV = process.env.REACT_APP_APP_ENVIRONMENT;
const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const ALGOLIA_MARKETS_INDEX = process.env.REACT_APP_ALGOLIA_MARKETS_INDEX;
export const DISCOVER_URL = process.env.REACT_APP_DISCOVER_URL ?? '';
export const CLOUDSCENE_URL = process.env.REACT_APP_CLOUDSCENE_URL ?? '';
export const TEAM_URL = process.env.REACT_APP_TEAM_URL ?? '';
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL ?? '';
export const TEAMS_ADMIN_URL = process.env.REACT_APP_TEAMS_ADMIN_URL ?? '';
export const EXPLORE_URL = process.env.REACT_APP_EXPLORE_URL ?? '';
export const PRODUCT_PORTAL_URL =
  process.env.REACT_APP_PRODUCT_PORTAL_URL ?? '';
export const CPF_URL = process.env.REACT_APP_CPF_URL;
export const MARKETPLACE_SERVICE_API =
  process.env.REACT_APP_MARKETPLACE_SERVICE_API ?? '';
export const DIRECTORY_SERVICE_API =
  process.env.REACT_APP_DIRECTORY_SERVICE_API ?? '';
export const ACCOUNT_SERVICE_SUBSCRIPTION_API =
  process.env.REACT_APP_ACCOUNT_SERVICE_SUBSCRIPTION_API ?? '';
export const ACCOUNT_SERVICE_API =
  process.env.REACT_APP_ACCOUNT_SERVICE_API ?? '';
export const LOCATIONS_SERVICE_API =
  process.env.REACT_APP_LOCATIONS_SERVICE_API ?? '';

export const FP_JS_ENABLED = process.env.REACT_APP_FP_JS_ENABLED === 'true';
export const FP_JS_API_KEY = process.env.REACT_APP_FP_JS_API_KEY ?? '';
export const FP_JS_DOMAIN = process.env.REACT_APP_FP_JS_DOMAIN ?? '';

export const MARKETPLACE_MATCH_SEARCH_URL =
  process.env.REACT_APP_MARKETPLACE_MATCH_SEARCH_URL ?? '';

export const PROVIDER_GATEWAY_API_URL =
  process.env.REACT_APP_PROVIDER_GATEWAY_API_URL ?? '';

const ALGOLIA_FACILITIES_INDEX = process.env.REACT_APP_ALGOLIA_FACILITIES_INDEX;

declare global {
  interface Window {
    google: any;
  }
}

export interface CountryResult extends ObjectWithObjectID {
  iso: string;
  name: string;
}

const algoliaClient = algoliasearch(
  ALGOLIA_APP_ID as string,
  ALGOLIA_SEARCH_API_KEY as string
);
export const marketsIndex = algoliaClient.initIndex(
  ALGOLIA_MARKETS_INDEX as string
);
export const facilitiesIndex = algoliaClient.initIndex(
  ALGOLIA_FACILITIES_INDEX as string
);

const countriesIndex = algoliaClient.initIndex('countries-production');

export const getCountries = async (): Promise<CountryResult[] | undefined> => {
  try {
    const { hits } = await countriesIndex.search<CountryResult>('', {
      hitsPerPage: 300,
    });
    return hits;
  } catch (error) {
    console.log('searchMarkets call error\n', error);
  }
};

export const submitCompanySignup = (company: {
  company_name: string;
  website: string;
  email: string;
  name: string;
  country: string;
  company_type: string;
  company_industry: string;
  service_categories: string[];
}) => {
  const data = {
    type: 'company-account',
    attributes: company,
  };

  return axios.post(`${API_ACCOUNT_URL}/accounts`, { data });
};

// Get company by ID
export const getCompanyById = (
  id: string
): Promise<CompanyAccount | undefined> => {
  return axios
    .get(`${API_ACCOUNT_URL}/accounts/${id}`)
    .then((response) => {
      return response.data?.data;
    })
    .catch((err) => {
      throw err;
    });
};

export type UserRolesResponse = {
  user: UserRolesPermissions;
  profiles?: CompanyProfile[];
};

// Get user roles and permissions
export const getUserRoles = (id: string) => {
  return axios
    .get<{
      data: UserRolesPermissions;
      included?: CompanyProfile[];
    }>(`${API_ACCOUNT_URL}/users/${id}`, {
      params: {
        'fields[user]': ['roles', 'permissions', 'company-profiles'].join(','),
        include: 'company-profiles',
      },
    })
    .then((response) => ({
      user: response.data.data,
      profiles: response.data.included,
    }))
    .catch((err) => {
      throw err;
    });
};

export const uploadDocument = (data: FormData, config: AxiosRequestConfig) => {
  return axios.post(`${API_BASE_URL}/documents`, data, config);
};

export const deleteDocument = (document_id: string) => {
  return axios.delete(`${API_BASE_URL}/documents/${document_id}`);
};

export const fetchDocument = (document_id: string) => {
  return axios.get(`${API_BASE_URL}/documents/${document_id}`);
};

export const accountDiscovery = (id: string): Promise<AccountDiscovery[]> => {
  const url = `${API_ACCOUNT_URL}/users/${id}/account-discovery`;
  return axios
    .get<{ data: AccountDiscovery[] }>(url)
    .then((response) => response.data.data)
    .catch((err) => {
      throw err;
    });
};

export const requestToJoinCompany = (account: string, user?: string) => {
  const url = `${API_ACCOUNT_URL}/accounts/${account}/join-requests`;
  const data = {
    data: {
      type: 'join-request',
      attributes: {
        user_id: user,
      },
    },
  };

  return axios.post(url, data);
};

export const getMarketByCoords = async (lat: string, lon: string) => {
  const url = new URL(`${LOCATIONS_SERVICE_API}/markets/search`);
  url.searchParams.append('lat', lat);
  url.searchParams.append('lon', lon);
  try {
    const { data } = await axios.get<LocationMarket>(url.toString());
    return data;
  } catch (err) {
    return null;
  }
};
