import { marketplaceServiceAPI } from './base';
import {
  EntityCollectionResponse,
  EntityResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import { encodeParams } from '../../../utilities/query-params';
import {
  transformEntityCollectionResponse,
  transformEntityResponse,
} from '../transform-response';
import {
  ServiceProviderContactRequest,
  ServiceProviderContactResponse,
  ServiceProviderContactResponseIncludes,
} from '../../../types/marketplace-service/service-provider-contact';

export const serviceProviderContactsAPI = marketplaceServiceAPI.injectEndpoints(
  {
    overrideExisting: process.env.NODE_ENV === 'development',
    endpoints: (builder) => ({
      createServiceProviderContact: builder.mutation<
        EntityResponse<
          ServiceProviderContactResponse,
          ServiceProviderContactResponseIncludes
        >,
        {
          payload: ServiceProviderContactRequest;
          params?: URLQueryParams;
        }
      >({
        query: (args) => ({
          url: `/provider-contacts`,
          params: args?.params,
          method: 'POST',
          body: JSON.stringify({ data: args.payload }),
        }),
        transformResponse: transformEntityResponse,
      }),
      readServiceProviderContactByID: builder.query<
        EntityResponse<
          ServiceProviderContactResponse,
          ServiceProviderContactResponseIncludes
        >,
        { id: string; params?: URLQueryParams }
      >({
        providesTags: (result) =>
          result?.entity
            ? [
                {
                  type: 'ServiceProviderContact',
                  id: result.entity.id,
                },
              ]
            : [],
        query: (args) => ({
          url: `/provider-contacts/${encodeURIComponent(args.id)}`,
          params: args.params,
          method: 'GET',
        }),
        transformResponse: transformEntityResponse,
      }),
      readAllServiceProviderContactsByUserID: builder.query<
        EntityCollectionResponse<
          ServiceProviderContactResponse,
          ServiceProviderContactResponseIncludes
        >,
        { user: string; params?: URLQueryParams }
      >({
        providesTags: (result) =>
          result
            ? result.entities
                .map(({ id }) => ({
                  type: 'ServiceProviderContact' as const,
                  id,
                }))
                .concat({ type: 'ServiceProviderContact', id: 'LIST' })
            : [{ type: 'ServiceProviderContact', id: 'LIST' }],
        query: (args) => ({
          url: `/provider-contacts`,
          params: encodeParams({ ...args.params, 'filter[user]': args.user }),
          method: 'GET',
        }),
        transformResponse: transformEntityCollectionResponse,
      }),
      updateServiceProviderContactByID: builder.mutation<
        EntityResponse<
          ServiceProviderContactResponse,
          ServiceProviderContactResponseIncludes
        >,
        {
          id: string;
          payload: ServiceProviderContactRequest;
          params?: URLQueryParams;
        }
      >({
        invalidatesTags: (result) =>
          result
            ? [
                {
                  type: 'ServiceProviderContact',
                  id: result.entity.id,
                },
              ]
            : [],
        query: (args) => ({
          url: `/provider-contacts/${encodeURIComponent(args.id)}`,
          params: args.params,
          method: 'PATCH',
          body: JSON.stringify({ data: args.payload }),
        }),
        transformResponse: transformEntityResponse,
      }),
      deleteServiceProviderContactByID: builder.mutation<void, { id: string }>({
        invalidatesTags: (result, error, args) => [
          {
            type: 'ServiceProviderContact',
            id: args.id,
          },
        ],
        query: (args) => ({
          url: `/provider-contacts/${encodeURIComponent(args.id)}`,
          method: 'DELETE',
        }),
      }),

      setDefaultServiceProviderContact: builder.mutation<
        EntityResponse<
          ServiceProviderContactResponse,
          ServiceProviderContactResponseIncludes
        >,
        {
          id: string;
          default: boolean;
          params?: URLQueryParams;
        }
      >({
        query: (args) => {
          const payload: ServiceProviderContactRequest = {
            id: args.id,
            type: 'provider-contact',
            attributes: {
              default_contact: args.default,
            },
          };

          return {
            url: `/provider-contacts/${encodeURIComponent(args.id)}`,
            params: args.params,
            method: 'PATCH',
            body: JSON.stringify({ data: payload }),
          };
        },
        transformResponse: transformEntityResponse,
      }),
    }),
  }
);

export const {
  useCreateServiceProviderContactMutation,
  useDeleteServiceProviderContactByIDMutation,
  useLazyReadAllServiceProviderContactsByUserIDQuery,
  useLazyReadServiceProviderContactByIDQuery,
  usePrefetch,
  useReadAllServiceProviderContactsByUserIDQuery,
  useReadServiceProviderContactByIDQuery,
  useSetDefaultServiceProviderContactMutation,
  useUpdateServiceProviderContactByIDMutation,
} = serviceProviderContactsAPI;
