import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from '../types';
import { listingsAPI } from '../../services/api/marketplace-service/listings';
import { ListingProviderContactResponse } from '../../types/marketplace-service/listing-provider-contact';

const listingProviderContactsAdapter =
  createEntityAdapter<ListingProviderContactResponse>();

const listingProviderContacts = createSlice({
  name: 'listing-provider-contacts',
  initialState: listingProviderContactsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingsByCompanyID.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context['listing-provider-contact']) return state;

        const listingProviderContacts = Object.values(
          action.payload.context['listing-provider-contact'].entities
        );
        listingProviderContactsAdapter.upsertMany(
          state,
          listingProviderContacts
        );
      }
    );
  },
});

export const {
  selectById: selectListingProviderContactById,
  selectIds: selectListingProviderContactIds,
  selectEntities: selectListingProviderContactEntities,
  selectAll: selectAllListingProviderContacts,
  selectTotal: selectTotalListingProviderContacts,
} = listingProviderContactsAdapter.getSelectors(
  (state: RootState) => state.listingProviderContacts
);

export default listingProviderContacts.reducer;
