import { PropsWithChildren } from 'react';
import {
  CacheLocation,
  FpjsProvider,
} from '@fingerprintjs/fingerprintjs-pro-react';
import {
  getFingerprintHandler,
  setFingerprintHandler,
} from '../../services/fingerprint-handler';
import { FingerprintHandler } from './FingerprintHandler';
import type {
  GetFingerprintHandlerCallback,
  SetFingerprintHandlerCallback,
} from './types';
import { FP_JS_API_KEY, FP_JS_DOMAIN } from '../../api';

export const FingerprintProvider = ({
  getHandler = getFingerprintHandler,
  setHandler = setFingerprintHandler,
  children,
}: PropsWithChildren<{
  getHandler?: GetFingerprintHandlerCallback;
  setHandler?: SetFingerprintHandlerCallback;
}>) => {
  return (
    <FpjsProvider
      loadOptions={{
        apiKey: FP_JS_API_KEY,
        endpoint: FP_JS_DOMAIN,
      }}
      cachePrefix="cs-fp"
      cacheLocation={CacheLocation.SessionStorage}
      cacheTimeInSeconds={86_400}
    >
      <FingerprintHandler getHandler={getHandler} setHandler={setHandler}>
        {children}
      </FingerprintHandler>
    </FpjsProvider>
  );
};
