import React from 'react';
import { formatISO, parseISO } from 'date-fns';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';
import PlacesAutocomplete from '../../../Form/PlacesAutocomplete';
import { SelectFieldPlaceholder as SelectField } from '../../../Form/CSSelectField';
import TextField from '../../../Form/CSTextField';
import DateField from '../../../Form/CSDateField';
import {
  ServiceBandwidth,
  ServiceBandwidthUnits,
  ServiceContractTerm,
  ServiceContractUnits,
} from '../../../../types/services';

const CloudConnectivityBasic: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Field
          name="attributes.external_service_name"
          render={({ input }) => (
            <TextField label="Circuit Name (optional)" {...input} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="attributes.external_service_id"
          render={({ input }) => (
            <TextField label="Service ID (optional)" {...input} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="attributes.point_a"
          render={({ input }) => (
            <PlacesAutocomplete
              label="A-End Location *"
              selected={input.value}
              onSelect={(place) => input.onChange(place)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="attributes.point_z.description"
          render={({ input }) => (
            <TextField label="Cloud Provider" disabled {...input} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          name="attributes.bandwidth"
          parse={(bandwidth: string): ServiceBandwidth => {
            const [, value, unit] = bandwidth.match(/(\d{1,})(\w{4})/) || [];

            return {
              value: Number(value),
              unit: unit as ServiceBandwidthUnits,
            };
          }}
          format={(bandwidth: ServiceBandwidth) => {
            return bandwidth ? `${bandwidth.value}${bandwidth.unit}` : '';
          }}
          render={({ input }) => (
            <SelectField label="Bandwidth *" {...input}>
              <MenuItem value="10Mbps">10 Mbps</MenuItem>
              <MenuItem value="20Mbps">20 Mbps</MenuItem>
              <MenuItem value="50Mbps">50 Mbps</MenuItem>
              <MenuItem value="100Mbps">100 Mbps</MenuItem>
              <MenuItem value="200Mbps">200 Mbps</MenuItem>
              <MenuItem value="500Mbps">500 Mbps</MenuItem>
              <MenuItem value="1Gbps">1 Gbps</MenuItem>
              <MenuItem value="10Gbps">10 Gbps</MenuItem>
              <MenuItem value="100Gbps">100 Gbps</MenuItem>
            </SelectField>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          name="contract_term"
          parse={(term: string): ServiceContractTerm => {
            const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];

            return {
              value: Number(value),
              unit: unit as ServiceContractUnits,
            };
          }}
          format={(term: ServiceContractTerm) => {
            return term ? `${term.value}${term.unit}` : '';
          }}
          render={({ input }) => (
            <SelectField label="Contract Term *" {...input}>
              <MenuItem value="12months">12 months</MenuItem>
              <MenuItem value="24months">24 months</MenuItem>
              <MenuItem value="36months">36 months</MenuItem>
              <MenuItem value="48months">48 months</MenuItem>
              <MenuItem value="60months">60 months</MenuItem>
            </SelectField>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          name="ready_for_service"
          parse={(value) => (value ? formatISO(value) : '')}
          format={(value) => (value ? parseISO(value) : '')}
          render={({ input }) => (
            <DateField minDate={new Date()} label="RFS Date *" {...input} />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CloudConnectivityBasic;
