import React, { useCallback } from 'react';
import {
  Grid,
  Paper,
  ButtonGroup,
  useMediaQuery,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import BidActionView from './BidActionView';
import BidActionUnInterested from './BidActionUnInterested';
import { useCompanyQuery } from '../../hooks/useCompanyQuery';
import { useUserQuery } from '../../hooks/useUserQuery';
import { CompanyInfoDisplay } from '../CompanyInfo';
import { useAppMessenger } from '../../store/messenger';
import { useUpdateBidByIDMutation } from '../../services/api/marketplace-service/bids';
import { useAuth } from '../../hooks/useAuth';
import { ROUTES } from '../../routes';
import BidActionInterested from './BidActionInterested';
import { BidResponse, BidState } from '../../types/marketplace-service/bid';
import { useUpdateListingByIDMutation } from '../../services/api/marketplace-service/listings';
import { ListingResponse } from '../../types/marketplace-service/listing';

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(2, 3),
    },
    buttons: {
      borderRight: 'none!important',
      borderRadius: '5px!important',
      margin: '5px',
    },
  })
);

interface BidActionsProps {
  company?: string;
  userId?: string;
  listing?: ListingResponse;
  bid: BidResponse;
  showView?: boolean;
  actions: {
    view?: boolean;
    interested?: boolean;
    uninterested?: boolean;
    showRating?: boolean;
  };
  enableNotification?: boolean;
  children?: React.ReactNode;
}

const BidActions: React.FC<BidActionsProps> = ({
  company,
  userId,
  listing,
  bid,
  actions: {
    view = true,
    interested = true,
    uninterested = true,
    showRating = true,
  },
}) => {
  const classes = useStyles();
  const [updateBidByIDMutation] = useUpdateBidByIDMutation();
  const companyQuery = useCompanyQuery(company);
  const userQuery = useUserQuery(userId);
  const { user } = useAuth();
  const messenger = useAppMessenger();
  const handleBidUpdate = useCallback(
    (state: BidState, bid: BidResponse, reason?: string) => {
      return updateBidByIDMutation({
        id: bid.id,
        payload: {
          id: bid.id,
          type: 'bid',
          attributes: {
            state,
            state_uninterested_reason: reason,
          },
        },
      }).unwrap();
    },
    [updateBidByIDMutation]
  );
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const addMessage = useCallback(
    (msg) => {
      messenger.addMessage('info', msg);
    },
    [messenger]
  );
  const uninterestedReasons = [
    'Price was too high',
    "We didn't win the deal",
    'Install interval was too long',
    "Solution didn't meet our requirements",
    'Other/Prefer not to say',
  ].map((option) => ({ value: option, label: option }));

  const [updateListingByID] = useUpdateListingByIDMutation();
  const handleListingUpdate = useCallback(
    async (rating: number | null, notify_unsuccessful?: boolean) => {
      if (!listing) return;
      await updateListingByID({
        id: listing.id,
        payload: {
          id: listing.id,
          type: 'listing',
          attributes: {
            state: 'completed',
            rating: rating,
            notify_unsuccessful: notify_unsuccessful,
            user_id: listing.attributes.user_id ?? user?.id,
            company_id:
              listing.attributes.company_id ?? user?.company_account_id,
          },
        },
      });
      return await navigate(
        `${ROUTES.buyingCompleted}/${listing.attributes.short_id}`,
        { replace: true }
      );
    },
    [updateListingByID, listing, user]
  );

  if (companyQuery.isLoading || companyQuery.isError || !companyQuery.data) {
    return null;
  }

  return (
    <Paper elevation={4}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.wrapper}>
            <CompanyInfoDisplay
              company={companyQuery.data.entity}
              user={userQuery.user}
              viewBidderInfo={bid.attributes.state === 'interested'}
              justifyCenter={mobile}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup
            orientation={mobile ? 'vertical' : 'horizontal'}
            variant="text"
            fullWidth
          >
            {view && (
              <BidActionView
                bid={bid}
                company={companyQuery.data.entity}
                bidder={userQuery.user}
                className={classes.buttons}
              />
            )}
            {interested && (
              <BidActionInterested
                className={classes.buttons}
                company={companyQuery.data.entity}
                showRating={showRating}
                onInterested={(rating, updateListing) => {
                  handleBidUpdate('interested', bid).then(() => {
                    addMessage(
                      `You are interested in a bid from ${companyQuery.data.entity.attributes.name}`
                    );
                    if (updateListing && rating) {
                      handleListingUpdate(rating);
                    }
                  });
                }}
              />
            )}
            {uninterested && (
              <BidActionUnInterested
                className={classes.buttons}
                options={uninterestedReasons}
                showRating={showRating}
                onUnInterested={(uninterested_reason, rating) => {
                  handleBidUpdate(
                    'uninterested',
                    bid,
                    uninterested_reason
                  ).then(() => {
                    addMessage(
                      `You have declined a bid from  ${companyQuery.data.entity.attributes.name}`
                    );
                    if (rating) {
                      handleListingUpdate(rating);
                    }
                  });
                }}
              />
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BidActions;
