import { directoryServiceAPI } from './base';
import {
  EntityResponse,
  EntityCollectionResponse,
} from '../../../types/entity-collection';
import {
  CompanyProfileResponse,
  CompanyProfileResponseIncludes,
} from '../../../types/directory-service/company-profile';
import {
  transformEntityResponse,
  transformEntityCollectionResponse,
} from '../transform-response';

interface FetchServiceProviderOptions {
  'fields[companyProfiles]': string;
  'filter[search]'?: string;
  'filter[premium]'?: number;
  sort: string;
  'page[size]'?: number;
}

export const companyProfilesAPI = directoryServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    readCompanyProfileByID: builder.query<
      EntityResponse<CompanyProfileResponse, CompanyProfileResponseIncludes>,
      { id: string; params?: FetchServiceProviderOptions }
    >({
      providesTags: (result, _error, args) => [
        { type: 'CompanyProfile', id: args.id },
      ],
      query: (args) => ({
        url: `/companyProfiles/${encodeURIComponent(args.id)}`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),
    readAllCompanyProfiles: builder.query<
      EntityCollectionResponse<
        CompanyProfileResponse,
        CompanyProfileResponseIncludes
      >,
      { params: FetchServiceProviderOptions }
    >({
      providesTags: (result, _error, _args) => [
        'CompanyProfile',
        ...(result?.entities?.map((entity) => ({
          type: 'CompanyProfile' as const,
          id: entity.id,
        })) ?? []),
      ],
      query: (args) => ({
        url: `/companyProfiles`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
  }),
});

export const {
  useLazyReadAllCompanyProfilesQuery,
  useLazyReadCompanyProfileByIDQuery,
  usePrefetch,
  useReadAllCompanyProfilesQuery,
  useReadCompanyProfileByIDQuery,
} = companyProfilesAPI;
