import { PropsWithChildren, useEffect } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import type {
  GetFingerprintHandlerCallback,
  SetFingerprintHandlerCallback,
} from './types.d';

export const FingerprintHandler = ({
  getHandler,
  setHandler,
  children,
}: PropsWithChildren<{
  getHandler?: GetFingerprintHandlerCallback;
  setHandler?: SetFingerprintHandlerCallback;
}>) => {
  const { getData } = useVisitorData({ extendedResult: true });

  useEffect(() => {
    if (!getHandler?.()) {
      setHandler?.(getData);
    }
  }, [getHandler, setHandler, getData]);

  return <>{children}</>;
};
