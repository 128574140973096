import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useEffect } from 'react';
import NoCompany from '../../../components/Listings/NoCompany';
import { useAuth } from '../../../hooks/useAuth';
import { useLazyReadAllListingProvidersQuery } from '../../../services/api/marketplace-service/listing-providers';
import { useAppDispatch } from '../../../store/hooks';

const SellingContainer: React.FunctionComponent<RouteComponentProps> = ({
  children,
}) => {
  const [readAllListingProvidersQuery] = useLazyReadAllListingProvidersQuery();
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  useEffect(() => {
    readAllListingProvidersQuery({ params: { include: 'listing,bid' } });
  }, [dispatch, readAllListingProvidersQuery]);

  return user?.company_account_id ? (
    <>{children}</>
  ) : (
    <NoCompany>
      It&apos;s looking a little empty in here. That&apos;s because you
      haven&apos;t been approved to join your team just yet. When the team
      manager approves your request, you&apos;ll be able to respond and bid on
      opportunities. In the meantime, feel free to keep browsing listings on
      Cloudscene Marketplace.
    </NoCompany>
  );
};

export default SellingContainer;
