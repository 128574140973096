import { Typography } from '@material-ui/core';
import { useReadServiceProviderContactByIDQuery } from '../../../services/api/marketplace-service/service-provider-contacts';
import { ListingProviderContact } from '../../../types/provider-contact';

const Details = ({ id }: { id: string }) => {
  const { data } = useReadServiceProviderContactByIDQuery({
    id,
  });

  if (!data?.entity.attributes.default_contact) {
    return null;
  }

  const { first_name, last_name, email } = data.entity.attributes;

  return (
    <Typography variant="body1" component="div">
      <strong>
        {first_name} {last_name}
      </strong>
      <br />
      {email}
    </Typography>
  );
};

export interface DefaultProviderContactProps {
  contacts: {
    state: 'initial' | 'add' | 'edit' | 'delete';
    value: ListingProviderContact;
  }[];
}

export const GatewayDefaultProviderContact: React.FC<DefaultProviderContactProps> =
  ({ contacts }) => {
    if (contacts.length === 0)
      return (
        <Typography variant="body1" component="div">
          Add My Contact (Optional)
        </Typography>
      );

    return (
      <>
        {contacts.map((contact) => (
          <Details
            key={contact.value.id}
            id={contact.value.relationships['provider-contact'].data.id}
          />
        ))}
      </>
    );
  };
