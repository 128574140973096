import React, { ReactNode } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

const EmptyContent: React.FC<{
  img: ReactNode;
  cta?: ReactNode;
}> = ({ children, cta, img, ...props }) => {
  return (
    <Box mx="auto" pt={2}>
      <Grid container direction="column" alignItems="center">
        <Grid item>{img}</Grid>
        <Grid item {...props}>
          <Typography variant="body2" component="div" align="center">
            {children}
          </Typography>
        </Grid>
        {cta && (
          <Grid item xs={12}>
            <Box mt={4}>{cta}</Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EmptyContent;
