import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Form,
  FormSpy,
  FormRenderProps,
  FormSpyRenderProps,
  Field,
} from 'react-final-form';
import CSDateTimeField from '../Form/CSDateTimeField';
import {
  formatISO,
  parseISO,
  add,
  format,
  getHours,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import validationSchema from './validation';
import { formatOfDuration } from '../../utilities/time';
import { useValidationSchema } from '../../hooks/useValidationSchema';

interface ExtendListingState {
  end_date: string;
}

interface ExtendListingProps {
  onExtend: (end: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      margin: theme.spacing(3, 'auto'),
    },
    error: {
      margin: theme.spacing(2, 0),
    },
  })
);

const ExtendListing: React.FC<ExtendListingProps> = ({ onExtend }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const validator = useValidationSchema(validationSchema);

  const today = new Date();
  const defaultEndDate = formatISO(
    add(new Date(format(today, 'dd MMMM yyyy')), {
      days: 3,
      hours: getHours(today) + 1,
    })
  );

  return (
    <>
      <Grid item xs={12} sm={9}>
        <Typography variant="h5" gutterBottom>
          Your listing has ended
        </Typography>
        <Typography variant="body2">
          Did it end too soon? You can extend your listing and attract more
          service providers.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          fullWidth
        >
          Extend Listing
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography align="center" component="div" variant="h2">
            Extend Listing
          </Typography>
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography variant="body1" align="center" gutterBottom>
          When would you like to extend your listing to?
        </Typography>
        <DialogContent>
          <Form
            initialValues={{
              end_date: defaultEndDate,
            }}
            onSubmit={(values: ExtendListingState) => onExtend(values.end_date)}
            validate={validator}
            render={({ handleSubmit }: FormRenderProps<ExtendListingState>) => (
              <form onSubmit={handleSubmit}>
                <Grid container justifyContent="center">
                  <Grid item xs="auto">
                    <Field
                      name="end_date"
                      parse={(value) => (value ? formatISO(value) : '')}
                      format={(value) => (value ? parseISO(value) : '')}
                    >
                      {({ input }) => (
                        <CSDateTimeField
                          label="New End Date"
                          minDate={new Date()}
                          tooltip="Intended expiry date for your listing"
                          {...input}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormSpy
                      subscription={{
                        values: true,
                        submitting: true,
                        valid: true,
                      }}
                    >
                      {({ submitting, values, valid }: FormSpyRenderProps) => {
                        const duration = intervalToDuration({
                          start: new Date(),
                          end: new Date(values.end_date),
                        });
                        const format: string[] = formatOfDuration(duration);

                        return (
                          <>
                            {!valid && (
                              <Typography
                                variant="body1"
                                color="error"
                                align="center"
                                className={classes.error}
                              >
                                The intended end date is invalid. Please make
                                sure the intended end date is at least 1 hour
                                from now.
                              </Typography>
                            )}
                            <Grid
                              container
                              justifyContent="center"
                              item
                              xs={12}
                            >
                              <Button
                                className={classes.button}
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                                disabled={!valid || submitting}
                                autoFocus
                                endIcon={
                                  submitting && (
                                    <CircularProgress
                                      color="secondary"
                                      size={20}
                                    />
                                  )
                                }
                              >
                                Extend listing by{' '}
                                {formatDuration(duration, { format })}
                              </Button>
                            </Grid>
                          </>
                        );
                      }}
                    </FormSpy>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExtendListing;
