import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types.d';
import { API_BASE_URL } from '../../api';
import {
  ListingMarketResponseIncludes,
  ListingMarketResponse,
} from '../../types/marketplace-service/market';

export const createMarket = createAsyncThunk<
  {
    data: ListingMarketResponse;
    included: ListingMarketResponseIncludes[];
  },
  { listing: string; market: ListingMarketResponse },
  { extra: ThunkExtra }
>(`market/create`, async ({ listing, market }, { extra: { axios } }) => {
  const response = await axios.post(
    `${API_BASE_URL}/listings/${listing}/markets`,
    {
      data: market,
    }
  );

  return response.data;
});

export const fetchAllMarkets = createAsyncThunk<
  {
    data: ListingMarketResponse[];
    included: ListingMarketResponseIncludes[];
  },
  { listing: string },
  { extra: ThunkExtra }
>(`market/fetchAll`, async (listing, { extra: { axios } }) => {
  const response = await axios.get(
    `${API_BASE_URL}/listings/${listing}/markets`
  );

  return response.data;
});

export const fetchMarketById = createAsyncThunk<
  { data: ListingMarketResponse; included: ListingMarketResponseIncludes[] },
  { listing: string; market: string },
  { extra: ThunkExtra }
>(`market/fetchById`, async ({ listing, market }, { extra: { axios } }) => {
  const response = await axios.get(
    `${API_BASE_URL}/listings/${listing}/markets/${market}`
  );

  return response.data;
});

export const deleteMarketById = createAsyncThunk<
  { id: string },
  { listing: string; market: string },
  { extra: ThunkExtra }
>(`market/delete`, async ({ listing, market }, { extra: { axios } }) => {
  await axios.delete(`${API_BASE_URL}/listings/${listing}/markets/${market}`);

  return { id: market };
});
