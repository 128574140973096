import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useNavigate } from '@reach/router';

interface EditListingConfirmationProps {
  open?: boolean;
  listingShortId: string;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      margin: '0 auto',
    },
  })
);

const EditListingConfirmation: React.FC<EditListingConfirmationProps> = ({
  open = false,
  onClose,
  listingShortId,
}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle>
          Are you sure you want to edit?
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            There may be submitted bids on this listing. If you update any
            requirement details including services and/or markets, the service
            providers that have bid on this listing will be notified and given
            the opportunity to change their quotes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={() => navigate?.(`/listing/${listingShortId}/edit`)}
            variant="contained"
            color="primary"
            autoFocus
          >
            Continue to edit
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={onClose}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditListingConfirmation;
