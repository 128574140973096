import { FC, useContext } from 'react';
import { HiOutlineMenu, HiOutlineX } from 'react-icons/hi';
import { AppMenuContext } from '../../components/AppMenu';
import { IconButton } from '../../components/Cloudscene/Button';
import { useBodyScrollLock } from '../../hooks/useBodyScrollLock';

export const MenuControl = () => {
  const { isOpen, closeAppMenu, openAppMenu } = useContext(AppMenuContext);
  const scroll = useBodyScrollLock();

  return isOpen ? (
    <IconButton
      id="close-menu"
      aria-label="Close Menu"
      onClick={(event) => {
        scroll.unlock(event.currentTarget);
        closeAppMenu();
      }}
    >
      <HiOutlineX size={24} />
    </IconButton>
  ) : (
    <IconButton
      id="open-menu"
      aria-label="Open Menu"
      onClick={(event) => {
        scroll.lock(event.currentTarget);
        openAppMenu();
      }}
    >
      <HiOutlineMenu size={24} />
    </IconButton>
  );
};

export const MenuContent: FC = ({ children }) => {
  return <>{children}</>;
};
