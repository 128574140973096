import { ServiceTypes, ServiceTypeKeys } from './types/services';
import { VisibilityEnum } from './components/CreateListing/types';
import { strEnum } from './utils';

export const PAGE_TITLE_SUFFIX = 'Cloudscene Marketplace';

export const getPageTitleTemplate = (suffix: string = PAGE_TITLE_SUFFIX) =>
  `%s - ${suffix}`;

export enum Currencies {
  USD = 'USD',
  AUD = 'AUD',
  GBP = 'GBP',
  EUR = 'EUR',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CZK = 'CZK',
  HKD = 'HKD',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  KES = 'KES',
  KSH = 'KSH',
  MYR = 'MYR',
  NOK = 'NOK',
  NZD = 'NZD',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  ARS = 'ARS',
  COP = 'COP',
  MXN = 'MXN',
  RUB = 'RUB',
}

let SERVICES_LIST = {
  ETHERNET: 'ETHERNET',
  IP_TRANSIT: 'IP_TRANSIT',
  DARK_FIBER: 'DARK_FIBER',
  INTERNET_ACCESS: 'INTERNET_ACCESS',
  CLOUD_CONNECTIVITY: 'CLOUD_CONNECTIVITY',
  CLOUD_SERVICES: 'CLOUD_SERVICES',
  COLOCATION: 'COLOCATION',
  WAVELENGTH: 'WAVELENGTH',
  SD_WAN: 'SD_WAN',
  MPLS: 'MPLS',
  OTHER: 'OTHER',
} as const;

export const SIGNUP_URL = process.env.REACT_APP_SIGNUP_URL;
export const FORM_STATE_STORAGE_KEY = '___form_state_CS_2020_vision_v0.31';

SERVICES_LIST = Object.freeze(SERVICES_LIST);
export const SERVICES = SERVICES_LIST;
export type ServiceChoicesKeysType = keyof typeof SERVICES;

export type UploadRequirementsType = 'UPLOAD_REQUIREMENTS';
export const UPLOAD_REQUIREMENTS: UploadRequirementsType =
  'UPLOAD_REQUIREMENTS';

export const getServiceUIName = (
  serviceKey: ServiceTypeKeys | 'other'
): string => {
  return {
    [ServiceTypes.ethernet]: 'Ethernet',
    [ServiceTypes.ip_transit]: 'IP Transit',
    [ServiceTypes.dark_fiber]: 'Dark Fiber',
    [ServiceTypes.internet_access]: 'Internet Access',
    [ServiceTypes.cloud_connectivity]: 'Cloud Connectivity',
    [ServiceTypes.cloud_services]: 'Cloud Services',
    [ServiceTypes.colocation]: 'Colocation',
    [ServiceTypes.wavelength]: 'Wavelength',
    [ServiceTypes.sd_wan]: 'SD-WAN',
    [ServiceTypes.mpls]: 'MPLS',
    [ServiceTypes.multi_service_requirements]: 'Requirements',
    other: 'Other Services',
  }[serviceKey];
};

export const MAX_NUM_PROVIDERS_NO_AUTH = 10;

const _mirroredObjKeyVal = strEnum([
  'listingVisibility',
  'listingTitle',
  'listingStartDate',
  'listingEndDate',
]);

export const WIZARD_FIELD_NAMES: Readonly<typeof _mirroredObjKeyVal> =
  _mirroredObjKeyVal; // workaround for intellisense

export const LISTING_VISIBILITY: VisibilityEnum = Object.freeze({
  private: 'private',
  public: 'public',
});

export const API_SERVICE_TYPE_IDS = {
  ETHERNET: 'ethernet',
  IP_TRANSIT: 'ip_transit',
  DARK_FIBER: 'dark_fiber',
  INTERNET_ACCESS: 'internet_access',
  CLOUD_CONNECTIVITY: 'cloud_connectivity',
  CLOUD_SERVICES: 'cloud_services',
  COLOCATION: 'colocation',
  WAVELENGTH: 'wavelength',
  SD_WAN: 'sd_wan',
  MPLS: 'mpls',
  OTHER: 'other',
  UPLOAD_REQUIREMENTS: 'multi_service_requirements',
};

export const CONTRACT_LENGTH_UNITS = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
};

export const ADDRESS_TYPES = {
  ADDRESS: 'address',
  DATA_CENTER: 'data_center',
  INTERNET: 'internet',
};

export const BANDWIDTH_UNITS = {
  MBPS: 'Mbps',
  GBPS: 'Gbps',
};

export type AddressType = {
  id: string;
  type: string;
  label: string;
  description: string;
  logo?: string;
};

export const ERROR_TYPE = {
  validation: 'validation',
  apiAuthentication: 'apiAuthentication',
  unknownError: 'unknownError',
};

export enum FormSections {
  ListingDetails = 'ListingDetails',
  ServiceRequirements = 'ServiceRequirements',
  Markets = 'Markets',
  InviteServiceProviders = 'InviteServiceProviders',
  AdditionalInformation = 'AdditionalInformation',
  MyDetails = 'MyDetails',
  ConfirmPublish = 'ConfirmPublish',
}

export type FormSectionsTypes =
  | FormSections.ListingDetails
  | FormSections.ServiceRequirements
  | FormSections.Markets
  | FormSections.InviteServiceProviders
  | FormSections.AdditionalInformation
  | FormSections.MyDetails
  | FormSections.ConfirmPublish;

export const FORM_SECTIONS_ORDER = [
  FormSections.ListingDetails,
  FormSections.ServiceRequirements,
  FormSections.Markets,
  FormSections.InviteServiceProviders,
  FormSections.AdditionalInformation,
  FormSections.MyDetails,
  FormSections.ConfirmPublish,
];

export enum TECH_SPECS_API_KEYS {
  ethernet = 'ethernet',
  ip_transit = 'ip_transit',
  dark_fiber = 'dark_fiber',
  cloud_connectivity = 'cloud_connectivity',
  cloud_services = 'cloud_services',
  internet_access = 'internet_access',
  colocation = 'colocation',
  mpls = 'mpls',
  q_in_q = 'q_in_q',
  qos = 'qos',
  tagged = 'tagged',
  untagged = 'untagged',
  protected = 'protected',
  unprotected = 'unprotected',
  jumbo_frames = 'jumbo_frames',
  premium_sla = 'premium_sla',
  other = 'other',
  bpg_support = 'bpg_support',
  ddos_filtering = 'ddos_filtering',
  encryption = 'encryption',
  burst_flexible = 'burst_flexible',
  fiber = 'fiber',
  bb_fttx = 'bb_fttx',
  fixed_wireless = 'fixed_wireless',
  '4g_5g' = '4g_5g',
  domestic = 'domestic',
  international = 'international',
  single = 'single',
  fixed_fiber = 'fixed_fiber',
  fixed_copper = 'fixed_copper',
  broadband = 'broadband',
  enterprise = 'enterprise',
  business = 'business',
  consumer = 'consumer',
  space_required = 'space_required',
  standard = 'standard',
  deep = 'deep',
  '247_security' = '247_security',
  remote_hands = 'remote_hands',
  remote_trigger_black_hole = 'remote_trigger_black_hole',
  separate_cage = 'separate_cage',
  dual_power = 'dual_power',
  cross_connect = 'cross_connect',
  cross_connects = 'cross_connects',
  mttr_enterprise = 'mttr_enterprise',
  mttr_business = 'mttr_business',
  mttr_no_sla = 'mttr_no_sla',
  lead_in = 'lead_in',
  street_geo = 'street_geo',
  ru = 'ru',
  quarter_rack = 'quarter_rack',
  half_rack = 'half_rack',
  full_rack = 'full_rack',
  physical = 'physical',
  internet = 'internet',
  csp = 'csp',
  electrical_copper = 'electrical_copper',
  fiber_optical = 'fiber_optical',
  hand_off = 'hand_off',
  access_type = 'access_type',
  minimum_sla = 'minimum_sla',
  bandwidth = 'bandwidth',
  wavelength = 'wavelength',
  bgp_community_support = 'bgp_community_support',
  building_lead_in = 'building_lead_in',
  sd_wan_overlay = 'sd_wan_overlay',
  fixed_line_fiber = 'fixed_line_fiber',
  fixed_line_copper = 'fixed_line_copper',
  ddos_filtering_scrubbing = 'ddos_filtering_scrubbing',
  protected_dual = 'protected_dual',
  '24_x_7_security' = '24_x_7_security',
  dual_power_feed = 'dual_power_feed',
  preferred_data_center = 'preferred_data_center',
  location = 'location',
  power = 'power',
  requirements = 'requirements',
  cores = 'cores',
  lan_phy = 'lan_phy',
  wan_phy = 'wan_phy',
  interface_type = 'interface_type',
  interface_type_single_mode = 'interface_type_single_mode',
  interface_type_multi_mode = 'interface_type_multi_mode',
  enterprise_grade_99_9 = 'enterprise_grade_99_9',
  business_grade_99_5 = 'business_grade_99_5',
  no_sla = 'no_sla',
  sd_wan = 'sd_wan',
  sd_wan_compatible = 'sd_wan_compatible',
  mttr = 'mttr',
  diversity = 'diversity',
  redundancy = 'redundancy',
  access_method = 'access_method',
  access_grade = 'access_grade',
  access_grade_enterprise = 'access_grade_enterprise',
  access_grade_business = 'access_grade_business',
  access_grade_consumer = 'access_grade_consumer',
  rfs_date = 'rfs_date',
  contract_term = 'contract_term',
  transit_type = 'transit_type',
}

export type FeatureKeysType = keyof typeof TECH_SPECS_API_KEYS;

export const getFeatureUIName = (featureKey: FeatureKeysType): string => {
  return {
    [TECH_SPECS_API_KEYS.ethernet]: 'Ethernet',
    [TECH_SPECS_API_KEYS.ip_transit]: 'IP Transit',
    [TECH_SPECS_API_KEYS.dark_fiber]: 'Dark Fiber',
    [TECH_SPECS_API_KEYS.cloud_connectivity]: 'Cloud Connectivity',
    [TECH_SPECS_API_KEYS.cloud_services]: 'Cloud Services',
    [TECH_SPECS_API_KEYS.internet_access]: 'Internet Access',
    [TECH_SPECS_API_KEYS.colocation]: 'Colocation',
    [TECH_SPECS_API_KEYS.mpls]: 'MPLS',
    [TECH_SPECS_API_KEYS.q_in_q]: 'Q in Q',
    [TECH_SPECS_API_KEYS.qos]: 'QoS',
    [TECH_SPECS_API_KEYS.tagged]: 'Tagged',
    [TECH_SPECS_API_KEYS.untagged]: 'Untagged',
    [TECH_SPECS_API_KEYS.protected]: 'Protected',
    [TECH_SPECS_API_KEYS.unprotected]: 'Unprotected',
    [TECH_SPECS_API_KEYS.jumbo_frames]: 'Jumbo Frames',
    [TECH_SPECS_API_KEYS.premium_sla]: 'Premium SLA',
    [TECH_SPECS_API_KEYS.other]: 'Other Services',
    [TECH_SPECS_API_KEYS.bpg_support]: 'BPG Support',
    [TECH_SPECS_API_KEYS.ddos_filtering]: 'DDoS Filtering',
    [TECH_SPECS_API_KEYS.encryption]: 'Encryption',
    [TECH_SPECS_API_KEYS.burst_flexible]: 'Burst Flexible',
    [TECH_SPECS_API_KEYS.fiber]: 'Fiber',
    [TECH_SPECS_API_KEYS.bb_fttx]: 'Broadband FTTx',
    [TECH_SPECS_API_KEYS.fixed_wireless]: 'Fixed Wireless',
    [TECH_SPECS_API_KEYS['4g_5g']]: '4G/5G',
    [TECH_SPECS_API_KEYS.domestic]: 'Domestic',
    [TECH_SPECS_API_KEYS.international]: 'International',
    [TECH_SPECS_API_KEYS.single]: 'Single',
    [TECH_SPECS_API_KEYS.fixed_fiber]: 'Fixed Fiber',
    [TECH_SPECS_API_KEYS.fixed_copper]: 'Fixed Copper',
    [TECH_SPECS_API_KEYS.broadband]: 'Broadband',
    [TECH_SPECS_API_KEYS.enterprise]: 'Enterprise',
    [TECH_SPECS_API_KEYS.business]: 'Business',
    [TECH_SPECS_API_KEYS.consumer]: 'Consumer',
    [TECH_SPECS_API_KEYS.standard]: 'Standard Rack',
    [TECH_SPECS_API_KEYS.deep]: 'Deep Rack',
    [TECH_SPECS_API_KEYS['247_security']]: '24x7 Security',
    [TECH_SPECS_API_KEYS.remote_hands]: 'Remote Hands',
    [TECH_SPECS_API_KEYS.remote_trigger_black_hole]:
      'Remote Trigger Black Hole',
    [TECH_SPECS_API_KEYS.separate_cage]: 'Separate Cage',
    [TECH_SPECS_API_KEYS.dual_power]: 'Dual Power',
    [TECH_SPECS_API_KEYS.cross_connect]: 'Cross Connect',
    [TECH_SPECS_API_KEYS.cross_connects]: 'Cross Connects',
    [TECH_SPECS_API_KEYS.mttr_enterprise]: 'Enterprise Grade (4 hours)',
    [TECH_SPECS_API_KEYS.mttr_business]: 'Business Grade (8 hours)',
    [TECH_SPECS_API_KEYS.mttr_no_sla]: 'No SLA',
    [TECH_SPECS_API_KEYS.lead_in]: 'Lead In',
    [TECH_SPECS_API_KEYS.street_geo]: 'Street Geo',
    [TECH_SPECS_API_KEYS.ru]: 'RU',
    [TECH_SPECS_API_KEYS.quarter_rack]: 'Quarter Rack',
    [TECH_SPECS_API_KEYS.half_rack]: 'Half Rack',
    [TECH_SPECS_API_KEYS.full_rack]: 'Full Rack',
    [TECH_SPECS_API_KEYS.physical]: 'Physical',
    [TECH_SPECS_API_KEYS.internet]: 'Internet',
    [TECH_SPECS_API_KEYS.csp]: 'CSP',
    [TECH_SPECS_API_KEYS.electrical_copper]: 'Electrical/Copper',
    [TECH_SPECS_API_KEYS.fiber_optical]: 'Fiber/Optical',
    [TECH_SPECS_API_KEYS.hand_off]: 'Hand Off',
    [TECH_SPECS_API_KEYS.access_type]: 'Access Type',
    [TECH_SPECS_API_KEYS.minimum_sla]: 'Minimum SLA',
    [TECH_SPECS_API_KEYS.bandwidth]: 'Bandwidth',
    [TECH_SPECS_API_KEYS.wavelength]: 'Wavelength',
    [TECH_SPECS_API_KEYS.bgp_community_support]: 'BGP Community Support',
    [TECH_SPECS_API_KEYS.building_lead_in]: 'Building Lead In',
    [TECH_SPECS_API_KEYS.sd_wan_overlay]: 'SD-WAN Overlay',
    [TECH_SPECS_API_KEYS.fixed_line_fiber]: 'Fixed Line Fiber',
    [TECH_SPECS_API_KEYS.fixed_line_copper]: 'Fixed Line Copper',
    [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: 'DDoS Filtering/Scrubbing',
    [TECH_SPECS_API_KEYS.protected_dual]: 'Protected Dual',
    [TECH_SPECS_API_KEYS['24_x_7_security']]: '24x7 Security',
    [TECH_SPECS_API_KEYS.dual_power_feed]: 'Dual Power Feed',
    [TECH_SPECS_API_KEYS.preferred_data_center]: 'Preferred Data Center',
    [TECH_SPECS_API_KEYS.location]: 'Location',
    [TECH_SPECS_API_KEYS.power]: 'Power',
    [TECH_SPECS_API_KEYS.requirements]: 'Requirements',
    [TECH_SPECS_API_KEYS.cores]: 'Cores',
    [TECH_SPECS_API_KEYS.lan_phy]: 'LAN PHY',
    [TECH_SPECS_API_KEYS.wan_phy]: 'WAN PHY',
    [TECH_SPECS_API_KEYS.interface_type]: 'Interface Type',
    [TECH_SPECS_API_KEYS.interface_type_single_mode]: 'Single Mode',
    [TECH_SPECS_API_KEYS.interface_type_multi_mode]: 'Multi Mode',
    [TECH_SPECS_API_KEYS.enterprise_grade_99_9]: 'Enterprise Grade 99.9%',
    [TECH_SPECS_API_KEYS.business_grade_99_5]: 'Business Grade 99.5%',
    [TECH_SPECS_API_KEYS.no_sla]: 'No SLA',
    [TECH_SPECS_API_KEYS.sd_wan]: 'SD-WAN',
    [TECH_SPECS_API_KEYS.sd_wan_compatible]: 'SD-WAN Compatible',
    [TECH_SPECS_API_KEYS.mttr]: 'MTTR',
    [TECH_SPECS_API_KEYS.diversity]: 'Diversity',
    [TECH_SPECS_API_KEYS.redundancy]: 'Redundancy',
    [TECH_SPECS_API_KEYS.access_method]: 'Access Method',
    [TECH_SPECS_API_KEYS.access_grade]: 'Access Grade',
    [TECH_SPECS_API_KEYS.access_grade_enterprise]: 'Enterprise Grade',
    [TECH_SPECS_API_KEYS.access_grade_business]: 'Business Grade',
    [TECH_SPECS_API_KEYS.access_grade_consumer]: 'Consumer Grade',
    [TECH_SPECS_API_KEYS.rfs_date]: 'RFS Date',
    [TECH_SPECS_API_KEYS.contract_term]: 'Contract Term',
    [TECH_SPECS_API_KEYS.transit_type]: 'Transit Type',
    [TECH_SPECS_API_KEYS.space_required]: 'Space Required',
  }[featureKey];
};

export const MPMatchForMarketsLabel =
  'For better results, enter a street address or data center.';

export const RequestQuoteServicesList = [
  {
    type: 'Colocation',
    key: ServiceTypes.colocation,
    description:
      'Colocation offers a secure environment to rent physical space (leased by the rack, cabinet, cage, or room) for your equipment within a data center. Typically, the data center provider takes care of the important aspects such as power and cooling.',
  },
  {
    type: 'Ethernet',
    key: ServiceTypes.ethernet,
    description:
      'Ethernet offers Point to Point connectivity and is used to connect one location to another for the purposes of transferring data. Ethernet services allow for high speed but typically do not provide dedicated bandwidth and tend to be cheaper than other offerings with dedicated bandwidth.',
  },
  {
    type: 'Dark Fiber',
    key: ServiceTypes.dark_fiber,
    description:
      "Dark Fiber services allow for high speed, dedicated bandwidth, usually between points of less than 80km. Dark Fiber can be considered more secure than other service types because there is no active equipment installed as part of the service, meaning you will need to 'light up' the service with your own equipment.",
  },
  {
    type: 'IP Transit',
    key: ServiceTypes.ip_transit,
    description:
      'IP Transit provides advanced Internet connectivity for more complex enterprise and service provider use cases. It is common for IP Transit products to include burst capacity and billing as well as other advanced features.',
  },
  {
    type: 'Wavelength',
    key: ServiceTypes.wavelength,
    description:
      "Wavelength services provide high speed, dedicated bandwidth and can operate over both short and very long distances. This form of Point to Point connectivity could be from a business' premises to a data center, between data centers or between business premises.",
  },
  {
    type: 'Internet Access',
    key: ServiceTypes.internet_access,
    description:
      'Internet Access provides general purpose Internet connectivity for business purposes. It can be delivered to your business premises or a data center where you colocate.',
  },
];

export const standardBandWidthOptions = [
  { value: '10Mbps', label: '10 Mbps' },
  { value: '20Mbps', label: '20 Mbps' },
  { value: '50Mbps', label: '50 Mbps' },
  { value: '100Mbps', label: '100 Mbps' },
  { value: '200Mbps', label: '200 Mbps' },
  { value: '500Mbps', label: '500 Mbps' },
  { value: '1Gbps', label: '1 Gbps' },
  { value: '10Gbps', label: '10 Gbps' },
  { value: '100Gbps', label: '100 Gbps' },
];
export const standardBandWidthOptionsForWavelength = [
  { value: '1Gbps', label: '1 Gbps' },
  { value: '10Gbps', label: '10 Gbps' },
  { value: '100Gbps', label: '100 Gbps' },
];

export const standardContractTermOptions = [
  { value: '12months', label: '12 months' },
  { value: '24months', label: '24 months' },
  { value: '36months', label: '36 months' },
  { value: '48months', label: '48 months' },
  { value: '60months', label: '60 months' },
];

export const standardCoresOptions = [
  { value: 1, label: '1 Core' },
  { value: 2, label: '2 Cores (1 Pair)' },
  { value: 4, label: '4 Cores(2 Pair)' },
];

export const standardQuantityOptions: { label: string; value: number }[] =
  Array(20)
    .fill(null)
    .map((x, index) => ({ label: (index + 1).toString(), value: index + 1 }));

export const standardPowerOptions: { label: string; value: string }[] = Array(
  20
)
  .fill(null)
  .map((x, index) => ({
    label: `${index + 1}kW`,
    value: `${index + 1}kW`,
  }));
