import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  Slide,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { useAuth } from '../../hooks/useAuth';
import { navigate } from '@reach/router';
import { useLazyReadListingsByCompanyIDQuery } from '../../services/api/marketplace-service/listings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
  })
);
const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const ListingReviewReminder: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const company = user?.company_account_id;
  useEffect(() => {
    const current = window.location.pathname;
    if (
      isAuthenticated &&
      !current.includes('signup') &&
      !current.includes('direct-invite') &&
      !current.includes('assign-invite') &&
      !current.includes('create-new-listing') &&
      !current.includes('buying/pending')
    ) {
      setOpen(true);
    }
  }, [setOpen, isAuthenticated]);
  const [readListingsByCompanyID, { data }] =
    useLazyReadListingsByCompanyIDQuery();

  const pendingListings = data?.meta?.count?.ended ?? 0;

  useEffect(() => {
    if (company) {
      readListingsByCompanyID(
        {
          id: company,
          params: {
            'meta[count]': 'ended',
          },
        },
        true
      );
    }
  }, [company, readListingsByCompanyID]);

  if (pendingListings === 0) return null;

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <DialogTitle id="alert-dialog-slide-title">
              <Grid item xs={11}>
                <Typography component="div" variant="h2" align="center">
                  You have{' '}
                  <Typography component="span" variant="h2" color="primary">
                    {pendingListings}
                  </Typography>{' '}
                  {pendingListings === 1 ? 'listing' : 'listings'} that{' '}
                  {pendingListings === 1 ? 'is ' : 'are '}
                  pending your review!
                </Typography>
              </Grid>

              <IconButton
                className={classes.closeButton}
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Typography variant="body1" align="center">
                Providing feedback to sellers helps ensure competitive, high
                quality, and timely bids for you in the future. With that in
                mind, please take a moment now to respond to the service
                providers that have provided bids for your listings that have
                now ended. You can mark the bids as shortlisted, successful or
                unsuccessful.
              </Typography>
              <DialogActions>
                <Box pt={2} width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() =>
                      navigate('/my-marketplace/buying/pending').then(() =>
                        setOpen(false)
                      )
                    }
                  >
                    Review now
                  </Button>
                </Box>
              </DialogActions>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default ListingReviewReminder;
