import React from 'react';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Typography,
  Grid,
  makeStyles,
  createStyles,
  useMediaQuery,
  Theme,
  Hidden,
} from '@material-ui/core';
import AddServiceProviderContact from '../AddServiceProviderContact';
import { selectProviderContactsByProviderId } from '../../../store/provider-contacts';
import { ProviderContact } from '../../../types/provider-contact';
import { noop } from 'lodash';
import { CompanyProfileResponse } from '../../../types/directory-service/company-profile';
import { ProviderContact as GQLProviderContact } from '../../../types/marketplace-service/gql/service-provider-contact';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { CreateListingFormState } from '../../../containers/CreateListing';
import { DefaultProviderContact } from '../DefaultProviderContact';

interface ServiceProvidersListItemProps {
  provider: CompanyProfileResponse;
  contact?: ProviderContact;
  selected?: boolean;
  onSelect: (
    provider: CompanyProfileResponse,
    contact?: ProviderContact | GQLProviderContact
  ) => void;
  onEdit: (
    provider: CompanyProfileResponse,
    contact?: ProviderContact | GQLProviderContact
  ) => void;
  onRemove: (
    provider: CompanyProfileResponse,
    contact?: ProviderContact
  ) => void;
  onRemoveContact?: (contact: ProviderContact) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignContent: 'center',
      minHeight: 100,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      '&:hover': {
        backgroundColor: '#eef4f9',
      },
    },
    container: {
      padding: theme.spacing(2.5, 0),
    },
    name: { fontWeight: 'bold' },
    image: {
      width: 30,
      margin: 10,
      borderRadius: '25%',
      backgroundColor: theme.palette.common.white,

      [theme.breakpoints.up('sm')]: {
        width: 40,
        margin: 5,
      },
    },
    company: {
      paddingLeft: 10,
      flex: 1,
    },
  })
);

const ServiceProvidersListItem: React.FC<ServiceProvidersListItemProps> = ({
  provider,
  contact,
  selected = false,
  onSelect,
  onEdit,
  onRemove,
  onRemoveContact = noop,
}) => {
  const classes = useStyles();
  const providerContacts = useSelector(
    selectProviderContactsByProviderId(provider.id)
  );
  const value =
    contact ??
    providerContacts.find((contact) => !!contact.attributes.default_contact);
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (selected) {
          onRemove(provider, contact);
        } else {
          onSelect(provider, value);
        }
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        alignContent="center"
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid
          item
          container
          xs={12}
          sm={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={1} sm={2}>
            <Checkbox color="primary" checked={selected} />
          </Grid>
          <Grid item xs={2}>
            <img
              className={classes.image}
              src={provider.attributes.logo}
              alt="provider-logo"
            />
          </Grid>
          <Grid item xs={7} sm={6} className={classes.company}>
            <Typography variant="body1" className={classes.name}>
              {provider.attributes.name}
            </Typography>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid container item xs={12} sm={4} justifyContent="flex-end">
            {!mobile && selected && (
              <>
                <Grid item xs={5}>
                  <FormSpy subscription={{ values: true }}>
                    {({
                      values,
                    }: FormSpyRenderProps<CreateListingFormState>) => {
                      const contacts = values?.provider_contacts.filter(
                        (contact) =>
                          contact.state !== 'delete' &&
                          contact.value?.relationships?.provider?.data?.id ===
                            provider.id
                      );

                      return <DefaultProviderContact contacts={contacts} />;
                    }}
                  </FormSpy>
                </Grid>

                <AddServiceProviderContact
                  provider={provider}
                  contacts={providerContacts}
                  value={contact}
                  onSelect={(contact) => {
                    onSelect(provider, contact);
                  }}
                  onEdit={(contact) => {
                    onEdit(provider, contact);
                  }}
                  onRemove={(contact) => {
                    onRemoveContact(contact);
                  }}
                />
              </>
            )}
          </Grid>
        </Hidden>
      </Grid>

      {mobile && selected && (
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={10}>
            <FormSpy subscription={{ values: true }}>
              {({ values }: FormSpyRenderProps<CreateListingFormState>) => {
                const contacts = values?.provider_contacts.filter(
                  (contact) =>
                    contact.state !== 'delete' &&
                    contact.value?.relationships?.provider?.data?.id ===
                      provider.id
                );

                return <DefaultProviderContact contacts={contacts} />;
              }}
            </FormSpy>
          </Grid>

          <Grid item xs={2}>
            <AddServiceProviderContact
              provider={provider}
              contacts={providerContacts}
              value={contact}
              onSelect={(contact) => {
                onSelect(provider, contact);
              }}
              onEdit={(contact) => {
                onEdit(provider, contact);
              }}
              onRemove={(contact) => {
                onRemoveContact(contact);
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ServiceProvidersListItem;
