import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    borderRadius: 6,
  },
  icon: {
    display: 'block',
    color: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.grey[700],
  },
}));

const InfoBlock: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return (
    <Box p={2} className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <InfoIcon className={classes.icon} />
        </Grid>
        <Grid item xs>
          <Typography variant="body2" className={classes.text}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoBlock;
