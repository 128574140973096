import { useEffect, useMemo } from 'react';
import { useLazyReadUserByIDQuery } from '../services/api/account-service/users';
import { CompanyAccountResponse } from '../types/account-service/company-account';
import { UserResponse } from '../types/account-service/users';
import { getRelationshipContext } from '../utilities/relationships';

export const useUserQuery = (
  user?: string | null
): {
  user: UserResponse | undefined;
  company: CompanyAccountResponse | undefined;
  isFetching: boolean;
} => {
  const [readUserByIDQuery, { data, isFetching }] = useLazyReadUserByIDQuery();

  useEffect(() => {
    if (user) {
      readUserByIDQuery({ id: user, params: { include: 'companyAccount' } });
    }
  }, [user, readUserByIDQuery]);

  const company = useMemo(
    () =>
      getRelationshipContext(
        data?.context.companyAccounts,
        data?.entity.relationships.companyAccount.data?.id
      ),
    [
      data?.context.companyAccounts,
      data?.entity.relationships.companyAccount.data?.id,
    ]
  );

  if (!data?.entity) {
    return {
      user: undefined,
      company: undefined,
      isFetching,
    };
  }

  return {
    user: data.entity,
    company,
    isFetching,
  };
};
