import React from 'react';
import { makeStyles, createStyles, Typography } from '@material-ui/core/';

const useHeaderStyles = makeStyles(
  createStyles({
    logo: {
      width: 'auto',
      height: 'auto',
      maxHeight: 40,
      maxWidth: 200,
      objectFit: 'contain',
    },
  })
);

const CompanyHeader: React.FC<{ name?: string; image?: string }> = ({
  name = '',
  image,
}) => {
  const classes = useHeaderStyles();
  return (
    <>
      {image && (
        <img className={classes.logo} src={image} alt={`${name} logo`} />
      )}
      <Typography variant="h6">{name}</Typography>
    </>
  );
};

export default CompanyHeader;
