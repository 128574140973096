import { useContext } from 'react';
import tw, { styled } from 'twin.macro';
import { isFunction } from '../../../utilities/is-function';
import { Close } from './Close';
import { ModalContext } from './Context';
import type { DialogBaseProps, DialogContentProps } from './types.d';

export const Base = styled.div.attrs({
  role: 'dialog',
  'aria-modal': 'true',
})<DialogBaseProps>(({ size }) => [
  tw`fixed w-full h-full max-w-full overflow-auto bg-white sm:(h-auto maxHeight[85vh] max-w-lg rounded-2xl shadow-card)`,
  size === 'sm' && tw`md:max-w-xl lg:max-w-xl`,
  size === 'md' && tw`md:max-w-xl lg:max-w-2xl`,
  size === 'lg' && tw`md:max-w-2xl lg:max-w-5xl`,
  size === 'auto' && tw`md:max-w-max lg:max-w-max`,
  size === 'full' && tw`md:max-w-full lg:max-w-full`,
]);

const DefaultCloseButton = () => {
  const ctx = useContext(ModalContext);

  return <Close onClick={ctx.closeModal} />;
};

export const Content = ({
  icon,
  close = <DefaultCloseButton />,
  header,
  children,
  footer,
  contentBorder = false,
}: DialogContentProps) => {
  const ctx = useContext(ModalContext);

  return (
    <div css={[tw`grid grid-flow-row py-8`]}>
      {(header || icon || close) && (
        <div
          css={[
            tw`px-8 mb-6`,
            tw`relative flex flex-row justify-between items-center`,
          ]}
        >
          {icon && (
            <div css={tw`absolute left-8 flex justify-center items-center w-8`}>
              {icon}
            </div>
          )}
          <div css={[tw`flex-auto`, icon && tw`pl-10`, close && tw`pr-10`]}>
            {isFunction(header) ? header(ctx) : header}
          </div>
          {close && (
            <div
              css={tw`absolute right-8 flex justify-center items-center w-8`}
            >
              {close}
            </div>
          )}
        </div>
      )}
      <div
        css={[
          tw`px-8 py-1 min-h-0 overflow-auto`,
          contentBorder && tw`border-t border-gray-200 pt-6`,
        ]}
      >
        {isFunction(children) ? children(ctx) : children}
      </div>
      {footer && (
        <div css={[tw`px-8 mt-6`, tw`flex justify-end items-center`]}>
          {isFunction(footer) ? footer(ctx) : footer}
        </div>
      )}
    </div>
  );
};
