import { marketplaceServiceAPI } from './base';
import {
  EntityResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import {
  ListingProviderContactResponse,
  ListingProviderContactRequest,
  ListingProviderContactResponseIncludes,
} from '../../../types/marketplace-service/listing-provider-contact';
import { transformEntityResponse } from '../transform-response';

export const listingProviderContactsAPI = marketplaceServiceAPI.injectEndpoints(
  {
    overrideExisting: process.env.NODE_ENV === 'development',
    endpoints: (builder) => ({
      createListingProviderContact: builder.mutation<
        EntityResponse<
          ListingProviderContactResponse,
          ListingProviderContactResponseIncludes
        >,
        {
          listing: string;
          payload: ListingProviderContactRequest;
          params?: URLQueryParams;
        }
      >({
        query: (args) => ({
          url: `/listings/${encodeURIComponent(
            args.listing
          )}/provider-contacts`,
          params: args?.params,
          method: 'POST',
          body: JSON.stringify({ data: args.payload }),
        }),
        transformResponse: transformEntityResponse,
      }),
      readListingProviderContactByID: builder.query<
        EntityResponse<
          ListingProviderContactResponse,
          ListingProviderContactResponseIncludes
        >,
        { id: string; params?: URLQueryParams }
      >({
        providesTags: (result) =>
          result?.entity
            ? [
                {
                  type: 'ListingProviderContact',
                  id: result.entity.id,
                },
              ]
            : [],
        query: (args) => ({
          url: `/listings/${encodeURIComponent(args.id)}`,
          params: args.params,
          method: 'GET',
        }),
        transformResponse: transformEntityResponse,
      }),
      updateListingProviderContactByID: builder.mutation<
        EntityResponse<
          ListingProviderContactResponse,
          ListingProviderContactResponseIncludes
        >,
        {
          listing: string;
          contact: string;
          payload: ListingProviderContactRequest;
          params?: URLQueryParams;
        }
      >({
        invalidatesTags: (result) =>
          result
            ? [
                {
                  type: 'ListingProviderContact',
                  id: result.entity.id,
                },
              ]
            : [],
        query: (args) => ({
          url: `/listings/${encodeURIComponent(
            args.listing
          )}/provider-contacts/${encodeURIComponent(args.contact)}`,
          params: args.params,
          method: 'PATCH',
          body: JSON.stringify({ data: args.payload }),
        }),
        transformResponse: transformEntityResponse,
      }),
      deleteListingProviderContactByID: builder.mutation<
        void,
        { listing: string; contact: string }
      >({
        invalidatesTags: (result, error, args) => [
          {
            type: 'ListingProviderContact',
            id: args.contact,
          },
        ],
        query: (args) => ({
          url: `/listings/${encodeURIComponent(
            args.listing
          )}/provider-contacts/${encodeURIComponent(args.contact)}`,
          method: 'DELETE',
        }),
      }),
    }),
  }
);

export const {
  useCreateListingProviderContactMutation,
  useDeleteListingProviderContactByIDMutation,
  useLazyReadListingProviderContactByIDQuery,
  usePrefetch,
  useReadListingProviderContactByIDQuery,
  useUpdateListingProviderContactByIDMutation,
} = listingProviderContactsAPI;
