import React, { useState } from 'react';
import { Typography, Button, ButtonProps, Link } from '@material-ui/core';
import ViewBid, { ViewBidDialog } from '../ViewBid';
import { UserResponse } from '../../types/account-service/users';
import { CompanyAccountResponse } from '../../types/account-service/company-account';
import { BidResponse } from '../../types/marketplace-service/bid';

interface BidActionViewProps extends ButtonProps {
  bid: BidResponse;
  company?: CompanyAccountResponse;
  bidder?: UserResponse;
  link?: boolean;
}

const BidActionView: React.FC<Omit<BidActionViewProps, 'css'>> = ({
  bid,
  company,
  bidder,
  link = false,
  ...props
}) => {
  const [viewBid, setViewBid] = useState<boolean>(false);

  const viewBidderInfo = bid?.attributes.state === 'interested';
  return (
    <>
      {link && (
        <Link onClick={() => setViewBid(true)} underline="none">
          <Typography variant="body1" color="primary">
            View Bid
          </Typography>
        </Link>
      )}
      {!link && (
        <Button
          fullWidth
          variant="text"
          onClick={() => setViewBid(true)}
          {...props}
        >
          <Typography variant="subtitle2" color="primary">
            View Bid
          </Typography>
        </Button>
      )}
      <ViewBidDialog
        open={viewBid}
        onClose={() => setViewBid(false)}
        company={company}
        submitted_at={bid.attributes.submitted_at}
        bidder={bid.attributes.contact}
        viewBidderInfo={viewBidderInfo}
      >
        <ViewBid bid={bid} />
      </ViewBidDialog>
    </>
  );
};

export default BidActionView;
