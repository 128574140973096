import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Avatar, Tooltip } from '@material-ui/core';
import { useUserQuery } from '../../hooks/useUserQuery';
import clsx from 'clsx';

const useStyles = makeStyles(
  createStyles({
    avatar: {
      fontSize: 18,
      backgroundColor: (props: { color: string }) => props.color,
    },
    small: {
      height: 28,
      width: 28,
      fontSize: 14,
    },
  })
);

interface InitialsAvatarProps {
  usersName?: string | null;
  userId?: string;
  small?: boolean;
}

const backgrounds = [
  '#fc5c65',
  '#26de81',
  '#10b9b1',
  '#10b9b1',
  '#662d91',
  '#fa8231',
  '#4b7bec',
  '#4b7bec',
  '#778ca3',
  '#f7b731',
];

export const InitialsAvatar: React.FC<InitialsAvatarProps> = ({
  usersName,
  userId = '',
  small,
}) => {
  const firstNumberInUserId = userId.match(/\d/);
  const number =
    firstNumberInUserId !== null ? Number(firstNumberInUserId[0]) : undefined;
  const bgColor = number !== undefined ? backgrounds[number] : '#bdbdbd';
  const classes = useStyles({ color: bgColor });
  const className = clsx(classes.avatar, { [classes.small]: small });

  if (!usersName) {
    // just to support the older listings until the data has been provided
    return <LegacyInitialsAvatar userId={userId} className={className} />;
  }

  const [first = '', last = ''] = usersName.split(' ');

  return (
    <Tooltip title={`${first} ${last}`}>
      <Avatar className={className}>
        {first.charAt(0)}
        {last.charAt(0)}
      </Avatar>
    </Tooltip>
  );
};

const LegacyInitialsAvatar: React.FC<
  InitialsAvatarProps & {
    className: string;
  }
> = ({ userId, className }) => {
  const { user } = useUserQuery(userId);

  if (!user) return null;

  const first = user.attributes.firstName;
  const last = user.attributes.lastName;
  return (
    <Tooltip title={`${first} ${last}`}>
      <Avatar className={className}>
        {first?.charAt(0)}
        {last?.charAt(0)}
      </Avatar>
    </Tooltip>
  );
};
