import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import { getPageTitleTemplate } from '../../../constants';
import { RequestQuoteForm } from '../../../containers/RequestQuote';
import { ROUTES } from '../../../routes';

const RequestQuote: React.FunctionComponent<
  RouteComponentProps<{
    providerId: string;
    title: string;
    navigate: string;
  }>
> = ({ title = '', navigate, providerId }) => {
  if (!providerId) return null;

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        <RequestQuoteForm
          providerId={providerId}
          pageTitle={title}
          onCreate={() => navigate?.(ROUTES.requestQuoteSuccess)}
        />
      </AppLayout>
    </>
  );
};

export default RequestQuote;
