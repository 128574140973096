import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  CircularProgress,
  Hidden,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import BidPreviewModal from '../BidPreviewModal';
import { useAuth } from '../../hooks/useAuth';
import { CreateBidFormState } from '../../types/bid';

const useStyles = makeStyles((theme) => ({
  resetButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

const BiddingFooter: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [showPreview, setShowPreview] = useState(false);
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'), {
    noSsr: true,
  });
  return (
    <FormSpy
      subscription={{
        values: true,
        pristine: true,
        valid: true,
        submitting: true,
      }}
    >
      {({
        form,
        values,
        valid,
        submitting,
        pristine,
      }: FormSpyRenderProps<CreateBidFormState>) => (
        <>
          <Grid
            container
            spacing={3}
            justifyContent={mobile ? 'flex-end' : 'flex-start'}
          >
            <Hidden xsDown>
              <Grid item xs>
                <Button
                  className={classes.resetButton}
                  variant="outlined"
                  onClick={() => form.mutators.resetForm()}
                  disabled={submitting || pristine}
                >
                  Reset form
                </Button>
              </Grid>
            </Hidden>
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setShowPreview(true)}
                disabled={submitting || pristine || !valid}
              >
                Preview
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitting || !valid}
                endIcon={
                  submitting && <CircularProgress color="secondary" size={20} />
                }
              >
                Submit Bid
              </Button>
            </Grid>
          </Grid>
          <BidPreviewModal
            company={user!.company_account_id}
            bid={values}
            open={showPreview}
            onClose={() => setShowPreview(false)}
            onSubmit={() => form.submit()}
            bidder={{
              id: user!.id,
              company: user!.employer,
              name: user!.name,
              email: user!.email,
              phone: user!.phone,
            }}
          />
        </>
      )}
    </FormSpy>
  );
};

export default BiddingFooter;
