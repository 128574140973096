import React, { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, Container, Grid, Link, makeStyles } from '@material-ui/core';
import landingPageImage from '../../../assets/sales-pipeline.png';
import { CLOUDSCENE_URL } from '../../../api';
import CloudsceneLogo from '../../../components/Logo/LogoCloudsceneStatic';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  image: {
    maxWidth: '350px',
    paddingBottom: theme.spacing(2),
  },
  leftContent: {
    padding: theme.spacing(5, 0, 0),
  },
  rightContent: {
    padding: theme.spacing(1.5, 0, 0),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionContainer: {
    marginTop: 'auto',
  },
  action: {
    display: 'flex',
    width: '100%',
  },
  pageWrap: {
    minHeight: '100vh',
    transition: theme.transitions.create('all'),
  },
  pageWrapShift: {
    width: '100%',
  },
  main: {
    paddingTop: 20,
    flexGrow: 5,
    flexBasis: 'auto',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 30,
    },
  },
}));

interface InvitationProps {
  onJoin: () => void;
  onLogin: () => void;
  header: ReactNode;
  body: ReactNode;
}

const Invitation = ({ onJoin, onLogin, header, body }: InvitationProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={clsx(classes.pageWrap, classes.pageWrapShift)}
    >
      <Box my={2} mx={3}>
        <CloudsceneLogo />
      </Box>

      <Container maxWidth="lg" component="main" className={classes.main}>
        <Grid container spacing={10} className={classes.root}>
          <Grid className={classes.gridItem} item xs={12} md={6}>
            {header}
            <div className={classes.leftContent}>{body}</div>
            <Grid container className={classes.actionContainer} spacing={2}>
              <Grid className={classes.gridItem} item xs={6}>
                <Button
                  onClick={onLogin}
                  className={classes.action}
                  variant="contained"
                >
                  Log In
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={onJoin}
                  className={classes.action}
                  variant="contained"
                  color="primary"
                >
                  Join Free
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} md={6}>
            <img
              className={classes.image}
              alt="landing page"
              src={landingPageImage}
            />
            <Typography align="center" variant="h2">
              What is Cloudscene?
            </Typography>
            <div className={classes.rightContent}>
              <Typography align="center">
                Cloudscene is the leading marketplace platform for buyers and
                sellers of network services. Backed by the world's most
                comprehensive market intelligence sourced by the industry, for
                the industry, Cloudscene Marketplace empowers teams to make
                data-driven decisions in an entirely digital way.
              </Typography>
            </div>
            <div className={classes.rightContent}>
              <Typography align="center">
                <Link
                  href={CLOUDSCENE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Cloudscene"
                >
                  cloudscene.com
                </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Invitation;
