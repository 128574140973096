import React from 'react';
import clsx from 'clsx';
import { formatISO } from 'date-fns';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  Button,
  Link,
  Collapse,
} from '@material-ui/core';
import { Mutator } from 'final-form';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Form, FormRenderProps } from 'react-final-form';
import CloudConnectivityBasic from './Basic';
import CloudConnectivityAdvanced from './Advanced';
import validationSchema from './validation';
import { TECH_SPECS_API_KEYS } from '../../../../constants';
import { ServiceCloudConnectivity } from '../../../../types/services';
import { useValidationSchema } from '../../../../hooks/useValidationSchema';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 20,
    height: 20,
    float: 'left',
    transition: '.3s',
  },
  iconActive: {
    transform: 'rotate(180deg)',
  },
}));
const resetAdvanced: Mutator<
  ServiceCloudConnectivity & {
    external_service_name: string;
    external_service_id: string;
  }
> = (
  [
    name = 'attributes.advanced',
    value = getInitialValues().attributes.advanced,
  ],
  state,
  { changeValue }
) => {
  changeValue(state, name, () => value);
};

interface ServiceRequirementsCloudConnectivityProps {
  onDone: (values: any) => void;
  values?: ServiceCloudConnectivity;
}

const getInitialValues = (): ServiceCloudConnectivity => ({
  type: 'cloud_connectivity',
  ready_for_service: formatISO(new Date()),
  contract_term: null,
  additional_info: '',
  attributes: {
    bandwidth: null,
    point_a: null,
    point_z: {
      id: '',
      type: 'cloud',
      csp: '',
      market: '',
      on_ramp: '',
      description: 'Cloud Provider',
    },
    advanced: {
      access_type: null,
      advanced_features: {
        [TECH_SPECS_API_KEYS.burst_flexible]: false,
      },
    },
    documents: [],
  },
});

const ServiceRequirementsCloudConnectivity = ({
  onDone,
  values = getInitialValues(),
}: ServiceRequirementsCloudConnectivityProps) => {
  const classes = useStyles();
  const [collapse, setCollapse] = React.useState(false);
  const validator = useValidationSchema(validationSchema);

  return (
    <Box my={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Cloud Connectivity
          </Typography>
        </Grid>

        <Form
          initialValues={values}
          keepDirtyOnReinitialize
          validate={validator}
          onSubmit={(values) => onDone(values)}
          mutators={{ resetAdvanced }}
          subscription={{ submitting: true, pristine: true, valid: true }}
          render={({
            submitting,
            valid,
            handleSubmit,
          }: FormRenderProps<
            ServiceCloudConnectivity & {
              external_service_name: string;
              external_service_id: string;
            }
          >) => {
            return (
              <Grid item xs>
                <form onSubmit={handleSubmit}>
                  <CloudConnectivityBasic />
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Link
                        onClick={() => {
                          setCollapse((prevState) => !prevState);
                        }}
                      >
                        <KeyboardArrowDownIcon
                          className={clsx(classes.icon, {
                            [classes.iconActive]: collapse,
                          })}
                        />
                        Advanced Options
                      </Link>
                    </Box>
                    <Collapse in={collapse}>
                      <CloudConnectivityAdvanced />
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mx="auto" mt={2} width={200}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        disabled={submitting || !valid}
                      >
                        Done
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </Grid>
            );
          }}
        />
      </Grid>
    </Box>
  );
};

export default ServiceRequirementsCloudConnectivity;
