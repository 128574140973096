import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { directoryServiceAPI } from '../services/api/directory-service/base';
import { accountServiceAPI } from '../services/api/account-service/base';
import { accountServiceSubscriptionAPI } from '../services/api/account-service/base.subscription';
import { actingOnBehalfAPI } from '../services/api/acting-on-behalf/base';
import axios from '../services/axios';
import { marketplaceServiceAPI } from '../services/api/marketplace-service/base';
import { marketplaceServiceGQLAPI } from '../services/api/marketplace-service/gql/base';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          axios,
        },
      },
    }).concat(
      marketplaceServiceGQLAPI.middleware,
      marketplaceServiceAPI.middleware,
      directoryServiceAPI.middleware,
      accountServiceAPI.middleware,
      accountServiceSubscriptionAPI.middleware,
      actingOnBehalfAPI.middleware
    ),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    import('./reducers').then((mod) => store.replaceReducer(mod.default));
  });
}

export default store;
