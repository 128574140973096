import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    width: 100,
    padding: 0,
    paddingLeft: 20,
    margin: 0,
    lineHeight: 0,
  },
  '@keyframes continuousRefill': {
    '0%': {
      fill: 'transparent',
    },

    '100%': {
      fill: 'rgb(0, 158, 255)',
    },
  },
  svgContainerPath: {
    width: 160,
    height: 'auto',
  },
  svgElem1: {
    // bottom cloud path
    animation:
      '$continuousRefill 1.9s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both alternate infinite',
  },
  svgElem2: {
    // top left cloud curve
    animation:
      '$continuousRefill 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both alternate infinite',
  },
  svgElem3: {
    // top right cloud curve
    animation:
      '$continuousRefill 1s cubic-bezier(0.47, 0, 0.745, 0.715) .9s both alternate infinite',
  },
});

const LogoCloudLoader = (): ReactElement => {
  const classes = useStyles({});

  return (
    <Box className={classes.root}>
      <svg
        viewBox="0 0 160 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="logo-cloud"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          className={classes.svgContainerPath}
        >
          <path
            d="M105.35,67.87 L4.61,67.87 C2.06,67.87 0,65.81 0,63.27 C0,60.73 2.06,58.66 4.61,58.66 L105.35,58.66 C108.06,58.66 110.48,57.3 111.23,55.36 L113.06,50.64 L115.62,43.72 C115.64,43.67 115.66,43.62 115.68,43.57 L118.32,36.76 C118.73,35.69 118.59,34.65 117.91,33.64 C117.07,32.42 115.2,31.11 112.44,31.11 L95.58,31.11 C92.81,31.11 89.69,33.41 88.95,35.32 C88.03,37.69 85.37,38.87 82.99,37.95 C80.62,37.03 79.44,34.36 80.36,31.99 C82.55,26.33 89.24,21.9 95.58,21.9 L112.43,21.9 C117.81,21.9 122.69,24.34 125.5,28.43 C127.9,31.93 128.41,36.18 126.9,40.08 L124.29,46.81 L121.73,53.73 C121.71,53.78 121.69,53.83 121.67,53.88 L119.81,58.68 C117.69,64.18 111.88,67.87 105.35,67.87 Z"
            id="Path"
            className={classes.svgElem1}
          ></path>
          <path
            d="M16.68,49.6 C16.15,49.6 15.61,49.51 15.08,49.31 C12.69,48.43 11.48,45.78 12.36,43.39 L19.1,24.87 C19.11,24.85 19.12,24.83 19.12,24.81 C21.25,19.31 27.06,15.62 33.59,15.62 L41.91,15.62 C44.45,15.62 46.52,17.68 46.52,20.23 C46.52,22.78 44.46,24.84 41.91,24.84 L33.6,24.84 C30.9,24.84 28.5,26.19 27.73,28.12 L21,46.59 C20.31,48.45 18.55,49.6 16.68,49.6 Z"
            id="Path"
            className={classes.svgElem2}
          ></path>
          <path
            d="M53.65,41.46 C53.13,41.46 52.6,41.37 52.09,41.19 C49.7,40.33 48.46,37.69 49.32,35.29 L58.6,9.32 C58.61,9.29 58.62,9.25 58.64,9.22 C60.77,3.72 66.58,0.03 73.11,0.03 L97.47,0.03 C102.74,0.03 107.58,2.4 110.4,6.37 C111.87,8.44 111.39,11.32 109.32,12.79 C107.25,14.26 104.37,13.78 102.9,11.71 C101.82,10.19 99.74,9.25 97.48,9.25 L73.11,9.25 C70.42,9.25 68.02,10.59 67.25,12.51 L57.99,38.41 C57.31,40.29 55.54,41.46 53.65,41.46 Z"
            id="Path"
            className={classes.svgElem3}
          ></path>
        </g>
      </svg>
    </Box>
  );
};

export default LogoCloudLoader;
