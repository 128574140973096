import React, { useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import CreateListing from '../../../containers/CreateListing';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import AdminActingAsUser from '../../../components/AdminActingAsUser';
import { getPageTitleTemplate } from '../../../constants';
import { ROUTES } from '../../../routes';
import { useListingInfo } from '../../../components/Listings/ListingInfo/useListingInfo';
import { useLazyReadListingByIDQuery } from '../../../services/api/marketplace-service/listings';

const CopyListing: React.FunctionComponent<
  RouteComponentProps<{
    id: string;
    title: string;
  }>
> = ({ title = 'New Marketplace Listing', navigate, id }) => {
  const { listing } = useListingInfo(id);
  const [readListingById] = useLazyReadListingByIDQuery();
  const onStartActing = useCallback(async () => {
    if (!listing?.id) return undefined;
    readListingById({
      id: listing.id,
      params: {
        include: 'markets',
      },
    });
  }, [readListingById, listing?.id]);

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        {listing && (
          <AdminActingAsUser
            id={listing.attributes.user_id}
            redirect={false}
            onStartActing={onStartActing}
          >
            <CreateListing
              pageTitle="Copy Marketplace Listing"
              onCreate={() => navigate?.(ROUTES.listingSuccess)}
              listing={listing}
              action="copy"
            />
          </AdminActingAsUser>
        )}
      </AppLayout>
    </>
  );
};

export default CopyListing;
