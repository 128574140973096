import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import Divider from '@material-ui/core/Divider';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BidderInfoBlock from '../BidderInfoBlock';
import BidPricingTable from '../BidPricingTable';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CreateBidFormState } from '../../types/bid';
import { getServiceUIName } from '../../constants';
import ServiceDetails from '../ServiceDetails';
import CompanyInfo from '../CompanyInfo';
import AttachmentList from '../AttachmentList';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 1),
  },
  service: {
    background: theme.palette.grey[50],
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  MuiGridItem: {
    padding: theme.spacing(2, 0),
  },
  section: {
    margin: theme.spacing(2, 0),
  },
  block: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonSection: {
    padding: theme.spacing(2),
    display: 'block',
    margin: 'auto',
  },
}));

interface BidPreviewModalProps extends Omit<DialogProps, 'children'> {
  bid: CreateBidFormState;
  company: string;
  bidder?: {
    id: string;
    name: string;
    company: string;
    avatar?: string;
    email: string;
    phone: string;
  };
  onSubmit: () => void;
}

const BidPreviewModal: React.FC<Omit<BidPreviewModalProps, 'css'>> = ({
  bid,
  company,
  bidder,
  onClose,
  onSubmit,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.root }}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      {...rest}
    >
      <DialogTitle>
        <Typography component="div" variant="h2">
          Your Bid Submission
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose?.({}, 'backdropClick')}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <>
        <DialogContent>
          <div className={classes.section}>
            <Grid container spacing={2}>
              <Grid item xs>
                <CompanyInfo company={company} />
              </Grid>
              <Grid item xs="auto">
                <Typography variant="h6" color="textPrimary">
                  {format(new Date(), 'do MMMM yyyy')}
                </Typography>
              </Grid>
            </Grid>
          </div>
          {bid.quotes.map((quote) => (
            <Grid
              key={quote.service.id}
              container
              spacing={2}
              className={classes.service}
            >
              <Grid item xs={12}>
                <ServiceDetails.ExpansionPanel
                  id={quote.service.id!}
                  title={getServiceUIName(quote.service.attributes.type)}
                >
                  <ServiceDetails.Summary service={quote.service.attributes} />
                </ServiceDetails.ExpansionPanel>
              </Grid>
              <Grid item xs={12} className={classes.MuiGridItem}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {!quote.optOut &&
                  quote.service.attributes.type !==
                    'multi_service_requirements' && (
                    <BidPricingTable pricing={quote.pricing} />
                  )}
                {quote.optOut && <Typography color="error">No Bid</Typography>}
              </Grid>
              {quote.additionalInfo && (
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    gutterBottom
                    className={classes.label}
                  >
                    {quote.service.attributes.type ===
                    'multi_service_requirements'
                      ? 'Pricing options/estimates'
                      : 'Additional Information'}
                  </Typography>
                  <Typography>{quote.additionalInfo}</Typography>
                </Grid>
              )}
            </Grid>
          ))}
          <Grid container spacing={4}>
            {bid.installLeadTime && (
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Installation and lead time
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {bid.installLeadTime}
                </Typography>
              </Grid>
            )}
            {bid.termsConditions && (
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Terms and conditions
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {bid.termsConditions}
                </Typography>
              </Grid>
            )}
            {bid.pointOfDifference && (
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Point of difference
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {bid.pointOfDifference}
                </Typography>
              </Grid>
            )}
            {bid.documents.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  Attachments
                </Typography>
                {bid.documents.map((document, index: number) => (
                  <AttachmentList key={index} document={document} />
                ))}
              </Grid>
            )}

            {bidder && (
              <Grid item xs={12} className={classes.section}>
                <BidderInfoBlock {...bidder} />
              </Grid>
            )}
          </Grid>
          <DialogActions>
            <Grid container>
              <Divider />
              <Grid item className={classes.buttonSection}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onClose?.({}, 'backdropClick')}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default BidPreviewModal;
