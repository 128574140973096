import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '../../components/Form/CSTextField';
import { Field } from 'react-final-form';

interface FormSectionProps {
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const AdditionalInfoSection: React.FC<FormSectionProps> = ({ name }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Field
        name={name}
        render={({ input }) => (
          <TextField fullWidth multiline minRows={6} {...input} />
        )}
      />
    </Box>
  );
};

export default AdditionalInfoSection;
