import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ACCOUNT_URL } from '../../api';
import { Account } from './index';
import { ThunkExtra } from '../types.d';

export const fetchAccountById = createAsyncThunk<
  {
    data: Account;
  },
  string,
  { extra: ThunkExtra }
>('account/fetchById', async (id, { extra: { axios } }) => {
  const response = await axios.get(`${API_ACCOUNT_URL}/accounts/${id}`);

  return response.data;
});
