import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export type TextReadMoreProp = {
  text: string;
  substringLength?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  })
);

const TextReadMore = ({
  text = '',
  substringLength = 350,
}: TextReadMoreProp) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(true);
  const body = collapse ? (
    <>
      {text.substr(0, substringLength)}
      <Link className={classes.root} onClick={() => setCollapse(!collapse)}>
        ..show more
      </Link>
    </>
  ) : (
    <>
      {text}
      <Link className={classes.root} onClick={() => setCollapse(!collapse)}>
        {' show less'}
      </Link>
    </>
  );

  return (
    <Box py={2} display="flex" flexDirection="row">
      <Typography>{text.length >= substringLength ? body : text}</Typography>
    </Box>
  );
};
export default TextReadMore;
