import {
  Link as MaterialLink,
  LinkBaseProps,
  makeStyles,
} from '@material-ui/core';
import { Link, LinkProps } from '@reach/router';
import React from 'react';

const useStyles = makeStyles(() => ({
  link: {
    '&:hover': {
      opacity: 1,
    },
  },
}));

const UnstyledLink: React.FC<
  React.PropsWithoutRef<Omit<LinkProps<any>, 'css'>> &
    React.RefAttributes<HTMLAnchorElement> &
    Omit<LinkBaseProps, 'css'>
> = (props) => {
  const classes = useStyles();
  return (
    <MaterialLink
      className={classes.link}
      component={Link}
      underline="none"
      {...props}
    >
      {props.children}
    </MaterialLink>
  );
};

export default UnstyledLink;
