import React, { ReactElement, useEffect } from 'react';
import { navigate, useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import NavigationListItem from '../../../components/NavigationListItem';
import { ROUTES } from '../../../routes';
import { useLazyReadListingsByCompanyIDQuery } from '../../../services/api/marketplace-service/listings';
import { useAuth } from '../../../hooks/useAuth';

const useListStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  chip: {
    display: 'inline-flex',
    marginLeft: theme.spacing(1.2),
    padding: theme.spacing(0.4, 0.8),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
  },
}));

const BuyingNavigation = (): ReactElement => {
  const listClasses = useListStyles();
  const location = useLocation();
  const { user } = useAuth();
  const [readListingsByCompanyID, { data }] =
    useLazyReadListingsByCompanyIDQuery();

  const pendingListings = data?.meta?.count?.ended ?? 0;

  useEffect(() => {
    if (user) {
      readListingsByCompanyID(
        {
          id: user.company_account_id,
          params: {
            'meta[count]': 'ended',
          },
        },
        true
      );
    }
  }, [user, readListingsByCompanyID]);
  return (
    <List component="nav" className={listClasses.root}>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.buyingListings)}
        onClick={() => navigate(ROUTES.buyingListings)}
      >
        Listings
      </NavigationListItem>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.buyingPending)}
        onClick={() => navigate(ROUTES.buyingPending)}
      >
        Ready to Review
        {pendingListings > 0 && (
          <span className={listClasses.chip}>{pendingListings}</span>
        )}
      </NavigationListItem>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.buyingCompleted)}
        onClick={() => navigate(ROUTES.buyingCompleted)}
      >
        Completed
      </NavigationListItem>
    </List>
  );
};

export default BuyingNavigation;
