import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  IconButton,
  Popover,
  PopoverOrigin,
  MenuList,
  IconButtonProps,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHorizRounded';

interface ActionMenuProps {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      color: theme.palette.grey[500],

      '&:hover': {
        color: theme.palette.grey[700],
      },
    },
    menu: {
      borderRadius: 8,
    },
  })
);

const ActionMenu: React.FC<
  ActionMenuProps & {
    iconButtonProps?: Omit<IconButtonProps, 'css'>;
  }
> = ({
  children,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  iconButtonProps = {},
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        {...iconButtonProps}
      >
        <MoreIcon className={classes.actions} />
      </IconButton>
      <Popover
        open={!!anchorEl}
        onClose={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{
          className: classes.menu,
        }}
      >
        <MenuList disablePadding>
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                key: index,
                onClick: () => {
                  setAnchorEl(null);
                  child.props.onClick?.();
                },
              });
            }
          })}
        </MenuList>
      </Popover>
    </>
  );
};

export default ActionMenu;
