import React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import FileUpload from '../../FileUpload';
import { Document } from '../../../types/document';
import AttachmentList from '../../AttachmentList';
import { deleteDocument } from '../../../api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    close: {
      color: theme.palette.grey[500],
    },
  })
);

interface AttachFileProps {
  open?: boolean;
  onClose?: () => void;
  onDone?: (values: any[]) => void;
}

const AttachFiles: React.FC<AttachFileProps> = ({
  open = false,
  onClose = () => {},
  onDone = () => {},
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle disableTypography className={classes.title}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Attachments
          </Typography>
          <Typography variant="body2" gutterBottom>
            Provide documentation to support your listing requirements.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={(values) => {
            onDone(values.documents);
          }}
          keepDirtyOnReinitialize
          mutators={{ ...arrayMutators }}
          subscription={{ submitting: true, pristine: true }}
        >
          {({
            form,
            handleSubmit,
          }: FormRenderProps<{ documents: Document[] }>) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box py={2}>
                  <FileUpload
                    onAdd={(file) => {
                      form.mutators.push('documents', file);
                    }}
                  ></FileUpload>
                </Box>
                <FormSpy subscription={{ values: true }}>
                  {(props) => {
                    const documents = props.values?.documents
                      ? props.values?.documents
                      : [];
                    return documents.map(
                      (document: Document, index: number) => (
                        <AttachmentList
                          document={document}
                          key={index}
                          onDelete={(id: string) => {
                            deleteDocument(id);
                            form.mutators.remove('documents', index);
                          }}
                        />
                      )
                    );
                  }}
                </FormSpy>

                {children}
                <Box my={2} mx="auto" width="200px">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Done
                  </Button>
                </Box>
              </form>
            );
          }}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AttachFiles;
