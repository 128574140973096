import { object, string, number, boolean } from 'yup';
import { TECH_SPECS_API_KEYS } from '../../../../constants';

export const schema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      csp: string(),
      market: string(),
      on_ramp: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({ value: number().required(), unit: string().required() })
      .nullable()
      .required()
      .label('Bandwidth'),
    advanced: object({
      access_type: string().nullable(),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.burst_flexible]: boolean(),
      }),
    }).defined(),
  }).defined(),
}).defined();

export default schema;
