import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { useCompanyQuery } from '../../hooks/useCompanyQuery';
import { useUserQuery } from '../../hooks/useUserQuery';
import { CompanyInfoDisplay } from '../CompanyInfo';
import BidActionView from './BidActionView';
import { BidResponse } from '../../types/marketplace-service/bid';

interface UnsuccessfulProviderProps {
  bid: BidResponse;
  onUnsuccessfulReasonChange: (bid: BidResponse, reason: string) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    reason: {
      margin: theme.spacing(-2, 2, 0, 2),
    },
    view: {
      textAlign: 'right',
      margin: theme.spacing(0, 2),
    },
  })
);

const UnsuccessfulProvider: React.FC<UnsuccessfulProviderProps> = ({
  bid,
  onUnsuccessfulReasonChange,
}) => {
  const classes = useStyles();
  const company = useCompanyQuery(bid.relationships.companyAccount?.data.id);
  const userQuery = useUserQuery(bid.relationships.user?.data.id);

  return (
    <Paper elevation={4}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6}>
          {company.data && (
            <CompanyInfoDisplay company={company.data.entity} size="small" />
          )}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.view}>
            <BidActionView
              bid={bid}
              company={company.data?.entity}
              bidder={userQuery.user}
              link
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UnsuccessfulProvider;
