import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { format, isAfter, parseISO } from 'date-fns';
import clsx from 'clsx';
import { getFeatureUIName } from '../../constants';
import {
  ServiceColocation as ServiceColocationProps,
  ServiceTypes,
} from '../../types/services';
import MarketPlaceMatchInfo from '../MarketPlaceMatchInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);

const ServiceDetailsColocation: React.FC<
  ServiceColocationProps & {
    marketplaceMatchLabels?: boolean;
    listingEndDate?: string;
  }
> = ({
  attributes,
  contract_term,
  ready_for_service,
  additional_info,
  marketplaceMatchLabels,
  listingEndDate,
}) => {
  const classes = useStyles();
  let pointA = attributes.point_a?.description;
  if (attributes.point_a?.type === 'data-center') {
    pointA = attributes.point_a?.address
      ? `${pointA} (${attributes.point_a?.address})`
      : pointA;
  }
  return (
    <Grid container spacing={3}>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={12} xl={6}>
          <Typography component="div" gutterBottom className={classes.label}>
            Location (City)
          </Typography>
          <Typography
            component="div"
            title={attributes.point_a?.description}
            className={classes.title}
          >
            {pointA}
          </Typography>
          {marketplaceMatchLabels && (
            <MarketPlaceMatchInfo
              aEnd={attributes.point_a}
              serviceType={ServiceTypes.colocation}
            />
          )}
        </Grid>
        {/* Removing Preferred Data Centre for Pre MVP*/}
        {/* <Grid item xs={12} md={6}>
          <Typography component="div" gutterBottom className={classes.label}>
            {attributes.point_z ? 'Preferred Datacenter' : ''}
          </Typography>
          <Typography
            component="div"
            noWrap
            title={attributes.point_z?.description}
            className={classes.title}
          >
            {attributes.point_z?.description}
          </Typography>
        </Grid> */}
      </Grid>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={6} md={3}>
          <Typography component="div" gutterBottom className={classes.label}>
            Contract Term
          </Typography>
          <Typography component="div" noWrap className={classes.title}>
            {contract_term?.value === 1 && contract_term?.unit === 'month'
              ? 'Monthly'
              : `${contract_term?.value} ${contract_term?.unit}`}
          </Typography>
        </Grid>
        {ready_for_service && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              RFS Date
            </Typography>
            <Typography
              component="div"
              noWrap
              className={clsx(classes.title, {
                [classes.error]:
                  listingEndDate &&
                  isAfter(
                    parseISO(listingEndDate),
                    parseISO(ready_for_service)
                  ),
              })}
            >
              {format(parseISO(ready_for_service), 'd MMMM yyyy')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} md={3}>
          <Typography component="div" gutterBottom className={classes.label}>
            Requirements
          </Typography>
          <Typography component="div" noWrap className={classes.title}>
            {attributes.requirements?.value} {attributes.requirements?.unit}
            {attributes.requirements?.value !== 1 ? 's' : ''}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography component="div" gutterBottom className={classes.label}>
            Power
          </Typography>
          <Typography component="div" noWrap className={classes.title}>
            {attributes.power?.value} {attributes.power?.unit}
          </Typography>
        </Grid>

        {attributes.advanced.space_required && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('space_required')}
            </Typography>
            <Typography component="div" noWrap className={classes.title}>
              {getFeatureUIName(attributes.advanced.space_required)}
            </Typography>
          </Grid>
        )}
        {attributes?.advanced?.additional_services &&
          Object.values(attributes?.advanced?.additional_services).some(
            (val) => val
          ) && (
            <Grid item xs={6} md={3}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Additional Services
              </Typography>
              {Object.entries(attributes.advanced.additional_services)
                .filter(([, value]) => value)
                .map(([key]) => (
                  <Typography
                    key={key}
                    component="div"
                    noWrap
                    className={classes.title}
                  >
                    {getFeatureUIName(
                      key as keyof typeof attributes.advanced.additional_services
                    )}
                  </Typography>
                ))}
            </Grid>
          )}
        {/*
          This is added for legacy compatibility. Additional Services and
          Advanced Features can't exist on the same service at the same time
        */}
        {attributes?.advanced?.advanced_features &&
          Object.values(attributes?.advanced?.advanced_features).some(
            (val) => val === 1 || val === '1'
          ) && (
            <Grid item xs={6} md={3}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Advanced Features
              </Typography>
              {Object.entries(attributes.advanced.advanced_features)
                .filter(([, value]) => value === 1 || value === '1')
                .map(([key]) => (
                  <Typography
                    key={key}
                    component="div"
                    noWrap
                    className={classes.title}
                  >
                    {getFeatureUIName(
                      key as keyof typeof attributes.advanced.advanced_features
                    )}
                  </Typography>
                ))}
            </Grid>
          )}
        {additional_info && (
          <Grid item xs={12}>
            <Typography component="div" gutterBottom className={classes.label}>
              Additional Information
            </Typography>
            <Typography component="div" className={classes.title}>
              {additional_info}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ServiceDetailsColocation;
