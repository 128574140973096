import { object, string, number } from 'yup';

export const schema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({
      value: number().required().min(1),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    advanced: object({
      phy: string().nullable().label('Physical'),
      redundancy: string().nullable().label('Redundancy'),
      minimum_sla: string().nullable().label('Minimum SLA'),
    }),
  }).defined(),
}).defined();

export default schema;
