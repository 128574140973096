import { object, string, boolean } from 'yup';

export const schema = object({
  type: string(),
  additional_info: string().required().label('Additional Notes'),
  attributes: object({
    requirements: object({
      ethernet: boolean(),
      ip_transit: boolean(),
      dark_fiber: boolean(),
      cloud_connectivity: boolean(),
      wavelength: boolean(),
      colocation: boolean(),
      sd_wan: boolean(),
      other: boolean(),
    }).required('Requirements'),
  }).defined(),
}).defined();

export default schema;
