import { CreateBidFormState, QuotePricing } from '../../types/bid';
import { Bid, BidStateUpdate } from '../../types/bid';
import { Document } from '../../types/document';
import { AuthUserType } from '../../hooks/useAuth';
import {
  BidPricingOptionRequest,
  BidRequest,
  BidServiceRequest,
  BidServiceResponse,
  BidState,
} from '../../types/marketplace-service/bid';
import { ListingServiceResponse } from '../../types/marketplace-service/listing-service';

export const makeBidRequest = (
  bid: CreateBidFormState,
  id: string,
  user?: AuthUserType
): BidRequest => {
  return {
    type: 'bid',
    attributes: {
      state: 'submitted',
      installation_and_lead_time: bid.installLeadTime,
      capabilities: bid.pointOfDifference,
      terms_and_conditions: bid.termsConditions,
      contact: {
        full_name: user?.name || '',
        phone: user?.phone || '',
        email: user?.email || '',
      },
    },
    relationships: {
      listing: { data: { type: 'listing', id } },
      documents: {
        data: bid.documents.map(
          (doc): Document => ({
            type: 'document',
            id: doc.id,
            attributes: doc.attributes,
          })
        ),
      },
    },
  };
};

export const makeBidStateUpdate = (
  state: BidState,
  bid: Bid,
  state_uninterested_reason?: string
): BidStateUpdate => {
  return {
    type: 'bid',
    id: bid.id!,
    attributes: {
      state,
      state_uninterested_reason,
    },
  };
};

export const makeBidServiceRequest = (
  info: string,
  service: ListingServiceResponse
): BidServiceRequest => {
  return {
    type: 'bid-service',
    attributes: {
      additional_information: info,
    },
    relationships: {
      'listing-service': {
        data: {
          type: 'listing-service',
          id: service.id,
        },
      },
    },
  };
};

export const makeBidPricingOptionRequest = (
  service: BidServiceResponse,
  pricing: QuotePricing
): BidPricingOptionRequest => {
  return {
    type: 'pricing-option',
    attributes: {
      contract_term_value: Number(pricing.contractTerm!.value),
      contract_term_unit: pricing.contractTerm!.unit,
      cost_value: Number(pricing.nonRecurringCost.value),
      cost_currency: pricing.nonRecurringCost.unit,
      recurring_cost_value: Number(pricing.recurringCost.value),
      recurring_cost_currency: pricing.recurringCost.unit,
      recurring_cost_frequency_value: Number(pricing.contractTerm!.value),
      recurring_cost_frequency_unit: pricing.contractTerm!.unit,
    },
    relationships: {
      'bid-service': {
        data: {
          type: 'bid-service',
          id: service.id,
        },
      },
    },
  };
};
