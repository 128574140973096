import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import ListingGroupProps from './ListingGroupProps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      flexDirection: 'column',
    },
    paper: {
      borderColor: 'transparent',
      borderRadius: 8,
      padding: theme.spacing(2, 3),
    },
    title: {
      fontSize: 16,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    header: {
      fontSize: 14,
      color: theme.palette.grey[700],
      fontWeight: 'bold',
    },
    content: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    empty: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ListingPanel: React.FunctionComponent<ListingGroupProps> = ({
  title,
  empty,
  children,
  opportunity,
}) => {
  const classes = useStyles();

  if (!children && !empty) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid container item xs={4} alignItems="center" wrap="nowrap">
            <Typography
              className={classes.title}
              component="div"
              color="textPrimary"
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                {opportunity ? 'Company' : 'Listing Type'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div" className={classes.header}>
                Owner
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                Expiry
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                Status
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {!children || (Array.isArray(children) && children.length === 0) ? (
        <div className={classes.empty}>{empty}</div>
      ) : (
        <div className={classes.content}>{children}</div>
      )}
    </div>
  );
};

export default ListingPanel;
