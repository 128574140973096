import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import { ProviderGatewayServiceProvider } from '../../types/provider-gateway';
import { createListing } from '../listings';

const gatewayMatchedProviderAdapter =
  createEntityAdapter<ProviderGatewayServiceProvider>({
    selectId: (provider) => provider.id,
  });
const gatewayMatchedProviders = createSlice({
  name: 'gateway-product-providers',
  initialState: gatewayMatchedProviderAdapter.getInitialState(),
  reducers: {
    mergeMatchedProviders: gatewayMatchedProviderAdapter.upsertMany,
    resetMatchedProviders: (state) => {
      gatewayMatchedProviderAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createListing.fulfilled, (state) => {
      gatewayMatchedProviderAdapter.removeAll(state);
    });
  },
});

export const {
  selectById: selectMatchedServiceProviderById,
  selectAll: selectAllMatchedServiceProviders,
  selectTotal: selectMatchedServiceProviders,
  selectIds: selectAllMatchedServiceProviderIds,
} = gatewayMatchedProviderAdapter.getSelectors(
  (state: RootState) => state.gatewayMatchedProviders
);

export const { mergeMatchedProviders, resetMatchedProviders } =
  gatewayMatchedProviders.actions;

export default gatewayMatchedProviders.reducer;
