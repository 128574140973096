import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ToggleButton } from '@material-ui/lab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export interface ListingFiltersProps {
  publicTenders?: boolean;
  setPublicTenders: (publicTenders: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  allListingsButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.text.primary}!important`,
  },
}));

const ListingFilters: React.FC<ListingFiltersProps> = ({
  publicTenders,
  setPublicTenders,
}) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom className={classes.title}>
            Listings
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <ToggleButton
              classes={{
                root: classes.allListingsButton,
                selected: classes.allListingsButton,
              }}
              size="small"
              selected={true}
              style={{ width: '100%' }}
              value="All Listings"
            >
              All listings
            </ToggleButton>
          </Grid>
        </Grid>
      </Grid>
      <Box my={2}>
        <Grid container alignItems="center">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={publicTenders}
                  color="primary"
                  onChange={() => setPublicTenders(!publicTenders)}
                />
              }
              label="Public Sector Tenders"
            />
          </Grid>
          <Grid item>
            <Tooltip title="Government tenders where bid submissions take place via external websites.">
              <HelpOutlineIcon color="disabled" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ListingFilters;
