import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import { useAuth } from '../../../hooks/useAuth';
import AppLayout from '../../../containers/AppLayout/AppLayout';
import { getPageTitleTemplate } from '../../../constants';
import * as ListingInfo from '../../../components/Listings/ListingInfo';
import AdminListingProviders from '../../../components/AdminListingProviders';
import { Box, Divider, Grid, Theme, useMediaQuery } from '@material-ui/core';
import { ListingOpportunityActionMenu } from '../../../containers/ListingOpportunityManager';
import { isListingOwner } from '../../../utilities/listings';
import ListingServiceProviders from '../../../components/ListingServiceProviders';

interface ViewListingProps extends RouteComponentProps<{ id: string }> {
  title?: string;
}

const ViewListing: React.FunctionComponent<ViewListingProps> = ({
  title = 'View Listing',
  id,
}) => {
  const { user } = useAuth();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        <ListingInfo.Container id={id}>
          <ListingInfo.Header navigateToOpportunity>
            {mobile && (
              <Grid container justifyContent="flex-end">
                <ListingOpportunityActionMenu />
              </Grid>
            )}
          </ListingInfo.Header>
          {mobile && (
            <Box mt={2} mx={-2}>
              <Divider />
            </Box>
          )}
          <ListingInfo.Content>
            <ListingInfo.ListingInfoContext.Consumer>
              {({ listing }) => {
                if (
                  isListingOwner(listing?.attributes.user_id, user?.id) ||
                  user?.csAdmin
                ) {
                  return user?.csAdmin ? (
                    <AdminListingProviders />
                  ) : (
                    <ListingServiceProviders />
                  );
                }
              }}
            </ListingInfo.ListingInfoContext.Consumer>
          </ListingInfo.Content>
        </ListingInfo.Container>
      </AppLayout>
    </>
  );
};

export default ViewListing;
