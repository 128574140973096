import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import { CompanyProfile } from '../../types/service-provider';
import { ProviderContact } from '../../types/marketplace-service/gql/service-provider-contact';
import { ServiceProviderContactAttributes } from '../../types/marketplace-service/service-provider-contact';

export interface ServiceProviderInfoChipProps extends ChipProps {
  provider: CompanyProfile;
  contact?: ProviderContact | ServiceProviderContactAttributes;
  onDelete?: () => void;
}

export const ServiceProviderInfoChip = ({
  provider,
  contact,
  onDelete,
  ...props
}: Omit<ServiceProviderInfoChipProps, 'css'>) => (
  <Chip
    label={
      contact
        ? `${provider.attributes.name} - ${contact.first_name} ${contact.last_name}`
        : provider.attributes.name
    }
    onDelete={onDelete}
    {...props}
  />
);

export default ServiceProviderInfoChip;
