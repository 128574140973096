import React, { useContext } from 'react';
import { Theme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { parseISO } from 'date-fns';
import ListingType from '../../ListingType';
import ListingOpportunityManager from '../../../containers/ListingOpportunityManager';
import ListingStatePill from '../../ListingStatePill';
import ListingExpiry from '../../ListingExpiry';
import { ListingInfoContext } from './Context';
import { isListingOwner } from '../../../utilities/listings';
import { useAuth } from '../../../hooks/useAuth';
import CompanyHeader from './CompanyHeader';
import EnableIntendToBid from '../../EnableIntendToBid';
import { isSalesManager } from '../../../utilities/users';

const ListingInfoHeader: React.FC<{
  navigateToOpportunity?: boolean;
  onFocusBidForm?: () => void;
}> = ({ navigateToOpportunity = false, onFocusBidForm, children }) => {
  const { user } = useAuth();
  const { listing, opportunity } = useContext(ListingInfoContext);
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const userIsListingOwner = isListingOwner(
    listing?.attributes.user_id,
    user?.id
  );
  const userIsOpportunityManager = isListingOwner(
    opportunity?.attributes.external_assigned_to_user_id,
    user?.id
  );
  const userIsSalesManager = isSalesManager(user);
  const hasDeclined = !!opportunity?.attributes.declined_at;
  const isInvited = !hasDeclined && !!opportunity?.attributes.invited_at;
  const showStatePill = !userIsListingOwner && (hasDeclined || isInvited);

  if (!listing) {
    return null;
  }

  return (
    <>
      {mobile && (
        <>
          <ListingOpportunityManager
            listing={listing}
            provider={opportunity}
            navigateToOpportunity={navigateToOpportunity}
            onFocusBidForm={onFocusBidForm}
          >
            {children && children}
          </ListingOpportunityManager>
          <Grid item container direction="column" spacing={1}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {
                  <CompanyHeader
                    name={listing.attributes.company}
                    image={listing.attributes.company_logo}
                  />
                }
              </Grid>
              <Grid item>
                <ListingExpiry
                  end={parseISO(listing.attributes.end)}
                  state={listing.attributes.state}
                />
              </Grid>
            </Grid>
            <Grid item>
              {showStatePill && (
                <Typography variant="h4">
                  <ListingStatePill
                    hasDeclined={hasDeclined}
                    isInvited={isInvited}
                  />
                </Typography>
              )}
              <Typography variant="body1">
                {listing.attributes.title}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {!mobile && (
        <>
          {children ? (
            <Grid container spacing={1} alignItems="center">
              {children}
              <Grid item xs container justifyContent="flex-end">
                <ListingExpiry
                  end={parseISO(listing.attributes.end)}
                  state={listing.attributes.state}
                />
              </Grid>
              {showStatePill && (
                <Grid item xs={12}>
                  <Typography variant="h3">
                    <ListingStatePill
                      hasDeclined={hasDeclined}
                      isInvited={isInvited}
                    />
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={2} justifyContent="space-between">
              {showStatePill && (
                <Grid item xs>
                  <Typography variant="h3">
                    <ListingStatePill
                      hasDeclined={hasDeclined}
                      isInvited={isInvited}
                    />
                  </Typography>
                </Grid>
              )}

              <Grid item container xs justifyContent="flex-end">
                <ListingExpiry
                  end={parseISO(listing.attributes.end)}
                  state={listing.attributes.state}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <ListingOpportunityManager
              listing={listing}
              provider={opportunity}
              navigateToOpportunity={navigateToOpportunity}
              onFocusBidForm={onFocusBidForm}
            />
          </Grid>
          {listing.attributes.state === 'published' &&
            hasDeclined &&
            (userIsSalesManager || userIsOpportunityManager) && (
              <EnableIntendToBid
                listingId={listing.id}
                providerId={opportunity?.id}
                listingShortId={listing.attributes.short_id}
              />
            )}

          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                {
                  <CompanyHeader
                    name={listing.attributes.company}
                    image={listing.attributes.company_logo}
                  />
                }
              </Grid>
              <Grid item xs="auto">
                <ListingType type={listing.attributes.visibility} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">{listing.attributes.title}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ListingInfoHeader;
