import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { useAppState } from '../../contexts/AppState';
import TwoColumnIcon from '@material-ui/icons/ViewAgenda';
import StopIcon from '@material-ui/icons/StopRounded';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
    },
  },
  // the icons use more white space than desired
  headerContainer: {
    lineHeight: '0.5rem',
  },
  // coudn't find the two column icon from designs, so just rotated one that was horizontal
  splitScreenIcon: {
    transform: 'rotate(90deg)',
    cursor: 'pointer',
  },
  // square icon is too small & upping the font size to "large" makes it way too big
  fullScreenIcon: {
    transform: 'scale(1.60)',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}));

const MarketPlaceHeaderNav: React.FC<{
  title: string;
  isDetail: boolean;
}> = ({ title, isDetail, children }) => {
  const classes = useStyles();
  const appState = useAppState();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const allowLayoutChange = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up('lg'),
    {
      noSsr: true,
    }
  );

  if (mobile && isDetail) {
    return null;
  }
  return (
    <Box className={classes.root}>
      <Box mb={mobile ? 2 : 4}>
        <Grid
          className={classes.headerContainer}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h2">{title}</Typography>
          </Grid>
          {allowLayoutChange && (
            <Grid item>
              <StopIcon
                onClick={() => appState.setLayoutPreference('FULLSCREEN')}
                color={
                  appState.layoutPreference === 'FULLSCREEN'
                    ? 'primary'
                    : 'disabled'
                }
                className={classes.fullScreenIcon}
              />
              <TwoColumnIcon
                color={
                  appState.layoutPreference === 'SPLITSCREEN'
                    ? 'primary'
                    : 'disabled'
                }
                className={classes.splitScreenIcon}
                onClick={() => appState.setLayoutPreference('SPLITSCREEN')}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {mobile && !isDetail && children}
    </Box>
  );
};

export default MarketPlaceHeaderNav;
