import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ListingInfoContext } from '../Listings/ListingInfo';
import { useUserQuery } from '../../hooks/useUserQuery';
import FlagsmithFeature from '@cloudscene-dev/flagsmith-react';
import { FLAGSMITH_FEATURES } from '../../utilities/flagsmith-config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: 150,
    },
  })
);

const AdminClientDetails: React.FC = () => {
  const { listing } = useContext(ListingInfoContext);
  const classes = useStyles();
  const { user, company } = useUserQuery(
    listing?.attributes?.admin_client_account_service_users_id
  );

  if (user && company) {
    return (
      <FlagsmithFeature id={FLAGSMITH_FEATURES.CLIENT_DETAILS}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="caption" component="div" color="textSecondary">
              Cloudscene Admin
            </Typography>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">
                Client details
              </Typography>
            </Grid>
            {company.attributes.logoUrl && (
              <Grid item xs="auto">
                <img
                  src={company.attributes.logoUrl}
                  alt={company.attributes.name}
                  className={classes.logo}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body1">
                {user.attributes.firstName} {user.attributes.lastName}
              </Typography>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >
                {user.attributes.email}
              </Typography>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                gutterBottom
              >
                {user.attributes.companyName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </FlagsmithFeature>
    );
  }
  return null;
};

export default AdminClientDetails;
