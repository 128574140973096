export type PresenceType = 'near-net' | 'on-net' | 'off-net';
export enum ProductStatusLabels {
  on = 'On-Net',
  off = 'Off-Net',
  near = 'Near-Net',
  available = 'Services Available',
  unavailable = 'Not Available',
  onNet = 'On-Net',
  offNet = 'Off-Net',
  nearNet = 'Near-Net',
}
export type Category =
  | 'Point to Point'
  | 'Internet'
  | 'WAN'
  | 'Cloud'
  | 'Colocation';
export type SubCategory = 'Dark Fiber' | 'Wavelength' | 'Ethernet' | 'SDH';
export type FacilityQuery = { id: string; type: 'datacenter' };
export type MarketQuery = { id: string; type: 'market' };
export type AddressQuery = {
  placeId: string;
  transactionId?: string;
};

export type MarketplaceMatchResults = {
  id: string;
  presence: {
    aEnd: PresenceType;
  };
  serviceProvider: {
    name: string;
    logo: string;
    queryTime: string;
  };
  products: {
    aEnd: [];
  };
};

export type MarketplaceMatchQuery = {
  product: {
    category: Category;
    subCategory?: SubCategory[] | [];
  };
  aEnd: FacilityQuery | AddressQuery | MarketQuery;
  providers?: [];
};

export type MarketPlaceMatchMeta = {
  onNet: {
    aEnd: number;
  };
  nearNet: {
    aEnd: number;
  };
  offNet: {
    aEnd: number;
  };
};
