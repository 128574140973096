import React from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import clsx from 'clsx';

interface ButtonGroupOptionProps {
  name?: string;
  value: string;
  className?: string;
  checked?: boolean;
  onClick?: (
    event: React.SyntheticEvent<HTMLInputElement>,
    value: string
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  text: {
    position: 'relative',
    zIndex: 2,
  },
  label: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 1,
    width: '50%',
    color: theme.palette.grey[500],
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#f3f6f9',
    padding: 10,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background 200ms ease, color 200ms ease',

    '&:hover': {
      color: theme.palette.grey[800],
    },

    '&:after': {
      background: lighten(theme.palette.grey[100], 0.9),
      content: '""',
      height: '100%',
      borderRadius: 6,
      position: 'absolute',
      top: 0,
      transition: 'left 200ms cubic-bezier(0.77, 0, 0.175, 1)',
      boxShadow: '0 0 4px 0 rgba(42, 42, 42, 0.01)',
      width: '100%',
      willChange: 'left',
      zIndex: -1,
    },
  },
  checked: {
    cursor: 'default',
    color: theme.palette.primary.main,
    zIndex: 1,

    '&:hover': {
      color: theme.palette.primary.main,
    },

    '&:after': {
      left: 0,
    },
  },
}));

const ButtonGroupOption: React.FC<ButtonGroupOptionProps> = ({
  className = '',
  name = '',
  value = '',
  checked = false,
  onClick = () => {},
  children,
}) => {
  const classes = useStyles();
  return (
    <>
      <input
        id={value}
        className={clsx(classes.input)}
        name={name}
        value={value}
        type="radio"
        checked={checked}
        onChange={(event) => {
          if (!checked) onClick(event, value);
        }}
      />
      <label
        htmlFor={value}
        className={clsx(classes.label, className, {
          [classes.checked]: checked,
        })}
      >
        <div className={classes.text}>{children}</div>
      </label>
    </>
  );
};

export default ButtonGroupOption;
