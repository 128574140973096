import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import clsx from 'clsx';

type ActionItemVariant = 'success' | 'danger' | undefined;

// Patch for the MenuItemProps to overcome a MUI typing limitation
// https://github.com/mui-org/material-ui/issues/14971#issuecomment-616791594
interface ActionItemProps extends MenuItemProps {
  variant?: ActionItemVariant;
  button?: true;
  enablePropagation?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 14,
      lineHeight: 2,
      padding: theme.spacing(0.75, 2),
      color: theme.palette.grey[700],
      minWidth: 180,
    },
    confirm: {
      color: theme.palette.success.main,
    },
    alert: {
      color: theme.palette.error.main,
    },
  })
);

const ActionItem: React.FC<Omit<ActionItemProps, 'css'>> = ({
  children,
  className,
  variant,
  onClick = () => {},
  enablePropagation = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MenuItem
      onClick={(event) => {
        if (!enablePropagation) {
          event.stopPropagation();
        }
        onClick(event);
      }}
      className={clsx(
        classes.root,
        {
          [classes.confirm]: variant === 'success',
          [classes.alert]: variant === 'danger',
        },
        className
      )}
      {...props}
    >
      {children}
    </MenuItem>
  );
};

export default ActionItem;
