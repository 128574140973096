import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMPANY_PACKAGES } from '../../hooks/useAuth';
import { CompanyAccountResponse } from '../../types/account-service/company-account';
import { UserResponse } from '../../types/account-service/users';
import { ActingUserType } from './types';

const initialState: { origin: string | null; user: ActingUserType } = {
  origin: null,
  user: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    position: '',
    account: '',
    logo: '',
    employer: '',
    companies: [],
    companyPackage: 'fre',
    roles: [],
    permissions: [],
  },
};

const actingSlice = createSlice({
  name: 'acting',
  initialState,
  reducers: {
    startActing: {
      reducer(
        state,
        action: PayloadAction<{ origin: string; user: ActingUserType }>
      ) {
        if (!!action.payload.user.id && !!action.payload.user.account) {
          state.origin = action.payload.origin;
          state.user = action.payload.user;
        }
      },
      prepare({
        user,
        account,
        profiles = [],
        permissions = [],
        companyPackage = COMPANY_PACKAGES.FREE,
        roles = [],
        origin = '/',
      }: {
        user: UserResponse;
        account: CompanyAccountResponse;
        profiles?: string[];
        permissions?: string[];
        roles?: string[];
        origin?: string;
        companyPackage: string;
      }) {
        return {
          payload: {
            origin,
            user: {
              id: user.id,
              first_name: user.attributes.firstName ?? '',
              last_name: user.attributes.lastName ?? '',
              email: user.attributes.email ?? '',
              phone: user.attributes.phone ?? '',
              position: user.attributes.position ?? '',
              account: account.id ?? '',
              logo: account.attributes.logoUrl ?? '',
              employer: account.attributes.name ?? '',
              companies: profiles,
              roles,
              permissions,
              companyPackage,
            },
          },
        };
      },
    },
    stopActing: (state) => {
      state.origin = null;
      state.user = initialState.user;
    },
  },
});

export const { startActing, stopActing } = actingSlice.actions;

export default actingSlice.reducer;
