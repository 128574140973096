import { format, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';

import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { useUserQuery } from '../../hooks/useUserQuery';
import InitialsAvatar from '../InitialsAvatar';
import LeadStatus from './LeadStatus';
import { LeadListProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '4px',
      boxShadow: theme.shadows[4],
      border: '1px solid transparent',
      width: '100%',
      marginBottom: '32px',
    },
  })
);

const LeadListCard: FC<LeadListProps> = ({ lead, onClick = () => false }) => {
  const classes = useStyles();
  const { user } = useUserQuery(lead.external_assigned_to_user_id);
  const userName = `${user?.attributes.firstName} ${user?.attributes.lastName}`;

  const services = useMemo(() => {
    if (lead.services.length === 0) {
      return 'Other Services';
    }
    return lead.services
      .map((service) => {
        return service.external_service_name;
      })
      .join(', ');
  }, [lead.services]);

  return (
    <Paper
      className={classes.root}
      onClick={(event) => onClick(event, lead.short_id)}
    >
      <Box p={2}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={6}>
            {lead.company_name}
          </Grid>
          <Grid item>{format(parseISO(lead.created_at), 'd MMM yyyy')}</Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="body2" title="leadTitle">
              {services} in {lead.locations.length}{' '}
              {lead.locations.length === 1 ? 'location' : 'locations'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container>
          <Grid item xs>
            {lead.external_assigned_to_user_id && (
              <InitialsAvatar
                usersName={userName}
                userId={lead.external_assigned_to_user_id}
                small
              />
            )}
          </Grid>
          <Grid item>
            <LeadStatus state={lead.state} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LeadListCard;
