import { useContext } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import {
  PrimaryButton,
  Adornment,
  SecondaryButton,
} from '../../components/Cloudscene/Button';
import { MenuContextProvider, MenuContext } from './Mobile.Context';
import * as Menu from './Mobile.Menu';
import * as styles from './mobile.styles';
import { DISCOVER_URL, CLOUDSCENE_URL, CPF_URL, EXPLORE_URL } from '../../api';
import { ROUTES } from '../../routes';
import tw from 'twin.macro';
import Anchor from '../../components/Cloudscene/Anchor';

export const Mobile = () => {
  return (
    <nav css={tw`p-4 relative`}>
      <MenuContextProvider>
        <RootMenu />
        <SourceMenu />
        <CloudPathfinderMenu />
        <ResourceMenu />
      </MenuContextProvider>
    </nav>
  );
};

const RootMenu = ({ id = null }: { id?: null }) => {
  const { state, onSelect } = useContext(MenuContext);

  return (
    <Menu.Container
      css={[
        state !== id && [styles.menu.left, styles.menu.hidden],
        state === id && styles.menu.visible,
      ]}
    >
      <Menu.List aria-hidden={state !== id} aria-haspopup={state !== id}>
        <Menu.Item>
          <Menu.Anchor onClick={() => onSelect('source')}>
            <Adornment end={<HiOutlineChevronRight size={16} />}>
              Source
            </Adornment>
          </Menu.Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={ROUTES.root}>
            <Menu.Anchor>Marketplace</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Menu.Anchor onClick={() => onSelect('cpf')}>
            <Adornment end={<HiOutlineChevronRight size={16} />}>
              Cloud Pathfinder
            </Adornment>
          </Menu.Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/for-buyers`}>
            <Menu.Anchor>For Buyers</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/for-sellers`}>
            <Menu.Anchor>For Sellers</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Menu.Anchor onClick={() => onSelect('resources')}>
            <Adornment end={<HiOutlineChevronRight size={16} />}>
              Resources
            </Adornment>
          </Menu.Anchor>
        </Menu.Item>
      </Menu.List>

      <SecondaryButton as="a" href={`${EXPLORE_URL}/book-a-demo`}>
        Book a demo
      </SecondaryButton>
    </Menu.Container>
  );
};

const SourceMenu = ({ id = 'source' }: { id?: string }) => {
  const { state, onBack } = useContext(MenuContext);

  return (
    <Menu.Container
      css={[
        state !== id && [styles.menu.hidden, styles.menu.right],
        state === id && styles.menu.visible,
      ]}
    >
      <Menu.List
        aria-label={`${id} menu`}
        aria-hidden={state !== id}
        aria-expanded={state === id}
      >
        <Menu.Item>
          <Menu.Back variant="primary" onClick={onBack}>
            <Adornment start={<HiOutlineChevronLeft size={16} />}>
              Back
            </Adornment>
          </Menu.Back>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${CLOUDSCENE_URL}/connectivity`}>
            <Menu.Anchor>Connectivity</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${CLOUDSCENE_URL}/cloud`}>
            <Menu.Anchor>Cloud</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${CLOUDSCENE_URL}/hosting`}>
            <Menu.Anchor>Hosting</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${CLOUDSCENE_URL}/managed-services`}>
            <Menu.Anchor>Managed Services</Menu.Anchor>{' '}
          </Anchor>
        </Menu.Item>

        <Menu.Separator />
      </Menu.List>
      <PrimaryButton as="a" href={`${EXPLORE_URL}/create-launch/`}>
        Create Marketplace Listing
      </PrimaryButton>
    </Menu.Container>
  );
};

const CloudPathfinderMenu = ({ id = 'cpf' }: { id?: string }) => {
  const { state, onBack } = useContext(MenuContext);

  return (
    <Menu.Container
      css={[
        state !== id && [styles.menu.hidden, styles.menu.right],
        state === id && styles.menu.visible,
      ]}
    >
      <Menu.List
        aria-label={`${id} menu`}
        aria-hidden={state !== id}
        aria-expanded={state === id}
      >
        <Menu.Item>
          <Menu.Back variant="primary" onClick={onBack}>
            <Adornment start={<HiOutlineChevronLeft size={16} />}>
              Back
            </Adornment>
          </Menu.Back>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={CPF_URL}>
            <Menu.Anchor>Find you nearest On-Ramp</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/cloud-pathfinder-for-google-cloud`}>
            <Menu.Anchor>Pathfinder for Google Cloud</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/cloud-pathfinder-for-ibm-cloud`}>
            <Menu.Anchor>Pathfinder for IBM Cloud</Menu.Anchor>{' '}
          </Anchor>
        </Menu.Item>
      </Menu.List>
    </Menu.Container>
  );
};

const ResourceMenu = ({ id = 'resources' }: { id?: string }) => {
  const { state, onBack } = useContext(MenuContext);

  return (
    <Menu.Container
      css={[
        state !== id && [styles.menu.hidden, styles.menu.right],
        state === id && styles.menu.visible,
      ]}
    >
      <Menu.List
        aria-label={`${id} menu`}
        aria-hidden={state !== id}
        aria-expanded={state === id}
      >
        <Menu.Item>
          <Menu.Back variant="primary" onClick={onBack}>
            <Adornment start={<HiOutlineChevronLeft size={16} />}>
              Back
            </Adornment>
          </Menu.Back>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href="https://help.cloudscene.com">
            <Menu.Anchor>Help</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        {/* <Menu.Item>
          <Anchor href="https://blog.cloudscene.com">
            <Menu.Anchor>News &amp; Blog</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator /> */}

        <Menu.Item>
          <Anchor href={`${DISCOVER_URL}/rankings/leaderboard`}>
            <Menu.Anchor>Global Rankings</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        {/* <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/ebooks`}>
            <Menu.Anchor>eBooks</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator /> */}

        <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/company`}>
            <Menu.Anchor>Company</Menu.Anchor>
          </Anchor>
        </Menu.Item>

        <Menu.Separator />

        <Menu.Item>
          <Anchor href={`${EXPLORE_URL}/testimonials`}>
            <Menu.Anchor>Testimonials</Menu.Anchor>
          </Anchor>
        </Menu.Item>
      </Menu.List>
    </Menu.Container>
  );
};
