import { useSelector } from 'react-redux';
import { RootState } from '../../store/types.d';
import { selectProviderById } from '../../store/listing-providers';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';

const ProviderState = ({
  id,
  children,
}: {
  id: string;
  children: (provider: ListingProviderResponse) => JSX.Element;
}) => {
  const provider = useSelector((state: RootState) =>
    selectProviderById(state, id)
  );
  return provider ? children(provider) : null;
};

export default ProviderState;
