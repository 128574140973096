import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { Address } from '../../../types/services';

interface AddressPredictionSubstring {
  length: number;
  offset: number;
}

interface AddressPredictionFormatting {
  main_text: string;
  main_text_matched_substrings: AddressPredictionSubstring[];
  secondary_text: string;
  secondary_text_matched_substrings?: AddressPredictionSubstring[];
}

interface AddressPredictionTerm {
  offset: number;
  value: string;
}

export interface AddressPredictionAttributes {
  description: string;
  distance_meters?: number;
  id: string;
  matched_substrings: AddressPredictionSubstring[];
  place_id: string;
  reference: string;
  structured_formatting: AddressPredictionFormatting;
  terms: AddressPredictionTerm[];
  types: string[];
}

export default function useAddressPredictions(
  value: string
): [Address[], () => void] {
  const [addressPredictions, setAddressPredictions] = useState<Address[]>([]);
  const autocomplete = useRef(
    new window.google.maps.places.AutocompleteService()
  );

  const debouncedHandler = useMemo(
    () =>
      debounce((input: string) => {
        autocomplete.current.getPlacePredictions(
          {
            input: input,
            types: ['address'],
          },
          (predictions: AddressPredictionAttributes[]) => {
            if (predictions?.length) {
              setAddressPredictions(
                predictions.map(({ description, place_id }) => {
                  return {
                    type: 'address',
                    description,
                    id: place_id,
                  };
                })
              );
            } else {
              setAddressPredictions([]);
            }
          }
        );
      }, 300),
    [setAddressPredictions]
  );

  const getPlacePredictions = useCallback(
    (input: string) => debouncedHandler(input),
    [debouncedHandler]
  );

  const clearAddressPredictions = useCallback(
    () => setAddressPredictions([]),
    []
  );

  useEffect(() => {
    if (value) {
      getPlacePredictions(value);
    } else {
      clearAddressPredictions();
    }
  }, [value, getPlacePredictions, clearAddressPredictions]);

  return [addressPredictions, clearAddressPredictions];
}
