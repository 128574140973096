import tw from 'twin.macro';
import { PrimaryButton } from '../../components/Cloudscene/Button';
import { Menu, MenuCard, MenuItem, MenuList, MenuLink } from '../Header/Menu';
import { ROUTES } from '../../routes';
import { CLOUDSCENE_URL, CPF_URL, DISCOVER_URL, EXPLORE_URL } from '../../api';

export const Desktop = () => {
  return (
    <nav css={tw`grid grid-flow-col gap-8 items-center`}>
      <Menu id="navigation-source" label="Source">
        <SourceMenu />
      </Menu>
      <Menu
        id="navigation-marketplace"
        label="Marketplace"
        href={ROUTES.root}
      />
      <Menu id="navigation-cpf" label="Cloud Pathfinder">
        <CloudPathfinderMenu />
      </Menu>
      <Menu
        id="navigation-buyers"
        label="For Buyers"
        href={`${EXPLORE_URL}/for-buyers`}
      />
      <Menu
        id="navigation-sellers"
        label="For Sellers"
        href={`${EXPLORE_URL}/for-sellers`}
      />
      <Menu id="navigation-resources" label="Resources">
        <ResourcesMenu />
      </Menu>
    </nav>
  );
};

const SourceMenu = ({ id = 'source' }: { id?: string }) => {
  return (
    <div css={tw`-translate-x-5`}>
      <MenuCard>
        <div css={tw`grid grid-flow-row gap-2`}>
          <MenuList id={id}>
            <MenuItem>
              <MenuLink href={`${CLOUDSCENE_URL}/connectivity`}>
                Connectivity
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href={`${CLOUDSCENE_URL}/cloud`}>Cloud</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href={`${CLOUDSCENE_URL}/hosting`}>Hosting</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href={`${CLOUDSCENE_URL}/managed-services`}>
                Managed Services
              </MenuLink>
            </MenuItem>
          </MenuList>
          <PrimaryButton as="a" href={`${EXPLORE_URL}/create-launch/`}>
            Create Marketplace Listing
          </PrimaryButton>
        </div>
      </MenuCard>
    </div>
  );
};

const CloudPathfinderMenu = ({ id = 'cpf' }: { id?: string }) => {
  return (
    <div css={tw`-translate-x-5`}>
      <MenuCard>
        <div css={tw`grid grid-flow-row gap-2`}>
          <MenuList id={id}>
            <MenuItem>
              <MenuLink href={CPF_URL}>Find your nearest On-Ramp</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink
                href={`${EXPLORE_URL}/cloud-pathfinder-for-google-cloud`}
              >
                Pathfinder for Google Cloud
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href={`${EXPLORE_URL}/cloud-pathfinder-for-ibm-cloud`}>
                Pathfinder for IBM Cloud
              </MenuLink>
            </MenuItem>
          </MenuList>
        </div>
      </MenuCard>
    </div>
  );
};

const ResourcesMenu = ({ id = 'resources' }: { id?: string }) => {
  return (
    <div css={tw`-translate-x-5`}>
      <MenuCard>
        <div css={tw`grid grid-flow-row gap-2`}>
          <MenuList id={id}>
            <MenuItem>
              <MenuLink href="https://help.cloudscene.com">Help</MenuLink>
            </MenuItem>
            {/* <MenuItem>
              <MenuLink href="https://blog.cloudscene.com">
                News &amp; Blog
              </MenuLink>
            </MenuItem> */}
            <MenuItem>
              <MenuLink href={`${DISCOVER_URL}/rankings/leaderboard`}>
                Global Rankings
              </MenuLink>
            </MenuItem>
            {/* <MenuItem>
              <MenuLink href={`${EXPLORE_URL}/ebooks`}>eBooks</MenuLink>
            </MenuItem> */}
            <MenuItem>
              <MenuLink href={`${EXPLORE_URL}/company`}>Company</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink href={`${EXPLORE_URL}/testimonials`}>
                Testimonials
              </MenuLink>
            </MenuItem>
          </MenuList>
        </div>
      </MenuCard>
    </div>
  );
};
