import React, { ReactNode } from 'react';
import tw from 'twin.macro';
import {
  Modal,
  ModalContextType,
  Overlay,
} from '../../../../components/Cloudscene/Modal';
import { Header } from './SalesManagerModal.Header';
import { Features } from './SalesManagerModal.Features';
import { PrimaryButton } from '../../../../components/Cloudscene/Button';
import { EXPLORE_URL } from '../../../../api';

export const SalesManagerModal = ({
  action,
}: {
  action: ReactNode | ((args: ModalContextType) => ReactNode);
}) => {
  return (
    <Modal Overlay={<Overlay variant="dark" />} action={action}>
      <div css={tw`flex flex-col`}>
        <Header />
        <div css={tw`flex flex-col gap-6 p-6`}>
          <Features />
          <div css={tw`flex flex-row justify-end`}>
            <PrimaryButton as="a" href={`${EXPLORE_URL}/for-sellers/pricing/`}>
              See all features
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
