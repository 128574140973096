import { useMemo } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useReadListingByIDQuery } from '../../../services/api/marketplace-service/listings';
import { getRelationshipContext } from '../../../utilities/relationships';

export const useListingInfo = (id?: string, setTrackView?: boolean) => {
  const { user, hasCompany } = useAuth();
  const { data, isFetching, isSuccess, isError } = useReadListingByIDQuery(
    {
      id: id!,
      params: {
        track_view: setTrackView ? 1 : undefined,
        include:
          'documents,markets,providers,provider-contacts,providers.listing,providers.bids,providers.bid.listing,providers.bid.documents',
      },
    },
    {
      skip: !id,
    }
  );
  const listing = data?.entity;

  const opportunities = useMemo(() => {
    if (!data?.context['listing-provider']) return [];
    const opportunities = Object.values(
      data?.context['listing-provider']?.entities
    );
    return opportunities;
  }, [data]);

  const opportunity = useMemo(
    () =>
      opportunities?.find(
        (item) =>
          item.attributes.external_company_account_id ===
            user?.company_account_id ||
          hasCompany(item.attributes.external_provider_id)
      ),
    [opportunities, user, hasCompany]
  );

  const bids = getRelationshipContext(
    data?.context.bid,
    data?.entity?.relationships?.bids?.data
  );

  return { listing, opportunity, bids, isFetching, isSuccess, isError };
};
