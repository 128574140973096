import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  makeStyles,
  createStyles,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { useDebounce } from '../../../hooks/useDebounce';
import { ServiceTypeKeys, Services } from '../../../types/services';
import ServiceProviderGateway from '../ServiceProviderGateway/ServiceProviderGateway';
import ProductGateway from '../ProductGateway';
import {
  selectProviderGatewayMeta,
  selectGatewayServiceProvidersTotal,
  selectAllGatewayServiceProviders,
  resetGatewayProviders,
} from '../../../store/gateway-providers';
import {
  resetGatewayProductProviders,
  selectAllProductServiceProviders,
} from '../../../store/gateway-product-providers';
import { concatAndDeDuplicateObjectsDeep } from '../../../utilities/arrays';
import { useReadAllCompanyProfilesQuery } from '../../../services/api/directory-service/company-profiles';
import {
  mergeMatchedProviders,
  selectAllMatchedServiceProviders,
  resetMatchedProviders,
} from '../../../store/gateway-matched-providers';
import { usePrevious } from '../../../hooks/usePrevious';
import {
  selectMatchedProvidersBySearch,
  sortProviders,
} from '../../../store/gateway-matched-providers/selectors';
import { ProviderGatewayServiceProvider } from '../../../types/provider-gateway';
import { CompanyProfileResponse } from '../../../types/directory-service/company-profile';
import { Heading } from './Heading';
import { Filters } from './Filters';
import { Loading } from './Loading';
import { Results } from './Results';

interface ServiceProviderDialogProps {
  open: boolean;
  onClose: () => void;
  selectedServices?: ServiceTypeKeys[];
  selectedProducts?: Services[];
  isColoc?: boolean;
}

const ServiceProvidersDialog: React.FC<ServiceProviderDialogProps> = ({
  open,
  onClose,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return (
    <Dialog
      open={open}
      maxWidth={mobile ? false : 'lg'}
      fullWidth
      classes={{ paper: classes.dialog }}
      onClose={onClose}
    >
      {open && (
        <SelectServiceProviders {...rest} onClose={onClose}>
          {children}
        </SelectServiceProviders>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.up('xs')]: {
        height: '75vh',
      },
    },
  })
);
const getTotalProviders = (premium: number = 0, nonPremium: number = 0) => {
  return premium + nonPremium;
};

const SelectServiceProviders: React.FC<
  Omit<ServiceProviderDialogProps, 'open'>
> = ({
  selectedServices = [],
  children,
  selectedProducts,
  onClose,
  isColoc = false, // If multi service requirement includes colocation
}) => {
  const [toggleMarketsValue, setToggleMarketsValue] = useState<
    'selected' | 'all'
  >('selected');
  const dispatch = useDispatch();
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 300);
  const providers = useSelector(selectAllProductServiceProviders);
  const queryCount = useSelector(selectProviderGatewayMeta);
  const isLoading = useMemo(() => queryCount.queries > 0, [queryCount]);
  const gatewayProvidersTotalCount = useSelector(
    selectGatewayServiceProvidersTotal
  );
  const [gatewayProvidersState, setGatewayProvidersState] =
    useState<boolean>(false);
  const [gatewayProvidersRefreshCount, setGatewayProvidersRefreshCount] =
    useState<number>(0);
  const previousRefreshCount = usePrevious(gatewayProvidersRefreshCount);
  const gatewayProviders = useSelector(selectAllGatewayServiceProviders);
  const allMatchedProviders = useSelector(selectAllMatchedServiceProviders);
  const matchedProviders = useSelector(
    selectMatchedProvidersBySearch(debouncedInput)
  );
  const companyProfileParams = useMemo(
    () => ({
      'filter[search]': debouncedInput || undefined,
      sort:
        selectedServices?.includes('colocation') || isColoc
          ? '-facilityCount,-popCount,name'
          : '-popCount,name',
      'fields[companyProfiles]': 'name,logo',
      'page[size]': 20,
    }),
    [debouncedInput, selectedServices, isColoc]
  );
  const premiumProvidersQuery = useReadAllCompanyProfilesQuery({
    params: {
      'filter[premium]': 1,
      ...companyProfileParams,
    },
  });
  const nonPremiumProvidersQuery = useReadAllCompanyProfilesQuery({
    params: {
      'filter[premium]': 0,
      ...companyProfileParams,
    },
  });
  const totalProviders = getTotalProviders(
    premiumProvidersQuery.data?.meta?.page?.total,
    nonPremiumProvidersQuery.data?.meta?.page?.total
  );
  const allProviders: CompanyProfileResponse[] = [
    ...(premiumProvidersQuery.data?.entities ?? []),
    ...(nonPremiumProvidersQuery.data?.entities ?? []),
  ].slice(0, 20);

  useEffect(() => {
    dispatch(resetMatchedProviders());
    dispatch(resetGatewayProviders());
    dispatch(resetGatewayProductProviders());
  }, [dispatch]);
  useEffect(() => {
    if (isLoading && gatewayProvidersState) {
      setGatewayProvidersState(false);
    }
  }, [isLoading, gatewayProvidersState]);
  useEffect(() => {
    if (!gatewayProvidersState && selectedProducts?.length !== 0) {
      const mergedProviders: ProviderGatewayServiceProvider[] =
        concatAndDeDuplicateObjectsDeep(
          'id',
          providers,
          allMatchedProviders
        ).sort(sortProviders);
      if (
        JSON.stringify(allMatchedProviders) !== JSON.stringify(mergedProviders)
      ) {
        dispatch(mergeMatchedProviders(mergedProviders));
      }
    }
    if (
      gatewayProvidersState &&
      gatewayProvidersRefreshCount !== previousRefreshCount
    ) {
      const mergedProviders = concatAndDeDuplicateObjectsDeep(
        'id',
        providers,
        gatewayProviders
      ).sort(sortProviders);
      dispatch(mergeMatchedProviders(mergedProviders));
    }
  }, [
    gatewayProvidersState,
    providers,
    gatewayProviders,
    gatewayProvidersRefreshCount,
    previousRefreshCount,
    dispatch,
    allMatchedProviders,
    selectedProducts?.length,
  ]);

  return (
    <>
      <ProductGateway products={selectedProducts} />
      <ServiceProviderGateway products={selectedProducts} />
      <DialogTitle disableTypography>
        <Heading onClose={onClose} />
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={12}>
            {children}
          </Grid>
          <Filters
            inputValue={input}
            setInputValue={(value) => setInput(value)}
            toggleMarketsValue={toggleMarketsValue}
            setToggleMarketsValue={(value) => setToggleMarketsValue(value)}
            matchedProvidersCount={matchedProviders.length}
            allProvidersCount={allProviders.length}
            allMatchedProvidersCount={allMatchedProviders.length}
            totalProvidersCount={totalProviders}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {selectedProducts?.length !== 0 &&
          !gatewayProvidersState &&
          (isLoading || gatewayProvidersTotalCount > 0) && (
            <Loading
              gatewayProvidersTotalCount={gatewayProvidersTotalCount}
              gatewayProvidersState={gatewayProvidersState}
              isLoading={isLoading}
              onRefresh={() => {
                setGatewayProvidersState(true);
                setGatewayProvidersRefreshCount(
                  (gatewayProvidersRefreshCount) =>
                    gatewayProvidersRefreshCount + 1
                );
              }}
            />
          )}
        <Results
          toggleMarketsValue={toggleMarketsValue}
          selectedProductsCount={selectedProducts?.length ?? 0}
          matchedProvidersCount={matchedProviders.length}
          matchedProviders={matchedProviders}
          allProviders={allProviders}
        />
      </DialogContent>
      <DialogActions>
        <Box mt={3} mx="auto" width={200}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onClose}
          >
            Done
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default ServiceProvidersDialog;
