import React from 'react';
import parser, {
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { Typography, Link } from '@material-ui/core';

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (isElement(domNode)) {
      if (domNode.name === 'a' && domNode.attribs.href) {
        return (
          <Link
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={domNode.attribs?.href}
          >
            {domToReact(domNode.children)}
          </Link>
        );
      } else if (domNode.name === 'p' || domNode.name === 'span') {
        return (
          <Typography component={domNode.name}>
            {domToReact(domNode.children)}
          </Typography>
        );
      }
      return domToReact(domNode.children) as JSX.Element;
    } else {
      return null;
    }
  },
};

const HtmlParser: React.FC = ({ children }) => {
  if (typeof children === 'string') {
    return <>{parser(children, options)}</>;
  }
  return <>{children}</>;
};

export default HtmlParser;
