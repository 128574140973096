import { actingOnBehalfAPI } from './base';
import {
  Entity,
  EntityCollection,
  URLQueryParams,
} from '../../../types/entity-collection';
import { CompanyProfileResponse } from '../../../types/directory-service/company-profile';
import { SubscriptionResponse } from '../../../types/account-service/subscription';
import { ACCOUNT_SERVICE_API, DIRECTORY_SERVICE_API } from '../../../api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { UserResponse } from '../../../types/account-service/users';
import { CompanyAccountResponse } from '../../../types/account-service/company-account';
import { RoleResponse } from '../../../types/account-service/roles';
import { COMPANY_PACKAGES } from '../../../hooks/useAuth';

export const usersAPI = actingOnBehalfAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    readActingUserByID: builder.query<
      {
        user: UserResponse;
        account: CompanyAccountResponse;
        profiles: string[];
        roles: string[];
        permissions: string[];
        companyPackage: string;
      },
      { id?: string; params?: URLQueryParams }
    >({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        if (!args.id) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Missing user id parameter',
            } as FetchBaseQueryError,
          };
        }

        const userQuery = await baseQuery({
          url: `${ACCOUNT_SERVICE_API}/users/${encodeURIComponent(args.id)}`,
          method: 'GET',
          params: {
            'fields[users]': [
              'email',
              'position',
              'firstName',
              'lastName',
              'permissions',
              'companyAccount',
              'roles',
            ].join(','),
            include: ['companyAccount', 'roles'].join(','),
          },
        });

        if (userQuery.error) throw userQuery.error;

        const user = userQuery.data as Entity<
          UserResponse,
          CompanyAccountResponse | RoleResponse | SubscriptionResponse
        >;
        const account = user.included?.find(
          (entity): entity is CompanyAccountResponse =>
            entity.type === 'companyAccounts'
        );

        const roles = user.included?.filter(
          (entity): entity is RoleResponse => entity.type === 'roles'
        );

        const profilesQuery = await baseQuery({
          url: `${DIRECTORY_SERVICE_API}/companyProfiles`,
          method: 'GET',
          params: {
            'filter[companyAccountId]': account?.id,
          },
        });

        if (profilesQuery.error) throw profilesQuery.error;

        const profiles =
          profilesQuery.data as EntityCollection<CompanyProfileResponse>;

        return user.data && account && profiles.data
          ? {
              data: {
                user: user.data,
                account,
                profiles: profiles.data.map((profile) => profile.id) ?? [],
                roles: roles?.map((role) => role.attributes.name) ?? [],
                permissions: user.data.attributes.permissions ?? [],
                companyPackage:
                  account?.attributes.subscriptionPackage ||
                  COMPANY_PACKAGES.FREE,
              },
            }
          : { error: { status: 404, data: undefined } as FetchBaseQueryError };
      },
    }),
  }),
});

export const {
  useLazyReadActingUserByIDQuery,
  usePrefetch,
  useReadActingUserByIDQuery,
} = usersAPI;
