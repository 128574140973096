import React, { useRef, useMemo } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Link } from '@reach/router';
import Helmet from 'react-helmet';
import {
  Grid,
  makeStyles,
  useMediaQuery,
  Theme,
  createStyles,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core';
import * as ListingInfo from '../../../components/Listings/ListingInfo';
import { getPageTitleTemplate } from '../../../constants';
import StaticSplitViewLayout from '../../../containers/StaticSplitViewLayout';
import noListingsImage from '../../../assets/marketplace-empty-seller-opportunities.svg';
import { ROUTES } from '../../../routes';
import ListingStatusLabel from '../../../components/ListingStatusLabel';
import { useAuth, COMPANY_PACKAGES, USER_ROLES } from '../../../hooks/useAuth';
import CreateBid from '../../../containers/CreateBid';
import { useAppMessenger } from '../../../store/messenger';
import { useAppState } from '../../../contexts/AppState';
import UnstyledLink from '../../../components/UnstyledLink';
import MarketPlaceHeaderNav from '../header-nav';
import BackButton from '../../../components/BackButton';
import ListItem from '../../../components/ListItem';
import ListingCard from '../../../components/ListingCard';
import ListingData from '../../../components/ListingData';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  ListingCardGroup,
  ListingPanel,
} from '../../../components/ListingGroup';
import SellingListingDetailHeader from './detail-header';
import EmptyContent from '../../../components/EmptyContent';
import { ListingCardProps } from '../../../components/ListingCard/ListingCard';
import { ListItemProps } from '../../../components/ListItem/ListItem';
import { useReadAllListingProvidersQuery } from '../../../services/api/marketplace-service/listing-providers';
import { ListingProviderResponse } from '../../../types/marketplace-service/listing-provider';
import {
  selectActiveListingProviders,
  selectFinishedListingProviders,
} from '../../../store/opportunities/selectors';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      maxWidth: '100%',
      width: 450,
    },
    emptyText: {
      fontWeight: 'normal',
    },
  })
);

const Content: React.FunctionComponent<
  RouteComponentProps<{
    listingShortId?: string;
    location: { state: { focusForm?: boolean } };
  }> & {
    title?: string;
  }
> = ({ listingShortId, location, title = 'Opportunities' }) => {
  const { user, hasCompanyPackage, hasRole } = useAuth();
  const messenger = useAppMessenger();
  const appState = useAppState();
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';

  const { data, isFetching, isSuccess, refetch } =
    useReadAllListingProvidersQuery({
      params: { include: 'listing,bid' },
    });
  const { invited, pending, submitted, declined } = useMemo(() => {
    const entities: ListingProviderResponse[] = data?.entities ?? [];
    const context = data?.context;

    const result = selectActiveListingProviders(entities, context);

    return result;
  }, [data]);

  const { ended } = useMemo(() => {
    const entities: ListingProviderResponse[] = data?.entities ?? [];
    const context = data?.context;

    const result = selectFinishedListingProviders(entities, context);

    return result;
  }, [data]);

  const assignedToMeListings = pending.filter(
    (item) =>
      item.provider?.attributes.external_assigned_to_user_id === user?.id &&
      !item.provider?.attributes.intended_to_bid_at
  );
  const unassignedListings = pending.filter(
    (item) =>
      item.provider?.attributes.external_assigned_to_user_id === null &&
      item?.provider?.attributes?.assigned_to_contact?.email === null
  );
  const awaitingBidListings = pending.filter(
    (item) =>
      !!item.provider?.attributes.intended_to_bid_at &&
      item.provider?.attributes.external_assigned_to_user_id !== null
  );
  const assignedToNewUserPendingListings = pending.filter(
    (item) =>
      !!item.provider?.attributes.assigned_to_contact &&
      item.provider?.attributes.external_assigned_to_user_id === null &&
      item.listing?.attributes.state === 'published'
  );

  const assignedToOthersListings = [
    ...assignedToNewUserPendingListings,
    ...pending.filter(
      (item) =>
        item.provider?.attributes.external_assigned_to_user_id !== user?.id &&
        !item.provider?.attributes.intended_to_bid_at &&
        !!item.provider?.attributes.assigned_at
    ),
  ];

  const submittedEnded = ended.filter(
    (item) =>
      !!item.provider?.attributes.intended_to_bid_at &&
      !!item.provider?.attributes.bid_at
  );

  const allSubmitted = [...submitted, ...submittedEnded];

  const pendingBuyerReviewListings = allSubmitted.filter(
    (item) =>
      item.provider?.attributes.state === 'bid' &&
      item.bid?.attributes.state === 'submitted'
  );

  const classes = useStyles();

  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const bidFormRef = useRef<HTMLElement>(null);
  const onFocusBidForm = () => {
    bidFormRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const noListings =
    invited.length === 0 &&
    assignedToMeListings.length === 0 &&
    assignedToOthersListings.length === 0 &&
    unassignedListings.length === 0 &&
    submitted.length === 0 &&
    awaitingBidListings.length === 0 &&
    declined.length === 0 &&
    pendingBuyerReviewListings.length === 0;

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <MarketPlaceHeaderNav title="Opportunities" isDetail={!!listingShortId}>
        <UnstyledLink to={ROUTES.sellingDashboard}>
          <BackButton />
        </UnstyledLink>
      </MarketPlaceHeaderNav>
      <StaticSplitViewLayout
        open={!!listingShortId && !noListings && !isFetching && isSuccess}
        content={
          <>
            <Grid container spacing={mobile ? 0 : 4} justifyContent="center">
              {isFetching && <LoadingSpinner />}
              {isSuccess && !isFetching && noListings && (
                <Grid
                  item
                  sm={10}
                  xs={12}
                  component={EmptyContent}
                  img={
                    <img
                      className={classes.image}
                      alt="landing page"
                      src={noListingsImage}
                    />
                  }
                  cta={
                    <Button
                      component={Link}
                      to={ROUTES.root}
                      variant="contained"
                      color="primary"
                    >
                      Browse Listings
                    </Button>
                  }
                >
                  <Typography color="primary" variant="h4">
                    Whoa! You don&apos;t have any opportunities in your pipeline
                    yet.
                  </Typography>
                  <Box py={2}>
                    <Typography variant="h5" className={classes.emptyText}>
                      Have you taken a look at the listings on Cloudscene
                      Marketplace? It&apos;s time to take the lead, start
                      browsing and bidding now.
                    </Typography>
                  </Box>
                </Grid>
              )}
              {isSuccess && !isFetching && invited.length > 0 && (
                <ListingData
                  title="Invited"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={invited}
                  baseURL={ROUTES.sellingOpportunities}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="opportunity" />}
                />
              )}
              {isSuccess && !isFetching && unassignedListings.length > 0 && (
                <ListingData
                  title="Unassigned"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={unassignedListings}
                  baseURL={ROUTES.sellingOpportunities}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="unassigned" />}
                />
              )}
              {isSuccess && !isFetching && assignedToOthersListings.length > 0 && (
                <ListingData
                  title="Assigned"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={assignedToOthersListings}
                  baseURL={ROUTES.sellingOpportunities}
                  activeListingShortId={listingShortId}
                  getProps={(item) => {
                    let props: Partial<ListItemProps> &
                      Partial<ListingCardProps> = {
                      invited: !!item.provider?.attributes.invited_at,
                    };
                    if (
                      item.provider?.attributes.external_assigned_to_user_id
                    ) {
                      props.userId =
                        item.provider?.attributes.external_assigned_to_user_id;
                    } else {
                      props.newUser =
                        item.provider?.attributes.assigned_to_contact;
                    }
                    return props;
                  }}
                  opportunity
                  status={() => <ListingStatusLabel variant="opportunity" />}
                />
              )}
              {isSuccess && !isFetching && assignedToMeListings.length > 0 && (
                <ListingData
                  title="Assigned To Me"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={assignedToMeListings}
                  baseURL={ROUTES.sellingOpportunities}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                    userId:
                      item.provider?.attributes.external_assigned_to_user_id,
                  })}
                  opportunity
                  status={(item) => (
                    <ListingStatusLabel
                      variant={
                        item.provider?.attributes.state === 'intend'
                          ? 'intend'
                          : 'opportunity'
                      }
                    />
                  )}
                />
              )}
              {isSuccess && !isFetching && awaitingBidListings.length > 0 && (
                <ListingData
                  title="Awaiting Bid"
                  container={mobile ? ListingCardGroup : ListingPanel}
                  component={mobile ? ListingCard : ListItem}
                  items={awaitingBidListings}
                  baseURL={ROUTES.sellingOpportunities}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                    userId:
                      item.provider?.attributes.external_assigned_to_user_id,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="intend" />}
                />
              )}
              {isSuccess &&
                !isFetching &&
                pendingBuyerReviewListings.length > 0 && (
                  <ListingData
                    title="Pending Buyer Review"
                    container={mobile ? ListingCardGroup : ListingPanel}
                    component={mobile ? ListingCard : ListItem}
                    items={pendingBuyerReviewListings}
                    baseURL={ROUTES.sellingOpportunities}
                    activeListingShortId={listingShortId}
                    getProps={(item) => ({
                      invited: !!item.provider?.attributes.invited_at,
                      userId:
                        item.provider?.attributes.external_assigned_to_user_id,
                    })}
                    opportunity
                    status={(item) => <ListingStatusLabel variant="bid" />}
                  />
                )}
            </Grid>
          </>
        }
        detail={
          <ListingInfo.Container id={listingShortId} setTrackView>
            <ListingInfo.Header onFocusBidForm={onFocusBidForm}>
              <SellingListingDetailHeader
                back={ROUTES.sellingOpportunities}
                splitScreenMode={splitScreenMode}
              />
            </ListingInfo.Header>
            {mobile && (
              <Box mt={2} mx={-2}>
                <Divider />
              </Box>
            )}
            <ListingInfo.Content>
              <ListingInfo.ListingInfoContext.Consumer>
                {({ listing, opportunity }) => {
                  if (
                    !hasCompanyPackage([
                      COMPANY_PACKAGES.STARTER,
                      COMPANY_PACKAGES.PRO,
                    ]) &&
                    !hasRole([
                      USER_ROLES.SALES_PRO,
                      USER_ROLES.SALES_MANAGER,
                    ]) &&
                    opportunity?.attributes.invited_at === null
                  ) {
                    return null;
                  }

                  if (
                    listing &&
                    !listing.attributes.external &&
                    opportunity?.attributes.external_assigned_to_user_id ===
                      user?.id &&
                    opportunity?.attributes.intended_to_bid_at !== null &&
                    pending.find((p) => p.listing.id === listing.id)
                  ) {
                    return (
                      <CreateBid
                        ref={bidFormRef}
                        focusForm={location?.state?.focusForm}
                        onFocusBidForm={onFocusBidForm}
                        listing={listing}
                        onSuccess={() => {
                          refetch();
                          navigate(ROUTES.sellingOpportunities, {
                            replace: true,
                          });

                          messenger.addMessage(
                            'info',
                            'Your bid has been submitted'
                          );
                        }}
                        onError={() => {
                          messenger.addMessage(
                            'error',
                            'Error submitting your bid'
                          );
                        }}
                      />
                    );
                  }
                }}
              </ListingInfo.ListingInfoContext.Consumer>
            </ListingInfo.Content>
          </ListingInfo.Container>
        }
      />
    </>
  );
};

export default Content;
