import React from 'react';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import InfoBlock from '../../InfoBlock';
import ListingType from '../../ListingType';
import ServiceDetails from '../../ServiceDetails';
import MarketInfo from '../../MarketInfoDisplay';
import ServiceProviderInfo from '../../ServiceProviderInfoBlock';
import clsx from 'clsx';
import { CreateListingFormState } from '../../../containers/CreateListing';
import AttachmentList from '../../AttachmentList';
import { getServiceUIName } from '../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    close: {
      color: theme.palette.grey[500],
    },
    heading: {
      margin: theme.spacing(3, 0, 2.5, 0),
    },
    providersSection: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
      padding: `${theme.spacing(1)}px 0px`,
      position: 'relative',
    },
    providersTitle: {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 4,
      display: 'inline-block',
      padding: theme.spacing(0.5, 1),
      zIndex: 99,
      position: 'absolute',
      marginTop: '-24px',
      marginLeft: '30px',
    },
    closeButton: {
      display: 'block',
      margin: theme.spacing(4, 'auto'),
    },
    duration: {
      textAlign: 'right',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

interface PreviewListingProps {
  listing: CreateListingFormState;
  disabled: boolean;
}

const PreviewListing: React.FC<PreviewListingProps> = ({
  listing,
  disabled,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const diff = differenceInCalendarDays(
    parseISO(listing.attributes.end),
    parseISO(listing.attributes.start)
  );
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="secondary"
        fullWidth
        disabled={disabled}
      >
        Preview Listing
      </Button>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h4">Preview</Typography>
          <IconButton
            aria-label="close"
            className={classes.close}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10}>
              <InfoBlock>This is a preview of your listing.</InfoBlock>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" className={classes.duration}>
                Listing Duration: {diff} {diff === 1 ? 'day' : 'days'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Typography variant="h6">
                    {listing.attributes.company}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <ListingType
                    type={
                      listing.status.private === true ? 'private' : 'public'
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography component="div" variant="h3">
                {listing.attributes.title}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {listing.services
                .filter(
                  (serviceMeta) =>
                    serviceMeta.state !== 'delete' && serviceMeta?.value
                )
                .map((serviceMeta, index) => {
                  const service = serviceMeta.value.service;
                  return (
                    <ServiceDetails.ExpansionPanel
                      key={index}
                      id={`${service.type}-${index}`}
                      title={
                        <>
                          {service.attributes.attributes.external_service_name
                            ? `${getServiceUIName(service.attributes.type)} (${
                                service.attributes.attributes
                                  .external_service_name
                              })`
                            : getServiceUIName(service.attributes.type)}
                          {service.attributes.attributes.external_service_id &&
                            ` (${service.attributes.attributes.external_service_id})`}
                        </>
                      }
                    >
                      <ServiceDetails.Summary service={service.attributes} />
                    </ServiceDetails.ExpansionPanel>
                  );
                })}
              {listing.multi_requirements
                .filter((service) => service.state !== 'delete')
                .map((service, index) => (
                  <ServiceDetails.ExpansionPanel
                    key={index}
                    id="multi-service-requirements"
                    title={getServiceUIName(
                      service.value.service.attributes.type
                    )}
                  >
                    <ServiceDetails.Summary
                      service={service.value.service.attributes}
                    />
                  </ServiceDetails.ExpansionPanel>
                ))}
            </Grid>

            {listing.markets.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.heading}>
                  Markets
                </Typography>
                <Grid container spacing={3}>
                  {listing.markets
                    .filter((market) => market.state !== 'delete')
                    .map((market, index: number) => (
                      <Grid key={index} item xs={3}>
                        <MarketInfo id={market.value?.attributes.id} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}

            {listing.documents.filter((document) => document.state !== 'delete')
              .length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.heading}>
                  Attachments
                </Typography>
                {listing.documents
                  .filter((document) => document.state !== 'delete')
                  .map((document, index: number) => (
                    <AttachmentList key={index} document={document.value} />
                  ))}
              </Grid>
            )}

            {listing.attributes.description && (
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.heading}>
                  Additional Information
                </Typography>
                <Typography variant="body2">
                  {listing.attributes.description}
                </Typography>
              </Grid>
            )}

            {listing.providers.length > 0 && (
              <Grid item xs={12}>
                <div className={clsx(classes.providersSection)}>
                  <div className={classes.providersTitle}>
                    This section is only visible to you
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5" className={classes.heading}>
                        Invited Service Providers
                      </Typography>
                    </Grid>
                    <Grid item xs sm={6}>
                      <Grid container spacing={2}>
                        {listing.providers
                          .filter((provider) => provider.state !== 'delete')
                          .map((provider) => {
                            const contact = listing.provider_contacts.find(
                              (contact) =>
                                contact.value.relationships.provider?.data
                                  ?.id ===
                                provider.value.relationships.provider.data.id
                            );

                            return (
                              <Grid
                                item
                                key={
                                  provider.value.relationships.provider.data.id
                                }
                                xs={12}
                              >
                                <ServiceProviderInfo
                                  provider={
                                    provider.value.relationships.provider.data
                                      .id
                                  }
                                  contact={
                                    contact?.value.relationships[
                                      'provider-contact'
                                    ].data.id
                                  }
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            className={classes.closeButton}
          >
            Close Preview
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewListing;
