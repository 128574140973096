import React, { useState } from 'react';
import { Form, FormRenderProps, Field } from 'react-final-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  makeStyles,
  Link,
  Box,
  createStyles,
  CircularProgress,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeftRounded';
import PersonAdd from '@material-ui/icons/PersonAdd';
import TeamMemberSearch from '../TeamMemberSearch';
import validationSchema from './validation';
import { UserResponse } from '../../types/account-service/users';
import { useThrottle } from '../../hooks/useThrottle';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import { AssignedNewUser } from '../../types/provider';
import AssignNewUserForm from './AssignNewUserForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

interface AssignOpportunityFromState {
  user: UserResponse | null;
}

interface AssignOpportunityProps {
  open: boolean;
  assignListingOwner?: boolean;
  onAssign: (user: UserResponse) => void;
  onNewUserAssign?: (newUser: AssignedNewUser) => void;
  onClose: () => void;
}

const AssignOpportunity: React.FC<AssignOpportunityProps> = ({
  open,
  onClose,
  onAssign,
  onNewUserAssign = () => {},
  assignListingOwner = false,
}) => {
  const classes = useStyles();
  const handleAssign = useThrottle(onAssign, 3000);
  const handleNewUserAssign = useThrottle(onNewUserAssign, 3000);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const validator = useValidationSchema(validationSchema);

  return (
    <Dialog
      open={open}
      onClose={(event, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown')
          return false;
        onClose();
      }}
      TransitionProps={{ onExited: () => setDisplayForm(false) }}
    >
      <DialogTitle disableTypography>
        {displayForm && (
          <Link onClick={() => setDisplayForm(false)} underline="none">
            <Box display="inline-flex" alignItems="center">
              <ChevronLeft fontSize="small" />
              Back
            </Box>
          </Link>
        )}
        <Typography align="center" component="div" variant="h2">
          {assignListingOwner ? 'Assign Listing' : 'Assign Opportunity'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!displayForm && (
          <Form
            initialValues={{
              user: null,
            }}
            onSubmit={async (values: AssignOpportunityFromState) => {
              if (values.user) {
                await handleAssign(values.user);
              }
            }}
            validate={validator}
          >
            {({
              handleSubmit,
              valid,
              submitting,
            }: FormRenderProps<AssignOpportunityFromState>) => {
              return (
                <>
                  <Grid container spacing={4} justifyContent="center">
                    <>
                      <Grid item xs={12}>
                        <Typography
                          align="center"
                          variant="body2"
                          color="textPrimary"
                        >
                          {assignListingOwner
                            ? `You can assign ownership of this listing to a team member which will allow them to invite service providers to quote, manage responses and bids, and select a successful vendor on behalf of your company.`
                            : `You can assign ownership of this opportunity to a team member, which will allow them to manage the bidding process on behalf of your company.`}
                        </Typography>
                      </Grid>
                      {!assignListingOwner && (
                        <Grid>
                          <Button
                            color="primary"
                            onClick={() => setDisplayForm(true)}
                            startIcon={<PersonAdd />}
                          >
                            Invite a new team member
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={10}>
                        <Field name="user">
                          {({ input }) => {
                            return (
                              <TeamMemberSearch
                                {...input}
                                label="Select team member"
                                assignListingOwner={assignListingOwner}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid
                        container
                        spacing={1}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        item
                        xs
                      >
                        <Grid item>
                          <Button
                            disabled={submitting || !valid}
                            onClick={handleSubmit}
                            color="primary"
                            variant="contained"
                            endIcon={
                              submitting && (
                                <CircularProgress color="secondary" size={20} />
                              )
                            }
                          >
                            Assign
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className={classes.boldText}
                            onClick={onClose}
                            color="primary"
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </>
              );
            }}
          </Form>
        )}
        {displayForm && (
          <AssignNewUserForm handleNewUserAssign={handleNewUserAssign} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AssignOpportunity;
