import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { format, isAfter, parseISO } from 'date-fns';
import clsx from 'clsx';
import { getFeatureUIName } from '../../constants';
import {
  ServiceEthernet as ServiceEthernetProps,
  ServiceTypes,
} from '../../types/services';
import MarketPlaceMatchInfo from '../MarketPlaceMatchInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);

const ServiceDetailsEthernet: React.FC<
  ServiceEthernetProps & {
    marketplaceMatchLabels?: boolean;
    listingEndDate?: string;
  }
> = ({
  attributes,
  contract_term,
  ready_for_service,
  additional_info,
  marketplaceMatchLabels,
  listingEndDate,
}) => {
  const classes = useStyles();

  let pointA = attributes.point_a?.description;
  let pointZ = attributes.point_z?.description;
  if (attributes.point_a?.type === 'data-center') {
    pointA = attributes.point_a?.address
      ? `${pointA} (${attributes.point_a?.address})`
      : pointA;
    pointZ = attributes.point_z?.address
      ? `${pointZ} (${attributes.point_z?.address})`
      : pointZ;
  }
  return (
    <Grid container spacing={3}>
      <Grid container spacing={3} item xs={12}>
        <Grid item xs={12} xl={6}>
          <Typography component="div" gutterBottom className={classes.label}>
            From
          </Typography>
          <Typography
            component="div"
            title={attributes.point_a?.description}
            className={classes.title}
          >
            {pointA}
          </Typography>

          {marketplaceMatchLabels && (
            <MarketPlaceMatchInfo
              aEnd={attributes.point_a}
              serviceType={ServiceTypes.ethernet}
            />
          )}
        </Grid>
        <Grid item xs={12} xl={6}>
          <Typography component="div" gutterBottom className={classes.label}>
            To
          </Typography>
          <Typography
            component="div"
            title={attributes.point_z?.description}
            className={classes.title}
          >
            {pointZ}
          </Typography>
          {marketplaceMatchLabels && (
            <MarketPlaceMatchInfo
              aEnd={attributes.point_z}
              serviceType={ServiceTypes.ethernet}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} item xs={12}>
        {contract_term?.value && contract_term?.unit && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('contract_term')}
            </Typography>
            <Typography component="div" noWrap className={classes.title}>
              {contract_term?.value === 1 && contract_term?.unit === 'month'
                ? 'Monthly'
                : `${contract_term.value} ${contract_term.unit}`}
            </Typography>
          </Grid>
        )}
        {ready_for_service && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('rfs_date')}
            </Typography>
            <Typography
              component="div"
              noWrap
              className={clsx(classes.title, {
                [classes.error]:
                  listingEndDate &&
                  isAfter(
                    parseISO(listingEndDate),
                    parseISO(ready_for_service)
                  ),
              })}
            >
              {format(parseISO(ready_for_service), 'd MMMM yyyy')}
            </Typography>
          </Grid>
        )}
        {attributes.bandwidth?.value && attributes.bandwidth?.unit && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('bandwidth')}
            </Typography>
            <Typography component="div" noWrap className={classes.title}>
              {attributes.bandwidth.value} {attributes.bandwidth.unit}
            </Typography>
          </Grid>
        )}
        {attributes.advanced && attributes.advanced?.access_type && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('access_type')}
            </Typography>
            <Typography component="div" noWrap className={classes.title}>
              {getFeatureUIName(attributes.advanced.access_type)}
            </Typography>
          </Grid>
        )}
        {attributes.advanced &&
          Object.values(attributes.advanced.advanced_features).some(
            (val) => val
          ) && (
            <Grid item xs={12} md={3}>
              <Typography
                component="div"
                gutterBottom
                className={classes.label}
              >
                Advanced Features
              </Typography>
              {Object.entries(attributes.advanced.advanced_features)
                .filter(([, value]) => value)
                .map(([key]) => (
                  <Typography
                    key={key}
                    component="div"
                    noWrap
                    className={classes.title}
                  >
                    {getFeatureUIName(
                      key as keyof typeof attributes.advanced.advanced_features
                    )}
                  </Typography>
                ))}
            </Grid>
          )}
        {attributes.advanced && attributes.advanced.minimum_sla && (
          <Grid item xs={12} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('minimum_sla')}
            </Typography>
            <Typography component="div" noWrap className={classes.title}>
              {getFeatureUIName(attributes.advanced.minimum_sla)}
            </Typography>
          </Grid>
        )}
        {attributes.advanced && attributes.advanced.hand_off && (
          <Grid item xs={6} md={3}>
            <Typography component="div" gutterBottom className={classes.label}>
              {getFeatureUIName('hand_off')}
            </Typography>
            <Typography component="div" noWrap className={classes.title}>
              {getFeatureUIName(attributes.advanced.hand_off)}
            </Typography>
          </Grid>
        )}
        {additional_info && (
          <Grid item xs={12}>
            <Typography component="div" gutterBottom className={classes.label}>
              Additional Information
            </Typography>
            <Typography component="div" className={classes.title}>
              {additional_info}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ServiceDetailsEthernet;
