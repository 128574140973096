import { fetchBaseQuery, retry, FetchArgs } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './prepare-headers';

export const staggeredBaseQueryWithBailOut = (
  baseUrl: string = '',
  maxRetries = 2
) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders,
      })(args, api, extraOptions);

      if ((result.error?.status ?? 0) >= 400) {
        retry.fail(result.error);
      }

      return result;
    },
    {
      maxRetries,
    }
  );
