import { object, string } from 'yup';
import { parseISO, addHours } from 'date-fns';

export const schema = object({
  end_date: string().test(
    'minDate',
    'End date should be at least 1 hour from now',
    (date) => (date ? parseISO(date) > addHours(new Date(), 1) : false)
  ),
}).defined();

export default schema;
