import { URLQueryParams } from '../types/entity-collection';

export const encodeParams = (params: URLQueryParams = {}): URLQueryParams => {
  return Object.entries(params).reduce<URLQueryParams>(
    (current, [key, value]) => {
      if (value === undefined || value === null || value === '') {
        return current;
      } else {
        current[key] = encodeURI(value);
        return current;
      }
    },
    {}
  );
};
