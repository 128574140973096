import { useCallback, useEffect, useState, useMemo } from 'react';
import { useAppState } from '../../../contexts/AppState';
import { useDebounce } from '../../../hooks/useDebounce';
import { Market } from '../../../types/market';
import { MarketOption } from '../../../types/services';

export default function useMarketPredictions(
  value: string
): [MarketOption[], () => void] {
  const debouncedValue = useDebounce(value, 300);
  const [marketPredictions, setMarketPredictions] = useState<MarketOption[]>(
    []
  );
  const appState = useAppState();
  const markets: Market[] = useMemo(
    () =>
      Object.values(appState.markets).sort((a, b) => {
        if (a.type === b.type) {
          return a.name.localeCompare(b.name);
        }
        if (a.type === 'zone') {
          return -1;
        }
        return 1;
      }),
    [appState.markets]
  );

  const clearMarketPredictions = useCallback(
    () => setMarketPredictions([]),
    []
  );

  useEffect(() => {
    if (debouncedValue) {
      setMarketPredictions(
        markets
          .filter((market: Market) => {
            const name: string = `${market.name} - ${market.zone}`;
            return name.toLowerCase().includes(debouncedValue.toLowerCase());
          })
          .map((market: Market) => {
            return {
              ...market,
              type: 'market',
              description:
                market.name === market.zone
                  ? market.name
                  : market.name + (', ' + market.zone),
              marketType: 'marketName' in market ? 'market' : 'zone',
            };
          })
      );
    } else {
      clearMarketPredictions();
    }
  }, [debouncedValue, clearMarketPredictions, markets]);

  return [marketPredictions ?? [], clearMarketPredictions];
}
