import React, { ReactElement } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { ROUTES } from '../../routes';
import Helmet from 'react-helmet';
import AppLayout from '../../containers/AppLayout';
import { getPageTitleTemplate } from '../../constants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Grid,
  Typography,
  Button,
  Link as SupportLink,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: 'auto',
    marginTop: theme.spacing(7),
    maxWidth: '500px',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 65,
  },
  text: {
    marginBottom: theme.spacing(3),
  },
}));

const RequestQuoteSuccess = ({
  title = 'Request a Quote',
}: RouteComponentProps<{ title?: string }>): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <AppLayout title={title}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <CheckCircleIcon className={classes.icon} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              All done! We've received your quote request
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.text}>
              Hoorah! Your quote request has been submitted. You'll receive an
              email notification when the service provider has received your
              quote request. Need help?{' '}
              <SupportLink
                color="primary"
                href="https://help.cloudscene.com/contact-support/"
              >
                Contact support{' '}
              </SupportLink>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/${document.referrer}`}
              fullWidth
            >
              Keep searching Cloudscene
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={ROUTES.buyingListings}
              fullWidth
            >
              Go to My Marketplace
            </Button>
          </Grid>
        </Grid>
      </AppLayout>
    </>
  );
};

export default RequestQuoteSuccess;
