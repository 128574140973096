import React, { ReactElement } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import { getPageTitleTemplate } from '../constants';

const NotFound = ({
  title = 'Not Found',
}: RouteComponentProps<{ title?: string }>): ReactElement => {
  return (
    <div>
      <p>{title}</p>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
    </div>
  );
};

export default NotFound;
