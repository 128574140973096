import React, { useContext, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { ListingInfoContext } from '../Listings/ListingInfo';
import ServiceProviderInfo from '../ServiceProviderInfoBlock';
import { useLazyReadAllListingProvidersByListingIDQuery } from '../../services/api/marketplace-service/listing-providers';
import LoadingSpinner from '../LoadingSpinner';

const ListingServiceProviders: React.FC = ({ children }) => {
  const { listing } = useContext(ListingInfoContext);
  const [readListingProvidersByListingID, { providers, isFetching }] =
    useLazyReadAllListingProvidersByListingIDQuery({
      selectFromResult: (result) => {
        const isFetching = result?.isFetching;
        const providers = result?.data?.entities;
        return { providers, isFetching };
      },
    });
  useEffect(() => {
    if (listing) {
      readListingProvidersByListingID({
        listing: listing.id,
      });
    }
  }, [listing, readListingProvidersByListingID]);
  if (isFetching) {
    return <LoadingSpinner />;
  }
  if (!providers?.length) {
    return null;
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5">Invited Service Providers</Typography>
      </Grid>
      {providers.map((provider) => {
        return (
          provider.relationships.provider.data && (
            <Grid key={provider.id} item xs={12}>
              <ServiceProviderInfo
                provider={provider.relationships.provider.data.id}
                status={provider.attributes.declined_at ? 'declined' : null}
              >
                {provider.attributes.declined_at && (
                  <Typography variant="body1">
                    <Box fontWeight="fontWeightMedium">
                      {provider.attributes.declined_reason}
                    </Box>
                  </Typography>
                )}
              </ServiceProviderInfo>
            </Grid>
          )
        );
      })}

      {children}
    </Grid>
  );
};

export default ListingServiceProviders;
