import React from 'react';
import { formatISO, parseISO } from 'date-fns';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, FormSpy, FormSpyRenderProps } from 'react-final-form';
import PlacesAutocomplete from '../../../Form/PlacesAutocomplete';
import { SelectFieldPlaceholder as SelectField } from '../../../Form/CSSelectField';
import CSTextField from '../../../Form/CSTextField';
import CSDateField from '../../../Form/CSDateField';
import {
  ServiceColocation,
  ServiceContractTerm,
  ServiceContractUnits,
  ServicePower,
  ServicePowerUnits,
} from '../../../../types/services';
import MarketPlaceMatchInfo from '../../../MarketPlaceMatchInfo';
import {
  standardContractTermOptions,
  standardQuantityOptions,
  standardPowerOptions,
} from '../../../../constants';

const ColocationBasic: React.FC<{ minRfsDate: Date }> = React.memo(
  ({ minRfsDate }) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.external_service_name">
            {({ input }) => (
              <CSTextField
                label="Colocation Service Name (Optional)"
                {...input}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.external_service_id">
            {({ input }) => (
              <CSTextField label="Service ID (Optional)" {...input} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="attributes.point_a">
            {({ input }) => (
              <PlacesAutocomplete
                label="Location (Data Center or Markets) *"
                selected={input.value}
                onSelect={(place) => input.onChange(place)}
                filterOptionType={['data-center', 'market']}
              />
            )}
          </Field>
          <FormSpy
            subscription={{
              values: true,
            }}
          >
            {({ values }: FormSpyRenderProps<ServiceColocation>) => (
              <MarketPlaceMatchInfo
                aEnd={values.attributes.point_a}
                serviceType={values.type}
              />
            )}
          </FormSpy>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Removing Preferred Data Centre for Pre MVP*/}
          {/*<Field name="attributes.point_z">
          {({ input }) => (
            <PlacesAutocomplete
              label="Preferred Data Center (Optional)"
              selected={input.value}
              onSelect={place => input.onChange(place)}
              filterOptionType="data-center"
            />
          )}
          </Field>*/}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field name="attributes.requirements.unit">
            {({ input }) => (
              <SelectField label="Requirements *" {...input}>
                <MenuItem value="RU">RU</MenuItem>
                <MenuItem value="Quarter Rack">Quarter Rack</MenuItem>
                <MenuItem value="Half Rack">Half Rack</MenuItem>
                <MenuItem value="Full Rack">Full Rack</MenuItem>
              </SelectField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field name="attributes.requirements.value">
            {({ input }) => (
              <SelectField label="Quantity *" {...input}>
                {standardQuantityOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="attributes.power"
            parse={(term: string): ServicePower => {
              const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];
              return {
                value: Number(value),
                unit: unit as ServicePowerUnits,
              };
            }}
            format={(term: ServicePower) => {
              return term ? `${term.value}${term.unit}` : '';
            }}
          >
            {({ input }) => (
              <SelectField label="Power (Per Rack) *" {...input}>
                {standardPowerOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="contract_term"
            parse={(term: string): ServiceContractTerm => {
              const [, value, unit] = term.match(/(\d{1,})(\w{1,})/) || [];

              return {
                value: Number(value),
                unit: unit as ServiceContractUnits,
              };
            }}
            format={(term: ServiceContractTerm) => {
              return term ? `${term.value}${term.unit}` : '';
            }}
          >
            {({ input }) => (
              <SelectField label="Contract Term *" {...input}>
                {standardContractTermOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            name="ready_for_service"
            parse={(value) => (value ? formatISO(value) : '')}
            format={(value) => (value ? parseISO(value) : '')}
          >
            {({ input }) => (
              <CSDateField minDate={minRfsDate} label="RFS Date *" {...input} />
            )}
          </Field>
        </Grid>
      </Grid>
    );
  }
);

export default ColocationBasic;
