import React from 'react';
import ServiceProvidersListItem from '../ServiceProvidersListItem';
import { ServiceProvider } from '../../../types/service-provider';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { CreateListingFormState } from '../../../containers/CreateListing';
import { CompanyProfileResponse } from '../../../types/directory-service/company-profile';
import { useLazyReadAllServiceProviderContactsByUserIDQuery } from '../../../services/api/marketplace-service/service-provider-contacts';
import { useAuth } from '../../../hooks/useAuth';
import { getProviderContactID } from '../../../utilities/service-provider-contact';

interface ServiceProvidersListProps {
  serviceProviders?: ServiceProvider[] | CompanyProfileResponse[];
}

const ServiceProvidersList: React.FC<ServiceProvidersListProps> = ({
  serviceProviders = [],
}) => {
  const { user } = useAuth();
  const [readAllServiceProviderContactsByUserID, { data }] =
    useLazyReadAllServiceProviderContactsByUserIDQuery();

  return (
    <FormSpy subscription={{ pristine: true, values: true }}>
      {({ form, values }: FormSpyRenderProps<CreateListingFormState>) => {
        return serviceProviders.map((provider) => {
          const listingContact = values.provider_contacts.find(
            (contact) =>
              contact.state !== 'delete' &&
              contact.value.relationships?.provider?.data?.id === provider.id
          );
          const providerContact = data?.entities.find(
            (contact) =>
              listingContact?.state !== 'delete' &&
              contact.id ===
                listingContact?.value.relationships['provider-contact']?.data.id
          );

          return (
            <ServiceProvidersListItem
              key={provider.id}
              provider={provider}
              contact={providerContact}
              selected={values.providers.some(
                (listingProvider) =>
                  listingProvider.state !== 'delete' &&
                  listingProvider.value.relationships.provider.data.id ===
                    provider.id
              )}
              onSelect={(provider, contact) => {
                const providers = values.providers.filter(
                  (item) => item.state !== 'delete'
                );
                const contactProviderId = getProviderContactID(contact);

                const contactIndex = values.provider_contacts.findIndex(
                  (item) => {
                    return (
                      item.state !== 'delete' &&
                      item.value.relationships.provider?.data?.id ===
                        contactProviderId
                    );
                  }
                );

                if (
                  providers.length < 20 &&
                  providers.every(
                    (item) =>
                      item.value.relationships.provider.data.id !== provider.id
                  )
                ) {
                  form.mutators.addResource('providers', {
                    type: 'listing-provider',
                    relationships: {
                      provider: {
                        data: { type: 'provider', id: provider.id },
                      },
                    },
                  });
                }

                if (contactIndex > -1) {
                  form.mutators.deleteResource(
                    'provider_contacts',
                    contactIndex
                  );
                }

                if (contact) {
                  form.mutators.addResource('provider_contacts', {
                    type: 'listing-provider-contact',
                    relationships: {
                      'provider-contact': {
                        data: { type: 'provider-contact', id: contact.id },
                      },
                      provider: {
                        data: { type: 'provider', id: provider.id },
                      },
                    },
                  });
                }
                if (user?.id)
                  readAllServiceProviderContactsByUserID({ user: user.id });
              }}
              onEdit={(provider, contact) => {
                const contactIndex = values.provider_contacts.findIndex(
                  (item) =>
                    item.state !== 'delete' &&
                    item.value.relationships?.['provider-contact']?.data.id ===
                      contact?.id
                );

                if (contact && contactIndex > -1) {
                  form.mutators.editResource(
                    'provider_contacts',
                    contactIndex,
                    {
                      type: 'listing-provider-contact',
                      relationships: {
                        'provider-contact': {
                          data: { type: 'provider-contact', id: contact.id },
                        },
                        provider: {
                          data: { type: 'provider', id: provider.id },
                        },
                      },
                    }
                  );
                }
                if (user?.id)
                  readAllServiceProviderContactsByUserID({ user: user.id });
              }}
              onRemove={(provider, contact) => {
                const providerIndex = values.providers.findIndex(
                  (item) =>
                    item.state !== 'delete' &&
                    item.value.relationships.provider.data.id === provider.id
                );
                const contactIndex = values.provider_contacts.findIndex(
                  (item) =>
                    item.state !== 'delete' &&
                    item.value.relationships?.['provider-contact']?.data.id ===
                      contact?.id
                );

                if (providerIndex > -1) {
                  form.mutators.deleteResource('providers', providerIndex);
                }

                if (contactIndex > -1) {
                  form.mutators.deleteResource(
                    'provider_contacts',
                    contactIndex
                  );
                }
                if (user?.id)
                  readAllServiceProviderContactsByUserID({ user: user.id });
              }}
              onRemoveContact={(contact) => {
                const contactIndex = values.provider_contacts.findIndex(
                  (item) =>
                    item.state !== 'delete' &&
                    item.value.relationships?.['provider-contact']?.data.id ===
                      contact?.id
                );

                if (contactIndex > -1) {
                  form.mutators.deleteResource(
                    'provider_contacts',
                    contactIndex
                  );
                }
                if (user?.id)
                  readAllServiceProviderContactsByUserID({ user: user.id });
              }}
            />
          );
        });
      }}
    </FormSpy>
  );
};

export default ServiceProvidersList;
