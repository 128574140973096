import tw from 'twin.macro';

export const root = [tw`p-4 relative`];

export const menu = {
  container: [
    tw`transform-gpu transition-all duration-200 ease-in-out`,
    tw`grid grid-cols-1 gap-4`,
  ],
  list: [],
  item: [],
  back: tw`block p-2 my-1`,
  action: tw`block p-2 my-1 w-full text-left`,
  separator: tw`w-full h-px bg-gray-200`,
  hidden: tw`pointer-events-none overflow-hidden h-0`,
  right: tw`translate-x-full`,
  left: tw`-translate-x-full`,
  visible: tw`translate-x-0 h-auto`,
};
