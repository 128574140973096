import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useReadCompanyProfileByIDQuery } from '../../services/api/directory-service/company-profiles';

interface RequestQuoteHeaderProps {
  providerId: string;
}

const RequestQuoteHeader: React.FC<RequestQuoteHeaderProps> = ({
  providerId,
}) => {
  const { data } = useReadCompanyProfileByIDQuery({
    id: providerId,
  });
  if (!data) return null;
  const provider = data?.entity;
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom color="textSecondary">
          Request a Quote: {provider?.attributes.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          I am looking for
        </Typography>
      </Grid>
    </>
  );
};

export default RequestQuoteHeader;
