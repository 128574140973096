import { gql } from 'graphql-request';

import {
  MarketplaceLead,
  MarketplaceLeadRequest,
} from '../../../../types/marketplace-service/gql/marketplace-leads';
import { marketplaceServiceGQLAPI } from './base';

export const marketplaceLeadsAPI = marketplaceServiceGQLAPI.injectEndpoints({
  endpoints: (builder) => ({
    readAllMarketplaceLeads: builder.query<{ leads: MarketplaceLead[] }, void>({
      providesTags: (result) =>
        result
          ? result.leads
              .map(({ id }) => ({
                type: 'MarketplaceLead' as const,
                id,
              }))
              .concat({ type: 'MarketplaceLead', id: 'LIST' })
          : [{ type: 'MarketplaceLead', id: 'LIST' }],
      query: () => ({
        document: gql`
          query {
            leads {
              id
              short_id
              state
              first_name
              last_name
              phone
              email
              company_name
              country
              business_type
              created_at
              additional_info
              external_assigned_to_user_id
              services {
                id
                external_service_name
                external_service_id
              }
              locations {
                id
                location_type
                external_location_id
              }
            }
          }
        `,
      }),
    }),
    readMarketplaceLeadsByStates: builder.query<
      { leadsByStates: MarketplaceLead[] },
      void
    >({
      providesTags: (result) =>
        result
          ? result.leadsByStates
              .map(({ id }) => ({
                type: 'MarketplaceLead' as const,
                id,
              }))
              .concat({ type: 'MarketplaceLead', id: 'LIST' })
          : [{ type: 'MarketplaceLead', id: 'LIST' }],
      query: () => ({
        document: gql`
          query {
            leadsByStates(states: [APPROVED, ASSIGNED, ACTIONED]) {
              id
              short_id
              state
              first_name
              last_name
              phone
              email
              company_name
              country
              business_type
              created_at
              additional_info
              external_assigned_to_user_id
              services {
                id
                external_service_name
                external_service_id
              }
              locations {
                id
                location_type
                external_location_id
              }
            }
          }
        `,
      }),
    }),
    updateMarketplaceLeadByID: builder.mutation<
      { data: { marketplaceLead: MarketplaceLead } },
      {
        id: string;
        payload: MarketplaceLeadRequest;
      }
    >({
      query: (args) => ({
        document: gql`
          mutation UpdateLead($marketplaceLeadRequest: UpdateLead!) {
            marketplaceLead: updateLead(input: $marketplaceLeadRequest) {
              id
              state
              external_assigned_to_user_id
              contacted
            }
          }
        `,
        variables: {
          marketplaceLeadRequest: args.payload,
        },
      }),
      async onQueryStarted({ id, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          marketplaceLeadsAPI.util.updateQueryData(
            'readMarketplaceLeadsByStates',
            undefined,
            (draft) => {
              const leadIndex = draft.leadsByStates.findIndex(
                (lead) => lead.id === id
              );

              draft.leadsByStates[leadIndex] = Object.assign(
                draft.leadsByStates[leadIndex],
                payload
              );
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useLazyReadAllMarketplaceLeadsQuery,
  useLazyReadMarketplaceLeadsByStatesQuery,
  useReadMarketplaceLeadsByStatesQuery,
  useReadAllMarketplaceLeadsQuery,
  useUpdateMarketplaceLeadByIDMutation,
} = marketplaceLeadsAPI;
