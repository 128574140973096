import { styled } from 'twin.macro';
import * as styles from './typography.styles';

export const Typography = styled.div(() => styles.base);

export const Hero = styled(Typography)(() => styles.hero);

export const Title = styled(Typography)(() => styles.title);

export const Subtitle = styled(Typography)(() => styles.subtitle);

export const Headline = styled(Typography)(() => styles.headline);

export const Caption = styled(Typography)(() => styles.caption);

export const Hint = styled(Typography)(() => styles.hint);
