import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import tw, { theme } from 'twin.macro';
import {
  Headline,
  Typography,
} from '../../../../components/Cloudscene/Typography';

export const Features = () => {
  return (
    <div css={tw`flex flex-col gap-4`}>
      <Headline>Streamline your sales team and process on Cloudscene</Headline>

      <ul css={tw`flex flex-col gap-2 p-2`}>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>Assign Sales Manager seats to your team</Typography>
        </li>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>Assign Sales Pro seats to your team</Typography>
        </li>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>Assign and reassign listings to team members</Typography>
        </li>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <div>
            <HiCheckCircle size="24" color={theme`colors.brand`} />
          </div>
          <Typography>Manage all leads and opportunities</Typography>
        </li>
        <li css={tw`flex flex-row gap-2 items-center justify-start`}>
          <Typography css={tw`text-brand`}>+ much more</Typography>
        </li>
      </ul>
    </div>
  );
};
