import { object, string, number, array, boolean } from 'yup';

export const schema = object({
  documents: array(),
  installLeadTime: string()
    .trim()
    .required()
    .label('Information around installation and lead time'),
  pointOfDifference: string()
    .trim()
    .required()
    .label('Capabilities or point of difference'),
  termsConditions: string()
    .trim()
    .required()
    .label('Exclusions or terms and conditions'),
  quotes: array().of(
    object({
      service: object().defined(),
      optOut: boolean(),
      additionalInfo: string().when('service', {
        is: (service) =>
          service.attributes.type === 'multi_service_requirements',
        then: string().required().label('Pricing options/estimates'),
      }),
      pricing: array().when(['optOut', 'service'], {
        is: (optOut: boolean, service) =>
          optOut === false &&
          service.attributes.type !== 'multi_service_requirements',
        then: array().of(
          object({
            contractTerm: object({
              value: number().required(),
              unit: string().required(),
            })
              .nullable()
              .required(),
            recurringCost: object({
              value: number().required().min(0),
              unit: string().required(),
            })
              .nullable()
              .required(),
            nonRecurringCost: object({
              value: number().required().min(0),
              unit: string().required(),
            })
              .nullable()
              .required(),
          }).defined()
        ),
      }),
    }).defined()
  ),
}).defined();

export default schema;
