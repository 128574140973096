import { useCallback, useRef } from 'react';
import { useMounted } from './useMounted';

export function useThrottle<Callback extends (...args: any[]) => void>(
  fn: Callback,
  timeout: number = 300
): Callback {
  const isMounted = useMounted();
  const timer = useRef<number | undefined>(undefined);
  const result = useRef<ReturnType<Callback> | void>();

  const callback = useCallback(
    (...args) => {
      if (!timer.current && isMounted) {
        result.current = fn(...args);

        timer.current = window.setTimeout(() => {
          if (isMounted) timer.current = undefined;
        }, timeout);
      }

      return result.current;
    },
    [fn, timeout, isMounted]
  );

  return callback as Callback;
}
