import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
export type PagingProps = {
  first: number;
  last: number;
  current: number;
  total: number;
  onChange: (page: number) => void;
};
const Paging: React.FC<PagingProps> = ({
  first,
  last,
  current,
  total,
  onChange,
}) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs="auto">
        <IconButton
          size="small"
          disabled={current === first}
          onClick={() => {
            onChange(current > first ? current - 1 : current);
          }}
        >
          <NavigateBefore />
        </IconButton>
      </Grid>
      <Grid item xs="auto">
        <Typography component="div" variant="body2">
          {current} / {last}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <IconButton
          size="small"
          disabled={current === last}
          data-testid="paging-next-button"
          onClick={() => {
            onChange(current < last ? current + 1 : current);
          }}
        >
          <NavigateNext />
        </IconButton>
      </Grid>
    </Grid>
  );
};
export default Paging;
