import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import { getPageTitleTemplate } from '../../constants';

const MyMarketplace: React.FunctionComponent<
  RouteComponentProps & {
    title?: string;
  }
> = ({ title = 'My Marketplace', children }) => {
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default MyMarketplace;
