import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Divider, Box } from '@material-ui/core';
import { useAppState } from '../../contexts/AppState';

interface StaticSplitViewProps {
  open: boolean;
  content: ReactNode;
  detail?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    minHeight: '80vh',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
      /*
        On mobile this was causing clipping overflow issues with the box shadow of the ListingCard.
        This is probably due to some negative margin grid issues from Grid but it's not worth fixing it
        as we don't need this scrolling behaivour on mobile anyway
      */
      height: 'calc(100vh - 210px)',
      overflowY: 'scroll',
    },
  },
  dividerContainer: {
    position: 'absolute',
    left: '50%',
    height: '100%',
    width: 1,
  },
  divider: {
    width: 1,
    backgroundColor: theme.palette.grey[300],
  },
  detailFullScreen: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
    },
  },
  drawer: {
    height: 'calc(100vh - 210px)',
    overflowY: 'scroll',
    padding: theme.spacing(1, 5),
  },
}));

export const StaticSplitViewLayout: React.FC<StaticSplitViewProps> = ({
  open,
  content,
  detail,
}: StaticSplitViewProps) => {
  const classes = useStyles();
  const appState = useAppState();
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';

  return (
    <Grid container spacing={0} className={classes.root}>
      {splitScreenMode || !open ? (
        <Grid item xs={open ? 6 : true}>
          <div className={classes.content}>{content}</div>
        </Grid>
      ) : null}
      {open &&
        (splitScreenMode ? (
          <>
            <Box className={classes.dividerContainer}>
              <Divider
                component="div"
                orientation="vertical"
                className={classes.divider}
              />
            </Box>
            <Grid item xs={6}>
              <div className={classes.drawer}>{detail}</div>
            </Grid>
          </>
        ) : (
          <Grid item xs className={classes.detailFullScreen}>
            {detail}
          </Grid>
        ))}
    </Grid>
  );
};
