import React, { createElement, Fragment } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { parseISO } from 'date-fns';
import InitialsAvatar from '../InitialsAvatar';
import ListingExpiry from '../ListingExpiry';
import ListingType from '../ListingType';
import { OpportunityOwner } from '../OpportunityOwner';
import Pill from '../Pill';
import { ListingResponse } from '../../types/marketplace-service/listing';

export interface ListingCardProps extends ListingResponse {
  userId?: string | null;
  userName?: string | null;
  invited?: boolean;
  status?: React.ReactNode;
  opportunity?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '4px',
      boxShadow: theme.shadows[4],
      border: '1px solid transparent',
    },
  })
);

const ListingCard: React.FunctionComponent<ListingCardProps> = ({
  attributes,
  userId = null,
  userName = null,
  invited,
  status,
  opportunity,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={6}>
            {opportunity ? (
              <Typography noWrap> {attributes.company} </Typography>
            ) : (
              <ListingType type={attributes.visibility} />
            )}
          </Grid>
          <Grid item>
            <ListingExpiry
              end={parseISO(attributes.end)}
              state={attributes.state}
            />
          </Grid>
          <Grid item xs={12}>
            {invited && <Pill label="Invited" variant="outlined" />}
            <Typography
              color="textPrimary"
              variant="body2"
              title={attributes.title}
            >
              {attributes.title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container>
          <Grid item xs>
            {createElement(
              opportunity ? OpportunityOwner : Fragment,
              undefined,
              <>
                {userId && (
                  <InitialsAvatar usersName={userName} userId={userId} small />
                )}
              </>
            )}
          </Grid>
          <Grid item>{status}</Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ListingCard;
