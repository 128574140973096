import { accountServiceAPI } from './base';
import {
  transformEntityCollectionResponse,
  transformEntityResponse,
} from '../transform-response';
import {
  CompanyAccountIncludes,
  CompanyAccountResponse,
} from '../../../types/account-service/company-account';
import {
  EntityCollectionResponse,
  EntityResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import {
  UserResponse,
  UserResponseIncludes,
} from '../../../types/account-service/users';

export const companyAccountsAPI = accountServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    readCompanyAccountByID: builder.query<
      EntityResponse<CompanyAccountResponse, CompanyAccountIncludes>,
      { id: string; params?: URLQueryParams }
    >({
      providesTags: (_result, _error, args) => [
        { type: 'CompanyAccount', id: args.id },
      ],
      query: (args) => ({
        url: `/companyAccounts/${encodeURIComponent(args.id)}`,
        method: 'GET',
        params: args.params,
      }),
      transformResponse: transformEntityResponse,
    }),
    readCompanyAccountUsers: builder.query<
      EntityCollectionResponse<UserResponse, UserResponseIncludes>,
      { id: string; params?: URLQueryParams }
    >({
      providesTags: (result, _error, _args) =>
        result?.entities.map((user) => ({ type: 'User', id: user.id })) ?? [],
      query: (args) => ({
        url: `/companyAccounts/${encodeURIComponent(args.id)}/users`,
        method: 'GET',
        params: args.params,
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
  }),
});

export const {
  useLazyReadCompanyAccountByIDQuery,
  useLazyReadCompanyAccountUsersQuery,
  usePrefetch,
  useReadCompanyAccountByIDQuery,
  useReadCompanyAccountUsersQuery,
} = companyAccountsAPI;
