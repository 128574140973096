import { Mutator } from 'final-form';
import { CreateListingFormState } from './types';

export const addResource: Mutator<CreateListingFormState> = (
  [name, value],
  state,
  { changeValue }
) => {
  changeValue(
    state,
    name,
    (
      resources: { state: string; value: any }[]
    ): {
      state: string;
      value: any;
    }[] =>
      resources
        ? [...resources, { state: 'add', value }]
        : [{ state: 'add', value }]
  );
};

export const editResource: Mutator<CreateListingFormState> = (
  [name, index, value],
  state,
  { changeValue }
) => {
  changeValue(
    state,
    name,
    (
      resources: { state: string; value: any }[] = []
    ): {
      state: string;
      value: any;
    }[] => {
      const copy = resources.slice(0);
      const resource = resources[index];

      if (resource.state === 'initial') {
        copy.splice(index, 1, { state: 'edit', value });
      } else {
        copy.splice(index, 1, { state: resource.state, value });
      }

      return copy;
    }
  );
};

export const duplicateResource: Mutator<CreateListingFormState> = (
  [name, index],
  state,
  { changeValue }
) => {
  changeValue(
    state,
    name,
    (
      resources: { state: string; value: any }[] = []
    ): {
      state: string;
      value: any;
    }[] => {
      const copy = resources.slice(0);
      const resource = resources[index];

      copy.splice(index, 0, { state: 'add', value: resource.value });

      return copy;
    }
  );
};

export const deleteResource: Mutator<CreateListingFormState> = (
  [name, index],
  state,
  { changeValue }
) => {
  changeValue(
    state,
    name,
    (
      resources: { state: string; value: any }[] = []
    ): {
      state: string;
      value: any;
    }[] => {
      const copy = resources.slice(0);
      const resource = resources[index];

      if (resource.state !== 'add') {
        copy.splice(index, 1, { state: 'delete', value: resource.value });
      } else {
        copy.splice(index, 1);
      }

      return copy;
    }
  );
};

export const deleteResources: Mutator<CreateListingFormState> = (
  [name, indexes],
  state,
  { changeValue }
) => {
  changeValue(
    state,
    name,
    (
      resources: { state: string; value: any }[] = []
    ): {
      state: string;
      value: any;
    }[] => {
      const copy = resources.slice(0);

      indexes.forEach((index) => {
        const resource = resources[index];
        if (resource.state !== 'add') {
          copy[index] = {
            ...resource,
            state: 'delete',
          };
        } else {
          copy[index] = {
            ...resource,
            state: 'NULL',
            value: null,
          };
        }
      });

      return copy.filter((c) => !!c.value);
    }
  );
};
