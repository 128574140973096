import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';

interface UpdateListingConfirmationProps {
  submitting: boolean;
  valid: boolean;
  onConfirm: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      margin: '0 auto',
    },
  })
);

const UpdateListingConfirmation: React.FC<UpdateListingConfirmationProps> = ({
  submitting,
  valid,
  onConfirm,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        fullWidth
        disabled={submitting || !valid}
      >
        Update Listing
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Listing Updates
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once updated, service providers that have submitted bids on this
            listing will receive a notification about the changes and be given
            the opportunity to resubmit their quotes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={onConfirm}
            disabled={submitting}
            endIcon={
              submitting && <CircularProgress color="secondary" size={20} />
            }
            variant="contained"
            color="primary"
            autoFocus
          >
            Update Listing
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateListingConfirmation;
