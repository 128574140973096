import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import Invitation from '../containers/Signup/Invitation';
import { getPageTitleTemplate, SIGNUP_URL } from '../constants';
import FullScreenLoader from '../components/FullScreenLoader';
import { ROUTES } from '../routes';
import { Typography } from '@material-ui/core';
import { useAssignListingProviderMutation } from '../services/api/marketplace-service/listing-providers';
import { getRelationshipContext } from '../utilities/relationships';
import { useAppMessenger } from '../store/messenger';
import { useAuth } from '../hooks/useAuth';
import { useThrottle } from '../hooks/useThrottle';

const AssignInvitePage: FunctionComponent<
  RouteComponentProps<{
    listingId: string;
    listingProviderId: string;
  }>
> = ({ listingId = '', listingProviderId = '' }) => {
  const title = 'Invited Opportunity';
  const { isAuthenticated, signin, user } = useAuth();
  const [assignListingProvider] = useAssignListingProviderMutation();
  const messenger = useAppMessenger();

  const onLogin = () => {
    signin({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };
  const onJoin = () => {
    // TEMPORARY: should be removed later, see SignupPage/index.tsx
    window.localStorage.setItem('returnUrl', window.location.href);
    if (SIGNUP_URL)
      window.location.href = `${SIGNUP_URL}?return_url=${window.location.href}`;
  };

  const onAssign = useCallback(async () => {
    return await assignListingProvider({
      listing: listingId,
      provider: listingProviderId,
      user: user?.id,
    })
      .unwrap()
      .then((response) => {
        const listing = getRelationshipContext(
          response.context.listing,
          response.entity.relationships.listing.data.id
        );
        messenger.addMessage('info', 'You have a new opportunity');

        if (listing) {
          navigate(
            `${ROUTES.sellingOpportunities}/${listing.attributes.short_id}`,
            {
              replace: true,
            }
          );
        }
      })
      .catch(() => {
        messenger.addMessage('error', 'Error accepting invitation');
        navigate(ROUTES.root, { replace: true });
      });
  }, [assignListingProvider, listingId, listingProviderId, messenger, user]);

  const handleAssign = useThrottle(onAssign, 3000);

  useEffect(() => {
    if (isAuthenticated && user) {
      handleAssign();
    }
  }, [isAuthenticated, user, handleAssign]);

  if (isAuthenticated) {
    return <FullScreenLoader />;
  }
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <Invitation
        body={
          <Typography>
            Ready to join your team on Cloudscene, respond to your assigned
            opportunity, and support more network buyers actively sourcing
            services on Marketplace?
          </Typography>
        }
        header={<Typography variant="h1">You're almost there...</Typography>}
        onLogin={onLogin}
        onJoin={onJoin}
      />
    </>
  );
};

export default AssignInvitePage;
