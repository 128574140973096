import React from 'react';
import ServiceDetailsEthernet from './Ethernet';
import ServiceDetailsIPTransit from './IPTransit';
import ServiceDetailsDarkFiber from './DarkFiber';
import ServiceDetailsCloudConnectivity from './CloudConnectivity';
import ServiceDetailsInternetAccess from './InternetAccess';
import ServiceDetailsWavelength from './Wavelength';
import ServiceDetailsColocation from './Colocation';
import ServiceDetailsCloudServices from './CloudServices';
import ServiceDetailsSDWan from './SDWan';
import ServiceDetailsMulti from './Multi';
import {
  Services,
  ServiceTypes,
  ServiceEthernet,
  ServiceIPTransit,
  ServiceDarkFiber,
  ServiceInternetAccess,
  ServiceCloudConnectivity,
  ServiceWavelength,
  ServiceColocation,
  ServiceCloudServices,
  ServiceSDWan,
  ServiceMultiRequirements,
} from '../../types/services';

const ServiceDetails: React.FC<{
  service: Services;
  marketplaceMatchLabels?: boolean;
  listingEndDate?: string;
}> = ({ children, marketplaceMatchLabels, service, listingEndDate }) => {
  if (!service.attributes) return null;

  return {
    [ServiceTypes.ethernet]: (
      <ServiceDetailsEthernet
        {...(service as ServiceEthernet)}
        marketplaceMatchLabels={marketplaceMatchLabels}
        listingEndDate={listingEndDate}
      />
    ),
    [ServiceTypes.mpls]: (
      <ServiceDetailsEthernet {...(service as ServiceEthernet)} />
    ),
    [ServiceTypes.ip_transit]: (
      <ServiceDetailsIPTransit
        {...(service as ServiceIPTransit)}
        marketplaceMatchLabels={marketplaceMatchLabels}
        listingEndDate={listingEndDate}
      />
    ),
    [ServiceTypes.dark_fiber]: (
      <ServiceDetailsDarkFiber
        {...(service as ServiceDarkFiber)}
        marketplaceMatchLabels={marketplaceMatchLabels}
        listingEndDate={listingEndDate}
      />
    ),
    [ServiceTypes.cloud_connectivity]: (
      <ServiceDetailsCloudConnectivity
        {...(service as ServiceCloudConnectivity)}
      />
    ),
    [ServiceTypes.internet_access]: (
      <ServiceDetailsInternetAccess
        {...(service as ServiceInternetAccess)}
        marketplaceMatchLabels={marketplaceMatchLabels}
        listingEndDate={listingEndDate}
      />
    ),
    [ServiceTypes.wavelength]: (
      <ServiceDetailsWavelength
        {...(service as ServiceWavelength)}
        marketplaceMatchLabels={marketplaceMatchLabels}
        listingEndDate={listingEndDate}
      />
    ),
    [ServiceTypes.colocation]: (
      <ServiceDetailsColocation
        {...(service as ServiceColocation)}
        marketplaceMatchLabels={marketplaceMatchLabels}
        listingEndDate={listingEndDate}
      />
    ),
    [ServiceTypes.cloud_services]: (
      <ServiceDetailsCloudServices {...(service as ServiceCloudServices)} />
    ),
    [ServiceTypes.sd_wan]: (
      <ServiceDetailsSDWan {...(service as ServiceSDWan)} />
    ),
    [ServiceTypes.multi_service_requirements]: (
      <ServiceDetailsMulti {...(service as ServiceMultiRequirements)} />
    ),
    [ServiceTypes.other]: <></>,
  }[service.type];
};

export default ServiceDetails;
