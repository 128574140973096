import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ListingGroupProps from './ListingGroupProps';

const ListingCardGroup: React.FC<ListingGroupProps> = ({ title, children }) => {
  return (
    <Box py={2}>
      <Typography variant="h6" color="textPrimary" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={4}>
        {children}
      </Grid>
    </Box>
  );
};

export default ListingCardGroup;
