import React, { useRef, useState } from 'react';
import { noop } from 'lodash';
import Menu from '@material-ui/core/Menu';
import { MenuItem, Typography, IconButton } from '@material-ui/core';
import { ProviderContact } from '../../../types/provider-contact';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      color: theme.palette.primary.main,
    },
    addAction: {
      color: theme.palette.primary.main,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    contactDetails: {
      width: '100%',
    },
    contactMenuItem: {
      fontSize: '0.825rem',
      color: theme.palette.common.black,
    },
    contactEmail: {
      color: theme.palette.grey[500],
    },
    addContactsIcon: {
      backgroundColor: theme.palette.common.white,
      fontSize: '1.3rem',
      borderRadius: 3,
      boxShadow: theme.shadows[5],
    },
  })
);

interface ManageContactsMenuProps {
  contacts: ProviderContact[];
  onSelect?: (contact: ProviderContact) => void;
  onAdd?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
}
const ManageContactsMenu: React.FC<ManageContactsMenuProps> = ({
  contacts,
  onSelect = noop,
  onAdd = noop,
  onEdit,
  onRemove,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(true);
        }}
      >
        <AddBoxIcon color="primary" className={classes.addContactsIcon} />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={ref.current}>
        {onEdit && (
          <MenuItem
            className={classes.actions}
            onClick={(event) => {
              onEdit();
              handleClose(event);
            }}
          >
            Edit Contact
          </MenuItem>
        )}
        {onRemove && (
          <MenuItem
            className={classes.actions}
            onClick={(event) => {
              onRemove();
              handleClose(event);
            }}
          >
            Remove Contact
          </MenuItem>
        )}
        <MenuItem
          className={classes.addAction}
          onClick={(event) => {
            onAdd();
            handleClose(event);
          }}
        >
          Add New Contact
        </MenuItem>
        {contacts.map((contact: ProviderContact) => {
          return (
            <MenuItem
              key={contact.id}
              onClick={(event) => {
                onSelect(contact);
                handleClose(event);
              }}
            >
              <Typography variant="body2" className={classes.contactMenuItem}>
                {contact.attributes.first_name} {contact.attributes.last_name}
                <br />
                <span className={classes.contactEmail}>
                  {contact.attributes.email}
                </span>
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ManageContactsMenu;
