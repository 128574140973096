import React, { useCallback, useState } from 'react';
import {
  Form,
  FormRenderProps,
  FormSpy,
  FormSpyRenderProps,
} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { addDays, formatISO } from 'date-fns';
import { dequal } from 'dequal/lite';
import { makeValidator } from '../../makeValidator';
import { useDispatch } from 'react-redux';
import requestQuoteImage from '../../assets/request-quote.png';
import {
  Box,
  Grid,
  Hidden,
  Collapse,
  useMediaQuery,
  Theme,
  Link,
  Typography,
} from '@material-ui/core';
import validator from './validation';
import { useAuth, AuthUserType } from '../../hooks/useAuth';
import {
  RequestQuoteProps,
  CreateListingFormState,
} from '../CreateListing/types';
import { addResource, deleteResource } from '../CreateListing/mutators';
import calculator from '../CreateListing/decorators';
import ServiceTypeTiles, {
  RequestQuoteHeader,
  Tiles,
} from '../../components/ServiceTypeTiles';
import { requestQuoteDraft } from '../CreateListing/make-listing';
import { RequestQuoteServicesList } from '../../constants';
import { ServiceTypes } from '../../types/services';
import {
  getInitialValuesForServiceRequirement,
  getMiniServiceRequirementValidationSchema,
} from '../../utilities/service-requirements';
import { createListingTitle } from '../../utilities/listings';
import { AppDispatch } from '../../store/types';
import { useAppState } from '../../contexts/AppState';
import { dispatchService } from '../../store/services';
import { useReadCompanyProfileByIDQuery } from '../../services/api/directory-service/company-profiles';
import { useCreateListingMutation } from '../../services/api/marketplace-service/listings';
import {
  useCreateListingProviderMutation,
  useDeleteListingProviderByListingIDMutation,
} from '../../services/api/marketplace-service/listing-providers';
import {
  useCreateListingMarketMutation,
  useDeleteListingMarketByIDMutation,
} from '../../services/api/marketplace-service/markets';

const getInitialValues = ({
  user,
  serviceType,
}: {
  user?: AuthUserType;
  serviceType?: ServiceTypes;
} = {}): Omit<CreateListingFormState, 'owner'> => {
  const defaultEndDate = formatISO(addDays(new Date(), 14));
  const defaultStartDate = formatISO(new Date());

  return {
    id: '',
    attributes: {
      description: '',
      end: defaultEndDate,
      external_contact_email: '',
      external_link: '',
      external_service_id: '',
      external_service_name: '',
      external: false,
      start: defaultStartDate,
      state: 'pending',
      title: '',
      visibility: 'private',
      user_id: user?.id,
      company: user?.employer ?? '',
      company_id: user?.company_account_id ?? '',
      company_logo: user?.account_logo ?? '',
      scheduled: 'not',
      origin: `${process.env.REACT_APP_APP_URL}/${window.location.pathname}`,
      invite_providers: false,
    },
    contacts: [],
    documents: [],
    services: [],
    multi_requirements: [],
    markets: [],
    providers: [],
    provider_contacts: [],
    status: {
      private: true,
      gated: false,
    },
    service: getInitialValuesForServiceRequirement(serviceType),
  };
};

export const RequestQuoteForm: React.FC<RequestQuoteProps> = ({
  providerId,
  onCreate,
}) => {
  const { user } = useAuth();
  const appState = useAppState();
  const dispatch: AppDispatch = useDispatch();
  const [view, setView] = useState<'buttons' | 'form'>('buttons');
  const [serviceType, setServiceType] = useState<ServiceTypes>(
    ServiceTypes.ethernet
  );
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const serviceValidationSchema =
    getMiniServiceRequirementValidationSchema(serviceType);
  const validatorSchema = validator.concat(serviceValidationSchema).defined();
  const [createListingMutation] = useCreateListingMutation();
  const [createListingProviderMutation] = useCreateListingProviderMutation();
  const [deleteListingProviderMutation] =
    useDeleteListingProviderByListingIDMutation();
  const [createListingMarketMutation] = useCreateListingMarketMutation();
  const [deleteListingMarketMutation] = useDeleteListingMarketByIDMutation();

  const handleSubmit = useCallback(
    async (values) => {
      const marketValues = values.markets;
      const marketDetails = marketValues.map(
        (value, index) => appState.markets[value.value.attributes.id]
      );
      const title = createListingTitle(marketDetails, values.service!);
      const { id, type, attributes, services, markets, providers } =
        requestQuoteDraft(values);
      const titleAndAttributes = { ...attributes, title };
      return await createListingMutation({
        id: id,
        payload: {
          id: id,
          type: type,
          attributes: titleAndAttributes,
        },
      })
        .unwrap()
        .then((response) => response.entity)
        .then(({ id }) => {
          const dispatchedServices = [
            dispatch(
              dispatchService('add', {
                listing: id,
                service: services[0],
              })
            ),
          ];
          const dispatchedMarkets = values.markets.map(({ state }, index) => {
            const marketId = markets[index].id;
            if (state === 'add') {
              return createListingMarketMutation({
                listing: id,
                payload: markets[index],
              });
            }
            if (state === 'delete' && marketId) {
              return deleteListingMarketMutation({
                listing: id,
                market: marketId,
              });
            }
            return [];
          });

          const dispatchedProviders = values.providers.map(
            ({ state }, index) => {
              const providerId = providers[index].id;
              if (state === 'add') {
                return createListingProviderMutation({
                  listing: id,
                  payload: providers[index],
                });
              }
              if (state === 'delete' && providerId) {
                return deleteListingProviderMutation({
                  listing: id,
                  provider: providerId,
                });
              }
              return [];
            }
          );
          return Promise.all([
            ...dispatchedServices,
            ...dispatchedMarkets,
            ...dispatchedProviders,
          ]);
        })
        .then(() => onCreate?.());
    },
    [
      createListingMutation,
      createListingProviderMutation,
      deleteListingProviderMutation,
      createListingMarketMutation,
      deleteListingMarketMutation,
      onCreate,
      dispatch,
      appState.markets,
    ]
  );
  const { data, isLoading } = useReadCompanyProfileByIDQuery({
    id: providerId,
  });
  if (isLoading) return null;
  if (!data)
    return <Typography>Sorry, could not resolve service provider</Typography>;

  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        <Box mb={3}>
          <RequestQuoteHeader providerId={providerId} />
        </Box>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
          <Collapse in={view === 'buttons'}>
            <Box px={2}>
              <Grid container>
                <Grid
                  spacing={3}
                  container
                  item
                  alignItems={mobile ? 'center' : 'stretch'}
                  xs="auto"
                >
                  {RequestQuoteServicesList.map((type, index) => (
                    <Tiles
                      key={index}
                      type={type}
                      setView={(view: 'buttons' | 'form') => setView(view)}
                      setService={(service: ServiceTypes) =>
                        setServiceType(service)
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={view === 'form'}>
            <Box px={2}>
              <Link onClick={() => setView('buttons')} underline="none">
                <Typography color="primary" align="right" gutterBottom>
                  Change Service
                </Typography>
              </Link>
              <Form
                initialValues={{
                  ...getInitialValues({ user, serviceType }),
                  owner: {
                    full_name: user?.name ?? '',
                    email: user?.email ?? '',
                    position: user?.position ?? '',
                    phone: user?.phone ?? '',
                    user_details_agreement: false,
                    company_id: user?.company_account_id ?? null,
                  },
                }}
                validate={makeValidator(validatorSchema)}
                onSubmit={handleSubmit}
                initialValuesEqual={(a, b) => dequal(a, b)}
                subscription={{ pristine: true }}
                decorators={[calculator]}
                mutators={{
                  ...arrayMutators,
                  addResource,
                  deleteResource,
                }}
                render={({
                  form,
                  values,
                }: FormRenderProps<CreateListingFormState>) => (
                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }: FormSpyRenderProps) => (
                      <ServiceTypeTiles
                        providerId={providerId}
                        service={serviceType}
                        onSubmit={(invite_providers: boolean) => {
                          const attributes = values.attributes;
                          form.change('attributes', {
                            ...attributes,
                            invite_providers: invite_providers,
                          });
                          form.mutators.addResource('providers', {
                            type: 'listing-provider',
                            relationships: {
                              provider: {
                                data: { type: 'provider', id: providerId },
                              },
                            },
                          });
                          form.submit();
                        }}
                      />
                    )}
                  </FormSpy>
                )}
              />
            </Box>
          </Collapse>
        </Grid>
        <Hidden mdDown>
          <Grid item sm={3}>
            <img alt="landing page" src={requestQuoteImage} />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};
