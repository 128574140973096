import { ReactNode, useEffect, useMemo, useRef } from 'react';
import {
  flip,
  offset,
  shift,
  useFloating,
  Placement,
  autoUpdate,
} from '@floating-ui/react-dom';
import { Transition } from 'react-transition-group';
import tw from 'twin.macro';
import { DropdownMenu } from '../DropdownMenu';
import { placements, transitions } from './floating.action.styles';

export const FloatingAction = ({
  action,
  origin = 'bottom-start',
  trigger,
  children,
}: {
  action: ReactNode;
  origin?: Placement;
  trigger?: Array<'click' | 'hover'>;
  children: ReactNode;
}) => {
  const transitionNodeRef = useRef<HTMLElement | null>(null);

  const { x, y, reference, floating, strategy, placement, refs, update } =
    useFloating({
      strategy: 'absolute',
      placement: origin,
      middleware: [offset(10), flip(), shift({ padding: 10 })],
    });

  const xy = useMemo(() => [x, y], [x, y]);

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }

    // Only call this when the floating element is rendered
    return autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [refs.reference, refs.floating, update, xy]);

  return (
    <div>
      <DropdownMenu
        trigger={trigger}
        action={<div ref={reference}>{action}</div>}
      >
        {({ isOpen }) => (
          <div
            ref={floating}
            css={{
              position: strategy,
              top: 0,
              left: 0,
              transform:
                typeof x === 'number' && typeof y === 'number'
                  ? `translate(${Math.round(x)}px,${Math.round(y)}px)`
                  : undefined,
              transitionProperty: 'transform',
              transitionDuration: '80ms',
              pointerEvents: isOpen ? 'auto' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
              zIndex: isOpen ? 99 : 0,
            }}
          >
            <Transition
              in={isOpen}
              timeout={100}
              appear
              nodeRef={transitionNodeRef}
            >
              {(status) => (
                <div
                  css={[
                    tw`relative transition-all duration-100 ease-in-out`,
                    placements[placement],
                    transitions[status],
                  ]}
                >
                  {children}
                </div>
              )}
            </Transition>
          </div>
        )}
      </DropdownMenu>
    </div>
  );
};
