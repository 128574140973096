import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../types.d';
import { fetchAccountById } from './actions';

export type Account = {
  type: 'account';
  id: string;
  attributes: {
    name: string;
    email: string;
    website: string;
    country: string;
    company_type: 'business_gov';
    company_industry: string;
    company_name: string;
    company_logo: string;
  };
};

const accountAdapter = createEntityAdapter<Account>();

const account = createSlice({
  name: 'account',
  initialState: accountAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountById.fulfilled, (state, action) => {
      accountAdapter.addOne(state, action.payload.data);
    });
  },
});

export { fetchAccountById };

export const { selectById: selectAccountById } = accountAdapter.getSelectors(
  (state: RootState) => state.account
);

export default account.reducer;
