import React from 'react';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InitialsAvatar from '../InitialsAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export type BidderInfo = {
  id?: string;
  company?: string;
  name: string;
  email?: string;
  phone?: string;
};

const BidderInfoBlock = ({ company, name, id, email, phone }: BidderInfo) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <InitialsAvatar usersName={name} userId={id} />
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.name}>
              {name}
            </Typography>
            {company && <Typography variant="body2">{company}</Typography>}
            <Hidden smUp>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">{phone}</Typography>
                <Typography variant="body2">{email}</Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">{phone}</Typography>
          <Typography variant="body2">{email}</Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default BidderInfoBlock;
