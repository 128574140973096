import React, { ReactNode } from 'react';
import { HiOutlineEyeOff } from 'react-icons/hi';
import tw from 'twin.macro';
import { ToolTip } from '../Cloudscene/ToolTip';
import { Caption, Typography } from '../Cloudscene/Typography';
import { PrimaryButton } from '../Cloudscene/Button';
import { COMPANY_PACKAGES, useAuth } from '../../hooks/useAuth';
import { EXPLORE_URL } from '../../api';
import { isSalesManager } from '../../utilities/users';

export const OpportunityOwner = ({ children }: { children?: ReactNode }) => {
  const { user, hasCompanyPackage } = useAuth();

  if (hasCompanyPackage(COMPANY_PACKAGES.FREE)) {
    return (
      <ToolTip
        variant="light"
        label={
          <div css={tw`grid grid-flow-row gap-4`}>
            <Caption>
              To see who owns leads/opportunities, upgrade to a team
              subscription
            </Caption>
            <PrimaryButton
              as="a"
              target="_blank"
              href={`${EXPLORE_URL}/for-sellers/pricing/`}
            >
              See all features
            </PrimaryButton>
          </div>
        }
        popper={{ delayHide: 300, delayShow: 100, interactive: true }}
      >
        <div>
          <HiOutlineEyeOff size={20} />
        </div>
      </ToolTip>
    );
  }

  if (!isSalesManager(user)) {
    return (
      <ToolTip
        variant="light"
        label={
          <div css={tw`grid grid-flow-row gap-2`}>
            <Typography css={tw`font-bold`}>
              You don't have the permission to see assignees
            </Typography>
            <Caption>
              You need to be a Sales Manager on your team in order to see
              assigned members. Contact your team manager to request the
              permission.
            </Caption>
          </div>
        }
        popper={{ delayHide: 300, delayShow: 100, interactive: true }}
      >
        <div>
          <HiOutlineEyeOff size={20} />
        </div>
      </ToolTip>
    );
  }

  return <>{children}</>;
};
