import tw, { styled } from 'twin.macro';

export const Option = styled.div(() => [tw`rounded-lg relative`]);

export const Label = styled.label<{ disabled?: boolean }>(({ disabled }) => [
  tw`flex flex-col justify-center items-start text-gray-500 font-normal rounded-md text-sm m-0 px-12 py-2.5 border-2 border-gray-200 h-full w-full`,
  !disabled &&
    tw`cursor-pointer hover:(text-gray-900 border-gray-300) focus-within:(text-gray-900)`,
  !disabled && tw`ring-0 ring-brand active:(ring-2) peer-focus:(ring-2)`,
  disabled && tw`opacity-70`,
  tw`peer-checked:(ring-2)`,
]);

export const Input = styled.input.attrs({ type: 'radio', className: 'peer' })(
  () => [
    tw`absolute h-5 w-5 transform -translate-y-1/2 top-1/2 translate-x-4`,
    tw`not-disabled:(cursor-pointer) disabled:(opacity-70 border-gray-300) outline-none`,
  ]
);
