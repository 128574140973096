import { useEffect, FC } from 'react';
import { useAuth } from '../hooks/useAuth';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import FullScreenLoader from '../components/FullScreenLoader';

const Signout: FC<RouteComponentProps> = () => {
  const { signout } = useAuth();

  useEffect(() => {
    signout();
  }, [signout]);
  return <FullScreenLoader />;
};

export default Signout;
