import React from 'react';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { PrimaryButton } from '../../../../components/Cloudscene/Button';
import * as RadioGroup from '../../../../components/Cloudscene/Forms/RadioGroup';
import {
  Caption,
  Hint,
  Typography,
} from '../../../../components/Cloudscene/Typography';
import { useCreateUserSessionMutation } from '../../../../services/api/account-service/base.subscription';
import { useStripeCheckout } from '../../../../hooks/useStripeCheckout';
import { Overlay } from '../Overlay';
import { TEAM_URL } from '../../../../api';

export type SalesProFormState = { interval: 'monthly' | 'yearly' };

export const Checkout = () => {
  const stripe = useStripeCheckout();
  const [createUserSessionMutation] = useCreateUserSessionMutation();
  const { handleSubmit, register } = useForm<SalesProFormState>({
    defaultValues: {
      interval: 'monthly',
    },
  });

  const onSubmitMutation = useMutation(async (values: SalesProFormState) => {
    const successURL = new URL('/checkout/sales-pro', TEAM_URL);
    const cancelURL = new URL(window.location.href);

    successURL.searchParams.set('go', window.btoa(window.location.href));

    const { url } = await createUserSessionMutation({
      interval: values.interval,
      successUrl: successURL.toString(),
      cancelUrl: cancelURL.toString(),
    }).unwrap();

    return stripe.onCheckout(url, { isAsync: false });
  });

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmitMutation.mutateAsync(values))}
    >
      <fieldset>
        <div css={tw`grid grid-flow-row gap-5`}>
          <div css={tw`grid grid-rows-2 gap-2.5`}>
            <RadioGroup.Option>
              <RadioGroup.Input
                id="monthly"
                value="monthly"
                defaultChecked
                {...register('interval')}
              />
              <RadioGroup.Label htmlFor="monthly">
                <Typography css={tw`font-bold`}>
                  Monthly - $99.00 USD/month
                </Typography>
              </RadioGroup.Label>
            </RadioGroup.Option>

            <RadioGroup.Option>
              <RadioGroup.Input
                id="yearly"
                value="yearly"
                {...register('interval')}
              />
              <RadioGroup.Label htmlFor="yearly">
                <Typography css={tw`font-bold`}>
                  Yearly - $79.00 USD/month
                </Typography>
                <Hint css={tw`leading-tight p-0`}>$948 billed annually</Hint>
              </RadioGroup.Label>
            </RadioGroup.Option>
          </div>
          <div css={tw`justify-self-end`}>
            <PrimaryButton type="submit">Pay with Stripe</PrimaryButton>
          </div>
          {onSubmitMutation.isError && (
            <Caption css={tw`text-danger-600 text-center font-bold`}>
              We encountered an error while processing your request.
            </Caption>
          )}
        </div>

        {stripe.isLoading && <Overlay onCancel={stripe.onCancel} />}
      </fieldset>
    </form>
  );
};
