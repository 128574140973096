import { styled } from 'twin.macro';
import { base, primary, danger } from './menu.styles';

export type MenuLinkVariant = 'default' | 'primary' | 'danger';

export const MenuLink = styled.a<{ variant?: MenuLinkVariant }>(
  ({ variant = 'default' }) => [
    base,
    variant === 'primary' && primary,
    variant === 'danger' && danger,
  ]
);
