import { marketplaceServiceAPI } from './base';
import {
  EntityResponse,
  EntityCollectionResponse,
  URLQueryParams,
} from '../../../types/entity-collection';
import {
  transformEntityResponse,
  transformEntityCollectionResponse,
} from '../transform-response';
import {
  ListingProviderContact,
  ListingProviderMeta,
  ListingProviderRequest,
  ListingProviderResponse,
  ListingProviderResponseIncludes,
} from '../../../types/marketplace-service/listing-provider';

type ListingProviderCacheTag = { type: 'ListingProvider'; id: string };

export const listingProvidersAPI = marketplaceServiceAPI.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: (builder) => ({
    createListingProvider: builder.mutation<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      {
        listing: string;
        payload: ListingProviderRequest;
        params?: URLQueryParams;
      }
    >({
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.listing)}/providers`,
        params: args.params,
        method: 'POST',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    readAllListingProviders: builder.query<
      EntityCollectionResponse<
        ListingProviderResponse,
        ListingProviderResponseIncludes,
        ListingProviderMeta
      >,
      { params?: URLQueryParams } | void
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<ListingProviderCacheTag>(({ id }) => ({
                type: 'ListingProvider',
                id,
              }))
              .concat({ type: 'ListingProvider', id: 'LIST' })
          : [{ type: 'ListingProvider', id: 'LIST' }],
      query: (args) => ({
        url: `/listing-providers`,
        params: args ? args?.params : undefined,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readListingProviderByID: builder.query<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      { id: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'ListingProvider',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/listing-providers/${encodeURIComponent(args.id)}`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),

    readAllListingProvidersByListingID: builder.query<
      EntityCollectionResponse<
        ListingProviderResponse,
        ListingProviderResponseIncludes
      >,
      { listing: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result
          ? result.entities
              .map<ListingProviderCacheTag>(({ id }) => ({
                type: 'ListingProvider',
                id,
              }))
              .concat({ type: 'ListingProvider', id: 'LIST' })
          : [{ type: 'ListingProvider', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(args.listing)}/providers`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityCollectionResponse,
    }),
    readListingProviderByListingID: builder.query<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      { listing: string; provider: string; params?: URLQueryParams }
    >({
      providesTags: (result) =>
        result?.entity
          ? [
              {
                type: 'ListingProvider',
                id: result.entity.id,
              },
            ]
          : [],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(
          args.listing
        )}/providers/${encodeURIComponent(args.provider)}`,
        params: args.params,
        method: 'GET',
      }),
      transformResponse: transformEntityResponse,
    }),
    updateListingProviderByListingID: builder.mutation<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      {
        listing: string;
        provider: string;
        payload: ListingProviderRequest;
        params?: URLQueryParams;
      }
    >({
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'ListingProvider', id: result.entity.id },
              { type: 'ListingProvider', id: 'LIST' },
            ]
          : [{ type: 'ListingProvider', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(
          args.listing
        )}/providers/${encodeURIComponent(args.provider)}`,
        params: args.params,
        method: 'PATCH',
        body: JSON.stringify({ data: args.payload }),
      }),
      transformResponse: transformEntityResponse,
    }),
    deleteListingProviderByListingID: builder.mutation<
      void,
      { listing: string; provider: string }
    >({
      invalidatesTags: (result, error, args) =>
        result
          ? [
              { type: 'ListingProvider', id: args.provider },
              { type: 'ListingProvider', id: 'LIST' },
            ]
          : [{ type: 'ListingProvider', id: 'LIST' }],
      query: (args) => ({
        url: `/listings/${encodeURIComponent(
          args.listing
        )}/providers/${encodeURIComponent(args.provider)}`,
        method: 'DELETE',
      }),
    }),

    registerListingProvider: builder.mutation<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      {
        listing: string;
        provider?: string;
        user?: string;
      }
    >({
      invalidatesTags: (result, err, args) =>
        result
          ? [
              { type: 'ListingProvider', id: result.entity.id },
              { type: 'ListingProvider', id: 'LIST' },
              { type: 'Listing', id: args.listing },
            ]
          : [
              { type: 'ListingProvider', id: 'LIST' },
              { type: 'Listing', id: args.listing },
            ],
      query: (args) => {
        const payload: ListingProviderRequest = {
          type: 'listing-provider',
          attributes: {
            state: 'intend',
            external_assigned_to_user_id: args.user,
          },
        };

        if (args.provider) {
          return {
            url: `/listings/${encodeURIComponent(
              args.listing
            )}/providers/${encodeURIComponent(args.provider)}`,
            method: 'PATCH',
            params: { include: 'listing,bid' },
            body: JSON.stringify({ data: payload }),
          };
        }

        return {
          url: `/listings/${encodeURIComponent(args.listing)}/providers`,
          method: 'POST',
          params: { include: 'listing,bid' },
          body: JSON.stringify({ data: payload }),
        };
      },
      transformResponse: transformEntityResponse,
    }),

    assignListingProvider: builder.mutation<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      {
        listing: string;
        provider?: string;
        user?: string | null;
        contact?: ListingProviderContact;
      }
    >({
      invalidatesTags: (result, err, args) =>
        result
          ? [
              { type: 'ListingProvider', id: result.entity.id },
              { type: 'ListingProvider', id: 'LIST' },
              { type: 'Listing', id: args.listing },
            ]
          : [
              { type: 'ListingProvider', id: 'LIST' },
              { type: 'Listing', id: args.listing },
            ],
      query: (args) => {
        const payload: ListingProviderRequest = {
          type: 'listing-provider',
          attributes: {
            state: 'assign',
            external_assigned_to_user_id: args.user,
            assigned_to_contact: args.contact,
          },
        };
        if (args.provider) {
          return {
            url: `/listings/${encodeURIComponent(
              args.listing
            )}/providers/${encodeURIComponent(args.provider)}`,
            method: 'PATCH',
            params: { include: 'listing,bid' },
            body: JSON.stringify({ data: payload }),
          };
        }

        return {
          url: `/listings/${encodeURIComponent(args.listing)}/providers`,
          method: 'POST',
          params: { include: 'listing,bid' },
          body: JSON.stringify({ data: payload }),
        };
      },
      transformResponse: transformEntityResponse,
    }),

    declineListingProvider: builder.mutation<
      EntityResponse<ListingProviderResponse, ListingProviderResponseIncludes>,
      { listing: string; provider: string; reason: string }
    >({
      invalidatesTags: (result, err, args) =>
        result
          ? [
              { type: 'ListingProvider', id: result.entity.id },
              { type: 'ListingProvider', id: 'LIST' },
              { type: 'Listing', id: args.listing },
            ]
          : [
              { type: 'ListingProvider', id: 'LIST' },
              { type: 'Listing', id: args.listing },
            ],
      query: (args) => {
        const payload: ListingProviderRequest = {
          type: 'listing-provider',
          attributes: {
            state: 'decline',
            declined_reason: args.reason,
          },
        };

        return {
          url: `/listings/${encodeURIComponent(
            args.listing
          )}/providers/${encodeURIComponent(args.provider)}`,
          method: 'PATCH',
          params: { include: 'listing,bid' },
          body: JSON.stringify({ data: payload }),
        };
      },
      transformResponse: transformEntityResponse,
    }),
  }),
});

export const {
  useAssignListingProviderMutation,
  useCreateListingProviderMutation,
  useDeclineListingProviderMutation,
  useDeleteListingProviderByListingIDMutation,
  useLazyReadAllListingProvidersByListingIDQuery,
  useLazyReadAllListingProvidersQuery,
  useLazyReadListingProviderByIDQuery,
  useLazyReadListingProviderByListingIDQuery,
  usePrefetch,
  useReadAllListingProvidersByListingIDQuery,
  useReadAllListingProvidersQuery,
  useReadListingProviderByIDQuery,
  useReadListingProviderByListingIDQuery,
  useRegisterListingProviderMutation,
  useUpdateListingProviderByListingIDMutation,
} = listingProvidersAPI;
