import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { MARKETPLACE_SERVICE_API } from '../../../../api';
import { prepareHeaders } from '../../prepare-headers';

export const marketplaceServiceGQLAPI = createApi({
  reducerPath: 'marketplaceServiceGQLAPI',
  baseQuery: graphqlRequestBaseQuery({
    url: `${MARKETPLACE_SERVICE_API}/graphql`,
    headers: {
      'Content-Type': 'application/json',
    },
    prepareHeaders,
  }),
  tagTypes: ['ServiceProviderContact', 'MarketplaceLead'],
  endpoints: () => ({}),
});
