import React, { ReactNode } from 'react';
import tw from 'twin.macro';
import {
  Modal,
  ModalContextType,
  Overlay,
} from '../../../../components/Cloudscene/Modal';
import { Header } from './SalesProModal.Header';
import { Features } from './SalesProModal.Features';
import { Checkout } from './SalesProModal.Checkout';

export const SalesProModal = ({
  action,
}: {
  action: ReactNode | ((args: ModalContextType) => ReactNode);
}) => {
  return (
    <Modal Overlay={<Overlay variant="dark" />} action={action}>
      <div css={tw`flex flex-col`}>
        <Header />
        <div css={tw`flex flex-col gap-6 p-6`}>
          <Features />
          <Checkout />
        </div>
      </div>
    </Modal>
  );
};
