import { styled } from 'twin.macro';
import * as styles from './styles';

export interface IconButtonProps {
  variant?: 'default' | 'filled';
}

export const IconButton = styled.button.attrs(({ type = 'button' }) => ({
  type,
}))<IconButtonProps>(({ variant = 'default' }) => [
  styles.icon.DEFAULT,
  variant === 'filled' && styles.icon.filled,
]);
