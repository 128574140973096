import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  fetchListingsByPage,
  fetchListingById,
  fetchListingsByCompany,
  removeListingById,
  fetchAvailableMarkets,
} from './actions';
import { fetchAllOpportunities } from '../opportunities/actions';
import { Listing, ListingMeta } from '../../types/listing';
import { ListingStatus } from './types';
import { RootState } from '../types.d';
import { filterRelationshipEntityType } from '../../utilities/relationships';
import { RegionMarket } from '../../types/region-market';

const listingsAdapter = createEntityAdapter<Listing>();

export type ListingsMarketsStatus = 'idle' | 'loading' | 'error';

const listings = createSlice({
  name: 'listings',
  initialState: listingsAdapter.getInitialState({
    status: ListingStatus.IDLE,
    error: undefined as undefined | string,
    meta: null as null | ListingMeta,
    markets: [] as RegionMarket[],
    marketsStatus: 'idle' as ListingsMarketsStatus,
  }),
  reducers: {
    reset: (state) => {
      listingsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllOpportunities.fulfilled, (state, action) => {
      listingsAdapter.addMany(
        state,
        filterRelationshipEntityType<Listing>(
          'listing',
          action.payload.included
        )
      );
    });

    // FETCH BY PAGE
    builder
      .addCase(fetchListingsByPage.pending, (state, action) => {
        state.status = ListingStatus.PENDING;
        state.error = undefined;
        state.meta = null;
        listingsAdapter.removeAll(state);
      })
      .addCase(fetchListingsByPage.fulfilled, (state, action) => {
        state.status = ListingStatus.IDLE;
        state.meta = action.payload.meta;
        listingsAdapter.addMany(state, action.payload.data);
      })
      .addCase(fetchListingsByPage.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = ListingStatus.ERROR;
          state.error = action.error.message;
          listingsAdapter.removeAll(state);
        }
      });

    builder
      .addCase(fetchAvailableMarkets.pending, (state) => {
        state.marketsStatus = 'loading';
        state.markets = [];
      })
      .addCase(fetchAvailableMarkets.fulfilled, (state, action) => {
        state.marketsStatus = 'idle';
        state.markets = action.payload.data;
      })
      .addCase(fetchAvailableMarkets.rejected, (state) => {
        state.marketsStatus = 'error';
        state.markets = [];
      });

    // FETCH ONE
    builder.addCase(fetchListingById.fulfilled, (state, action) => {
      listingsAdapter.upsertOne(state, action.payload.data);
    });

    // FETCH BY COMPANY
    builder
      .addCase(fetchListingsByCompany.pending, (state, action) => {
        state.status = ListingStatus.PENDING;
        state.error = undefined;
        state.meta = null;
        listingsAdapter.removeAll(state);
      })
      .addCase(fetchListingsByCompany.fulfilled, (state, action) => {
        state.status = ListingStatus.IDLE;
        state.meta = action.payload.meta;
        listingsAdapter.addMany(state, action.payload.data);
      })
      .addCase(fetchListingsByCompany.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = ListingStatus.ERROR;
          state.error = action.error.message;
          listingsAdapter.removeAll(state);
        }
      });

    // DELETE
    builder.addCase(removeListingById.fulfilled, (state, action) => {
      listingsAdapter.removeOne(state, action.payload.id);
    });
  },
});

export const { reset: resetListings } = listings.actions;

export const {
  selectById: selectListingById,
  selectIds: selectListingIds,
  selectEntities: selectListingEntities,
  selectAll: selectAllListings,
  selectTotal: selectTotalListings,
} = listingsAdapter.getSelectors((state: RootState) => state.listings);

export default listings.reducer;
