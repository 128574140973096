import React, { ReactElement } from 'react';
import Container, { ContainerProps } from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Hidden, Typography, Box } from '@material-ui/core';

interface AppLayoutProps {
  title?: string;
  containerProps?: Omit<ContainerProps, 'children' | 'css'>;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  title,
  containerProps = {},
}): ReactElement => {
  return (
    <Grid container direction="column">
      {children && (
        <Container {...containerProps} component="main">
          <Box mt={2}>
            <Hidden mdUp>
              <Box mb={2}>
                <Typography variant="h5">{title}</Typography>
              </Box>
            </Hidden>
            {children}
          </Box>
        </Container>
      )}
    </Grid>
  );
};

export default AppLayout;
