import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormSpy, FormRenderProps } from 'react-final-form';
import { CreateListingFormState } from '../../../containers/CreateListing';

interface HeadingProps {
  onClose: () => void;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    titleHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    close: {
      color: theme.palette.grey[500],
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);
export const Heading: React.FC<HeadingProps> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.titleHeader}>
        <Typography variant="h4">Invite Service Providers</Typography>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={10}>
          <Typography variant="body2" component="div">
            Invite up to 20 service providers to quote your Marketplace listing.
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <FormSpy subscription={{ values: true }}>
            {({ values }: FormRenderProps<CreateListingFormState>) => {
              const providerLength = values.providers.filter(
                ({ state }) => state !== 'delete'
              ).length;
              return (
                <Typography
                  variant="body2"
                  component="div"
                  className={providerLength === 20 ? classes.error : ''}
                >
                  <b>{providerLength} / 20 Selected</b>
                </Typography>
              );
            }}
          </FormSpy>
        </Grid>
      </Grid>
    </>
  );
};
