import React from 'react';
import { Link } from '@reach/router';
import {
  Grid,
  useMediaQuery,
  Theme,
  makeStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import noListingsImage from '../../assets/marketplace-empty-seller-completed.svg';
import { ROUTES } from '../../routes';
import EmptyContent from '../../components/EmptyContent';
import {
  LeadListCard,
  LeadListHeader,
  LeadListRow,
} from '../../components/LeadListing';
import LoadingSpinner from '../../components/LoadingSpinner';
import UnstyledLink from '../UnstyledLink';
import { useAppState } from '../../contexts/AppState';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: '100%',
    width: 450,
  },
  emptyText: {
    fontWeight: 'normal',
  },
  label: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const LeadsSection = ({
  leads,
  isFetching,
  isSuccess,
  activeListingShortId,
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const appState = useAppState();
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';

  const noListings = leads.length === 0;

  return (
    <>
      <Grid container spacing={mobile ? 0 : 4} justifyContent="center">
        {isFetching && <LoadingSpinner />}
        {!isFetching && isSuccess && noListings && (
          <Grid
            item
            sm={10}
            xs={12}
            component={EmptyContent}
            img={
              <img
                className={classes.image}
                alt="landing page"
                src={noListingsImage}
              />
            }
            cta={
              <Button
                component={Link}
                to={ROUTES.root}
                variant="contained"
                color="primary"
              >
                Browse Leads
              </Button>
            }
          >
            <Typography color="primary" variant="h4">
              Hmm. Looks like you currently have no Leads on Cloudscene.
              Here&apos;s where all the listings your bids are passed on by
              buyer&apos;s will live.
            </Typography>
            <Box py={2}>
              <Typography variant="h5" className={classes.emptyText}>
                In the meantime, let&apos;s find more leads!
              </Typography>
            </Box>
          </Grid>
        )}
        {!isFetching && isSuccess && leads.length > 0 && (
          <>
            {mobile ? (
              <Box style={{ marginTop: '10px' }}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  Leads
                </Typography>
                <Grid
                  container
                  spacing={4}
                  style={{ margin: '0px', width: '100%' }}
                >
                  {leads.map((lead) => (
                    <UnstyledLink
                      to={`/my-marketplace/selling/leads/${lead.short_id}`}
                      replace={splitScreenMode}
                      style={{ width: '100%' }}
                    >
                      <LeadListCard lead={lead} key={lead.id} />
                    </UnstyledLink>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Box
                style={{
                  marginTop: '10px',
                  width: '100%',
                }}
              >
                <LeadListHeader />
                {leads.map((lead) => {
                  const active = activeListingShortId === lead.short_id;

                  return (
                    <UnstyledLink
                      to={`/my-marketplace/selling/leads/${lead.short_id}`}
                      replace={splitScreenMode}
                    >
                      <LeadListRow lead={lead} key={lead.id} active={active} />
                    </UnstyledLink>
                  );
                })}
              </Box>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default LeadsSection;
