import { FeatureKeysType } from '../constants';
import { Document } from './document';
import { MarketType } from './market';

export enum ServiceLevelTypes {
  enterprise_grade_99_9 = 'enterprise_grade_99_9',
  business_grade_99_5 = 'business_grade_99_5',
  no_sla = 'no_sla',
}

export type ServiceLevelKeys = keyof typeof ServiceLevelTypes;

export enum ServiceTypes {
  ethernet = 'ethernet',
  ip_transit = 'ip_transit',
  dark_fiber = 'dark_fiber',
  cloud_connectivity = 'cloud_connectivity',
  cloud_services = 'cloud_services',
  internet_access = 'internet_access',
  wavelength = 'wavelength',
  sd_wan = 'sd_wan',
  colocation = 'colocation',
  mpls = 'mpls',
  multi_service_requirements = 'multi_service_requirements',
  other = 'other',
}

export type ServiceTypeKeys = keyof typeof ServiceTypes;

export enum ServicePoints {
  address = 'address',
  cloud = 'cloud',
  internet = 'internet',
  'data-center' = 'data-center',
  // incase there are legacy DCs selected with this type
  'data_center' = 'data_center',
  colocation = 'colocation',
  market = 'market',
}

export type ServicePointType = keyof typeof ServicePoints;

export interface PhysicalAddress {
  type: ServicePointType;
  id?: string;
  description?: string;
}

export type PointA = Facility | Address | MarketOption;
export type PointZ =
  | Facility
  | Address
  | MarketOption
  | CloudOnRamp
  | CoLocationPoint
  | InternetPoint;

export interface CloudOnRamp extends PhysicalAddress {
  type: 'cloud';
  csp: string;
  market: string;
  on_ramp: string;
}

export interface Address extends PhysicalAddress {
  type: 'address';
  market?: string;
  zone?: string;
  id: string;
  description: string;
  address?: string;
}

export interface Facility extends PhysicalAddress {
  type: 'data-center' | 'data_center';
  logo: string;
  market?: string;
  zone?: string;
  dc_id?: string;
  id: string;
  description: string;
  address?: string;
}

export interface InternetPoint extends PhysicalAddress {
  type: 'internet';
}

export interface CoLocationPoint extends PhysicalAddress {
  type: 'colocation';
}

export type ServiceContractUnits = 'days' | 'month' | 'months' | 'years';

export type ServiceContractTerm = { value: number; unit: ServiceContractUnits };

export type ServiceBandwidthUnits = 'Mbps' | 'Gbps';

export type ServiceBandwidth = { value: number; unit: ServiceBandwidthUnits };

export type ServicePowerUnits = 'kW';

export type ServicePower = { value: number; unit: ServicePowerUnits };

export type ServiceBase = {
  id?: string;
  additional_info: string;
  contract_term: ServiceContractTerm | null;
  ready_for_service?: string;
  relationships?: {
    documents?: { data: Document[] };
  };
};

export type ServiceEthernetAttributes = {
  documents: (string | Document)[];
  external_service_name?: string;
  external_service_id?: string;
  point_a: Facility | Address | MarketOption | null;
  point_z: Facility | Address | MarketOption | null;
  bandwidth: ServiceBandwidth | null;
  advanced: {
    access_type?: Extract<
      FeatureKeysType,
      'fiber' | 'bb_fttx' | 'fixed_wireless' | '4g_5g'
    > | null;
    advanced_features: Record<
      Extract<FeatureKeysType, 'q_in_q' | 'jumbo_frames' | 'encryption'>,
      boolean
    >;
    minimum_sla: ServiceLevelKeys | null;
    hand_off: Extract<
      FeatureKeysType,
      'fiber_optical' | 'electrical_copper'
    > | null;
  };
};
export type ServiceEthernet = ServiceBase & {
  type: 'ethernet';
  attributes: ServiceEthernetAttributes;
};
export type ServiceMPLS = ServiceBase & {
  type: 'mpls';
  attributes: ServiceEthernetAttributes;
};

export type ServiceIPTransit = ServiceBase & {
  type: 'ip_transit';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
    point_a: Facility | Address | MarketOption | null;
    point_z: InternetPoint;
    commit: ServiceBandwidth | null;
    burst: ServiceBandwidth | null;
    advanced: {
      transit_type: Extract<
        FeatureKeysType,
        'domestic' | 'international'
      > | null;
      advanced_features: Record<
        Extract<
          FeatureKeysType,
          | 'bgp_community_support'
          | 'ddos_filtering_scrubbing'
          | 'remote_trigger_black_hole'
        >,
        boolean
      >;
    };
  };
};

export type ServiceDarkFiber = ServiceBase & {
  type: 'dark_fiber';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
    point_a: Facility | Address | MarketOption | null;
    point_z: Facility | Address | MarketOption | null;
    cores: number | null;
    advanced: {
      // Regression hazard MP-497
      interface_type_single_mode?: boolean;
      interface_type: Extract<
        FeatureKeysType,
        'interface_type_single_mode' | 'interface_type_multi_mode'
      > | null;
      diversity: Record<
        Extract<FeatureKeysType, 'building_lead_in' | 'street_geo'>,
        boolean
      >;
      mttr: Extract<
        FeatureKeysType,
        'mttr_enterprise' | 'mttr_business' | 'mttr_no_sla'
      > | null;
    };
  };
};

export type ServiceCloudConnectivity = ServiceBase & {
  type: 'cloud_connectivity';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
    point_a: Facility | Address | MarketOption | null;
    point_z: CloudOnRamp | null;
    bandwidth: ServiceBandwidth | null;
    advanced?: {
      access_type?: Extract<FeatureKeysType, 'fiber' | 'ethernet'> | null;
      advanced_features: Record<
        Extract<FeatureKeysType, 'burst_flexible'>,
        boolean
      >;
    };
  };
};

export type ServiceInternetAccess = ServiceBase & {
  type: 'internet_access';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
    point_a: Facility | Address | MarketOption | null;
    point_z: InternetPoint;
    bandwidth: ServiceBandwidth | null;
    sd_wan_compatible: boolean;
    advanced: {
      access_grade: Extract<
        FeatureKeysType,
        | 'access_grade_enterprise'
        | 'access_grade_business'
        | 'access_grade_consumer'
      > | null;
      access_method: Extract<
        FeatureKeysType,
        | 'fixed_line_fiber'
        | 'fixed_line_copper'
        | 'broadband'
        | 'fixed_wireless'
      > | null;
      advanced_features: Record<
        Extract<
          FeatureKeysType,
          'bgp_community_support' | 'ddos_filtering_scrubbing'
        >,
        boolean
      >;
      minimum_sla: ServiceLevelKeys | null;
    };
  };
};

export type ServiceWavelength = ServiceBase & {
  type: 'wavelength';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
    point_a: Facility | Address | MarketOption | null;
    point_z: Facility | Address | MarketOption | null;
    bandwidth: ServiceBandwidth | null;
    advanced: {
      phy: Extract<FeatureKeysType, 'lan_phy' | 'wan_phy'> | null;
      redundancy: Extract<FeatureKeysType, 'protected' | 'unprotected'> | null;
      minimum_sla: ServiceLevelKeys | null;
    };
  };
};

export type ServiceColocation = ServiceBase & {
  type: 'colocation';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
    point_a: Facility | Address | MarketOption | null;
    point_z: CoLocationPoint;
    requirements: { value: number; unit: string } | null;
    power: ServicePower | null;
    advanced: {
      space_required: Extract<FeatureKeysType, 'standard' | 'deep'> | null;
      additional_services: Record<
        Extract<
          FeatureKeysType,
          | '247_security'
          | 'remote_hands'
          | 'separate_cage'
          | 'dual_power_feed'
          | 'cross_connects'
        >,
        boolean
      >;
      advanced_features?: Record<
        Extract<
          FeatureKeysType,
          | '24_x_7_security'
          | 'remote_hands'
          | 'separate_cage'
          | 'dual_power_feed'
          | 'cross_connects'
        >,
        '1' | 1 | '0' | 0
      >;
    };
  };
};

export type ServiceMultiRequirements = {
  type: 'multi_service_requirements';
  id?: string;
  additional_info: string;
  documents: Document[];
  attributes: {
    external_service_name?: string;
    external_service_id?: string;
    requirements: {
      [ServiceTypes.ethernet]: boolean;
      [ServiceTypes.ip_transit]: boolean;
      [ServiceTypes.dark_fiber]: boolean;
      [ServiceTypes.cloud_connectivity]: boolean;
      [ServiceTypes.wavelength]: boolean;
      [ServiceTypes.colocation]: boolean;
      [ServiceTypes.sd_wan]: boolean;
      other: boolean;
    };
  };
  relationships?: {
    documents?: { data: Document[] };
  };
};

export type ServiceCloudServices = ServiceBase & {
  type: 'cloud_services';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
  };
};
export type ServiceSDWan = ServiceBase & {
  type: 'sd_wan';
  attributes: {
    documents: (string | Document)[];
    external_service_name?: string;
    external_service_id?: string;
  };
};

export type Services =
  | ServiceEthernet
  | ServiceIPTransit
  | ServiceDarkFiber
  | ServiceCloudConnectivity
  | ServiceInternetAccess
  | ServiceWavelength
  | ServiceColocation
  | ServiceCloudServices
  | ServiceSDWan
  | ServiceMPLS
  | ServiceMultiRequirements;

export type ListingService<T = Services> = {
  type: 'listing-service';
  id?: string;
  attributes: T;
  relationships?: {
    documents: { data: Document[] };
  };
};

export type ServiceRelationship = {
  type: 'listing-service';
  id: string;
};

export type ServicesPlaceAttributes = {
  point_a: PointA | null;
  point_z?: PointZ | null;
  serviceType: ServiceTypeKeys;
};

export interface FacilityHit {
  name: string;
  logo: string;
  operator: {
    name: string;
  };
  market: string;
  zone: string;
  objectID: string;
  address: {
    city: string;
    country: string;
    lat: string;
    line1: string | null;
    line2: string | null;
    lon: string;
    region: string;
    zipCode: string | null;
  };
}

export interface MarketOption extends PhysicalAddress {
  type: 'market';
  countryCode: string;
  flag: string;
  id: string;
  name: string;
  zone: string;
  marketType: MarketType;
  description: string;
  address?: string;
}
