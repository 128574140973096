import React, { Fragment, useState } from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import ServiceProvidersDialog from '../../components/ServiceProviders/ServiceProvidersDialog';
import { CreateListingFormState } from '../CreateListing';
import ServiceProviderInfo, {
  GatewayServiceProviderInfo,
} from '../../components/ServiceProviderInfoBlock';
import { useSelector } from 'react-redux';
import { selectAllMatchedServiceProviders } from '../../store/gateway-matched-providers';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';

const ProvidersSection: React.FC<{
  name: string;
}> = ({ name }) => {
  const [open, setOpen] = useState(false);
  const allMatchedProviders = useSelector(selectAllMatchedServiceProviders);

  return (
    <Box mt={3}>
      <Box pb={2}>
        <FormSpy subscription={{ pristine: true, values: true }}>
          {({ form, values }: FormSpyRenderProps<CreateListingFormState>) => {
            const {
              providers,
              provider_contacts,
              services,
              multi_requirements,
            } = values;
            const { regularProviders, matchedProviders } = providers
              ? providers.reduce<{
                  regularProviders: {
                    state: 'initial' | 'add' | 'edit' | 'delete';
                    value: ListingProviderResponse;
                  }[];
                  matchedProviders: {
                    state: 'initial' | 'add' | 'edit' | 'delete';
                    value: ListingProviderResponse;
                  }[];
                }>(
                  (result, entity) => {
                    const provider_id =
                      entity.value.relationships.provider.data.id;
                    const matched = allMatchedProviders.find(
                      (matched) => matched.id === provider_id
                    );
                    if (matched) {
                      result.matchedProviders.push(entity);
                    } else {
                      result.regularProviders.push(entity);
                    }

                    return result;
                  },
                  {
                    regularProviders: [],
                    matchedProviders: [],
                  }
                )
              : { regularProviders: [], matchedProviders: [] };
            const hasColocation = multi_requirements.some(
              (multi) =>
                multi.state !== 'delete' &&
                multi.value.service.attributes.attributes.requirements
                  .colocation
            );
            const chips = (
              <Grid container spacing={1}>
                {regularProviders.map((provider, index) => {
                  const contact = provider_contacts.find(
                    (contact) =>
                      contact.state !== 'delete' &&
                      contact.value.relationships.provider?.data?.id ===
                        provider.value.relationships.provider.data.id
                  );

                  if (provider.state === 'delete') {
                    return <Fragment key={index} />;
                  }

                  return (
                    <Grid key={index} item xs="auto">
                      <ServiceProviderInfo
                        variant="chip"
                        provider={provider.value.relationships.provider.data.id}
                        contact={
                          contact?.value.relationships?.['provider-contact']
                            ?.data.id
                        }
                        onDelete={() => {
                          const contactIndex = provider_contacts.findIndex(
                            (contact) =>
                              contact.value.relationships.provider?.data?.id ===
                              provider.value.relationships.provider.data.id
                          );

                          form.mutators.deleteResource('providers', index);

                          if (contactIndex > -1) {
                            form.mutators.deleteResource(
                              'provider_contacts',
                              provider_contacts.findIndex(
                                (contact) =>
                                  contact.value.relationships.provider?.data
                                    ?.id ===
                                  provider.value.relationships.provider.data.id
                              )
                            );
                          }
                        }}
                      />
                    </Grid>
                  );
                })}
                {matchedProviders.map((provider, index) => {
                  const contact = provider_contacts.find(
                    (contact) =>
                      contact.state !== 'delete' &&
                      contact.value.relationships.provider?.data?.id ===
                        provider.value.relationships.provider.data.id
                  );

                  if (provider.state === 'delete') {
                    return <Fragment key={index} />;
                  }

                  return (
                    <Grid key={index} item xs="auto">
                      <GatewayServiceProviderInfo
                        provider={provider.value.relationships.provider.data.id}
                        contact={
                          contact?.value.relationships?.['provider-contact']
                            ?.data.id
                        }
                        onDelete={() => {
                          const contactIndex = provider_contacts.findIndex(
                            (contact) =>
                              contact.value.relationships.provider?.data?.id ===
                              provider.value.relationships.provider.data.id
                          );

                          form.mutators.deleteResource('providers', index);

                          if (contactIndex > -1) {
                            form.mutators.deleteResource(
                              'provider_contacts',
                              provider_contacts.findIndex(
                                (contact) =>
                                  contact.value.relationships.provider?.data
                                    ?.id ===
                                  provider.value.relationships.provider.data.id
                              )
                            );
                          }
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
            return (
              <>
                {chips}
                <ServiceProvidersDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  selectedServices={services
                    .filter((service) => service.state !== 'delete')
                    .map((service) => service.value?.service.attributes.type)}
                  selectedProducts={services
                    .filter((service) => service.state !== 'delete')
                    .map((service) => service.value?.service.attributes)}
                  isColoc={hasColocation}
                >
                  {chips}
                </ServiceProvidersDialog>
              </>
            );
          }}
        </FormSpy>
      </Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Service Providers
      </Button>
    </Box>
  );
};

export default ProvidersSection;
