import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { parseISO, isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ListingType from '../ListingType';
import ListingExpiry from '../ListingExpiry';
import ActionMenu from '../ActionMenu';
import EditListingConfirmation from '../EditListingConfirmation';
import EndListing from '../EndListing';
import CompleteListing from '../CompleteListing';
import AssignOpportunity from '../AssignOpportunity';
import { useAppMessenger } from '../../store/messenger';
import { useAuth } from '../../hooks/useAuth';
import { isListingOwner, listingHasEnded } from '../../utilities/listings';
import { isAccountManager } from '../../utilities/users';
import { ButtonGroupOption } from '../ButtonGroupToggle';
import ButtonGroupToggle from '../ButtonGroupToggle';
import { UserResponse } from '../../types/account-service/users';
import { ListingInfoContext } from '../Listings/ListingInfo';
import * as ListingInfo from '../Listings/ListingInfo';
import { getFullReadable } from '../../utilities/time';
import ExtendListing from '../ExtendListing';
import { selectAllListingProvidersFromRelationship } from '../../store/listing-providers/selectors';
import { useUpdateListingByIDMutation } from '../../services/api/marketplace-service/listings';
import { BidResponse } from '../../types/marketplace-service/bid';
import { useNavigate } from '@reach/router';
import { useActingUser } from '../../hooks/useActingUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      padding: theme.spacing(1, 2),
      background: '#f1f3fa',
      borderRadius: '8px',
    },
    icon: {
      display: 'block',
    },
    section: {
      margin: theme.spacing(2, 0, 4),
    },
    dashboard: {
      margin: theme.spacing(4, 0, 0, 0),
    },
    extend: {
      boxShadow: theme.shadows[4],
      border: '1px solid transparent',
      borderRadius: '8px',
      padding: theme.spacing(1, 2),
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);
const ListingDetail: React.FC<{
  leftNav: ReactNode;
  middleNav: ReactNode;
}> = ({ children, leftNav, middleNav }) => {
  const classes = useStyles();
  const messenger = useAppMessenger();
  let navigate = useNavigate();
  const { isActing } = useActingUser();
  const { listing, bids } = useContext(ListingInfoContext);
  const { submitted, unactioned } = bids
    ? bids.reduce<{
        submitted: BidResponse[];
        unactioned: BidResponse[];
      }>(
        (result, entity) => {
          if (entity.attributes.state === 'submitted') {
            result.submitted.push(entity);
          }

          if (
            !['interested', 'uninterested'].includes(entity.attributes.state)
          ) {
            result.unactioned.push(entity);
          }

          return result;
        },
        {
          submitted: [],
          unactioned: [],
        }
      )
    : { submitted: [], unactioned: [] };
  const [editModal, setEditModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [endModal, setEndModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const { user } = useAuth();
  const [activeView, setActiveView] = useState('requirements');
  const allProviders = useSelector(
    selectAllListingProvidersFromRelationship(listing)
  );
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  //Date limit of 01st January 2021 set to not show view counts before this date.
  const dateLimit = new Date(2021, 0, 1, 0, 0, 0, 0);
  const invitedProviders = useMemo(
    () =>
      allProviders.filter(
        (provider) => provider.attributes.invited_at !== null
      ),
    [allProviders]
  );

  const intendProviders = useMemo(
    () =>
      allProviders.filter((provider) => provider.attributes.state === 'intend'),
    [allProviders]
  );

  const declinedProviders = useMemo(
    () =>
      allProviders.filter(
        (provider) =>
          provider.attributes.state === 'decline' &&
          provider.attributes.declined_at !== null
      ),
    [allProviders]
  );

  const [updateListingByID] = useUpdateListingByIDMutation();
  const handleAssignListing = useCallback(
    async (user: UserResponse) => {
      if (!listing) return;
      return await updateListingByID({
        id: listing.id,
        payload: {
          id: listing.id,
          type: 'listing',
          attributes: {
            user_id: user.id,
            company_id: user.relationships?.companyAccount.data?.id,
            contact: {
              full_name: `${user.attributes.firstName} ${user.attributes.lastName}`,
              email: user.attributes.email!,
              phone: user.attributes.phone,
            },
          },
        },
      })
        .unwrap()
        .then(() => {
          setAssignModal(false);
          messenger.addMessage('info', 'You have assigned a listing');
        });
    },
    [updateListingByID, listing, messenger]
  );

  const handleExtendListing = useCallback(
    async (end: string) => {
      if (!listing) return;
      return await updateListingByID({
        id: listing.id,
        payload: {
          id: listing.id,
          type: 'listing',
          attributes: {
            state: 'published',
            end: end,
            user_id: user?.id,
            company_id: user?.company_account_id,
          },
        },
      })
        .unwrap()
        .then(() => {
          messenger.addMessage(
            'info',
            `This listing has been extended until ${getFullReadable(
              parseISO(end)
            )}`
          );
        })
        .catch(() => {
          messenger.addMessage(
            'error',
            'There was a problem updating this listing'
          );
        });
    },
    [updateListingByID, listing, user, messenger]
  );
  if (!listing) {
    return null;
  }

  //Check if viewcount is zero or start Date before older
  const viewCountCheck =
    isAfter(new Date(listing.attributes.start), dateLimit) &&
    listing.attributes.view_count !== 0;

  const userIsListingOwner = isListingOwner(
    listing.attributes.user_id,
    user?.id
  );
  const userIsAccountManager = isAccountManager(user);
  const ended = listingHasEnded(
    listing.attributes.state,
    parseISO(listing.attributes.end)
  );
  const userCanAssign =
    user?.csAdmin || userIsAccountManager || userIsListingOwner;
  const userCanEdit =
    (user?.csAdmin || userIsAccountManager || userIsListingOwner) && !ended;
  const userCanComplete =
    (user?.csAdmin || userIsAccountManager || userIsListingOwner) &&
    listing.attributes.state === 'ended' &&
    submitted.length + unactioned.length === 0;
  const userCanCopy = !!user?.csAdmin || !!isActing;

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          {leftNav}
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          {middleNav}
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          {(!userCanCopy && !userCanComplete) ||
            (ended && (
              <ActionMenu>
                {userCanCopy && (
                  <ActionMenu.Item
                    divider
                    onClick={() =>
                      navigate?.(`/listing/${listing.attributes.short_id}/copy`)
                    }
                  >
                    Duplicate Listing
                  </ActionMenu.Item>
                )}
                {userCanComplete && (
                  <ActionMenu.Item
                    divider
                    onClick={() => setCompleteModal(true)}
                  >
                    Complete Listing
                  </ActionMenu.Item>
                )}
              </ActionMenu>
            ))}

          {(!userCanAssign && !userCanCopy && !userCanEdit) ||
            (!ended && (
              <>
                <ActionMenu>
                  {userCanAssign && (
                    <ActionMenu.Item
                      divider
                      onClick={() => setAssignModal(true)}
                    >
                      Assign User
                    </ActionMenu.Item>
                  )}
                  {userCanCopy && (
                    <ActionMenu.Item
                      divider
                      onClick={() =>
                        navigate?.(
                          `/listing/${listing.attributes.short_id}/copy`
                        )
                      }
                    >
                      Duplicate Listing
                    </ActionMenu.Item>
                  )}
                  {userCanEdit && [
                    <ActionMenu.Item
                      key="edit"
                      divider
                      onClick={() => setEditModal(true)}
                    >
                      Edit Listing
                    </ActionMenu.Item>,

                    <ActionMenu.Item
                      key="end"
                      variant="danger"
                      divider
                      onClick={() => setEndModal(true)}
                    >
                      End Listing
                    </ActionMenu.Item>,
                  ]}
                </ActionMenu>
                <EditListingConfirmation
                  listingShortId={listing.attributes.short_id!}
                  open={editModal}
                  onClose={() => setEditModal(false)}
                />
                <AssignOpportunity
                  open={assignModal}
                  onClose={() => setAssignModal(false)}
                  assignListingOwner={true}
                  onAssign={handleAssignListing}
                />
                <EndListing
                  listingId={listing.id!}
                  userId={listing.attributes.user_id!}
                  companyId={listing.attributes.company_id!}
                  open={endModal}
                  onClose={() => setEndModal(false)}
                />
              </>
            ))}
          {ended && (
            <CompleteListing
              listingId={listing.id!}
              userId={listing.attributes.user_id!}
              companyId={listing.attributes.company_id!}
              open={completeModal}
              onClose={() => setCompleteModal(false)}
            />
          )}
        </Grid>
      </Grid>
      {userIsListingOwner && (
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.message}
          >
            <Grid item xs="auto">
              <InfoIcon className={classes.icon} fontSize="small" />
            </Grid>
            <Grid item xs>
              <Typography component="div" variant="body2" color="textPrimary">
                {listing.attributes.state === 'approved'
                  ? `This listing is scheduled to start on the ${getFullReadable(
                      parseISO(listing.attributes.start)
                    )}`
                  : 'You are the owner of this listing'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {(isAfter(new Date(), new Date(listing.attributes.end)) ||
        listing.attributes.state === 'ended') &&
        listing.attributes.approval_actioned_at !== null &&
        listing.attributes.state !== 'completed' &&
        userIsListingOwner && (
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              className={classes.extend}
            >
              <ExtendListing
                onExtend={(end: string) => handleExtendListing(end)}
              />
            </Grid>
          </Grid>
        )}

      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <ListingType type={listing.attributes.visibility} />
          </Grid>
          <Grid item xs="auto">
            <ListingExpiry
              end={parseISO(listing.attributes.end)}
              state={listing.attributes.state}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={mobile ? 'h4' : 'h3'}>
              {listing.attributes.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {mobile && (
        <Box mt={2} mx={-2}>
          <Divider />
        </Box>
      )}
      <Grid container item xs="auto">
        <ButtonGroupToggle
          name="detail-view"
          value={activeView}
          onChange={(e, value) => setActiveView(value)}
        >
          <ButtonGroupOption value="requirements">
            Requirements
          </ButtonGroupOption>
          <ButtonGroupOption value="performance">Performance</ButtonGroupOption>
        </ButtonGroupToggle>
      </Grid>
      {activeView === 'requirements' && (
        <Grid item xs>
          <ListingInfo.Content />
          {children && (
            <Grid container item xs={12} className={classes.section}>
              {children}
            </Grid>
          )}
        </Grid>
      )}
      {activeView === 'performance' && (
        <Grid container spacing={4} className={classes.dashboard}>
          <Grid item xs={4}>
            <Typography variant="h1" component="div" align="center">
              {invitedProviders.length}
            </Typography>
            <Typography variant="h6" component="div" align="center">
              Invited Providers
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h1" component="div" align="center">
              {submitted.length}
            </Typography>
            <Typography variant="h6" component="div" align="center">
              Bids Received
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h1" component="div" align="center">
              {intendProviders.length}
            </Typography>
            <Typography variant="h6" component="div" align="center">
              Intend to Bid
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h1" component="div" align="center">
              {declinedProviders.length}
            </Typography>
            <Typography variant="h6" component="div" align="center">
              No Bid
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h1" component="div" align="center">
              {viewCountCheck ? listing.attributes.view_count : '-'}
            </Typography>
            <Typography variant="h6" component="div" align="center">
              Views
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ListingDetail;
