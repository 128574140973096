import React, { StrictMode, useEffect } from 'react';
import { Router, Redirect, navigate, RouteComponentProps } from '@reach/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Provider } from 'react-redux';
import { Integrations } from '@sentry/tracing';
import SentryProvider, { Profiler as SentryProfiler } from 'sentry-react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Auth0Provider, useAuth0, AppState } from '@auth0/auth0-react';
import { AppMessengerProvider } from './store/messenger';
import store from './store';
import theme from './theme';
import { ROUTES } from './routes';
import MyMarketplace from './pages/my-marketplace';
// buying
import BuyingPage, {
  BuyingCompleted,
  BuyingCompletedContainer,
  BuyingPending,
  BuyingPendingContainer,
  CreateNewListing,
  RequestQuote,
  EditListing,
  CopyListing,
  ViewListing,
} from './pages/my-marketplace/buying';
import * as BuyingListings from './pages/my-marketplace/buying/listings';
//browse
import BrowsePage from './pages/my-marketplace/browse';
// selling
import SellingPage, {
  SellingOpportunities,
  SellingPending,
  SellingCompleted,
  SellingQualified,
  SellingClosedLost,
  SellingNoBidNoResponse,
  SellingLeads,
  ViewBid,
  SellingContainer,
} from './pages/my-marketplace/selling';
// listing success
import ListingSuccess from './pages/my-marketplace/success';
import RequestQuoteSuccess from './pages/my-marketplace/request-quote-success';
// general
import NotFound from './pages/404-not-found';
import Contacted from './pages/contacted';
import NotContacted from './pages/not-contacted';
import AppMessenger from './containers/AppMessenger';
import FullScreenLoader from './components/FullScreenLoader';
import { AppErrorBoundary } from './components/ErrorBoundary';
import { FingerprintProvider } from './components/FingerprintContext';
import { AppStateProvider } from './contexts/AppState';
import { axiosInterceptors } from './services/axios';
import { getAccessTokenHandler, setAccessTokenHandler } from './services/auth';
import DirectInvite from './pages/direct-invite';
import AssignInvite from './pages/assign-invite';
import SignOut from './pages/signout';
import GlobalStyles from './styles/GlobalStyles';
import ListingReviewReminder from './components/ListingReviewReminder';
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from './api';

const BUILDKITE_AGENT_NAME = process.env.BUILDKITE_AGENT_NAME;
const BUILDKITE_PIPELINE_SLUG = process.env.BUILDKITE_PIPELINE_SLUG;
const BUILDKITE_BUILD_NUMBER = process.env.BUILDKITE_BUILD_NUMBER;

const release = !BUILDKITE_AGENT_NAME
  ? undefined
  : `${BUILDKITE_PIPELINE_SLUG}_${SENTRY_ENVIRONMENT}_${BUILDKITE_BUILD_NUMBER}`;

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

axiosInterceptors(store);

const PublicHandler: React.FC<RouteComponentProps> = ({ children }) => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isLoading && !getAccessTokenHandler()) {
      setAccessTokenHandler(getAccessTokenSilently);
    }
  }, [isLoading, getAccessTokenSilently]);

  if (isLoading || error) {
    return <FullScreenLoader />;
  }

  return <> {children} </>;
};

const AuthHandler: React.FC<RouteComponentProps> = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (isLoading && !getAccessTokenHandler()) {
      setAccessTokenHandler(getAccessTokenSilently);
    }
  }, [isLoading, getAccessTokenSilently]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated || isLoading || error || !getAccessTokenHandler()) {
    return <FullScreenLoader />;
  }

  return <AppStateProvider>{children}</AppStateProvider>;
};

const onRedirectCallback = (appState: AppState = {}) => {
  /*
   ** Navigate user back to where they wanted to go to before authenticating.
   ** Only navigate to the user's preferred destination if the pathname is "/"
   ** Otherwise the return_url has probably been set and intends to send the user somewhere else e.g to signup if the user has no company
   **
   ** Concept is from official auth0-react example: https://github.com/auth0-samples/auth0-react-samples/blob/master/Sample-01/src/index.js
   */

  if (window.location.pathname === ROUTES.root) {
    navigate(appState.returnTo ?? window.location.pathname, {
      replace: true,
    });
  }
};

const App: React.FC = () => {
  return (
    <StrictMode>
      <SentryProvider
        dsn={SENTRY_DSN}
        environment={SENTRY_ENVIRONMENT}
        enabled={SENTRY_ENVIRONMENT !== 'development'}
        tracesSampleRate={SENTRY_ENVIRONMENT === 'production' ? 0.01 : 0}
        integrations={[new Integrations.BrowserTracing()]}
        release={release}
      >
        <SentryProfiler>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH_URL!}
            clientId={process.env.REACT_APP_AUTH_CLIENT_ID!}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="memory"
            authorizationParams={{
              redirect_uri: window.location.origin,
              scope: 'openid profile email',
            }}
          >
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                  <AppMessengerProvider hideIconVariant>
                    <FingerprintProvider>
                      <>
                        <GlobalStyles />
                        <CssBaseline />
                        <AppErrorBoundary>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <AppMessenger />
                            <ListingReviewReminder />
                            <Router>
                              <SignOut path={ROUTES.signout} />
                              <PublicHandler
                                path={`${ROUTES.directInvite}/:code`}
                              >
                                <DirectInvite path="/" />
                                <NotFound default />
                              </PublicHandler>
                              <PublicHandler
                                path={`${ROUTES.assignInvite}/:listingId/:listingProviderId`}
                              >
                                <AssignInvite path="/" />
                                <NotFound default />
                              </PublicHandler>

                              <PublicHandler path={`/leads/:leadId`}>
                                <Contacted path="/contacted" />
                                <NotContacted path="/not-contacted" />
                              </PublicHandler>

                              <AuthHandler default>
                                {/* redirects */}
                                <Redirect
                                  from={ROUTES.oldCreateListing}
                                  to={ROUTES.browseListings}
                                  noThrow
                                />
                                <Redirect
                                  from={ROUTES.browseListings}
                                  to={ROUTES.root}
                                  replace
                                  noThrow
                                />
                                <BrowsePage path={ROUTES.root} />
                                <CreateNewListing path={ROUTES.createListing} />
                                <RequestQuote path={ROUTES.requestQuote} />
                                <EditListing path={ROUTES.editListing} />
                                <CopyListing path={ROUTES.copyListing} />
                                <ViewBid path={ROUTES.viewBid} />
                                <ViewListing path={ROUTES.viewListing} />
                                <ListingSuccess path={ROUTES.listingSuccess} />
                                <RequestQuoteSuccess
                                  path={ROUTES.requestQuoteSuccess}
                                />
                                <MyMarketplace path={ROUTES.myMarketplace.root}>
                                  <BuyingPage
                                    path={ROUTES.myMarketplace.buying}
                                  >
                                    <BuyingListings.Container
                                      path={ROUTES.myMarketplace.listings}
                                      title="Source"
                                    >
                                      <BuyingListings.Content path="/" />
                                      <BuyingListings.Content path="/:listingShortId" />
                                    </BuyingListings.Container>
                                    <BuyingPendingContainer
                                      path={ROUTES.myMarketplace.pending}
                                    >
                                      <BuyingPending path="/" />
                                      <BuyingPending path="/:listingShortId" />
                                    </BuyingPendingContainer>
                                    <BuyingCompletedContainer
                                      path={ROUTES.myMarketplace.completed}
                                    >
                                      <BuyingCompleted path="/" />
                                      <BuyingCompleted path="/:listingShortId" />
                                    </BuyingCompletedContainer>
                                  </BuyingPage>
                                  <SellingPage
                                    path={ROUTES.myMarketplace.selling}
                                  >
                                    <SellingContainer
                                      path={ROUTES.myMarketplace.opportunities}
                                    >
                                      <SellingOpportunities path="/" />
                                      <SellingOpportunities path="/:listingShortId" />
                                    </SellingContainer>
                                    <SellingContainer
                                      path={ROUTES.myMarketplace.pending}
                                    >
                                      <SellingPending path="/" />
                                      <SellingPending path="/:listingShortId" />
                                    </SellingContainer>
                                    <SellingContainer
                                      path={ROUTES.myMarketplace.completed}
                                    >
                                      <SellingCompleted path="/" />
                                      <SellingCompleted path="/:listingShortId" />
                                    </SellingContainer>
                                    <SellingContainer
                                      path={
                                        ROUTES.myMarketplace
                                          .qualifiedOpportunities
                                      }
                                    >
                                      <SellingQualified path="/" />
                                      <SellingQualified path="/:listingShortId" />
                                    </SellingContainer>
                                    <SellingContainer
                                      path={ROUTES.myMarketplace.closedLost}
                                    >
                                      <SellingClosedLost path="/" />
                                      <SellingClosedLost path="/:listingShortId" />
                                    </SellingContainer>
                                    <SellingContainer
                                      path={
                                        ROUTES.myMarketplace.noBidNoResponse
                                      }
                                    >
                                      <SellingNoBidNoResponse path="/" />
                                      <SellingNoBidNoResponse path="/:listingShortId" />
                                    </SellingContainer>
                                    <SellingContainer
                                      path={ROUTES.myMarketplace.leads}
                                    >
                                      <SellingLeads path="/" />
                                      <SellingLeads path="/:leadShortId" />
                                    </SellingContainer>
                                  </SellingPage>
                                  <Redirect
                                    from="*"
                                    to={ROUTES.myMarketplace.buying}
                                    replace
                                    noThrow
                                  />
                                </MyMarketplace>
                                <NotFound default />
                              </AuthHandler>
                            </Router>
                          </MuiPickersUtilsProvider>
                        </AppErrorBoundary>
                      </>
                    </FingerprintProvider>
                  </AppMessengerProvider>
                </QueryClientProvider>
              </ThemeProvider>
            </Provider>
          </Auth0Provider>
        </SentryProfiler>
      </SentryProvider>
    </StrictMode>
  );
};

export default App;
