import { combineReducers } from 'redux';
import listingsReducer from './listings';
import opportunitiesReducer from './opportunities';
import bidsReducer from './bids';
import bidServicesReducer from './bids/services';
import bidPricingOptionsReducer from './bids/pricing-options';
import documentsReducer from './documents';
import marketsReducer from './markets';
import servicesReducer from './services';
import providerContactsReducer from './provider-contacts';
import gatewayProvidersReducer from './gateway-providers';
import gatewayProductProvidersReducer from './gateway-product-providers';
import gatewayMatchedProvidersReducer from './gateway-matched-providers';
import listingProviderContactsReducer from './listing-provider-contacts';
import listingProvidersReducer from './listing-providers';
import marketplaceMatchReducer from './marketplace-match';
import messagesReducer from './messenger';
import accountReducer from './account';
import actingReducer from './acting';
import usersReducer from './users';
import { directoryServiceAPI } from '../services/api/directory-service/base';
import { accountServiceAPI } from '../services/api/account-service/base';
import { accountServiceSubscriptionAPI } from '../services/api/account-service/base.subscription';
import { actingOnBehalfAPI } from '../services/api/acting-on-behalf/base';
import { marketplaceServiceAPI } from '../services/api/marketplace-service/base';
import { marketplaceServiceGQLAPI } from '../services/api/marketplace-service/gql/base';

const rootReducer = combineReducers({
  [marketplaceServiceGQLAPI.reducerPath]: marketplaceServiceGQLAPI.reducer,
  [marketplaceServiceAPI.reducerPath]: marketplaceServiceAPI.reducer,
  [directoryServiceAPI.reducerPath]: directoryServiceAPI.reducer,
  [accountServiceAPI.reducerPath]: accountServiceAPI.reducer,
  [accountServiceSubscriptionAPI.reducerPath]:
    accountServiceSubscriptionAPI.reducer,
  [actingOnBehalfAPI.reducerPath]: actingOnBehalfAPI.reducer,
  listings: listingsReducer,
  opportunities: opportunitiesReducer,
  bids: bidsReducer,
  bidServices: bidServicesReducer,
  bidPricingOptions: bidPricingOptionsReducer,
  documents: documentsReducer,
  markets: marketsReducer,
  services: servicesReducer,
  providerContacts: providerContactsReducer,
  listingProviderContacts: listingProviderContactsReducer,
  listingProviders: listingProvidersReducer,
  gatewayProviders: gatewayProvidersReducer,
  gatewayProductProviders: gatewayProductProvidersReducer,
  gatewayMatchedProviders: gatewayMatchedProvidersReducer,
  marketplaceMatch: marketplaceMatchReducer,
  messages: messagesReducer,
  account: accountReducer,
  acting: actingReducer,
  users: usersReducer,
});

export default rootReducer;
