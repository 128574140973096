import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceById, selectServiceById } from '../../store/services';
import { ListingService } from '../../types/services';
import { useCallback, useEffect } from 'react';
import { AppDispatch } from '../../store/types';

const ServiceState = ({
  id,
  listingId,
  children,
}: {
  id: string;
  listingId: string;
  children: (service: ListingService) => JSX.Element;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const service = useSelector(selectServiceById(id));
  const handleSelectServiceById = useCallback(() => {
    return dispatch(
      fetchServiceById({
        listing: listingId,
        service: id,
      })
    );
  }, [dispatch, id, listingId]);

  useEffect(() => {
    if (!service?.attributes.attributes) {
      const action = handleSelectServiceById();
      return () => action.abort();
    }
  }, [handleSelectServiceById, service]);

  return service ? children(service) : null;
};

export default ServiceState;
