import { ReactElement } from 'react';
import tw, { css } from 'twin.macro';
import { PrimaryButton, SecondaryButton } from '../Cloudscene/Button';
import { Caption } from '../Cloudscene/Typography';
import { Link as RouterLink } from '@reach/router';
import { ROUTES } from '../../routes';

const Header = (): ReactElement => {
  return (
    <div css={tw`grid grid-flow-row`}>
      <div
        css={[
          tw`overflow-x-auto p-1 -m-1 flex gap-2 flex-nowrap`,
          css`
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
          `,
        ]}
      >
        <div>
          <PrimaryButton
            /* @ts-ignore */
            as={RouterLink}
            to={ROUTES.createListing}
            css={tw`text-sm py-2 block whitespace-nowrap`}
          >
            Create a Listing
          </PrimaryButton>
        </div>
        <div>
          <SecondaryButton
            /* @ts-ignore */
            as={RouterLink}
            variant="ghost"
            to={`/`}
            css={tw`text-sm py-2 block whitespace-nowrap`}
          >
            <Caption>Browse Listings</Caption>
          </SecondaryButton>
        </div>
        <div>
          <SecondaryButton
            /* @ts-ignore */
            as={RouterLink}
            variant="ghost"
            to={ROUTES.buyingListings}
            css={tw`text-sm py-2 block whitespace-nowrap`}
          >
            <Caption>My Marketplace</Caption>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
