import React, { createElement, Fragment } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Paper, Typography, Avatar, Tooltip } from '@material-ui/core';
import { parseISO } from 'date-fns';
import ListingType from '../ListingType';
import ListingExpiry from '../ListingExpiry';
import InitialsAvatar from '../InitialsAvatar';
import { OpportunityOwner } from '../OpportunityOwner';
import Pill from '../Pill';
import { AssignedNewUser } from '../../types/provider';
import { ListingResponse } from '../../types/marketplace-service/listing';

export interface ListItemProps extends ListingResponse {
  onClick?: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
  userId?: string | null;
  userName?: string | null;
  active?: boolean;
  invited?: boolean;
  status?: React.ReactNode;
  opportunity?: boolean;
  newUser?: AssignedNewUser;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '95%',
    },
    paper: {
      height: 56,
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      padding: theme.spacing(0, 3),
      transition: 'box-shadow 100ms ease',
      position: 'relative',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      '&:hover': {
        borderRadius: 8,
        borderColor: theme.palette.grey[100],
        boxShadow: `0 0 24px -12px ${theme.palette.grey[400]}`,
        zIndex: 1,
      },
    },
    active: {
      borderRadius: 8,
      borderColor: theme.palette.grey[100],
      boxShadow: `0 0 24px -12px ${theme.palette.grey[400]}`,
      zIndex: 1,
    },
    new_user: {
      height: 28,
      width: 28,
      fontSize: 14,
    },
  })
);

const ListItem: React.FunctionComponent<ListItemProps> = ({
  id,
  attributes,
  userId = null,
  newUser = null,
  userName = null,
  onClick = () => false,
  active = false,
  invited,
  status,
  opportunity = false,
}) => {
  const classes = useStyles();

  return (
    <div onClick={(event) => onClick(event, id)}>
      <Paper
        variant="outlined"
        className={clsx(classes.paper, {
          [classes.active]: active,
        })}
      >
        <Grid container spacing={2} wrap="nowrap">
          <Grid container item xs={4} alignItems="center" wrap="nowrap">
            {invited && <Pill label="Invited" variant="outlined" />}
            <Typography
              className={classes.title}
              component="div"
              color="textPrimary"
              title={attributes.title}
            >
              {attributes.title}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            zeroMinWidth
          >
            <Grid item xs={3}>
              {opportunity ? (
                <Typography noWrap> {attributes.company} </Typography>
              ) : (
                <ListingType type={attributes.visibility} />
              )}
            </Grid>
            <Grid item xs={2}>
              {createElement(
                opportunity ? OpportunityOwner : Fragment,
                undefined,
                <>
                  {userId && (
                    <InitialsAvatar
                      usersName={userName}
                      userId={userId}
                      small
                    />
                  )}
                  {newUser && (
                    <Tooltip
                      title={`${newUser.first_name} ${newUser.last_name} (Pending)`}
                    >
                      <Avatar
                        className={classes.new_user}
                        src="/broken-image.jpg"
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              <ListingExpiry
                end={parseISO(attributes.end)}
                state={attributes.state}
                hidePrefix
              />
            </Grid>
            <Grid item xs={3}>
              {status}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ListItem;
