import { format, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { useUserQuery } from '../../hooks/useUserQuery';
import InitialsAvatar from '../InitialsAvatar';
import LeadStatus from './LeadStatus';
import { LeadListProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      flexDirection: 'column',
    },
    paper: {
      height: 56,
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      padding: theme.spacing(0, 3),
      transition: 'box-shadow 100ms ease',
      position: 'relative',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      '&:hover': {
        borderRadius: 8,
        borderColor: theme.palette.grey[100],
        boxShadow: `0 0 24px -12px ${theme.palette.grey[400]}`,
        zIndex: 1,
      },
    },
    active: {
      borderRadius: 8,
      borderColor: theme.palette.grey[100],
      boxShadow: `0 0 24px -12px ${theme.palette.grey[400]}`,
      zIndex: 1,
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '95%',
    },
    header: {
      fontSize: 14,
      color: theme.palette.grey[700],
      fontWeight: 'bold',
    },
    content: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  })
);

const LeadListRow: FC<LeadListProps> = ({
  lead,
  active,
  onClick = () => false,
}) => {
  const classes = useStyles();
  const { user } = useUserQuery(lead.external_assigned_to_user_id);
  const userName = `${user?.attributes.firstName} ${user?.attributes.lastName}`;

  const services = useMemo(() => {
    if (lead.services.length === 0) {
      return 'Other Services';
    }
    return lead.services
      .map((service) => {
        return service.external_service_name;
      })
      .join(', ');
  }, [lead.services]);

  return (
    <div
      className={classes.root}
      onClick={(event) => onClick(event, lead.short_id)}
    >
      <Paper
        variant="outlined"
        className={clsx(classes.paper, {
          [classes.active]: active,
        })}
      >
        <Grid container spacing={2} wrap="nowrap">
          <Grid container item xs={4} alignItems="center" wrap="nowrap">
            <Typography
              className={classes.title}
              component="div"
              color="textPrimary"
            >
              {services} in {lead.locations.length}{' '}
              {lead.locations.length === 1 ? 'location' : 'locations'}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                {lead.company_name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div" className={classes.header}>
                {lead.external_assigned_to_user_id && (
                  <InitialsAvatar
                    usersName={userName}
                    userId={lead.external_assigned_to_user_id}
                    small
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                {format(parseISO(lead.created_at), 'd MMM yyyy')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                <LeadStatus state={lead.state} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LeadListRow;
