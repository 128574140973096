import { VisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

interface FingerprintHandler {
  (): Promise<VisitorData<true> | void> | null;
}

interface GetFingerprintHandlerCallback {
  (): FingerprintHandler | null;
}

interface SetFingerprintHandlerCallback {
  (fn: FingerprintHandler): void;
}

let getFingerprint: FingerprintHandler | null = null;

export const getFingerprintHandler: GetFingerprintHandlerCallback = () =>
  getFingerprint;
export const setFingerprintHandler: SetFingerprintHandlerCallback = (
  fn: FingerprintHandler
) => (getFingerprint = fn);
