import { navigate } from '@reach/router';
import copy from 'copy-to-clipboard';
import { ROUTES } from '../../routes';
import { useAppMessenger } from '../../store/messenger';
import { ListingOpportunityActions } from './ListingOpportunityManager';
import { AllowedActions } from './useListingOpportunityStatus';
import { ListingResponse } from '../../types/marketplace-service/listing';

export const useActions = (
  allowedActions: AllowedActions[],
  listing: ListingResponse,
  navigateToOpportunity: boolean,
  onFocusBidForm?: () => void
): ListingOpportunityActions => {
  const messenger = useAppMessenger();
  const actions: ListingOpportunityActions = {};
  if (allowedActions.includes('copy_listing')) {
    actions.onCopyListing = () => {
      copy(
        `${window.location.origin}/listing/${listing.attributes.short_id}/view`
      );
      messenger.addMessage('info', 'Link copied to clipboard!');
    };
  }
  if (
    allowedActions.includes('contact') &&
    listing.attributes.external_contact_email
  ) {
    actions.onContact = () => {
      window.open(
        `mailto:${listing.attributes.external_contact_email}`,
        '_blank'
      );
    };
  }
  if (allowedActions.includes('view_info')) {
    actions.onMoreInfo = () => {
      window.open(
        listing.attributes.external_link
          ? listing.attributes.external_link
          : `https://cloudscene.com`,
        '_blank'
      );
    };
  }
  if (allowedActions.includes('view_listing')) {
    actions.onViewListing = () =>
      navigate(`/listing/${listing.attributes.short_id}/view`);
  }
  if (allowedActions.includes('view_opportunity')) {
    // user is coming from outside of my opportunities
    if (navigateToOpportunity) {
      actions.onViewOpportunity = () => {
        navigate(
          `${ROUTES.sellingOpportunities}/${listing.attributes.short_id}`,
          { state: { focusForm: true } }
        );
      };
    } else {
      actions.onViewOpportunity = () => {
        onFocusBidForm && onFocusBidForm();
      };
    }
  }
  return actions;
};
