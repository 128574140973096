import { parseISO } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';
import { Bid } from '../../types/bid';
import { ListingResponse } from '../../types/marketplace-service/listing';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';
import { ProviderContact } from '../../types/provider-contact';
import { listingHasEnded } from '../../utilities/listings';
import { isAccountManager } from '../../utilities/users';

export type ListingOpportunityStatus =
  | 'SCHEDULED'
  | 'DECLINED'
  | 'ASSIGNED_CONTACT'
  | 'INVITED'
  | 'SHORTLISTED'
  | 'INTENDED_TO_BID'
  | 'BID'
  | 'BID_UNSUCCESSFUL'
  | 'BID_SUCCESSFUL'
  | 'USER_OWNS_LISTING'
  | 'COMPANY_CREATED_LISTING'
  | 'LISTING_EXTERNAL'
  | 'POSSIBLE_OPPORTUNITY'
  | 'INVITED_PROVIDER_CONTACT'
  | 'MISSED_DEADLINE'
  | 'OPPORTUNITY_WAITING'
  | 'ENDED'
  | 'INTERESTED'
  | 'INTENDED_UNSUBMITTED'
  | 'NO_RESPONSE'
  | 'UNINTERESTED';

export type AllowedActions =
  | 'intend'
  | 'decline'
  | 'assign'
  | 'contact'
  | 'view_info'
  | 'view_listing'
  | 'edit_listing'
  | 'copy_listing'
  | 'view_opportunity';

export const useListingOpportunityStatus = (
  listing: ListingResponse,
  provider?: ListingProviderResponse,
  bid?: Bid,
  contact?: ProviderContact
): {
  listingOpportunityStatus: ListingOpportunityStatus;
  allowedActions: AllowedActions[];
} => {
  const { user } = useAuth();
  const isEnded = listingHasEnded(
    listing?.attributes.state,
    parseISO(listing?.attributes.end)
  );

  const userIsOwner =
    user?.id === provider?.attributes.external_assigned_to_user_id;
  const userIsManager = isAccountManager(user);
  const assignedNewUser = provider?.attributes.assigned_to_contact;

  if (listing.attributes.state === 'approved') {
    let allowedActions: AllowedActions[] = [];
    if (listing.attributes.user_id === user?.id) {
      allowedActions = ['view_listing', 'copy_listing'];
      if (!isEnded) {
        allowedActions.push('edit_listing');
      }
    }
    return {
      listingOpportunityStatus: 'SCHEDULED',
      allowedActions,
    };
  }

  // USER CREATED LISTING
  if (listing.attributes.user_id === user?.id) {
    const allowedActions: AllowedActions[] = ['view_listing', 'copy_listing'];
    if (!isEnded) {
      allowedActions.push('edit_listing');
    }
    return {
      listingOpportunityStatus: 'USER_OWNS_LISTING',
      allowedActions,
    };
  }

  // USER's company created listing
  if (
    listing.attributes.company_id &&
    user?.company_account_id === listing.attributes.company_id
  ) {
    const allowedActions: AllowedActions[] = ['copy_listing', 'view_listing'];
    if (userIsManager) {
      allowedActions.push('edit_listing');
    }
    return {
      listingOpportunityStatus: 'COMPANY_CREATED_LISTING',
      allowedActions,
    };
  }

  /*
    From this point on you would be on the selling side.
    We distinguish between isEnded & the actual ListingOpportunityStatus of the listing (except for one state - when the listing is ended)
    This is because we want the correct message to show always.
    isEnded is mostly only checked to see if the user should be able to perform an action on a listing.
  */

  // EXTERNAL
  if (listing.attributes.external) {
    return {
      listingOpportunityStatus: 'LISTING_EXTERNAL',
      allowedActions: ['view_info', 'contact', 'copy_listing'],
    };
  }

  /*
    INTENDED BUT UNSUBMITTED
  */
  if (
    isEnded &&
    !provider?.attributes.bid_at &&
    !!provider?.attributes.intended_to_bid_at
  ) {
    return {
      listingOpportunityStatus: 'INTENDED_UNSUBMITTED',
      allowedActions: [],
    };
  }

  /*
    NO RESPONSE
  */
  if (
    isEnded &&
    !provider?.attributes.bid_at &&
    !provider?.attributes.intended_to_bid_at &&
    !provider?.attributes.declined_at &&
    !!provider?.attributes.invited_at
  ) {
    return {
      listingOpportunityStatus: 'NO_RESPONSE',
      allowedActions: [],
    };
  }

  /*
      COMPLETED AND UNSUBMITTED
      We need this as a user could navigate to /listing/listingId/view from an email
  */
  if (isEnded && !provider?.attributes.bid_at) {
    return {
      listingOpportunityStatus: 'MISSED_DEADLINE',
      allowedActions: [],
    };
  }

  // POSSIBLE OPPORTUNITY
  if (!provider) {
    const allowedActions: AllowedActions[] = ['copy_listing'];
    if (user?.company_account_id) {
      allowedActions.push('intend');
    }
    if (userIsManager && user?.company_account_id) {
      allowedActions.push('assign');
    }
    return {
      listingOpportunityStatus: 'POSSIBLE_OPPORTUNITY',
      allowedActions,
    };
  }

  /*
    INVITED DIRECT VENDOR PROVIDER CONTACT
    The contact will receive the invitation through their email. Even a sales manager cannot reassign this listing.
  */

  if (
    provider.attributes.invited_at &&
    !provider.attributes.intended_to_bid_at &&
    !provider.attributes.assigned_at &&
    !provider.attributes.bid_at &&
    !provider.attributes.declined_at &&
    contact
  ) {
    return {
      listingOpportunityStatus: 'INVITED_PROVIDER_CONTACT',
      allowedActions:
        userIsManager || user?.csAdmin
          ? ['assign', 'copy_listing']
          : ['copy_listing'],
    };
  }

  // INVITED & DECLINED
  if (!!provider.attributes.invited_at && !!provider.attributes.declined_at) {
    return {
      listingOpportunityStatus: 'DECLINED',
      allowedActions: ['copy_listing'],
    };
  }

  // DECLINED
  if (!!provider.attributes.declined_at) {
    return {
      listingOpportunityStatus: 'DECLINED',
      allowedActions: ['copy_listing'],
    };
  }

  // COMPLETED & UNSUCCESSFUL
  if (
    listing.attributes.state === 'completed' &&
    provider.attributes.state === 'bid' &&
    bid?.attributes.state === 'unsuccessful'
  ) {
    return {
      listingOpportunityStatus: 'BID_UNSUCCESSFUL',
      allowedActions: ['copy_listing'],
    };
  }

  // COMPLETED & SUCCESSFUL
  if (
    listing.attributes.state === 'completed' &&
    provider.attributes.state === 'bid' &&
    bid?.attributes.state === 'successful'
  ) {
    return {
      listingOpportunityStatus: 'BID_SUCCESSFUL',
      allowedActions: ['copy_listing'],
    };
  }

  // SHORTLISTED
  if (!!provider.attributes.bid_at && bid?.attributes.state === 'shortlisted') {
    return {
      listingOpportunityStatus: 'SHORTLISTED',
      allowedActions: ['copy_listing'],
    };
  }

  // INTERESTED
  if (
    provider.attributes.state === 'bid' &&
    bid?.attributes.state === 'interested'
  ) {
    return {
      listingOpportunityStatus: 'INTERESTED',
      allowedActions: ['copy_listing'],
    };
  }

  // UNINTERESTED
  if (
    provider.attributes.state === 'bid' &&
    bid?.attributes.state === 'uninterested'
  ) {
    return {
      listingOpportunityStatus: 'UNINTERESTED',
      allowedActions: ['copy_listing'],
    };
  }

  // SUBMITTED
  if (!!provider.attributes.bid_at) {
    return {
      listingOpportunityStatus: 'BID',
      allowedActions:
        !isEnded && (userIsManager || userIsOwner)
          ? ['assign', 'copy_listing']
          : ['copy_listing'],
    };
  }

  // ASSIGNED && UNINTENDED
  if (
    !!provider.attributes.external_assigned_to_user_id &&
    !provider.attributes.intended_to_bid_at
  ) {
    return {
      listingOpportunityStatus: 'OPPORTUNITY_WAITING',
      allowedActions:
        !isEnded && (userIsManager || userIsOwner)
          ? ['assign', 'decline', 'intend', 'copy_listing']
          : ['copy_listing'],
    };
  }

  // INTENDED & UNSUBMITTED
  if (!!provider.attributes.intended_to_bid_at && !provider.attributes.bid_at) {
    const allowedActions: AllowedActions[] = ['copy_listing'];

    if (!isEnded) {
      if (userIsManager || userIsOwner) {
        allowedActions.push('assign', 'decline');
      }
      if (userIsOwner) {
        allowedActions.push('view_opportunity', 'decline');
      }
    }
    return {
      listingOpportunityStatus: 'INTENDED_TO_BID',
      allowedActions,
    };
  }

  if (assignedNewUser) {
    const allowedActions: AllowedActions[] = ['copy_listing'];
    if (userIsManager) {
      allowedActions.push('assign');
    }
    return {
      allowedActions,
      listingOpportunityStatus: 'ASSIGNED_CONTACT',
    };
  }

  /*
    INVITED
    Company has been invited to the listing.
  */
  if (
    listing.attributes.state === 'published' &&
    !!provider.attributes.invited_at &&
    !provider.attributes.intended_to_bid_at &&
    !provider.attributes.external_assigned_to_user_id &&
    !provider.attributes.bid_at
  ) {
    const allowedActions: AllowedActions[] = ['copy_listing'];
    if (!isEnded) {
      // TODO check userIsOwner before allowing to decline or intend. see https://cloudscene.atlassian.net/browse/MP-685
      allowedActions.push('intend');
      allowedActions.push('decline');
      if (userIsManager || userIsOwner) {
        allowedActions.push('assign');
      }
    }
    return { listingOpportunityStatus: 'INVITED', allowedActions };
  }

  return {
    listingOpportunityStatus: 'ENDED',
    allowedActions: ['copy_listing'],
  };
};
