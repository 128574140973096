import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Hidden,
} from '@material-ui/core';
import { selectProviderContactsByProviderId } from '../../../store/provider-contacts';
import { ProviderContact } from '../../../types/provider-contact';
import { noop } from 'lodash';
import ServiceProviderProductsInfo from './ServiceProviderProductsInfo';
import { ServicesPlaceAttributes } from '../../../types/services';
import {
  ProviderGatewayProducts,
  ProviderGatewayServiceProvider,
} from '../../../types/provider-gateway';
import ServiceProviderProductDetails from './ServiceProviderProductDetails';
import AddGatewayServiceProviderContact from '../AddServiceProviderContact/AddGatewayServiceProviderContact';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { CreateListingFormState } from '../../../containers/CreateListing';
import { GatewayDefaultProviderContact } from '../GatewayDefaultProviderContact';

interface ServiceProviderGatewayItemProps {
  provider: ProviderGatewayServiceProvider;
  productPresence: ProviderGatewayProducts | undefined;
  contact?: ProviderContact;
  selected?: boolean;
  servicesPlaceAttributes: ServicesPlaceAttributes[];
  onSelect: (
    provider: ProviderGatewayServiceProvider,
    contact?: ProviderContact
  ) => void;
  onEdit: (
    provider: ProviderGatewayServiceProvider,
    contact?: ProviderContact
  ) => void;
  onRemove: (
    provider: ProviderGatewayServiceProvider,
    contact?: ProviderContact
  ) => void;
  onRemoveContact?: (contact: ProviderContact) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignContent: 'center',
      minHeight: 100,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      '&:hover': {
        backgroundColor: '#eef4f9',
      },
    },
    container: {
      padding: theme.spacing(2.5, 0),
    },
    name: { fontWeight: 'bold' },
    image: {
      width: 30,
      margin: 10,
      borderRadius: '25%',
      backgroundColor: theme.palette.common.white,

      [theme.breakpoints.up('sm')]: {
        width: 40,
        margin: 5,
      },
    },
    company: {
      paddingLeft: 10,
      flex: 1,
    },
    contact: { paddingLeft: '16px!important' },
  })
);

const ServiceProviderGatewayItem: React.FC<ServiceProviderGatewayItemProps> = ({
  provider,
  productPresence,
  contact,
  selected = false,
  servicesPlaceAttributes,
  onSelect,
  onEdit,
  onRemove,
  onRemoveContact = noop,
}) => {
  const classes = useStyles();
  const providerContacts = useSelector(
    selectProviderContactsByProviderId(provider.id)
  );
  const value =
    contact ??
    providerContacts.find((contact) => !!contact.attributes.default_contact);
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (selected) {
          onRemove(provider, contact);
        } else {
          onSelect(provider, value);
        }
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        alignContent="center"
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid
          item
          container
          xs={12}
          sm={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={1} sm={2}>
            <Checkbox color="primary" checked={selected} />
          </Grid>
          <Grid item xs={2}>
            <img
              className={classes.image}
              src={provider.logo}
              alt="provider-logo"
            />
          </Grid>
          <Grid item xs={7} sm={6} className={classes.company}>
            <Typography variant="body1" className={classes.name}>
              {provider.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={4}>
          <ServiceProviderProductsInfo
            products={productPresence}
            servicesPlaceAttributes={servicesPlaceAttributes}
            expand={expand}
            setExpand={setExpand}
          />
        </Grid>
        <Hidden smDown>
          <Grid container item xs={12} sm={4} justifyContent="flex-end">
            {selected && (
              <>
                <Grid item xs={5}>
                  <FormSpy subscription={{ values: true }}>
                    {({
                      values,
                    }: FormSpyRenderProps<CreateListingFormState>) => {
                      const contacts = values?.provider_contacts.filter(
                        (contact) =>
                          contact.state !== 'delete' &&
                          contact.value?.relationships?.provider?.data?.id ===
                            provider.id
                      );

                      return (
                        <GatewayDefaultProviderContact contacts={contacts} />
                      );
                    }}
                  </FormSpy>
                </Grid>

                <AddGatewayServiceProviderContact
                  provider={provider}
                  contacts={providerContacts}
                  value={contact}
                  onSelect={(contact) => {
                    onSelect(provider, contact);
                  }}
                  onEdit={(contact) => {
                    onEdit(provider, contact);
                  }}
                  onRemove={(contact) => {
                    onRemoveContact(contact);
                  }}
                />
              </>
            )}
          </Grid>
        </Hidden>
      </Grid>
      {expand && (
        <ServiceProviderProductDetails
          products={provider?.products}
          servicesPlaceAttributes={servicesPlaceAttributes}
        />
      )}

      <Hidden smUp>
        {selected && (
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={10} className={classes.contact}>
              <FormSpy subscription={{ values: true }}>
                {({ values }: FormSpyRenderProps<CreateListingFormState>) => {
                  const contacts = values?.provider_contacts.filter(
                    (contact) =>
                      contact.state !== 'delete' &&
                      contact.value?.relationships?.provider?.data?.id ===
                        provider.id
                  );

                  return <GatewayDefaultProviderContact contacts={contacts} />;
                }}
              </FormSpy>
            </Grid>

            <AddGatewayServiceProviderContact
              provider={provider}
              contacts={providerContacts}
              value={contact}
              onSelect={(contact) => {
                onSelect(provider, contact);
              }}
              onEdit={(contact) => {
                onEdit(provider, contact);
              }}
              onRemove={(contact) => {
                onRemoveContact(contact);
              }}
            />
          </Grid>
        )}
      </Hidden>
    </div>
  );
};

export default ServiceProviderGatewayItem;
