import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import LoadingSpinner from '../../LoadingSpinner';
import ListingItem from '../ListingItem/ListingItem';
import Paging from './Paging';
import SortSelect from './SortSelect';
import { ServiceTypeKeys } from '../../../types/services';
import TuneIcon from '@material-ui/icons/Tune';
import SearchField from './SearchField';
import clsx from 'clsx';
import useDebouncedFunction from '../../../hooks/useDebouncedFunction';
import EmptyContent from '../../../components/EmptyContent';
import noListingsImage from '../../../assets/empty-browse-listing.svg';
import BrowseListingsFilters from './Filters';
import ActiveListing from './ActiveListing';
import { useLazyReadAllListingsQuery } from '../../../services/api/marketplace-service/listings';

export type SortKeys = '-start' | 'end';
const sortOptions: { value: SortKeys; label: string }[] = [
  {
    value: '-start',
    label: 'Newest',
  },
  {
    value: 'end',
    label: 'Ending Soon',
  },
];
const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  cardContainer: {
    height: 'calc(100vh - 280px)',
  },
  filterColumn: {
    height: 'calc(100vh - 330px)',
  },
  dialogButton: {
    padding: 0,
    minWidth: 0,
  },
  avoidClipping: {
    zIndex: 2,
    background: 'white',
  },
  emptyImage: {
    maxWidth: '100%',
    width: 450,
  },
  link: {
    color: theme.palette.primary.main,
  },
  emptyText: {
    fontWeight: 'normal',
  },
}));
const ListingContainer: React.FC = () => {
  const classes = useStyles();
  const [active, setActive] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const onSearchChange = useDebouncedFunction(setSearchTerm, 300);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<
    ServiceTypeKeys[]
  >([]);
  const onServiceTypesChange = useDebouncedFunction(
    setSelectedServiceTypes,
    100
  );
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  const onMarketChange = useDebouncedFunction(setSelectedMarkets, 200);
  const [publicTenders, setPublicTenders] = useState<boolean | undefined>();
  const onPublicTendersChange = useDebouncedFunction(setPublicTenders, 100);
  const [sortBy, setSortBy] = useState<SortKeys>('-start');
  const onSortByChange = useDebouncedFunction(setSortBy, 100);
  const [showFilters, setShowFilter] = useState(false);
  const [readAllListings, { data, isFetching, isSuccess }] =
    useLazyReadAllListingsQuery();
  const handleReadAllListingsByPage = useCallback(
    async (page) => {
      return await readAllListings({
        params: {
          page: page,
          include: 'markets',
          'filter[search]': searchTerm,
          'filter[service_type]': selectedServiceTypes?.join(','),
          'filter[external]':
            publicTenders === undefined
              ? 0
              : publicTenders === true
              ? undefined
              : 0,
          'filter[market]': selectedMarkets?.join(','),
          sort: sortBy,
        },
      });
    },
    [
      readAllListings,
      selectedMarkets,
      searchTerm,
      selectedServiceTypes,
      publicTenders,
      sortBy,
    ]
  );
  useEffect(() => {
    const action = async () => {
      return await handleReadAllListingsByPage(1);
    };
    action();
  }, [
    handleReadAllListingsByPage,
    selectedServiceTypes,
    selectedMarkets,
    publicTenders,
    sortBy,
    searchTerm,
  ]);
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h2">Browse Listings</Typography>
          </Grid>
          <Grid item className={classes.avoidClipping}>
            <SortSelect
              sortOptions={sortOptions}
              sortBy={sortBy}
              setSortBy={onSortByChange}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container spacing={3}>
          <Grid item xs={9} sm={6} className={classes.avoidClipping}>
            <SearchField onSearchChange={onSearchChange} />
          </Grid>
          <Grid item xs={3} sm={6} className={classes.avoidClipping}>
            <Grid item container justifyContent="flex-end">
              <Button onClick={() => setShowFilter(true)} variant="contained">
                <TuneIcon />
              </Button>
            </Grid>
            <Dialog
              fullWidth
              maxWidth="lg"
              onClose={(event, reason: string) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown')
                  return false;
              }}
              open={showFilters}
            >
              <DialogTitle>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h5">Filters</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.dialogButton}
                      onClick={() => setShowFilter(false)}
                      color="primary"
                      size="small"
                    >
                      Hide
                    </Button>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Box overflow="hidden" px={1}>
                  <Grid container spacing={3}>
                    <Grid item xs sm={6}>
                      <SortSelect
                        sortOptions={sortOptions}
                        sortBy={sortBy}
                        setSortBy={onSortByChange}
                      />
                    </Grid>
                    <Grid item>
                      <BrowseListingsFilters
                        publicTenders={publicTenders}
                        setPublicTenders={onPublicTendersChange}
                        selectedServiceTypes={selectedServiceTypes}
                        setSelectedServiceTypes={onServiceTypesChange}
                        selectedMarkets={selectedMarkets}
                        setSelectedMarkets={onMarketChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid item xs={5} sm={6} md={5} lg={4} xl={3}>
            <Grid
              style={{ height: '100%' }}
              container
              spacing={3}
              direction="row"
            >
              <Grid item xs={12}>
                <SearchField onSearchChange={onSearchChange} />
              </Grid>
              <Grid
                item
                xs={12}
                className={clsx(classes.scrollContainer, classes.filterColumn)}
              >
                <BrowseListingsFilters
                  publicTenders={publicTenders}
                  setPublicTenders={onPublicTendersChange}
                  selectedServiceTypes={selectedServiceTypes}
                  setSelectedServiceTypes={onServiceTypesChange}
                  selectedMarkets={selectedMarkets}
                  setSelectedMarkets={onMarketChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          xs
          className={clsx(classes.scrollContainer, classes.cardContainer)}
        >
          <Grid container spacing={3} alignItems="stretch">
            {isFetching && <LoadingSpinner />}
            {!isFetching && isSuccess && !data?.entities.length && (
              <Grid
                item
                sm={7}
                xs={12}
                component={EmptyContent}
                img={
                  <img
                    className={classes.emptyImage}
                    alt="no listings"
                    src={noListingsImage}
                  />
                }
              >
                <Typography color="primary" variant="h4">
                  Sorry, we can&apos;t find a Marketplace listing that matches
                  your search. Try a different search, there are plenty more
                  listings to explore on Cloudscene.
                </Typography>
                <Box py={2}>
                  <Typography variant="h5" className={classes.emptyText}>
                    Still can&apos;t find what you&apos;re looking for?{' '}
                    <Link
                      href="https://help.cloudscene.com/contact-support/"
                      target="_blank"
                      underline="always"
                      className={classes.link}
                      rel="noreferrer"
                    >
                      Reach out to our support team
                    </Link>
                    {', '}
                    we&apos;re happy to help.
                  </Typography>
                </Box>
              </Grid>
            )}
            {!isFetching &&
              isSuccess &&
              data?.entities.map((listing) => (
                <Grid
                  key={listing.id}
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  xl={4}
                >
                  <ListingItem
                    focused={active === listing.attributes.short_id}
                    onClick={() => setActive(listing.attributes.short_id)}
                    markets={listing.relationships?.markets?.data}
                    services={listing.relationships?.services?.data}
                    {...listing}
                  />
                </Grid>
              ))}
          </Grid>
          {data?.meta && data?.meta?.pagination?.total_pages > 1 && (
            <Grid container spacing={2}>
              <Box my={4} flexGrow={1} data-testid="listing-container-paging">
                <Paging
                  first={1}
                  last={data?.meta?.pagination?.total_pages}
                  current={data?.meta?.pagination.current_page}
                  total={data?.meta?.pagination.total_pages}
                  onChange={handleReadAllListingsByPage}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={!!active}
        onClose={(event, reason: string) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown')
            return false;
          setActive(undefined);
        }}
        fullWidth
        maxWidth="lg"
      >
        <ActiveListing
          listingShortId={active}
          onClose={() => setActive(undefined)}
        />
      </Dialog>
    </>
  );
};
export default ListingContainer;
