import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { formatISO, parseISO, isAfter } from 'date-fns';
import { Typography, FormControlLabel, Radio, Grid } from '@material-ui/core';
import { getFullReadable } from '../../utilities/time';
import { Field } from 'react-final-form';
import CSDateTimeField from '../../components/Form/CSDateTimeField';

interface ListingStartDateProps {
  start: string | Date;
  scheduled: string;
  state: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    start: {
      paddingBottom: theme.spacing(3),
    },
  })
);

const ListingStartDate: React.FC<ListingStartDateProps> = ({
  start,
  scheduled,
  state,
  onChange,
  name = '',
}) => {
  const classes = useStyles();
  const today = new Date();

  if (isAfter(new Date(), new Date(start)) && state === 'published') {
    return (
      <Typography
        variant="body1"
        component="div"
        className={classes.start}
        data-testid="published-text"
      >
        Listing was published on {getFullReadable(new Date(start))}
      </Typography>
    );
  }
  return (
    <Grid container spacing={2} data-testid="start-date">
      <Grid item xs="auto">
        <Field name={`${name}.scheduled`} type="radio" value="not">
          {({ input }) => (
            <FormControlLabel
              value="not"
              control={
                <Radio
                  color="primary"
                  size="small"
                  onChange={(event) => {
                    onChange(event);
                    input.onChange(event.target.value);
                  }}
                  checked={input.checked}
                  data-testid="start-immediately"
                />
              }
              label="Start Immediately"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs="auto">
        <Field name={`${name}.scheduled`} type="radio" value="scheduled">
          {({ input }) => (
            <FormControlLabel
              value="scheduled"
              control={
                <Radio
                  color="primary"
                  size="small"
                  onChange={input.onChange}
                  checked={input.checked}
                />
              }
              label="Scheduled to Start on"
            />
          )}
        </Field>
      </Grid>
      <Grid item xs="auto">
        <Field
          name={`${name}.start`}
          parse={(value) => (value ? formatISO(value) : '')}
          format={(value) => (value ? parseISO(value) : '')}
        >
          {({ input }) => {
            return (
              <CSDateTimeField
                disabled={scheduled === 'not'}
                minDate={today}
                {...input}
              />
            );
          }}
        </Field>
      </Grid>
    </Grid>
  );
};

export default ListingStartDate;
