import React from 'react';
import {
  Grid,
  Theme,
  makeStyles,
  createStyles,
  Typography,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      flexDirection: 'column',
    },
    paper: {
      borderColor: 'transparent',
      borderRadius: 8,
      padding: theme.spacing(2, 3),
    },
    title: {
      fontSize: 16,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    header: {
      fontSize: 14,
      color: theme.palette.grey[700],
      fontWeight: 'bold',
    },
    content: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  })
);

const LeadListHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid container item xs={4} alignItems="center" wrap="nowrap">
            <Typography
              className={classes.title}
              component="div"
              color="textPrimary"
            >
              Leads
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                Company
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div" className={classes.header}>
                Owner
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography component="div" className={classes.header}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LeadListHeader;
