import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { CSTextFieldProps } from '../CSTextField/CSTextField';
import { SelectField, SelectFieldProps } from '../CSSelectField';

interface TextSelectGroupProps {
  name: string;
  label: React.ReactNode;
  value?: string;
  selected?: any;
  onBlur?: () => void;
  defaultSelected?: any;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onSelect: (event: React.ChangeEvent<{ name?: string; value?: any }>) => void;
  textFieldProps?: Omit<CSTextFieldProps, 'css'>;
  selectFieldProps?: Omit<SelectFieldProps, 'css'>;
}

const useInputStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingRight: 0,
    },
  })
);

const useSelectStyles = makeStyles((theme) =>
  createStyles({
    select: {
      fontWeight: theme.typography.fontWeightBold,
    },
    icon: {
      top: 'calc(50% - 14px)',
    },
  })
);

const TextSelectGroup: React.FC<TextSelectGroupProps> = ({
  name,
  label,
  value,
  selected,
  defaultSelected,
  onChange,
  onSelect,
  textFieldProps,
  selectFieldProps,
  children,
  onBlur = () => false,
}) => {
  const inputClasses = useInputStyles();
  const selectClasses = useSelectStyles();

  return (
    <TextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      classes={inputClasses}
      endAdornment={
        <InputAdornment position="end">
          <SelectField
            value={selected}
            defaultValue={defaultSelected}
            onChange={onSelect}
            classes={selectClasses}
            fullWidth={false}
            {...selectFieldProps}
          >
            {children}
          </SelectField>
        </InputAdornment>
      }
      {...textFieldProps}
    />
  );
};

export default TextSelectGroup;
