import { ReactNode } from 'react';

export const MenuList = ({
  id,
  children,
}: {
  id?: string;
  children: ReactNode;
}) => {
  return (
    <ul role="menu" aria-orientation="vertical" aria-labelledby={id}>
      {children}
    </ul>
  );
};
