import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field, FormSpy } from 'react-final-form';
import CSTextField from '../../components/Form/CSTextField';
import AdminTeamMemberSearch from '../../components/AdminTeamMemberSearch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';

interface FormSectionProps {
  name: string;
}

const MyDetailsSection: React.FC<FormSectionProps> = ({ name }) => {
  return (
    <Grid container spacing={4}>
      <FormSpy subscription={{ values: true, pristine: true }}>
        {({ values, form }) => (
          <>
            {values[name]?.search && (
              <Grid item xs={12} md={12}>
                <Field name="teamMember">
                  {({ input }) => (
                    <AdminTeamMemberSearch
                      {...input}
                      label="Select User"
                      onChange={({ user, account }) => {
                        form.change('attributes.user_id', user.id);
                        form.change('attributes.company_id', account.id);
                        form.change(
                          'attributes.company',
                          account.attributes.name
                        );
                        form.change(
                          'attributes.company_logo',
                          account.attributes.logoUrl
                        );
                        form.change('owner', {
                          ...values[name],
                          full_name: `${user.attributes.firstName} ${user.attributes.lastName}`,
                          email: user.attributes.email,
                          position: user.attributes.position,
                          phone: user.attributes.phone,
                          company_id: account.id,
                        });
                      }}
                      optionType="table"
                    />
                  )}
                </Field>
              </Grid>
            )}
          </>
        )}
      </FormSpy>

      <Grid item xs={12} md={6}>
        <Field name={`${name}.full_name`}>
          {({ input, meta }) => (
            <CSTextField
              label="Full Name *"
              {...input}
              error={meta.touched && !!meta.error}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name={`attributes.company`}>
          {({ input, meta }) => (
            <CSTextField
              label="Company *"
              {...input}
              error={meta.touched && !!meta.error}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name={`${name}.position`}>
          {({ input, meta }) => (
            <CSTextField
              label="Position Title"
              {...input}
              error={meta.touched && !!meta.error}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name={`${name}.email`}>
          {({ input, meta }) => (
            <CSTextField
              label="Email *"
              {...input}
              error={meta.touched && !!meta.error}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name={`${name}.phone`} type="tel">
          {({ input, meta }) => (
            <CSTextField
              label="Phone"
              {...input}
              error={meta.touched && !!meta.error}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name={`${name}.user_details_agreement`} type="checkbox">
          {({ input }) => (
            <FormControlLabel
              label={
                <Typography variant="body2">
                  By submitting my details, I understand that Cloudscene will
                  collect and use my personal data in accordance with its{' '}
                  <Link
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://cloudscene.com/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              }
              control={<Checkbox color="primary" />}
              {...input}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

export default MyDetailsSection;
