import { useSelector } from 'react-redux';
import { selectMarketById } from '../../store/markets';
import { MarketEntity } from '../../types/market';

const MarketState = ({
  id,
  children,
}: {
  id: string;
  children: (market: MarketEntity) => JSX.Element;
}) => {
  const market = useSelector(selectMarketById(id));
  return market ? children(market) : null;
};

export default MarketState;
