import tw from 'twin.macro';

export const base = [
  tw`text-center text-base leading-none font-medium font-brand py-3 px-5 rounded-md cursor-pointer disabled:opacity-50 disabled:cursor-default outline-none focus:outline-none focus:(ring-2 ring-brand)`,
];
export const primary = {
  DEFAULT: [
    tw`text-white bg-brand hover:bg-primary-600 focus:bg-primary-600 disabled:(hover:bg-brand focus:bg-brand)`,
  ],
  danger: [
    tw`text-white bg-danger-500 hover:bg-danger-600 focus:bg-danger-600 disabled:(hover:bg-danger-500 focus:bg-danger-500)`,
  ],
};
export const secondary = {
  DEFAULT: [
    tw`text-gray-800 bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 disabled:(hover:(bg-gray-100) focus:bg-gray-100)`,
  ],
  primary: [
    tw`text-brand bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 disabled:(hover:(bg-gray-100) focus:bg-gray-100)`,
  ],
  ghost: [
    tw`text-gray-700 bg-transparent hover:(text-gray-900 bg-gray-100) focus:(text-gray-900 bg-gray-100) disabled:(text-gray-500 hover:(bg-transparent) focus:bg-transparent)`,
  ],
  danger: [
    tw`text-danger-500 hover:text-danger-600 focus:text-danger-600 disabled:(hover:bg-danger-400 focus:bg-danger-400)`,
  ],
};
export const dangerTextButton = [
  tw`text-danger-500 hover:text-danger-600 focus:text-danger-600 p-0`,
];
export const primaryTextButton = [
  tw`font-brand text-primary-500 hover:text-primary-700 focus:text-primary-700 cursor-pointer p-0`,
];
export const outlined = {
  DEFAULT: [
    tw`bg-transparent box-border text-brand font-medium border-2 border-primary-500`,
  ],
  light: [
    tw`bg-white text-gray-400 border-gray-400 hover:bg-gray-50 focus:bg-gray-50 disabled:(hover:bg-gray-50 focus:bg-gray-50)`,
  ],
};

export const transparent = [
  tw`inline-flex h-10 justify-center items-center w-full`,
  tw`rounded-md border border-transparent px-4 py-2`,
  tw`text-sm font-medium text-gray-500 bg-white hover:bg-gray-50`,
  tw`focus:(outline-none ring-brand ring)`,
];

export const icon = {
  DEFAULT: [
    tw`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600`,
    tw`hover:(outline-none text-gray-800 bg-gray-500 bg-opacity-10)`,
    tw`focus:(outline-none ring-brand ring-inset ring-2 bg-gray-500 bg-opacity-10 text-gray-800)`,
  ],
  filled: [tw`bg-gray-100 hover:(text-gray-800 bg-gray-200)`],
};

export const text = {
  DEFAULT: [
    tw`text-gray-700 bg-transparent hover:(text-gray-900 bg-transparent) focus:(text-gray-900 bg-transparent) px-1 py-0.5 leading-tight`,
  ],
  primary: [
    tw`text-primary-500 bg-transparent hover:(text-primary-600 bg-transparent) focus:(text-primary-600 bg-transparent) px-1 py-0.5 leading-tight`,
  ],
  danger: [
    tw`text-danger-500 bg-transparent hover:(text-danger-600 bg-transparent) focus:(text-danger-600 bg-transparent) px-1 py-0.5 leading-tight`,
  ],
};
