import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

interface ListingSectionProps {
  heading: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.common.white,
      borderRadius: 8,
      boxShadow: `0 0 24px -12px ${theme.palette.grey[400]}`,
    },
  })
);

const ListingSection: React.FC<ListingSectionProps> = ({
  heading,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} p={4}>
      {heading}
      <Box mt={4}>{children}</Box>
    </Box>
  );
};

export default ListingSection;
