import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import BidServices from './BidServices';
import BidDocument from './BidDocument';
import AttachmentList from '../AttachmentList';
import HtmlParser from '../HtmlParser';
import { BidResponse } from '../../types/marketplace-service/bid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    service: {
      padding: theme.spacing(4),
    },
  })
);

interface ViewBidProps {
  bid?: BidResponse;
}

const ViewBid: React.FC<ViewBidProps> = ({ bid }) => {
  const classes = useStyles();

  if (!bid) {
    return null;
  }

  const documents = bid.relationships.documents?.data ?? [];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.service}>
        <BidServices bid={bid} />
      </Grid>
      {bid.attributes.installation_and_lead_time && (
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Installation and lead time
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <HtmlParser>{bid.attributes.installation_and_lead_time}</HtmlParser>
          </Typography>
        </Grid>
      )}
      {bid.attributes.terms_and_conditions && (
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Terms and conditions
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <HtmlParser>{bid.attributes.terms_and_conditions}</HtmlParser>
          </Typography>
        </Grid>
      )}
      {bid.attributes.capabilities && (
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Point of difference
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <HtmlParser>{bid.attributes.capabilities}</HtmlParser>
          </Typography>
        </Grid>
      )}
      {documents.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Attachment
          </Typography>

          {documents.map((document, index: number) => (
            <BidDocument key={document.id!} id={document.id!}>
              {(document) => {
                return <AttachmentList key={index} document={document} />;
              }}
            </BidDocument>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ViewBid;
