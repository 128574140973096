import { useSelector } from 'react-redux';
import { selectDocumentEntities } from '../../store/documents';
import { selectMarketEntities } from '../../store/markets';
import { selectListingProviderContactEntities } from '../../store/listing-provider-contacts';
import { selectServiceEntities } from '../../store/services';
import { Document } from '../../types/document';
import { MarketEntity } from '../../types/market';
import { ListingService } from '../../types/services';
import { ListingResponse } from '../../types/marketplace-service/listing';
import { ListingProviderContactResponse } from '../../types/marketplace-service/listing-provider-contact';
import { useLazyReadAllListingProvidersByListingIDQuery } from '../../services/api/marketplace-service/listing-providers';
import { useEffect } from 'react';

export const useInitialListingValues = (listing?: ListingResponse) => {
  const services = useSelector(selectServiceEntities);
  const [readListingProvidersByListingID, { providers }] =
    useLazyReadAllListingProvidersByListingIDQuery({
      selectFromResult: (result) => {
        const providers = result?.data?.entities;
        return { providers };
      },
    });
  useEffect(() => {
    if (listing) {
      readListingProvidersByListingID({
        listing: listing.id,
      });
    }
  }, [listing, readListingProvidersByListingID]);
  const contacts = useSelector(selectListingProviderContactEntities);
  const markets = useSelector(selectMarketEntities);
  const documents = useSelector(selectDocumentEntities);
  if (!listing) {
    return {};
  }

  return {
    services: listing.relationships?.services?.data
      .map((service) => services[service.id])
      .filter((s) => !!s) as ListingService[],
    providers: providers,
    contacts: listing.relationships?.['provider-contacts']?.data
      .map((contact) => contacts[contact.id])
      .filter((c) => !!c) as ListingProviderContactResponse[],
    markets: listing.relationships?.markets?.data
      .map((market) => markets[market.id])
      .filter((m) => !!m) as MarketEntity[],
    documents: listing.relationships?.documents?.data
      .map((document) => documents[document.id])
      .filter((d) => !!d) as Document[],
  };
};
