type RoutesType = {
  root: string;
  authCallbacks: string;
  notFound: string;
  browseListings: string;
  listingSuccess: string;
  requestQuoteSuccess: string;
  myMarketplace: {
    root: string;
    buying: string;
    selling: string;
    dashboard: string;
    listings: string;
    opportunities: string;
    completed: string;
  };
  // my marketplace buying
  buyingDashboard: string;
  buyingListings: string;
  buyingPending: string;
  buyingCompleted: string;
  oldCreateListing: string;
  createListing: string;
  editListing: string;
  copyListing: string;
  viewListing: string;
  requestQuote: string;
  // my marketplace selling
  sellingDashboard: string;
  sellingOpportunities: string;
  sellingPending: string;
  sellingCompleted: string;
  viewBid: string;
  signup: {
    root: string;
  };
  newCompany: string;
  companyType: string;
  companyCompleteSignup: string;
  [k: string]: string | { [k: string]: string };
};

interface GetRoute {
  (path: string, routes?: RoutesType | { [k: string]: string }): string;
}

export const ROUTES = {
  // general app pages
  root: '/',
  directInvite: 'direct-invite',
  assignInvite: 'assign-invite',
  authCallbacks: '/authentication/:callbackType',
  notFound: '404-not-found',
  createListing: '/my-marketplace/buying/create-new-listing',
  editListing: '/listing/:id/edit',
  copyListing: '/listing/:id/copy',
  viewBid: '/bid/:id/view',
  viewListing: '/listing/:id/view',
  oldCreateListing: '/procurement/create-new-listing',
  listingSuccess: '/my-marketplace/create/success',
  requestQuote: '/requestquote/:providerId',
  requestQuoteSuccess: '/my-marketplace/requestquote/success',
  browseListings: '/browse',
  signout: '/signout',
  signup: {
    root: '/signup',
  },
  myMarketplace: {
    root: '/my-marketplace',
    buying: 'buying',
    selling: 'selling',
    dashboard: '/',
    listings: 'listings',
    opportunities: 'opportunities',
    completed: 'completed',
    pending: 'pending',
    qualifiedOpportunities: 'qualified-opportunities',
    closedLost: 'closed-lost',
    noBidNoResponse: 'no-bid-no-response',
    leads: 'leads',
    contacted: 'contacted',
    notContacted: 'not-contacted',
  },
  // my marketplace buying
  get buyingDashboard() {
    return getRoute('root.buying', this.myMarketplace);
  },
  get buyingListings() {
    return getRoute('root.buying.listings', this.myMarketplace);
  },
  get buyingPending() {
    return getRoute('root.buying.pending', this.myMarketplace);
  },
  get buyingCompleted() {
    return getRoute('root.buying.completed', this.myMarketplace);
  },
  // signup
  get teamSearch() {
    return getRoute('root', this.signup);
  },
  get teamFound() {
    return getRoute('root.found', this.signup);
  },
  get teamJoinRequested() {
    return getRoute('root.requested', this.signup);
  },
  get signupError() {
    return getRoute('root.error', this.signup);
  },
  get teamFoundNotConfirmed() {
    return getRoute('root.foundNotConfirm', this.signup);
  },
  get newCompany() {
    return getRoute('root.new', this.signup);
  },
  get companyType() {
    return getRoute('root.type', this.signup);
  },
  get companyCompleteSignup() {
    return getRoute('root.complete', this.signup);
  },
  // my marketplace selling
  get sellingDashboard() {
    return getRoute('root.selling', this.myMarketplace);
  },
  get sellingOpportunities() {
    return getRoute('root.selling.opportunities', this.myMarketplace);
  },
  get sellingPending() {
    return getRoute('root.selling.pending', this.myMarketplace);
  },
  get sellingCompleted() {
    return getRoute('root.selling.completed', this.myMarketplace);
  },
  get sellingQualifiedOpportunities() {
    return getRoute('root.selling.qualifiedOpportunities', this.myMarketplace);
  },
  get sellingClosedLost() {
    return getRoute('root.selling.closedLost', this.myMarketplace);
  },
  get sellingNoBidNoResponse() {
    return getRoute('root.selling.noBidNoResponse', this.myMarketplace);
  },
  get leads() {
    return getRoute('root.selling.leads', this.myMarketplace);
  },
  get contacted() {
    return getRoute('root.selling.contacted', this.myMarketplace);
  },
  get notContacted() {
    return getRoute('root.selling.notContacted', this.myMarketplace);
  },
} as const;

// This is rather naive and stupidly simplistic. However it just creates a path
// from an object of routes using each `path` segment (separated by `.`) as the
// key for each segment value. The idea being that you can construct any URL
// combinations based on path segment "names", with the option to use a custom
//"root" object to change the route starting point.
export const getRoute: GetRoute = (path, routes = ROUTES) => {
  const segments = path.split('.');

  return segments.map((segment) => routes[segment]).join('/');
};
