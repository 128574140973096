import React, { ReactElement } from 'react';
import { navigate, useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import NavigationListItem from '../../../components/NavigationListItem';
import { ROUTES } from '../../../routes';
import { useReadAllListingProvidersQuery } from '../../../services/api/marketplace-service/listing-providers';
import FlagsmithFeature from '@cloudscene-dev/flagsmith-react';
import { FLAGSMITH_FEATURES } from '../../../utilities/flagsmith-config';
import { Divider } from '@material-ui/core';

const useListStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  chip: {
    display: 'inline-flex',
    marginLeft: theme.spacing(1.2),
    padding: theme.spacing(0.4, 0.8),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const SellingNavigation = (): ReactElement => {
  const listClasses = useListStyles();
  const location = useLocation();
  const { data } = useReadAllListingProvidersQuery({
    params: {
      include: 'listing,bid',
      'meta[count]': ['opportunities', 'qualified-opportunities'].join(','),
    },
  });

  const totalOpportunities = data?.meta?.count?.opportunities ?? 0;
  const qualifiedOpportunities =
    data?.meta?.count?.['qualified-opportunities'] ?? 0;

  return (
    <List component="nav" className={listClasses.root}>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.sellingOpportunities)}
        onClick={() => navigate(ROUTES.sellingOpportunities)}
      >
        Opportunities
        {totalOpportunities > 0 && (
          <span className={listClasses.chip}>{totalOpportunities}</span>
        )}
      </NavigationListItem>
      <NavigationListItem
        button
        selected={location.pathname.includes(
          ROUTES.sellingQualifiedOpportunities
        )}
        onClick={() => navigate(ROUTES.sellingQualifiedOpportunities)}
      >
        Qualified Opportunities
        {qualifiedOpportunities > 0 && (
          <span className={listClasses.chip}>{qualifiedOpportunities}</span>
        )}
      </NavigationListItem>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.sellingCompleted)}
        onClick={() => navigate(ROUTES.sellingCompleted)}
      >
        Completed
      </NavigationListItem>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.sellingClosedLost)}
        onClick={() => navigate(ROUTES.sellingClosedLost)}
      >
        Closed/Lost
      </NavigationListItem>
      <NavigationListItem
        button
        selected={location.pathname.includes(ROUTES.sellingNoBidNoResponse)}
        onClick={() => navigate(ROUTES.sellingNoBidNoResponse)}
      >
        No Bid/No Response
      </NavigationListItem>
      <FlagsmithFeature id={FLAGSMITH_FEATURES.MARKETPLACE_LEADS}>
        <Divider className={listClasses.divider} />
        <NavigationListItem
          button
          selected={location.pathname.includes(ROUTES.leads)}
          onClick={() => navigate(ROUTES.leads)}
        >
          Leads
        </NavigationListItem>
      </FlagsmithFeature>
    </List>
  );
};

export default SellingNavigation;
