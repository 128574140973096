import React from 'react';
import { Link } from '@reach/router';
import { Button, makeStyles, createStyles, Grid } from '@material-ui/core';
import noListingsImage from '../../../../assets/marketplace-no-listings-buyer.svg';
import { ROUTES } from '../../../../routes';
import EmptyContent from '../../../../components/EmptyContent';

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      maxWidth: '100%',
      width: 450,
    },
  })
);

const Empty = () => {
  const classes = useStyles();
  return (
    <Grid
      item
      sm={6}
      xs={12}
      component={EmptyContent}
      img={
        <img
          className={classes.image}
          alt="No listings"
          src={noListingsImage}
        />
      }
      cta={
        <Button
          component={Link}
          to={ROUTES.createListing}
          variant="contained"
          color="primary"
        >
          Create Listing
        </Button>
      }
    >
      You don&apos;t have any active listings on Cloudscene Marketplace right
      now, but we can help with that. Tell us your requirements by creating a
      Marketplace listing and our platform will match you with service providers
      that can provide competitive bids, fast.
    </Grid>
  );
};

export default Empty;
