import React, { useCallback } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAppMessenger } from '../../store/messenger';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  IconButton,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { useAuth } from '../../hooks/useAuth';
import { useUpdateListingByIDMutation } from '../../services/api/marketplace-service/listings';
import { ROUTES } from '../../routes';

interface EndListingProps {
  open?: boolean;
  listingId: string;
  userId: string;
  companyId: string;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      margin: '0 auto',
    },
    MuiButtonContainedSecondary: {
      margin: '0 auto',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);

const EndListing: React.FC<EndListingProps> = ({
  open = false,
  onClose,
  listingId,
  userId,
  companyId,
}) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const messenger = useAppMessenger();
  const { user } = useAuth();
  const [updateListingByID, { isLoading }] = useUpdateListingByIDMutation();
  const handleListingUpdate = useCallback(async () => {
    return await updateListingByID({
      id: listingId,
      payload: {
        id: listingId,
        type: 'listing',
        attributes: {
          state: 'ended',
          user_id: userId ?? user?.id,
          company_id: companyId ?? user?.company_account_id,
        },
      },
    })
      .unwrap()
      .then(() => {
        navigate(`${ROUTES.buyingPending}/${listingId}`, {
          replace: true,
        }).then(() => {
          messenger.addMessage('info', 'This listing has ended.');
        });
      });
  }, [updateListingByID, companyId, listingId, messenger, userId, user]);
  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle disableTypography>
          <Typography align="center" component="div" variant="h2">
            End Listing?
          </Typography>
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography align="center" variant="body2" color="textPrimary">
              Are you sure you want to end your listing? You will not be able to
              receive any further bids.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item xs="auto">
              <Button
                className={classes.MuiButtonContainedSecondary}
                onClick={handleListingUpdate}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                endIcon={
                  isLoading && <CircularProgress color="secondary" size={20} />
                }
                autoFocus
              >
                End Listing
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                className={classes.button}
                onClick={onClose}
                color="primary"
                disabled={isLoading}
                autoFocus
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EndListing;
