import { Button, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { UserResponse } from '../../types/account-service/users';
import { ListingProviderResponse } from '../../types/marketplace-service/listing-provider';
import { ProviderContact } from '../../types/provider-contact';
import { isAccountManager } from '../../utilities/users';
import { ListingOpportunityStatus } from './useListingOpportunityStatus';
import BuyerDetails from './BuyerDetails';
import { getFullReadable } from '../../utilities/time';
import { BidResponse } from '../../types/marketplace-service/bid';
import { ListingResponse } from '../../types/marketplace-service/listing';

const InfoModal: React.FC<{
  listingStartDate: Date;
  listingOpportunityStatus: ListingOpportunityStatus;
  contact?: ProviderContact;
  assignedUser?: UserResponse | null;
  intendByUser?: UserResponse | null;
  provider?: ListingProviderResponse;
  bid?: BidResponse;
  setViewBid: (viewBid: boolean) => void;
  showOwnerDetails: boolean;
  onShowOwnerDetails: () => void;
  ownerDetails: ListingResponse | undefined;
  isError: boolean;
}> = ({
  listingStartDate,
  listingOpportunityStatus,
  provider,
  bid,
  contact,
  assignedUser,
  intendByUser,
  setViewBid,
  showOwnerDetails,
  onShowOwnerDetails,
  ownerDetails,
  isError,
}) => {
  const { user } = useAuth();
  const userIsOwner =
    user?.id === provider?.attributes.external_assigned_to_user_id;
  const assignedNewUser = provider?.attributes.assigned_to_contact;
  const userIsManager = isAccountManager(user);

  if (listingOpportunityStatus === 'SCHEDULED') {
    return (
      <Typography variant="inherit">
        This listing is scheduled to start on the{' '}
        {getFullReadable(listingStartDate)}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'USER_OWNS_LISTING') {
    return (
      <Typography variant="inherit">
        You are the owner of this listing.
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'COMPANY_CREATED_LISTING') {
    return (
      <Typography variant="inherit">
        {' '}
        Your company created this listing.{' '}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'LISTING_EXTERNAL') {
    return (
      <Typography variant="inherit">
        This is an external listing and cannot be bid on through the Cloudscene
        marketplace.
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'POSSIBLE_OPPORTUNITY') {
    return (
      <Typography variant="inherit">
        {userIsManager
          ? 'Are you interested in quoting this listing? Please let the buyer know by registering your intent to bid, or assign the opportunity to a team member.'
          : 'Are you interested in quoting this listing? Please let the buyer know by registering your intent to bid.'}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'INVITED_PROVIDER_CONTACT') {
    return contact ? (
      <Typography variant="inherit">
        This opportunity has automatically been assigned to{' '}
        {contact.attributes.first_name} {contact.attributes.last_name} by the
        buyer, who has nominated this team member as their Vendor Contact. This
        listing can be reassigned using the reassign action.
      </Typography>
    ) : null;
  }

  if (listingOpportunityStatus === 'MISSED_DEADLINE') {
    return (
      <Typography variant="inherit">
        {' '}
        Your company did not submit a bid on this opportunity.{' '}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'DECLINED') {
    return (
      <>
        <Typography variant="inherit">
          Your company has decided not to bid on this opportunity.
        </Typography>
        {provider?.attributes?.declined_reason && (
          <Typography component="div" variant="inherit">
            Declined reason: {provider?.attributes.declined_reason}
          </Typography>
        )}
      </>
    );
  }

  if (listingOpportunityStatus === 'BID_UNSUCCESSFUL') {
    return (
      <Typography variant="inherit">
        Your company's bid was unsuccessful for this opportunity.{' '}
        <Link
          onClick={() => {
            setViewBid(true);
          }}
        >
          View bid
        </Link>
        {!!provider?.attributes.declined_reason && (
          <Typography variant="body2">
            Buyer's reason: {provider.attributes.declined_reason}
          </Typography>
        )}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'BID_SUCCESSFUL') {
    return (
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={!showOwnerDetails ? 9 : 12}>
          <Typography variant="body1">
            Great news! Your company's bid was successful for this opportunity.{' '}
            <Link
              onClick={() => {
                setViewBid(true);
              }}
            >
              View bid
            </Link>{' '}
            You are now able to contact the buyer to finalize this opportunity.
          </Typography>
        </Grid>
        {!showOwnerDetails && (
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onShowOwnerDetails();
              }}
            >
              Show Details
            </Button>
          </Grid>
        )}

        {showOwnerDetails && ownerDetails && (
          <BuyerDetails
            name={ownerDetails.attributes.contact?.full_name!}
            email={ownerDetails.attributes.contact?.email!}
            phone={ownerDetails.attributes.contact?.phone}
          />
        )}
        {showOwnerDetails && isError && (
          <Typography variant="body2">
            Sorry, could not retrieve buyer details at this moment.
          </Typography>
        )}
      </Grid>
    );
  }

  if (listingOpportunityStatus === 'SHORTLISTED') {
    return (
      <Typography variant="inherit">
        Your company's bid has been shortlisted for this opportunity. When the
        listing ends, the buyer will review all bids and notify your company on
        the outcome shortly.{' '}
        <Link
          onClick={() => {
            setViewBid(true);
          }}
        >
          View bid
        </Link>
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'BID') {
    return (
      <Typography variant="inherit">
        A bid has been received. When the listing ends, this buyer will review
        all bids and select a service provider.{' '}
        <Link
          onClick={() => {
            setViewBid(true);
          }}
        >
          View bid
        </Link>
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'OPPORTUNITY_WAITING') {
    return (
      <Typography variant="inherit">
        {userIsOwner
          ? 'You have a new opportunity! Please respond by indicating if you can fulfill the requirements and intend to submit a bid.'
          : assignedUser
          ? `${assignedUser?.attributes.firstName} ${assignedUser?.attributes.lastName} is the owner of this opportunity.`
          : `${assignedNewUser?.first_name} ${assignedNewUser?.last_name} (Pending) is the owner of this opportunity.`}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'INTENDED_TO_BID') {
    return (
      <Typography variant="inherit">
        {userIsOwner
          ? 'You have registered an intent to bid on this listing. Please submit your bid or you can assign the opportunity to a team member.'
          : assignedUser
          ? `${intendByUser?.attributes.firstName} ${intendByUser?.attributes.lastName} has intended to Bid on the listing and assigned the listing to ${assignedUser?.attributes.firstName} ${assignedUser?.attributes.lastName}.`
          : `${intendByUser?.attributes.firstName} ${intendByUser?.attributes.lastName} has intended to Bid on the listing and assigned the listing to ${assignedNewUser?.first_name} ${assignedNewUser?.last_name} (Pending).`}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'ASSIGNED_CONTACT') {
    return (
      <Typography variant="inherit">
        {assignedNewUser?.first_name} {assignedNewUser?.last_name} (Pending) is
        the owner of this opportunity.
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'INVITED') {
    return (
      <Typography variant="inherit">
        Your company has been invited to bid on this listing. Please respond by
        indicating if you can fulfill the requirements and intend to submit a
        bid.
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'INTERESTED') {
    return (
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={!showOwnerDetails ? 9 : 12}>
          <Typography variant="body1">
            Great news, the buyer is interested in your bid. You are now able to
            contact the buyer to discuss your proposal.{' '}
            <Link
              onClick={() => {
                setViewBid(true);
              }}
            >
              View bid
            </Link>{' '}
          </Typography>
        </Grid>
        {!showOwnerDetails && (
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onShowOwnerDetails();
              }}
            >
              Show Details
            </Button>
          </Grid>
        )}

        {showOwnerDetails && ownerDetails && (
          <BuyerDetails
            name={ownerDetails.attributes.contact?.full_name!}
            email={ownerDetails.attributes.contact?.email!}
            phone={ownerDetails.attributes.contact?.phone}
          />
        )}
        {showOwnerDetails && isError && (
          <Typography variant="body2">
            Sorry, could not retrieve buyer details at this moment.
          </Typography>
        )}
      </Grid>
    );
  }

  if (listingOpportunityStatus === 'INTENDED_UNSUBMITTED') {
    return (
      <Typography variant="inherit">
        {' '}
        Your company did not bid on this opportunity.{' '}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'NO_RESPONSE') {
    return (
      <Typography variant="inherit">
        {' '}
        Your company did not respond this opportunity.{' '}
      </Typography>
    );
  }

  if (listingOpportunityStatus === 'UNINTERESTED') {
    return (
      <Typography variant="inherit">
        Your company's bid was unsuccessful for this opportunity.{' '}
        {!!bid?.attributes.state_uninterested_reason && (
          <Typography variant="body2">
            Reason from buyer: {bid?.attributes.state_uninterested_reason}
          </Typography>
        )}
        <Link
          onClick={() => {
            setViewBid(true);
          }}
        >
          View bid
        </Link>
      </Typography>
    );
  }

  return null;
};

export default InfoModal;
