import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface LeadActionModalProps {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paragraph: {
    padding: theme.spacing(0, 4, 4, 4),
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

const LeadActionModal: React.FC<LeadActionModalProps> = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle disableTypography>
        <Typography align="center" component="div" variant="h2">
          Action this Lead?
        </Typography>
        <IconButton
          className={classes.closeButton}
          size="small"
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Box className={classes.paragraph}>
            <Typography>
              Would you like to update the status of this lead to Actioned?
            </Typography>
            <Typography>This state cannot be reverted.</Typography>
          </Box>
          <Grid container className={classes.buttons}>
            <Grid item className={classes.button}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={() => onConfirm()}
              >
                Yes
              </Button>
            </Grid>
            <Grid item className={classes.button}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LeadActionModal;
