import React from 'react';
import {
  Grid,
  CircularProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    spinner: {
      display: 'block',
      margin: theme.spacing(5, 0),
    },
  })
);

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs="auto"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress className={classes.spinner} />
    </Grid>
  );
};

export default LoadingSpinner;
