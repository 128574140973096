import * as React from 'react';
import { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ListingRating from '../../components/ListingRating';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CompanyInfoDisplay } from '../CompanyInfo';
import { CompanyAccountResponse } from '../../types/account-service/company-account';

interface BidActionInterestedProps extends ButtonProps {
  company: CompanyAccountResponse;
  onInterested: (rating?: number | null, updateListing?: boolean) => void;
  showRating: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: theme.spacing(2, 0),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    text: {
      textAlign: 'center',
    },
  })
);

const BidActionInterested: React.FC<Omit<BidActionInterestedProps, 'css'>> = ({
  company,
  onInterested,
  showRating,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [step, setStep] = useState<'confirm' | 'complete'>('confirm');

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="text" {...props}>
        <Typography variant="subtitle2" color="primary">
          I&apos;m Interested
        </Typography>
      </Button>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpen(false);
          }
        }}
        TransitionProps={{
          onExited: () => {
            if (step === 'complete') {
              onInterested();
            }
          },
        }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            data-testid="close-button"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12} className={classes.section}>
            {step === 'confirm' && (
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    variant="h2"
                    className={classes.text}
                  >
                    Let {company.attributes.name} know you&apos;re interested.
                  </Typography>
                </Grid>
                <Grid item>
                  <CompanyInfoDisplay company={company} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    className={classes.text}
                  >
                    Your details are about to be sent to the provider. The
                    provider will be able to contact you directly to discuss
                    their bid.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  container
                  spacing={1}
                  alignItems="center"
                  direction="column"
                >
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (showRating) {
                          setStep('complete');
                        } else {
                          onInterested();
                        }
                      }}
                    >
                      Send Interest
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {step === 'complete' && (
              <ListingRating
                onClose={(rating) => {
                  if (rating) {
                    onInterested(rating, true);
                  }
                }}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BidActionInterested;
