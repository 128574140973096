import { FC, useRef } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import tw, { theme, TwStyle } from 'twin.macro';
import Anchor from '../../components/Cloudscene/Anchor';
import { AppMenu, AppMenuContext } from '../../components/AppMenu';
import { CloudsceneLogo } from '../../components/CloudsceneLogo';
import { PrimaryMenu } from '../../features/PrimaryMenu';
import { UtilityMenu } from '../../features/UtilityMenu';
import { MenuControl } from './MenuControl';
import { CLOUDSCENE_URL } from '../../api';
import { HeaderProps } from './types';

export const SecondaryMenu: FC<HeaderProps> = ({ variant, children }) => (
  <div css={tw`border-t border-b border-gray-300`}>
    <div
      css={[variant === 'contained' && tw`container mx-auto`, tw`px-3 py-2`]}
    >
      {children}
    </div>
  </div>
);

const transitions: Record<TransitionStatus, TwStyle> = {
  unmounted: tw`hidden`,
  exited: tw`opacity-0 invisible -translate-y-full`,
  exiting: tw`opacity-0 pointer-events-none -translate-y-full`,
  entering: tw`opacity-100 pointer-events-none translate-y-0`,
  entered: tw`opacity-100 translate-y-0`,
};

export const Header: FC<HeaderProps> = ({
  variant = 'contained',
  children,
  primary = (
    <>
      <div css={tw`block xl:hidden`}>
        <PrimaryMenu.Mobile />
      </div>
      <div css={tw`hidden xl:block`}>
        <PrimaryMenu.Desktop />
      </div>
    </>
  ),
  secondary,
  utility = (
    <>
      <div css={tw`block xl:hidden`}>
        <UtilityMenu.Mobile />
      </div>
      <div css={tw`hidden xl:block`}>
        <UtilityMenu.Desktop>{children}</UtilityMenu.Desktop>
      </div>
    </>
  ),
}) => {
  const transitionNodeRef = useRef<HTMLElement>(null);
  return (
    <AppMenu maxWidth={theme`screens.xl`}>
      <header css={tw`relative z-10`}>
        <div className="main-menu" css={tw`grid grid-flow-col items-center`}>
          <div className="mobile-menu" css={tw`block xl:hidden`}>
            <div css={tw`container mx-auto`}>
              <div
                css={tw`relative grid grid-flow-col justify-between items-center gap-2 p-3 z-50 bg-white grid-cols-3 grid-template-columns[80px 1fr 80px]`}
              >
                <div css={tw`self-start`}>
                  <MenuControl />
                </div>

                <div css={tw`self-center -mt-1.5 flex justify-center`}>
                  <a href={CLOUDSCENE_URL}>
                    <Anchor>
                      <CloudsceneLogo size="small" />
                    </Anchor>
                  </a>
                </div>

                <div
                  className="utility-menu"
                  css={tw`self-end flex justify-end`}
                >
                  {utility}
                </div>
              </div>
              <AppMenuContext.Consumer>
                {({ isOpen }) => (
                  <Transition
                    in={isOpen}
                    timeout={200}
                    appear
                    nodeRef={transitionNodeRef}
                  >
                    {(state) => (
                      <div
                        css={[
                          tw`fixed z-40 container mx-auto transform-gpu transition-all duration-200 ease-in-out transform-origin[top] inset-x-0`,
                          transitions[state],
                        ]}
                      >
                        <div css={tw`relative`}>
                          <div
                            css={[
                              tw`block xl:hidden bg-white overflow-hidden w-full shadow-2xl rounded-b-2xl`,
                            ]}
                          >
                            {children}
                            {primary}
                          </div>
                        </div>
                      </div>
                    )}
                  </Transition>
                )}
              </AppMenuContext.Consumer>
            </div>
            {secondary && <div className="secondary-menu">{secondary}</div>}
          </div>

          <div className="desktop-menu" css={tw`hidden xl:block`}>
            <div
              css={[
                variant === 'contained' && tw`container mx-auto relative z-20`,
                tw`p-3`,
              ]}
            >
              <div
                css={tw`w-full grid grid-cols-2 grid-template-columns[auto auto] gap-8 justify-between items-center`}
              >
                <div
                  css={tw`grid grid-flow-col gap-8 grid-template-columns[1fr auto] items-center`}
                >
                  <div css={tw`self-center -mt-2`}>
                    <a href={CLOUDSCENE_URL}>
                      <Anchor>
                        <CloudsceneLogo size="small" />
                      </Anchor>
                    </a>
                  </div>

                  {<div className="primary-menu">{primary}</div>}
                </div>

                {<div className="utility-menu">{utility}</div>}
              </div>
            </div>

            {secondary && <div className="secondary-menu">{secondary}</div>}
          </div>
        </div>
      </header>
    </AppMenu>
  );
};
