import React, { useMemo } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import Helmet from 'react-helmet';
import {
  Grid,
  useMediaQuery,
  Theme,
  makeStyles,
  createStyles,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core';
import StaticSplitViewLayout from '../../../containers/StaticSplitViewLayout';
import ListItem from '../../../components/ListItem';
import { getPageTitleTemplate } from '../../../constants';
import * as ListingInfo from '../../../components/Listings/ListingInfo';
import ListingStatusLabel from '../../../components/ListingStatusLabel';
import noListingsImage from '../../../assets/marketplace-empty-seller-completed.svg';
import { ROUTES } from '../../../routes';
import { useAppState } from '../../../contexts/AppState';
import UnstyledLink from '../../../components/UnstyledLink';
import BackButton from '../../../components/BackButton';
import MarketPlaceHeaderNav from '../header-nav';
import ListingData from '../../../components/ListingData';
import ListingCard from '../../../components/ListingCard';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  ListingCardGroup,
  ListingPanel,
} from '../../../components/ListingGroup';
import SellingListingDetailHeader from './detail-header';
import EmptyContent from '../../../components/EmptyContent';
import { useReadAllListingProvidersQuery } from '../../../services/api/marketplace-service/listing-providers';
import { ListingProviderResponse } from '../../../types/marketplace-service/listing-provider';
import { selectFinishedListingProviders } from '../../../store/opportunities/selectors';

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      maxWidth: '100%',
      width: 450,
    },
    emptyText: {
      fontWeight: 'normal',
    },
  })
);

const SellingCompleted: React.FunctionComponent<
  RouteComponentProps<{
    listingShortId?: string;
  }> & {
    title?: string;
  }
> = ({ title = 'Completed Opportunities', listingShortId }) => {
  const classes = useStyles();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const { data, isFetching, isSuccess } = useReadAllListingProvidersQuery({
    params: { include: 'listing,bid' },
  });

  const { completed } = useMemo(() => {
    const entities: ListingProviderResponse[] = data?.entities ?? [];
    const context = data?.context;

    const result = selectFinishedListingProviders(entities, context);

    return result;
  }, [data]);

  const appState = useAppState();
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';

  const successfulListings = completed.filter(
    (item) => item.bid?.attributes.state === 'successful'
  );

  const unsuccessfulListings = completed.filter(
    (item) => item.bid?.attributes.state === 'unsuccessful'
  );

  const archivedListings = completed.filter(
    (item) =>
      !!item.provider?.attributes.bid_at &&
      item.bid?.attributes.state !== 'successful' &&
      item.bid?.attributes.state !== 'unsuccessful'
  );

  const unsubmittedListings = completed.filter(
    (item) => !item.provider?.attributes.bid_at
  );

  const noListings =
    successfulListings.length === 0 &&
    unsuccessfulListings.length === 0 &&
    unsubmittedListings.length === 0;

  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <MarketPlaceHeaderNav title="Completed" isDetail={!!listingShortId}>
        <UnstyledLink to={ROUTES.sellingDashboard}>
          <BackButton />
        </UnstyledLink>
      </MarketPlaceHeaderNav>
      <StaticSplitViewLayout
        open={!!listingShortId && !noListings && !isFetching && isSuccess}
        content={
          <>
            <Grid container spacing={mobile ? 0 : 4} justifyContent="center">
              {isFetching && <LoadingSpinner />}
              {!isFetching && isSuccess && noListings && (
                <Grid
                  item
                  sm={10}
                  xs={12}
                  component={EmptyContent}
                  img={
                    <img
                      className={classes.image}
                      alt="landing page"
                      src={noListingsImage}
                    />
                  }
                  cta={
                    <Button
                      component={Link}
                      to={ROUTES.root}
                      variant="contained"
                      color="primary"
                    >
                      Browse Opportunities
                    </Button>
                  }
                >
                  <Typography color="primary" variant="h4">
                    Hey, slow down! You don&apos;t have any completed
                    opportunities just yet on Cloudscene Marketplace.
                    That&apos;s okay, these things take time. When a buyer has
                    made their decision on a listing, it will live here.
                  </Typography>
                  <Box py={2}>
                    <Typography variant="h5" className={classes.emptyText}>
                      In the meantime, let&apos;s find more leads!
                    </Typography>
                  </Box>
                </Grid>
              )}
              {!isFetching && isSuccess && successfulListings.length > 0 && (
                <ListingData
                  title="Successful Bids"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={successfulListings}
                  baseURL={ROUTES.sellingCompleted}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                    userId:
                      item.provider?.attributes.external_assigned_to_user_id,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="successful" />}
                />
              )}

              {!isFetching && isSuccess && unsuccessfulListings.length > 0 && (
                <ListingData
                  title="Unsuccessful Bids"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={unsuccessfulListings}
                  baseURL={ROUTES.sellingCompleted}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                    userId:
                      item.provider?.attributes.external_assigned_to_user_id,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="unsuccessful" />}
                />
              )}

              {!isFetching && isSuccess && unsubmittedListings.length > 0 && (
                <ListingData
                  title="No Bid Submitted"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={unsubmittedListings}
                  baseURL={ROUTES.sellingCompleted}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                    userId:
                      item.provider?.attributes.external_assigned_to_user_id,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="unsubmitted" />}
                />
              )}

              {!isFetching && isSuccess && archivedListings.length > 0 && (
                <ListingData
                  title="Archived"
                  component={mobile ? ListingCard : ListItem}
                  container={mobile ? ListingCardGroup : ListingPanel}
                  items={archivedListings}
                  baseURL={ROUTES.sellingCompleted}
                  activeListingShortId={listingShortId}
                  getProps={(item) => ({
                    invited: !!item.provider?.attributes.invited_at,
                    userId:
                      item.provider?.attributes.external_assigned_to_user_id,
                  })}
                  opportunity
                  status={() => <ListingStatusLabel variant="archived" />}
                />
              )}
            </Grid>
          </>
        }
        detail={
          <ListingInfo.Container id={listingShortId}>
            <ListingInfo.Header>
              <SellingListingDetailHeader
                back={ROUTES.sellingCompleted}
                splitScreenMode={splitScreenMode}
              />
            </ListingInfo.Header>
            {mobile && (
              <Box mt={2} mx={-2}>
                <Divider />
              </Box>
            )}
            <ListingInfo.Content />
          </ListingInfo.Container>
        }
      />
    </>
  );
};

export default SellingCompleted;
