import { createSelector } from '@reduxjs/toolkit';
import { ProviderGatewayServiceProvider } from '../../types/provider-gateway';
import { selectAllMatchedServiceProviders } from './index';

export function sortProviders(
  a: ProviderGatewayServiceProvider,
  b: ProviderGatewayServiceProvider
): number {
  return (
    Number(b.premium) - Number(a.premium) ||
    b.score - a.score ||
    b.pointsofpresence - a.pointsofpresence ||
    (a?.name ?? '').localeCompare(b?.name ?? '')
  );
}

export const selectMatchedProvidersBySearch = (searchTerm?: string) => {
  return createSelector(selectAllMatchedServiceProviders, (providers) => {
    if (searchTerm) {
      return providers
        .filter((provider) =>
          provider?.name?.match(new RegExp('.*' + searchTerm + '.*', 'gi'))
        )
        .sort(sortProviders)
        .slice(0, 20);
    } else {
      return providers.sort(sortProviders).slice(0, 20);
    }
  });
};
