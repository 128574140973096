import { InputAdornment } from '@material-ui/core';
import React from 'react';
import CSTextField from '../../Form/CSTextField';
import SearchIcon from '@material-ui/icons/SearchRounded';

const SearchField: React.FC<{
  onSearchChange: (value: string) => void;
}> = ({ onSearchChange }) => {
  return (
    <CSTextField
      onChange={(event) => {
        const searchValue = (event.target.value as string).trim().toLowerCase();
        onSearchChange(searchValue);
      }}
      placeholder="Search listings"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      }
    />
  );
};

export default SearchField;
