import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import { ListingMarketResponse } from '../../types/marketplace-service/market';
import { MarketEntity } from '../../types/market';
import {
  createMarket,
  fetchAllMarkets,
  fetchMarketById,
  deleteMarketById,
} from './actions';
import { RootState, AppThunk } from '../types.d';
import { listingsAPI } from '../../services/api/marketplace-service/listings';

const marketsAdapter = createEntityAdapter<MarketEntity>({
  selectId: (market) => market.id!,
});

const markets = createSlice({
  name: 'markets',
  initialState: marketsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllMarkets.fulfilled, (state, action) => {
      marketsAdapter.upsertMany(
        state,
        action.payload.data.map<MarketEntity>((market) => ({
          type: market.type,
          id: market.id!,
          attributes: {
            id: market.attributes?.external_market_id!,
            type: market.relationships.market.data.type,
          },
        }))
      );
    });

    builder.addCase(fetchMarketById.fulfilled, (state, action) => {
      marketsAdapter.upsertOne(state, {
        type: action.payload.data.type,
        id: action.payload.data.id!,
        attributes: {
          id: action.payload.data.attributes?.external_market_id!,
          type: action.payload.data.relationships.market.data.type,
        },
      });
    });

    builder.addCase(deleteMarketById.fulfilled, (state, action) => {
      marketsAdapter.removeOne(state, action.payload.id);
    });

    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingsByCompanyID.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context['listing-market']) return state;

        const markets = Object.values(
          action.payload.context['listing-market'].entities
        ).map<MarketEntity>((market) => ({
          type: market.type,
          id: market.id!,
          attributes: {
            id: market.attributes?.external_market_id!,
            type: market.relationships.market.data.type,
          },
        }));

        marketsAdapter.upsertMany(state, markets);
      }
    );
  },
});

export const {
  selectIds: selectMarketIds,
  selectEntities: selectMarketEntities,
  selectAll: selectAllMarkets,
  selectTotal: selectTotalMarkets,
} = marketsAdapter.getSelectors((state: RootState) => state.markets);

export { createMarket, fetchAllMarkets, fetchMarketById, deleteMarketById };
export const selectMarketById = (id?: string | null) =>
  createSelector(selectMarketEntities, (markets) =>
    id ? markets[id] : undefined
  );

export const dispatchMarket =
  (
    action: string,
    payload: {
      listing: string;
      market: ListingMarketResponse;
    }
  ): AppThunk =>
  async (dispatch) => {
    if (action === 'add') return dispatch(createMarket(payload));
    if (action === 'delete')
      return dispatch(
        deleteMarketById({
          listing: payload.listing,
          market: payload.market.id!,
        })
      );
  };

export default markets.reducer;
