import React from 'react';
import { CreateListingFormState } from '../../../containers/CreateListing';
import ServiceRequirementsEthernet from './Ethernet';
import ServiceRequirementsIPTransit from './IPTransit';
import ServiceRequirementsDarkFiber from './DarkFiber';
import ServiceRequirementsCloudConnectivity from './CloudConnectivity';
import ServiceRequirementsInternetAccess from './InternetAccess';
import ServiceRequirementsWavelength from './Wavelength';
import ServiceRequirementsColocation from './Colocation';
import ServiceRequirementsMulti from './Multi';
import {
  Services,
  ServiceTypes,
  ServiceEthernet,
  ServiceIPTransit,
  ServiceDarkFiber,
  ServiceInternetAccess,
  ServiceCloudConnectivity,
  ServiceWavelength,
  ServiceColocation,
  ServiceMultiRequirements,
  ServiceTypeKeys,
} from '../../../types/services';
import { useFormState } from 'react-final-form';

const ServiceRequirements: React.FC<{
  onDone: (service: any) => void;
  type?: ServiceTypeKeys | null;
  service?: Services;
}> = ({ onDone, type = null, service }) => {
  const formState = useFormState<CreateListingFormState>();
  const minRfsDate = new Date(formState.values.attributes.end);
  return {
    [ServiceTypes.ethernet]: (
      <ServiceRequirementsEthernet
        onDone={onDone}
        values={service as ServiceEthernet}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.mpls]: (
      <ServiceRequirementsEthernet
        onDone={onDone}
        values={service as ServiceEthernet}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.ip_transit]: (
      <ServiceRequirementsIPTransit
        onDone={onDone}
        values={service as ServiceIPTransit}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.dark_fiber]: (
      <ServiceRequirementsDarkFiber
        onDone={onDone}
        values={service as ServiceDarkFiber}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.cloud_connectivity]: (
      <ServiceRequirementsCloudConnectivity
        onDone={onDone}
        values={service as ServiceCloudConnectivity}
      />
    ),
    [ServiceTypes.internet_access]: (
      <ServiceRequirementsInternetAccess
        onDone={onDone}
        values={service as ServiceInternetAccess}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.wavelength]: (
      <ServiceRequirementsWavelength
        onDone={onDone}
        values={service as ServiceWavelength}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.colocation]: (
      <ServiceRequirementsColocation
        onDone={onDone}
        values={service as ServiceColocation}
        minRfsDate={minRfsDate}
      />
    ),
    [ServiceTypes.cloud_services]: <></>,
    [ServiceTypes.sd_wan]: <></>,
    [ServiceTypes.multi_service_requirements]: (
      <ServiceRequirementsMulti
        onDone={onDone}
        values={service as ServiceMultiRequirements}
      />
    ),
    [ServiceTypes.other]: <></>,
  }[type ?? 'other'];
};

export default ServiceRequirements;
