import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MarketsAutocomplete from '../MarketsAutocomplete';
import { Market } from '../../types/market';

interface AddMarketProps {
  open?: boolean;
  onChange: (market: Market[]) => void;
  onClose: () => void;
  selectedMarkets: Market[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: 600,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    close: {
      color: theme.palette.grey[500],
    },
    chips: {
      margin: theme.spacing(0.5),
    },
  })
);

const AddMarkets: React.FC<AddMarketProps> = ({
  open = false,
  onChange,
  onClose,
  selectedMarkets,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }} onClose={onClose}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h4">Add Markets</Typography>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box pb={2}>{children}</Box>
        <MarketsAutocomplete
          selectedMarkets={selectedMarkets}
          onChange={onChange}
        />
        <Box mx="auto" mt={3} width={200}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onClose}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddMarkets;
