import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Document } from '../../types/document';
import {
  createDocument,
  deleteDocumentById,
  fetchDocumentById,
  updateDocumentById,
} from './actions';
import { RootState, AppThunk } from '../types.d';
import { listingsAPI } from '../../services/api/marketplace-service/listings';
import { DocumentResponse } from '../../types/marketplace-service/document';

const documentsAdapter = createEntityAdapter<DocumentResponse>({
  selectId: (document) => document.id,
});

const documents = createSlice({
  name: 'documents',
  initialState: documentsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    // FETCH ONE
    builder.addCase(fetchDocumentById.fulfilled, (state, action) => {
      documentsAdapter.upsertOne(state, action.payload.data);
    });

    builder.addMatcher(
      isAnyOf(
        listingsAPI.endpoints.readAllListings.matchFulfilled,
        listingsAPI.endpoints.readListingsByCompanyID.matchFulfilled,
        listingsAPI.endpoints.readListingByID.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload.context.document) return state;

        const documents = Object.values(
          action.payload.context.document.entities
        );
        documentsAdapter.upsertMany(state, documents);
      }
    );
  },
});

export const {
  selectById: selectDocumentById,
  selectIds: selectDocumentIds,
  selectEntities: selectDocumentEntities,
  selectAll: selectAllDocuments,
  selectTotal: selectTotalDocuments,
} = documentsAdapter.getSelectors((state: RootState) => state.documents);

export const dispatchDocuments =
  (
    action: string,
    payload: {
      listing: string;
      document: Document;
    }
  ): AppThunk =>
  async (dispatch) => {
    if (action === 'add') return dispatch(createDocument(payload));
    if (action === 'edit') return dispatch(updateDocumentById(payload));
    if (action === 'delete')
      return dispatch(
        deleteDocumentById({
          document: payload.document.id!,
        })
      );
  };

export {
  createDocument,
  updateDocumentById,
  deleteDocumentById,
  fetchDocumentById,
} from './actions';

export default documents.reducer;
