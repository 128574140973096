import { object, string, number, boolean } from 'yup';
import { TECH_SPECS_API_KEYS } from '../../../../constants';

export const schema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
    })
      .nullable()
      .label('B-End'),
    requirements: object({
      value: number().min(1),
      unit: string().required(),
    })
      .nullable()
      .required(),
    power: object({
      value: number().label('Power Value').min(1),
      unit: string().required(),
    })
      .nullable()
      .required(),
    advanced: object({
      space_required: string().nullable().label('Space Required'),
      additional_services: object({
        [TECH_SPECS_API_KEYS['247_security']]: boolean(),
        [TECH_SPECS_API_KEYS.remote_hands]: boolean(),
        [TECH_SPECS_API_KEYS.separate_cage]: boolean(),
        [TECH_SPECS_API_KEYS.dual_power_feed]: boolean(),
        [TECH_SPECS_API_KEYS.cross_connects]: boolean(),
      }),
    }),
  }).defined(),
}).defined();

export default schema;
