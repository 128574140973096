import React, { useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import FlagsmithFeature from '@cloudscene-dev/flagsmith-react';
import { ROUTES } from '../../../routes';
import BackButton from '../../../components/BackButton';
import { LeadDetails, LeadsSection } from '../../../components/LeadListing';
import UnstyledLink from '../../../components/UnstyledLink';
import StaticSplitViewLayout from '../../../containers/StaticSplitViewLayout';
import { getPageTitleTemplate } from '../../../constants';
import { useReadMarketplaceLeadsByStatesQuery } from '../../../services/api/marketplace-service/gql/marketplace-leads';
import { FLAGSMITH_FEATURES } from '../../../utilities/flagsmith-config';
import MarketPlaceHeaderNav from '../header-nav';

const order = {
  APPROVED: 1,
  ASSIGNED: 2,
  ACTIONED: 3,
  CLOSED: 4,
};

const SellingLeads: React.FunctionComponent<
  RouteComponentProps<{
    leadShortId?: string;
  }> & {
    title?: string;
  }
> = ({ title = 'Leads', leadShortId }) => {
  const { data, isFetching, isSuccess } =
    useReadMarketplaceLeadsByStatesQuery();

  const noListings = data?.leadsByStates.length === 0;

  const leads = useMemo(() => {
    const copy = data?.leadsByStates.slice() ?? [];

    return copy.sort((a, b) => {
      return order[a.state] - order[b.state];
    });
  }, [data?.leadsByStates]);

  const activeLead = useMemo(() => {
    return data?.leadsByStates.find((lead) => lead.short_id === leadShortId);
  }, [data?.leadsByStates, leadShortId]);

  return (
    <FlagsmithFeature id={FLAGSMITH_FEATURES.MARKETPLACE_LEADS}>
      <>
        <Helmet titleTemplate={getPageTitleTemplate()}>
          <title>{title}</title>
        </Helmet>
        <MarketPlaceHeaderNav title="Leads" isDetail={!!leadShortId}>
          <UnstyledLink to={ROUTES.sellingDashboard}>
            <BackButton />
          </UnstyledLink>
        </MarketPlaceHeaderNav>
        <StaticSplitViewLayout
          open={!!leadShortId && !noListings && !isFetching && isSuccess}
          content={
            <LeadsSection
              leads={leads}
              activeListingShortId={leadShortId}
              isFetching={isFetching}
              isSuccess={isSuccess}
            />
          }
          detail={<>{activeLead && <LeadDetails lead={activeLead} />}</>}
        />
      </>
    </FlagsmithFeature>
  );
};

export default SellingLeads;
