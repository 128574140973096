import { EntityType } from '../types/entity-collection';

export const filterRelationshipEntityType = <T extends EntityType>(
  type: T['type'],
  relationships: EntityType[] = []
): T[] => {
  return relationships?.filter<T>((item): item is T => item.type === type);
};

export const filterRelationshipContext = <T extends EntityType>(
  ctx: { ids: string[]; entities: { [key: string]: T } } | undefined,
  fn: (entity: T) => boolean | undefined
) => {
  if (!ctx) return [];

  return ctx.ids.reduce<T[]>((result, id) => {
    if (!ctx.entities[id]) return result;

    return fn(ctx.entities[id]) ? [...result, ctx.entities[id]] : result;
  }, []);
};

export const findRelationshipContext = <T extends EntityType>(
  ctx: { ids: string[]; entities: { [key: string]: T } } | undefined,
  fn: (entity: T) => boolean | undefined
) => {
  if (!ctx) return undefined;

  const id = ctx.ids.find((id) =>
    ctx.entities[id] ? fn(ctx.entities[id]) : false
  );

  return id ? ctx.entities[id] : undefined;
};

interface GetRelationshipContextFn {
  <T extends EntityType>(
    ctx: { entities: { [key: string]: T } } | undefined,
    keys: string | { id: string } | null | undefined
  ): T | undefined;
  <T extends EntityType>(
    ctx: { entities: { [key: string]: T } } | undefined,
    keys: Array<string | { id: string }> | null | undefined
  ): T[] | undefined;
}

export const getRelationshipContext: GetRelationshipContextFn = (ctx, keys) => {
  if (!ctx || !keys) return undefined;

  type K = typeof ctx.entities[keyof typeof ctx.entities];

  if (Array.isArray(keys)) {
    return keys.reduce<K[]>((result, key) => {
      if (typeof key === 'string' && ctx.entities[key]) {
        return [...result, ctx.entities[key]];
      }

      if (typeof key === 'object' && ctx.entities[key.id]) {
        return [...result, ctx.entities[key.id]];
      }

      return result;
    }, []);
  }

  if (typeof keys === 'object' && ctx.entities[keys.id]) {
    return ctx.entities[keys.id];
  }

  if (typeof keys === 'string' && ctx.entities[keys]) return ctx.entities[keys];

  return undefined;
};
