import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Field, FormSpy, FormSpyRenderProps } from 'react-final-form';
import { ServiceTypeKeys, ServiceTypes } from '../../types/services';
import SubmitSection from './SubmitSection';
import MiniServiceRequirements from '../MiniServiceRequirements';
import TextField from '../Form/CSTextField';
import MarketsAutocomplete from '../MarketsAutocomplete';
import { CreateListingFormState } from '../../containers/CreateListing';
import { MarketEntity, Market } from '../../types/market';
import { useAppState } from '../../contexts/AppState';

interface ServiceRequirementsProps {
  providerId: string;
  onSubmit: (invite_providers: boolean) => void;
  service: ServiceTypeKeys;
}

const ServiceTypeTiles: React.FC<ServiceRequirementsProps> = ({
  providerId,
  onSubmit,
  service,
}) => {
  const appState = useAppState();
  const marketsLimit =
    service === ServiceTypes.ip_transit ||
    service === ServiceTypes.internet_access ||
    service === ServiceTypes.colocation
      ? 1
      : 2;
  const marketsLabel =
    service === ServiceTypes.ip_transit ||
    service === ServiceTypes.internet_access ||
    service === ServiceTypes.colocation
      ? 'Please indicate the market where you require this service *'
      : 'Please indicate the market/s you require services(Add up to two markets) *';
  return (
    <Grid spacing={3} container>
      <Box py={2}>
        <MiniServiceRequirements type={service} name="service" />
        <Grid item xs={12}>
          <FormSpy subscription={{ values: true, pristine: true }}>
            {({ form, values }: FormSpyRenderProps<CreateListingFormState>) => {
              const markets = values.markets;

              return (
                <MarketsAutocomplete
                  label={marketsLabel}
                  limit={marketsLimit}
                  selectedMarkets={markets.map(
                    (market) => appState.markets[market.value?.attributes.id]
                  )}
                  onDelete={(index) => {
                    if (values.markets.length > 0)
                      form.mutators.deleteResource('markets', index);
                  }}
                  onChange={(marketValues: Market[]) => {
                    const marketEntities = marketValues
                      .filter(
                        (market) =>
                          !markets.find(
                            (m) => m.value.attributes.id === market.id
                          )
                      )
                      .map<MarketEntity>((market) => ({
                        type: 'listing-market',
                        attributes: {
                          id: market.id,
                          type: market.type,
                        },
                      }));
                    marketEntities.forEach((market) =>
                      form.mutators.addResource('markets', market)
                    );
                  }}
                />
              );
            }}
          </FormSpy>
        </Grid>
        <Box py={2}>
          <Grid item xs={12}>
            <Field
              name="attributes.description"
              render={({ input }) => (
                <TextField
                  label="Do you have any extra information regarding your requirements?"
                  fullWidth
                  multiline
                  minRows={6}
                  {...input}
                />
              )}
            />
          </Grid>
        </Box>
      </Box>
      <FormSpy
        subscription={{
          valid: true,
          submitting: true,
          pristine: true,
        }}
      >
        {({ valid, submitting, pristine }) => (
          <SubmitSection
            providerId={providerId}
            onSubmit={(invite_providers) => onSubmit(invite_providers)}
            disabled={submitting || pristine || !valid}
            submitting={submitting}
          />
        )}
      </FormSpy>
    </Grid>
  );
};

export default ServiceTypeTiles;
