import {
  ServiceEthernet,
  ServiceTypes,
  ServiceDarkFiber,
  ServiceColocation,
  ServiceWavelength,
  ServiceIPTransit,
  ServiceInternetAccess,
  ServiceTypeKeys,
} from '../types/services';
import { object, string, boolean, number } from 'yup';
import { addDays, formatISO } from 'date-fns';
import { TECH_SPECS_API_KEYS } from '../constants';

const defaultRFSDate = formatISO(addDays(new Date(), 14));

const serviceEthernetInitialValue = (): ServiceEthernet => ({
  type: 'ethernet',
  ready_for_service: defaultRFSDate,
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: null,
    bandwidth: null,
    advanced: {
      access_type: null,
      advanced_features: {
        [TECH_SPECS_API_KEYS.q_in_q]: false,
        [TECH_SPECS_API_KEYS.jumbo_frames]: false,
        [TECH_SPECS_API_KEYS.encryption]: false,
      },
      minimum_sla: null,
      hand_off: null,
    },
    documents: [],
  },
});

const serviceDarkFiberInitialValue = (): ServiceDarkFiber => ({
  type: 'dark_fiber',
  ready_for_service: defaultRFSDate,
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: null,
    cores: null,
    advanced: {
      interface_type: null,
      diversity: {
        [TECH_SPECS_API_KEYS.building_lead_in]: false,
        [TECH_SPECS_API_KEYS.street_geo]: false,
      },
      mttr: null,
    },
    documents: [],
  },
});

const serviceWavelengthInitialValue = (): ServiceWavelength => ({
  type: 'wavelength',
  ready_for_service: formatISO(addDays(new Date(), 30)),
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: null,
    bandwidth: null,
    advanced: {
      phy: null,
      redundancy: null,
      minimum_sla: null,
    },
    documents: [],
  },
});

const serviceColocationInitialValue = (): ServiceColocation => ({
  type: 'colocation',
  ready_for_service: defaultRFSDate,
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: {
      type: 'colocation',
      id: '',
      description: '',
    },
    requirements: null,
    power: null,
    advanced: {
      space_required: null,
      additional_services: {
        [TECH_SPECS_API_KEYS['247_security']]: false,
        [TECH_SPECS_API_KEYS.remote_hands]: false,
        [TECH_SPECS_API_KEYS.separate_cage]: false,
        [TECH_SPECS_API_KEYS.dual_power_feed]: false,
        [TECH_SPECS_API_KEYS.cross_connects]: false,
      },
    },
    documents: [],
  },
});

const serviceIPTransitInitialValue = (): ServiceIPTransit => ({
  type: 'ip_transit',
  ready_for_service: formatISO(addDays(new Date(), 30)),
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: {
      type: 'internet',
      id: '',
      description: '',
    },
    commit: null,
    burst: null,
    advanced: {
      transit_type: null,
      advanced_features: {
        [TECH_SPECS_API_KEYS.bgp_community_support]: false,
        [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: false,
        [TECH_SPECS_API_KEYS.remote_trigger_black_hole]: false,
      },
    },
    documents: [],
  },
});

const serviceInternetAccessInitialValue = (): ServiceInternetAccess => ({
  type: 'internet_access',
  ready_for_service: formatISO(addDays(new Date(), 30)),
  contract_term: null,
  additional_info: '',
  attributes: {
    point_a: null,
    point_z: {
      type: 'internet',
      id: '',
      description: '',
    },
    bandwidth: null,
    sd_wan_compatible: false,
    advanced: {
      access_grade: null,
      access_method: null,
      advanced_features: {
        [TECH_SPECS_API_KEYS.bgp_community_support]: false,
        [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: false,
      },
      minimum_sla: null,
    },
    documents: [],
  },
});

export const getInitialValuesForServiceRequirement = (
  type?: ServiceTypes
):
  | ServiceEthernet
  | ServiceDarkFiber
  | ServiceColocation
  | ServiceWavelength
  | ServiceIPTransit
  | ServiceInternetAccess => {
  switch (type) {
    case ServiceTypes.ethernet:
      return serviceEthernetInitialValue();
    case ServiceTypes.dark_fiber:
      return serviceDarkFiberInitialValue();
    case ServiceTypes.colocation:
      return serviceColocationInitialValue();
    case ServiceTypes.wavelength:
      return serviceWavelengthInitialValue();
    case ServiceTypes.ip_transit:
      return serviceIPTransitInitialValue();
    case ServiceTypes.internet_access:
      return serviceInternetAccessInitialValue();

    default:
      return serviceEthernetInitialValue();
  }
};

const serviceEthernetSchema = object({
  service: object({
    type: string(),
    additional_info: string().nullable().label('Additional Notes'),
    ready_for_service: string(),
    contract_term: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .required()
      .label('Contract Term'),
    // external_service_name: string(),
    // external_service_id: string(),
    attributes: object({
      point_a: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('A-End'),
      point_z: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('B-End'),
      bandwidth: object({
        value: number(),
        unit: string(),
      })
        .nullable()
        .required()
        .label('Bandwidth'),
      advanced: object({
        access_type: string().nullable().label('Access Type'),
        advanced_features: object({
          [TECH_SPECS_API_KEYS.q_in_q]: boolean(),
          [TECH_SPECS_API_KEYS.jumbo_frames]: boolean(),
          [TECH_SPECS_API_KEYS.encryption]: boolean(),
        }),
        minimum_sla: string().nullable().label('Minimum SLA'),
        hand_off: string().nullable().label('Hand Off'),
      }),
    }),
  }),
}).defined();

const serviceColocationSchema = object({
  service: object({
    type: string(),
    additional_info: string().nullable().label('Additional Notes'),
    ready_for_service: string(),
    contract_term: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .required()
      .label('Contract Term'),
    external_service_name: string(),
    external_service_id: string(),
    attributes: object({
      point_a: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('A-End'),
      point_z: object({
        type: string(),
      })
        .nullable()
        .label('B-End'),
      requirements: object({
        value: number().required(),
        unit: string().required(),
      })
        .nullable()
        .required(),
      power: object({
        value: number().label('Power Value').required(),
        unit: string().required(),
      })
        .nullable()
        .required(),
      advanced: object({
        space_required: string().nullable().label('Space Required'),
        additional_services: object({
          [TECH_SPECS_API_KEYS['247_security']]: boolean(),
          [TECH_SPECS_API_KEYS.remote_hands]: boolean(),
          [TECH_SPECS_API_KEYS.separate_cage]: boolean(),
          [TECH_SPECS_API_KEYS.dual_power_feed]: boolean(),
          [TECH_SPECS_API_KEYS.cross_connects]: boolean(),
        }),
      }),
    }),
  }),
}).defined();

const serviceDarkFiberSchema = object({
  service: object({
    type: string(),
    additional_info: string().nullable().label('Additional Notes'),
    ready_for_service: string(),
    contract_term: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .required()
      .label('Contract Term'),
    external_service_name: string(),
    external_service_id: string(),
    attributes: object({
      point_a: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('A-End'),
      point_z: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('B-End'),
      cores: number().nullable().required().label('Cores'),
      advanced: object({
        interface_type: string().nullable().label('Interface Type'),
        diversity: object({
          [TECH_SPECS_API_KEYS.building_lead_in]: boolean(),
          [TECH_SPECS_API_KEYS.street_geo]: boolean(),
        }).label('Diversity'),
        mttr: string().nullable().label('MTTR'),
      }),
    }),
  }),
}).defined();

const serviceInternetAccessSchema = object({
  service: object({
    type: string(),
    additional_info: string().nullable().label('Additional Notes'),
    ready_for_service: string(),
    contract_term: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .required()
      .label('Contract Term'),
    external_service_name: string(),
    external_service_id: string(),
    attributes: object({
      point_a: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('A-End'),
      point_z: object({
        type: string(),
      })
        .nullable()
        .required()
        .label('B-End'),
      bandwidth: object({
        value: number(),
        unit: string(),
      })
        .nullable()
        .required()
        .label('Bandwidth'),
      sd_wan_compatible: boolean(),
      advanced: object({
        access_grade: string().nullable().label('Access Grade'),
        access_method: string().nullable().label('Access Method'),
        advanced_features: object({
          [TECH_SPECS_API_KEYS.bgp_community_support]: boolean(),
          [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: boolean(),
        }),
        minimum_sla: string().nullable().label('Minimum SLA'),
      }),
    }),
  }),
}).defined();

const serviceWavelengthSchema = object({
  service: object({
    type: string(),
    additional_info: string().nullable().label('Additional Notes'),
    ready_for_service: string(),
    contract_term: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .required()
      .label('Contract Term'),
    external_service_name: string(),
    external_service_id: string(),
    attributes: object({
      point_a: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('A-End'),
      point_z: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('B-End'),
      bandwidth: object({
        value: number(),
        unit: string(),
      })
        .nullable()
        .required()
        .label('Bandwidth'),
      advanced: object({
        phy: string().nullable().label('Physical'),
        redundancy: string().nullable().label('Redundancy'),
        minimum_sla: string().nullable().label('Minimum SLA'),
      }),
    }),
  }),
}).defined();

export const serviceIPTransitSchema = object({
  service: object({
    type: string(),
    additional_info: string().nullable().label('Additional Notes'),
    ready_for_service: string(),
    contract_term: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .required()
      .label('Contract Term'),
    external_service_name: string(),
    external_service_id: string(),
    attributes: object({
      point_a: object({
        type: string(),
        description: string(),
        id: string(),
      })
        .nullable()
        .required()
        .label('A-End'),
      point_z: object({
        type: string(),
      })
        .nullable()
        .required()
        .label('B-End'),
      commit: object({
        value: number(),
        unit: string(),
      })
        .nullable()
        .required()
        .label('Bandwidth'),
      burst: object({
        value: number(),
        unit: string(),
      })
        .nullable()
        .label('Burst'),
      advanced: object({
        transit_type: string().nullable().label('Transit Type'),
        advanced_features: object({
          [TECH_SPECS_API_KEYS.bgp_community_support]: boolean(),
          [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: boolean(),
          [TECH_SPECS_API_KEYS.remote_trigger_black_hole]: boolean(),
        }).label('Advanced Features'),
      }),
    }),
  }),
}).defined();

const serviceEthernetListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  // external_service_name: string(),
  // external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({
      value: number().required(),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    advanced: object({
      access_type: string().nullable().label('Access Type'),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.q_in_q]: boolean(),
        [TECH_SPECS_API_KEYS.jumbo_frames]: boolean(),
        [TECH_SPECS_API_KEYS.encryption]: boolean(),
      }),
      minimum_sla: string().nullable().label('Minimum SLA'),
      hand_off: string().nullable().label('Hand Off'),
    }),
  }),
}).defined();

const serviceColocationListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
    })
      .nullable()
      .label('B-End'),
    requirements: object({
      value: number().required(),
      unit: string().required(),
    })
      .nullable()
      .required(),
    power: object({
      value: number().label('Power Value').required(),
      unit: string().required(),
    })
      .nullable()
      .required(),
    advanced: object({
      space_required: string().nullable().label('Space Required'),
      additional_services: object({
        [TECH_SPECS_API_KEYS['247_security']]: boolean(),
        [TECH_SPECS_API_KEYS.remote_hands]: boolean(),
        [TECH_SPECS_API_KEYS.separate_cage]: boolean(),
        [TECH_SPECS_API_KEYS.dual_power_feed]: boolean(),
        [TECH_SPECS_API_KEYS.cross_connects]: boolean(),
      }),
    }),
  }),
}).defined();

const serviceDarkFiberListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    cores: number().nullable().required().label('Cores'),
    advanced: object({
      interface_type: string().nullable().label('Interface Type'),
      diversity: object({
        [TECH_SPECS_API_KEYS.building_lead_in]: boolean(),
        [TECH_SPECS_API_KEYS.street_geo]: boolean(),
      }).label('Diversity'),
      mttr: string().nullable().label('MTTR'),
    }),
  }),
}).defined();

const serviceInternetAccessListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({
      value: number().required(),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    sd_wan_compatible: boolean(),
    advanced: object({
      access_grade: string().nullable().label('Access Grade'),
      access_method: string().nullable().label('Access Method'),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.bgp_community_support]: boolean(),
        [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: boolean(),
      }),
      minimum_sla: string().nullable().label('Minimum SLA'),
    }),
  }),
}).defined();

const serviceWavelengthListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({
      value: number().required(),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    advanced: object({
      phy: string().nullable().label('Physical'),
      redundancy: string().nullable().label('Redundancy'),
      minimum_sla: string().nullable().label('Minimum SLA'),
    }),
  }),
}).defined();

export const serviceIPTransitListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    commit: object({
      value: number().required(),
      unit: string().required(),
    })
      .nullable()
      .required()
      .label('Bandwidth'),
    burst: object({
      value: number(),
      unit: string(),
    })
      .nullable()
      .label('Burst'),
    advanced: object({
      transit_type: string().nullable().label('Transit Type'),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.bgp_community_support]: boolean(),
        [TECH_SPECS_API_KEYS.ddos_filtering_scrubbing]: boolean(),
        [TECH_SPECS_API_KEYS.remote_trigger_black_hole]: boolean(),
      }).label('Advanced Features'),
    }),
  }),
}).defined();

export const serviceCloudConnectivityListingSchema = object({
  type: string(),
  additional_info: string().nullable().label('Additional Notes'),
  ready_for_service: string(),
  contract_term: object({
    value: number().required(),
    unit: string().required(),
  })
    .nullable()
    .required()
    .label('Contract Term'),
  external_service_name: string(),
  external_service_id: string(),
  attributes: object({
    point_a: object({
      type: string(),
      description: string(),
      id: string(),
    })
      .nullable()
      .required()
      .label('A-End'),
    point_z: object({
      type: string(),
      csp: string(),
      market: string(),
      on_ramp: string(),
    })
      .nullable()
      .required()
      .label('B-End'),
    bandwidth: object({ value: number().required(), unit: string().required() })
      .nullable()
      .required()
      .label('Bandwidth'),
    advanced: object({
      access_type: string().nullable(),
      advanced_features: object({
        [TECH_SPECS_API_KEYS.burst_flexible]: boolean(),
      }),
    }).defined(),
  }).defined(),
}).defined();

export const getMiniServiceRequirementValidationSchema = (
  type?: ServiceTypes
) => {
  switch (type) {
    case ServiceTypes.ethernet:
      return serviceEthernetSchema;
    case ServiceTypes.dark_fiber:
      return serviceDarkFiberSchema;
    case ServiceTypes.colocation:
      return serviceColocationSchema;
    case ServiceTypes.wavelength:
      return serviceWavelengthSchema;
    case ServiceTypes.ip_transit:
      return serviceIPTransitSchema;
    case ServiceTypes.internet_access:
      return serviceInternetAccessSchema;

    default:
      return serviceEthernetSchema;
  }
};

export const getServiceRequirementValidationSchema = (
  type?: ServiceTypeKeys
) => {
  switch (type) {
    case ServiceTypes.ethernet:
      return serviceEthernetListingSchema;
    case ServiceTypes.dark_fiber:
      return serviceDarkFiberListingSchema;
    case ServiceTypes.colocation:
      return serviceColocationListingSchema;
    case ServiceTypes.wavelength:
      return serviceWavelengthListingSchema;
    case ServiceTypes.ip_transit:
      return serviceIPTransitListingSchema;
    case ServiceTypes.internet_access:
      return serviceInternetAccessListingSchema;
    case ServiceTypes.cloud_connectivity:
      return serviceCloudConnectivityListingSchema;

    default:
      return serviceEthernetListingSchema;
  }
};
