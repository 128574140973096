import React, { useCallback, useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import BidPricingOption from './BidPricingOption';
import { useReadAllBidServicesQuery } from '../../services/api/marketplace-service/bids';
import { BidResponse } from '../../types/marketplace-service/bid';
import ServiceDetails from '../ServiceDetails';
import { getServiceUIName } from '../../constants';
import { useAppSelector } from '../../store/hooks';
import { fetchServiceById, selectServiceById } from '../../store/services';
import { AppDispatch } from '../../store/types';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(2, 0),
      background: theme.palette.grey[50],
    },
    bid: {
      margin: theme.spacing(1, -1),
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: theme.spacing(3),
    },
    label: {
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

interface BidServicesProps {
  bid: BidResponse;
}

const ListingService: React.FC<{
  id: string;
  pricing: React.ReactNode;
  listing: string;
}> = ({ id, pricing, listing, children }) => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const listingService = useAppSelector(selectServiceById(id));
  const handleSelectServiceById = useCallback(() => {
    return dispatch(
      fetchServiceById({
        listing: listing,
        service: id,
      })
    );
  }, [dispatch, id, listing]);
  useEffect(() => {
    const action = handleSelectServiceById();
    return () => action.abort();
  }, [handleSelectServiceById]);

  if (!listingService) return null;

  return (
    <Grid container spacing={2} item xs={12} className={classes.root}>
      <Grid item xs={12}>
        <ServiceDetails.ExpansionPanel
          id={listingService.type}
          title={getServiceUIName(listingService.attributes.type)}
        >
          <ServiceDetails.Summary service={listingService.attributes} />
        </ServiceDetails.ExpansionPanel>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container spacing={2} item xs className={classes.bid}>
        {listingService.attributes.type !== 'multi_service_requirements' && (
          <Grid item xs={12}>
            {pricing}
          </Grid>
        )}
        {children && (
          <Grid item xs={12}>
            <Typography component="div" gutterBottom className={classes.label}>
              {listingService.attributes.type === 'multi_service_requirements'
                ? 'Pricing options/estimates'
                : 'Additional Pricing Information'}
            </Typography>
            {children}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const BidServices: React.FC<BidServicesProps> = ({ bid }) => {
  const { data } = useReadAllBidServicesQuery({ bid: bid.id });

  if (!data) return null;

  return (
    <>
      {data.entities.map((bidService) => {
        return (
          <ListingService
            key={bidService.id}
            id={bidService.relationships['listing-service'].data.id}
            listing={bid.relationships.listing.data.id}
            pricing={<BidPricingOption bid={bid.id} service={bidService.id} />}
          >
            {bidService.attributes.additional_information && (
              <Typography component="div">
                {bidService.attributes.additional_information}
              </Typography>
            )}
          </ListingService>
        );
      })}
    </>
  );
};

export default BidServices;
