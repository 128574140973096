import tw from 'twin.macro';

export const base = [tw`text-base font-brand text-gray-600`];
export const hero = [
  tw`font-bold text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-gray-800`,
];
export const title = [tw`font-bold text-3xl text-gray-700`];
export const subtitle = [tw`font-bold text-2xl text-gray-700`];
export const headline = [tw`font-bold text-xl text-gray-700`];
export const caption = [tw`font-normal text-sm text-gray-500 leading-tight`];
export const hint = [
  tw`font-sans font-normal text-xs text-gray-400 leading-4 py-1`,
];
