import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Helmet from 'react-helmet';
import {
  Button,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import StaticSplitViewLayout from '../../../../containers/StaticSplitViewLayout';
import ListItem from '../../../../components/ListItem';
import * as ListingInfo from '../../../../components/Listings/ListingInfo';
import ListingDetail from '../../../../components/ListingDetail';
import ListingTabs from '../../../../components/ListingTabs';
import { getPageTitleTemplate } from '../../../../constants';
import ListingStatusLabel from '../../../../components/ListingStatusLabel';
import { ROUTES } from '../../../../routes';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { useAppState } from '../../../../contexts/AppState';
import Empty from './empty';
import MarketPlaceHeaderNav from '../../header-nav';
import UnstyledLink from '../../../../components/UnstyledLink';
import BackButton from '../../../../components/BackButton';
import ListingCard from '../../../../components/ListingCard';
import ListingData from '../../../../components/ListingData';
import {
  ListingCardGroup,
  ListingPanel,
} from '../../../../components/ListingGroup';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ListingProviderGroup } from '../../../../types/listing-provider';
import { useAuth } from '../../../../hooks/useAuth';
import { useLazyReadListingsByCompanyIDQuery } from '../../../../services/api/marketplace-service/listings';

const Content: React.FunctionComponent<
  RouteComponentProps<{
    title: string;
    listingShortId: string;
  }>
> = ({ title = 'Listings', listingShortId }) => {
  const { user } = useAuth();
  const [readListingsByCompanyID, { data, isFetching, isSuccess }] =
    useLazyReadListingsByCompanyIDQuery();

  const activeListings =
    data?.entities.map<ListingProviderGroup>((listing) => ({
      listing,
    })) ?? [];
  const appState = useAppState();
  const splitScreenMode = appState.layoutPreference === 'SPLITSCREEN';
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const noListings = activeListings.length === 0;
  useEffect(() => {
    if (user) {
      readListingsByCompanyID(
        {
          id: user?.company_account_id!,
          params: {
            include: 'documents,markets,providers,provider-contacts,bids',
            'filter[state]': 'active,published,approved,pending',
          },
        },
        true
      );
    }
  }, [user, readListingsByCompanyID]);
  return (
    <>
      <Helmet titleTemplate={getPageTitleTemplate()}>
        <title>{title}</title>
      </Helmet>
      <MarketPlaceHeaderNav title="Listings" isDetail={!!listingShortId}>
        <UnstyledLink to={ROUTES.buyingDashboard}>
          <BackButton />
        </UnstyledLink>
      </MarketPlaceHeaderNav>
      <StaticSplitViewLayout
        open={!!listingShortId && !noListings && isSuccess}
        content={
          <Grid container spacing={mobile ? 0 : 4}>
            {isFetching && <LoadingSpinner />}
            {!isFetching && isSuccess && noListings && <Empty />}
            {!isFetching && isSuccess && activeListings.length > 0 && (
              <ListingData
                title="Active"
                component={mobile ? ListingCard : ListItem}
                container={mobile ? ListingCardGroup : ListingPanel}
                items={activeListings}
                baseURL={ROUTES.buyingListings}
                activeListingShortId={listingShortId}
                getProps={(item) => ({
                  userId: item.listing.attributes.user_id ?? null,
                  userName: item.listing.attributes.contact?.full_name ?? null,
                })}
                status={(item) => (
                  <ListingStatusLabel
                    variant={
                      item.listing.attributes.state === 'published'
                        ? 'active'
                        : item.listing.attributes.state === 'approved'
                        ? 'scheduled'
                        : 'pending'
                    }
                  />
                )}
              />
            )}
          </Grid>
        }
        detail={
          <ListingInfo.Container id={listingShortId}>
            <ListingDetail
              leftNav={
                <UnstyledLink to={ROUTES.buyingListings}>
                  {mobile || !splitScreenMode ? (
                    <BackButton />
                  ) : (
                    <Button
                      color="primary"
                      endIcon={<ChevronRightIcon>close</ChevronRightIcon>}
                      variant="text"
                      size="small"
                    >
                      Close
                    </Button>
                  )}
                </UnstyledLink>
              }
              middleNav={
                mobile && <Typography variant="h6"> Source</Typography>
              }
            >
              <ListingTabs />
            </ListingDetail>
          </ListingInfo.Container>
        }
      />
    </>
  );
};

export default Content;
