import { createApi } from '@reduxjs/toolkit/query/react';
import { DIRECTORY_SERVICE_API } from '../../../api';
import { staggeredBaseQueryWithBailOut } from '../fetch-base-query';

export const directoryServiceAPI = createApi({
  reducerPath: 'directoryServiceAPI',
  baseQuery: staggeredBaseQueryWithBailOut(DIRECTORY_SERVICE_API),
  tagTypes: ['CompanyProfile'],
  endpoints: () => ({}),
});
