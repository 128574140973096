import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { HiOutlineInformationCircle, HiShare } from 'react-icons/hi';
import { COMPANY_PACKAGES, useAuth, USER_ROLES } from '../../hooks/useAuth';
import { SalesProModal } from '../../features/Subscription/SalesPro/Modal';
import { SalesManagerModal } from '../../features/Subscription/SalesManager/Modal';
import {
  PrimaryButton,
  SecondaryButton,
  IconButton,
} from '../../components/Cloudscene/Button';
import {
  ListingOpportunityActions,
  ListingOpportunityContext,
} from './ListingOpportunityManager';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    padding: theme.spacing(2),
    background: '#f1f3fa',
    borderRadius: '8px',
  },

  icon: {
    display: 'block',
  },
}));

const ListingOpportunityManagerView: React.FC<
  ListingOpportunityActions & {
    text?: React.ReactNode;
  }
> = ({
  text,
  onIntendToBid,
  onDecline,
  onAssign,
  onRequestToBid,
  onMoreInfo,
  onContact,
  onEditListing,
  onViewListing,
  onCopyListing,
  onViewOpportunity,
}) => {
  const classes = useStyles();
  const { hasCompanyPackage, hasRole } = useAuth();
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const { isInvited } = useContext(ListingOpportunityContext);
  if (!text) {
    return null;
  }

  /*
    On desktop we show the assign button if "Assign" is available.
    On mobile, for screen real estate reasons we only show it here if
    "Intend" or "Decline" actions are available as they go hand in hand.
    Otherwise, the "Assign" button will be in the mobile action menu if "Assign" is available.
  */
  const showAssignButton = onAssign && (!mobile || onIntendToBid || onDecline);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <div className={classes.message}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs="auto">
              <HiOutlineInformationCircle size={24} />
            </Grid>
            <Grid item xs>
              <Typography component="div" variant="body2" color="textPrimary">
                {text}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          {onMoreInfo && !mobile && (
            <Grid item xs={4} md="auto">
              <PrimaryButton onClick={onMoreInfo}>
                More Information
              </PrimaryButton>
            </Grid>
          )}
          {onContact && !mobile && (
            <Grid item xs={4} md="auto">
              <SecondaryButton onClick={onContact}>Contact</SecondaryButton>
            </Grid>
          )}
          {onViewOpportunity && (
            <Grid item xs={4} md="auto">
              {(hasCompanyPackage(COMPANY_PACKAGES.FREE) && isInvited) ||
              hasRole([USER_ROLES.SALES_PRO, USER_ROLES.SALES_MANAGER]) ? (
                <PrimaryButton onClick={onViewOpportunity}>
                  Submit Bid
                </PrimaryButton>
              ) : (
                <></>
              )}
            </Grid>
          )}
          {onIntendToBid && (
            <Grid item xs={4} md="auto">
              {isInvited ||
              hasRole([USER_ROLES.SALES_PRO, USER_ROLES.SALES_MANAGER]) ? (
                <PrimaryButton onClick={onIntendToBid}>
                  Intend to Bid
                </PrimaryButton>
              ) : (
                <SalesProModal
                  action={({ openModal }) => (
                    <PrimaryButton onClick={openModal}>
                      Intend to Bid
                    </PrimaryButton>
                  )}
                />
              )}
            </Grid>
          )}
          {onDecline && (
            <Grid item xs={4} md="auto">
              <SecondaryButton variant="danger" onClick={onDecline}>
                {mobile ? 'No Bid' : 'Decline / No Bid'}
              </SecondaryButton>
            </Grid>
          )}
          {onRequestToBid && !mobile && (
            <Grid item xs={4} md="auto">
              <PrimaryButton onClick={onAssign}>Request to Bid</PrimaryButton>
            </Grid>
          )}
          {showAssignButton && (
            <Grid item xs={4} md="auto">
              {(hasCompanyPackage(COMPANY_PACKAGES.FREE) ||
                !hasRole(USER_ROLES.SALES_MANAGER)) && (
                <SalesManagerModal
                  action={({ openModal }) => (
                    <SecondaryButton variant="primary" onClick={openModal}>
                      Assign
                    </SecondaryButton>
                  )}
                />
              )}

              {hasRole(USER_ROLES.SALES_MANAGER) &&
                !hasCompanyPackage([COMPANY_PACKAGES.FREE]) && (
                  <SecondaryButton variant="primary" onClick={onAssign}>
                    Assign
                  </SecondaryButton>
                )}
            </Grid>
          )}
          {onEditListing && !mobile && (
            <Grid item xs={4} md="auto">
              <SecondaryButton onClick={onEditListing}>
                Edit Listing
              </SecondaryButton>
            </Grid>
          )}
          {onViewListing && !mobile && (
            <Grid item xs={4} md="auto">
              <PrimaryButton onClick={onViewListing}>
                View Listing
              </PrimaryButton>
            </Grid>
          )}
          {onCopyListing && !mobile && (
            <Grid item xs={4} md="auto">
              <IconButton onClick={onCopyListing}>
                <HiShare size={24} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListingOpportunityManagerView;
