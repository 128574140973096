import React from 'react';
import { Typography } from '@material-ui/core';
import { MarketPlaceMatchMeta } from '../../types/marketplace-match';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface MarketPlaceMatchInfoLabelProps {
  meta: MarketPlaceMatchMeta;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#9E9E9E',
      fontSize: 14,
      fontWeight: 'bold',
      padding: theme.spacing(1, 0),
    },
  })
);

const ProductInfoLabel: React.FC<MarketPlaceMatchInfoLabelProps> = ({
  meta,
}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.label} component="div">
      {meta.onNet.aEnd + meta.nearNet.aEnd} Provider
      {meta.onNet.aEnd + meta.nearNet.aEnd > 1 ? 's are ' : ' is '}
      {meta.onNet.aEnd > 0 && meta.nearNet.aEnd === 0
        ? 'on-net '
        : meta.onNet.aEnd > 0 && meta.nearNet.aEnd > 0
        ? 'on-net and near-net '
        : 'near-net '}
      at this location
    </Typography>
  );
};

export default ProductInfoLabel;
