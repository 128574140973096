import { AuthUserType } from '../hooks/useAuth';
import { hasIntersection } from './arrays';

export const userHasRole = (role: string | string[], user?: AuthUserType) => {
  return hasIntersection(role, user?.roles);
};

export const userHasCompany = (
  company: string | string[],
  user?: AuthUserType
) => {
  return hasIntersection(company, user?.companies);
};

export const isAccountManager = (user?: AuthUserType) => {
  return hasIntersection(['MP_Sales_Manager', 'MP_Procurement'], user?.roles);
};
export const isSalesManager = (user?: AuthUserType) => {
  return hasIntersection(['MP_Sales_Manager'], user?.roles);
};
