import {
  Box,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { SortKeys } from './ListingContainer';

const useStyles = makeStyles((theme) => ({
  subSelectText: {
    color: theme.palette.grey[400],
    paddingRight: theme.spacing(0.5),
  },
  selectText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  select: {
    width: '100%',
  },
}));

const SortSelect: React.FC<{
  setSortBy: (newSortBy: SortKeys) => void;
  sortBy: SortKeys;
  sortOptions: { value: SortKeys; label: string }[];
}> = ({ setSortBy, sortBy, sortOptions }) => {
  const classes = useStyles();
  return (
    <Grid item xs>
      <Select
        className={classes.select}
        renderValue={(value) => (
          <Box display="flex">
            <Typography className={classes.subSelectText}>Sort By:</Typography>
            <Typography className={classes.selectText}>
              {sortOptions.find((op) => op.value === value)?.label}
            </Typography>
          </Box>
        )}
        variant="outlined"
        value={sortBy}
        onChange={(event) => {
          setSortBy(event.target.value as SortKeys);
        }}
      >
        {sortOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default SortSelect;
