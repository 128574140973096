import { Typography } from '@material-ui/core';
import { useReadServiceProviderContactByIDQuery } from '../../../services/api/marketplace-service/service-provider-contacts';
import { useReadServiceProviderContactByIDQuery as useGQLReadServiceProviderContactByIDQuery } from '../../../services/api/marketplace-service/gql/service-provider-contacts';
import { useFlagsmithFeature } from '@cloudscene-dev/flagsmith-react';
import { FLAGSMITH_FEATURES } from '../../../utilities/flagsmith-config';
import { ListingProviderContact } from '../../../types/provider-contact';

const Details = ({ id }: { id: string }) => {
  const useGraphQLPOC = useFlagsmithFeature(FLAGSMITH_FEATURES.GRAPHQL_POC);
  const { data } = useReadServiceProviderContactByIDQuery(
    {
      id,
    },
    {
      skip: !id || useGraphQLPOC,
    }
  );
  const providerContact = useGQLReadServiceProviderContactByIDQuery(
    {
      id: id,
    },
    {
      skip: !id || !useGraphQLPOC,
    }
  );
  const contact = useGraphQLPOC
    ? providerContact?.data?.providerContact
    : data?.entity.attributes;

  if (contact?.default_contact === 0 || contact?.default_contact === false)
    return null;

  return (
    <Typography variant="body1" component="div">
      <strong>
        {contact?.first_name} {contact?.last_name}
      </strong>
      <br />
      {contact?.email}
    </Typography>
  );
};

export interface DefaultProviderContactProps {
  contacts: {
    state: 'initial' | 'add' | 'edit' | 'delete';
    value: ListingProviderContact;
  }[];
}

export const DefaultProviderContact: React.FC<DefaultProviderContactProps> = ({
  contacts,
}) => {
  if (contacts.length === 0)
    return (
      <Typography variant="body1" component="div">
        Add My Contact (Optional)
      </Typography>
    );

  return (
    <>
      {contacts.map((contact) => (
        <Details
          key={contact.value.id}
          id={contact.value.relationships['provider-contact'].data.id}
        />
      ))}
    </>
  );
};
