import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Field } from 'react-final-form';
import ExternalListing from './ExternalListing';
import ClientDetails from './ClientDetails';
import Toggle from '../../components/Toggle';
import InfoBlock from '../../components/InfoBlock';
import { useAuth } from '../../hooks/useAuth';
import { FLAGSMITH_FEATURES } from '../../utilities/flagsmith-config';
import FlagsmithFeature from '@cloudscene-dev/flagsmith-react';

interface FormSectionProps {
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  MuiGridItem: {
    padding: theme.spacing(2, 0),
  },
}));

const ConfirmPublishSection: React.FC<FormSectionProps> = ({ name }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.MuiGridItem}>
        <Grid container spacing={4} item alignItems="center">
          <Grid item xs>
            <Typography variant="h5" component="div" gutterBottom>
              Private Listing
            </Typography>
            <Typography variant="body2" component="div">
              A private listing is only visible to the service providers you
              invite to respond to it. This is valuable for those companies that
              want to compare offerings and pricing between a select few of
              their preferred suppliers without opening up their listing to all
              available service providers.
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Field
              name={`${name}.private`}
              type="checkbox"
              render={({ input }) => <Toggle {...input} />}
            />
          </Grid>
        </Grid>
      </Grid>
      {user?.csAdmin && (
        <>
          <Grid item xs={12} className={classes.MuiGridItem}>
            <ExternalListing name="attributes" />
          </Grid>
          <FlagsmithFeature id={FLAGSMITH_FEATURES.CLIENT_DETAILS}>
            <Grid item xs={12} className={classes.MuiGridItem}>
              <ClientDetails name="attributes" />
            </Grid>
          </FlagsmithFeature>
        </>
      )}
      <Grid item xs={12} className={classes.MuiGridItem}>
        <InfoBlock>
          Ensure your listing contains all the information sellers will need to
          prepare an accurate quote. Incomplete or unclear listings may be
          rejected.&nbsp;
          <Link href="https://help.cloudscene.com/support/best-practice-creating-marketplace-listing/">
            Learn more.
          </Link>
        </InfoBlock>
      </Grid>
    </Grid>
  );
};

export default ConfirmPublishSection;
