import { useCallback, useRef } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useMutation } from 'react-query';

type StripeOnCheckoutConf = { isAsync?: boolean };

export const STRIPE_CHANNEL = 'STRIPE_CHANNEL';
export const STRIPE_SUCCESS = 'STRIPE_SUCCESS';
export const STRIPE_CANCELLED = 'STRIPE_CANCELLED';

export const STRIPE_TIMEOUT = 60000 * 10;

export const initStripeCheckout = (
  url: string,
  { signal, isAsync }: { signal?: AbortSignal; isAsync?: boolean } = {}
) => {
  if (!isAsync) {
    window.location.href = url;
    return Promise.resolve();
  }

  const promise = new Promise<void>((resolve, reject) => {
    if (!url) {
      return reject();
    }

    const win = window.open(url, '_blank');

    signal?.addEventListener('abort', () => {
      win?.close();
      reject();
    });

    const bc = new BroadcastChannel(STRIPE_CHANNEL);

    bc.onmessage = (message) => {
      if (message === STRIPE_SUCCESS) {
        resolve();
      } else if (message === STRIPE_CANCELLED) {
        reject();
      }

      bc.close();
    };
  });

  return promise;
};

/*
  We open stripe in a new tab so we need to wait for a message to come through
*/
export const useStripeCheckout = (onDone?: () => void) => {
  const controller = useRef<AbortController | null>(null);

  const { isLoading, isSuccess, mutateAsync, isError, reset } = useMutation<
    void,
    void,
    { url: string; isAsync?: boolean }
  >(
    ({ url, isAsync }) =>
      initStripeCheckout(url, {
        isAsync,
        signal: controller.current?.signal,
      }),
    {
      onSuccess: () => onDone?.(),
    }
  );

  const onCheckout = useCallback(
    (url: string, { isAsync }: StripeOnCheckoutConf = {}) => {
      controller.current = new AbortController();
      return mutateAsync({ url, isAsync });
    },
    [mutateAsync]
  );

  const onReset = useCallback(() => {
    controller.current = null;
    reset();
  }, [reset]);

  const onCancel = useCallback(() => {
    controller.current?.abort();
  }, []);

  return {
    onCheckout,
    onCancel,
    onReset,
    isLoading,
    isSuccess,
    isError,
    isCancelled: Boolean(controller.current?.signal.aborted),
  };
};
